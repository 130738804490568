
import React from "react";
import classnames from "classnames";
import $ from "jquery";

import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultImage from "./defaultImage";
import StringHelper from "../../../helpers/StringHelper";


class DefaultLink extends React.Component {

    renderContent()
    {
        if (this.props.icon)
        {
            return <>
               <DefaultImage key={StringHelper.createDomElementId()} src={this.props.icon ? this.props.icon : this.props.svg} />
               &nbsp;
               {this.props.content}
            </>
        }
        else{
            return this.props.content
        }

    }

    render() {
        var htmlContent = this.props.content;

        if (this.props.children && htmlContent == null)
        {
            return <a href={this.props.href ? this.props.href : "#"} target={this.props.target} style={this.props.style} className={"t2y-link" + (this.props.small ? "-small" : "")} onClick={this.props.onClick}>
                {this.renderContent()}
                {this.props.children}
            </a>
        }
        else
        {
            return <a href={this.props.href ? this.props.href : "#"}  target={this.props.target} style={this.props.style} className={"t2y-link" + (this.props.small ? "-small" : "")} onClick={this.props.onClick} dangerouslySetInnerHTML={{__html: htmlContent}}></a>
        }
    }
}

export default injectIntl(DefaultLink)
