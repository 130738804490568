
import React from "react";
import queryString from 'query-string';
import classnames from "classnames"
import "react-sliding-pane/dist/react-sliding-pane.css";

// reactstrap components
import {
    Progress,
    Modal,
    ButtonGroup,
    Container,
    Row,
    Col,
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Button,

    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardFooter
  } from "reactstrap";
  
import Gallery from 'react-grid-gallery';
import BusinessService from '../../../proxies/BusinessService'
import FormLoadingIndicator from "../components/FormLoadingIndicator";
import Resources from "../../../resources";
import ReactDatetime from "react-datetime";
import { injectIntl , FormattedMessage } from 'react-intl';

import DefaultButton from '../../components/common/defaultButton'
import TruckImage from "../components/Image/TruckImage";
import FormInput from "../components/FormInput";
import FormValidationComponent from "../components/FormValidationComponent";
import TruckBusinessSummary from "../components/card/TruckBusinessSummary";
import RepositoryDataComponent from "../components/RepositoryDataComponent";
import MapRouteComponent, { MapPoint, MapRoute } from "../components/map/MapRouteComponent";
import SlidingPane from "react-sliding-pane";

import TruckRepresentation from "../../components/truck/TruckRepresentation";
import DefaultImage from "../../components/common/defaultImage";
import NewMissionStep from "./NewMissionStep";
import FreightDocument from "./FreightDocument";
import TransportOperationsList from "../../components/mission/TransportOperationsList";
import DefaultLink from "../../components/common/defaultLink";
import DriversInput from "../../components/business/driversInput";
import FormDriversInput from "../components/form/FormDriversInput"
import TransporterService from "../../../proxies/TransporterService";
import StringHelper from "../../../helpers/StringHelper";
import Title2 from "../../components/common/title2";
import Title3 from "../../components/common/title3";
import Title4 from "../../components/common/title4";
import DefaultLabel from "../../components/common/defaultLabel";
import Message from "../../components/common/message";
import StepsAsRoadMap from "../../components/mission/StepsAsRoadMap";
import MissionProgress from "../../components/mission/MissionProgress";
import TruckSummary from "../../components/business/truckSummary";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import Config from "../../../config";
import Rating from "../../components/rating/rating";
import PageNotFound from "../../../components/notFound/PageNotFound";
import Report from "../../../V2/Page/Mission/Report";

var moment = require('moment');
require('moment/locale/fr');

class Mission extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.mapComponent = React.createRef();

        this.businessService = new BusinessService();
        this.transporterService = new TransporterService();

        this.validationRules = {}
        this.backUrl= null;

        this.state = {
            isTruckPanelOpen: false,
            isTransporter: this.businessService.getUser().is_transporter,
            loading: false,
            events: [],
            mission: null,
            firstManualStep: false,
            showDeleteModal: false,
            showMissionStepModal: false,
            showFreightDocumentModal: false,
            stepToDelete: null,
            stepToEdit: null,
            sourceStep: null,
            mapRoutes : [],
            mapPoints : [],
            currentTab: "organization",
            findMissionError: false,
        };
    }

    isReadonly()
    {
        return this.state.mission.readonly;
    }

    isDone()
    {
        return (this.state.mission.end_km != null && this.state.mission.end_km > 0) || this.state.mission.done;
    }

    isStarted(mission = null)
    {   
        if (mission == null)
        {
            mission = this.state.mission;
        }

        return mission && mission.start_km != null && mission.start_km > 0
    }

    loadDatas()
    {
        this.businessService.getMission(this, this.props.match.params.id, (response) => {
            const mission = response.mission;
            const steps = response.steps;
            const events = response.events;

            var mapPoints = [];

            var wayPointIndex = 0;
            steps.forEach(s => {
                if (s.coordonates != null && s.coordonates.gps_lat != null)
                {
                    mapPoints.push({
                        order: wayPointIndex,
                        latitude: s.coordonates.gps_lat,
                        longitude: s.coordonates.gps_long,
                        title: s.type.label,
                        description: s.description
                    })
                }
                wayPointIndex ++;
            })

            this.setState({
                events: events,
                mission: mission, 
                steps: steps,
                shipper: response.shipper,
                shipper_user: response.shipper_user,
                truck: response.truck,
                driver: response.driver,
                order: response.order, 
                center: response.center,
                invoice: response.invoice,
                firstManualStep: false, //firstManualStep,
                formDatas : { description: { value: mission.description} },
                currentTab: this.isStarted(mission) ? "tour" : "organization"
            })

            if (this.mapComponent != null && this.mapComponent.current != null && response.center)
            {
                this.mapComponent.current.showPointsOnMap({
                    latitude: response.center.gps_lat,
                    longitude: response.center.gps_long
                }, mapPoints)
            }
        }, () => {
            this.setState({
                findMissionError: true
            })
        });
    }

    end()
    {
        //alert("fin du process de création d'étape")
    }

    startFirstMissionStep()
    {
        return <>
            <NewMissionStep 
                allowAddressBookStepContactCreation={false}
                parent={this} 
                mission={this.state.mission} 
                from={this.state.steps[0]}
                step={null} type="MISSION_STEP_TYPE_LOADING"></NewMissionStep>
        </>
    }

    componentDidMount()
    {
        const parsedQueryString = queryString.parse(window.location.search);
        this.backUrl = parsedQueryString.backUrl;

        if (!this.props.match.params.id == false)
        {
            this.loadDatas()
        }
    }
    
    saveDescription()
    {
        var formIsValid = this.htmlFormIsValid();
        if (formIsValid)
        {
            var dtoRequest = {
                id : this.state.mission.id,
                description : this.getHtmlFormControlValue("description"),
            }
            this.businessService.saveMission(this, dtoRequest, (response) => {
                this.showSuccessToastNotification("", "Description enregistrée avec succès", "Fermer");
            })
        }
    }

    deleteMissionStep(event)
    {
        event.preventDefault();
        this.businessService.deleteMissionStep(this, this.state.stepToDelete.id, (response) => {
            this.setState({showDeleteModal: false})
            this.loadDatas();
            this.showSuccessModalNotification("", "Etape supprimée avec succès", "Fermer");
        })
    }

    saveMissionStep(event)
    {
        event.preventDefault();
        this.setState({showMissionStepModal: true})
    }
    
    addMissionFromHere(event, missionStepId)
    {
        event.preventDefault();
    }

    moveUp(event, missionStepId)
    {
        event.preventDefault();
        this.businessService.moveUpMissionStep(this, missionStepId, (response) => {
            this.loadDatas();
            this.showSuccessToastNotification("Etape déplacée avec succès");
        })
    }

    moveDown(event, missionStepId)
    {
        event.preventDefault();
        this.businessService.moveDownMissionStep(this, missionStepId, (response) => {
            this.loadDatas();
            this.showSuccessToastNotification("Etape déplacée avec succès");
        })
    }

    canDelete(step, stepIndex, nbSteps)
    {
        return !step.readonly
    }


    canEdit(step, stepIndex, nbSteps)
    {
        return !step.readonly
    }

    canAdd(step, stepIndex, nbSteps)
    {
        return (stepIndex + 1) < nbSteps;
    }

    canMoveUp(step, stepIndex, nbSteps)
    {
        return step.readonly == false && stepIndex >= 2
    }

    canMoveDown(step, stepIndex, nbSteps)
    {
        return step.readonly == false && stepIndex < (nbSteps - 2)
    }


    toggleDeleteModal(event)
    {
        this.setState({showDeleteModal: !this.state.showDeleteModal})
    };

    toggleMissionStepModal(event)
    {
        this.setState({showMissionStepModal: !this.state.showMissionStepModal})
    }

    openRemoveDriverModal()
    {
        var options = {}
        options.title = "Confirmation"
        options.component = <>Confirmer vous la désafectation de ce conducteur ?</>
        options.okButtonTitle = "Confirmer"
        options.okButtonCallback = () => {
            this.transporterService.removeMissionDriver(this, this.state.mission.id, 
                (response) => {
                    this.showWarningModalNotification("Attention", "Cette mission n'a aucun conducteur affecté, vous devez en attribuer un pour qu'elle puisse être effectuée");
                    this.setState({driver: null})
                }, 
                (httpErrorResponse) => {
                    httpErrorResponse.json().then((apiErrorResponse) => {
                        this.showErrorFromResponse(apiErrorResponse)
                    })
                    this.setState({loading: false})
                }
            )
        }

        this.showModalConfirmation(options)
    }

    // downloadMissionReport()
    // {
    //     this.businessService.downloadMissionReport(this, this.state.mission.id, response => {
    //
    //         const attachment = response.value
    //         const linkSource = `data:application/pdf;base64,${attachment.base64_content}`;
    //         const downloadLink = document.createElement("a");
    //         const fileName = attachment.filename;
    //
    //         downloadLink.href = linkSource;
    //         downloadLink.download = fileName;
    //         downloadLink.click();
    //     });
    // }

    renderChangeDriverModal()
    {
        this.showCustomModalNotification("Modification du conducteur", 
        <>
        Merci de selectionner dans la liste le conducteur à affecter à cette mission
        <FormDriversInput truck={this.state.truck} childRef={elt => this.driversInputRef = elt} required={true} manager={this} name="drivers"></FormDriversInput>
        </>, "Annuler", false, {
            customFooterButton : <>
                <DefaultButton className="t2y-secondary-button small" onClick={() => {
                    var selectedDriversId = this.getHtmlFormControlValue("drivers")
                    var driverId = null;
                    if (selectedDriversId.length > 0)
                    {
                        driverId = selectedDriversId[0];
                    }

                    if (driverId)
                    {
                        this.transporterService.changeMissionDriver(this, this.state.mission.id, driverId, 
                        (response) => {
                            this.setState({driver: response.value})
                            this.showSuccessModalNotification("Confirmation", "Conducteur modifié avec succès")
                            
                        }, 
                        (httpErrorResponse) => {
                            httpErrorResponse.json().then((apiErrorResponse) => {
                                if (apiErrorResponse.invalid_rules && apiErrorResponse.invalid_rules.length > 0)
                                {
                                    if (apiErrorResponse.invalid_rules[0] == "RULE_DRIVER_NOT_AVAILABLE")
                                    {
                                        // Si le conducteur est déjà affecté à la mission en cours alors message spécial
                                        if (apiErrorResponse.value && apiErrorResponse.value.length > 0 && this.props.match.params.id == apiErrorResponse.value[0].id)
                                        {
                                            this.showErrorModalNotification("Action impossible", <>
                                                <DefaultLabel>Ce conducteur est déjà affecté à cette mission.</DefaultLabel>
                                            </>, StringHelper.translate(this, "Default.close"))
                                        }
                                        else // Sinon message avec lien sur la mission déjà affecté au conducteur selectionné
                                        {
                                            this.showErrorModalNotification("Action impossible", <>
                                                <DefaultLabel>Le conducteur selectionné n'est pas disponible pour cette mission.&nbsp;
                                                {
                                                    (apiErrorResponse.value && apiErrorResponse.value.length > 0) ?
                                                    (
                                                        <>
                                                            Il est déjà associé à 
                                                            <DefaultLink href={"/admin/mission/" + apiErrorResponse.value[0].id} target={"_blank"}> une autre mission.</DefaultLink>
                                                        </>

                                                    ) : (<></>)
                                                }
                                                </DefaultLabel>
                                                
                                            </>, StringHelper.translate(this, "Default.close"))
                                        }
                                    }
                                    else
                                    {
                                        this.showErrorFromResponse(apiErrorResponse)
                                    }
                                }

                                this.setState({loading: false})
                            })
                        })

                        this.closeModal()
                    }
                    else{
                        this.closeModal()
                        this.showErrorModalNotification("Action impossible", "Merci de selectionner un conducteur dans la liste avant de valider");
                    }
                    
                }}>
                    Affecter ce conducteur
                </DefaultButton>
            </>
        })

    }

    renderDeleteModal()
    {
        return <Modal className="modal-dialog-centered" isOpen={this.state.showDeleteModal} toggle={() => this.toggleDeleteModal()}>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
          <FormattedMessage id="MissionStep.delete_title" />
          </h6>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleDeleteModal()} >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            <FormattedMessage id="MissionStep.delete_message" />
          </p>
        </div>
        <div className="modal-footer">
          <Button className="ml-auto" color="secondary"ata-dismiss="modal" type="button" onClick={() => this.toggleDeleteModal()} >
            <FormattedMessage id="Default.no" />
          </Button>
          <Button color="danger" type="button" onClick={this.deleteMissionStep.bind(this)}>
            <FormattedMessage id="Default.yes" />
          </Button>
        </div>
      </Modal>
    }

    renderFreightDocumentModal(transportOperation)
    {
        return <FreightDocument
            parent={this} 
            visible={true}
            mission={this.state.mission} 
            readonly={this.isDone() || this.isReadonly() || this.state.isTransporter}
            transporter={this.state.isTransporter}
            firstTransportOperation={this.transportOperationsList.count() == 0}
            defaultOrderDepartureTime={this.state.order.arrival_time}
            id={transportOperation ? transportOperation.id : null}
            onHide={(e) => {
                this.setState({showFreightDocumentModal: false});
                // Mise à jour de la liste des opérations de transport
                this.transportOperationsList.refresh();
                if (this.stepsAsRoadMap)
                {
                    this.stepsAsRoadMap.refresh(true);
                }
            }}
        ></FreightDocument>
    }

    renderMissionStepModal(mission)
    {
         {/*return <Modal className="modal-dialog-centered modal-xl" isOpen={this.state.showMissionStepModal} toggle={() => this.toggleMissionStepModal()}>
            <div className="modal-header">

            </div>
            <div className="modal-body">
                <MissionStep manager={this} mission={mission} stepToEdit={this.state.stepToEdit}  sourceStep={this.state.sourceStep} />
            </div>
    </Modal>*/}
        return <NewMissionStep 
            parent={this} 
            onHide={(e) => this.toggleMissionStepModal(e)}
            mission={mission} 
            from={this.state.sourceStep} 
            step={this.state.stepToEdit}
            type={this.state.type}
        ></NewMissionStep>
    }

    renderStats()
    {
        var startKm = this.state.mission ? this.state.mission.start_km : null;
        var endKm = this.state.mission ? this.state.mission.end_km : null;
        var distance = this.state.mission ? this.state.mission.nb_km : null;
        
        var duration = this.state.mission ? this.state.mission.duration_as_string : null;
        var nbSteps = this.state.mission ? this.state.mission.nb_steps : 0;
        var nbCompletedSteps = 0

        if (nbSteps > 0)
        {
            this.state.steps.forEach(s => {
                if (s.state.code == "MISSION_STEP_STATE_COMPLETED")
                {
                    nbCompletedSteps += 1;
                }
            })
        }


        var stepPercent = Math.round(nbCompletedSteps * 100 / nbSteps)
        var stepPercentColor = stepPercent >= 100 ? "success" : (stepPercent <= 33) ? "danger" : "warning"

        return <>
            <Card className="card-stats">
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle tag="h5" className="text-uppercase text-muted mb-1 mt-1">
                            Distance et Durée
                            </CardTitle>
                            <Row>
                            <span className="h2 font-weight-bold mb-0">
                                <i className="far fa-play-circle">&nbsp;</i>
                                {startKm ? startKm + " km à " + this.state.mission.start_time_as_string : "N.C"} 
                            </span>
                            </Row>
                            <Row>
                            <span className="h2 font-weight-bold mb-0">
                                <i className="fas fa-flag-checkered">&nbsp;</i>
                                {endKm ? endKm + " km  à " + this.state.mission.end_time_as_string : "N.C"} 
                                &nbsp;
                            </span>
                            </Row>
                            <Row>
                            <span className="h2 font-weight-bold mb-0">
                                <i className="fas fa-route">&nbsp;</i>
                                {distance ? distance + " km " : "N.C"} 
                            </span>
                            
                            </Row>
                            <Row>
                            <span className="h2 font-weight-bold mb-0">
                                <i className="fas fa-stopwatch">&nbsp;</i>
                                {duration ? duration : "N.C"} 
                            </span>
                            </Row>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="ni ni-chart-bar-32" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-1 mt-1">
                        Réalisation
                        </CardTitle>
                        <span className="mr-2">{nbCompletedSteps} étapes sur {nbSteps} soit {stepPercent} % de réalisé</span>
                        <div>
                            <Progress
                            max="100"
                            value={stepPercent}
                            barClassName={"bg-" + stepPercentColor}
                            />
                        </div>
                    </div>
                    </Row>

                </CardBody>
            </Card>
        </>
    }

    
    renderTruck()
    {
        var logoColor = "primary"

        var truckLabel = null;
        
        if (this.state.isTransporter && this.state.truck)
        {
            truckLabel = <>{this.state.truck.reference}<br/>{this.state.truck.immatriculation.trim()}<br/></>
        }

        return <>
            <Card className="card-stats t2y-clickable" >
                <CardBody onClick={e => this.truckSummaryRef.openCompleteSummary()}>
                    <Row>
                    <Col xs="10">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                        Véhicule
                        </CardTitle>
                    </Col>
                    <Col xs="2" style={{position:'absolute', top:'2px', right:'2px'}}>
                        <TruckSummary fullsizeImage={true} childRef={elt => this.truckSummaryRef = elt} completeSummaryOnClick={true} image={true} label={false} properties={false} truck={this.state.truck}></TruckSummary>
                    </Col>
                    <Col xs="12">
                        <span className="h2 font-weight-bold mb-0">
                            {truckLabel ? truckLabel : <></>}
                            {this.state.truck.technical_label}
                        </span>
                    </Col>
                    <Col xs="12" className="mt-3 mb-0 text-sm">
                        <span className={" mr-2"} style={{color: Resources.icon_primary_color}}>
                            <i className={Resources.truck_logo} />
                        </span>{" "}
                        <span outline /*onClick={(e) => this.setState({isTruckPanelOpen: true})}*/ className="text-nowrap">Voir toutes les données du camion</span>            
                    </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    }

    renderDriver()
    {   
        var driverSummary = "XXXXX XXXXXX";
        var driverMobilePhoneNumberLabel = "06XXXXXXXX";
        var driverMobilePhoneNumber = null;
        var logoColor = "primary"

        if (this.state.driver != null)
        {
            driverSummary = this.state.driver.summary;
            driverMobilePhoneNumber = this.mobilePhoneNumber(this.state.driver.mobile_phone_number) ? this.state.driver.mobile_phone_number : null;
            driverMobilePhoneNumberLabel = this.state.driver.mobile_phone_number;
        }
        
        if (this.state.loading == false && this.state.driver == null && this.state.isTransporter)
        {
            return <Message type="warning">
                <DefaultLabel>Aucun conducteur affecté</DefaultLabel>
                <br/>

                <DefaultLink small={true} onClick={() => this.renderChangeDriverModal()}>Affecter un conducteur</DefaultLink>
            </Message>
        }
        else
        {
            return <>
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="10">
                                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                Conducteur
                                </CardTitle>
                            </Col>
                            <Col xs="2" style={{position: "absolute", top: "6px", right: "0px", float: "right"}}>
                                <DefaultImage src={require("../../../assets/img/icons/conducteur.svg").default} style={{width:"30px"}} />
                            </Col>
                            <Col xs="12">
                                <span className="h2 font-weight-bold mb-0">
                                {driverSummary}
                                </span>
                            </Col>
                        
                            <Col xs="12" className="mt-3 mb-0 text-sm">
                                <span className={" mr-2"} style={{color: Resources.icon_primary_color}}>
                                    <DefaultImage src={Resources.mobile_phone_logo}></DefaultImage>
                                </span>{" "}
                                <span className="text-nowrap">
                                    <DefaultLink style={{textDecoration: "none"}} href={driverMobilePhoneNumber ? "tel:" + driverMobilePhoneNumber.replace(/[ -,.]/g, ""): null} small={true}>{driverMobilePhoneNumberLabel}</DefaultLink>
                                </span>
                            </Col>
                        </Row>
                        {
                            (this.state.mission && this.state.mission.change_driver_enable) ?
                            (
                                <>
                                    <Row>
                                    <Col lg="12" xl="6">
                                        <DefaultLink small={true} onClick={() => this.renderChangeDriverModal()}>Changer de conducteur</DefaultLink>
                                    </Col>
                                    <Col lg="12" xl="6">
                                        <DefaultLink small={true} onClick={() => this.openRemoveDriverModal()}>Désaffecter ce conducteur</DefaultLink>
                                    </Col>
                                    </Row>
                                </>
                            ) : (<></>)
                        }
                    </CardBody>
                    
                </Card>
            </>
        }
    }

    renderTimeLine()
    {
    /* Affichage des étapes */
        var timelineDatas = "";
    
        if (this.state.mission)
        {
            var nbSteps = this.state.steps.length;
            timelineDatas = this.state.steps.map((step, stepIndex) => {
                var time = null;
                var title = null;
                var description= null;
                var images = [];
                var thumbnailWidth = 300;
                var thumbnailHeight = 200;
                
                if (step.driver_pictures != null && step.driver_pictures.length > 0)
                {
                    step.driver_pictures.forEach(picture => {
                        images.push({
                            src: picture.url,
                            thumbnail: picture.url.replace('--\/', '--\/w_'+ thumbnailWidth+ ',h_' + thumbnailHeight + ',c_crop\/'),
                            thumbnailWidth: thumbnailWidth,
                            thumbnailHeight: thumbnailHeight,
                            isSelected: false,
                            caption: picture.comment
                        })
                    })
                }

                if (step.type.code == "MISSION_STEP_TYPE_GET_TRUCK")
                {
                    title = "Adresse départ du camion";
                    description = "(non modifiable)";
                }
                else if (step.type.code == "MISSION_STEP_TYPE_PARK_TRUCK")
                {
                    title = "Adresse de dépôt du camion";
                    description = "(non modifiable)";
                }
                else
                {
                    title = step.address_summary
                    description = step.description
                }

                return <div className="timeline-block" key={"step" + stepIndex}>
                    <span className="timeline-step badge-secondary">
                        <RepositoryDataComponent data={step.type} mode={"icon"} ></RepositoryDataComponent>
                    </span>
                    <div className="timeline-content">
                        {
                            (step.arrival_time) ?
                            (
                                <small className="font-weight-bold text-danger">
                                        <i className="fas fa-clock mr-1" />{step.arrival_time}
                                </small>
                            ) : ("")
                        }
                        <div className="d-flex justify-content-between pt-1">
                            <div>
                                <span className="text-muted text-sm font-weight-bold">
                                {title}
                                </span>
                            </div>
                            {
                                // Pas d'action sur la dernière étape si elle est en lecture seule
                                (this.isReadonly() == false && (step.readonly == false || (stepIndex > 0 && stepIndex < nbSteps - 1))) ?
                                (
                                    <UncontrolledDropdown>
                                        <DropdownToggle size="sm" color="neutral" className="mr-0 pull-right">
                                        <i className="fas fa-ellipsis-h" />
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            { 
                                                /*(this.canEdit(step, stepIndex, nbSteps)) ?
                                                (
                                                    <DropdownItem href="#pablo" onClick={e => {
                                                        e.preventDefault()
                                                        this.setState({stepToEdit: step, sourceStep: null})
                                                        this.toggleMissionStepModal(e)
                                                    }}>
                                                        <i className="fas fa-edit"></i>
                                                        Modifier
                                                    </DropdownItem>
                                                ) : ("")*/
                                            }
                                            { 
                                                /*
                                                (this.canAdd(step, stepIndex, nbSteps)) ?
                                                (
                                                    <>
                                                        <DropdownItem href="#pablo" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({sourceStep: step, stepToEdit:null, type: "MISSION_STEP_TYPE_LOADING"})
                                                            this.toggleMissionStepModal(e)
                                                        }}>
                                                            <i className="fas fa-clone"></i>
                                                            Ajouter une étape de chargement à partir d'ici
                                                        </DropdownItem>
                                                        <DropdownItem href="#pablo" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({sourceStep: step, stepToEdit:null, type: 'MISSION_STEP_TYPE_UNLOADING'})
                                                            this.toggleMissionStepModal(e)
                                                        }}>
                                                            <i className="fas fa-clone"></i>
                                                            Ajouter une étape de déchargement à partir d'ici
                                                        </DropdownItem>
                                                    </>
                                                ) : ("")*/
                                            }
                                            { 
                                                (this.canMoveUp(step, stepIndex, nbSteps)) ?
                                                (
                                                    <DropdownItem href="#pablo" onClick={e => this.moveUp(e, step.id)}>
                                                        <i className="fas fa-chevron-up"></i>
                                                        Déplacer vers le haut
                                                    </DropdownItem>
                                                ) : ("")
                                            }
                                            { 
                                                (this.canMoveDown(step, stepIndex, nbSteps)) ?
                                                (
                                                    <DropdownItem href="#pablo" onClick={e => this.moveDown(e, step.id)}>
                                                        <i className="fas fa-chevron-down"></i>
                                                        Déplacer vers le bas
                                                    </DropdownItem>
                                                ) : ("")
                                            }
                                            { 
                                                /*
                                                (this.canDelete(step, stepIndex, nbSteps)) ?
                                                (
                                                    <div>
                                                    <div className="dropdown-divider"></div>
                                                    <DropdownItem href="#pablo" onClick={e => {
                                                        e.preventDefault()
                                                        this.setState({stepToDelete: step})
                                                        this.toggleDeleteModal(e)
                                                    }}>
                                                        <i className="fas fa-trash-alt"></i>
                                                        Supprimer
                                                    </DropdownItem>
                                                    </div>
                                                ) : ("")*/
                                            }
                                            
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                ) : ("")
                            }
                        </div>
                        <h6 className="text-sm mt-1 mb-0">
                            {description}
                        </h6>
                        <div className="mt-3">
                                {
                                    (step.driver_start_date != null && step.type.code != "MISSION_STEP_TYPE_GET_TRUCK") ?
                                    (
                                        <Row>
                                            <span className="h4 font-weight-bold mb-0">
                                                <i className={Resources.start_logo}>&nbsp;</i>
                                            </span>
                                            {step.driver_start_time_as_string} 
                                        </Row>
                                    ) : (<></>)
                                }
                                {
                                    (step.driver_arrived_date != null && step.type.code != "MISSION_STEP_TYPE_GET_TRUCK") ?
                                    (
                                        <Row>
                                            <span className="h4 font-weight-bold mb-0">
                                                <i className={Resources.arrived_logo}>&nbsp;</i>
                                            </span>
                                            {step.driver_arrived_time_as_string} 
                                        </Row>
                                    ) : (<></>)
                                }
                                {
                                    (step.driver_end_date != null  && step.type.code != "MISSION_STEP_TYPE_PARK_TRUCK") ?
                                    (
                                        <Row>
                                            <span className="h4 font-weight-bold mb-0">
                                                <i className={Resources.stop_logo}>&nbsp;</i>
                                            </span>
                                            {step.driver_end_time_as_string} 
                                        </Row>
                                    ) : (<></>)
                                }
                                {
                                    (step.driver_end_comment != null && step.type.code != "MISSION_STEP_TYPE_GET_TRUCK") ?
                                    (
                                        <>
                                        <Row>
                                            <span className="h4 font-weight-bold mb-0 mt-2">
                                                <i className={Resources.driver_logo}>&nbsp;</i>
                                                Commentaire du chauffeur
                                            </span>
                                            
                                        </Row>
										<Row>
										{step.driver_end_comment} 
										</Row>
										</>
                                    ) : (<></>)
                                }
                                {
                                    (step.driver_pictures != null && step.driver_pictures.length > 0 && step.type.code != "MISSION_STEP_TYPE_GET_TRUCK") ?
                                    (
                                        
                                        <>
                                        <Row>
                                            <span className="h4 font-weight-bold mb-0 mt-2">
                                                <i className={Resources.proof_logo}>&nbsp;</i>
                                                Image(s) prise par le chauffeur ({step.driver_pictures.length})
                                            </span>
                                        </Row>
                                        <Gallery
                                        images={images}
                                        enableLightbox={true}
                                        enableImageSelection={false}/>
                                        </>
                                    ) : (<></>)
                                }
                                
                        </div>
                    </div>
                </div>
            })
        }
        return <>
            <Card>
                
                <CardBody>

                    <CardTitle>
                        <Row className="align-items-center">
                            <Col xs="8">
                            <h3 className="mb-0">Détail des étapes de la mission</h3>
                            </Col>
                        </Row>
                    </CardTitle>
                    <div
                        className="timeline timeline-one-side"
                        data-timeline-axis-style="dashed"
                        data-timeline-content="axis"
                    >
                        {timelineDatas}
                    </div>
                </CardBody>
            </Card>
        </>
    }

    renderShipper()
    {
        var logoColor = "primary"
        var companyName = "XXXXXXXXXXXX";
        var shipperUserMobilePhoneLabel = "06XXXXXXXX XXXXXXXXXXXXXX";
        var mobilePhoneNumber = null

        if (this.state.shipper != null)
        {
            companyName = this.state.shipper.company_name;
        }

        if (this.state.shipper_user != null)
        {
            mobilePhoneNumber = this.mobilePhoneNumber(this.state.shipper_user.mobile_phone_number) ? this.state.shipper_user.mobile_phone_number : null
            shipperUserMobilePhoneLabel = this.state.shipper_user.mobile_phone_number + " / " + this.state.shipper_user.summary
        }

        return <>
            <Card className="card-stats">
                <CardBody>
                    <Row>
                    <Col xs="10">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                        Expéditeur
                        </CardTitle>
                    </Col>

                    <Col xs="2" style={{position: "absolute", top: "6px", right: "0px", float: "right"}}>
                        <div className={"icon icon-shape btn-t2y-primary-button-color text-white rounded-circle shadow"} style={{width:"25px", height:"25px"}}>
                            <i className={Resources.shipper_logo} style={{fontSize:"16px"}} />
                        </div>
                    </Col>

                    <Col xs="12">
                        <span className="h2 font-weight-bold mb-0">
                            {companyName}
                        </span>
                    </Col>
                    <Col xs="12" className="mt-3 mb-0 text-sm">
                        <span className={ " mr-2"} style={{color: Resources.icon_primary_color}}>
                            <i className={Resources.mobile_phone_logo} /> 
                        </span>{" "}
                        <span className="text-nowrap">
                            <DefaultLink style={{textDecoration: "none"}} href={mobilePhoneNumber ? "tel:" + mobilePhoneNumber.replace(/[ -,.]/g, ""): null} small={true}>{shipperUserMobilePhoneLabel}</DefaultLink>
                        </span>
                    </Col>
                    </Row>
                    
                </CardBody>
            </Card>
        </>
    }

    renderComment()
    {
        return <>
            <CardTitle>
            <Row className="align-items-center">
                <Col xs="8">
                    <h3 className="mb-0">
                        {this.state.isTransporter ? "Commentaire du client" : "Description de la mission"}
                    </h3>
                </Col>
                {
                    (this.state.isTransporter || this.isDone()) ?
                    (
                        <></>
                    ) : (
                        <Col className="text-right" xs="4">
                            <DefaultButton
                            className="t2y-secondary-button small"
                            onClick={e => this.saveDescription()}
                            icon={Resources.button_edit_icon}
                            >
                            Modifier la description
                            </DefaultButton>
                        </Col>
                    )
                }
                
            </Row>
            </CardTitle>
            {
                 (this.state.isTransporter) ?
                 (
                     <>
                        {this.state.formDatas ? this.state.formDatas.description.value : "Pas de commentaire"}
                     </>
                 ) : (
                    <FormInput type="textarea" rows={3} name="description" manager={this} />
                 )
            }
            
            <hr/>
        </>
    }

    renderFreightDocuments()
    {
        return <>
            <CardTitle>
            <Row className="align-items-center">
                <Col xs="8">
                    <h3 className="mb-0">
                        Opération(s) de transport 
                    </h3>
                </Col>
                {
                    (this.state.isTransporter || this.isDone()) ?
                    (
                        <></>
                    ) : (
                        <Col className="text-right" xs="4">
                            <DefaultButton
                            className="t2y-secondary-button small"
                            onClick={e => this.setState({showFreightDocumentModal: true, transportOperation: null})}
                            icon={Resources.button_add_icon}
                            >
                            Ajouter une opération de transport
                            </DefaultButton>
                        </Col>
                    )
                }
            </Row>
            </CardTitle>
            {
                (this.state.mission) ?
                (
                    
                    <>
                        <Row>
                            <Col md={"12"/*this.state.steps.length > 0 ? "8" : "12"*/}>
                                <TransportOperationsList 
                                    readonly={this.state.isTransporter || this.isDone()}
                                    onEdit={(transportOperation) => this.setState({showFreightDocumentModal: true, transportOperation: transportOperation})} missionId={this.props.match.params.id} childRef={(elt) => this.transportOperationsList = elt} />
                            </Col>
                            {   
                                /*
                                (this.state.steps.length > 0) ?
                                (
                                    <Col md="4">
                                        <StepsAsRoadMap mission={this.state.mission} steps={this.state.steps} childRef={(elt) => this.stepsAsRoadMap = elt} ></StepsAsRoadMap>
                                    </Col>
                                )
                                : (<></>)*/
                            }
                            
                        </Row>
                    </>
                    /*
                    <TransportOperationsList 
                        onEdit={(transportOperation) => this.setState({showFreightDocumentModal: true, transportOperation: transportOperation})} mission={this.state.mission} childRef={(elt) => this.transportOperationsList = elt} />
                    */
                ) : (<></>)
            }
            <hr/>
        </>
    }

    renderMap()
    {
        return <>    
            <MapRouteComponent ref={this.mapComponent} width="500px" height="500px"></MapRouteComponent>
        </>
    }

    renderInvoice()
    {
        const invoice = this.state.invoice
        const amount_ht = !this.state.isTransporter ? invoice.shipper_amount_ht : invoice.transporter_amount_ht
        const additionalTimeHt = !this.state.isTransporter ? invoice.shipper_additional_time_ht : invoice.transporter_additional_time_ht
        const additionalDistanceHt = !this.state.isTransporter ? invoice.shipper_additional_distance_ht : invoice.transporter_additional_distance_ht
        const total_amount_ht = !this.state.isTransporter ? invoice.shipper_total_amount_ht : invoice.transporter_total_amount_ht

        const expected = invoice.package_distance + " km / " + invoice.package_minutes
        const realDistance = invoice.distance_quantity + " kilomètres" + (invoice.additional_distance_quantity > 0 ? " dont " + invoice.additional_distance_quantity + " en supplément": "")
        const realTime = invoice.minutes_quantity + (invoice.additional_minutes_quantity ? " dont " + invoice.additional_minutes_quantity + " en supplément":  "")

        const realDistanceAmount = additionalDistanceHt ? " + " + additionalDistanceHt + " supplément kilométrique " : ""
        const realTimeAmount = additionalTimeHt ?  " + " + additionalTimeHt + "  supplément horaire  " : ""
        let discountAmount = ""

        if(!this.state.isTransporter && invoice.shipper_incident_discount_ht && invoice.shipper_incident_discount_ht > 0) {
            discountAmount = `- ${invoice.shipper_incident_discount_ht} de remise`
        } else if(this.state.isTransporter && invoice.transporter_incident_discount_ht && invoice.transporter_incident_discount_ht > 0) {
            discountAmount = `- ${invoice.transporter_incident_discount_ht} de remise`
        }

        return <>
            <Card className="card-stats t2y-clickable" >
                <CardBody>
                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                        Facturation
                    </CardTitle>
                    {/*<Row className="mt-2">
                        <Col xs="4" sm="5" md="4" lg="4"><span className="h4 mb-0">Forfait</span></Col>
                        <Col xs="8" sm="7" md="8" lg="8">
                            <Row><Col xs="12">{expected}</Col></Row>
                        </Col>
                    </Row>*/}
                    <Row className="mt-2">
                        <Col xs="4" sm="5" md="4" lg="12" xl="4"><span className="h4 mb-0">Réalisé</span></Col>
                        <Col xs="8" sm="7" md="8" lg="12" xl="8">
                            <Row><Col xs="12">{realDistance}</Col></Row>
                            <Row><Col xs="12">{realTime}</Col></Row> 
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs="4" sm="5" md="4" lg="12" xl="4"><span className="h4 mb-0">Facturation</span></Col>
                        <Col xs="8" sm="7" md="8" lg="12" xl="8">
                            <Row><Col xs="12">{amount_ht} forfait {realDistanceAmount}{realTimeAmount}{discountAmount}</Col></Row>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs="4" sm="5" md="4" lg="4"><span className="h4 mb-0">Total</span> </Col>
                        <Col xs="8" sm="7" md="8" lg="8">
                            <Row><Col xs="12"><span className="h3 font-weight-bold mb-0">{total_amount_ht}</span></Col></Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    }

    renderEntities()
    {   
        const colSize = this.state.isTransporter && this.state.invoice ? 3 : 4;


        return <Row>
            <Col lg={colSize}>
                {this.renderTruck()}
            </Col>
            <Col lg={colSize}>
                {this.renderDriver()}
            </Col>
            {
                (this.state.isTransporter) ?
                (
                    <Col lg={colSize}>
                        {this.renderShipper()}
                    </Col>
                ) : (<></>)
            }
            {
                (this.state.invoice) ?
                (
                    <Col lg={colSize}>
                        {this.renderInvoice()}
                    </Col>
                ) : (null)
            }
        </Row>
    }

    renderOrganization()
    {
        return  <>
            <Card>
                <CardBody>
                    {/*this.renderComment()*/}
                    {this.renderFreightDocuments()}
                </CardBody>
            </Card>
        </>
    }

    renderTour()
    {
        return <> 
            <Row>
                <Col className="order-xl-1" xl="12">
                    {/*this.renderTimeLine()*/}
                    <MissionProgress childRef={(elt) => this.missionProgressRef = elt} 
                        steps={this.state.steps}
                        mission={this.state.mission} 
                        events={this.state.events} />
                </Col>
            </Row>
        </>
    }


    render() {
        if (this.state.mission == null)
        {
            if(this.state.findMissionError) {
                return <PageNotFound mainText="La mission demandée n'existe pas"/>
            }
            return <>
                <FormLoadingIndicator loading={true}></FormLoadingIndicator>
            </>
        } else if(this.state.mission?.v2Compatibility) {
            return <>
                <Report missionId={this.state.mission.missionV2Id}/>
            </>
        } else {
            const dateFormat = this.props.intl.formatMessage({id: "Default.day_month_year_format"})
            return (
                <>
                {this.renderParent()}
                {this.renderDeleteModal()}
                {
                    (this.state.showFreightDocumentModal) ? 
                    (this.renderFreightDocumentModal(this.state.transportOperation)) 
                    : (<></>)
                }

                {this.state.firstManualStep ? this.startFirstMissionStep() : <></>}
                <FormLoadingIndicator loading={this.state.loading}>
                <Container className="mt-0" fluid>
                    <Row>
                        <Col xs="12" md="6" lg="9">
                            <Row>
                                <Col xs="12">
                                    <Title4>
                                        <a href="#" style={{textDecoration:"underline"}}  className="t2y-clickable" onClick={(e) => WebsiteHelper.goTo(this, "/admin/orders?orderId=" + this.state.order.id)}>
                                            Revenir à la commande
                                        </a>
                                    </Title4>
                                    <Title3>
                                        {
                                            (this.state.mission.rating)?
                                            (
                                                <Rating note={parseFloat(this.state.mission.rating) / 2.0} style={{marginTop:"-10px"}}>
                                                    <>{this.state.mission ? "Mission du " + this.state.mission.date_as_string : ""}&nbsp;</>
                                                </Rating>
                                            ) : (<>
                                                {this.state.mission ? "Mission du " + this.state.mission.date_as_string : ""}
                                            </>)
                                        }
                                    </Title3>
                                </Col>
                                {
                                    (this.state.mission.end_date) ?
                                    (
                                        <Col xs="12">
                                            <> (Terminée à  {this.state.mission.end_time_as_string})</>
                                        </Col>
                                    ) : (
                                        (this.state.order.arrival_time) ?
                                        (
                                            <Col xs="12">
                                                <DefaultLabel icon={Resources.time_logo}>{StringHelper.translate(this, "Orders.line_expected_arrival_time", null, {date: this.state.order.arrival_time})}</DefaultLabel>
                                            </Col>

                                        ) : (<></>)
                                    )
                                }
                            </Row>
                        </Col>
                        {/*
                            (this.isStarted()) ?
                            (
                                <Col xs="12" md="6" lg="3">
                                    <Card className="card-stats">
                                        <CardBody className="t2y-clickable" onClick={() => {this.downloadMissionReport()}}>
                                            <Row>
                                            <Col xs="10">
                                                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                Télécharger le compte-rendu
                                                </CardTitle>
                                            </Col>
                                            <Col xs="2" style={{position:"absolute", top:"8px", right:"2%"}}>
                                                <DefaultImage src={Resources.downloaded_file_logo} style={{fontSize:"30px"}} />
                                            </Col>
                                            </Row>
                                        </CardBody>
                                        
                                    </Card>
                                </Col>
                            ) : (<></>)
                        */}
                    </Row>
                    {this.renderEntities()}
                    <Row className="justify-content-center mb-5">
                        <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                            <Button className={classnames({ active: this.state.currentTab === "organization" }) + " tabBtn"} color="secondary"
                                onClick={(e) => this.setState({currentTab: "organization"})}>
                                Planification
                            </Button>
                            <Button className={classnames({ active: this.state.currentTab === "tour", disable: this.isStarted() == false }) + " tabBtn"} color={"secondary"}
                                onClick={(e) => {if (this.isStarted()) { this.setState({currentTab: "tour"})}}}>
                                Déroulement
                            </Button>
                        </ButtonGroup>
                    </Row>
                    {
                        (this.state.currentTab === "organization") ?
                        (
                            /* Affichage du commentaire et des opérations de transport */
                            this.renderOrganization()
                        ) : 
                        (
                            this.renderTour()
                        )
                    }

                    
                    <Row>
                        <Col xs="12" className="text-center">
                            <DefaultLink onClick={(e) => WebsiteHelper.goTo(this, "/admin/orders?orderId=" + this.state.order.id)}>
                            Revenir à la commande
                            </DefaultLink>
                        </Col>
                    </Row>


                </Container>
                </FormLoadingIndicator>
                </>
            )
        }
    }
}


export default injectIntl(Mission)
