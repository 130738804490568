const AddressMessages = {
    "address.transporter_delete_confirmation_title": "Confirmation",
    "address.transporter_delete_confirmation_message": "Confirmez-vous la suppression de ce lieu de stationnement ?",
    "address.shipper_delete_confirmation_title": "Confirmation",
    "address.shipper_delete_confirmation_message": "Confirmez-vous la suppression de ce lieu de chargement ?",
    "address.transporter_save_confirmation_title": "Confirmation",    
    "address.transporter_save_confirmation_message": "Les données du lieu de stationnement ont été enregistrées avec succès",
    "address.shipper_save_confirmation_title": "Confirmation",    
    "address.shipper_save_confirmation_message": "Les données du lieu de chargement ont été enregistrées avec succès"
}

export default AddressMessages;