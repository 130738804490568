
import React from "react";
import PropTypes from 'prop-types'
import {
   Row, Col,
 } from "reactstrap";
import { injectIntl} from 'react-intl';

import StringHelper from "../../../helpers/StringHelper";
import DefaultButton from "../common/defaultButton";
import FormValidationComponent from "../../pages/components/FormValidationComponent";

class FormFooter extends FormValidationComponent
{
    constructor(props)
    {
        super(props)
        this.state = {        
        }
    }

    getStateCode()
    {
        if (this.props.instance && this.props.instance.state && this.props.instance.state.code)
        {
            return this.props.instance.state.code
        }
        else
        {
            return this.props.instance && this.props.instance.state_code ? this.props.instance.state_code : ""; 
        }
    }

    isRemoved()
    {
        return this.getStateCode() === "STATE_REMOVED";
    }

    showSubmitButton()
    {
        return typeof this.props.disableSubmitButton === "undefined" || this.props.disableSubmitButton == false;
    }

    render() {
      
      return (
        <>
        {this.renderParent()}
        <Row className="align-items-center">
            <Col className="text-right" xs="12">
                {
                    (this.isRemoved() === false && this.showSubmitButton()) ?
                    (
                        <>
                        <DefaultButton type="submit" 
                            className="t2y-secondary-button small" 
                            icon="ni ni-check-bold">
                                {StringHelper.translate(this, "Default.save")}
                        </DefaultButton>
                        </>
                    ) : ("")
                }
            </Col>
        </Row>
        </>
      );
    }
}

FormFooter.propTypes = {
    instance: PropTypes.any,
    disableSubmitButton: PropTypes.bool
}

export default injectIntl(FormFooter);
