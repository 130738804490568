
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
	Container,
	Input,
	Button,
	Row,
	Col,
	Form,
	ButtonGroup,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	TabContent,
	TabPane

  } from "reactstrap";
import BusinessService from "../../../proxies/BusinessService";
import GeocodingAndNavigationService from "../../../proxies/GeocodingAndNavigationService";
import FormLoadingIndicator from "../components/FormLoadingIndicator";
import FormValidationComponent from "../components/FormValidationComponent";
import FormInput from "../components/FormInput"
import FormOpenTimeInput from "../components/form/FormOpenTimeInput"
import FormHourInput from '../components/form/FormHourInput'
import FormCivilityInput from "../components/form/FormCivilityInput"
import FormCountryInput from "../components/form/FormCountryInput";
import FormCollaboratorsInput from "../components/form/FormCollaboratorsInput";
import { injectIntl , FormattedMessage } from 'react-intl';
import FormAutocompleteAddress from "../components/form/FormAutocompleteAddress";
import moment from 'moment';
import FormHeader from "../components/form/FormHeader";
import FormAddress from "../components/form/FormAddress";
import FormGroupInput from "../components/form/FormGroupInput";
import DefaultButton from "../../components/common/defaultButton";
import DefaultInput from "../../components/common/defaultInput";
import CivilityInput from "../../components/common/civilityInput";
import FormLegend from "../../components/form/formLegend";
import CollaboratorsInput from "../../components/business/collaboratorsInput";
import { add } from "date-fns";
import SecurityService from "../../../proxies/SecurityService";
import StringHelper from "../../../helpers/StringHelper";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import Resources from "../../../resources";
import FormFooter from "../../components/form/formFooter";

class Address extends FormValidationComponent {

	constructor(props)
	{
		super(props)
		this.securityService = new SecurityService();
		this.businessService = new BusinessService();
		this.geocodingAndNavigationService = new GeocodingAndNavigationService();
		this.isShipper = this.securityService.isShipper();

		this.validationRules = {
			name : {
				required : true,
				requiredMessage : "La désignation de l'adresse est obligatoire"
			},
			street : {
				required :true,
				requiredMessage : "L'adresse est obligatoire"
			},
			zip_code : {
				required: true,
				requiredMessage : "Le code postal est obligatoire"
			},
			city : {
				required: true,
				requiredMessage : "La ville est obligatoire"
			},
			country_code: {
				required: true,
				requiredMessage : "Le pays est obligatoire"
			},
			contact_civility_code : {
				required: true,
				requiredMessage : "La civilité du contact est obligatoire"
			},
			contact_first_name : {
				required: true,
				requiredMessage : "Le prénom du contact est obligatoire"
			},
			contact_last_name : {
				required: true,
				requiredMessage : "Le nom de famille du contact est obligatoire"
			},
			contact_email: {
				required: true,
				requiredMessage : "L'email du contact est obligatoire",
				rules : [
					{ 
					  rule : this.email.bind(this),
					  ruleMessage : this.translateMessage("Default.invalid_email")
					}
				]
			},
			contact_mobile_phone_number : {
				required: true,
				requiredMessage : "La numéro de téléphone mobile du contact est obligatoire",
				rules : [
					{ 
					  rule : this.mobilePhoneNumber.bind(this),
					  ruleMessage : this.translateMessage("Default.invalid_mobile_phone_number")
					}
				]
			}
		}

		var formDatas = {
			id : { value : null},
			country_code : { value : this.translateMessage("Default.country_code") },
		}

		var days = [ "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
		var times = ["_morning_start_time", "_morning_end_time", "_afternoon_start_time", "_afternoon_end_time"];
		days.forEach(day => {
			times.forEach(suffixe => {
				var field = day + suffixe;
				formDatas[field] = { value : ''};
			});
		});


		this.state = {
			currentTabIndex: 1,
			selectionMode: "global",
			address : {},
			formDatas : formDatas,
			addressTypeIsChecked:  false,
			exists : false,
			loading : false,
		}

	}

	componentWillMount()
	{
		if (!this.props.match.params.id == false)
		{
			this.businessService.getAddress(this, this.props.match.params.id, this.showAddress.bind(this));
		}
	}

	componentDidMount()
	{
	}

	toggleTabs(e, index, mode) {
		e.preventDefault();
		this.setState({
			selectionMode: mode,
			currentTabIndex: index
		});
	}


	showAddress(response)
	{
		if (response.instance !== null)
		{
			let address = response.instance;
			this.addressNameInputRef.setValue(address.name)
			this.formAddressRef.setValue(address)
			this.collaboratorsInputRef.setValues(address.users.map(u => { return {id: u.id, name: u.summary}}))
			
			// Récupération des donées d'adresse
			let formDatas = {
				id : { value : address.id},
				contact_civility_code : {value: address.contact_civility_code},
				contact_first_name : {value: address.contact_first_name},
				contact_last_name : {value: address.contact_last_name},
				contact_email : {value: address.contact_email},
				contact_mobile_phone_number : {value: address.contact_mobile_phone_number},
			}

			formDatas = this.geocodingAndNavigationService.setAddressFields(this, formDatas, address)

			var days = [ "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
			var times = ["_morning_start_time", "_morning_end_time", "_afternoon_start_time", "_afternoon_end_time"];
			days.forEach(day => {
				times.forEach(suffixe => {
					var field = day + suffixe;
					formDatas[field] = { value : address[field] ? address[field].substring(0, 5) : null};
				});
			});
			
			this.setState({
				instance: address,
				id : address.id,
				addressTypeIsChecked:  address.type_code == "ADDRESS_TYPE_SUBCONTRACTOR",         
				formDatas : formDatas, 
				exists : true})
		}
	}

	addressTypeChanged(e)
	{
		this.setState({
			addressTypeIsChecked:  !this.state.addressTypeIsChecked
		})
	}

	saveAddress(dtoRequest)
	{
		const confirmMessage = this.props.intl.formatMessage({id: "Default.address_successfully_registered"});
		const warningMessage = this.props.intl.formatMessage({id: "Default.address_registered_with_warning"});
		
		if (this.notEmptyValue(dtoRequest.id))
		{
			this.businessService.saveAddress(this, dtoRequest, (response) => {
				if (response.warnings && response.warnings.length > 0)
				{
					this.showWarningModalNotification("Confirmation", warningMessage);
				}
				else
				{
					// L'enregistrement s'est bien passée
					//this.showSuccessModalNotification("Confirmation", confirmMessage);
					WebsiteHelper.goTo(this, "/admin/addresses?mode=success")
				}
			})
		}
		else
		{
			this.businessService.createAddress(this, dtoRequest, (response) => {
				// Mise à jour de l'identifiant à partir de la réponse
				this.setState({"id" : response.id})
				
				if (response.warnings && response.warnings.length > 0)
				{
					this.showWarningModalNotification("Confirmation", warningMessage);
				}
				else
				{
					// L'enregistrement s'est bien passée
					//this.showSuccessModalNotification("Confirmation", confirmMessage);
					WebsiteHelper.goTo(this, "/admin/addresses?mode=success")
				}
			})
		}            
	}

	onSubmitForm(e)
	{
		e.preventDefault();
		var formIsValid = this.htmlFormIsValid(e);
		if (formIsValid)
		{
			var dtoRequest = {
				id : this.getHtmlFormControlValue("id"),
				type_code : this.state.addressTypeIsChecked ? "ADDRESS_TYPE_SUBCONTRACTOR": "ADDRESS_TYPE_INTERNAL",
				name : this.getHtmlFormControlValue("name"),
				users : this.collaboratorsInputRef.getValues().map(x => {return x.id}),
				contact_civility_code : this.state.addressTypeIsChecked ? this.getHtmlFormControlValue("contact_civility_code") : null,
				contact_first_name : this.getHtmlFormControlValue("contact_first_name"),
				contact_last_name : this.getHtmlFormControlValue("contact_last_name"),
				contact_email : this.getHtmlFormControlValue("contact_email"),
				contact_mobile_phone_number : this.cleanPhoneNumber(this.getHtmlFormControlValue("contact_mobile_phone_number")),
			}

			var days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
			var times = ["_morning_start_time", "_morning_end_time", "_afternoon_start_time", "_afternoon_end_time"];
			days.forEach(day => {
				times.forEach(suffixe => {
					var field = day + suffixe;
					dtoRequest[field] = this.getHtmlFormControlValue(field);
				});
			});

			// Les données de l'adresse
			this.geocodingAndNavigationService.getAddressFields(this, dtoRequest, (dtoRequestWithAddressFields) => {
					this.saveAddress(dtoRequestWithAddressFields)
				}
			)            
		}
	}

	onDeleteAction(event)
	{
		this.businessService.deleteAddress(this, this.state.id, (response) => {
			this.updateState(response)
			this.showSuccessModalNotification("Confirmation", "L'adresse a été supprimée avec succès")
		});
	}

	onActiveAction(event)
	{
		this.businessService.activeAddress(this, this.state.id, (response) => {
			this.updateState(response)
			this.showSuccessModalNotification("Confirmation", "L'adresse a été ré-activée avec succès")
		});
	}

	buildKey(day, suffix) {
		const key = day + suffix;
		return key;
	}

	renderOpenTimeLine(day, dayLabel) {

		return <div style={{ width: "100%", padding: 15, display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
			<div style={{ width: "100%", display: "flex", flexDirection: "row", minHeight: 50, justifyContent: "space-evenly" }}>
				<div style={{ display: "flex", alignItems: "center", minWidth: 120, padding: 20 }}>
					<strong>{dayLabel}</strong>
				</div>

				<div style={{ display: "flex", flexDirection: "row" }}>
					<div style={{ display: "flex", alignItems: "center", padding: 20 }}>de</div>
					<div style={{ display: "flex", alignItems: "center" }}>
						<FormHourInput 
							manager={this}
							name={this.buildKey(day, "_morning_start_time")} 
							autoComplete="OFF" 
							showSecond={false} 
							minuteStep={15}
							defaultValue=""
						/>
					</div>
					<div style={{ display: "flex", alignItems: "center", padding: 20 }}>à</div>
						<div style={{ display: "flex", alignItems: "center" }}>
							<FormHourInput 
								manager={this}
								name={this.buildKey(day, "_morning_end_time")} 
								autoComplete="OFF" 
								showSecond={false} 
								minuteStep={15}
							/>
						</div>

						<div style={{ display: "flex", flexDirection: "row" }}>
							<div style={{ display: "flex", alignItems: "center", padding: 20 }}>de</div>
							<div style={{ display: "flex", alignItems: "center" }}>
								<FormHourInput 
									manager={this}
									name={this.buildKey(day, "_afternoon_start_time")} 
									autoComplete="OFF" 
									showSecond={false} 
									minuteStep={15}
								/>
							</div>
							<div style={{ display: "flex", alignItems: "center", padding: 20 }}>à</div>
							<div style={{ display: "flex", alignItems: "center" }}>
								<FormHourInput 
									manager={this}
									name={this.buildKey(day, "_afternoon_end_time")} 
									autoComplete="OFF" 
									showSecond={false} 
									minuteStep={15}
								/>
							</div>
						</div>
				</div>
			</div>
		</div>
		return <Row className="mb-2">
			<Col xl="2">
				<strong>
					{dayLabel}
				</strong>
			</Col>
		<Col xl="10">
			<Row>
				<Col xs="12" md="6" className="mb-1">
					<Row>
						<Col>De</Col>
						<Col xs="4">
						<FormHourInput 
							manager={this}
							name={this.buildKey(day, "_morning_start_time")} 
							autoComplete="OFF" 
							className="form-control" 
							showSecond={false} 
							minuteStep={15}
							defaultValue=""
						/>
						</Col>
						<Col>à</Col>
						<Col xs="4">
							<FormHourInput 
								manager={this}
								name={this.buildKey(day, "_morning_end_time")} 
								autoComplete="OFF" 
								className="form-control" 
								showSecond={false} 
								minuteStep={15}
							/>

						</Col>
					</Row>
				</Col>
				<Col xs="12" md="6">
					<Row>
						<Col>De</Col>
						<Col xs="4">
							<FormHourInput 
								manager={this}
								name={this.buildKey(day, "_afternoon_start_time")} 
								autoComplete="OFF" 
								className="form-control" 
								showSecond={false} 
								minuteStep={15}
							/>

						</Col>
						<Col>à</Col>
						<Col xs="4">
							<FormHourInput 
								manager={this}
								name={this.buildKey(day, "_afternoon_end_time")} 
								autoComplete="OFF" 
								className="form-control" 
								showSecond={false} 
								minuteStep={15}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
		
	</Row>
	}

	renderHours()
	{
		return <div className="pl-lg-4">
			<h6 className="heading-small text-muted mb-4">
				Horaires
			</h6>
			
			{this.renderOpenTimeLine("monday", this.translateMessage("Default.monday"))}
			{this.renderOpenTimeLine("tuesday", this.translateMessage("Default.tuesday"))}
			{this.renderOpenTimeLine("wednesday", this.translateMessage("Default.wednesday"))}
			{this.renderOpenTimeLine("thursday", this.translateMessage("Default.thursday"))}
			{this.renderOpenTimeLine("friday", this.translateMessage("Default.friday"))}
			{this.renderOpenTimeLine("saturday", this.translateMessage("Default.saturday"))}
			{this.renderOpenTimeLine("sunday", this.translateMessage("Default.sunday"))}
		</div>
	}

	renderGlobal()
	{
		var formDatas = this.state.formDatas;

		return <>
			<Row>
				<Col lg="6">
				{
				(this.isShipper && (!this.state.instance || this.state.instance.type.code !== "ADDRESS_TYPE_BILLING")) ?
				(
					<Row className="justify-content-center pl-lg-4">
								<Col lg="4">
									Interne
								</Col>
								<Col>
									<label className="custom-toggle custom-toggle-success">
										<input checked={ this.state.addressTypeIsChecked ? "checked" : ""} type="checkbox" onChange={this.addressTypeChanged.bind(this)} />
										<span className="custom-toggle-slider primary rounded-circle" />
									</label>
								</Col>
								<Col>
									Sous-Traitant
								</Col>
					</Row>
				)
				:
				(
					<span />
				)
			}
					<div className="pl-lg-4">
						<h6 className="heading-small text-muted mb-4">
							Désignation
						</h6>
						<Row>
							<Col lg="12">
								<FormGroupInput htmlFor="name" intlId={"Default.address_name"}>
									<DefaultInput childRef={elt => this.addressNameInputRef = elt} required={true} name='name' manager={this} />
								</FormGroupInput>
							</Col>
						</Row>
						<h6 className="heading-small text-muted mb-4">
							Contact
						</h6>
						<Row>
							<Col lg="12">
						
					{
						(this.state.addressTypeIsChecked) ? 
						(
							<Row>
								<Col>
									<Row>
										<Col lg="6">
											<FormGroupInput 
												intlId={"user.civility"} 
												htmlFor={"contact_civility_code"}
												type="multiline">
												<CivilityInput childRef={elt => this.contactCivilityInputRef = elt} manager={this} required={true} id="contact_civility_code" value={formDatas.contact_civility_code ? formDatas.contact_civility_code.value : null}></CivilityInput>
											</FormGroupInput>
										</Col>
									</Row>
									<Row>
										<Col lg="6">
											<FormGroupInput intlId={"user.first_name"} htmlFor="contact_first_name" type="multiline">
												<DefaultInput required={true} childRef={(elt) => this.contactFirstNameInputRef = elt}  name='contact_first_name' value={formDatas.contact_first_name ? formDatas.contact_first_name.value : null} manager={this} />
											</FormGroupInput>
										</Col>
										<Col lg="6">
											<FormGroupInput intlId={"user.last_name"} htmlFor="contact_last_name" type="multiline">
												<DefaultInput required={true} childRef={(elt) => this.contactLastNameInputRef = elt}  name='contact_last_name' manager={this} value={formDatas.contact_last_name ? formDatas.contact_last_name.value : null} />
											</FormGroupInput>
										</Col>
									</Row>
									<Row>
										<Col lg="6">
											<FormGroupInput intlId={"user.email"} htmlFor="contact_email" type="multiline">
												<DefaultInput childRef={(elt) => this.contactEmailInputRef = elt}  required={true} name='contact_email' manager={this} value={formDatas.contact_email ? formDatas.contact_email.value : null} />
											</FormGroupInput>
										</Col>
										<Col lg="6">
											<FormGroupInput intlId={"user.mobile_phone_number"} htmlFor="contact_mobile_phone_number" type="multiline">  
												<DefaultInput childRef={(elt) => this.contactMobilePhoneNumberInputRef = elt} required={true} name='contact_mobile_phone_number' manager={this} value={formDatas.contact_mobile_phone_number ? formDatas.contact_mobile_phone_number.value : null} />
											</FormGroupInput>
										</Col>
									</Row>
								</Col>
							</Row>
							
						)
						:
						(   
							<Row>
								<Col lg="12">
									<FormGroupInput htmlFor="input-civility" label={"Responsable(s)"}>
									<CollaboratorsInput multiple={true} value={this.getFormControlValue("collaborators")} childRef={(elt) => this.collaboratorsInputRef = elt}  manager={this} name="collaborators"></CollaboratorsInput>
									</FormGroupInput>
								</Col>
							</Row>
						)
					}
							</Col>
						
						</Row>
					</div>
				</Col>
				<Col lg="6">
					<h6 className="heading-small text-muted mb-4">
						Adresse
					</h6>
					<Row>
						<Col lg="12">
							<FormAddress creation={this.state.formDatas.id.value == null} childRef={elt => this.formAddressRef = elt} manager={this} suggestions={true} addressIsGeocoded={this.state.addressIsGeocoded} addressAlertMessage={this.state.addressAlertMessage} />
						</Col>
					</Row>
				</Col>
			</Row>
								

		</>
	}

	render() {
		return (
			<>
				{this.renderParent()}
				<Container className="mt--4" fluid>
				<Form className="needs-validation" noValidate onSubmit={e => this.onSubmitForm(e)} >
					<Input type="hidden" id="id" name="id" value={this.state.id} />
					<Row>
						<Col className="order-xl-1" xl="12">
						<FormLoadingIndicator loading={this.state.loading}>
						<Card>
							<CardHeader>
								<FormHeader 
										instance={this.state.instance} 
										breadCrumb={true}
										breadCrumbStyle={null}
										breadCrumbTitle1={"Default.breadcrumb_" + (this.securityService.isTransporter() ? "transporter" : "shipper") + "_addresses_title"}
										breadCrumbTitle1Click={() => WebsiteHelper.goTo(this, "/admin/addresses")}
										breadCrumbTitle2={this.state.instance ? this.state.instance.name : ""}
										allowDeleteButton={false}
										allowActiveButton={true} 
										disableSubmitButton={true} 
										onActiveAction={this.onActiveAction.bind(this)}
										customActionButtons={[
											{
												generate: true,
												targetId: "btnDeleteAddress",
												buttonIcon: Resources.button_delete_icon,
												buttonText: StringHelper.translate(this, "Default.delete"), 
												buttonColor: "warning",
												modalTitle : StringHelper.translate(this, "address." + (this.securityService.isTransporter() ? "transporter" : "shipper") + "_delete_confirmation_title"), 
												modalMessage : StringHelper.translate(this, "address." + (this.securityService.isTransporter() ? "transporter" : "shipper") + "_delete_confirmation_message"), 
												modalKoButtonText: StringHelper.translate(this, "Default.cancel"),
												modalOkButtonText: StringHelper.translate(this, "Default.delete"), 
												onAction: this.onDeleteAction.bind(this)
											}
										]}
								></FormHeader>
							</CardHeader>
							<CardBody>
								<FormLegend required={true}></FormLegend>
								{
									(this.isShipper) ?
									(   
										<>
										<Row className="justify-content-center">
											<ButtonGroup className="btn-group-toggle" data-toggle="buttons">
												<Button className={classnames({ active: this.state.selectionMode === "global" }) + " tabBtn"} color="secondary"
													onClick={(e) => this.toggleTabs(e, 1, 'global')}>
													Données générales
												</Button>
												<Button className={classnames({ active: this.state.selectionMode === "hours" }) + " tabBtn"} color="secondary"
													onClick={(e) => this.toggleTabs(e, 2, 'hours')}>
													Horaires
												</Button>
											</ButtonGroup>
										</Row>
										</>
									) : (<></>)
								}
								<hr/>
								<TabContent activeTab={"tabs" + this.state.currentTabIndex}>
									<TabPane tabId="tabs1">
										<Row>
											<Col lg="12">
												{this.renderGlobal()}
											</Col>
										</Row>
									</TabPane>
									<TabPane tabId="tabs2">
										<Row>
											<Col lg="12">
												{this.renderHours()}
											</Col>
										</Row>

									</TabPane>
								</TabContent>    
							</CardBody>
							<CardFooter>
								<FormFooter instance={this.state.instance}></FormFooter>
							</CardFooter>
						</Card>
						</FormLoadingIndicator>
						</Col>

						</Row>
					</Form>
				


				
				</Container>
			</>
		)
	}
}


export default injectIntl(Address)