
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    Col,
  } from "reactstrap";
import { Calendar } from 'react-date-range';
import DefaultImage from "../common/defaultImage";
import Config from "../../../config";

class TruckRepresentation extends React.Component {

    render() {
        if (!this.props.truck_id)
            return <></>

        const customClassName = "t2y-truck-representation " + (this.props.small ? " t2y-truck-representation-small" : "") + " " + this.props.className

        const url = Config.bndApi + "/truck/image/" + this.props.truck_id
        return <DefaultImage src={url} style={this.props.style} className={customClassName} />
    }
}

export default TruckRepresentation
