
import React from "react";
import classnames from "classnames";
import $ from "jquery";
import PropTypes from "prop-types";

// reactstrap components
import {
    Button, Row, ButtonGroup, Table, Pagination, PaginationItem, PaginationLink
  } from "reactstrap";
import { injectIntl , FormattedMessage } from 'react-intl';
import StringHelper from "../../../helpers/StringHelper";
import ProxyService from "../../../proxies/ProxyService";
import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator";

class DefaultPaginatedList extends React.Component {

    constructor(props)
    {
        super(props)
        this.proxyService = new ProxyService()

        this.state = {
            items: [],
            totalItems: null,
            page: this.props.page ? this.props.page : 0,
            itemsPerPage: this.props.itemsPerPage ? this.props.itemsPerPage : 20,
        }
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    componentWillMount() {
        if (this.props.datasource)
        {
            this.getItems(1)
        }
    }

    getItems(page)
    {
        const datasource = this.props.datasource;
        var url = this.props.datasource.url.replace(datasource.pageParameter, page - 1).replace(datasource.itemsPerPageParameter, this.state.itemsPerPage)

        this.setState({loading: true})
        this.proxyService.get(this, url, (response) => {
            this.setState({
                totalItems: response.total_items,
                items: response.list,
                loading: false,
                page: page
            })

            if (this.props.onLoaded)
            {
                this.props.onLoaded(response.list.length)
            }
        });
    }

    componentWillUnmount() {}

    renderDefaultNoResultComponent()
    {
        return <>
            Pas de résultat
        </>
    }

    renderListAsTable()
    {
        if (this.state.items && this.state.items.length == 0)
        {

            return this.props.noResultComponent ? this.props.noResultComponent : this.renderDefaultNoResultComponent()
        }

        return <>
            <Table className="align-items-center" responsive>
                <thead className="thead-light">
                    <tr>
                    {this.props.columns.map((column, index) => {
                        return <>
                            <th scope="col">{column.translationId ? StringHelper.translate(this, column.translationId) : column.name}</th>
                        </>
                    })} 
                    </tr>       
                </thead>
                <tbody>
                    {
                        this.state.items.map((item, index) => {
                            return <tr>
                                {
                                    this.props.columns.map((column, index) => {
                                        return <td>
                                            {column.value(item, index)}
                                        </td>
                                    })
                                }
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        </>
    }

    renderPagination()
    {
        const divideResult = this.state.totalItems / this.state.itemsPerPage 
        const nbButtons = Math.floor(divideResult) + ((divideResult - Math.floor(divideResult)) > 0 ? 1 : 0);
        const nbButtonsToShow = 10;

        if (nbButtons == 1)
        {
            return <></>
        }

        if (nbButtons == 0)
        {
            return <></>
        }
        else{
            var buttonIndexes = [];
            
            if (nbButtons > nbButtonsToShow)
            {

                var min = this.state.page - 5;
                if (min < 1)
                {
                    min = 1;
                }

                var max = this.state.page + 5;
                if (max > nbButtons)
                {
                    max = nbButtons;
                }

                if (min > 1)
                {
                    buttonIndexes.push(1);
                }
                if (min > 2)
                {
                    buttonIndexes.push("...");
                }

                // Premiers boutons
                for(var i = min; i <= max ; i++)
                {
                    buttonIndexes.push(i);
                }

                if (max < nbButtons - 1)
                {
                    buttonIndexes.push("...");
                }
                if (max < nbButtons)
                {
                    buttonIndexes.push(nbButtons);
                }
            }
            else
            {
                for(var i = 0; i < nbButtons; i++)
                {
                    buttonIndexes.push(i + 1);
                }    
            }

            
            return <nav aria-label="...">
                <Pagination  className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
                <PaginationItem className={""}>
                    <PaginationLink
                    href="#pablo"
                    onClick={e => this.getItems(1)}
                    tabIndex="-1"
                    >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {
                    buttonIndexes.map(index => {
                        return <>
                            <PaginationItem className={(index == this.state.page ? "active" : "") + " " + (index == "..." ? "disabled": "")}>
                                <PaginationLink href="#pablo" onClick={e => this.getItems(index)}>
                                {index}
                                </PaginationLink>
                            </PaginationItem>
                        </>
                    })
                }
                
                <PaginationItem className={""}>
                    <PaginationLink
                    href="#pablo"
                    onClick={e => this.getItems(nbButtons)}
                    >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
                </Pagination>
            </nav> 
        }
    }

    render() 
    {
        if (this.state.loading)
        {
            return <FormLoadingIndicator loading={true}></FormLoadingIndicator>
        }

        return <>
            {this.renderListAsTable()}
            {this.renderPagination()}
        </>
    }
}


DefaultPaginatedList.propTypes = {
    items: PropTypes.array,
    itemsPerPage: PropTypes.number,
    cache: PropTypes.bool,
    noResultComponent: PropTypes.any,
    columns: PropTypes.arrayOf({
        name: PropTypes.string,
        translationId: PropTypes.string,
        value: PropTypes.func
    }),
    datasource: PropTypes.instanceOf({
        mode: PropTypes.oneOf(["GET", "POST"]),
        url: PropTypes.url,
        pageParameter: PropTypes.string,
        itemsPerPageParameter: PropTypes.string
    })
};

export default injectIntl(DefaultPaginatedList)
