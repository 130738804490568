import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import Step from "../step"
import DefaultLabel from "../../common/defaultLabel";
import Title3 from "../../common/title3";
import Title2 from "../../common/title2";
import DefaultButton from "../../common/defaultButton";
import StringHelper from "../../../../helpers/StringHelper";
import WebsiteHelper from "../../../../helpers/WebsiteHelper";
import HeaderComponent from "./headerComponent";
import SecurityService from "../../../../proxies/SecurityService";

var moment = require('moment');
require('moment/locale/fr');

class CompanyConfirmationStep extends Step {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.securityService = new SecurityService();
        this.state = {
            loafing: false,
            billingAddresOperationalAnswer: false
        }
    }

    componentWillMount() {
        super.componentWillMount()
    }

    componentDidMount() {
        super.componentDidMount()
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }

        // Si la confirmation est en erreur alors on kill la session
        if (this.securityService.isTransporter() && (this.props.workflow.state.input.lc_exists == false || this.props.workflow.state.input.lc_active == false))
        {
            this.businessService.clearStorage();
        }
        else{
            if(this.state.billingAddresOperationalAnswer == false)
            {
                this.renderOperationalBillingAddressModal()

                this.securityService.me(null, (response) => {})
            }
        }
    }

    renderOperationalBillingAddressModal()
    {
        var question = StringHelper.translate(this, this.securityService.isTransporter() ? 
            "company.confirmation_step.transporter_billing_operational_address" : 
            "company.confirmation_step.shipper_billing_operational_address"
        )

        var component = <>
            {question}
        </>

        var options = {
            title: "",
            hideIcon: true,
            component: component,
            okButtonTitle: "Oui",
            okButtonCallback: null,
            cancelButtonTitle: "Non",
            cancelButtonCallback: () => {
                this.businessService.setBillingAddressNotOperational(this, (response) => {})
            }
        }

        this.showModalConfirmation(options)
    }

    isSearchReturnUrl(returnUrl)
    {
        return returnUrl && window.atob(returnUrl).indexOf("search") > -1
    }

    redirect()
    {
        const returnUrl = this.props.workflow.getReturnUrl();
        if(this.isSearchReturnUrl(returnUrl))
        {
            WebsiteHelper.goTo(this, window.atob(returnUrl).replace("/public/search-result/", "/admin/search-results/").replace("?id=", "?resultId="))
        }
        else
        {
            WebsiteHelper.goToHome(this) //this.submitStep(item, next)}
        }
    }

    getNextButtonPreviousMessage()
    {
        const returnUrl = this.props.workflow.getReturnUrl();
        if(this.isSearchReturnUrl(returnUrl))
        {
            return <></>
        }
        else
        {
            return <Row className="mb-3">
                <DefaultLabel>
                    <FormattedMessage id={
                        this.securityService.isTransporter() ?
                        "company.confirmation_step_transporter.success_message" : 
                        "company.confirmation_step_shipper.success_message"
                    }>
                    {this.decodeEntities}
                    </FormattedMessage>
                </DefaultLabel>
            </Row>
        }
    }

    getNextButtonLabel()
    {
        const returnUrl = this.props.workflow.getReturnUrl();
        if(this.isSearchReturnUrl(returnUrl))
        {
            return StringHelper.translate(this, "company.confirmation_ste_.shipper_return_to_search_results");
        }
        else
        {
            return StringHelper.translate(this, (this.securityService.isTransporter() ? 
                "company.confirmation_step_transporter.success_next":
                "company.confirmation_step_shipper.success_next"
                )
            )
        }
    }

    renderLcExistsAndActive()
    {
        return <>
            <HeaderComponent success={true} ></HeaderComponent>
            <Row> 
                <Col lg="12" md="12" className="order-lg-1 order-2">
                    <Row className="mb-3">
                        <Col>
                        <Title2 justify={true}>
                            <FormattedMessage id={
                                this.securityService.isTransporter() ?
                                    "company.confirmation_step_transporter.success_title":
                                    "company.confirmation_step_shipper.success_title"
                                }></FormattedMessage>
                        </Title2>
                        <Title3 justify={true}>
                            <FormattedMessage id={
                                this.securityService.isTransporter() ?
                                    "company.confirmation_step_transporter.success_subtitle": 
                                    "company.confirmation_step_shipper.success_subtitle"
                            }></FormattedMessage>
                        </Title3>
                        </Col>
                    </Row>
                    {this.getNextButtonPreviousMessage()}
                    <Row className="align-item-center justify-content-center" >
                        <DefaultButton    
                            type="button"
                            className="t2y-secondary-button"
                            key={"go_to_orders"}
                            onClick={(e) => this.redirect()}
                            label={this.getNextButtonLabel()}>
                        </DefaultButton>
                    </Row>
                </Col>
            </Row>
        </> 
    }

    renderLcExistsAndNotActive()
    {
        return this.renderLcNotExists()
    }


    renderLcNotExists()
    {
        return <>
            {this.renderParent()}
            <HeaderComponent error={true} ></HeaderComponent>
            <Row> 
                <Col lg="12" md="12" className="order-lg-1 order-2">
                    <Row className="mb-3">
                        <Col>
                        <Title2 justify={true}>
                            <FormattedMessage id="company.confirmation_step.no_licence_title"></FormattedMessage>
                        </Title2>
                        <Title3 justify={true}>
                            <FormattedMessage id="company.confirmation_step.no_licence_subtitle"></FormattedMessage>
                        </Title3>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <DefaultLabel>
                            <FormattedMessage id="company.confirmation_step.no_licence_message">
                            {this.decodeEntities}
                            </FormattedMessage>
                        </DefaultLabel>
                    </Row>
                </Col>

            </Row>
        </>
    }

    
    render() {
        
        return (
            <>  
                {this.renderParent()}
                <FormLoadingIndicator loading={this.state.loading} />
                {
                    (this.securityService.isTransporter()) ?
                    (
                        (this.props.workflow.state.input.lc_exists) ?
                        (
                            (this.props.workflow.state.input.lc_active) ?
                            (
                                this.renderLcExistsAndActive()

                            ) : (
                                this.renderLcExistsAndNotActive()
                            )
                        )
                        :
                        (
                            this.renderLcNotExists()
                        )
                    ) : (
                        this.renderLcExistsAndActive()
                    )
                }
            </>
        );
    }
}


export default injectIntl(CompanyConfirmationStep)

