import React from "react";
import PropTypes, { string } from 'prop-types'
import NotificationComponent from "./NotificationComponent"
import {
    Button,
    Row,
    Col,
    Modal,
    Alert,
    Fade
} from "reactstrap";
import NotificationAlert from 'react-notification-alert';
import $ from "jquery"
import { injectIntl , FormattedMessage } from 'react-intl';
import ArrayHelper from "../../../helpers/ArrayHelper";
import Title3 from "../../components/common/title3";

class FormValidationComponent extends NotificationComponent {

    constructor(props)
    {
        super(props)
        this.intl  = this.props.intl;
        this.validationRules = {}
    }

    buildTruckWarningComponent(response)
    {
        const completeTrucks = ArrayHelper.find(response.working_datas.trucks, (item) => { return item.complete == true});
        const notCompleteTrucks = ArrayHelper.find(response.working_datas.trucks, (item) => { return item.complete == false}); 

        response.warnings = ArrayHelper.find(response.warnings, (item) => { return item !== "TRUCK_COMPLETE_FLAG_CHANGED"}); 

        return <>
            <Row className="text-muted lead text-justify">
                <Col lg="12">
                    <div className="text-center" style={{fontSize:"100px", color:"#ff9966"}}>
                        <i className="fas fa-exclamation-triangle"></i>
                    </div>
                    <Title3 center={true}>Les données du conducteur ont été enregistrées avec succès.</Title3>
                </Col>
                {
                    (response.warnings.length > 0) ?
                    (
                        <Col lg="12">
                            <>{this.prepareResponseErrorComponent(response, "")}</>
                        </Col>
                    ) : (<></>)
                }
                <Col xs="12">
                {
                    (completeTrucks.length > 0) ?
                    (
                        <>
                            Les véhicules suivants sont désormais disponibles à la location:
                            <br/>
                            <ul>
                            {completeTrucks.map((truck, index) => {

                                return <><li>{truck.summary} - { truck.technical_label}</li></>   
                            })}
                            </ul>
                        </>
                    ) : (<></>)
                }
                {
                    (notCompleteTrucks.length > 0) ?
                    (
                        <>
                            Les véhicules suivants ne sont plus disponibles à la location car aucun conducteur ne leur est affecté :
                            <br/>
                            <ul>
                            {notCompleteTrucks.map((truck, index) => {

                                return <><li>{truck.summary} - { truck.technical_label}</li></>   
                            })}
                            </ul>
                        </>

                    ) : (<></>)
                }    
                </Col>
            </Row>
        </>
    }

    updateState(response)
    {
        var instance = this.state.instance ? this.state.instance : {};
        instance.state = response.state;
        
        instance.state_code = response.state_code;
        instance.state_label = response.state_label;
        instance.state_image_url = response.state_image_url;
        
        // Reprise de l'ancienne gestion du state
        if (!instance.state && instance.state_code)
        {
            instance.state = {code: instance.state_code, label: instance.state_label}
        }
        
        this.setState({instance :instance})
    }

    decodeEntities(...nodes) {
        return nodes
            .map(node => typeof node === 'string' ? 
                node.split('\n').map((text, index) => React.createElement("p", { key:index, dangerouslySetInnerHTML: { 
                    __html: String(text).replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot/g, '"')
                } })) : node)
            .reduce((nodes, node) => nodes.concat(node), []);
    }

    emptyValue(value = null)
    {
        return !this.notEmptyValue(value)
    }

    notEmptyValue(value = null)
    {
        if (value == null)
        {
            return false;
        }

        if (Array.isArray(value) && typeof value === "object")
        {
            return value.length > 0;
        }
        else
        {
            return (typeof value !== "undefined" && value !== null && value !== "" && value.trim() !== "");
        }
    }

    checkRegExp(value, regexp)
    {
        if (this.notEmptyValue(value))
        {
            return regexp.test(value);
        }
        return true;
    }

    emptyFloatValue(value)
    {
        if (value == null || value == "" || this.amount(value.trim()) == false)
            return true;
        
        const valAsFloat = parseFloat(value)
        if (typeof valAsFloat === "undefined" || valAsFloat == NaN)
        {
            return true;
        }
        else if (valAsFloat == 0)
        {
            return true
        }
        else {
            return false;
        }
    }

    amount(value)
    {
        return this.checkRegExp(value, /^\d+([\.,]\d{1,2})?$/);
    }

    homePhoneNumber(value)
    {
        return this.checkRegExp(value, /^(0[1234589]([. -]?[0-9]){8})$/);
    }

    mobilePhoneNumber(value)
    {
        return this.checkRegExp(value, /^(0[67]([. -]?[0-9]){8})$/);
    }

    cleanPhoneNumber(phoneNumber)
    {
        if (!phoneNumber)
        {
            return null;
        }
        else
        {
            return phoneNumber.replace(/[-. ]/g,'');
        }
    }

    siret(value)
    {
        if (value != null)
        {
            value = value.replace(/\D/g,'');
        }

        

        var isValid;
        if ( (value.length != 14) || (isNaN(value)) )
            isValid = false;
        else 
        {
            // Donc le SIRET est un numérique à 14 chiffres
            // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants
            // correspondent au numéro d'établissement
            // et enfin le dernier chiffre est une clef de LUHN. 
            var sum = 0;
            var tmp;
            for (var cpt = 0; cpt<value.length; cpt++) {
                if ((cpt % 2) == 0) { // Les positions impaires : 1er, 3è, 5è, etc... 
                tmp = value.charAt(cpt) * 2; // On le multiplie par 2
                if (tmp > 9) 
                    tmp -= 9;	// Si le résultat est supérieur à 9, on lui soustrait 9
                }
            else
                tmp = value.charAt(cpt);
                sum += parseInt(tmp);
            }
            if ((sum % 10) == 0)
                isValid = true; // Si la somme est un multiple de 10 alors le SIRET est valide 
            else
                isValid = false;
        }
        return isValid;
    }

    iban(value)
    {
        if (this.emptyValue(value))
        {
            return true;
        }

        value = value.replace(/ /g,'');

        let rearrange = value.substring(4, value.length) + value.substring(0, 4);
        let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');
        let alphaMap = {};
        let number = [];

        alphabet.forEach((value, index) => {
            alphaMap[value] = index + 10;
        });

        rearrange.split('').forEach((value, index) => {
            number[index] = alphaMap[value] || value;
        });

        return this.modulo(number.join('').toString(), 97) === 1;
    }
    
    modulo(aNumStr, aDiv)
    {
        var tmp = "";
        var i, r;
        for (i = 0; i < aNumStr.length; i++) {
            tmp += aNumStr.charAt(i);
            r = tmp % aDiv;
            tmp = r.toString();
        }
        return tmp / 1;
    }

    email(value)
    {
        return this.checkRegExp(value, /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);       
    }

    htmlFormControlExists(field)
    {
        return  document.getElementById(field) != null;
    }

    htmlFormControlHidden(field)
    {
        return $("#" + field).parents(".not-visible-and-exclude-from-validation").length > 0
        //return $("#" + field).is(":hidden");
    }

    getHtmlFormControlValue(field)
    {
        var element = document.getElementById(field);
        if (element != null)
        {
            var nodeName = element.nodeName;
            var value = null;
            switch(nodeName.toUpperCase())
            {
                case "INPUT":
                    value = element.value;
                    break;
                case "TEXTAREA":
                    value = element.value;
                    break;
                case "SELECT":
                    var selectedOptions = element.selectedOptions;
                    var selectedValues = [];
                    for (let i=0; i<selectedOptions.length; i++) {
                        if (this.notEmptyValue(selectedOptions[i].value))
                        {
                            selectedValues.push(selectedOptions[i].value)
                        }
                    }

                    value = selectedValues;
                    break;
            }

            return value;
        }
        else
        {
            return null;
        }
    }

    resetHtmlMessages(key)
    {
        let elements = [];
        // Suppression du message d'erreur
        let element = document.getElementById(key);

        if (element != null)
        {
            var parent = null;
            // Récupération du parent si il est explicitement mentionné
            var specificParent = element.getAttribute("error-parent-container");
            if (typeof specificParent !== "undefined" && specificParent != null)
            {
                parent = document.getElementById(specificParent);
                element = element.parentElement;
            } 
            else
            {
                parent = element.parentElement;
            }

            let errorId = key + "-invalid-message";
            let errorElement = document.getElementById(errorId);
            if (errorElement != null)
            {
                parent.removeChild(errorElement);
            }

            // Suppression du contour rouge autour du control
            // Gestion du controle select 2
            let select2 = parent.getElementsByClassName("select2-container")[0];
            if (select2 != null)
            {
                elements.push(select2)
                elements.push(element)
                elements.push(parent)
            }
            else
            {
                elements.push(element)
            }

            elements.forEach(e => { e.classList.remove( "is-invalid")});
        }
    }

    showHtmlError(key, message)
    {
        // Création du message d'erreur
        let errorId = key + "-invalid-message";
        let errorMessage = document.createElement('div');
        errorMessage.id = errorId
        errorMessage.className = "invalid-feedback"
        errorMessage.style = "display:block";
        errorMessage.innerHTML = message;

        // Ajout du message d'erreur
        var element = document.getElementById(key);
        if (element != null)
        {
            // Récupération du parent si il est explicitement mentionné
            var specificParent = element.getAttribute("error-parent-container");
            
            var parent = null;
            
            if (typeof specificParent !== "undefined" && specificParent != null)
            {
                parent = document.getElementById(specificParent);
                element = element.parentElement;
            } 
            else
            {
                parent = element.parentElement;
            }
            
            // Gestion du controle select 2
            let select2 = parent.getElementsByClassName("select2-container")[0];
            if (select2 != null)
            {
                element = select2;
            }

            // Ajout du message
            parent.insertBefore(errorMessage, element.nextSibling);

            // Ajout du contour rouge autour du control
            element.classList.remove( "is-invalid");
            element.classList.add("is-invalid");
            parent.classList.remove("is-invalid");
            parent.classList.add("is-invalid");
        }
    }

    getHtmlValidationRequiredMessage(key)
    {   
        if (typeof this.validationRules[key].requiredMessage !== null && this.validationRules[key].requiredMessage)
        {
            return this.validationRules[key].requiredMessage;
        }
        else
        {
            return this.translateMessage("Default.required_field")
        }
    }

    checkHtmlInput(key)
    {
        // Si le controle n'existe pas alors on considère qu'il est valide
        if (this.htmlFormControlExists(key) == false)
            return true;

        // Si le controle n'est pas visible ou si un des parents n'est pas visible on considère qu'il est valide
        if (this.htmlFormControlHidden(key))
            return true;


        // Récupération de la valeur
        var value = this.getHtmlFormControlValue(key)
        var isFieldValid = true;
        var message = null;
        
        if (this.checkRequiredField(key, value) == false)
        {
            isFieldValid = false;
            message = this.getHtmlValidationRequiredMessage(key);
        }
        else // Gestion des règles sur le champs
        {
            // Vérification des règles métier (email, regexp)
            var rules = (this.validationRules[key] && typeof this.validationRules[key].rules !== "undefined" ? this.validationRules[key].rules : [])
            rules.forEach(rule => {
                if (rule.rule(value) == false)
                {
                    isFieldValid = false;
                    message = !rule.ruleMessage ? this.translateMessage("Default.invalid_field") : rule.ruleMessage
                }
            });

            if (this.validationRules[key] && this.validationRules[key].linkTo)
            {
                this.validationRules[key].linkTo.forEach(fieldKey => {
                    this.checkHtmlInput(fieldKey)
                })
            }
        }

        this.resetHtmlMessages(key);
        if (isFieldValid == false)
        {
            this.showHtmlError(key, message)
        }

        return isFieldValid;
    }

    htmlInputChanged(e, field)
    {
        this.checkHtmlInput(field);
    }

    htmlFormIsValid()
    {
        var summary = this.htmlFormValidationSummary();
        return summary.formIsValid;
    }


    htmlFormValidationSummary()
    {
        var summary = {
            formIsValid : false,
            errors: []
        }

        var formIsValid = true;
        for(var key in this.validationRules)
        {               
            var inputIsValid = this.checkHtmlInput(key);
            if (inputIsValid == false)
            {
                formIsValid = false;
                summary.errors.push(key)
            }
        }

        summary.formIsValid = formIsValid;

        return summary;
    }
















    /**
     * Récupération de la valeur d'un champ
     * @param {*} field 
     */
    getFormControlValue(field)
    {
        var htmlValue = this.getHtmlFormControlValue(field);     
        var value = null;
        if (this.notEmptyValue(htmlValue))
        {
            value = htmlValue;
        }
        else
        {
            if (typeof this.state.formDatas !== "undefined" && typeof this.state.formDatas[field] !== "undefined")
            {
                value = this.state.formDatas[field].value;
            }
        }
        return value;
    }
    
    /**
     * Récupération de l'état d'un champs (required, invalid, valid, ...)
     * @param {*} field 
     */
    getFormControlValidationState(field)
    {
        if (typeof this.state.formDatas !== "undefined" && typeof this.state.formDatas[field] !== "undefined")
        {
            return this.state.formDatas[field].validationState;
        }

        return null;
    }

    /**
     * Méthode permettant de vérifier si un champs obligatoire possède bien une valeur
     * @param {*} key 
     */
    checkRequiredField(key, value)
    {
        var isRequired = (this.validationRules[key] && typeof this.validationRules[key].required !== "undefined" ? this.validationRules[key].required : false)
        
        if (isRequired)
        {
            return this.notEmptyValue(value);
        }
        else
        {
            return true;
        }
    }

    getValidationInvalidMessage(key)
    {
        if (typeof this.state.formDatas !== "undefined" && typeof this.state.formDatas[key] !== "undefined")
        {
            return this.state.formDatas[key].validationInvalidMessage;
        }
        else{
            return null;
        }
    }

    getValidationRequiredMessage(key)
    {
        if (typeof this.state.formDatas !== "undefined" && typeof this.state.formDatas[key] !== "undefined")
        {
            return this.state.formDatas[key].validationRequiredMessage;
        }
        else{
            return null;
        }
    }

    /**
     * Méthode permettant de vérifier les règles pour un champs donné
     */
    checkField(key, specificValue = null)
    {
        var fieldObject = {
            validationState : null,
            value : specificValue !== null ? specificValue : this.getFormControlValue(key),
            validationInvalidMessage : null,
            validationRequiredMessage : null
        }
        // Vérification des champs obligatoires
        var validRequiredValue =  this.checkRequiredField(key, fieldObject.value);
        if (validRequiredValue == false)
        {
            fieldObject.validationState = "required";
            fieldObject.validationRequiredMessage = ((typeof this.validationRules[key].requiredMessage !== "undefined" ? this.validationRules[key].requiredMessage : null))
        }
        else
        {
            // Vérification des règles métier (email, regexp)
            var rules = (this.validationRules[key] && typeof this.validationRules[key].rules !== "undefined" ? this.validationRules[key].rules : [])
            rules.forEach(rule => {
                if (rule.rule(fieldObject.value) == false)
                {
                    fieldObject.validationState = "invalid";
                    fieldObject.validationInvalidMessage = rule.ruleMessage;
                }
            });
        }
        return fieldObject;
    }


    validateForm(event, field)
    {
        if (!this.state.formDatas)
        {
            this.state.formDatas = {}
        }

        // Récupération de la valeur du champs
        var value = event.target.value;
        // Vérification des données du champs
        this.state.formDatas[field] = this.checkField(field, value);
        // Mise à jour du statut
        this.setState({formDatas : this.state.formDatas})
    }

    /**
     * Méthode permettant d'exécuter les règles de validation d'un formulaire 
     * @param {*} e 
     */
    formIsValid(e)
    {
        var formDatas = this.state.formDatas;
        var formIsValid = true;
        for(var key in this.validationRules)
        {   
            // Initialisation des valeur
            if (typeof formDatas[key] === "undefined")
            {
                formDatas[key] = {
                    validationState : null
                }
            }

            // Vérification des champs obligatoires
            formDatas[key] = this.checkField(key);
            if (formDatas[key].validationState !== null && formDatas[key].validationState !== "valid")
            {
                formIsValid = false;
            }
        }
        this.setState({formDatas : formDatas});        
        return formIsValid;

    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    errorMessage(message, title)
    {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text" style={{marginLeft:"10px"}}>
                  <span className="alert-title" data-notify="title">
                    <p>{title}</p>
                  </span>
                  <span data-notify="message" dangerouslySetInnerHTML={{__html: message}}>
                  </span>
                </div>
            ),
            type: "warning",
            icon: "ni ni-bell-55",
            autoDismiss: 15
          };
          this.refs.notificationAlert.notificationAlert(options);
    }

    warningMessage(message, warnings, title = "Confirmation")
    {
        let warningMessage = "";

        warningMessage += "<p>" + this.translateMessage("WARNING_MESSAGE") + "</p>";
        warningMessage += "<ul>";
        warnings.forEach(element => {
            warningMessage += "<li>" +  this.translateMessage(element) + "</li>";
        });
        warningMessage += "</ul>";
        

        let options = {
            place: "tc",
            message: (
                <div className="alert-text" style={{marginLeft:"25px"}}>
                  <span className="alert-title" data-notify="title">
                    <p>{title}</p>
                  </span>
                  <span data-notify="message">
                    <p>{message}</p>
                  </span>
                  <Alert color="warning">
                  <span data-notify="message" dangerouslySetInnerHTML={{__html: warningMessage}}>
                  </span>
                  </Alert>
                </div>
            ),
            type: "success",
            icon: "fas fa-info-circle",
            autoDismiss: false
          };
          this.refs.notificationAlert.notificationAlert(options);
    }

    confirmMessage(message, title = "Confirmation")
    {
        this.showSuccessModalNotification(title, message)
    }

    scrollToFirstError(domContainerId = 'html,body', delta = 200)
    {
        const items = $('.invalid-feedback');
        
        if (items && items.length > 0)
        {
            $(domContainerId).animate({ scrollTop: $(items[0]).offset().top - delta}, 1000);
        }
    }
    
    renderParent() {
        return (
            <>
            {super.renderParent()}
            <NotificationAlert ref="notificationAlert" />
            <Modal
                className="modal-dialog-centered modal-danger"
                isOpen={this.state.errorNotificationModal}
                toggle={() => this.toggleModal("errorNotificationModal")}
            >
                <div className="modal-header" contentClassName="bg-danger">
                    <h6
                    className="modal-title"
                    id="modal-title-notification"
                    >
                    {this.state.errorNotificationModalTitle}
                    </h6>
                    <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("errorNotificationModal")}
                    >
                    <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body" dangerouslySetInnerHTML={{__html: this.state.errorNotificationModalMessage}}>
                </div>
                <div className="modal-footer">
                    <Button
                    className="text-white ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() =>
                        this.toggleModal("errorNotificationModal")
                    }
                    >
                    Fermer
                    </Button>
                </div>
            </Modal>
            </>
        )
    }

  }
 
  export default FormValidationComponent;