/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect } from "react";
// reactstrap components
import {

  Container,
  Row,
  Col
} from "reactstrap";
// core components
import SecurityService from "../../../proxies/SecurityService";
import { injectIntl  } from 'react-intl';
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import UserState from "../../components/user/userState";
import RedirectionAlert from "../../components/business/redirectionAlert";

class CancelEmailModification extends FormValidationComponent {

  constructor(props)
  {
    super(props);

    this.urlToken = null;
    this.urlId = null;

    this.state = {
      loading : false,
      success : null,
      errorMessage : null,
      isShipper : false,
      isTransporter : false,
      seconds : 5
    }
    this.securityService = new SecurityService();
  }

  componentDidMount()
  {
    // Si aucune clé n'est présente, alors on retourne sur l'écran de connexion
    const urlParams = new URLSearchParams (this.props.location.search)    
    this.urlToken = urlParams.get("token")
    this.urlId = urlParams.get("id")
    if (this.urlToken == null || this.urlId == null)
    {
      this.props.history.push('/auth/login');
    }
    else
    {
      // Appel de l'url de validation de compte et enregistrement du nouveau token
      this.securityService.cancelEmailModification(this, this.urlToken, (response) => {

        var url = response.user.return_url != null ? response.user.return_url : '/admin/profile'
        if (this.securityService.hasCompanyInfos() == false)
        {
          url = "/auth/login"
        }
        this.setState({
          url: url,
          success: true
        })
        
        this.successNotification("Confirmation", "La demande de modication d'email a bien été annulée. Votre ancien email " + response.user.email + " sera conservé.", "Fermer")        
      }, (httpResponse) => {
        this.setState({success : false, loading : false })

        httpResponse.json().then( async (apiResponse) => {
          
        })        
      })
    }
  }

  render() {
    return (
      <>
        {this.renderParent()}
        <Container className="mt--8 pb-5">
          <FormLoadingIndicator loading={this.state.loading} />

          <Row className="justify-content-center">
            <Col md="8">
              {
                (this.state.success == true) ? 
                (
                  <RedirectionAlert style={{marginTop:"200px"}} url={this.state.url} ></RedirectionAlert>
                ) 
                :
                (
                    (this.state.success != null && this.state.success == false) ? 
                    (
                      <UserState id={this.urlId} token={this.urlToken}></UserState>
                    )
                    :
                    (<></>)
                )
              }
              
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(CancelEmailModification);
