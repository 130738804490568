import "../../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components
 import {
   Form
 } from "reactstrap";
 import Select2 from "react-select2-wrapper";

import RepositoryService from "../../../../proxies/RepositoryService";
import FormInputValidationMessages from '../FormInputValidationMessages.jsx';
import FormComponent from "./FormComponent";
import { injectIntl , FormattedMessage } from 'react-intl';

class FormRepositoryTreeview extends FormComponent     {

    constructor(props, multiple = false, drawOption = null)
    {
        super(props)
        this.repositoryService = new RepositoryService();
        this.drawOption = drawOption;
        this.multiple = multiple;
        this.state = {
            flatDatas : [],
            datas : [],
            closeOnSelect: false
        }
    }

    addImageToOption(text, image)
    {
        if (typeof image === "undefined" || image == null || image == "")
            return text;

        return "<span><img width='20px' class='' src='" + image + "' />&nbsp;" + text + "</span>"
    }

    dataBind()
    {
        this.repositoryService.getRepositoriesByGroupingCode(this, "TREEVIEW_DOMAIN_BODY", response => {
            const flatDatas = response.datas;

            var datas = [];
            response.datas.forEach(item => {
                if (item.parent_id == null)
                {
                    datas.push({id: item.id, text: item.label});
                }
            })

            this.setState({
                flatDatas : response.datas,
                datas : datas
            })
        })
    }

    onChange(e)
    {
        const selectedId = e.target && e.target.value ? e.target.value:  null;
        
        if (selectedId != null)
        {
            const newDatas = [
                {id: -1, text: '< Retour'}
            ];
            this.state.flatDatas.forEach(item => {
                if (item.parent_id == selectedId)
                {
                    newDatas.push({id: item.id, text: item.label});
                }
            })

            this.setState({datas : newDatas})

                
            e.target.trigger("opening");
        }
    }

    componentDidMount() {
        this.dataBind();
    }

    render() {
        let validationState = this.getValidationState();
      return (
        <>
            <Select2
                disabled={this.props.readOnly}
                key={this.getId()}
                id={this.getId()}
                name={this.getName()}
                className={"form-control"}
                defaultValue={this.props.manager.getFormControlValue(this.getKey())}
                value={this.multiple ? this.props.manager.getFormControlValue(this.getKey()) : null}
                options={{
                    allowClear: true,
                    closeOnSelect: this.state.closeOnSelect,
                    placeholder: typeof this.props.placeholder !== "undefined" ? this.props.placeholder : "...", 
                    templateResult : this.drawOption ? this.drawOption : (elt) => { return elt.text},
                    templateSelection : this.drawOption ? this.drawOption : (elt) => { return elt.text},
                    escapeMarkup: (m) => { return m;}
                }}
                multiple={false}
                data={!this.state.datas ? [] : this.state.datas}
                onChange={(e) => this.onChange(e)}//this.props.manager.htmlInputChanged(e, this.getKey())}
            />
        </>
      );
    }
}

export default injectIntl(FormRepositoryTreeview)