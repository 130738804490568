/* eslint-disable */
const Codes = {
	CANCELED: "STATE_CANCELED",
	CANCELLATION_TYPE_EXPIRATION: "CANCELLATION_TYPE_EXPIRATION",
	CANCELLATION_TYPE_REFUSAL: "CANCELLATION_TYPE_REFUSAL",
	CANCELLATION_TYPE_SHIPPER: "CANCELLATION_TYPE_SHIPPER",
	CARD: "PAYMENT_METHOD_TYPE_CARD",

	CLASS_CONST_HIDDEN: "HIDDEN",
	CLASS_CONST_LOADING_CONTEXT: "LOADING_CONTEXT",
	CLASS_CONST_NEW: "NEW",
	CLASS_CONST_SELECTION: "EDITABLE",
	CLASS_CONST_SUMMARY: "SUMMARY",
	CLASS_CONST_UNLOADING_CONTEXT: "UNLOADING_CONTEXT",

	IBAN: "PAYMENT_METHOD_TYPE_IBAN",
	WAITING_FOR_COLLABORATOR_VALIDATION: "STATE_PENDING_MAIL_VALIDATION",
	WAITING_FOR_DRIVER_VALIDATION: "STATE_PENDING_SMS_VALIDATION",
	WAITING_FOR_ORDER_VALIDATION: "ORDER_STATE_WAITING_TRANSPORTER_VALIDATION"
}


export default Codes
