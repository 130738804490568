import "../../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
import {
   Row,
   Col,
   Input
 } from "reactstrap";
import FormComponent from "./FormComponent";
import { injectIntl , FormattedMessage } from 'react-intl';
import FormInput from "../FormInput";

class FormBoolean extends FormComponent
{

    constructor(props)
    {
        super(props)
        this.state = {        
            
        }
    }


    render() {
      return (
        <>
            <FormInput type="select" name={this.props.name} manager={this.props.manager}>
                <option key="bool__1" value="-1">{this.props.intl.formatMessage({id: "Default.i_dont_known"})}</option>
                <option key="bool_1" value="1">{this.props.intl.formatMessage({id: "Default.yes"})}</option>
                <option key="bool_0" value="0">{this.props.intl.formatMessage({id: "Default.no"})}</option>
            </FormInput>
        </>
      );
    }
}

 
export default injectIntl(FormBoolean);
