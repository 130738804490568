
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    ButtonGroup,
    Button
  } from "reactstrap";
import { Calendar } from 'react-date-range';

import { injectIntl , FormattedMessage } from 'react-intl';
import StringHelper from "../../../helpers/StringHelper";
import DateHelper from "../../../helpers/DateHelper";


class CountDown extends React.Component {

    constructor(props) {
        super(props)

        this.scheduledInterval = null;
        
        this.state = {
            date: this.props.date,
            value: this.props.value,
            timeInterval: this.props.timeInterval ? this.props.timeInterval : 1000,
            valueInterval: this.props.valueInterval ? this.props.valueInterval : 1,
            currentValue: this.props.value,
            currentValueAsHms: DateHelper.secondsAsHms(this.props.value),
            done: false
        }
    }

    componentWillMount()
    {
        
    }

    componentDidMount()
    {   
        this.scheduledInterval = setInterval(this.scheduledFunction.bind(this), 1000); 
    }

    scheduledFunction()
    {
        var currentValue = this.state.currentValue
        var done = this.state.done
        currentValue = currentValue - this.state.valueInterval;

        if (currentValue < 0)
        {
            currentValue = 0
            done = true
            if (this.scheduledInterval)
            {
                clearInterval(this.scheduledInterval)
            }
            if (this.props.onDone)
            {
                this.props.onDone()
            }
        }

        this.setState({currentValue: currentValue, done: done})
    }

    renderAsHms()
    {
        var hms = DateHelper.secondsAsHms(this.state.currentValue)

        return this.props.pattern.replace("{h}", hms.hours)
        .replace("{m}", (hms.minutes < 10 ? "0" : "") + hms.minutes)
        .replace("{s}", (hms.seconds < 10 ? "0" : "") + hms.seconds)
    }

    render() {
        if (this.state.done)
        {
            if (this.props.doneComponent)
            {
                return this.props.doneComponent
            }
            else
            {
                return "Compteur terminé"
            }
        }
        else
        {
            if (this.props.renderAsHms)
            {
                return this.renderAsHms();
            }
            else
            {
                return <>
                    {this.state.currentValue}
                </>
            }
        }
    }
}

export default injectIntl(CountDown)
