import React from "react";
import PropTypes from 'prop-types'
import {
    Alert,
    Button,
    FormGroup,
    Row,
    Col,
    UncontrolledTooltip,
    Label
} from "reactstrap";
import FormComponent from "./FormComponent"
import FormInput from "../FormInput"
import FormGroupInput from "./FormGroupInput"
import FormAutocompleteAddress from "./FormAutocompleteAddress"
import FormCountryInput from "./FormCountryInput"

import { injectIntl , FormattedMessage } from 'react-intl';
import FormAddressAutocomplete from "./FormAddressAutocomplete";
import DefaultCheckbox from "../../../components/common/defaultCheckbox"
import DefaultPopover from "../../../components/common/defaultPopover"
import DefaultInput from "../../../components/common/defaultInput";
import FormValidationComponent from "../FormValidationComponent";
import RepositoryInput from "../../../components/business/repositoryInput";
import { add } from "date-fns";
import GeocodingAndNavigationService from "../../../../proxies/GeocodingAndNavigationService";
import DefaultButton from "../../../components/common/defaultButton";
import StringHelper from "../../../../helpers/StringHelper";
import Resources from "../../../../resources";

class FormAddress extends FormValidationComponent {

    constructor(props)
    {
        super(props)

        this.geocodingAndNavigationService = new GeocodingAndNavigationService();
        this.state = {
            readonly: true
        }
    }

    componentDidMount()
    {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    readonlyChanged(e)
    {
        const newValue = !this.state.readonly;
        this.setState({readonly: newValue})
    }

    uncheckAddress()
    {
    //    this.addressEditAddressCkbRef.setValue(false)
        this.setState({readonly: true})
    }

    checkAddress()
    {
        //document.getElementById("formAddressEditAddressChecked").checked = true;
        this.setState({readonly: false})
    }


    setValue(address)
    {
        this.streetInputRef.setValue(address ? address.street : null); 
        this.street2InputRef.setValue(address ? address.street2 : null);
        this.zipCodeInputRef.setValue(address ? address.zip_code : null); 
        this.cityInputRef.setValue(address ? address.city : null);  
        this.countryInputRef.setValue(address ? address.country_code : null);
        this.gpsExternalIdInputRef.setValue(address ? address.gps_external_id : null);
        this.gpsLevelInputRef.setValue(address ? address.gps_level : null);
        this.gpsLatInputRef.setValue(address ? address.gps_lat : null);
        this.gpsLongInputRef.setValue(address ? address.gps_long : null);

        // Affichage du picto pour la qualité du niveau de géocodage de l'adresse
        this.geocodingAndNavigationService.setCallerAddressIsValid(this.props.manager, address.gps_level)
    }

    onAddressSelected(gpsInfos)
    {
        //console.log(gpsInfos)
        //document.getElementById("street").value= gpsInfos.street;
        //document.getElementById("zip_code").value= gpsInfos.zipCode;
        //document.getElementById("city").value= gpsInfos.city;
        this.streetInputRef.setValue(gpsInfos.street); 
        this.zipCodeInputRef.setValue(gpsInfos.zipCode); 
        this.cityInputRef.setValue(gpsInfos.city); 
        this.countryInputRef.setValue(gpsInfos.country);
        this.gpsExternalIdInputRef.setValue(gpsInfos.gps_external_id);
        this.gpsLevelInputRef.setValue(gpsInfos.gps_level);
        this.gpsLatInputRef.setValue(gpsInfos.gps_lat);
        this.gpsLongInputRef.setValue(gpsInfos.gps_long);

        this.uncheckAddress();
    }

    render() {

        return (
            <>
                <Row>
                    <Col lg="12">
                        {
                            (this.props.suggestions && this.state.readonly) ? 
                            (
                                <>
                                <FormGroupInput htmlFor="suggest2" intlId={"Default.suggestions"}>
                                    <FormAddressAutocomplete name="suggest2"  manager={this.props.manager} onSelected={this.onAddressSelected.bind(this)} />
                                </FormGroupInput>
                                {/*<FormGroupInput htmlFor="street" intlId={"Default.suggestions"}>
                                    <FormAutocompleteAddress name='suggest' manager={this.props.manager} onSelected={this.onAddressSelected.bind(this)} />
                                </FormGroupInput>*/}
                                </>
                            ) : ("")
                        }
                    </Col>
                    
                    {
                        (this.props.suggestions && this.props.addressAlertMessage == true) ?
                        (
                            <>
                            <Col lg="12">
                            <Alert color="none" className="border border-warning">
                                <Row>
                                    <Col xs="2">
                                        <i className="text-warning fas fa-info-circle fa-lg" />
                                    </Col>
                                    <Col xs="10" className="text-warning">
                                        <p><strong>L'adresse n'est pas assez précise pour permettre un géocodage correct.</strong></p>
                                        <p>Pour remédier à ce problème, vous pouvez : </p>
                                        <Button color="secondary"  className="text-warning" 
                                        outline type="button" onClick={e => this.checkAddress()}>Saisir l'adresse manuellement</Button>
                                        <Button color="secondary" className="text-warning" outline type="button" onClick={e => this.uncheckAddress()}>Rechercher une nouvelle adresse</Button>
                                        {
                                            /*
                                            <Button color="secondary" className="text-warning" outline type="button">Choisir un emplacement sur une carte</Button>
                                            */
                                        }
                                        
                                    </Col>
                                </Row>
                            </Alert>
                            </Col>
                            </>
                        )
                        :("")
                    }

                    <DefaultInput childRef={elt => this.gpsExternalIdInputRef = elt} type="hidden" manager={this.props.manager} name="gps_external_id" />
                    <DefaultInput childRef={elt => this.gpsLatInputRef = elt} type="hidden" manager={this.props.manager} name="gps_lat" />
                    <DefaultInput childRef={elt => this.gpsLongInputRef = elt} type="hidden" manager={this.props.manager} name="gps_long" />
                    <DefaultInput childRef={elt => this.gpsLevelInputRef = elt} type="hidden" manager={this.props.manager} name="gps_level" />

                    <Col lg="12">
                        {/*<DefaultCheckbox id="formAddressEditAddressChecked"
                            childRef={(elt) => this.addressEditAddressCkbRef = elt} 
                            defaultValue={false}
                            onChange={e => this.readonlyChanged(e)}
                            content={this.props.intl.formatMessage({id: "Default.edit_address"})}
                        />*/}
                        <DefaultButton className="t2y-secondary-button small" onClick={(e) => this.readonlyChanged(e)}
                            icon={(this.state.readonly ? Resources.button_edit_icon : Resources.button_search_location) }>
                            {StringHelper.translate(this, this.state.readonly ? (this.props.creation ? "Default.create_address" : "Default.edit_address" ) : "Default.undo_edit_address")}
                        </DefaultButton>
                    </Col>
                    <Col lg="12" className="mt-2">
                        <FormGroupInput htmlFor="street" intlId={"Default.street"} 
                            tooltip={this.props.suggestions && this.props.addressIsGeocoded == false ? 
                                {icon:'fas fa-info-circle ml-2', color:'danger', message:"L'adresse n'est pas assez précise pour permettre un géocodage correct."}: 
                                (this.props.addressIsGeocoded == true) ? {icon:'fas fa-map-marked-alt ml-2', color:'success', message:"Adresse géolocalisée correctement."} : null
                                }
                        >
                            <DefaultInput required={!this.state.readonly} readOnly={this.state.readonly} childRef={elt => this.streetInputRef = elt} name='street' manager={this.props.manager} />
                        </FormGroupInput>
                    </Col>
                    <Col lg="12">
                        <FormGroupInput htmlFor="street2" intlId={"Default.street2"}>
                            <DefaultInput childRef={elt => this.street2InputRef = elt} name='street2' manager={this.props.manager} />
                        </FormGroupInput>
                    </Col>
                    <Col lg="12">
                        <Row>
                            <Col lg="6">
                                <FormGroupInput htmlFor="zip_code" intlId={"Default.zip_code"}>
                                    <DefaultInput required={!this.state.readonly}  readOnly={this.state.readonly} childRef={elt => this.zipCodeInputRef = elt} name='zip_code' manager={this.props.manager} />
                                </FormGroupInput>
                            </Col>
                            <Col lg="6">
                                <FormGroupInput htmlFor="city" intlId={"Default.city"}>
                                    <DefaultInput required={!this.state.readonly} readOnly={this.state.readonly} childRef={elt => this.cityInputRef = elt} name='city' manager={this.props.manager} />
                                </FormGroupInput>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="12">
                        <FormGroupInput htmlFor="country_code" intlId={"Default.country"}> 
                            <RepositoryInput childRef={elt => this.countryInputRef = elt} required={!this.state.readonly} readOnly={this.state.readonly}  name='country_code' manager={this.props.manager} familyCode={"COUNTRY"} multiple={false}/>
                        </FormGroupInput>
                    </Col>
                </Row>
            </>
        )
    }

}

FormGroupInput.defaultProps = {
    type: 'inline'
  };

FormGroupInput.propTypes = {
    suggestions: PropTypes.bool,
    manager: PropTypes.any,
    onSuggestionSelected: PropTypes.func,
    addressIsGeocoded: PropTypes.bool,
    addressAlertMessage: PropTypes.bool
    /*label: PropTypes.any,
    type: PropTypes.oneOf(["inline", "multiline"])
    */
}

export default injectIntl(FormAddress);