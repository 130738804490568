import "../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
import { renderToString } from 'react-dom/server'
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components
 import {
     Row,
     Col
 } from "reactstrap";
import RepositoryService from "../../../proxies/RepositoryService"
import { injectIntl , FormattedMessage } from 'react-intl';

import { Multiselect } from 'multiselect-react-dropdown';
import DefaultInput from "../common/defaultInput";
import DefaultImage from "../common/defaultImage";
import StringHelper from "../../../helpers/StringHelper";
import TagsInput from "react-tagsinput";
import ShipperService from "../../../proxies/ShipperService";
import LoadingIndicator from 'react-spinners/MoonLoader'
import DefaultCheckbox from "../common/defaultCheckbox";
import Resources from "../../../resources";
import FormValidationComponent from "../../pages/components/FormValidationComponent";

class GoodItemLabelInput extends DefaultInput     {

    constructor(props)
    {
        super(props);
        
        this.repositoryService = new RepositoryService();

        this.state = {
            options: [],
            company: this.props.company == null ? true : this.props.company,
            loading: false,
            currentDatas: []
        }
    }

    componentWillMount()
    {
        super.componentWillMount();

        this.repositoryService.getRepositories(this, "FREIGHT_TYPE", (response) => {

            var datas = []

            response.datas.forEach(element => {
                datas.push({id : element.label, label: element.label})
            });

            this.setState({datas : datas});
        })
    }

    componentDidMount()
    {
        super.componentDidMount();

        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }


    _onChanged(option)
    {

        this.setValue(option.id)
        this.setState({options: [], text: null, onlyAutocomplete: false})
    }
    
    _handleSearch(text)
    {
        if (text != null && text.length >= 1)
        {
            this.setState({loading: true, text: text, onlyAutocomplete: true})
        
            var newDatas = []

            if (this.state.datas)
            {
                this.state.datas.forEach(d => {
                    if (d.label.toLowerCase().indexOf(text.toLowerCase()) >= 0)
                    {
                        newDatas.push(d)
                    }
                })
            }

            this.setState({options: newDatas, loading: false, value: text})

        }
        else
        {
            this.setState({options: [], text: null, onlyAutocomplete: false, value: null})
            document.getElementById(this.getKey()).value = "";
        }

        if (this.props.onSearch)
        {
            this.props.onSearch(text)
        }
    }


    render()
    {
        var backgroundColor = "white"
        var textColor = "#1e2635" 
        var iconColor = "#FFC42B"
        var className = this.props.className ? this.props.className : {}
        if (this.props.required)
        {
            className += " " + (this.hasValue() ? this.getRequiredWithValueClass() : this.getRequiredWithoutValueClass()) // " t2y-required" + (this.hasValue() ? "-with-value" : "")
        }
        return <>
        <div className={className}>
            <div className={"btn btn-default "} style={{zIndex:"600", width: "100%", backgroundColor: backgroundColor, borderColor: textColor, color:textColor, padding:0}}>
                <input
                    
                    id="customer_search_input"
                    value={this.getValue()}
                    focus={this.state.focus}
                    onChange={(e)=> this._handleSearch(e.currentTarget.value)}
                    autoComplete="OFF" 
                    style={{ border:"none", height:"50px", paddingLeft:"5px", marginTop:"0px", float:"left", width:"80%", color:this.props.textColor}}
                    placeholder={this.props.autocompletePlaceHolder ? this.props.autocompletePlaceHolder : "..."}
                />
                {
                    (this.state.loading) ?
                    (
                        <span class="btn-inner--icon t2y-default-input-icon-container">
                            <LoadingIndicator size={15} />
                        </span>
                    ) : (<></>)
                }
                <div style={{width:"100%", overflow:"scroll"}}  className={"dropdown-menu " + ((this.state.options.length > 0) ? "show": "")}>
                    { this.state.options.map((option, index) => 
                            <div 
                                key={"selectItem" + index} 
                                className="dropdown-item" onClick={ ()=> this._onChanged(option)}
                                dangerouslySetInnerHTML={{__html: option.label}}
                            > 
                            </div> 
                        )
                    }
                </div>
                <span className="btn-inner--icon" style={{ color:this.props.iconColor, fontSize: "20px", float: "right", margin: "0.625rem 1.25rem" }}>
                    <DefaultImage style={{ color: iconColor}} src="fas fa-box-open"></DefaultImage>
                </span>
            </div>
        </div>
        <input type="hidden" id={this.getKey()} value={this.getValue()}></input>
        </>
    }
  }
 
  export default injectIntl(GoodItemLabelInput);