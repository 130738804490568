
import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal
  } from "reactstrap";
import LoadingOverlay from 'react-loading-overlay'
import Spinner from 'react-spinners/PulseLoader'
import styled from 'styled-components'

import BusinessService from '../../../../proxies/BusinessService';
import GeocodingAndNavigationService from '../../../../proxies/GeocodingAndNavigationService';
// nodejs library that concatenates classes
import classnames from "classnames";
import FormGroupInput from "../form/FormGroupInput"
import FormInput from '../FormInput'
import FormValidationComponent from "../FormValidationComponent";
import FormLoadingIndicator from "../FormLoadingIndicator";
import { injectIntl , FormattedMessage } from 'react-intl';


const StyledLoader = styled(LoadingOverlay)`
  .Step1_overlay {
    color:white;
  }
  &.Step1_wrapper--active {
    overflow: hidden;
  }
`

class SearchBySiretModal extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    this.businessService = new BusinessService();
    this.geocodingAndNavigationService = new GeocodingAndNavigationService();
    this.index = 1;
    this.isTransporter = this.businessService.isTransporter()

    this.validationRules = {
      siret_criteria : {
        required :true,
        rules : [
           {
               rule: this.siret.bind(this),
               ruleMessage : this.translateMessage("Default.invalid_siret")
           }
        ]
      }
    }

    this.state = {
        loading : false,
        company_infos: null,
        step: 1
    }
  }

  toggleSiretModal()
  {
      // Récupération de la visibilité actuelle
      var currentValue = this.props.manager.state[this.props.stateKey];
    
      // Modification de la valeur
      var newVisibleState = {}
      newVisibleState[this.props.stateKey] = !currentValue;

      // Affectation du container parent
      this.props.manager.setState(newVisibleState)
  }

  onSubmitStep1(e)
  {
    e.preventDefault();
    var formIsValid = this.htmlFormIsValid();
    if (formIsValid)
    {
        // Récupération du siret selectionnné
        var siret = this.getHtmlFormControlValue("siret_criteria");
        
        // Affichage de l'indicateur de chargement        
        this.setState({loading : true})
        
        // Récupération des donnnées à partir du siret
        this.businessService.getCompanyInfosBySiret(this, siret, (response) => {
            // Passage à l'étape 2
            this.setState({
                company_infos: response,
                step: 2
            })

            // Géocodage de la ville selectionnée
            var companyAddress = (!response.billing_address.zip_code ? "" : " " + response.billing_address.zip_code) + 
            (!response.billing_address.city ? "" : " " + response.billing_address.city)

            var modal = this;
            this.geocodingAndNavigationService.geocode(this, companyAddress, (geocodeResponse) => {
                /*var company_infos = this.state.company_infos;
                company_infos.gps_lat = {value: geocodeResponse.gps_lat};
                company_infos.gps_long = {value: geocodeResponse.gps_long};
                company_infos.gps_level = {value: geocodeResponse.gps_level};
                company_infos.gps_external_id = {value: geocodeResponse.gps_external_id};

                this.setState({company_infos: company_infos});*/
            })
        })
        
        return false;
    }
  }

  onCompanySelected(e)
  {
    if (this.props.onSiretConfirmation)
    {
        this.props.onSiretConfirmation(this.state.company_infos);
        
        // Affichage du message de confirmation
        this.setState({step: 3})
    }
  }

  render() {
    const companyInfos = this.state.company_infos
    return  (
        <>
        {this.renderParent()}

        <Modal key="modal_siret" className="modal-dialog-centered" size="md" isOpen={this.props.manager.state[this.props.stateKey]}>
            {
                (this.state.step == 1) ? 
                (
                    <Form key={++this.index} id="form_change_email" className="needs-validation" noValidate  onSubmit={e => this.onSubmitStep1(e)} >
                        <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">
                            <FormattedMessage id="SearchBySiretModal.step1_title" key={++this.index} />
                            </h6>
                            <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleSiretModal()}
                            >
                            <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <FormattedMessage id="SearchBySiretModal.step1_message">
                                {this.decodeEntities}
                            </FormattedMessage>

                            <Row>
                            <Col xs="12">
                                <FormGroup>
                                    <FormInput placeholder={this.translateMessage("SearchBySiretModal.step1_siret_placeholder")} name='siret_criteria' manager={this} />
                                </FormGroup>
                            </Col>
                        </Row>
                        </div>
                        

                        <div className="modal-footer">
                            <Button
                            className=""
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleSiretModal()}
                            >
                                <FormattedMessage id="Default.close" key={++this.index} />
                            </Button>

                            <Button className="" color="primary" type="submit">
                                {
                                    (this.state.loading) ?
                                    (
                                        <Spinner color="white" />
                                    )
                                    :
                                    (
                                        <FormattedMessage id="SearchBySiretModal.step1_search" key={++this.index} />
                                    )
                                }

                            </Button>
                        </div>
                    </Form>
                ) 
                : 
                (
                    (this.state.step == 2) ?
                    (
                        <>
                        <div className="modal-header">
                        <FormattedMessage id="SearchBySiretModal.step2_message">
                                {this.decodeEntities}
                            </FormattedMessage>
                            <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleSiretModal()}
                            >
                            <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Row>
                            <Col xs="12">
                                <h6 className="modal-title mb-2" id="modal-title-default">
                                    <FormattedMessage id="SearchBySiretModal.step2_company_title" key={++this.index} />
                                </h6>
                                <FormGroupInput label="Raison Sociale" >
                                    <label>{this.state.company_infos.company_name}</label>
                                </FormGroupInput>
                                <FormGroupInput label="SIRET" >
                                    <label>{this.state.company_infos.siret}</label>
                                </FormGroupInput>
                                <FormGroupInput label="SIREN" >
                                    <label>{this.state.company_infos.siren}</label>
                                </FormGroupInput>
                                <FormGroupInput label="Adresse" >
                                    <label>
                                        {this.state.company_infos.billing_address.street}<br/>
                                        {this.state.company_infos.billing_address.street2}<br/>
                                        {this.state.company_infos.billing_address.zip_code} {this.state.company_infos.billing_address.city}
                                    </label>
                                </FormGroupInput>
                                <FormGroupInput label="Code activité" >
                                    <label>{this.state.company_infos.activity_code}</label>
                                </FormGroupInput>
                            </Col>
                            {
                                (companyInfos!= null && this.isTransporter) ?
                                (
                                    <Col xs="12">       
                                        <h6 className="modal-title mb-2" id="modal-title-default">
                                            <FormattedMessage id="SearchBySiretModal.step2_license_title" key={++this.index} />
                                        </h6>
                                        {
                                            // Si il y a au moins une licence   
                                            (companyInfos.lti_number != null || companyInfos.lc_number != null) ?
                                            (
                                                <>
                                                {
                                                    (companyInfos.lc_number != null) ?
                                                    (
                                                        <label>Licence communautaire  n°<strong>{companyInfos.lc_number}</strong> valable jsuqu au <strong>{companyInfos.lc_end_date}</strong></label>
                                                    ) : ("")
                                                }
                                                {
                                                    (companyInfos.lti_number != null) ?
                                                    (
                                                        <label>Licence de transport intérieur n°<strong>{companyInfos.lti_number}</strong> valable jsuqu au <strong>{companyInfos.lti_end_date}</strong></label>
                                                    ) : ("")
                                                }
                                                </>
                                            ) : ("Aucune information n'a été trouvée")
                                        }
                                    </Col>
                                )
                                :
                                ("")
                            }
                        </Row>
                        </div>
                        

                        <div className="modal-footer">
                            <Button
                            className=""
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.setState({step: 1})}
                            >
                                <FormattedMessage id="SearchBySiretModal.step2_back_to_step1" key={++this.index} />
                            </Button>

                            <Button className="" color="primary" type="button" onClick={this.onCompanySelected.bind(this)}>
                                <FormattedMessage id="SearchBySiretModal.step2_select" key={++this.index} />
                            </Button>
                        </div>
                        </>
                    )
                    : 
                    (
                        <>
                        <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">
                            <FormattedMessage id="SearchBySiretModal.step3_title" key={++this.index} />
                            </h6>
                            <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleSiretModal()}
                            >
                            <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <FormattedMessage id="SearchBySiretModal.step3_message">
                                {this.decodeEntities}
                            </FormattedMessage>
                        </div>
                        

                        <div className="modal-footer">
                            <Button className="" color="primary" type="button" onClick={() => this.toggleSiretModal()}>
                                <FormattedMessage id="SearchBySiretModal.step3_close" key={++this.index} />
                            </Button>
                        </div>
                        </>
                    )
                    
                )
            }
            
        </Modal>


      </>
    );
  }
}

export default injectIntl(SearchBySiretModal);
