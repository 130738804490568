import Config from '../config.js'

export default class ApiV2Service {
	constructor() {}

	callApi(url, option) {
		const defaultOption = {
			method: "GET",
			requiredConnexion: false
		}

		const finalOption = {...defaultOption, ...option}

		let token = null
		if(option.requiredConnexion) {
			token = localStorage.getItem("token")
		}

		return fetch(Config.apiv2 + "/" + url, {
			method: finalOption.method,
			headers: {
				"Content-Type": "application/json",
				"Authorization" : (token === null ? "" : "Bearer " + token)
			},
		})
		.then(httpResponse => {
				return httpResponse
		})
		.catch(error => {
				return error
		});
	}

	cancelOrder(orderId) {
		return this.callApi("api/order/"+ orderId +"/cancel", { requiredConnexion: true }).then((response) => {
			if(response.status === 200) {
				return response.json().then(res => {
					return res.success
				})
			}
			return false
		}).catch(error => {
			return false
		})
	}
}