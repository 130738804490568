/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import SecurityService from "../../../proxies/SecurityService";
import { injectIntl , FormattedMessage } from 'react-intl';
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormInput from '../components/FormInput.jsx';
import FormCivilityInput from '../components/form/FormCivilityInput.jsx';

import FormInputValidationMessages from '../components/FormInputValidationMessages.jsx';

class Register extends FormValidationComponent {
  constructor(props)
  {
    super(props);

    this.securityService = new SecurityService();

    this.validationRules = {
      civility_code : {
        required: true
      },
      first_name: {
        required: true
      },
      last_name: {
        required: true
      },
      mobile_phone_number : {
        required: true,
        rules : [
          {rule : this.mobilePhoneNumber.bind(this)}
        ]
      },
      email : {
        required : true,
        rules : [
          {rule : this.email.bind(this)}
        ]
      },
      password : {
        required :true
      }
    }

    this.state = {
      wizardStep: 1,
      selectedAccountType:null,
      accountTypeState : '',
      loading : false,
      formDatas : {
        email : { value: ""},
        password : { value: ""},
        civility_code: { value: "" },
        first_name: { value: ""},
        last_name: { value: ""},
        mobile_phone_number: { value: ""},
      }
    }
  }

  setAccountType(accountType)
  {
    this.setState(
      {
        selectedAccountType: accountType,
        wizardStep : 2
      }
    )
  }

  getSelectedAccountType()
  {
    var radios = document.getElementsByName('accountType');
    var value = null;
    for(var i = 0; i < radios.length; i++){
      if(radios[i].checked){
        value = radios[i].value;
      }
    }
    return value;
  }

  changeAccountType()
  {
    const accountType = this.getSelectedAccountType();
    if (accountType == null)
    {
      this.setState({accountTypeState: 'required'})
      return false;
    }
    else{
      this.setState({accountTypeState: 'valid'})
      return true;
    }
  }

  onSubmitForm = e => {
    // Vérification du type de compte
    var accountTypeIsValid = this.state.selectedAccountType? true : false;//this.changeAccountType() ;

    if (accountTypeIsValid == false)
    {
      this.setState({
        wizardStep : 1
      })
      return false;
    }

    e.preventDefault()
    const accountType = this.state.selectedAccountType;//this.getSelectedAccountType();
    var formIsValid = this.htmlFormIsValid(e);

    if (formIsValid && accountTypeIsValid)
    {
      // Récupération des donées postée
      var dataToPost = {
        "civility_code": this.getHtmlFormControlValue("civility_code"),
        "first_name": this.getHtmlFormControlValue("first_name"),
        "last_name": this.getHtmlFormControlValue("last_name"), 
        "mobile_phone_number": this.cleanPhoneNumber(this.getHtmlFormControlValue("mobile_phone_number")), 
        "username": this.getFormControlValue("email"), 
        "password": this.getFormControlValue("password"), 
        "type" : accountType
      }
      // Création du compte
      this.securityService.createAccount(this, dataToPost, this.onAccountCreated.bind(this));
    }
  }

  onAccountCreated(response)
  {
    this.securityService.storeData("register_email", this.getFormControlValue("email"));
    this.securityService.storeData("register_account_type", this.state.selectedAccountType);
    this.props.history.push('/auth/register-confirmation');
  }

  renderShipperCard()
  {
    return <Card>
      <CardBody>
        <a href="#" onClick={e => this.setAccountType("shipper")}>
          <img
              alt="..."
              className=" img-center img-fluid shadow shadow-lg--hover"
              src={require("../../../assets/img/icons/common/register_shipper_icon.svg").default}
              style={{ width: "140px" }}
            />
        </a>
        <div className="pt-4 text-center">
          <h5 className="h3 title">
            <span className="d-block mb-1">Expéditeur</span>
            <small className="h4 font-weight-light text-muted">
            vous cherchez une solution de transport de vos marchandises
            </small>
          </h5>
        </div>
      </CardBody>
    </Card>
  }

  renderTransporterCard()
  {
    return <Card>
      <CardBody>
        <a href="#pablo" onClick={e => this.setAccountType("transporter")}>
          <img
              alt="..."
              className=" img-center img-fluid shadow shadow-lg--hover"
              src={require("../../../assets/img/icons/common/empty_truck_image.svg").default}
              style={{ width: "140px" }}
            />
        </a>
        <div className="pt-4 text-center">
          <h5 className="h3 title">
            <span className="d-block mb-1">Offreur</span>
            <small className="h4 font-weight-light text-muted">
            vous mettez à disposition vos solutions de transport
            </small>
          </h5>
        </div>
      </CardBody>
    </Card>
  }

  renderStep1()
  {
      return <Card className="bg-secondary border-0">
      <CardHeader className="bg-transparent">
        <div className="text-muted text-center mt-2 mb-4">
          <small><FormattedMessage id='Register.step1_title' defaultMessage={`Register.step1_title`} /></small>
        </div>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
        <div className="text-center">
          <Row>
            <Col xs="6">
              {this.renderShipperCard()}
            </Col>
            <Col xs="6">
              {this.renderTransporterCard()}
            </Col>
          </Row>
        </div>
        </CardBody>
    </Card>
  }

  renderStep2()
  {
    const accountTypeLabel = this.translateMessage("Default." + this.state.selectedAccountType);

    return <Card className="bg-secondary border-0">
      <CardBody className="px-lg-5 py-lg-5">
        <div className="text-muted text-center mt-2 mb-4">
          <small><FormattedMessage id='Register.choice_title' defaultMessage={`Register.choice_title`} /></small>
        </div>
        <Row>
          <Col xs="6">
            <div className="text-center">
                  
                  {
                    (this.state.selectedAccountType == "shipper") ?
                    (
                      this.renderShipperCard()
                    )
                    :
                    (
                      this.renderTransporterCard()
                    )
                  }
            </div>
          </Col>
          <Col xs="6">

        <Form onSubmit={e => this.onSubmitForm(e)} role="form">
              <FormGroup
                className={classnames("mb-3", {
                  focused: this.state.focusedCivilityCode
                })}
              >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-hat-3" />
                  </InputGroupText>
                </InputGroupAddon>
                <FormCivilityInput name="civility_code" manager={this} />
              </InputGroup>
            </FormGroup>
            <FormGroup
                className={classnames("mb-3", {
                  focused: this.state.focusedFirstName
                })}
              >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-circle-08" />
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  id="first_name"
                  placeholder={this.intl.formatMessage({"id" : "Login.first_name", "defaultMessage" : "!Login.first_name"})}
                  type="first_name"
                  manager={this}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
                className={classnames("mb-3", {
                  focused: this.state.focusedLastName
                })}
              >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  id="last_name"
                  placeholder={this.intl.formatMessage({"id" : "Login.last_name", "defaultMessage" : "!Login.last_name"})}
                  type="last_name"
                  manager={this}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
                className={classnames("mb-3", {
                  focused: this.state.focusedMobilePhoneNumber
                })}
              >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-mobile-button" />
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  id="mobile_phone_number"
                  placeholder={this.intl.formatMessage({"id" : "Login.mobile_phone_number", "defaultMessage" : "!Login.mobile_phone_number"})}
                  type="mobile_phone_number"
                  manager={this}
                />
              </InputGroup>
            </FormGroup>
            
            <FormGroup
              className={classnames("mb-3", {
                focused: this.state.focusedEmail
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  id="email"
                  placeholder={this.intl.formatMessage({"id" : "Login.email", "defaultMessage" : "!Login.email"})}
                  type="email"
                  manager={this}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
              className={classnames({
                focused: this.state.focusedPassword
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  id="password"
                  placeholder={this.intl.formatMessage({"id" : "Login.password", "defaultMessage" : "!Login.password"})}
                  type="password"
                  manager={this}
                />
              </InputGroup>
            </FormGroup>
            <div className="text-center">
              <Button className="my-4" color="info" type="submit">
                <FormattedMessage id='Register.create_account' defaultMessage={`Register.create_account`} />
              </Button>
            </div>
          </Form>
          </Col>
        </Row>
        

        
      </CardBody>
    </Card>
  }

  render() {
    return (
      <>
        {this.renderParent()}
        <AuthHeader
          title={this.translateMessage("Register.title1")}
          lead={this.translateMessage("Register.title2")}
        />
        <Container className="mt--8 pb-5">
          <FormLoadingIndicator loading={this.state.loading} />
          <Row className="justify-content-center">
            <Col lg={this.state.wizardStep == 1 ? 10 : 10} md={this.state.wizardStep == 1 ? 8 : 12}>
              {
                (this.state.wizardStep == 1) ?
                (
                  this.renderStep1()
                )
                :
                (
                  this.renderStep2()
                )
              }
              
              <Row className="mt-3">
                <Col className="text-right" xs="12">
                  <a
                    className="text-light"
                    href="/auth/login"
                  >
                    <small>
                      <FormattedMessage id='Register.login' defaultMessage={`!Register.login`} />
                    </small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(Register);
