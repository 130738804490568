/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import queryString from 'query-string';
import $ from "jquery";
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
    Modal,
    Alert,
    Table,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardText,
  CardBody,
  CardImg,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import BusinessService from "../../../proxies/BusinessService.js";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import FormGroupInput from '../components/form/FormGroupInput.jsx';
import FormInput from '../components/FormInput.jsx';
import ReactDatetime from "react-datetime";

import Config from "../../../config.js"
import { injectIntl , FormattedMessage } from 'react-intl';
import AdministratorService from "../../../proxies/AdministratorService.js";
import DefaultLabel from "../../components/common/defaultLabel.jsx";
import StringHelper from "../../../helpers/StringHelper.js";
import DateLabel from '../../components/common/dateLabel';
import DefaultImage from "../../components/common/defaultImage.jsx";
import Resources from "../../../resources.js";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
import FormHeader from "../components/form/FormHeader.jsx";
import Title3 from "../../components/common/title3.jsx";
import DefaultButton from "../../components/common/defaultButton.jsx";
import DateHelper from "../../../helpers/DateHelper.js";


class TransporterTransferValidation extends FormValidationComponent {
  
    constructor(props) 
    {
        super(props);
        this.administratorService = new AdministratorService();

        this.state = {
            transporterId: null,
            company: null,
            done: false,
            doneMessage: null
        }
    }
 
    componentDidMount() {
        this.getTransportersPaymentLotInfos()
    }

    getTransportersPaymentLotInfos()
    {
        const parsedQueryString = queryString.parse(window.location.search);
        // Récupération des données du lot de paiement
        this.administratorService.getTransportersPaymentLot(this, parsedQueryString.paymentLotId, (response) => {
            this.setState({
                paymentLot: response.value,
                paymentLotId: parsedQueryString.paymentLotId
            })


        }, (httpErrorResponse) => {
            httpErrorResponse.json().then((apiResponse) => {
                this.showErrorFromResponse(apiResponse, null)
            })
            this.setState({loading: false}) 
        })
    }


    confirmOk()
    {
        this.showModalConfirmation({
            iconComponent: <>
                <div className="text-center text-success"  style={{fontSize:"100px",}}>
                    <DefaultImage src={Resources.button_success_icon}></DefaultImage>
                </div>
            </>,
            title: "", 
            component: <>Confirmez vous avoir envoyé le fichier à la banque?</>, 
            okButtonTitle: StringHelper.translate(this, "Default.yes"), cancelButtonTitle: StringHelper.translate(this, "Default.no"),
            okButtonCallback: () => {
                this.administratorService.paymentLotSent(this, this.state.paymentLotId, (response) => {
                        // Confirmation de l'acceptation
                        this.showSuccessModalNotification("", "Ce lot de paiements a bien été considéré comme envoyé à la banque.")
                        // On n'affiche plus rien
                        this.setState({paymentLot: response.value})
                    }, 
                    (httpErrorResponse) => {
                        httpErrorResponse.json().then((apiResponse) => {
                            this.showErrorFromResponse(apiResponse, null)
                        })
                        this.setState({loading: false}) 
                    }
                )
            },
            cancelButtonCallback: () => {}
        })
    }

    confirmKo()
    {
        this.showModalConfirmation({
            iconComponent: <>
                <div className="text-center text-warning"  style={{fontSize:"100px",}}>
                    <DefaultImage src={Resources.toast_warning_logo}></DefaultImage>
                </div>
            </>,
            title: "", 
            component: <>Confirmez vous que la banque a refusé ce fichier?</>, 
            okButtonTitle: StringHelper.translate(this, "Default.yes"), cancelButtonTitle: StringHelper.translate(this, "Default.no"),
            okButtonCallback: () => {
                this.administratorService.paymentLotRefused(
                    this, 
                    this.state.paymentLotId,
                    (response) => {
                        //console.log("OK")
                        this.showSuccessModalNotification("", "Ce lot de paiements à bien été refusé.")
                        // On n'affiche plus rien
                        this.setState({paymentLot: response.value})
                    }, 
                    (httpErrorResponse) => {

                        httpErrorResponse.json().then((apiResponse) => {
                            this.showErrorFromResponse(apiResponse, null)
                        })
                        this.setState({loading: false}) 
                    }
                )
            },
            cancelButtonCallback: () => {}
        })
    }

    downloadFile()
    {
        this.administratorService.getPaymentLotAttachmentsAsZip(this, this.state.paymentLotId, response => {

            const dtoAttachment = response.value;

            const linkSource = "data:" + dtoAttachment.content_type + `;base64,${dtoAttachment.base64_content}`;
            const downloadLink = document.createElement("a");
            const fileName = dtoAttachment.filename;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }, (httpErrorResponse) => {
            httpErrorResponse.json().then((apiResponse) => {
                this.showErrorFromResponse(apiResponse, null)
            })
            this.setState({loading: false}) 
        })
    }

  renderPaymentLotInfos(){
    const paymentLotInfos = this.state.paymentLot;

    return <> 
        <div className="pl-lg-4" key={StringHelper.createDomElementId()}>
            <Row>
                <Col lg="12">
                    <Title3>Lot n° {paymentLotInfos.external_id}</Title3>
                </Col>
                <Col lg="12">
                    <Row>
                        <Col xs="12" md="9">
                            <h6 className="heading-small text-muted mb-4">
                                Donnée du paiement
                            </h6>
                            <Row>
                                <Col lg="5">
                                    <FormGroupInput intlId={"PaymentLot.creation_date"} type="multiline">
                                    <DefaultLabel  style={{width:"100%"}}>Créé le <DateLabel label=" " date={paymentLotInfos.creation_date}></DateLabel></DefaultLabel>
                                    </FormGroupInput>
                                </Col>
                                <Col lg="7">
                                    <FormGroupInput intlId={"PaymentLot.state"} type="multiline">
                                        {
                                            (!paymentLotInfos.state_user) ? 
                                            (
                                                <DefaultLabel  style={{width:"100%"}}>{paymentLotInfos.state.label}</DefaultLabel>
                                            ) :
                                            (
                                                <DefaultLabel  style={{width:"100%"}}>{paymentLotInfos.state.label} par {paymentLotInfos.state_user.summary} le {DateHelper.toString(paymentLotInfos.state_date, "DD/MM/YYYY HH:mm")}</DefaultLabel>
                                            )
                                        }
                                    </FormGroupInput>
                                </Col>
                                <Col lg="5">
                                    <FormGroupInput intlId={"PaymentLot.nb_payment_lines"} type="multiline">
                                        <DefaultLabel  style={{width:"100%"}}>{paymentLotInfos.nb_payment_lines}</DefaultLabel>
                                    </FormGroupInput>
                                </Col>
                                <Col lg="7">
                                    <FormGroupInput intlId={"PaymentLot.amounts"} type="multiline">
                                        <DefaultLabel  style={{width:"100%"}}>{paymentLotInfos.total_amount_ht} € HT / {paymentLotInfos.total_amount_ttc} € TTC</DefaultLabel>
                                    </FormGroupInput>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="3">
                            <h6 className="heading-small text-muted mb-4">
                                Fichier a télécharger
                            </h6>
                            <Row className="t2y-center-title">
                                <DefaultImage onClick={elt => this.downloadFile()} className="t2y-clickable" src={Resources.downloaded_file_logo} style={{fontSize:"80px", margin:"0 auto"}}></DefaultImage>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col lg="12">
                    <Row>
                        <Col lg="6" style={{textAlign:"center"}}>
                            <DefaultButton className="t2y-third-button small t2y-warning-button" onClick={(elt) => this.confirmKo()} icon={Resources.checkKo_logo}>
                                {StringHelper.translate(this, "PaymentLot.refused_button")}
                            </DefaultButton>
                        </Col>
                        <Col lg="6" style={{textAlign:"center"}}>
                            <DefaultButton className="t2y-secondary-button" onClick={(elt) => this.confirmOk()} icon={Resources.checkOk_logo}>
                                {StringHelper.translate(this, "PaymentLot.paied_button")}
                            </DefaultButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr/>
        </div>
    </>
  }

  render() {
    if (this.state.done)
    {
        return <>
            {this.renderParent()}
        </>
    }

    return (
      <>
        
        {this.renderParent()}
        <FormLoadingIndicator loading={this.state.loading}/>
        <Container className="">
          <Row className="justify-content-center">
            <Col xs="12" lg="8">
                < Card className="bg-secondary border-0 mb-0 p-1">
                    <CardBody>
                        {
                            (this.state.paymentLot) ?
                            (
                                this.renderPaymentLotInfos()
                            ) 
                            : (<></>)
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(TransporterTransferValidation);
