import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";
import queryString from 'query-string'

import {
    Row,
    Col,
    Card,
    Container,
    CardBody,
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import SecurityService from "../../../../proxies/SecurityService";
import DefaultInput from "../../common/defaultInput";
import Resources from "../../../../resources";
import Title2 from "../../common/title2"
import Title3 from "../../common/title3"
import Step from "../step";
import PasswordInput from "../../common/passwordInput";
import StringHelper from "../../../../helpers/StringHelper";
import UserState from '../../user/userState'
import WebsiteHelper from "../../../../helpers/WebsiteHelper";
import DefaultButton from "../../common/defaultButton";

var moment = require('moment');
require('moment/locale/fr');

class LoginStep extends Step {

    constructor(props) {
        super(props)

        this.businessService = new BusinessService();
        this.securityService = new SecurityService();
        
        this.validationRules = {
            email: {
                required: true,
                rules: [
                    {
                        rule: this.email.bind(this),
                        ruleMessage: this.translateMessage("Default.invalid_email")
                    }
                ]
            },
            password: {
                required: true
            }
        }

        this.state = {
            loading: false,
            userError: false
        }
    }

    componentWillMount() {
        super.componentWillMount()
    }

    componentDidMount() {
        super.componentDidMount()

        if (this.props.childRef) {
            this.props.childRef(this)
        }

        if (this.props.email && StringHelper.notEmptyValue(this.props.email)) {
            document.getElementById("email").value = this.props.email;
        }
        else if (this.props.workflow.state.input)
        {
            document.getElementById("email").value = this.props.workflow.state.input;
        }
        document.getElementById("email").focus();
    }

    saveEmail()
    {
        this.props.workflow.setInput(this.getHtmlFormControlValue("email"))
    }

    submit(pid) {
        var formIsValid = this.htmlFormIsValid();
        if (formIsValid) {
            super.submit(pid)
            this.setState({ loading: true })
            // Connexion
            this.securityService.connect(this, this.getHtmlFormControlValue("email"), this.getHtmlFormControlValue("password"),
                this.onLoggedIn.bind(this),
                this.onError.bind(this)
            );
        }
    }

    onError(httpResponse) {
        this.setState({ loading: false })
        httpResponse.json().then((apiResponse) => {

            this.securityService.getUserStateByEmail(null, this.getHtmlFormControlValue("email"), (response) => {
                if (response.user != null && response.user.state.code == "STATE_PENDING_MAIL_VALIDATION") {
                    response.code = "STATE_PENDING_MAIL_VALIDATION"

                    this.setState({ userError: true, userStateActionResponse: response })
                    this.props.workflow.reload();
                }
                else {
                    this.showErrorModalNotification(
                        this.translateMessage("Login.login_check_error_title"),
                        this.translateMessage("Login.login_check_error_message")
                    );
                }
            });
        });
    }

    onLoggedIn(response) {
        this.setState({ loading: false })
        if (this.securityService.hasCompanyInfos() && this.shouldReferToWorkflow() == false)
        {
            var url = WebsiteHelper.getHomeUrl(this);

            // Vérification d'une url de redirection
            const parsedQueryString = queryString.parse(window.location.search);
            if (parsedQueryString.expectedUrl && parsedQueryString.expectedUrl  != null)
            {
                url = window.atob(parsedQueryString.expectedUrl)
            }

            WebsiteHelper.tryEscapeFromIframe(url)
        }
        else
        {
            this.props.workflow.goToNextStep(this.state.pid)
            /*
            // Si on est un transporteur, on informe l'utilisateur du problème
            if (response.user.is_transporter)
            {
                this.props.workflow.props.workflow.showSearchTransporterErrorMessage();
            }
            else
            {
                
            }*/
        } 
    }

    render() {
        if (this.state.userError) {
            return <>
                <UserState userStateActionResponse={this.state.userStateActionResponse} />
            </>
        }
        return (
            <>
                {this.renderParent()}
                <FormLoadingIndicator loading={this.state.loading} />
                <Container>
                    <Row>
                        {
                            (this.props.embeded) ?
                                (
                                    <Col lg="12" md="12" className="text-center justify-content-text-center mb-4">
                                        <img src={require('../../../../assets/img/illustrations/secure_login_vT2Y-2.svg').default} style={{ width: "50%" }} />
                                    </Col>
                                ) : (<></>)
                        }
                        <Col lg={this.props.embeded ? "12" : "6"} md="12" className="order-lg-1 order-2">
                            <Row className="mb-3">
                                <Col>
                                    <Title2>
                                        <FormattedMessage id="account.login_step.title1"></FormattedMessage>
                                    </Title2>
                                    <Title3>
                                        <FormattedMessage id="account.login_step.title2"></FormattedMessage>
                                    </Title3>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <DefaultInput id="email"
                                        childRef={(elt) => this.loginInputRef = elt}
                                        icon={Resources.form_email_icon} placeholder={this.translateMessage("account.login_step.email")}>
                                    </DefaultInput>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <PasswordInput id="password"
                                        childRef={(elt) => this.passwordInputRef = elt}
                                        icon={Resources.form_password_icon} placeholder={this.translateMessage("account.login_step.password")}>
                                    </PasswordInput>
                                </Col>
                            </Row>
                        </Col>
                        {
                            (!this.props.embeded) ?
                                (
                                    <Col lg="6" md="12" className="justify-content-text-center order-lg-2 order-1 mb-4">
                                        <img src={require('../../../../assets/img/illustrations/secure_login_vT2Y.svg').default} style={{ width: "100%" }} />
                                    </Col>
                                )
                                : (<></>)
                        }

                    </Row>
                </Container>
            </>
        );
    }
}


export default injectIntl(LoginStep)

