/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Link, Redirect } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button
} from "reactstrap";

import { injectIntl , FormattedMessage } from 'react-intl';
import Config from '../../config.js'
import SecurityService from '../../proxies/SecurityService';
import WebsiteHelper from '../../helpers/WebsiteHelper';
import DefaultImage from "../../views/components/common/defaultImage.jsx";

class AuthNavbar extends React.Component {

  constructor(props)
  {
    super(props)
    this.securityService = new SecurityService();
  }

  restart(e)
  {
    // On vide la session en cours
    this.securityService.clearStorage(); 
    // On retourne à l'écran de login
    WebsiteHelper.goTo(this, "/auth/login");
  }


  render() {
    return (
      <>
        <Navbar
          className="navbar-horizontal navbar-main t2y-auth-navbar"
          expand="lg"
          id="navbar-main"
        >
          <Container style={{marginLeft: "inherit", marginRight: "inherit", maxWidth: "100%"}}>
            <NavbarBrand to={null} onClick={(e) => this.restart(e)} tag={Link}>
              <img
                alt="..."
                style={{maxHeight:"30%"}}
                src={require('../../assets/img/logo/t2y_logo_menu.png').default}
              />
            </NavbarBrand>
            <button
              aria-controls="navbar-collapse"
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navbar-collapse"
              data-toggle="collapse"
              id="navbar-collapse"
              type="button"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              className="navbar-custom-collapse"
              navbar
              toggler="#navbar-collapse"
              style={{position:"absolute", right:"0"}}
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link onClick={(e) => this.restart(e)}>
                      <img
                        alt="..."
                        style={{maxHeight:"30%"}}
                        src={require('../../assets/img/logo/t2y_logo_menu.png').default}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      aria-controls="navbar-collapse"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-collapse"
                      data-toggle="collapse"
                      id="navbar-collapse"
                      type="button"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              {/*
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink to="/admin/dashboard" tag={Link} className="t2y-menu-item-link">
                    <span className="nav-link-inner--text">
                      <FormattedMessage id="Auth.menu.help"></FormattedMessage>
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
              */}
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default injectIntl(AuthNavbar);
