const DriverMessages = {
    "driver.RATING_PERSON_TYPE_RECIPIENT": "Déstinataire",
    "driver.RATING_PERSON_TYPE_SHIPPER": "Expéditeur",
    "driver.save_confirmation_title": "Confirmation",
    "driver.save_confirmation_message": "Les données du conducteur ont été enregistrées avec succès",
    "driver.mobile_phone_number_infos" : "Le numéro de téléphone mobile est l'identifiant du conducteur dans l'application",
    "driver.multiselect_placeholder": "...",
    "driver.multiselect_empty_result": "Aucun résultat disponible",
    "driver.trucks_panel_title": "Véhicule(s)",
    "driver.close_go_to_list": "Retourner à la liste des conducteurs",
    "driver.close_and_stay": "Rester sur la fiche",
    "truck.multiselect_placeholder": "...",
    "truck.multiselect_empty_result": "Aucun résultat disponible",
    "trucks.multiselect_tooltip": "Plusieurs véhicules sont possibles"
}

export default DriverMessages