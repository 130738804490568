/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import queryString from 'query-string';
import $ from "jquery";
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
    Alert,
    Table,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardText,
  CardBody,
  CardImg,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import BusinessService from "../../../proxies/BusinessService.js";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import FormGroupInput from '../components/form/FormGroupInput.jsx';
import FormInput from '../components/FormInput.jsx';
import ReactDatetime from "react-datetime";

import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultButton from "../../components/common/defaultButton.jsx";
import Resources from "../../../resources.js";
import StringHelper from "../../../helpers/StringHelper.js";
import InvoiceSummary from "./InvoiceSummary.jsx"
import DefaultInput from "../../components/common/defaultInput.jsx";
import Message from "../../components/common/message.jsx";
import WebsiteHelper from "../../../helpers/WebsiteHelper.js";
import RedirectionAlert from "../../components/business/redirectionAlert.jsx";

class ManualInvoiceUpload extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    
    this.businessService = new BusinessService();

    this.validationRules = {
        file : {
            required :true,
            requiredMessage : this.translateMessage("Default.transport_pdf_invoice_required")
        }
    }
    this.state = {
        order_id: null,
        transporter_id: null,
        confirmation: false,
        loading : false,
        image_file: null,
        upload_file_alert: false,
        formDatas : {}
    }
  }
 
  componentDidMount() {
      // Récupération des données de la facture
      const parsedQueryString = queryString.parse(window.location.search);
      this.businessService.getTransporterInvoiceBillingInfos(this, parsedQueryString.orderId, parsedQueryString.transporterId, (response) => {

        this.setState({
            invoice: response.invoice,
            order_id: parsedQueryString.orderId,
            transporter_id: parsedQueryString.transporterId
        })

        if (response.invoice.state.code != "INVOICE_STATE_NOT_RECEIVED")
        {
            this.showErrorModalNotification("", <>Impossible de télécharger un fichier sur cette facture.</>)
        }
      });
  }

  componentWillUnmount(){
  }

  onSubmitForm = e => {
    e.preventDefault()

    if (this.state.upload_file_alert)
    {
        alert("Merci de télécharger un fichier au format PDF de 5Mo maximum");
        return false;
    }

    var formIsValid = this.htmlFormIsValid(e);
    if (formIsValid)
    {
        // Récupération des donées postée
        var formData = new FormData(e.target)
        const fileInput = document.querySelector("#file");
        const fileNameParts = this.state.image_file.name.split(".");
        var fileExtension = "";
        if (fileNameParts.length > 0)
        {
            fileExtension = "." + fileNameParts[fileNameParts.length - 1];
        }

        const cleanedFilename = StringHelper.keepRegularChars(this.state.image_file.name.replace(fileExtension, ""), "_", true) + fileExtension;  
        formData.append("file", this.state.image_file, cleanedFilename);

        formData.append("comment", (typeof this.commentInputRef.getValue() !== "undefined" ? this.commentInputRef.getValue() : ""));
        
        this.businessService.uploadTransporterManualInvoice(this, this.state.transporter_id, this.state.invoice.id, formData, (response) => {
            this.setState({confirmation: true})

            this.showSuccessModalNotification("", <>
                Votre facture a bien été transmise<br/>
                <br/>
                Elle sera validée par notre équipe et mise en paiement si elle est conforme à l'appel à facturation.
            </>)
        })
        
    }
  }

  fileChangedHandler = (event) => {
    let file = event.target.files[0];
    let fileSize = file.size;
    let fileType = file.type;
        
    if (((fileSize / 1024) / 1024) > 5 || fileType != "application/pdf")
    {
        this.setState({upload_file_alert: true})
    }
    else {
        this.setState({upload_file_alert: false, image_file: file})
    }
  }

  renderUploadPart()
  {
    return <>
        <Message type="default">
            Attention : votre facture doit respecter scrupuleusement les indications de l'appel à facturation, sans quoi votre facture risquerait d'être retardée voire refusée.
        </Message>
        <FormGroupInput htmlFor="file" label={"Facture au fromat pdf"}>
            <input type="file" id="file" name="file" onChange={(e) => this.fileChangedHandler(e)}/>
        </FormGroupInput>
        <FormGroupInput htmlFor="file" label={"Commentaire éventuel"}>
            <DefaultInput childRef={elt => this.commentInputRef = elt} type="textarea" rows="6"  name="comment" placeholder={"..."} manager={this} />
        </FormGroupInput>
    </>
  }
  

    renderRedirectContent()
    {
        const url = WebsiteHelper.getHomeUrl(this)

        return <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
                <RedirectionAlert style={{marginTop:"200px"}} url={url} ></RedirectionAlert>
            </Row>
        </Container>
    }

    renderMainContent()
    {
        return <Container className="m3 pb-5">
        <FormLoadingIndicator
          loading={this.state.loading}
        />
        <Row className="justify-content-center">
            <Col md="12">
                <Card className="bg-secondary border-0">
                    <CardBody>
                        {
                            (this.state.confirmation) ?
                            (
                                <></>
                            ) : 
                            (
                                (this.state.invoice) ?
                                (
                                    ( this.state.invoice.state.code != "INVOICE_STATE_NOT_RECEIVED") ?
                                    (<></>)
                                    : (
                                        <>
                                            <Form onSubmit={e => this.onSubmitForm(e)} role="form">
                                                <InvoiceSummary 
                                                    orderId={this.state.order_id}
                                                    transporterId={this.state.transporter_id}
                                                    invoice={this.state.invoice} title="Appel à facturation : rappel des éléments attendus dans votre facture">
                                                </InvoiceSummary>

                                                <CardTitle className="mb-3" tag="h3">
                                                Votre facture
                                                </CardTitle>
                                                <CardText className="mb-4">
                                                {
                                                    (this.state.invoice) ?
                                                    (
                                                        this.renderUploadPart()
                                                    ) : (<></>)
                                                }


                                                </CardText>

                                                <div className="text-center">
                                                <DefaultButton className="t2y-secondary-button small" type="submit">
                                                    <FormattedMessage id='ManualInvoiceUpload.save' defaultMessage={`Télécharger ma facture pour validation`} />
                                                </DefaultButton>
                                                </div>
                                            </Form>
                                        </>
                                    )
                                )
                                :
                                (<></>)
                            )
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row>
        </Container>
    }


  render() {
    return (
      <>
        {this.renderParent()}
        <AuthHeader />
        {
            (this.state.confirmation) ?
            (
                this.renderRedirectContent()
            ) : 
            (
                this.renderMainContent()
            )
        }
      </>
    );
  }
}

export default injectIntl(ManualInvoiceUpload);
