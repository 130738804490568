import React from "react";

import LoadingOverlay from 'react-loading-overlay'
import Spinner from 'react-spinners/PulseLoader'

import styled from 'styled-components'

const StyledLoader = styled(LoadingOverlay)`
  .MyLoader_overlay {
    with:100%;
    height:100%;
    background: rgba(0, 0, 0, 0.1);;
  }
  &.MyLoader_wrapper--active {
    overflow: hidden;
  }
`

const StyledLoaderWithoutOverlay = styled(LoadingOverlay)`
  .MyLoader_overlay {
    with:100%;
    height:100%;
    background: transparent;
  }
  &.MyLoader_wrapper--active {
    overflow: hidden;
  }
`


export default function FormLoadingIndicator({ loading, children, overlay }) {
  if (overlay)
  {
    return (
      <StyledLoader
        active={loading}
        classNamePrefix='MyLoader_'
        spinner={<Spinner />}
      >
        {children}
      </StyledLoader>
    )
  }
  else
  {
    return (

      <StyledLoaderWithoutOverlay
        active={loading}
        classNamePrefix='MyLoader_'
        spinner={<Spinner />}
      >
        {children}
      </StyledLoaderWithoutOverlay>
    )
  }
}
