import "../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components
 import {
   Form, Spinner
 } from "reactstrap";
import RepositoryService from "../../../proxies/RepositoryService"
import DefaultSelect2 from "../common/defaultSelect2";
import StringHelper from "../../../helpers/StringHelper";

class RepositoryInput extends DefaultSelect2     {

    constructor(props)
    {
        const multiple = (typeof props.multiple !== "undefined" ? props.multiple : true)
        
        super(props, multiple);

        this.className = "t2y-default-input-control " 
        this.repositoryService = new RepositoryService();
    }

    displayResponse(response)
    {
        var datas = []
        datas.push({id : " ", text: typeof this.props.placeholder !== "undefined" ? this.props.placeholder : "..."})
        
        response.datas.forEach(element => {
            datas.push({id : element.code, text: element.label})
        });

        this.setState({datas : datas, loading: false})
    }

    dependsOn(sourceId, selectedValue)
    {
        this.setState({loading: true})
        this.repositoryService.getRepositoriesByParent(this, this.props.familyCode, selectedValue, (response) => {
            this.displayResponse(response)
        })
    }

    dataBind()
    {
        if(StringHelper.notEmptyValue(this.props.dependsOn))
        {
            this.dependsOn(null, this.props.dependsOn)
        }
        else
        {
            this.setState({loading: true})
            this.repositoryService.getRepositories(this, this.props.familyCode, (response) => {
                this.displayResponse(response)
            })
        }
    }
}
 
export default RepositoryInput;