import React from "react";
import SecurityService from "../proxies/SecurityService";
import ProxyService from "../proxies/ProxyService";
import frTranslations from '../lang/fr/messages'

export default class StringHelper
{

    static proxyService = new ProxyService()
    static translations = {
        "fr": frTranslations
    }

    static capitalize(value, allWord = false)
    {
        if (!value || value.length == 0)
        {
            return value;
        }
        
        return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1)
    }

    static camelCase(s){
        return this.capitalize(s
              .replace(/_/g, " ")
              .replace(/\s(.)/g, function($1) { return $1.toUpperCase(); })
              .replace(/\s/g, '')
              .replace(/^(.)/, function($1) { return $1.toLowerCase(); })
        );
    }

    static toCode(s)
    {
        return s.replace(/_-/g, " ").replace(/\s/g, '').toLowerCase()
    }

    static createDomElementId(length)
    {
        var charstoformid = '_0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');
        if (!length)
        {
            length = Math.floor(Math.random() * charstoformid.length);
        }
        var uniqid = '';
        for (var i = 0; i < length; i++) {
            uniqid += charstoformid[Math.floor(Math.random() * charstoformid.length)];
        }
        return "_" + uniqid;
    }

    static createNewGuid()
    {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
         });
    }

    static toBoolean(value)
    {
        if (value != null)
        {
            if (value == 1 || value == true || value.toUpperCase() == "TRUE")
            {
                return true;
            }   
        }
        return false;
    }

    static notEmptyValue(value = null)
    {
        if (Array.isArray(value) && typeof value === "object")
        {
            return value.length > 0;
        }
        else
        {
            return (typeof value !== "undefined" && value !== null && value !== "" && value.trim() !== "");
        }
    }

    static checkRegExp(value, regexp)
    {
        if (StringHelper.notEmptyValue(value))
        {
            return regexp.test(value);
        }
        return true;
    }

    static formatAmountWithPattern(amount, devise, pattern = null)
    {
        if (pattern == null)
        {
            pattern = "{devise} {amount}"
        }

        if (!amount)
            return devise + " ";

        const n = String(parseFloat(amount) * 1.00)
        const p = n.indexOf('.')
        amount = n.replace(
            /\d(?=(?:\d{3})+(?:\.|$))/g,
            (m, i) => p < 0 || i < p ? `${m} ` : m
        )

        if (amount.indexOf(".") < 0)
        {
            amount = amount.toString() + ".00";
        }
        else if (amount.indexOf(".") == amount.length - 2) // Si un seul chiffre apres la virgule
        {
            amount = amount.toString() + "0";
        }
        else
        {
            amount = amount.toString()
        }

        return pattern.replace("{devise}", devise).replace("{amount}", amount.replace(".", ","));
    }

    static decodeEntities(nodes, domElement = "p", nodeClass = "t2y-decode-entities-p") {
        return nodes
            .map(node => typeof node === 'string' ? 
                node.split(/\r?\n/).map((text, index) => React.createElement(domElement, { class:nodeClass, key:index, dangerouslySetInnerHTML: { 
                    __html: String(text).replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot/g, '"')
                } })) : node)
            .reduce((nodes, node) => nodes.concat(node), []);
    }

    static translateWithHtmlWithoutClass(source, key, defaultValue = null, values = null, domElement = "p")
    {
        return this.translateWithHtml(source, key, defaultValue, values, domElement, "")
    }

    static translateWithHtml(source, key, defaultValue = null, values = null, domElement = "p", nodeClass = "t2y-decode-entities-p")
    {
        return this.decodeEntities([this.translate(source, key, defaultValue, values)], domElement, nodeClass)
    }

    static translate(source, key, defaultValue = null, values = null)
    {
        var trad = key
        if (source != null && source.props != null && source.props.intl != null)
        {
            trad = source.props.intl.formatMessage({"id" : key}, values)
        } 

        // Si aucune traduction alors la valeur est égale à celle de la clé recherchée
        // Dans ce cas là on prend la valeur par défaut si elle est spécifiée
        if (trad == key && defaultValue != null)
        {
            trad = defaultValue;
        }

        // On tente de remplacer les valeurs que ne l'aurai pas été
        if (values != null)
        {
            for(const [key, value] of Object.entries(values)) {
                trad = trad.replace("{" + key + "}", value);
            }
        }

        return trad;
    }

    static tryTranslate(source, key, defaultValue = null)
    {
        var trad = this.translate(source, key);
        // Si aucune traduction
        if (trad == key)
        {
            const lang = this.proxyService.getLang();
            trad = this.translations[lang] ? this.translations[lang][key] : null;
        }

        return !trad ? defaultValue : trad;
    }

    static formatNumber(amount, decimalCount = 2, decimal = ".", thousands = ",")
    {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        
            const negativeSign = amount < 0 ? "-" : "";
        
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;
        
            const formatedAmount = negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            return formatedAmount   

        } catch (e) {
            return "";
        }
    }

    static formatAmount(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        var formattedAmount =  this.formatNumber(amount, decimalCount, decimal, thousands);
        if (formattedAmount && formattedAmount != "")
        {
            return formattedAmount + " €"
        }
        else
        {
            return formattedAmount
        }
    };

    static asBlockOf(value, blockSize = 4, separator = "-")
    {
        if (value == null || value.length < blockSize)
        {
            return value
        }

        const length = value.length;
        const nbBlocks = parseInt(length / blockSize) + (length % blockSize > 0 ? 1 : 0)
        //console.log(nbBlocks)
        var formattedValue = "";
        for(var i = 0; i < nbBlocks; i++)
        {
            formattedValue += separator + value.substr(i * blockSize, 4);
        }

        return formattedValue.substr(separator.length);
    }

    static replaceAll(source, from, to)
    {
        var replace = from;
        var regexp = new RegExp(replace,"g");
        const result = source.replace(regexp, to);

        return result;
    }

    static removeSpecialChars(text, alternativeString = "", removeAccents = false)
    {
        if (!text)
        {
            return text;
        }
        

        var newText = text.replace(/[^0-9A-Za-z_\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F\u00C0-\u024F\u1E00-\u1EFF\u0300-\u036f]/g, alternativeString);
        if (removeAccents)
        {
            newText = newText.normalize('NFD').replace(/([\u0300-\u036f])/g, '');
        }
        return newText;
    }

    static keepRegularChars(text)
    {
        if (!text)
        {
            return text;
        }
        
        var newText = text.replace(/[ \.]/g, "_").normalize('NFD').replace(/([\u0300-\u036f])/g, '').replace(/[^0-9A-Za-z_]/g, "");
        return newText;
    }

}