
import React from "react";
import PropTypes from 'prop-types';
import classnames from "classnames";

// reactstrap components
import {
    Button,
    Container,
    Form,
    FormGroup,
    UncontrolledPopover,
    UncontrolledTooltip,
    PopoverBody,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
  } from "reactstrap";
import SecurityService from "../../../proxies/SecurityService"
import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultButton from "../common/defaultButton";
import Resources from "../../../resources";
import Title2 from "../common/title2";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import StringHelper from "../../../helpers/StringHelper";
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import MissionStepAddressSelection from "./missionStepAddressSelection";
import MissionStepContactSelection from "./missionStepContactSelection";
import Codes from "../../../codes";
import { compareDesc } from "date-fns/esm";

class MissionStepAddressAndContactSelection extends FormValidationComponent {

    static SELECTION_MODE_ADDRESS = "ADDRESS";
    static SELECTION_MODE_CONTACT = "CONTACT";

    constructor(props)
    {
        super(props)

        this.state = {
            address: this.props.address,
            contact: this.props.contact,
            mode: this.props.mode,
            selectionMode: MissionStepAddressAndContactSelection.SELECTION_MODE_ADDRESS
        }
    }

    componentWillMount()
    {

    }

    componentDidMount()
    {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    renderContactSelectionMode()
    {
        if (this.props.address && this.props.address.type.code == "ADDRESS_TYPE_CUSTOMER" && this.props.address.customer_company_address === false)
        {
            return <></>
        }

        return <>
            <MissionStepContactSelection key={"ContactSelectionMode"}
                contactSelectionHeaderComponent={this.props.contactSelectionHeaderComponent}
                childRef={elt => this.missionStepContactSelectionRef = elt}
                readOnly={this.props.readOnly}
                address={this.state.address}
                contact={this.state.contact}
                context={this.props.context}
                onContactChangeRequest={() => {
                    if (this.props.onContactChangeRequest)
                    {
                        this.props.onContactChangeRequest()
                    }
                }}
                onContactSelection={contact => {

                    if (this.props.onContactSelection)
                    {
                        this.props.onContactSelection(contact)
                    }

                    this.setState({
                        contact: contact
                        // On remonte l'information au composant parent que l'étape est terminée
                    })
                }}
            >
            </MissionStepContactSelection>
        </>
    }

    renderAddressSelectionMode()
    {
        return <>
            <MissionStepAddressSelection key={"AddressSelectionMode"}
                addressSelectionHeaderComponent={this.props.addressSelectionHeaderComponent}
                readOnly={this.props.readOnly}
                address={this.state.address}
                context={this.props.context}
                // En cas de modification de demande de modification d'adresse
                onAddressChangeRequest={() => {
                    // Passage du module contact en modification également
                    if (this.missionStepContactSelectionRef)
                    {
                        this.missionStepContactSelectionRef.reset()
                    }

                    if (this.props.onAddressChangeRequest)
                    {
                        this.props.onAddressChangeRequest()
                    }
                }}

                // Suite à la selection d'une adresse on passe à la selection du contact
                onAddressSelection={address => {

                    if (this.props.onAddressSelection)
                    {
                        this.props.onAddressSelection(address)
                    }

                    // Mise à jour de l'adresse pour le rechargement des contacts
                    if (this.missionStepContactSelectionRef)
                    {
                        this.missionStepContactSelectionRef.setAddress(address)
                    }

                    this.setState({
                        address: address,
                        selectionMode: MissionStepAddressAndContactSelection.SELECTION_MODE_CONTACT}
                    )
                }}
            >
            </MissionStepAddressSelection>
        </>
    }

    renderSelectionMode()
    {
        return <>
            {this.renderAddressSelectionMode()}
            {
                (this.props.skipContact == false) ?
                (
                    ((this.state.address && this.state.address.id) || (this.state.selectionMode == MissionStepAddressAndContactSelection.SELECTION_MODE_CONTACT)) ?
                    (
                        <>
                        <br/>
                        {this.renderContactSelectionMode()}
                        </>
                    ) : (<></>)
                ) : (<></>)
            }
        </>
    }

    renderSummaryMode()
    {
        return <>
            <MissionStepAddressSelection key={StringHelper.createDomElementId()}
                readOnly={this.props.readOnly}
                address={this.props.address}
                context={this.props.context}
                addressSummaryAddressFooterComponent={
                    this.props.readOnly == false ? <>
                        <Row className="align-item-center justify-content-center mb-3">
                            <DefaultButton className="t2y-secondary-button small"
                                onClick={e => {
                                    this.setState({
                                        mode: Codes.CLASS_CONST_SELECTION,
                                        selectionMode: MissionStepAddressAndContactSelection.SELECTION_MODE_ADDRESS
                                    })
                                }}
                            >Modifier cette adresse</DefaultButton>
                        </Row>
                    </> : <></>
                }
            >
            </MissionStepAddressSelection>

            <MissionStepContactSelection key={StringHelper.createDomElementId()}
                readOnly={this.props.readOnly}
            >
            </MissionStepContactSelection>
        </>
    }

    render() {
        return  <>
            {
                /*(this.props.readOnly || this.state.mode == Codes.CLASS_CONST_SUMMARY) ?
                (
                    this.renderSummaryMode()
                ) :
                (*/
                    this.renderSelectionMode()
                //)
            }
        </>
    }
}

MissionStepAddressAndContactSelection.propTypes = {
    addressSelectionHeaderComponent: PropTypes.any,
    contactSelectionHeaderComponent: PropTypes.any,
    onAddressChangeRequest: PropTypes.func,
    onContactChangeRequest: PropTypes.func,
    readOnly: PropTypes.bool.isRequired,
    address: PropTypes.any,
    contact: PropTypes.any,
    context: PropTypes.oneOf([Codes.CLASS_CONST_LOADING_CONTEXT, Codes.CLASS_CONST_UNLOADING_CONTEXT]),
};

export default injectIntl(MissionStepAddressAndContactSelection)