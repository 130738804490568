import BusinessService from "./BusinessService";
import axios from "axios";

class GeocodingAndNavigationService {

    constructor()
    {
        this.apiKey = "acmytatgx55jHJgYF6S9ssO0tHA1SDzsQvrDV3jx4KU";
        this.businessService = new BusinessService();
    }

    levelIsValid(gpsLevel)
    {
        return (gpsLevel == "houseNumber" || gpsLevel == "street");
    }

    getCenter(points)
    {
        if (points == null || points.length == 0)
        {
            return null;
        }

        var lat = 0;
        var long = 0;
        
        var nb = points.length;
        points.forEach(coordonate => {
            lat += (coordonate.latitude);
            long += (coordonate.longitude);
        });

        return {
            latitude: lat / nb,
            longitude: long / nb
        }
    }

    setCallerAddressIsValid(caller, gpsLevel)
    {
        var addressIsGeocoded = true;
        var addressAlertMessage = false;
        if (typeof  gpsLevel === "undefined" || gpsLevel == null || gpsLevel == "")
        {
            addressIsGeocoded = null;
            addressAlertMessage = true;
        }
        else if (gpsLevel == "houseNumber" || gpsLevel == "street")
        {
            addressIsGeocoded = true;
            addressAlertMessage = false;
        }
        else{
            addressIsGeocoded = false;
            addressAlertMessage = true;
        }

        caller.setState({
            addressIsGeocoded: addressIsGeocoded,
            addressAlertMessage: addressAlertMessage
        })  

    }

    setAddressFields(caller, target, address)
    {
        target.street = { value: address.street };
        target.street2 = { value: address.street2 };
        target.zip_code = { value: address.zip_code };
        target.city = { value: address.city };
        target.country_code = { value: address.country_code };
        target.gps_lat = { value: address.gps_lat };
        target.gps_long = { value: address.gps_long };
        target.gps_external_id = { value: address.gps_external_id };
        target.gps_level = { value: address.gps_level };
        
        this.setCallerAddressIsValid(caller, address.gps_level)
        return target;
    }

    getAddressFields(caller, request, okCallback)
    {
        request.street = caller.getHtmlFormControlValue("street");
        request.street2 = caller.getHtmlFormControlValue("street2");
        request.zip_code = caller.getHtmlFormControlValue("zip_code");
        request.city = caller.getHtmlFormControlValue("city");
        request.country_code = caller.getHtmlFormControlValue("country_code")[0];
        request.gps_lat = caller.getHtmlFormControlValue("gps_lat");
        request.gps_long = caller.getHtmlFormControlValue("gps_long");
        request.gps_external_id = caller.getHtmlFormControlValue("gps_external_id");
        request.gps_level = caller.getHtmlFormControlValue("gps_level");

        // On regarde si on doit géocoder l'adresse à nouveau
        //if (document.getElementById("formAddressEditAddressChecked").checked)
        //{
            this.geocode(caller, request.street + " " + request.zip_code + " " + request.city, (response) => {
                request.gps_lat = response.gps_lat
                request.gps_long = response.gps_long
                request.gps_external_id = response.gps_external_id
                request.gps_level = response.gps_level
                
                this.setCallerAddressIsValid(caller, response.gps_level)

                okCallback(request);
            })
        //}
        //else{
        //    okCallback(request);
        //}
    }

    getPointAndValidateLevel(caller, address, okCallback)
    {
        var url = "https://geocode.search.hereapi.com/v1/geocode?q={ADDRESS}&apiKey={API_KEY}"
        url = url.replace("{API_KEY}", this.apiKey).replace("{ADDRESS}", address);
        
        const config = {
            headers: {
                'Accept': 'application/json'
            }
        };

        axios.get(url, config).then(httpResponse => {
            var apiResponse = httpResponse.data;

            const hereSuggestions = apiResponse.items;
            var address = null;
            if (hereSuggestions.length > 0)
            {
                const suggestion = hereSuggestions[0]
                
                address = {}
                address.gps_external_id = suggestion.id;
                address.gps_lat = suggestion.position.lat;
                address.gps_long = suggestion.position.lng;
                address.gps_level = suggestion.resultType;
                address.summary = suggestion.address.label;
                address.street = suggestion.address ? (suggestion.address.houseNumber ? suggestion.address.houseNumber + " " : "") + (suggestion.address.street ? suggestion.address.street : "") : "";
                address.zip_code = suggestion.address.postalCode;
                address.city = suggestion.address.city;
                address.country_code = "COUNTRY_FR";
            }

            okCallback(address);
        })
     /*
        return fetch(url, {
            method: "GET"
        })
        .then(httpResponse => {
            httpResponse.json().then(apiResponse => {        
                const hereSuggestions = apiResponse.items;
                var address = null;
                if (hereSuggestions.length > 0)
                {
                    const suggestion = hereSuggestions[0]
                    
                    address = {}
                    address.gps_external_id = suggestion.id;
                    address.gps_lat = suggestion.position.lat;
                    address.gps_long = suggestion.position.lng;
                    address.gps_level = suggestion.resultType;
                    address.summary = suggestion.address.label;
                    address.street = suggestion.address ? (suggestion.address.houseNumber ? suggestion.address.houseNumber + " " : "") + (suggestion.address.street ? suggestion.address.street : "") : "";
                    address.zip_code = suggestion.address.postalCode;
                    address.city = suggestion.address.city;
                    address.country_code = "COUNTRY_FR";
                }

                okCallback(address);
            })
        });
*/
    }

    geocode(caller, text, okCallback)
    {
        var url = "https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey={API_KEY}&searchtext={TEXT}"
        url = url.replace("{API_KEY}", this.apiKey).replace("{TEXT}", text);
        return fetch(url, {
            method: "GET"
        })
        .then(httpResponse => {
            httpResponse.json().then(apiResponse => {
                var gpsExternalId = null;
                var gpsLatitude = null;
                var gpsLongitude = null;
                var gpsLevel = null;
                var street = null;
                var zipCode = null;
                var city = null;
                var country = "COUNTRY_FR";

                if (apiResponse.Response.View.length > 0)
                {
                    if (apiResponse.Response.View[0].Result.length)
                    {
                        const result = apiResponse.Response.View[0].Result[0];
                     
                        if (result.Relevance > 0.9)
                        {
                            gpsExternalId = result.Location.LocationId;
                            gpsLatitude = result.Location.DisplayPosition.Latitude;
                            gpsLongitude = result.Location.DisplayPosition.Longitude;
                            gpsLevel = result.MatchLevel;
                            street = ((result.Location.Address.HouseNumber ? result.Location.Address.HouseNumber: "") + " " + result.Location.Address.Street).trim();
                            zipCode = result.Location.Address.PostalCode;
                            city = result.Location.Address.City;
                        }
                    }
                }
                
                okCallback({
                    gps_external_id: gpsExternalId,
                    gps_lat: gpsLatitude,
                    gps_long: gpsLongitude,
                    gps_level: gpsLevel,
                    street: street,
                    zipCode: zipCode,
                    city: city,
                    country: country
                });
            })
        })
    }

    suggest(caller, text, okCallback)
    {
        var url = "https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey={API_KEY}&query={TEXT}&country=FRA";//&beginHighlight=<b>&endHighlight=</b>";
        
        //var url = "https://autosuggest.search.hereapi.com/v1/autosuggest?apiKey={API_KEY}&at=52.5199813,13.3985138&limit=5&q={TEXT} france";//&beginHighlight=<b>&endHighlight=</b>";
        
        url = url.replace("{API_KEY}", this.apiKey).replace("{TEXT}", text);


        return fetch(url, {
            method: "GET"
        })
        .then(httpResponse => {
            httpResponse.json().then(apiResponse => {
                const hereSuggestions = apiResponse.suggestions;
                var suggestions= [];
                hereSuggestions.forEach(elt => {
                    suggestions.push({id: elt.locationId, label: elt.label, 
                        street: ((elt.address.houseNumber ? elt.address.houseNumber: "") + " " + elt.address.street).trim()
                    })
                })
                /*
                const hereSuggestions = apiResponse.items;
                var suggestions= [];
                hereSuggestions.forEach(elt => {
                    suggestions.push({
                        id: elt.id, 
                        label: elt.title,
                        address: elt.address,
                        position: elt.position,
                        street: elt.address ? ((elt.address.houseNumber ? elt.address.houseNumber: "") + " " + elt.address.street).trim() : ""
                    })
                })*/
                okCallback(suggestions);
            })
        })
    }

    /*
    Méthode permettant de récupérer l'adresse associée aux coordonnées GPS
    */
    reverse(caller, latitude, longitude, okCallback){
        var url = "https://autosuggest.search.hereapi.com/v1/revgeocode?apiKey={API_KEY}&at={LAT}%2C{LONG}&lang=fr-FR";
        url = url.replace("{API_KEY}", this.apiKey).replace("{LAT}", latitude).replace("{LONG}", longitude);

        return fetch(url, {
            method: "GET"
        })
        .then(httpResponse => {
            httpResponse.json().then(apiResponse => {        
                const hereSuggestions = apiResponse.items;
                if (hereSuggestions.length > 0)
                {
                    var suggestion = hereSuggestions[0];
                    if ( suggestion.resultType == "houseNumber" || suggestion.resultType == "street")
                    {
                        var address = {}
                        address.gps_external_id = suggestion.id;
                        address.gps_lat = suggestion.position.lat;
                        address.gps_long = suggestion.position.lng;
                        address.gps_level = suggestion.resultType;
                        address.summary = suggestion.address.label;
                        address.street = suggestion.address ? (suggestion.address.houseNumber ? suggestion.address.houseNumber + " " : "") + (suggestion.address.street ? suggestion.address.street : "") : "";
                        address.zip_code = suggestion.address.postalCode;
                        address.city = suggestion.address.city;
                        address.country_code = "COUNTRY_FR";

                        okCallback(address)
                    }
                    else {
                        okCallback(null)
                    }
                }        
            })
        });
    }

    autosuggest(caller, text, okCallback)
    {
        // On centralise la recherche autour de la position du siège social
        var company = this.businessService.getCompany();
        var nbResults = 10;
        var searchLat = company && company.gps_lat && company.gps_lat != ""  ? company.gps_lat :  "52.5199813";
        var searchLong = company && company.gps_long && company.gps_long != "" ? company.gps_long : "13.3985138";
        
        var url = "https://autosuggest.search.hereapi.com/v1/autosuggest?apiKey={API_KEY}&at=" + searchLat + "," + searchLong + "&in=countryCode:FRA&limit=" + nbResults + "&q={TEXT} france";//&beginHighlight=<b>&endHighlight=</b>";
        url = url.replace("{API_KEY}", this.apiKey).replace("{TEXT}", text);


        return fetch(url, {
            method: "GET"
        })
        .then(httpResponse => {
            httpResponse.json().then(apiResponse => {
                const hereSuggestions = apiResponse.items;
                var suggestions= [];
                
                (!hereSuggestions ? [] : hereSuggestions).forEach(elt => {

                    if (elt.resultType != "chainQuery" && elt.resultType != "categoryQuery")
                    {
                        var newSuggestion = {
                            gps_external_id: elt.id, 
                            label: elt.address ? elt.address.label :"",
                            gps_lat : elt.position && elt.position.lat ? elt.position.lat:  "",
                            gps_long : elt.position && elt.position.lng ? elt.position.lng:  "",
                            gps_level : elt.resultType,
                            street: elt.address ? (elt.address.houseNumber ? elt.address.houseNumber + " " : "") + (elt.address.street ? elt.address.street : "") : "",
                            zipCode: elt.address && elt.address.postalCode ? elt.address.postalCode : "",
                            city: elt.address && elt.address.city? elt.address.city : "",
                            country: "COUNTRY_FR"

                            //street: elt.address ? ((elt.address.houseNumber ? elt.address.houseNumber: "") + " " + elt.address.street).trim() : ""
                        }
                        if (elt.highlights && elt.highlights.title)
                        {
                            var htmlLabel = newSuggestion.label;

                            elt.highlights.title.forEach(h => {
                                var txt = newSuggestion.label.substring(h.start, h.end)
                                htmlLabel = htmlLabel.replace(txt, "<strong>" + txt + "</strong>");
                            })

                            newSuggestion.html = htmlLabel
                        }



                        suggestions.push(newSuggestion);
                    }
                })
                okCallback(suggestions);
            })
        })
    }

    getAutoSuggestDetail(id, okCallback)
    {
        var url = "https://autosuggest.search.hereapi.com/v1/lookup?apiKey={API_KEY}&source=pvid&id={id}";
        url = url.replace("{API_KEY}", this.apiKey).replace("{id}", id);


        return fetch(url, {
            method: "GET"
        })
        .then(httpResponse => {
            httpResponse.json().then(apiResponse => {
                var detail = {
                    gps_external_id: apiResponse.id, 
                    label: apiResponse.title ? apiResponse.title :"",
                    gps_lat : apiResponse.position && apiResponse.position.lat ? apiResponse.position.lat:  "",
                    gps_long : apiResponse.position && apiResponse.position.lng ? apiResponse.position.lng:  "",
                    gps_level : apiResponse.resultType,
                    street: apiResponse.address ? (apiResponse.address.houseNumber ? apiResponse.address.houseNumber + " " : "") + (apiResponse.address.street ? apiResponse.address.street : "") : "",
                    zipCode: apiResponse.address && apiResponse.address.postalCode ? apiResponse.address.postalCode : "",
                    city: apiResponse.address && apiResponse.address.city? apiResponse.address.city : "",
                    country: "COUNTRY_FR"

                    
                    //street: elt.address ? ((elt.address.houseNumber ? elt.address.houseNumber: "") + " " + elt.address.street).trim() : ""
                }
                okCallback(detail)
            })
        })
    }

    getLocationDetail(caller, locationId, okCallback)
    {
        var url = "https://geocoder.ls.hereapi.com/6.2/geocode.json?locationid={LOCATION_ID}&jsonattributes=1&gen=9&apiKey={API_KEY}";
        url = url.replace("{API_KEY}", this.apiKey).replace("{LOCATION_ID}", locationId);

        return fetch(url, {
            method: "GET"
        })
        .then(httpResponse => {
            httpResponse.json().then(apiResponse => {
                var gpsExternalId = null;
                var gpsLatitude = null;
                var gpsLongitude = null;
                var gpsLevel = null;
                var street = null;
                var zipCode = null;
                var city = null;
                var country = "COUNTRY_FR";

                if (apiResponse.response.view.length > 0)
                {
                    if (apiResponse.response.view[0].result.length)
                    {
                        const result = apiResponse.response.view[0].result[0];
                        gpsExternalId = result.location.locationId;
                        gpsLatitude = result.location.displayPosition.latitude;
                        gpsLongitude = result.location.displayPosition.longitude;
                        gpsLevel = result.matchLevel;
                        street = ((result.location.address.houseNumber ? result.location.address.houseNumber: "") + " " + result.location.address.street).trim();
                        zipCode = result.location.address.postalCode;
                        city = result.location.address.city;
                    }
                }

                this.setCallerAddressIsValid(caller, gpsLevel)
                
                okCallback({
                    gps_external_id: gpsExternalId,
                    gps_lat: gpsLatitude,
                    gps_long: gpsLongitude,
                    gps_level: gpsLevel,
                    street: street,
                    zipCode: zipCode,
                    city: city,
                    country: country
                });
            })
        })
    }
}


export default GeocodingAndNavigationService;


