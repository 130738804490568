import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={400}
    height={320}
    viewBox="0 0 400 320"
    backgroundColor="#f5f5f5"
    foregroundColor="#ffde88"
    {...props}
  >
    <rect x="11" y="19" rx="2" ry="2" width="140" height="10" /> 
    <rect x="2" y="38" rx="0" ry="0" width="369" height="38" /> 
    <rect x="2" y="159" rx="0" ry="0" width="369" height="38" /> 
    <rect x="11" y="138" rx="2" ry="2" width="177" height="10" /> 
    <rect x="3" y="279" rx="0" ry="0" width="369" height="38" /> 
    <rect x="6" y="255" rx="2" ry="2" width="96" height="10" />
  </ContentLoader>
)

export default MyLoader

