/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import queryString from 'query-string';
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CardText
} from "reactstrap";
// core components
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import FormInput from '../components/FormInput.jsx';
import { injectIntl , FormattedMessage } from 'react-intl';

import AccountWorkflow from "../../components/workflow/account/accountWorkflow.jsx";
import DefaultButton from "../../components/common/defaultButton.jsx";
import RedirectToProfile from "./RedirectToProfile.jsx";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import BusinessService from "../../../proxies/BusinessService.js";
import Resources from "../../../resources.js";
import WebsiteHelper from "../../../helpers/WebsiteHelper.js";

class MobileApp extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    
    this.businessService = new BusinessService()

    this.state = {
        success: true,
        loading : true,
        application: null
    }
  }
 

  componentDidMount() {
    // Récupération des données de l'application à télécharger
    if (this.props.match.params.code)
    {
        this.businessService.getMobileAppInfos(this, "APP_PLATFORM_TYPE_ANDROID", this.props.match.params.code, (response) => {
            this.setState({application: response.instance})
        }, (httpResponse) => {
            httpResponse.json().then((apiResponse) => {
              this.setState({success : false, loading : false})
            })        
          }
        );
    }
  }

  componentWillUnmount(){
  }

  renderMobileAppDownloadComponent()
  {
    return <>
      <Card className="bg-secondary border-0">
        <CardBody>
          {
            (this.state.success == true) ? 
            (
                (this.state.application) ?
                (
                    <>
                        <CardTitle className="h2 mb-0">
                            {this.state.application.name}
                        </CardTitle>
                        <small className="text-muted">Version {this.state.application.store_current_version}</small>
                        
                        <CardText className="mb-4 t2y-justify">
                            {this.state.application.description}
                        </CardText>
                        <DefaultButton 
                            onClick={() => WebsiteHelper.goTo(this, this.state.application.store_url) }
                            icon={Resources.button_google_play_store} className="t2y-secondary-button">
                            <FormattedMessage id="Default.android_app_download_title"></FormattedMessage>
                        </DefaultButton>  
                    </>
                ) : (<></>)
            ) : (
                <>Impossible de télécharger l'application demandée.</>
            )
          }
          
        </CardBody>  
      </Card>

    </>
  }

  render() {
    return (
      <>
        {this.renderParent()}
        
        <Container className="mt-5 pb-5">
          <FormLoadingIndicator loading={this.state.loading} />

          <Row className="justify-content-center">
            <Col md="8">
              {
                (this.state.loading == false) ? 
                (
                  this.renderMobileAppDownloadComponent()
                )
                :
                (
                  <Card className="bg-secondary border-0">
                    <CardBody>
                        <CardText className="mb-4 text-center">
                          <FormattedMessage id="Default.loading_placeholder" />
                        </CardText>
                      </CardBody>  
                  </Card>      
                )
              }
              
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(MobileApp);
