import React from 'react';
import './PlanningLegend.css'

const PlanningLegend = ({ props }) => {

	const legendItems = [
		{ label: 'Disponible', color: 'white' },
		{ label: 'Jour de fermeture', color: '#E6E6F0' },
		{ label: 'Indisponible ou fermeture exceptionnelle', color: '##61616A' },
		{ label: 'Commande', color: 'green' },
		{ label: 'Commande avec une alerte', color: 'red' },
	]

	return (
		<div className='legendWrapper'>
			<div className='legendContainer'>
				<div className='legendPanel'>
					<div className='legendBody'>
						{
							legendItems.map(items => {
								return <div className='legendRow'>
									<div className='legendColor' style={{ backgroundColor: items.color, marginRight: 5 }}></div>
									<p>{items.label}</p>
								</div>
							})
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default PlanningLegend;