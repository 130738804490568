
import React from "react";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
import classnames from "classnames"
import queryString from 'query-string';
import $ from "jquery";
// reactstrap components
import {
	Modal,
	Button,
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Collapse,

	FormGroup,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Badge,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu, 
	ButtonGroup,
	Table,
	DropdownItem
  } from "reactstrap";
  
import BusinessService from '../../../proxies/BusinessService'
import ShipperService from "../../../proxies/ShipperService";
import SecurityService from "../../../proxies/SecurityService";
import FormLoadingIndicator from "../components/FormLoadingIndicator";
import Resources from "../../../resources";
import ReactDatetime from "react-datetime";
import { injectIntl , FormattedMessage } from 'react-intl';
import FormValidationComponent from "../components/FormValidationComponent";
import DefaultPopover from '../../components/common/defaultPopover';
import DefaultButton from '../../components/common/defaultButton'
import DefaultImage from '../../components/common/defaultImage'
import StringHelper from "../../../helpers/StringHelper.js";
import EmptyResult from "../../components/common/emptyResult";
import NotFoundScene from "../../components/common/notFoundScene.jsx";
import SlidingPane from "react-sliding-pane";
import PortalSearchForm from '../../components/search/portalSearchForm'
import DateHelper from "../../../helpers/DateHelper.js";
import WebsiteHelper from "../../../helpers/WebsiteHelper.js";
import ArrayHelper from "../../../helpers/ArrayHelper.js";
import Rating from "../../components/rating/rating.jsx";
import Message from "../../components/common/message.jsx";
import Codes from "../../../codes.js";
import DefaultLink from "../../components/common/defaultLink.jsx";
import DefaultLabel from "../../components/common/defaultLabel.jsx";
import CallsForBids from "./CallsForBids.jsx";
import CustomModal from "../../../components/CustomModal.jsx";
import ApiV2Service from "../../../proxies/ApiV2Service.js";
import GifLoader from "../../../components/GifLoader.jsx";

var moment = require('moment');
require('moment/locale/fr');

class Orders extends FormValidationComponent {

	DETAIL_TAB = "DETAIL_TAB"
	IN_PROGRESS_TAB = "IN_PROGRESS_TAB"
	COMPLETED_TAB = "COMPLETED_TAB"
	CANCELED_TAB = "CANCELED_TAB"
	CALL_FOR_BIDS_TAB = "CALL_FOR_BIDS_TAB"

	constructor(props)
	{
		super(props)
		
		this.businessService = new BusinessService()
		this.securityService = new SecurityService()
		this.shipperService = new ShipperService()
		this.ApiV2Service = new ApiV2Service()
		this.state = this.getParentState()
	}

	getParentState()
	{
		var tabs = [
			{
				code: this.IN_PROGRESS_TAB,
				position: 0,
				states: ["ORDER_STATE_WAITING_AUTO_VALIDATION", "ORDER_STATE_WAITING_TRANSPORTER_VALIDATION", "ORDER_STATE_VALIDATED", "ORDER_STATE_STARTED"],
				onPress: this.loadOrders.bind(this),
				orders: null,
				yearAndMonthFilter: false,
				selectedStartDate: null,
				selectedEndDate: null,
				loading: false,
				labelId: "Orders.in_progress_tab_label"
			},
			{
				code: this.COMPLETED_TAB,
				position: 1,
				states: ["ORDER_STATE_COMPLETED"],
				onPress: this.loadOrders.bind(this),
				orders: null,
				yearAndMonthFilter: true,
				selectedStartDate: DateHelper.firstDateOfMonth(),
				selectedEndDate: DateHelper.lastDateOfMonth(),
				loading: false,
				labelId: "Orders.completed_tab_label"
			},
			{
				code: this.CANCELED_TAB,
				position: 2,
				states: ["STATE_CANCELED"],
				onPress: this.loadOrders.bind(this),
				orders: null,
				yearAndMonthFilter: true,
				selectedStartDate: DateHelper.firstDateOfMonth(),
				selectedEndDate: DateHelper.lastDateOfMonth(),
				loading: false,
				labelId: "Orders.canceled_tab_label"
			},
			{
				code: this.CALL_FOR_BIDS_TAB,
				position: 3,
				onPress: this.loadCallForBids.bind(this),
				yearAndMonthFilter: false,
				selectedStartDate: DateHelper.firstDateOfMonth(),
				selectedEndDate: DateHelper.lastDateOfMonth(),
				loading: false,
				labelId: "Orders.bids_tab_label"
			}
		]

		// Si un identifiant de commande st passé alors on ajoute un onglet spécifique
		const parsedQueryString = queryString.parse(window.location.search);
		var currentTab = null;

		if (parsedQueryString.mode && parsedQueryString.mode == "call_for_bids" )
		{
			currentTab = tabs[3];

		}
		else if (parsedQueryString.orderId || this.props.match.params.orderNumber)
		{
			tabs[0].position = 1;
			tabs[1].position = 2;
			tabs[2].position = 3;
			tabs[3].position = 4;

			var orderIdTab = {
				code: this.DETAIL_TAB,
				position: 0,
				states: [],
				onPress: this.loadOrders.bind(this),
				orders: null,
				orderId: parsedQueryString.orderId,
				orderNumber: this.props.match.params.orderNumber,
				yearAndMonthFilter: false,
				selectedStartDate: null,
				selectedEndDate: null,
				loading: false,
				labelId: "Orders.order_id_tab_label"
			}

			tabs.unshift(orderIdTab)
		}

		return {
			openedCollapses: [],
			loading: true,
			currentTab: currentTab,
			tabs: tabs,
			cancelModalOpen: false,
			loadingModalOpen: false,
			cancelModalTarget: null,
			loadingModalTarget: null
		}
	}

	updateCancelModalOpenStatus = (id) => {
		this.setState(prevState => ({
			cancelModalOpen: !prevState.cancelModalOpen,
			cancelModalTarget: id,
    }))
	}

	updateLoadingModalOpenStatus = (id) => {
		this.setState(prevState => ({
			loadingModalOpen: !prevState.loadingModalOpen,
			loadingModalTarget: id,
    }))
	}


	getCurrentTab()
	{
		return this.state.currentTab == null ? this.state.tabs[0] : this.state.currentTab;
	}

	getTabByCode(code)
	{
		return ArrayHelper.findFirst(this.state.tabs, (item) => {
			return item.code == code;
		})
	}

	componentWillMount()
	{    
		var currentTab = this.getCurrentTab();
		currentTab.onPress(currentTab);
		//this.loadOrders(currentTab);
	}

	componentDidMount()
	{
	}

	// Récupération des appele d'offres en cours
	loadCallForBids(tab, forceRefresh = false)
	{
		if (tab == null)
		{
			tab = this.state.currentTab
		}


		var state = this.state;
		state.tabs[tab.position].loading = true;
		state.tabs[tab.position].orders = null;
		state.currentTab = state.tabs[tab.position];
		this.setState(state)

		this.businessService.getCallForBids(this, (response) => {
			tab.callsForBids = response.list;
			tab.loading = false;

			var state = this.state;
			state.loading = false;
			
			state.tabs[tab.position] = tab;
			state.openedCollapses = response.list.length == 1 ? ["collapse" + response.list[0].id] : []

			this.setState(state)
		})
	}

	// Récupération des données des commandes
	loadOrders(tab, forceRefresh = false)
	{
		var state = this.state;
		state.tabs[tab.position].loading = true;
		state.tabs[tab.position].orders = null;
		state.currentTab = state.tabs[tab.position];
		this.setState(state)

		this.businessService.getOrdersByStates(this, tab.orderId, tab.orderNumber, tab.states, tab.selectedStartDate, tab.selectedEndDate, (response) => {
			tab.orders = response.orders;
			tab.loading = false;

			var state = this.state;
			
			state.tabs[tab.position] = tab;
			state.openedCollapses = response.orders.length == 1 ? ["collapse" + response.orders[0].id] : []

			this.setState(state)
		})
		
		/*
		this.businessService.getOrders(this, date, selectedOrderId, (response) => {
			this.setState({selectedDate: date, orders : response.orders, selectedOrderId: selectedOrderId})
		})*/
	}

	downloadInvoice(invoiceId)
	{
		this.businessService.downloadInvoice(this, invoiceId, response => {

			const linkSource = `data:application/pdf;base64,${response.instance.base64_content}`;
			const downloadLink = document.createElement("a");
			const fileName = response.instance.filename;

			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
		});
	}

	downloadInvoicingRequest(orderId)
	{
		this.businessService.downloadInvoicingRequestAsPdf(this, orderId, null, response => {

			const linkSource = `data:application/pdf;base64,${response.instance.base64_content}`;
			const downloadLink = document.createElement("a");
			const fileName = response.instance.filename;

			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
		});
	}

	// downloadMissionReport(missionId)
	// {
	// 	this.businessService.downloadMissionReport(this, missionId, response => {
	//
	// 		const attachment = response.value
	// 		const linkSource = `data:application/pdf;base64,${attachment.base64_content}`;
	// 		const downloadLink = document.createElement("a");
	// 		const fileName = attachment.filename;
	//
	// 		downloadLink.href = linkSource;
	// 		downloadLink.download = fileName;
	// 		downloadLink.click();
	// 	});
	// }

	expandedIsOpen(collapseCode, orderId)
	{
		if (this.state.selectedOrderId)
		{
			var exists = this.state.selectedOrderId == orderId
			return exists;
		}
		else
		{
			return this.state.openedCollapses.includes(collapseCode);
		}
	}

	collapsesToggle = collapse => {
		let openedCollapses = this.state.openedCollapses;
		if (openedCollapses.includes(collapse)) {
			this.setState({
			openedCollapses: []
			});
		} else {
			this.setState({
			openedCollapses: [collapse]
			});
		}
	};

	onSearchButtonClick()
	{
		this.setState({searchVisible: !this.state.searchVisible})
	}

	renderSearchModal() {
		if (this.state.searchVisible) {
			var width = window.innerWidth < 1400 ? "90%" : "30%";

			return (
				<SlidingPane
					width={width}
					className="some-custom-class"
					overlayClassName="some-custom-overlay-class"
					isOpen={this.state.searchVisible}
					title=""
					subtitle={null}
					onRequestClose={() => {
						// triggered on "<" on left top click or on outside click
						this.setState({ searchVisible: false });
					}}
				>
					<Container>
						<PortalSearchForm modal_className="force-right" body_safe={true} public={false} />
					</Container>
				</SlidingPane>
			);
		} else {
			return <></>;
		}
	}
	renderOrderArrivalTime(order) {
		if (order.arrival_time) {
			return (
				<>
					<div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
						<div style={{ justifyContent: "center" }}>
							<DefaultLabel icon={Resources.time_logo}>{StringHelper.translate(this, "Orders.line_expected_arrival_time", null, { date: order.arrival_time })}</DefaultLabel>
						</div>
						{this.securityService.isShipper() && DateHelper.calculateHoursRemaining(order.start_date.date, order.arrival_time) >= 0 && order.state.code === "ORDER_STATE_VALIDATED" && <div style={{ justifyContent: "center" }}>
							<DefaultButton className="t2y-secondary-button small" onClick={() => {this.updateCancelModalOpenStatus(order.id)}} type="button">
								Annuler la course
							</DefaultButton>
						</div>}
					</div>
				</>
			)
		} else {
			return <>
				<div style={{ display: "flex", justifyContent: "end", marginBottom: 10 }}>
					{this.securityService.isShipper() && DateHelper.calculateHoursRemaining(order.start_date.date) >= 0 && order.state.code === "ORDER_STATE_VALIDATED" && <div style={{ justifyContent: "center" }}>
						<DefaultButton className="t2y-secondary-button small" onClick={() => {this.updateCancelModalOpenStatus(order.id)}} type="button">
							Annuler la course
						</DefaultButton>
					</div>}
				</div>
			</>
		}
	}


	renderOrderIncidentAlert(order) {
		if (order.ko_incident_alert) {
			return <DefaultPopover 
				placement="right"
				component={<span id={"order_incident_"+ order.id} className={"incident-alert-icon"}>&nbsp;</span>}
				targetId={"order_incident_"+ order.id} content={StringHelper.translate(this, "Orders.line_incident_alert")}
				></DefaultPopover>
		}
		else
		{
			return <></>
		}
	}

	renderMissionIncidentAlert(mission) {
		if (mission.ko_incident_alert) {
			return (
				<DefaultPopover
					placement="right"
					component={
						<span id={"order_incident_" + mission.id} className={"incident-alert-icon"}>
							&nbsp;
						</span>
					}
					targetId={"order_incident_" + mission.id}
					content={StringHelper.translate(this, "Orders.missions.incident_alert")}
				></DefaultPopover>
			);
		} else {
			return <></>;
		}
	}


	renderTabs() {
		const currentTab = this.getCurrentTab();

		return (
			<Row className="justify-content-center mb-2 mt-3" style={{ marginRight: 0, marginLeft: 0 }}>
				<ButtonGroup className="btn-group-toggle" data-toggle="buttons">
					{this.state.tabs.map((tab, tabindex) => {
						return <Button
								key={tabindex}
								className={classnames({ active: currentTab.code === tab.code }) + " tabBtn"}
								onClick={(e) => {
									const selectedTab = this.getTabByCode(tab.code);

									this.setState({ currentTab: selectedTab });
									selectedTab.onPress(selectedTab, true);
									//this.loadOrders(selectedTab, true)
								}}
							>
								{StringHelper.translate(this, tab.labelId)}
							</Button>
					})}
				</ButtonGroup>
			</Row>
		)
	}

	previousMonth() {
		var currentTab = this.getCurrentTab();
		this.yearAndMonthChanged(moment(DateHelper.addMontToDate(-1, currentTab.selectedStartDate)));
	}

	nextMonth() {
		var currentTab = this.getCurrentTab();
		this.yearAndMonthChanged(moment(DateHelper.addMontToDate(1, currentTab.selectedStartDate)));
	}

	yearAndMonthChanged(selectedDate) {
		var currentTab = this.getCurrentTab();
		currentTab.selectedStartDate = DateHelper.firstDateOfMonth(selectedDate);
		currentTab.selectedEndDate = DateHelper.lastDateOfMonth(selectedDate);

		this.loadOrders(currentTab, true);
	}

	renderYearAndMonthSelection() {
		var currentTab = this.getCurrentTab();
		return (
			<Row className="mt-1 justify-content-center" style={{ marginRight: 0, marginLeft: 0 }}>
				<Col md="6" xl="4">
					<FormGroup>
						<InputGroup
							className={classnames({
								focused: this.state.default && this.state.default.dollar,
							})}
						>
							<InputGroupAddon addonType="prepend">
								<InputGroupText style={{ cursor: "pointer" }} onClick={() => this.previousMonth()}>
									<DefaultImage src="fas fa-arrow-circle-left" style={{ fontSize: 20 }}></DefaultImage>
								</InputGroupText>
							</InputGroupAddon>
							<ReactDatetime
								inputProps={{ readOnly: true, style: { color: "var(--gris-fonce)", backgroundColor: "white", cursor: "pointer", textTransform: "capitalize", textAlign: "center" } }}
								value={currentTab.selectedStartDate}
								style={{ border: "none" }}
								multiple={false}
								closeOnSelect={true}
								id="planning_filter"
								dateFormat="MMMM YYYY"
								timeFormat={false}
								locale={"fr-FR"}
								onChange={this.yearAndMonthChanged.bind(this)}
							/>
							<InputGroupAddon addonType="append">
								<InputGroupText style={{ cursor: "pointer" }} onClick={() => this.nextMonth()}>
									<DefaultImage src="fas fa-arrow-circle-right" style={{ fontSize: 20 }}></DefaultImage>
								</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</FormGroup>
				</Col>
			</Row>
		);
	}

	renderTabContent() {
		var currentTab = this.getCurrentTab();
		const parsedQueryString = queryString.parse(window.location.search);
		if (currentTab.loading) {
			return <FormLoadingIndicator context={this} loading={true}></FormLoadingIndicator>;
		} else {
			if (currentTab.code === this.CALL_FOR_BIDS_TAB) {
				return <CallsForBids values={currentTab.callsForBids} parent={this} current={parsedQueryString.id}></CallsForBids>;
			} else {
				if (!currentTab.orders) return <></>;

				return (
					<>
						{currentTab.yearAndMonthFilter ? this.renderYearAndMonthSelection() : <></>}

						{currentTab.orders.length === 0 ? (
							this.renderEmptyResult(
								<EmptyResult
									component={<NotFoundScene width={"90%"} icon={Resources.orders_logo}></NotFoundScene>}
									context="orders"
									separator={false}
									showTitle={false}
									description={StringHelper.translate(this, "Orders.datalist_sorry_default_description")}
								></EmptyResult>
							)
						) : (
							<>
								<Container>
									{this.renderTabContentHeader()}
									{currentTab.orders.map((order, orderIndex) => {
										return this.renderTabContentOrder(order, orderIndex);
									})}
								</Container>
							</>
						)}
					</>
				);
			}
		}
	}

	renderOrderDates(startDate, endDate, nbDays) {
		if (nbDays < 1) {
			return StringHelper.translateWithHtml(this, "Orders.line_date_0.5_day", null, { date: startDate });
		} else if (nbDays === 1) {
			return StringHelper.translateWithHtml(this, "Orders.line_date_1_day", null, { date: startDate });
		} else {
			return StringHelper.translateWithHtml(this, "Orders.line_date_more_than_1_day", null, { startDate: startDate, endDate: endDate, nb: parseInt(nbDays) });
		}
	}

	renderOrderRating(order) {
		if (order.rating && parseFloat(order.rating) > 0) {
			return <Rating iconWidth="0.8em" iconHeight="0.8em" note={parseFloat(order.rating) / 2.0}></Rating>;
		} else {
			return <>/</>;
		}
	}

	renderOrderState(order) {
		const stateLabel = StringHelper.translate(this, "State." + order.state.code);
		if (order.state.code === "ORDER_STATE_WAITING_TRANSPORTER_VALIDATION") {
			return (
				<Badge className="t2y-badge-warning">
					<DefaultImage src={Resources.question_logo}></DefaultImage>&nbsp; {stateLabel}
				</Badge>
			);
		} else {
			return stateLabel;
		}
	}

	renderOrders() {
		return (
			<>
				<FormLoadingIndicator context={this} loading={this.state.loading}></FormLoadingIndicator>
				{this.renderTabs()}
				{this.renderTabContent()}
			</>
		);
	}

	renderCancelOrderConfirmationModal(order) {
		var options = {};
		options.title = "Confirmation";
		options.component = <>Confirmer vous l'annulation de la commande n°{order.number}?</>;
		options.okButtonTitle = "Confirmer";
		options.okButtonCallback = () => {
			this.shipperService.cancelOrder(
				this,
				order.id,
				(response) => {
					this.showModalConfirmation("", "Votre commande a bien été annulée");
					this.loadOrders(this.getCurrentTab(), true);
				},
				(httpErrorResponse) => {
					httpErrorResponse.json().then((apiErrorResponse) => {
						this.showErrorFromResponse(apiErrorResponse);
					});
					this.setState({ loading: false });
				}
			);
		};

		this.showModalConfirmation(options);
	}

	renderAutoValidationMessage() {
		const isTransporter = this.props.user?.is_transporter;
		const company = this.props.company;

		if (isTransporter) {
			if (company.automatic_validation) {
				return <></>;
			} else {
				return (
					<Message type="default">
						Pour augmenter le volume de vos réservations : passez en <DefaultLink href="/admin/commercial">"Réservation immédiate"</DefaultLink> dans les options commerciales :
						<br />
						Vous serez mis en avant par rapport aux transporteurs qui restent en mode "validation manuelle" Lorsqu'un expéditeur choisit votre offre, vous aurez l'exclusivité plutôt que
						d'être mis en concurrence
					</Message>
				);
			}
		} else {
			return <></>;
		}
	}

	renderTotalAmount(order) {
		if (order.discount_amount_ht && order.discount_amount_ht > 0) {
			return (
				<>
					<DefaultPopover
						placement="right"
						component={
							<span id={"order_" + order.id + "old_amount"} className="t2y-order-old-price">
								{StringHelper.formatAmount(order.ht_total_amount)}
							</span>
						}
						targetId={"order_" + order.id + "old_amount"}
						content={order.discount_comment}
					></DefaultPopover>
					<br />
					<span className="t2y-order-new-price">{StringHelper.formatAmount(order.total_amount_with_discount_ht)}</span>
				</>
			);
		} else {
			return StringHelper.formatAmount(order.ht_total_amount);
		}
	}

	renderMissions(order)
	{
		if (order.state.code == Codes.CANCELED && order.sub_state.code == Codes.CANCELLATION_TYPE_SHIPPER)
		{
			return <>
				<Message type="infos">
					Cette demande a été annulée par l'expéditeur !
				</Message>
				{this.renderAutoValidationMessage()}
			</>

		}
		else if (order.state.code == Codes.CANCELED && order.sub_state.code == Codes.CANCELLATION_TYPE_EXPIRATION)
		{
			return <>
				<Message type="infos">
					Trop tard : cette demande a expiré !
				</Message>
				{this.renderAutoValidationMessage()}
			</>

		}
		else if (order.alternative_transporter && order.state.code != Codes.WAITING_FOR_ORDER_VALIDATION)
		{
			return <>
				<Message type="infos">
					Cette demande a déjà été acceptée par un autre transporteur !
				</Message>
				{this.renderAutoValidationMessage()}
			</>
		}


		var dayMonthFormat = this.props.intl.formatMessage({id: "Default.day_month_format"})
		var isTransporter = this.securityService.isTransporter()
		var isShipper = this.securityService.isShipper()

		const hoursRemaining = DateHelper.calculateHoursRemaining(order.start_date.date, order.arrival_time)
		let compensation = {
			duration: "plus de 48h",
			percent: "aucune indemnité"
		}

		if(48 < hoursRemaining) {
			compensation.duration = "plus de 48h"
			compensation.percent = "aucune indemnité"
		} else if(2 > hoursRemaining) {
			compensation.duration = "moins de 2h"
			compensation.percent = "une indemnité de 100% du montant"
		} else if(24 > hoursRemaining) {
			compensation.duration = "moins de 24h"
			compensation.percent = "une indemnité de 80% du montant"
		} else {
			compensation.duration = "moins de 48h"
			compensation.percent = "une indemnité de 50% du montant"
		}

		return <>
			<CustomModal key={'cancel' + order.id} open={this.state.loadingModalTarget === order.id && this.state.loadingModalOpen} setOpen={this.updateLoadingModalOpenStatus}
				disabledCss={true}
				showCloseIcon={false}
				styles={{ modal: {margin: 0, padding: 50, minHeigth: "auto", minWidth: "auto", minHeigth: "auto", textAlign: "center"}}}
			>
				<Card className="d-flex align-items-center justify-content-center bg-faded">
					<GifLoader height={200} width={270}/>
				</Card>
			</CustomModal>
			<CustomModal key={order.id} open={this.state.cancelModalTarget === order.id && this.state.cancelModalOpen} setOpen={this.updateCancelModalOpenStatus}
				disabledCss={true}
				styles={{ modal: {margin: 0, padding: 50, minHeigth: "auto", minWidth: "auto", minHeigth: "auto", textAlign: "center"}}}
			>
				<div>
					<h1>Demande d'annulation</h1>
					<div style={{ marginTop: 10, marginBottom: 20 }}>
						Vous souhaitez effectuer une demande d'annulation sur la commande du {order.start_date_as_string} ?
					</div>
					<div style={{ paddingTop: 10}}>
						Conformément à <a target="_blank" href="https://www.truck2you.com/conditions-generales#annulation-commande">l'article 4.4.11 de nos CGU</a> l'annulation de la course à {compensation.duration}
						<br/>de sa date d'exécution entraîne {compensation.percent}.
					</div>
					<div style={{ marginTop: 20}}>
						<DefaultButton className="t2y-secondary-button small" onClick={this.updateCancelModalOpenStatus} type="button">
							Annuler
						</DefaultButton><br/>
						<span style={{ display: "block", marginTop: 20 }}>
							<a style={{ textDecoration: "underline", cursor: "pointer", fontSize: 15 }} onClick={() => {
								this.updateLoadingModalOpenStatus(order.id)
								this.updateCancelModalOpenStatus()
								try {
									this.ApiV2Service.cancelOrder(order.id).then(response => {
										if(response) {
											this.updateLoadingModalOpenStatus(order.id)
											this.showSuccessModalNotification("", "Votre commande a bien été annulée", "Fermer", {okButtonCallback: () => { window.location.reload() }})
										} else {
											this.updateLoadingModalOpenStatus(order.id)
											this.showErrorModalNotification("", "Votre commande n'a pas été annulée, réessayer ultérieurement")
										}
									})
								} catch(error) {
									console.log(error)
									this.updateLoadingModalOpenStatus(order.id)
									this.showErrorModalNotification("", "Votre commande n'a pas été annulée, réessayer ultérieurement")
									}
							}}>
								Confirmer ma demande
							</a>
						</span>
					</div>
				</div>
			</CustomModal>
			<Row>
				<Col md="12">
					<Table classnames="table" style={{ position: "relative", zIndex: 0 }} responsive>
						<thead>
							<tr>
								<th>{StringHelper.translate(this, "Orders.missions.header_date")}</th>
								<th class={WebsiteHelper.onlyVisibleOnLarge()}>{StringHelper.translate(this, "Orders.missions.header_first_loading_city")}</th>
								<th>{StringHelper.translate(this, "Orders.missions.header_steps")}</th>
								<th>{StringHelper.translate(this, "Orders.missions.header_state")}</th>
								<th>{StringHelper.translate(this, "Orders.missions.header_distance")}</th>
								<th>{StringHelper.translate(this, "Orders.missions.header_duration")}</th>
								<th>{StringHelper.translate(this, "Orders.missions.header_rating")}</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							
					{
						(order.missions) ?
						(
							order.missions.map((mission, missionIndex) => {
								var missionUrl = "/admin/mission/" + mission.id;

								return <>
									<tr  key={order.id + mission.id}>
										<td>
											<h5>
											{
												(isTransporter && mission.missing_driver_alert) ?
												(
													<DefaultPopover 
													placement="right"
													component={<span id={"order_"+ order.id + "_m_" + mission.id} className={mission.missing_driver_alert ? "driver-alert-icon" : ""}>&nbsp;</span>}
													targetId={"order_"+ order.id + "_m_" + mission.id} content={StringHelper.translate(this, "Orders.missions.driver_alert")} >
													</DefaultPopover>
												) : (<></>)
											}
											{this.renderMissionIncidentAlert(mission)}
											{moment(mission.date.date).format(dayMonthFormat)}
											</h5>
										</td>
										<td class={WebsiteHelper.onlyVisibleOnLarge()}>{mission.first_loading_city}</td>
										<td>
											{
												(mission.nb_steps > 2) ?
												(
													<FormattedMessage id="Orders.steps" values={{"nb" : (mission.nb_steps - 2)}} />
												):
												(
													<>/</>
												)
											}
										</td>
										<td>
											{StringHelper.translate(this, (mission.end_km ? "Orders.missions.MISSION_COMPLETED" : (mission.start_km ? "Orders.missions.MISSION_STARTED": "Orders.missions.MISSION_NOT_STARTED")))}
										</td>
										<td>
											{mission.nb_km ? mission.nb_km : "/"}
										</td>
										<td>
											{mission.end_date && mission.duration_as_string ? mission.duration_as_string : "/"}
										</td>
										<td>
											{
												(mission.rating && parseFloat(mission.rating) > 0) ?
												(
													<Rating note={parseFloat(mission.rating) / 2.0}></Rating>
												) :
												(
													<>/</>
												)
											}
										</td>
										<td>
											{
												((!order.refused && ["STATE_CANCELED", "STATE_REMOVED", "ORDER_STATE_WAITING_TRANSPORTER_VALIDATION"].includes(order.state.code) == false && mission.state.code != "MISSION_STATE_DRAFT") || this.securityService.isShipper()) ?
												(
													<DefaultButton className="t2y-secondary-button small" onClick={(e) => WebsiteHelper.goTo(this, missionUrl)} type="button">
														{this.securityService.isTransporter() ? StringHelper.translate(this, "Orders.missions.transporter_button_title") : StringHelper.translate(this, "Orders.missions.shipper_button_title") }
													</DefaultButton>
												) : (<></>)
											}
										</td>
										<td className="text-right">
											{/*
												(mission.start_km != null && mission.start_km > 0) ?
												(
													<UncontrolledDropdown>
														<DropdownToggle
															className="btn-icon-only"
															href="#pablo"
															role="button"
															size="md"
															color=""
															onClick={e => e.preventDefault()}
														>
															<DefaultImage style={{color: "var(--gris-fonce)"}} src={Resources.downloaded_file_logo}></DefaultImage>
														</DropdownToggle>
														<DropdownMenu className="dropdown-menu-arrow" right>
															<DropdownItem href="#pablo" onClick={e => this.downloadMissionReport(mission.id)}>
																{StringHelper.translate(this, "Orders.missions.report_button_title")}
															</DropdownItem>
														</DropdownMenu>
													</UncontrolledDropdown>
												) : (<></>)
											*/}
											
										</td>
									</tr>

								</>
							})

						) : (<></>)
					}

					</tbody>
					</Table>

				</Col>


			</Row>
			{
				(isShipper && order.state.code == "ORDER_STATE_WAITING_TRANSPORTER_VALIDATION") ?
				(
					<>
					<Row>
						<Col xs="12">
							<Message type="default">
							Votre commande est actuellement dans l'attente de la validation du transporteur. Vous pouvez toutefois l'annuler en cliquant sur le bouton suivant
							<DefaultButton className="t2y-removed-button-alt small ml-2" onClick={(elt) => this.renderCancelOrderConfirmationModal(order)}>Annuler cette commande</DefaultButton>
							</Message>
						</Col>
					</Row>
					</>
				) : (<></>)
			}
			
		</>
	}

	render()
	{
		return <></>
	}
}


export default Orders
