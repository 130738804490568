import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import ShipperService from "../../../../proxies/ShipperService";
import GeocodingAndNavigationService from "../../../../proxies/GeocodingAndNavigationService";
import Step from "../step"
import AddressComponent from "./addressComponent";
import { add } from "date-fns";
import Message from "../../common/message";
import ShipperCustomerContactInput from "../../business/shipperCustomerContactInput";
import Title3 from "../../common/title3";
import DefaultLink from "../../common/defaultLink";
import DefaultPopover from "../../common/defaultPopover"
import DeleteButton from "../../common/deleteButton";
import StringHelper from "../../../../helpers/StringHelper";
import ArrayHelper from "../../../../helpers/ArrayHelper";
import DefaultLabel from "../../common/defaultLabel";
import DefaultButton from "../../common/defaultButton";
import Resources from "../../../../resources";
import CustomerAddressContacts from "../../mission/CustomerAddressContacts";

var moment = require('moment');
require('moment/locale/fr');

class AddressSummaryStep extends Step {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.shipperService = new ShipperService();
        this.geocodingAndNavigationService = new GeocodingAndNavigationService();
        this.state = {
            loading: false
        }
    }

    componentWillMount() {
        super.componentWillMount()

        // Si aucune addres alors on retourne sur l'écran de choix sélection d'address
        if (this.getAddress() == null )
        {
            this.props.workflow.goToSpecificStep("MANUAL_ADDRESS_STEP");
        }
        else
        {
            //console.log("AddressSummaryStep:componentWillMount")
            //console.log(this.getAddress())
            this.props.workflow.data = this.getAddress()
        }
    }

    componentDidMount() {
        super.componentDidMount()
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    getAddress()
    {
        if (this.props.workflow.data)
        {
            return this.props.workflow.data;
        }
        else
        {
            return this.props.address
        }
    }

    render() {
        var address = this.getAddress();
        if (!address)
        {
            return <></>
        }
        else
        {
            return (
                <>
                    {this.renderParent()}
                    <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
                    {
                        (address && this.geocodingAndNavigationService.levelIsValid(address.gps_level) == false) ?
                        (
                            <Message type="warning">
                                Cette adresse n'est pas assez précise.
                            </Message>
                        )
                        :
                        (<></>)
                    }
                    <AddressComponent address={address} ></AddressComponent>
                    
                    <CustomerAddressContacts onDefaultContactChanged={this.props.onDefaultContactChanged} defaultContact={this.props.defaultContact} readonly={this.props.readonly} address={address}></CustomerAddressContacts>
                    
                    <Row className="justify-content-center">
                    {
                        (address.id && this.props.readonly == false) ?
                        (
                            <DefaultLink small={true} onClick={(e) => {
                                var nextStep = "ADDRESS_CONFIRMATION_STEP";
                                if (address.id != null)
                                {
                                    nextStep = "MANUAL_ADDRESS_STEP";
                                }

                                this.props.workflow.goToSpecificStep(nextStep)

                            }}>Modifier cette adresse</DefaultLink>
                        ) : (<></>)   
                    }
                    </Row>
                </>
            );
        }
    }
}


export default injectIntl(AddressSummaryStep)

