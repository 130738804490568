
import React from "react";
import classnames from "classnames";

// reactstrap components
import {
    Button,
    Container,
    Form,
    FormGroup,
    UncontrolledPopover,
    UncontrolledTooltip,
    PopoverBody,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
  } from "reactstrap";
import Resources from "../../../resources";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
import FormInput from '../components/FormInput'
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import TransporterService from "../../../proxies/TransporterService"
import SecurityService from "../../../proxies/SecurityService"
import FormUploadImage from "../components/FormUploadImage";
import FormCivilityInput from "../components/form/FormCivilityInput";
import FormHeader from "../components/form/FormHeader";
import FormTrucksInput from "../components/form/FormTrucksInput";
import { injectIntl , FormattedMessage } from 'react-intl';
import FormGroupInput from "../components/form/FormGroupInput";
import DefaultInput from "../../components/common/defaultInput";
import CivilityInput from "../../components/common/civilityInput";
import TrucksInput from "../../components/business/trucksInput";
import StringHelper from "../../../helpers/StringHelper";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import FormFooter from "../../components/form/formFooter";
import DefaultTabs from "../../components/common/defaultTabs";
import Rating from "../../components/rating/rating";
import DefaultPaginatedList from "../../components/common/defaultPaginatedList"
import DateHelper from "../../../helpers/DateHelper";
import Ribbon from "../../components/common/ribbon";
import Codes from "../../../codes";
import ArrayHelper from "../../../helpers/ArrayHelper";
import DefaultButton from "../../components/common/defaultButton";
import Message from "../../components/common/message";
import Title1 from "../../components/common/title1";
import Title2 from "../../components/common/title2";
import Title3 from "../../components/common/title3";
import DefaultLabel from "../../components/common/defaultLabel";


class Driver extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.transporterService = new TransporterService();
        this.securityService = new SecurityService();

        this.createValidationRules();
        this.createDefaultState();
    }

    createDefaultState()
    {
        this.state = {
            loading : false,
            instance : null,
            companyAccountManagementIsChecked:  false,
            instance: {
                civility_code: null,
                first_name: null,
                last_name: null,
                mobile_phone_number: null,
                email: null,
            },
            trucks: [],
            formDatas : {
                civility_code: {value: (this.props.match.params.id) ? null : "CIVILITY_MR"},
                image_url: { value : null }
            }
        }
    }

    updateState(response)
    {
        var instance = this.state.instance;
        instance.state = response.state;
        this.setState({instance :instance})
    }

    createValidationRules()
    {
        this.validationRules = {
            civility_code : { required: true, },
            first_name : { required: true, },
            last_name : { required: true, },
            mobile_phone_number: {
                required : true, 
                rules : [{
                    rule : this.mobilePhoneNumber.bind(this)
                }]
            },
            email : { rules : [{
                rule : this.email.bind(this)
            }]}
        }
    }

    componentWillMount()
    {
        // Récupération des données du conducteur
        if (!this.props.match.params.id == false)
        {
            this.transporterService.getDriver(this, this.props.match.params.id, this.bindData.bind(this));
        }
    }

    bindData(response)
    {
        var driver = response.instance;

        // Préparation des données des camions rattachés
        let trucks = []
        driver.trucks.forEach(truck => {
            trucks.push({id: truck.id, name: this.trucksInputRef.getTruckSummary(truck)})
        })


        this.civilityInputRef.setValue(driver.civility_code)
        this.firstNameInputRef.setValue(driver.first_name)
        this.lastNameInputRef.setValue(driver.last_name)
        this.emailInputRef.setValue(driver.email)
        this.mobilePhoneNumberInputRef.setValue(driver.mobile_phone_number)
        this.trucksInputRef.setValues(trucks)

        var formDatas = {
            image_url : { value : driver.image_url }
        }

        this.setState({
            instance: driver,
            id: driver.id, 
            stateLabel: driver.state_label,
            formDatas: formDatas, 
            loading: false,
            trucks: trucks
        })
    }
    onSubmitForm(e)
    {
        e.preventDefault();
        var formIsValid = this.htmlFormIsValid(e);
        if (formIsValid)
        {
            var postedData = {
                id : this.getHtmlFormControlValue("id"),
                civility_code : this.getHtmlFormControlValue("civility_code"),
                first_name : this.getHtmlFormControlValue("first_name"),
                last_name : this.getHtmlFormControlValue("last_name"),
                email: this.getHtmlFormControlValue("email"),
                mobile_phone_number : this.cleanPhoneNumber(this.getHtmlFormControlValue("mobile_phone_number")),
                image_url: this.getHtmlFormControlValue("image_url"),
                trucks : this.trucksInputRef.getValues().map(truck => { return truck.id }),
            }

            this.transporterService.saveDriver(this, postedData, (response) => {
                this.setState({
                    id : response.id,
                    instance : response
                })

                if(response.warnings.length > 0)
                {
                    var truckWarningComponent = null
                    if (ArrayHelper.findFirst(response.warnings, (item) => { return item == "TRUCK_COMPLETE_FLAG_CHANGED"}) !== null)
                    {
                        truckWarningComponent = this.buildTruckWarningComponent(response);
                        var options = {
                            customFooterButton: <>
                                <DefaultButton className="t2y-secondary-button small" onClick={() => WebsiteHelper.goTo(this, "/admin/drivers")}>
                                    {this.translateMessage("driver.close_go_to_list")}
                                </DefaultButton>
                            </>
                        }

                        this.showCustomModalNotification("", truckWarningComponent, this.translateMessage("driver.close_and_stay"), true, options)
                    }
                    else
                    {  
                        // Alerte concernant le sms non envoyé
                        this.showWarningFromResponse(response, "", " ")
                    }
                }
                else
                {
                    //this.showSuccessModalNotification("Confirmation", "Les données du conducteur ont été enregistrées avec succès")
                    WebsiteHelper.goTo(this, "/admin/drivers?mode=success")
                }
            }, (httpResponse) => {

                // On recherche l'erreur d'expiration
                httpResponse.json().then((apiResponse) => {
                if (apiResponse.invalid_rules && apiResponse.invalid_rules.length > 0)
                {
                    this.showErrorFromResponse(apiResponse)
                }
                else
                {
                    this.setState({userError: true})
                }
                
                });
                
                this.setState({loading: false})
            })
        }
    }

    onDeleteAction(event)
    {
        this.transporterService.deleteDriver(this, this.state.id, (response) => {
            this.updateState(response)
            this.showSuccessModalNotification("Confirmation", "Le conducteur a été supprimé avec succès")
        });
    }

    onActiveAction(event)
    {
        this.transporterService.activeDriver(this, this.state.id, (response) => {
            this.updateState(response)
            this.showSuccessModalNotification("Confirmation", "Le conducteur a été ré-activé avec succès")
        });
    }

    onResendSmsAction(event)
    {
        this.transporterService.resendSms(this, this.state.id, (response) => {
            this.updateState(response)
            if (response.warnings && response.warnings.length > 0)
            {
                this.showErrorModalNotification("", "Une erreur est survenue lors de l'envoi du sms.");
            }
            else
            {
                this.showSuccessModalNotification("Confirmation", "Un sms a été envoyé au numéro de mobile " + this.getHtmlFormControlValue("mobile_phone_number"))
            }
        });
    }

    renderIdTab()
    {
        return <Form className="needs-validation" noValidate onSubmit={e => this.onSubmitForm(e)} >
            <Input type="hidden" id="id" name="id" value={this.state.id} />
            <Row>
            <Col lg="7">
                    <div className="pl-lg-4">
                        <h6 className="heading-small text-muted mb-4">
                            Identité
                        </h6>
                        <Row>
                            <Col lg="12">
                                <FormGroupInput 
                                    intlId={"user.civility"} 
                                    htmlFor={"civility_code"}
                                    type="multiline">
                                    <CivilityInput value={this.state.instance.civility_code} childRef={elt => this.civilityInputRef = elt} manager={this} required={true} id="civility_code"></CivilityInput>
                                </FormGroupInput>
                            </Col>
                            <Col lg="6">
                                <FormGroupInput intlId={"user.first_name"} htmlFor="first_name" type="multiline">
                                    <DefaultInput value={this.state.instance.first_name} required={true} childRef={(elt) => this.firstNameInputRef = elt}  name='first_name' manager={this} />
                                </FormGroupInput>
                            </Col>
                            <Col lg="6">
                                <FormGroupInput intlId={"user.last_name"} htmlFor="last_name" type="multiline">
                                    <DefaultInput value={this.state.instance.last_name} required={true} childRef={(elt) => this.lastNameInputRef = elt}  name='last_name' manager={this} />
                                </FormGroupInput>
                            </Col>
                            <Col lg="6">
                                <FormGroupInput intlId={"user.mobile_phone_number"} htmlFor="mobile_phone_number" type="multiline"
                                    tooltip={{message: this.translateMessage("driver.mobile_phone_number_infos")}}
                                >  
                                    <DefaultInput value={this.state.instance.mobile_phone_number} required={true} childRef={(elt) => this.mobilePhoneNumberInputRef = elt}  name='mobile_phone_number' manager={this} />
                                </FormGroupInput>
                            </Col>
                            <Col lg="6">
                                <FormGroupInput intlId={"user.email"} htmlFor="email" type="multiline">
                                    <DefaultInput value={this.state.instance.email} childRef={(elt) => this.emailInputRef = elt}  name='email' manager={this} />
                                </FormGroupInput>
                            </Col>
                        </Row>
                    </div>
                </Col>
            
            <Col lg="5">
                <div className="pl-lg-4">
                    <h6 className="heading-small text-muted mb-4">
                        Role
                    </h6>
                    <Row>
                        <Col lg="12">
                                <FormGroupInput intlId={"driver.trucks_panel_title"} htmlFor="trucks" type="multiline"
                                    tooltip={{message: this.translateMessage("trucks.multiselect_tooltip")}}
                                >  
                                <TrucksInput values={this.state.trucks} multiple={true} childRef={(elt) => this.trucksInputRef = elt}  manager={this} name="trucks"></TrucksInput>
                                </FormGroupInput>
                        </Col>
                    </Row>
                    <h6 className="heading-small text-muted mb-4">
                        Photo
                    </h6>
                    <Row>
                        <Col lg="12">
                            <FormUploadImage  mode="inline" oldImageUrl={this.state.formDatas.image_url.value} />
                        </Col>
                    </Row>
                </div>
            </Col>
            </Row>
            <FormFooter instance={this.state.instance}></FormFooter>        
        </Form>
    }

    renderRatingTab()
    {
        const url = "/api/transporter/driver/{id}/ratings/{page}/{itemsPerPage}".replace("{id}", this.state.id);

        return <>
            <DefaultPaginatedList 
                cache={true} 
                noResultComponent={
                    <Row className="align-item-center justify-content-center mb-3">
                        <Message type="infos">
                        <>Ce conducteur n'a reçu aucune note pour le moment</>
                        </Message>
                    </Row>
                }
                datasource={{mode:"GET", url:url, pageParameter:"{page}", itemsPerPageParameter: "{itemsPerPage}"}}
                columns={[
                    { name: "Commande", value: (item, index) => { return item.mission.order.number}},
                    { name: "Mission", value: (item, index) => { return DateHelper.toString(item.mission.date, 'DD/MM/YYYY')}},
                    { name: "Type", value: (item, index) => { return StringHelper.translate(this, "driver." + item.type.code)}},
                    { name: "Note", value: (item, index) => { return <Rating iconWidth="1.2em" note={parseFloat(item.rating) / 2.0}></Rating>}}
                ]}
            >

            </DefaultPaginatedList>
        </>
    }

    renderApplicationTab()
    {
        if (!this.state.instance)
            return <></>

        return <>
            <Row>
                <Col lg="6">
                <Card>
                    <CardHeader>
                        <h6 className="heading-small text-muted mb-4">Installation et Application</h6>
                    </CardHeader>
                    <CardBody>
                        {
                            (this.state.instance.device) ?
                            (
                                <>
                                <Row>
                                    <Col lg="6">
                                        <FormGroupInput intlId={"user.device_first_connection"}  type="multiline">
                                            <div><DefaultLabel>{this.state.instance.first_connection_date_as_string}</DefaultLabel></div>
                                        </FormGroupInput>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroupInput intlId={"user.device_last_connection"}  type="multiline">
                                            <div><DefaultLabel>{this.state.instance.last_connection_date_as_string}</DefaultLabel></div>
                                        </FormGroupInput>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroupInput intlId={"user.device_system"}  type="multiline">
                                            <div><DefaultLabel>{this.state.instance.device.type.label}</DefaultLabel></div>
                                        </FormGroupInput>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroupInput intlId={"user.device_system_version"}  type="multiline">
                                            <div><DefaultLabel>{this.state.instance.device.version}</DefaultLabel></div>
                                        </FormGroupInput>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <FormGroupInput intlId={"user.device_applications"}  type="multiline">
                                        <br/>
                                        {
                                            this.state.instance.device.applications.map((application, index) => {
                                                return <>
                                                    <DefaultLabel>{application.name}</DefaultLabel> (v {application.version}) installée le <DefaultLabel>{application.installation_date_as_string}</DefaultLabel>
                                                    <br/>
                                                </>
                                            })
                                        }
                                        </FormGroupInput>
                                    </Col>
                                </Row>
                                </>
                            ) : 
                            (
                                <Row className="mb-3">
                                    <Message type="infos" style={{width:"600px"}}>
                                        <>L'application n'a pas encore été installée</>
                                    </Message>
                                </Row>
                            )
                        }
                    </CardBody>
                    </Card>
                </Col>
                <Col lg="6">
                    <Card>
                        <CardHeader>
                            <h6 className="heading-small text-muted mb-4">Formations</h6>
                        </CardHeader>
                        <CardBody>
                            {
                                (this.state.instance.elearnings && this.state.instance.elearnings.length > 0) ?
                                (   
                                    this.state.instance.elearnings.map((elearning, index) => {
                                        return <>
                                        <Row>
                                            <Col lg="5">
                                                <DefaultLabel>{elearning.label}</DefaultLabel> 
                                            </Col>
                                            <Col lg="7">
                                                { 
                                                    (!elearning.start_date) ?
                                                    (
                                                        <>Pas encore commencée</>
                                                    ) : 
                                                    (   
                                                        (elearning.end_date) ?
                                                        (
                                                            <> terminée le {elearning.end_date_as_string}</>
                                                        ) : (
                                                            <> démarrée le {elearning.start_date_as_string} et pas encore terminée</>
                                                        )
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                        </>
                                    })

                                ) : (
                                    <Row className="mb-3">
                                        <Message type="infos"  style={{width:"600px"}}>
                                        <>Aucune formation n'a été suivie pour le moment</>
                                        </Message>
                                    </Row>
                                )
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    }

    afterTabSelected(currentTab)
    {
        this.setState({currentTab: currentTab})
    }

    render() {
        return  (
            <>
            {this.renderParent()}
            
            <Container className="mt-4" fluid>
                <Row>
                    
                    <Col className="order-xl-1" xl="12">
                    <FormLoadingIndicator loading={this.state.loading}>
                    <Card>
                        <CardHeader>
                            <FormHeader 
                                instance={this.state.instance} 
                                breadCrumb={true}
                                breadCrumbStyle={null}
                                breadCrumbTitle1={"Default.breadcrumb_drivers_title"}
                                breadCrumbTitle1Click={() => WebsiteHelper.goTo(this, "/admin/drivers")}
                                breadCrumbTitle2={this.state.instance ? this.state.instance.summary : ""}
                                disableSubmitButton={true} 
                                allowDeleteButton={false}
                                allowActiveButton={true} 
                                onActiveAction={this.onActiveAction.bind(this)}
                                customActionButtons={[
                                    { 
                                        generate: true,
                                        targetId: "btnResendSms",
                                        buttonIcon: "fas fa-mobile-alt",
                                        buttonText: "Renvoyer le SMS d’activation du compte", 
                                        buttonColor: "t2y-primary-button-color",
                                        modalTitle : "Confirmation", 
                                        modalMessage : "Souhaitez vous renvoyer le SMS pour confirmer le numéro de mobile ?", 
                                        modalKoButtonText: StringHelper.translate(this, "Default.cancel"),
                                        modalOkButtonText: "Renvoyer le SMS d’activation du compte",
                                        onAction: this.onResendSmsAction.bind(this)
                                    },
                                    {
                                        generate: true,
                                        targetId: "btnDeleteDriver",
                                        buttonIcon: Resources.button_delete_icon,
                                        buttonText: StringHelper.translate(this, "Default.delete"), 
                                        buttonColor: "warning",
                                        modalTitle : "Confirmation de suppression", 
                                        modalMessage : "Confirmez-vous la suppression de ce conducteur ?", 
                                        modalKoButtonText: StringHelper.translate(this, "Default.cancel"),
                                        modalOkButtonText: StringHelper.translate(this, "Default.delete"), 
                                        onAction: this.onDeleteAction.bind(this)
                                    }
                                ]}
                            >
                            </FormHeader>
                            {
                                <DefaultTabs childRef={(elt) => this.tabsRef = elt} manager={this} 
                                    afterTabSelected={(currentTab) => this.afterTabSelected(currentTab)}
                                    tabs={[{label: "Identité et coordonnées", code: "ID"}, 
                                        {label: "Application et formation", code:"application"},
                                        {label: "Satisfaction", code:"rating"}]}>
                                </DefaultTabs>
                            }
                            
                        </CardHeader>
                        <CardBody>
                        {
                            (this.state.id && this.state.instance.state.code == Codes.WAITING_FOR_DRIVER_VALIDATION) ?
                            (
                                <Ribbon type="warning" position="top-left" longText={false}>
                                    en attente
                                </Ribbon>
                            ) : (
                                    (this.state.id && this.state.instance.rating) ?
                                    (
                                        <div class="t2y-driver-rating ribbon-top-left" style={{zIndex:9999}}>
                                            <span>
                                            <Rating iconWidth="1.2em" note={parseFloat(this.state.instance.rating) / 2.0}></Rating> 
                                            {this.state.instance.rating_count} évaluation(s)
                                            </span>
                                        </div>
                                    ) : (<></>)
                            )
                        }
                        {
                            (this.state.currentTab && this.state.currentTab.code == "rating") ?
                            (   
                                this.renderRatingTab()

                            ) : (
                                (this.state.currentTab && this.state.currentTab.code == "application") ?
                                (
                                    this.renderApplicationTab()
                                ) : (this.renderIdTab())
                            )
                            
                        }
                        </CardBody>
                    </Card>
                    </FormLoadingIndicator>
                    </Col>
                </Row>
            </Container>
          </>
        );
    }
}


export default injectIntl(Driver)