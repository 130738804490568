import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";

import DefaultInput from "../../common/defaultInput";
import DefaultLink from "../../common/defaultLink";
import PasswordInput from "../../common/passwordInput";
import Resources from "../../../../resources";
import Message from "../../common/message";
import DefaultPopover from "../../common/defaultPopover";
import Title2 from "../../common/title2"
import Title3 from "../../common/title3"
import AddressesInput from "../../business/addressesInput"


import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import SecurityService from "../../../../proxies/SecurityService";
import UserState from '../../user/userState'
import Step from "../step";
import AddressWorkflow from '../address/addressWorkflow'
import DriverWorkflow from '../driver/driverWorkflow'
import AddressComponent from '../address/addressComponent'
import StringHelper from "../../../../helpers/StringHelper";
import { add } from "date-fns";
import DriversInput from "../../business/driversInput";
import TransporterService from "../../../../proxies/TransporterService";

var moment = require('moment');
require('moment/locale/fr');

class TruckAddressAndDriversStep extends Step {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.securityService = new SecurityService();
        this.transporterService = new TransporterService();
        
        this.validationRules = {
            address_id: { required: false}
        }

        this.syncAddressPanelIsVisible = false;
        this.syncDriverPanelIsVisible = false;

        this.state = {
            adresse_selection_mode: true,
            driverPanelIsVisible: false,
            addressPanelIsVisible: false,
            address: null,
            drivers: [],
            formDatas : {
                address_id: {value: null}
            }
        }
    }

    componentWillMount() {
        super.componentWillMount()
    }

    componentDidMount() {
        super.componentDidMount()

        // Préparation des données pour pré-remplir les champs
        const truck = this.props.workflow.getTruck();
        var formDatas = this.state.formDatas;
        formDatas.address_id = { value: truck.address_id }
        formDatas.drivers = { value: truck.drivers }

        this.driversInputRef.setValues(truck.selected_drivers)
        
        this.setState({
            formDatas: formDatas,
            address: truck.address,
            drivers: truck.selected_drivers ? truck.selected_drivers : []
        })
    }

    componentWillUnmount() {
        super.componentWillUnmount()
    }

    getAddress()
    {
        return this.state.address ? this.state.address : this.props.address
    }

    hideAddressOrDriverPanel()
    {
        this.syncAddressPanelIsVisible = false
        this.syncDriverPanelIsVisible = false
        this.setState({addressPanelIsVisible:false, driverPanelIsVisible: false})
    }

    newAddressClick()
    {   
        var isVisible = !this.state.addressPanelIsVisible
        this.syncAddressPanelIsVisible = isVisible

        this.setState({
            addressPanelIsVisible: isVisible
        })

        this.props.workflow.reload();
    }

    newDriverClick()
    {
        var isVisible = !this.state.driverPanelIsVisible
        this.syncDriverPanelIsVisible = isVisible
        this.setState({
            driverPanelIsVisible: isVisible
        })

        this.props.workflow.reload();
    }

    allowNextStep()
    {
        return !this.syncAddressPanelIsVisible && !this.syncDriverPanelIsVisible
    }

    buildTruckInfos()
    {
        var truckInfos = this.props.workflow.getTruck();
        truckInfos.address_id = this.getHtmlFormControlValue("address_id") ? this.getHtmlFormControlValue("address_id")[0] : null;
        truckInfos.selected_drivers = this.driversInputRef.getValues()
        truckInfos.drivers = [];
        
        if (truckInfos.selected_drivers && truckInfos.selected_drivers.length > 0)
        {
            truckInfos.drivers = truckInfos.selected_drivers.map(driver => {return driver.id})
        }

        return truckInfos;
    }

    onLeave()
    {
        this.props.workflow.setInput(this.buildTruckInfos())
    }

    submit(pid)
    {
        var formIsValid = this.htmlFormIsValid(null);

        if (formIsValid)
        {
            // On remonte l'information au workflow
            super.submit(pid)

            var truckInfos = this.buildTruckInfos()
            
            // Enregistrement des données des montants
            this.transporterService.saveTruckAddressAndDrivers(this, truckInfos, (response) => {
                // On remonte l'information au workflow
                super.submit(pid)

                // Mise à jour des données de l'état du camion
                truckInfos.complete = response.complete;
                truckInfos.complete_response = response.complete_response; 

                // On passe à l'étape suivante et on met à jour la données du workflow pour les écrans suivants
                this.props.workflow.goToNextStep(pid, truckInfos)
            })
        }
    }
    
    /*
    onAddressSubmited(address, callback)
    {
        // Récupération des données du camion
        // Rattachement de l'adress au camion
        

        // Passage à l'étape suivante

        this.setState({address: address, adresse_selection_mode: false})

        this.props.workflow.reload();
    }*/

    render() {
        if (this.syncAddressPanelIsVisible)
        {
            return <AddressWorkflow 
            creation={true} 
            customer={false}
            autocompletePlaceHolder={this.translateMessage("address.autocomplete_transporter_placeholder")}
            addressConfirmationAllowOperationalAddress={true}
            onAddressSubmited={(data) => { 
                this.setState({loading: true})
                // Création de la nouvelle adresse
                this.businessService.createAddress(null, data, (response) => {
                    // Récupération de l'identifiant de la nouvelle adresse
                    const addressId = response.id;

                    // Rechargement de la liste des addresses
                    this.addressInputRef.dataBind((input) => {
                        // Préselection de l'adresse
                        input.setValue(addressId);
                        var formDatas = this.state.formDatas;
                        formDatas.address_id =  { value : addressId}
                        
                        // Fermeture de la popup et enregistrement de la nouvelle donnée
                        this.syncAddressPanelIsVisible = false
                        this.setState({ formDatas: formDatas, addressPanelIsVisible: false, loading: false });
                
                        // Rechargement pour afficher le bouton d'étape suivante
                        this.props.workflow.reload();
                    });
                })
            }
            }></AddressWorkflow>
        }
        else if (this.syncDriverPanelIsVisible)
        {
            return <DriverWorkflow 
                id="driverCreationWorkflow"
                onDriverSubmited={(driverInfos) => { 
                    this.setState({loading: true})

                    // Ajout du conducteur aux valeurs déjà selectionnées
                    var formDatas = this.state.formDatas;
                    formDatas.drivers.value.push(driverInfos.id)

                    var selectedValues = this.driversInputRef.getValues() ? this.driversInputRef.getValues() : [];
                    selectedValues.push({id: driverInfos.id, name: driverInfos.summary})

                    console.log(JSON.stringify(selectedValues))

                    //this.driversInputRef.clear();
                    this.driversInputRef.dataBind(input => {
                        //input.setValues(selectedValues)

                        // Mise à jour des données du workflow
                        var truckInfos = this.buildTruckInfos()
                        truckInfos.selected_drivers = selectedValues
                        truckInfos.drivers = [];
                        
                        if (truckInfos.selected_drivers && truckInfos.selected_drivers.length > 0)
                        {
                            truckInfos.drivers = truckInfos.selected_drivers.map(driver => {return driver.id})
                        }

                        this.props.workflow.setInput(truckInfos)

                        // Fermeture de la popup et enregistrement de la nouvelle donnée
                        this.syncDriverPanelIsVisible = false
                        this.setState({ formDatas: formDatas, drivers: selectedValues, driverPanelIsVisible: false, loading: false });
                        this.props.workflow.reload();
                    })
                }}>
            </DriverWorkflow>
        }
        
        return (
            <>
                {this.renderParent()}
                <Row className="justify-content-center">
                    <Message type="infos">
                        Nous allons renseigner le lieu de stationnement ainsi que le ou les conducteurs autorisé(s) à conduire ce véhicule
                    </Message>
                </Row>
                <Row>
                    <Col className="mb-5" lg="12">
                        <Row>
                            <Title3>{StringHelper.translate(this, "truck.edit_address")}</Title3> 
                        </Row>
                        <Row>
                            <Col lg="12">
                                <AddressesInput required={true}  childRef={(elt) => this.addressInputRef = elt} multiple={false} manager={this} name="address_id" />
                                <DefaultLink small={true} onClick={(e) => this.newAddressClick()} style={{paddingTop:"5px", float:"right"}} icon={Resources.button_add_icon}>Créer un nouveau lieu de stationnement</DefaultLink>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="mb-5" lg="12">
                        <Row>
                            <Title3>{StringHelper.translate(this, "truck.edit_drivers")} <DefaultPopover content={StringHelper.translate(this, "truck.edit_drivers_message")}></DefaultPopover></Title3>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <DriversInput  childRef={elt => this.driversInputRef = elt} required={true} multiple={true} manager={this} name="drivers" add={true}></DriversInput>                      
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}


export default injectIntl(TruckAddressAndDriversStep)

