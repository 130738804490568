import ProxyService from './ProxyService'

class SearchService extends ProxyService {
    
    search(caller, extended, trucksInfos, datesInfos, addressInfo, parentSearchId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});
    
        var dataToPost = {
            "days": datesInfos.dates,
            "half_day": datesInfos.half_day,
            "properties": trucksInfos,
            "address": addressInfo,
            "extended": extended,
            "use_saturday": datesInfos.use_saturday,
            "use_sunday": datesInfos.use_sunday,
            "parent_search_id": parentSearchId
        }

        const relativeUrl = this.isConnected() ? "/api/search" : "/search";

        this.post(caller, relativeUrl, dataToPost, okCallback, errorCallback)
    }

    extendedSearch()
    {

    }

    getSearchResults(caller, request, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});
    
        let apiUrl = this.apiUrls["PUBLIC_SEARCH_RESULTS"];
        this.get(caller, apiUrl + request.search_id + "/" + request.order + "/" + request.from + "/" + request.nb, okCallback, errorCallback);
    }    

    getSearchFilters(caller, searchId, okCallback)
    {
        this.get(null, '/search/filters/' + searchId, okCallback);
    }

    applyCkbFilters(caller, searchId, propertyId, propertyValueId, use, okCallback, errorCallback = null)
    {
        this.post(caller, '/search/filters/' + searchId, {
            properties: [
                {id: propertyId, value: propertyValueId, use: use}
            ]

        }, okCallback, errorCallback);
    }

    clearFilters(caller, searchId, okCallback, errorCallback = null)
    {
        if (caller != null)
        {
            caller.setState({loading: true})
        }
        this.post(caller, '/search/filters/clear/' + searchId, {}, okCallback, errorCallback);
    }

    applyMultiRangesFilter(caller, searchId, type, minValue, maxValue, okCallback, errorCallback = null)
    {
        this.post(caller, '/search/filters/' + searchId, {
            multi_ranges: [
                {type: type, min_value: minValue, max_value: maxValue}
            ]

        }, okCallback, errorCallback);
    }

    applySliderFilter(caller, searchId, type, minValue, maxValue, okCallback, errorCallback = null)
    {
        this.post(caller, '/search/filters/' + searchId, {
            ranges: [
                {type: type, min_value: minValue, max_value: maxValue}
            ]

        }, okCallback, errorCallback);
    }
}


export default SearchService;