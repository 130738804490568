export default class MessageQueueHelper
{
    static addMessage()
    {
        
    }

    static getMessages()
    {

    }
}