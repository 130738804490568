import "../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components
 import {
   Form
 } from "reactstrap";
import RepositoryService from "../../../proxies/RepositoryService"
import { injectIntl , FormattedMessage } from 'react-intl';

import { Multiselect } from 'multiselect-react-dropdown';
import DefaultInput from "../common/defaultInput";
import StringHelper from "../../../helpers/StringHelper";
import TagsInput from "react-tagsinput";

class OperationalAddressesInput extends DefaultInput     {

    constructor(props)
    {
        super(props, true, function(element){
            return element.text;
        });
        this.multiselectRef = React.createRef();
        this.repositoryService = new RepositoryService();

        this.state = {
            datas: [],
            selectedValues: []
        }
    }

    componentWillMount()
    {
        super.componentWillMount();
        this.dataBind();
    }

    componentDidMount()
    {
        super.componentDidMount();
    }

    hasValue()
    {
        return this.getValues() && this.getValues().length > 0;
    }

    dataBind(callback = null)
    {
        this.repositoryService.getAddresses(this, (response) => {
            var datas = []

            if (this.props.emptyElement && this.props.emptyElement == true)
            {
                datas.push({id : "", name: typeof this.props.placeholder !== "undefined" ? this.props.placeholder : "..."})
            }
            
            response.addresses.forEach(element => {
                datas.push({id : element.id, name: element.name});
                    
            });

            this.setState({datas : datas});
            
            if (callback)
            {
                callback(this)
            }
        })
    }
    setValues(values, refresh = false)
    {
        this.setState({selectedValues: values})
    }

    clear()
    {
        this.multiselectRef.current.resetSelectedValues();
        this.setState({datas: [], selectedValues: []})
    }

    getValues()
    {
        return this.state.selectedValues;
    }

    onSelect(selectedList, selectedItem) {
        this.setValues(selectedList)
    }
     
    onRemove(selectedList, removedItem) {
        this.setValues(selectedList)
    }

    renderMultiSelect()
    {
        return <Multiselect
            id="addresses-input"
            ref={this.multiselectRef}
            options={this.state.datas} // Options to display in the dropdown
            selectedValues={this.getValues()}
            onSelect={this.onSelect.bind(this)} // Function will trigger on select event
            onRemove={this.onRemove.bind(this)} 
            displayValue="name" // Property name to display in the dropdown options
            placeholder={StringHelper.translate(this, "addresses.multiselect_placeholder")}
            emptyRecordMsg={StringHelper.translate(this, "addresses.multiselect_empty_result")}
            hidePlaceholder={true}
            style={{
                chips: { background: "var(--jaune)", color: "var(--gris-fonce)", fontSize:"16px"},
                option: {
					color: "black",
					background: "white",
					fontWeight: "normal",
					fontSize: "15px",
				}
            }}
        />
    }

    render()
    {
        return this.rendeInner(this.renderMultiSelect())
    }
  }
 
  export default injectIntl(OperationalAddressesInput);