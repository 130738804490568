import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import Resources from "../../../../resources";
import AddressSelectionStep from "./addressSelectionStep";
import AddressConfirmationStep from './addressConfirmationStep';
import AddressSummaryStep from './addressSummaryStep';
import AddressContactStep from "./addressContactStep";

var moment = require('moment');
require('moment/locale/fr');

class AddressWorkflow extends Workflow {

    constructor(props) {
        super(props)
        this.businessService = new BusinessService();
        this.data = this.props.data ? this.props.data : null;

        this.state = {
            visible: false,
            current_step: this.props.current_step ? this.props.current_step : "ADDRESS_SUMMARY_STEP",
            steps: {
                "MANUAL_ADDRESS_STEP": {
                    id: "MANUAL_ADDRESS_STEP",
                    position: 1,
                    component: <AddressSelectionStep context={this.props.context} 
                        forceContact={this.props.forceContact}
                        addressSelectionGeolocalisation={this.props.addressSelectionGeolocalisation}
                        addressSelectionComponent={this.props.addressSelectionComponent} 
                        autocompletePlaceHolder={this.props.autocompletePlaceHolder}
                        onAddressSubmited={this.props.onAddressSubmited}
                        address={this.props.address} 
                        creation={this.props.creation}
                        backgroundColor={"white"} 
                        textColor={"#1e2635"} 
                        iconColor={"#FFC42B"} 
                        workflow={this} 
                        id="MANUAL_ADDRESS_STEP"
                        skipAddressConfirmation={this.props.skipAddressConfirmation}
                        customer={this.props.customer}>
                    </AddressSelectionStep>,
                    nexts: [
                        {   
                            step: "ADDRESS_CONFIRMATION_STEP", 
                            label: "Selectionner cette adresse", 
                            icon: Resources.button_success_icon,
                            visible: false,
                            //condition: (function(component) { return !this.props.forceContact;}).bind(this)
                        }
                    ]
                },
                "ADDRESS_CONTACT_STEP" : {
                    id: "ADDRESS_CONTACT_STEP",
                    position: 2,
                    component: <AddressContactStep 
                        address={this.props.address} workflow={this} id="ADDRESS_CONTACT_STEP"
                        customer={this.props.customer}
                        readonly={this.props.readonly}
                        defaultContact={this.props.defaultContact}
                        context={this.props.context}
                        onDefaultContactChanged={this.props.onDefaultContactChanged} >
                    </AddressContactStep>,
                    nexts: [
                        {   
                            step: "ADDRESS_CONFIRMATION_STEP", 
                            label: "Je ne souhaite pas préciser de contact", 
                            icon: Resources.button_success_icon,
                            visible: true,
                            //condition: (function(component) { return this.props.forceContact;}).bind(this)
                        }
                    ]
                },
                "ADDRESS_CONFIRMATION_STEP": {
                    id: "ADDRESS_CONFIRMATION_STEP",
                    position: 3,
                    component: <AddressConfirmationStep 
                        creation={this.props.creation}
                        confirmationButton={this.props.confirmationButton}
                        addressConfirmationAllowOperationalAddress={this.props.addressConfirmationAllowOperationalAddress}
                        onAddressSubmited={this.props.onAddressSubmited} address={this.props.address} 
                        workflow={this} id="ADDRESS_CONFIRMATION_STEP"
                        customer={this.props.customer}>
                    </AddressConfirmationStep>,
                    nexts: [
                        {   
                            step: "ADDRESS_SUMMARY_STEP", 
                            label: function(component) { 
                                return this.props.confirmationButton ? this.props.confirmationButton.label : "Choisir cette adresse" 
                            }.bind(this), 
                            icon: Resources.button_success_icon,
                        }
                    ],
                    links: [
                        {step: "MANUAL_ADDRESS_STEP", label: "Saisir une nouvelle adresse" }
                    ]
                },
                "ADDRESS_SUMMARY_STEP": {
                    id: "ADDRESS_SUMMARY_STEP",
                    position: 3,
                    component: <AddressSummaryStep 
                        address={this.props.address} workflow={this} id="ADDRESS_SUMMARY_STEP"
                        customer={this.props.customer}
                        readonly={this.props.readonly}
                        defaultContact={this.props.defaultContact}
                        context={this.props.context}
                        onDefaultContactChanged={this.props.onDefaultContactChanged} >
                    </AddressSummaryStep>,
                    
                }
            }
        }
    }

    isCustomerMode()
    {
        return this.props.customer && this.props.customer == true;
    }

    componentWillMount() {
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    start(inputValue)
    {
        this.setState({ 
            input: inputValue,
            visible: true
        })
    }

    render() {
        return (
            <>
                {this.renderCurrentStep()}
            </>
        );
    }
}


export default injectIntl(AddressWorkflow)

