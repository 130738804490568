import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";

import Message from '../../common/message'
import DefaultInput from "../../common/defaultInput";
import DefaultCheckbox from '../../common/defaultCheckbox'
import DefaultPopover from '../../common/defaultPopover'
import PasswordInput from "../../common/passwordInput";
import Resources from "../../../../resources";
import Title2 from "../../common/title2"
import Title3 from "../../common/title3"
import FormGroupInput from '../../../pages/components/form/FormGroupInput'

import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import SecurityService from "../../../../proxies/SecurityService";
import UserState from '../../user/userState'
import Step from "../step";
import StringHelper from "../../../../helpers/StringHelper";
import TransporterService from "../../../../proxies/TransporterService";

var moment = require('moment');
require('moment/locale/fr');

class TruckAmountsStep extends Step {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.securityService = new SecurityService();
        this.transporterService = new TransporterService();
        this.validationRules = {
        }

        this.state = {
            half_day: false
        }
    }

    componentWillMount() {
        super.componentWillMount()

        var truck = this.props.workflow.getTruck();
        if (truck.cost_half_day && truck.cost_half_day > 0)
        {
            this.setState({half_day: true})
        }
    }

    componentDidMount() {
        super.componentDidMount()

        // Pré-remplissage des champs
        var truck = this.props.workflow.getTruck();
        this.costDayInputRef.setValue(truck.cost_day);
        if (truck.cost_half_day && truck.cost_half_day > 0)
        {
            this.halfDayModeRef.setValue(true)
            this.costHalfDayInputRef.setValue(truck.cost_half_day);
        }

        this.costKmInputRef.setValue(truck.cost_km);
        this.costHourInputRef.setValue(truck.cost_hour);
    }

    componentWillUnmount() {
        super.componentWillUnmount()
    }

    buildTruckInfos()
    {
        var truckInfos = this.props.workflow.getTruck();
        truckInfos.cost_day = this.costDayInputRef.getValue();
        
        truckInfos.cost_half_day = this.halfDayModeRef.getValue() ? this.costHalfDayInputRef.getValue() : 0;
        
        truckInfos.cost_km = this.costKmInputRef.getValue();
        truckInfos.cost_hour = this.costHourInputRef.getValue();
        
        return truckInfos
    }

    onLeave()
    {
        this.props.workflow.setInput(this.buildTruckInfos())
    }

    submit(pid)
    {
        var formIsValid = this.htmlFormIsValid(null);

        if (formIsValid)
        {
            var truckInfos = this.buildTruckInfos()

            // Enregistrement des données des montants
            this.transporterService.saveTruckAmounts(this, truckInfos, (response) => {
                // On remonte l'information au workflow
                super.submit(pid)

                // Mise à jour des données de l'état du camion
                truckInfos.complete = response.complete;
                truckInfos.complete_response = response.complete_response; 

                // On passe à l'étape suivante et on met à jour la données du workflow pour les écrans suivants
                this.props.workflow.goToNextStep(pid, truckInfos)
            })
        }
    }
    
    renderDurantionAndDistance(km, hours, minutes, seconds, pattern = "{km} kilomètres et/ou {duration}")
    {
        var duration = "";

        if (hours)
        {
            duration = duration + hours + " heure(s)"
        }
        if (minutes)
        {
            duration = duration + (hours ? " et " : " ") + minutes + " minute(s)"
        }
        if (seconds)
        {
            duration = duration + (hours || minutes ? " et " : " ") + seconds + "seconde(s)"
        }

        return pattern.replace("{km}", km).replace("{duration}", duration.trim())
    }

    render() {
        var truckInfos = this.props.workflow.getTruck();

        return (
            <>
                {this.renderParent()}
                <Row className="justify-content-center">
                    <Message type="infos">
                        <label className="t2y-justify">
                            Merci de renseigner votre tarif pour ce véhicule. 
                        </label>
                    </Message>
                </Row>
                <Row>
                    <Col lg="12">
                        <Row>
                            <Title3>Forfait</Title3> 
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Message style={{width:"100%"}} type="infos" oneLine={true}>
                                    <label className="ty2-default-input-description">{StringHelper.translate(this, "truck.toll_message")}</label>
                                </Message>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <FormGroupInput label={StringHelper.translate(this, "truck.edit_cost_day") +  " (" + 
                                    this.renderDurantionAndDistance(truckInfos.bnd_distance_per_day, truckInfos.bnd_hours_per_day, truckInfos.bnd_minutes_per_day, null, StringHelper.translate(this, "truck.edit_cost_day_light_message")) + 
                                ")"} htmlFor="cost_day" type="multiline">
                                    <DefaultInput required={true} childRef={(elt) => this.costDayInputRef = elt} icon={Resources.form_euro} type="number" name='cost_day' manager={this} />
                                </FormGroupInput>
                            </Col>
                        </Row>
                        <Row>
                                <Col lg="12">
                                    <DefaultCheckbox id="use_cost_half_day"
                                        right={false}
                                        childRef={(elt) => this.halfDayModeRef = elt} 
                                        defaultValue={this.state.half_day}
                                        onChange={(e) => this.setState({half_day: !this.state.half_day})}
                                        content={"Proposer ce véhicule pour une demi journée"}
                                    />
                                </Col>
                        </Row>
                            {
                                (this.state.half_day) ?
                                (
                                    <Row>
                                    <Col lg="12">
                                        <FormGroupInput label={StringHelper.translate(this, "truck.edit_cost_half_day") +  " (" +
                                            this.renderDurantionAndDistance(truckInfos.bnd_distance_per_half_day, truckInfos.bnd_hours_per_half_day, truckInfos.bnd_minutes_per_half_day, null, StringHelper.translate(this, "truck.edit_cost_half_day_light_message")) + 
                                        ")"} htmlFor="cost_half_day" type="multiline">
                                            <DefaultInput childRef={(elt) => this.costHalfDayInputRef = elt}  icon={Resources.form_euro} type="number" name='cost_half_day' manager={this} />
                                        </FormGroupInput>
                                    </Col>
                                    </Row>
                                ) : (<></>)
                            }
                    </Col>
                    <Col lg="12">
                        <Row>
                            <Title3>Suppléments</Title3>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Message style={{width:"100%"}} type="infos" oneLine={true}>
                                    <label className="ty2-default-input-description">{StringHelper.translate(this, "truck.toll_message")}</label>
                                </Message>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <FormGroupInput label={StringHelper.translate(this, "truck.edit_km_sup")} htmlFor="cost_km" type="multiline">
                                    <DefaultInput required={true} childRef={(elt) => this.costKmInputRef = elt} icon={Resources.form_euro} type="number" name='cost_km' manager={this} />
                                </FormGroupInput> 
                            </Col>
                            <Col lg="12">
                                <FormGroupInput label={StringHelper.translate(this, "truck.edit_hour_sup")} htmlFor="cost_hour" type="multiline"
                                    tooltip={{message:StringHelper.translate(this, "truck.edit_hour_sup_message")}}>
                                    <DefaultInput required={true} childRef={(elt) => this.costHourInputRef = elt} icon={Resources.form_euro} type="number" name='cost_hour' manager={this} />
                                </FormGroupInput>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </>
        );
    }
}


export default injectIntl(TruckAmountsStep)

