
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    Col,
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    NavLink,
    Modal,
    Button
  } from "reactstrap";
import { Calendar } from 'react-date-range';

import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator"
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import FormHourInput from "../../pages/components/form/FormHourInput";
import ReactDatetime from "react-datetime";
import RepositoryService from "../../../proxies/RepositoryService";

import { injectIntl , FormattedMessage } from 'react-intl';
import FormRepository from "../../pages/components/form/FormRepository";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DefaultInput from "./defaultInput";
import ReactPasswordStrength from 'react-password-strength'
import StringHelper from "../../../helpers/StringHelper";
import Message from "./message";

var moment = require('moment');
require('moment/locale/fr');

class PasswordInput extends DefaultInput {

    constructor(props) {
        super(props)

        this.internalValid = false;
        this.state = {
            visible: false,
            hidden_value : null,
            size: false,
            capital: false,
            tiny: false,
            number: false,
            special: false,
            nb_valid_chars: 0,
            valid_chars: false,
            valid: false
        }
    }

    componentDidMount()
    {
        super.componentDidMount()
    }

    componentWillMount()
    {
        super.componentWillMount()
    }

    isValid()
    {
        return this.state.valid ? this.state.valid : this.internalValid
    }
    
    // Consultation du mot de passe
    changeView()
    {
        var visible = !this.state.visible

        if (visible)
        {
            $("#" + this.props.id).attr("type", "text")
        }
        else
        {
            $("#" + this.props.id).attr("type", "password")
        }

        this.setState({visible: visible})
    }

    renderInvalidCaracters()
    {
        var text = "";
        if (this.state.capital == false)
        {
            text += ", une majuscule";
        }
        if (this.state.tiny == false)
        {
            text += ", une minuscule";
        }
        if (this.state.number == false)
        {
            text += ", un chiffre";
        }
        if (this.state.special == false)
        {
            text += ", un caractère spécial";
        }

        text = "au moins " + (3 - this.state.nb_valid_chars) + " critères parmis " + text.substring(1);

        return text
    }

    renderInvalidPassword()
    {
        if (this.state.value == null || this.state.value.trim().length == 0)
        {
            return <></>
        }



        var message = StringHelper.translateWithHtml(this, "account.password_information")

        return <>
            <Message type="infos" style={{marginTop: "10px"}}>
                <label>{message}</label>
            </Message>
             {
             /*   {
                    (this.state.size == false) ?
                    (
                        <>au moins 6 caractères</>
                    ) : (<></>)
                }
                {
                    (this.state.valid_chars == false) ?
                    (
                        (this.state.capital == false) ? 
                        (
                            <>
                                au mois une majuscule
                            </>
                        ) : (<></>)
                        (this.state.tiny == false) ? 
                        (
                            <>au mois une minuscule</>
                        ) : (<></>)
                    
                        (this.state.number == false) ? 
                        (
                            <>au moins un chiffre</>
                        ) : (<></>)
                        
                        (this.state.special == false) ? 
                        (
                            <>au moins un caractère spécial</>
                        ) : (<></>)
                    ) : (<></>)
                }*/
            }
        </>
    }

    renderValidPassword()
    {
        return <>
        </>
    }

    onPasswordChange(value)
    {
        var size = false
        
        if (value != null && value.trim().length >= 6)
        {
            size = true;
        }

        const capital = StringHelper.notEmptyValue(value) ? StringHelper.checkRegExp(value, /[A-Z]/) : false;
        const tiny = StringHelper.notEmptyValue(value) ? StringHelper.checkRegExp(value, /[a-z]/) : false;
        const number = StringHelper.notEmptyValue(value) ? StringHelper.checkRegExp(value, /[0-9]/) : false;
        const special = StringHelper.notEmptyValue(value) ? StringHelper.checkRegExp(value, /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/) : false;

        const nbValidChars = (capital ? 1 : 0) + (tiny ? 1 : 0) + (number ? 1 : 0) + (special ? 1 : 0);
        const validChars = ( nbValidChars >= 3)
        const valid = size && validChars;

        this.internalValid = valid

        this.setState({
            size: size,
            capital: capital,
            tiny: tiny,
            number: number,
            special: special,
            valid: valid,
            nb_valid_chars: nbValidChars,
            valid_chars: validChars
        })

        this.onChange(value)
    }

    render() {
        var style = {
            position: "fixed",
            right: "10px"
        }
        return <>
        { 
            this.rendeInner(
            <>
                <input 
                    type="password"
                    error-parent-container={this.props.id + "-main-container"}
                    id={this.props.id}
                    name={this.props.id}
                    className="t2y-default-input-control"
                    onChange={(e)=> this.onPasswordChange(e.currentTarget.value)}
                    autoComplete="OFF" 
                    value={this.state.value}
                    style={{width:"60%" }}
                    placeholder={!this.props.placeholder ? "..." : this.props.placeholder}
                />
                <span className="btn-inner--icon t2y-default-input-icon-container" style={{color: "black"}}>
                    {
                        (this.state.visible) ?
                        (
                            <i className="fas fa-eye" style={{...style}} onClick={(e) => this.changeView()}></i>
                        )
                        :
                        (
                            <i className="fas fa-eye-slash" style={{...style}} onClick={(e) => this.changeView()}></i>
                        )
                    }
                </span>
            </>
            )
        }
        {
            (this.props.creation) ?
            (
                (this.isValid()) ? 
                (
                    this.renderValidPassword()
                ) :
                (
                    this.renderInvalidPassword()
                )

            ) :(<></>)
        }
        </>
    }
}

export default injectIntl(PasswordInput)
