
// Données sur la société
const globalConfig = {
    localization : 'fr',
    logoUrl : '~.assets/img/logo/truck2you_logo.svg',
    logoMenuUrl: '../../assets/img/logo/t2y_logo_menu.png',
    appName : 'Truck 2 You',
    companyName : 'Truck 2 You',
    companyNameWebsite : 'https://truck2you.com',
    allowMobileApplicationDownload: true,
    mode : 'recette',
    hereAppId: 'lvF127hC04wkp7cXKiDL',
    hereAppCode: 'P-vntSgzpgTRxTY7Qvhmodfee9s7FHhY4wAlX6tvIJQ',
    hereApiKey: 'P-vntSgzpgTRxTY7Qvhmodfee9s7FHhY4wAlX6tvIJQ'
}


const development = {
    wpSite: 'http://localhost/test2.php',
    bndFe: 'http://localhost:3000',
    bndApi:'http://127.0.0.1:8000', //'http://127.0.0.1:8000', //'https://api-test.truck2you.com', //'http://192.168.1.123:8080/public', //'http://192.168.1.14:8080/public', // 'http://localhost:8000',
    apiv2: 'http://127.0.0.1:8002',
    debug: false,
    showSideBar: false,
    stripeSecretKey: "sk_test_wbUe5C90V8FhFm2WerkcsU1y00PGJb9CIr",
    stripePublicKey: "pk_test_AqPriqhulED2f6zd7qHOOydS00Xfv1WJ57",
    ...globalConfig,
}

const recette = {
    wpSite: 'http://localhost/test2.php',
    bndFe: 'https://app-test.truck2you.com',
    bndApi:'https://api-test.truck2you.com', //'http://127.0.0.1:8000', //'https://api-test.truck2you.com', //'http://192.168.1.123:8080/public', //'http://192.168.1.14:8080/public', // 'http://localhost:8000',
    apiv2: 'https://apiv2-test.truck2you.com',
    debug: false,
    showSideBar: false,
    stripeSecretKey: "sk_test_wbUe5C90V8FhFm2WerkcsU1y00PGJb9CIr",
    stripePublicKey: "pk_test_AqPriqhulED2f6zd7qHOOydS00Xfv1WJ57",
    ...globalConfig,
}

const mobile = {
    wpSite: 'http://localhost/test2.php',
    bndFe: 'http://192.168.1.14:3000',
    bndApi: 'http://192.168.1.14/public', // 'http://localhost:8000',
    debug: false,
    showSideBar: false,
    stripeSecretKey: "sk_test_wbUe5C90V8FhFm2WerkcsU1y00PGJb9CIr",
    stripePublicKey: "pk_test_AqPriqhulED2f6zd7qHOOydS00Xfv1WJ57",
    ...globalConfig,
}

const demo = {
    wpSite: 'https://www-test.truck2you.com/compte',
    bndFe: 'https://front-test.bookedeliver.com',
    bndApi: 'https://api-test.bookedeliver.com',
    debug: false,
    showSideBar: false,
    stripeSecretKey: "sk_test_wbUe5C90V8FhFm2WerkcsU1y00PGJb9CIr",
    stripePublicKey: "pk_test_AqPriqhulED2f6zd7qHOOydS00Xfv1WJ57",
    ...globalConfig,
}



const production = {
    wpSite: 'https://www.truck2you.com/compte/',
    bndFe: 'https://app.truck2you.com',
    bndApi: 'https://api.truck2you.com', // 'http://localhost:8000',
    debug: false,
    showSideBar: false,
    stripeSecretKey: "sk_live_wB1iv5lO1k1RceAQ5tKLIJ7U00Pt7VBoqc",
    stripePublicKey: "pk_live_ZDrBSS5aCd2VsMGp617cKkSK00wWI1FK5E",
    ...globalConfig,
    allowMobileApplicationDownload: true
}


const configs = {
    production,
    development,
    demo,
    mobile,
    recette
}

var Config = configs[globalConfig.mode]

export default Config
/*[process.env.REACT_APP_ENV_TYPE]*/
