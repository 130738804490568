import "../../../assets/vendor/select2/dist/css/select2.min.css";

import RepositoryService from "../../../proxies/RepositoryService"
import DefaultSelect2 from "../common/defaultSelect2";

class AddressesInput extends DefaultSelect2     {

	constructor(props) {
		const multiple = (typeof props.multiple !== "undefined" ? props.multiple : true) 

		super(props, multiple);
		this.className = "t2y-default-input-control " 
		this.repositoryService = new RepositoryService();
	}

	componentDidMount() {
		if (this.props.childRef) {
			this.props.childRef(this)
		}

		super.componentDidMount()
	}

	onChange(e) {
		super.onChange(e);
	}

	dataBind(callback = null) {
		this.repositoryService.getAddresses(this, (response) => {
			var datas = []

			if (this.props.emptyElement && this.props.emptyElement == true)
			{
				datas.push({id : "", text: typeof this.props.placeholder !== "undefined" ? this.props.placeholder : "..."})
			}

			response.addresses.forEach(element => {
				datas.push({id : element.id, text: this.addImageToOption(element.name + " (" + element.city + ")", element.type_image_url)})
			});

			this.setState({datas : datas});

			if (callback) {
				callback(this)
			}
		})
	}
  }

  export default AddressesInput;