import "../../../assets/vendor/select2/dist/css/select2.min.css";
import CloudService from '../../../proxies/CloudService'

import React from "react";
import PropTypes from 'prop-types'
import {
    Card,
    CardBody,
    CardImg,
    Button,
    UncontrolledTooltip,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import ImageWithRescue from "../components/Image/ImageWithRescue" 

import Dropzone from "dropzone";
Dropzone.autoDiscover = false;


class FormUploadImage extends React.Component {

    constructor(props)
    {
        super(props)
        this.state = {
            imageUploaded: false,
            newImageUrl:this.getOldImageUrl()
        }
    }

    componentDidMount()
    {
        // this variable is to delete the previous image from the dropzone state
        // it is just to make the HTML DOM a bit better, and keep it light
        let caller = this;
        let currentSingleFile = undefined;
        // single dropzone file - accepts only images
        this.dropzone = new Dropzone(document.getElementById("dropzone-single"), {
        url: "/",
        dictDefaultMessage: 'Déposez ici pour télécharger',
        thumbnailWidth: null,
        thumbnailHeight: null,
        previewsContainer: document.getElementsByClassName(
            "dz-preview-single"
        )[0],
        previewTemplate: document.getElementsByClassName("dz-preview-single")[0].innerHTML,
        maxFiles: 1,
        acceptedFiles: "image/*",
        init: function() {
            caller.setState({loading : true})
            this.on("addedfile", function(file) {
                if (currentSingleFile) {
                    this.removeFile(currentSingleFile);
                }
                currentSingleFile = file;

                // Envoi du fichier sur le serveur
                new CloudService().uploadCollaboratorImage(caller, file, (caller, secureUrl) => {
                    
                    caller.setState({imageUploaded: true, loading: false, newImageUrl:secureUrl});
                });

            });
        }
        });
        document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";
    }
    
    restoreOldImageUrl()
    {
        this.dropzone.removeAllFiles(true);
        this.setState({imageUploaded: false, newImageUrl: this.getOldImageUrl()});
    }

    getOldImageUrl()
    {
        if (typeof this.props.oldImageUrl !== "undefined" && this.props.oldImageUrl !== null)
        {
            return this.props.oldImageUrl;
        }
        else
        {
            return "";
        }
    }

    render() {
        var defaultImageUrl = this.props.defaultImageUrl ? this.props.defaultImageUrl : require("../../../assets/img/icons/common/empty_image.svg").default;

      return (
        <>
        <input type="hidden" id="old_image_url" name="old_image_url" value={this.getOldImageUrl()} />
        <input type="hidden" id="image_url" name="image_url" value={this.state.newImageUrl} />

        <Card>
                <CardBody>
                    <Row>
                        <Col lg={this.props.mode == "inline" ? "6" : "12"}>
                            <a href="#" onClick={e => e.preventDefault()}>
                                <ImageWithRescue 
                                    rounded={false} className="uploaded_image_url"  
                                    imageUrl={this.props.oldImageUrl} 
                                    defaultImage={defaultImageUrl} 
                                />
                            </a>
                        </Col>
                        <Col lg={this.props.mode == "inline" ? "6" : "12"}>
                        <div className="pt-4 text-center">
                            <div className="dropzone dropzone-single mb-3" id="dropzone-single">
                                <div className="fallback">
                                    <div className="custom-file">
                                        <input onDragStart={(e) => {/*alert("ok")*/}}
                                        className="custom-file-input"
                                        id="projectCoverUploads"
                                        type="file"
                                        />
                                        <label
                                        className="custom-file-label"
                                        htmlFor="projectCoverUploads"
                                        >
                                        Choisir une image
                                        </label>
                                    </div>
                                </div>
                                <div className="dz-preview dz-preview-single">
                                    <div className="dz-preview-cover">
                                        <img
                                        alt="..."
                                        className="dz-preview-img"
                                        data-dz-thumbnail=""
                                        />
                                    </div>
                                </div>
                            </div>
                    {/*
                    <div className="mt-3">
                            <Button
                            className="btn-icon-only rounded-circle"
                            color="primary"
                            href="#"
                            onClick={e => e.preventDefault()}
                            >
                            <i className="fas fa-camera" />
                            </Button>
                            {
                                (this.state.imageUploaded) ? (
                                    <Button
                                        id="form-upload-image-btn-undo"
                                        title="Reprendre l'image d'origine"
                                        className="btn-icon-only rounded-circle"
                                        color="secondary"
                                        href="#"
                                        onClick={e => this.restoreOldImageUrl()}
                                    >
                                        <i className="fas fa-undo" />
                                        <UncontrolledTooltip
                                        delay={0}
                                        placement="top"
                                        target="form-upload-image-btn-undo"
                                    >
                                        Annuler et reprendre l'image d'origine
                                    </UncontrolledTooltip>
                                    </Button>
                                ):
                                (
                                    <p></p>
                                )
                            }
     
                        </div>*/}
                  </div>
                        </Col>
                    </Row>
                    
                    
                </CardBody>
              </Card>


        
            
            
        </>
      );
    }
  }

  

  FormUploadImage.propTypes = {
    oldImageUrl: PropTypes.string,
    defaultImageUrl: PropTypes.string
  }
 
  export default FormUploadImage;