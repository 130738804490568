
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    Col
  } from "reactstrap";
import DateHelper from "../../../helpers/DateHelper";
import { injectIntl , FormattedMessage } from 'react-intl';
import FormRepository from "../../pages/components/form/FormRepository";
import DefaultImage from "./defaultImage"
import Message from "./message"
import Title3 from './title3'
import Title4 from './title4'
import Title5 from './title5'

var moment = require('moment');
require('moment/locale/fr');



class BlockTitle extends React.Component {

    constructor(props)
    {
        super(props)

        this.state = {
        }
    }

    componentDidMount() {}

    componentWillMount() {}

    componentWillUnmount() {}

    render() 
    {
        return (
        <Col lg="12">
            <Row>
                <Col lg="12">
                    <Row>
                        <Col xs="12">
                            {
                                (this.props.iconSrc) ?
                                (
                                    <DefaultImage className={this.props.iconClassName} src={this.props.iconSrc} style={{float:"left", fontSize:"30px", marginRight:"20px", ...this.props.iconStyle}}></DefaultImage>
                                ) : (<></>)
                            }
                            <Title3 style={{float:"left", paddingTop:"3px", width:this.props.alertMessage ? "450px" : "auto"}}>{this.props.title}</Title3>
                        </Col>
                        {
                            (this.props.subTitle) ?
                            (
                                <Col xs="12"><em>{this.props.subTitle}</em></Col>
                            ) : (<></>)
                        }
                    </Row>
                </Col>
                {/*
                <Col lg="4">
                    {
                        (this.props.alertMessage) ?
                        (
                            <Row className='align-items-center justify-content-center'><Message type="infos">{this.props.alertMessage}</Message></Row>
                        ) : (<></>)
                    }
                </Col>
                */}
            </Row>
        </Col>
        )
    }
}

BlockTitle.defaultProps = {
    toggleSidenav: () => {},
    sidenavOpen: false,
    theme: "dark"
};

BlockTitle.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    alertMessage: PropTypes.string,
    iconSrc: PropTypes.string,
    iconStyle: PropTypes.string,
    iconClassName : PropTypes.string
};

export default injectIntl(BlockTitle)
