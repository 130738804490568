
import React from "react";
import UserImage from "../Image/UserImage";
import StateLabel from "../StateLabel";

// reactstrap components
import {
    Button,
    Badge,
    Card,
    CardHeader,
    CardBody,
    CardImg,
    CardTitle,
    FormGroup,
    Form,
    Input,
    ListGroupItem,
    ListGroup,
    Progress,
    Container,
    Row,
    Col
  } from "reactstrap";
import Codes from "../../../../codes";
import Ribbon from "../../../components/common/ribbon";


const CollaboratorCard = ({ data }) => (
    <a href={"/admin/collaborator/" + data.id }>
    <Card>
        <CardBody>
            {   
                (data.state && data.state.code == Codes.WAITING_FOR_COLLABORATOR_VALIDATION) ?
                (
                    <Ribbon type={"warning"} position={"top-right"}>en attente</Ribbon>
                ) : (<></>)
            }
            <UserImage user={data} maxWidth="140px" />
            <div className="pt-4 text-center">
                <h5 className="h3 title">
                    <span className="d-block mb-1">{data.summary}</span>
                </h5>
                <StateLabel data={data} />
            </div>
        </CardBody>
    </Card>
    </a>
);


export default CollaboratorCard