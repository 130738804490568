import "../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components
 import {
   Form
 } from "reactstrap";
import RepositoryService from "../../../proxies/RepositoryService"
import { injectIntl , FormattedMessage } from 'react-intl';

import { Multiselect } from 'multiselect-react-dropdown';
import DefaultInput from "../common/defaultInput";
import StringHelper from "../../../helpers/StringHelper";
import TagsInput from "react-tagsinput";
import TransporterService from "../../../proxies/TransporterService";
import DefaultSelect2 from "../common/defaultSelect2";

class TrucksForOrderProposalInput extends DefaultSelect2     {

    constructor(props)
    {
        const multiple = (typeof props.multiple !== "undefined" ? props.multiple : true)
        
        super(props, multiple);

        this.className = "t2y-default-input-control " 
        this.transporterService = new TransporterService();
    }

    displayResponse(response)
    {
        var datas = []
        datas.push({id : " ", text: typeof this.props.placeholder !== "undefined" ? this.props.placeholder : "..."})
        
        response.trucks.forEach(element => {
            datas.push({id : element.id, text: element.technical_label + "(" + element.summary + ")"})
        });

        this.setState({datas : datas, loading: false})
    }

    dataBind()
    {
    
        this.setState({loading: true})
        this.transporterService.getTrucksForOrderPropsal(this, this.props.orderId, (response) => {
            this.displayResponse(response)
        })
    }
}
 


  export default injectIntl(TrucksForOrderProposalInput);