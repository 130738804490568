
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    ButtonGroup,
    Button
  } from "reactstrap";
import { Calendar } from 'react-date-range';

import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator"
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import FormHourInput from "../../pages/components/form/FormHourInput";
import ReactDatetime from "react-datetime";
import RepositoryService from "../../../proxies/RepositoryService";

import { injectIntl , FormattedMessage } from 'react-intl';
import FormRepository from "../../pages/components/form/FormRepository";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DefaultInput from "./defaultInput";
import ReactPasswordStrength from 'react-password-strength'
import StringHelper from "../../../helpers/StringHelper";

var moment = require('moment');
require('moment/locale/fr');

class CivilityInput extends DefaultInput {

    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            hidden_value : null
        }
    }

    componentDidMount()
    {
        super.componentDidMount()
    }

    componentWillMount()
    {
        super.componentWillMount()
    }

    onClick(e, value)
    {
        this.setValue(value)
        document.getElementById(this.getKey()).value = value;
        if (this.props.manager)
        {
            this.props.manager.checkHtmlInput(this.getKey())
        }
    }

    // On surcharge la classe si données obligatoire avec une valeur pour ne pas avoir de coche verte 
    getRequiredWithValueClass()
    {
        return "";
    }

    render() {
        return this.rendeInner(<>
            <div id={this.getKey() + "-main-container"}>
            <input 
                type="hidden"
                error-parent-container={this.getKey() + "-main-container"}
                id={this.getKey()}
                name={this.getKey()}
                className="t2y-default-input-control"
                onChange={(e)=> this.onChange(e.currentTarget.value)}
                autoComplete="OFF" 
                value={this.getValue()}
                style={{width:"60%" }}
                placeholder={!this.props.placeholder ? "..." : this.props.placeholder}
            />
            <Row className="justify-content-center">
                <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                    <Button className={classnames({ active: this.getValue() == "CIVILITY_MR" }) + " multiChoiceBtn"} color="secondary"
                        onClick={(e) => this.onClick(e, "CIVILITY_MR")}>
                        {StringHelper.translate(this, "Default.civility_mr")}
                    </Button>
                    <Button className={classnames({ active: this.getValue() == "CIVILITY_MME" }) + " multiChoiceBtn"} color="secondary"
                        onClick={(e) => this.onClick(e, "CIVILITY_MME")}>
                        {StringHelper.translate(this, "Default.civility_mme")}
                    </Button>
                </ButtonGroup>
            </Row>
            </div>
        </>
      )
    }
}

export default injectIntl(CivilityInput)
