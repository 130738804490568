
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Button,
    Container,
    Form,
    Table,
    FormGroup,
    Modal,
    UncontrolledPopover,
    UncontrolledTooltip,
    PopoverBody,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Alert,
    InputGroup,
    InputGroupAddon,
    InputGroupText
  } from "reactstrap";
import Resources from "../../../resources";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
import FormInput from '../components/FormInput'
import Title3 from '../../components/common/title3'
import DefaultPopover from '../../components/common/defaultPopover'
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormGroupInput from '../components/form/FormGroupInput'
import FormHourInput from '../components/form/FormHourInput'
import TransporterService from "../../../proxies/TransporterService"
import SecurityService from "../../../proxies/SecurityService"
import FormCivilityInput from "../components/form/FormCivilityInput";
import ReactDatetime from "react-datetime";
import { injectIntl , FormattedMessage } from 'react-intl';
import StringHelper from "../../../helpers/StringHelper";
import DefaultButton from "../../components/common/defaultButton";
import DefaultImage from "../../components/common/defaultImage";
import FormFooter from "../../components/form/formFooter";
import DefaultInput from "../../components/common/defaultInput";
import ShipperService from "../../../proxies/ShipperService";
import DateHelper from "../../../helpers/DateHelper";
import Message from "../../components/common/message"
import Separator from "../../components/common/separator";
import ArrayHelper from "../../../helpers/ArrayHelper";
import BlockTitle from "../../components/common/blockTitle";
import DefaultCheckbox from "../../components/common/defaultCheckbox";
import WebsiteHelper from "../../../helpers/WebsiteHelper";

var moment = require('moment');
require('moment/locale/fr');

class Config extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.shipperService = new ShipperService();
        this.securityService = new SecurityService();

        this.validationRules = {
            internal_freight_id_label : { required: true, },
            custom_satisfaction_survey_question : { required: true, },
        }

        this.state = {
            loading : false,
            company: null
        }
    }
    componentWillMount()
    {
        
    }

    componentDidMount()
    {
        // Récupération des règles de planning
        this.securityService.me(this, (response => {
            this.securityService.storeCompany(response.company);
            this.setState({
                loading: false,
                company: response.company
            })

            if (this.internalIdInputRef)
            {
                this.internalIdInputRef.setValue(response.company.internal_freight_id_label)
            }
            if (this.internalIdCommentInputRef)
            {
                this.internalIdCommentInputRef.setValue(response.company.internal_freight_id_comment)
            }
            if (this.customSatisfactionSurveyQuestionInputRef)
            {
                this.customSatisfactionSurveyQuestionInputRef.setValue(response.company.custom_satisfaction_survey_question)
            }
        }));
        
    }

    changeCompanyValue(key, value)
    {
        var company = this.state.company;
        company[key] = value;

        this.setState({company: company})
    }

    checkAlert(confirmCallback)
    {
        var alerts = [];

        if (this.state.company.internal_freight_id_required == false)
        {
            alerts.push({
                label: "Vous avez désactivé le recueil de votre référence de livraison",
                subLabel: "Chaque opération de transport sera identifiée par une référence propre à Truck2You."
            })
        }

        if (this.state.company.call_of_the_driver && this.state.company.departure_notification)
        {
            alerts.push({
                label: "Vous avez activé l'appel préalable par le conducteur ET la notification au départ du conducteur",
                subLabel: "Ces 2 notifications concernent la même information (le départ du conducteur). Nous vous recommander de n'activer que l'une d'entre elles."
            })
        }

        if (this.state.company.allow_satisfaction_survey == false)
        {
            alerts.push({
                label: "Vous avez désactivé l'envoi d'enquêtes satisfaction à vos clients",
                subLabel: "Nous n'enverrons pas d'enquête satisfaction à vos clients, nous ne serons donc pas en mesure d'évaluer la perception de notre qualité de service par vos clients."
            })
        }


        if (alerts.length == 0)
        {
            confirmCallback()
        }
        else
        {
            this.showModalConfirmation({
                hideIcon: true,
                headerComponent: <Title3>Vos règles de gestion - attention</Title3>,
                bodyClassName: "text-left",
                okButtonIcon: Resources.button_save_icon,
                okButtonTitle:  "J'ai compris",    
                cancelButtonTitle:  "Je modifie mes choix",    
                component:  <>
                    <div className="text-justify">Vos choix de politique commerciale requièrent votre attention&nbsp;:</div>
                    <ul>
                    {
                        alerts.map((a, index) => {
                            return <>
                                <li>
                                <div className="mt-2 mb-2 text-justify">
                                <strong>{a.label}</strong><br/>
                                <em style={{fontStyle:"initial"}}>{a.subLabel}</em>
                                </div>
                                </li>
                            </>
                        })
                    }
                    </ul>
                </>,
                okButtonCallback: () => {
                  confirmCallback()
                }
            })
        }
    }

    onSubmitForm(e)
    {
        e.preventDefault();
        var formIsValid = this.htmlFormIsValid(null);
        if (formIsValid)
        {
            this.checkAlert( () => {

                // Enregistrement des règles de disponibilités
                var postedData = {
                    internal_freight_id_required: this.state.company.internal_freight_id_required,
                    internal_freight_id_label: this.internalIdInputRef ? this.internalIdInputRef.getValue() : null,
                    internal_freight_id_comment: this.internalIdCommentInputRef ? this.internalIdCommentInputRef.getValue() : null,
                    notification_the_day_before: this.state.company.notification_the_day_before,
                    call_of_the_driver: this.state.company.call_of_the_driver,
                    departure_notification: this.state.company.departure_notification,
                    eta_notification: this.state.company.eta_notification,
                    allow_satisfaction_survey: this.state.company.allow_satisfaction_survey,
                    use_custom_satisfaction_survey_question: this.state.company.use_custom_satisfaction_survey_question,
                    custom_satisfaction_survey_question: this.customSatisfactionSurveyQuestionInputRef ? this.customSatisfactionSurveyQuestionInputRef.getValue() : null
                }

                this.shipperService.saveConfiguration(this, postedData, (response) => {
                    // Mise à jour de la date de modification de la configuration pour ne plus avoir la notification
                    var company = this.state.company;
                    company.configuration_last_modification_date = DateHelper.now();
                    company.internal_freight_id_required = postedData.internal_freight_id_required
                    company.internal_freight_id_label = postedData.internal_freight_id_label
                    company.internal_freight_id_comment = postedData.internal_freight_id_comment
                    
                    this.securityService.storeCompany(company)


                    this.confirmMessage("Les données ont été enregistrées avec succès")
                });
            });
        }
        else
        {
            this.scrollToFirstError()
        }
    }

    renderItem(label, subLabel, popover, component)
    {
        return <Row className="mb-2">
            <Col lg="12">
                {label}
                {
                    (popover) ?
                    (
                        <DefaultPopover content={popover}></DefaultPopover>
                    ) : (<></>)
                }
                {subLabel ? <><br/><em style={{fontSize:"14px"}}>{subLabel}</em></> : <></>}
            </Col>
            <Col lg="12">
                {component}
            </Col>
        </Row>
    }


    renderChoice(label, subLabel, popover, choices, valuekey)
    {
        var currentValue = this.state.company[valuekey]
        var id = StringHelper.createDomElementId();

        return this.renderItem(label, subLabel, popover, <>
                <Row>
                    {
                        choices.map((choice, index) => {
                            return <>
                                <Col md={choice.subLabel ? 3 : 12} className="">
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input
                                            className="custom-control-input"
                                            id={id + "_" + index}
                                            name={id + "_" + index}
                                            type="radio"
                                            checked={choice.value == currentValue}
                                            onChange={() => this.changeCompanyValue(valuekey, choice.value)}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={id + "_" + index}
                                        >
                                            {this.renderLabel(choice.label)}
                                        </label>
                                    </div>
                                </Col>
                                {
                                    (choice.subLabel) ? 
                                    (
                                        <Col md="12" className="">
                                        <em>{choice.subLabel}</em>
                                        </Col>
                                    ) : (<></>)
                                }
                            </>
                        })
                    }
                </Row>
            </>
        )
    }

    renderLabel(label)
    {
        return <strong>{label}</strong>
    }

    renderCheckbox(companyStateKey, label, subLabel, popoverMessage)
    {
        return <DefaultCheckbox  right={false} key={companyStateKey + "_ckb"} id={companyStateKey}
            checked={this.state.company[companyStateKey]}
            onChange={(e) => {
                var checked = !this.state.company[companyStateKey];
                this.changeCompanyValue(companyStateKey, checked)
            }}
            component={<>
                <Row className="mb-2">
                    <Col lg="12">
                        {this.renderLabel(label)}
                        {
                            (popoverMessage) ?
                            (
                                <DefaultPopover content={popoverMessage}></DefaultPopover>
                            ) : (<></>)
                        }
                        {subLabel ? <><br/><em style={{fontSize:"14px"}}>{subLabel}</em></> : <></>}
                    </Col>
                </Row>
            </>}
        >
        </DefaultCheckbox>
    }

    renderInternalFreightId()
    {
        return <>
            <Row className="mb-2">
                <Col lg="12">
                    {this.renderCheckbox(
                        "internal_freight_id_required",
                        "Recueil de votre référence",
                        null,
                        "Indiquez le type de référence que vous utilisez habituellement pour identifier vos livraisons : nous nous assurerons que notre conducteur (ou vos collaborateurs) renseignent systématiquement cette information."
                        )}
                </Col>
            </Row>
            
            {
                (this.state.company.internal_freight_id_required) ?
                (
                    <>
                    <Row className="mb-2">
                        <Col lg="12">
                            Nom de votre référence
                            <DefaultPopover content="Indiquez le nom de votre référence ; il s'agit en général d'un numéro de bon de livraison ou d'un numéro de commande"></DefaultPopover>
                        </Col>
                        <Col lg="12">
                            <Row>
                                <Col lg="12">
                                    <DefaultInput type="text" required={true} name={"internal_freight_id_label"} manager={this} childRef={(elt) =>  this.internalIdInputRef = elt}></DefaultInput>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-2 mt-3">
                        <Col lg="12">
                            Explications au conducteur
                            <DefaultPopover content="Expliquez au conducteur où il peut trouver cette information"></DefaultPopover>
                        </Col>
                        <Col lg="12">
                            <Row>
                                <Col lg="12">
                                    <DefaultInput type="textarea" rows={3} childRef={(elt) =>  this.internalIdCommentInputRef = elt} message={"ex: Ce numéro est indiqué en haut à droite du bon de livraison, il commence par BCxxxxxxx."}></DefaultInput>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    </>
                ) : (<></>)
            }
        </>
    }

    renderCustomerRelationship()
    {
            /*
            - [ ] Notification la veille destinataire Mail et si pas de mail => sms   OUI
            - [ ] Appel préalable par le conducteur : Oui/Non.             OUI
            - [ ] SMS / Mail au moment du départ du conducteur : Oui/non.   OUI
            - [ ] Notification ETA -15 minutes  => 10km SMS / ou mail.  OUI
            */

        return <>
            {this.renderCheckbox("notification_the_day_before", "Rappel de livraison la veille", "E-mail/SMS envoyé au client pour lui confirmer la livraison le lendemain", "Cette notification ne pourra être envoyée que si vous renseignez le détail de vos livraisons à l'avance, et uniquement pour les clients pour lesquels vous avez fourni un numéro de téléphone ou une adresse e-mail")}
            {this.renderCheckbox("call_of_the_driver", "Appel préalable par le conducteur", "Le conducteur appelle votre client au moment où il se met en route", "Cet appel ne pourra être passé que pour les clients dont vous avez fourni un numéro de téléphone")}
            {this.renderCheckbox("departure_notification", "Notification du départ du conducteur", "E-mail/SMS envoyé au client pour l'informer du départ du conducteur", "Cette notification ne pourra être envoyée que pour les clients dont vous avez fourni un numéro de téléphone ou une adresse e-mail")}
            {this.renderCheckbox("eta_notification", "Notification d'arrivée imminente", "E-mail/SMS envoyé au client lorsque le conducteur s'approche à moins de 10 km du lieu de livraison", "Cette notification ne pourra être envoyée que les clients dont vous avez fourni un numéro de téléphone ou une adresse e-mail")}
        </>
    }
    
    renderSatisfactionSurvey()
    {
        return <>
            {this.renderCheckbox("allow_satisfaction_survey", "Envoi d'enquêtes satisfaction à vos clients", null, null)}
            {
                (this.state.company.allow_satisfaction_survey) ?
                (
                    <>
                    {this.renderChoice("Formulation de la question", null, null, [{label: "Standard", value:false, subLabel: "« " + this.state.company.company_name + " est soucieuse de la qualité des livraisons. Merci de nous indiquer si la livraison qui vient d’être effectuée vous satisfait ? »"}, {label: "Personnalisée", value:true}], "use_custom_satisfaction_survey_question")}

                    {
                        (this.state.company.use_custom_satisfaction_survey_question) ?
                        (
                            this.renderItem("", null, null, <>
                                <DefaultInput 
                                    manager={this}
                                    type="textarea" 
                                    rows="3" 
                                    required={true} 
                                    name="custom_satisfaction_survey_question" 
                                    childRef={(elt) => this.customSatisfactionSurveyQuestionInputRef = elt} 
                                >
                                </DefaultInput>
                            </>)

                        ) : (<></>)
                    }
                    </>
                ) : 
                (<></>)
            }
            
        </>
    }

    
    renderConfiguration()
    {
        return <>
        <Row>
            <Col lg="8">
                <Card>
                    <CardHeader>
                        <BlockTitle iconSrc="fas fa-barcode"
                            title="RÉFÉRENCE DE VOS LIVRAISON"
                            subTitle= "Définissez de quelle manière vous voulez identifier les livraisons que vous nous confiez."
                        />
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col lg="12">
                            {this.renderInternalFreightId()}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col xs="4" className={"d-none d-lg-block"}>
                <Message type="infos">Nous utiliserons systématiquement votre référence pour identifier les livraisons que vous nous confiez</Message>
            </Col>
        </Row>
        <Row>
            <Col lg="8">
                <Card>
                    <CardHeader>
                        <BlockTitle iconSrc="fas fa-bullhorn"
                            title="RELATION CLIENT"
                            subTitle="Choisissez de quelle manière vous souhaitez que nous informions vos clients de leur livraison"
                        />
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col lg="12">
                            {this.renderCustomerRelationship()}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col xs="4" className={"d-none d-lg-block"}>
                <Message type="infos">Les notifications sont envoyées en votre nom : {this.securityService.getCompany().company_name}</Message>
            </Col>
        </Row>
        <Row>
            <Col lg="8">
                <Card>
                    <CardHeader>
                        <BlockTitle iconSrc="far fa-smile"
                            title="ENQUÊTES CLIENT"
                            subTitle="Précisez la forme avec laquelle vous souhaitez que nous interrogions vos clients sur leur satisfaction"
                        />
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col lg="12">
                                {this.renderSatisfactionSurvey()}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col xs="4" className={"d-none d-lg-block"}>
                <Message type="infos">Les notes et commentaires de vos clients vous seront systématiquement transmises</Message>
            </Col>
        </Row>
        </>
    }


    
    render() {
        if (this.state.company == null)
        {
            return <FormLoadingIndicator loading={true}></FormLoadingIndicator>
        }

        return  (
            <>
            {this.renderParent()}
            <Container className="mt--4" fluid>
                <Form className="needs-validation" noValidate onSubmit={e => this.onSubmitForm(e)} >
                    <Row>
                        <Col className="order-xl-1" xl="12">
                        <FormLoadingIndicator loading={this.state.loading}>
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center ml-3">
                                    <Col className="text-left" xs="6">
                                        <Title3>
                                            <DefaultImage src={Resources.menu_shipper_config_logo}></DefaultImage>&nbsp;
                                            {StringHelper.translate(this, "Default.menu_shipper_configuration")}
                                        </Title3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.renderConfiguration()}
                                        
                            </CardBody>
                            <CardFooter>
                                <FormFooter></FormFooter>
                            </CardFooter>
                        </Card>
                        </FormLoadingIndicator>
                        </Col>

                        </Row>
                    </Form>
                


                
                </Container>
          </>
        );
    }
}


export default injectIntl(Config)