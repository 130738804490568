
import React from "react";
import PropTypes from 'prop-types';

import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    Col
  } from "reactstrap";

import { injectIntl , FormattedMessage } from 'react-intl';
import BusinessService from "../../../proxies/BusinessService";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import DefaultLabel from "../common/defaultLabel";
import Rating from "../rating/rating";
import Title2 from "../common/title2";
import Title3 from "../common/title3";
import Title4 from "../common/title4";
import Title5 from "../common/title5";
import StringHelper from "../../../helpers/StringHelper";
import TruckRepresentation from "../truck/TruckRepresentation";
import SlidingPane from "react-sliding-pane";

var moment = require('moment');
require('moment/locale/fr');

class TruckSummary extends React.Component {

    constructor(props) {
        super(props)

        this.businessService = new BusinessService();

        this.state = {
            isCompleteSummaryOpened: false,
            loading: true
        }
    }

    
    componentWillMount()
    {
        this.loadData();
    }

    componentDidMount()
    {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    loadData()
    {
        // Récupération des données du camion
        if (this.props.id && (this.props.properties == true || this.props.label == true))
        {
            this.businessService.getTruckSummary(null, this.props.id, (response) => {
                this.setState({
                    truck : response.instance,
                    loading: false
                })
            })
        }
        else
        {
            this.setState({loading: false, truck: this.props.truck})
        }
    }

    renderLoading()
    {
        return <>
        Chargement
        </>
    }

    renderPropertyValue(propertyValue)
    {
        return <>
            <Col xs="6" md="3">
                <DefaultLabel>{propertyValue.property_label}</DefaultLabel>
            </Col>
            <Col xs="6" md="3">
                {propertyValue.value} {propertyValue.unit ? <FormattedMessage id={propertyValue.unit.code + ".truck_summary_short_label"}></FormattedMessage> : <></>}
            </Col>
        </>
    }

    renderPropertyFamilies()
    {
        if (this.state.truck.property_families)
        {
            return this.state.truck.property_families.map((propertyFamily, index) => {
                return <>
                    <Row>
                        <Col xs="12">
                            <Title5 style={{color:"var(--jaune)", borderBottom:"solid 1px var(--jaune)"}}>{propertyFamily.label}</Title5>
                        </Col>
                    </Row>
                    <Row>
                    {
                        propertyFamily.values.map((propertyValue, propertyValueIndex) => {
                            return this.renderPropertyValue(propertyValue)
                        })
                    }
                    </Row>
                </>
            })
        }
        else
        {
            return <></>
        }

    }

    renderImage()
    {
        if (this.props.fullsizeImage)
        {
            return <Row className="justify-content-text-center">
                <TruckRepresentation truck_id={this.state.truck.id}></TruckRepresentation>
            </Row>
        }
        else
        {
            return <Row className="justify-content-text-center">
                <Col lg="3"></Col>
                <Col lg="6">
                <TruckRepresentation truck_id={this.state.truck.id}></TruckRepresentation>
                </Col>
            </Row>
        }
    }

    openCompleteSummary()
    {
        this.setState({isCompleteSummaryOpened: !this.state.isCompleteSummaryOpened})
    }

    renderInternalInfos()
    {
        return <>
            <Row>
                <Col xs="12">
                    <Title5 style={{color:"var(--jaune)", borderBottom:"solid 1px var(--jaune)"}}>INFORMATION DU VÉHICULE</Title5>
                </Col>
            </Row>
            <Row>
                <Col xs="6" md="3">
                    <DefaultLabel>Référence</DefaultLabel>
                </Col>
                <Col xs="6" md="3">
                    {this.state.truck.reference}
                </Col>
                <Col xs="6" md="3">
                    <DefaultLabel>Immatriculation</DefaultLabel>
                </Col>
                <Col xs="6" md="3">
                    {this.state.truck.immatriculation}
                </Col>
            </Row>
        </>
    }

    renderTruck()
    {
        return <>
            <div className="t2y-clickable" onClick={(elt) => this.props.completeSummaryOnClick ? this.setState({isCompleteSummaryOpened: !this.state.isCompleteSummaryOpened}): {}}>
            {
                (this.props.image) ?
                (
                    this.renderImage()
                ) : (<></>)
            }
            {
                (this.props.label) ?
                (
                    <Row>
                        <Col xs="12" className="text-center"><strong>{this.state.truck.technical_label}</strong></Col>
                    </Row>
                ) : (<></>)
            }
            {
                (this.props.reference) ?
                (
                    <Row>
                        <Col xs="12" className="text-center"><strong>{this.state.truck.reference}</strong></Col>
                        <Col xs="12" className="text-center"><strong>{this.state.truck.immatriculation}</strong></Col>
                    </Row>
                ) : (<></>)
            }
            {
                (this.businessService.isTransporter() && this.props.internalInfos) ?
                (
                    this.renderInternalInfos()
                ) : (<></>) 
            }
            {
                (this.props.properties) ?
                (
                    this.renderPropertyFamilies()
                ) : (<></>)
            }
            </div>
        </>
    }

    renderCompleteSummaryOnClick()
    {
        return <SlidingPane
          width="50%"
          className="some-custom-class"
          overlayClassName="some-custom-overlay-class"
          isOpen={this.state.isCompleteSummaryOpened}
          title="Informations sur le véhicule"
          subtitle={null}
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            this.setState({ isCompleteSummaryOpened: false });
          }}
        >
            <TruckSummary internalInfos={true} properties={true} label={true} image={true} id={this.state.truck.id}></TruckSummary>
            {/*
            <Row className="justify-content-text-center">
                <Col lg="3"></Col>
                <Col lg="6">
                    <TruckImage className="missions_truck_image_url" truck={this.state.truck} />
                </Col>
            </Row>
            <Container>
                {this.renderTruckProperties(logoColor, false)}
            </Container>
            */}
        </SlidingPane>
    }
    
    render() {
        return <>
            {
                (this.state.loading) ?
                (
                    this.renderLoading()
                )
                :
                (
                    (this.state.truck) ?
                    (
                        this.renderTruck()
                    ) : (<></>)
                )
            }

            {
                (this.props.completeSummaryOnClick) ?
                (
                    this.renderCompleteSummaryOnClick()
                ) : (<></>)
            }
        </>
    }
}

TruckSummary.propTypes = {
    id: PropTypes.string,
    truck: PropTypes.any,
    internalInfos: PropTypes.bool.isRequired,
    reference: PropTypes.any,
    label: PropTypes.bool.isRequired,
    properties: PropTypes.bool.isRequired,
    image: PropTypes.bool.isRequired,
    completeSummaryOnClick: PropTypes.bool,
    fullsizeImage: PropTypes.bool
};

export default injectIntl(TruckSummary)
