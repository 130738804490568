import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import queryString from 'query-string';

import {
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    Container,
    Badge
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import SecurityService from "../../../../proxies/SecurityService";
import DefaultInput from "../../common/defaultInput";
import Resources from "../../../../resources";
import Title2 from "../../common/title2"
import Title3 from "../../common/title3"
import Step from "../step";
import PasswordInput from "../../common/passwordInput";
import DefaultButton from "../../common/defaultButton";
import FormGroupInput from "../../../pages/components/form/FormGroupInput";
import DefaultCheckbox from "../../common/defaultCheckbox";
import DefaultPopover from "../../common/defaultPopover";
import StringHelper from "../../../../helpers/StringHelper";
import DefaultLabel from "../../common/defaultLabel";
import UserState from '../../user/userState'
import DefaultImage from "../../common/defaultImage";
import HeaderComponent from "./headerComponent";
import WebsiteHelper from "../../../../helpers/WebsiteHelper";
import { __RouterContext } from "react-router";

var moment = require('moment');
require('moment/locale/fr');

class SiretInputStep extends Step {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.securityService = new SecurityService();

        this.validationRules = {
            siret: {
                required: true,
                /*rules: [
                    {
                        rule : this.siret.bind(this),
                        ruleMessage : this.translateMessage("Default.invalid_siret")
                    }
                ]*/
            }
        }

        this.state = {
            init: true,
            lading: false,
            not_found: false,
            search_in_progress: false,
            userError: false
        }
    }

    componentWillMount() {
        super.componentWillMount()
        // Au démarrage, on cache les boutons de validation
        this.props.workflow.disableNexts(this.props.id)
    }

    componentDidMount() {
        super.componentDidMount()

        this.checkTokenAndCompany();
    }

    async checkTokenAndCompany()
    {
        this.setState({loading: true})
        var refreshUserResponse = await this.securityService.syncMe(null);
        if (refreshUserResponse == null)
        {
            this.setState({userError: true, userStateActionResponse: {
                code: "LOGIN"
            }})
        }
        else
        {
            this.securityService.storeUser(refreshUserResponse.user);
            this.securityService.storeCompany(refreshUserResponse.company);
        }
        this.setState({loading: false})
    }

    getType()
    {
        return this.props.type;
    }

    search()
    {

        var formIsValid = this.htmlFormIsValid();
        if (formIsValid)
        {    

            this.setState({search_in_progress: true})
            
            const siret = this.getHtmlFormControlValue("siret")
            this.businessService.getCompanyInfosBySiret(null, siret, (response) => {
                this.setState({
                    company_infos: response,
                    search_in_progress: false,
                    init: false,
                    not_found: false
                })
                this.props.workflow.reload()

            },  (httpError) => {
                this.setState({company_infos: null, init: true, not_found: false, search_in_progress: false})
                /*switch(httpError.status)
                {
                    case 404:
                        //this.setState({company_infos: null, init: false, not_found: true, search_in_progress: false})
                        this.setState({company_infos: null, init: true, not_found: false, search_in_progress: false})
                        break;
                    default:
                        this.setState({company_infos: null, init: true, not_found: false, search_in_progress: false})
                        break;
                }*/
                this.props.workflow.reload()

                var customModalComponent = null;
                if (httpError.status == 404)
                {
                    customModalComponent = <>
                        <Row className="mb-5">
                            <Col xl="12" className="text-center">
                                <img src={require('../../../../assets/img/illustrations/companyWorkflow_siretNotFound.svg').default} style={{width:"40%"}} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="12" className="text-center">
                                <Title2 center={true} >
                                    <FormattedMessage id="company.confirmation_step.company_not_found_title"></FormattedMessage>
                                </Title2>
                                <Title3 center={true} >
                                    <FormattedMessage id="company.confirmation_step.company_not_found_message"></FormattedMessage>
                                </Title3>
                            </Col>
                        </Row>
                    </>
                }
                else
                {
                    customModalComponent = <>
                        <div className="text-center text-warning" style={{fontSize:"100px"}}>
                            <i className="fas fa-exclamation-triangle"></i>
                        </div>
                        <Row>
                            <Col xl="12" className="text-center">
                                <Title3 center={true} >
                                    <FormattedMessage id="company.confirmation_step.bad_siret_format_title"></FormattedMessage>
                                </Title3>
                                <Title3 center={true} >
                                    <FormattedMessage id="company.confirmation_step.bad_siret_format_message"></FormattedMessage>
                                </Title3>
                            </Col>
                        </Row>
                    </>
                }

                this.showCustomModalNotification(null, customModalComponent, null)

            })
        }
        else
        {
            this.setState({
                company_infos: null,
                init: true,
                search_in_progress: false
            })

            this.props.workflow.reload()
        }
    }

    allowNextStep()
    {
        return this.state.company_infos != null && this.state.search_in_progress == false;
    }

    submit(pid)
    {
        var formIsValid = this.htmlFormIsValid();
        if (formIsValid)
        {
            if (this.state.company_infos)
            {
                super.submit(pid)
                this.setState({loading: true})
                // Récupération des données nécessaires à la création de l'entreprise
                const siret = this.getHtmlFormControlValue("siret")
                const operationalAddress = StringHelper.toBoolean(this.getHtmlFormControlValue("operational_address"));

                // Enregistrement des données de la société (adresse de facturation, licence, rattachement au compte)
                this.businessService.createCompanyBillinfInfosFromSiret(this, siret, operationalAddress, (response) => {

                    this.setState({loading: false})
                    // Mise en session des données de la société
                    this.businessService.storeCompany(this.state.company_infos)
                    // Passage à l'étape suivante
                    //this.props.workflow.goToNextStep(pid);

                    // Si une license est présente et valide
                    if (this.state.company_infos.lc_exists && this.state.company_infos.lc_active)
                    {
                        this.props.workflow.goToSpecificStep("COMPANY_WITH_LICENSE_CONFIRMATION", this.state.company_infos)
                    }
                    else
                    {
                        this.props.workflow.goToSpecificStep("COMPANY_WITHOUT_LICENSE_CONFIRMATION", this.state.company_infos)
                    }

                }, this.onError.bind(this))
            }
        }
    }

    onError(httpResponse)
    {
        this.setState({loading: false})

        httpResponse.json().then((apiResponse) => {
            this.showErrorFromResponse(
                apiResponse,
                this.translateMessage("company.siret_input_step_error_title")
            );
        });
    }


    renderCompanyInfos()
    {
        if (this.state.init)
        {
            return <>
                <Row>
                    <Col xl="12" className="text-center">
                        <img src={require('../../../../assets/img/illustrations/companyWorkflow_siretInputStep.svg').default} style={{width:"50%"}} />
                    </Col>
                </Row>
            </>
        }
        
        if (this.state.not_found)
        {
            return <>
                <Row>
                <Col xl="12" className="text-center">
                    <Title2 center={true} content="Société non trouvée" >
                        <FormattedMessage id="SearchBySiretModal.company_not_found"></FormattedMessage>
                    </Title2>
                </Col>
                </Row>
                <Row>
                <Col xl="12" className="text-center">
                    <img src={require('../../../../assets/img/illustrations/companyWorkflow_siretNotFound.svg').default} style={{width:"25%"}} />
                </Col>
                </Row>
            </>
        }

        return <>
            <h6 className="modal-title mb-2" id="modal-title-default">
                <FormattedMessage id="SearchBySiretModal.step2_company_title" key={++this.index} />
            </h6>
            <FormGroupInput label="Raison Sociale"  labelColumns={3} controlColumns={9} style={{marginBottom:"1px"}}>
                <label>{this.state.company_infos.company_name}</label>
            </FormGroupInput>
            <FormGroupInput label="SIRET" labelColumns={3} controlColumns={9}  style={{marginBottom:"1px"}}>
                <label>{this.state.company_infos.siret}</label>
            </FormGroupInput>
            <FormGroupInput label="SIREN"  labelColumns={3} controlColumns={9}  style={{marginBottom:"1px"}}>
                <label>{this.state.company_infos.siren}</label>
            </FormGroupInput>
            <FormGroupInput label="Adresse"  labelColumns={3} controlColumns={9}  style={{marginBottom:"1px"}}>
                <label>
                    {this.state.company_infos.billing_address.street} {this.state.company_infos.billing_address.zip_code} {this.state.company_infos.billing_address.city}
                </label>
            </FormGroupInput>
            <FormGroupInput label="Code activité" labelColumns={3} controlColumns={9}  style={{marginBottom:"1px"}}>
                {
                    (this.state.company_infos.activity) ?
                    (
                        <label>{this.state.company_infos.activity.label + " (" + this.state.company_infos.activity.code + ")"}</label>
                    ) : 
                    (
                        <>
                            <label>{this.state.company_infos.activity_code}</label>
                        </>
                    )
                }
            </FormGroupInput>
            {
                (this.securityService.isTransporter())?
                (
                    this.renderTransporterLicenses()
                ) : (<></>)
            }
            {/*
            <DefaultCheckbox id="operational_address"
                    content={this.props.intl.formatMessage({id: "company.operational_address"})}
                    popover={
                        <DefaultPopover content={<FormattedMessage id="company.operational_address.infos" />} />
                    } 
            />
            */}
        </>
    }

    renderTransporterLicensesIcon(valid = false)
    {   
        var logo = valid ? Resources.checkOk_logo : Resources.checkKo_logo
        var color = valid ? "green" : "red"

        return <DefaultImage key={"l-icon-" + (valid ? 1 : 0)} src={logo} style={{fontSize:22, color: color}}></DefaultImage>
    }

    renderTransporterLicenses()
    {
        return <Row>
            <Col xl="12">
            <h6 className="modal-title mb-2" id="modal-title-default">
                <FormattedMessage id="SearchBySiretModal.step2_license_title" key={++this.index} />
            </h6>
            {
                // Si il y a au moins une licence   
                (this.state.company_infos.lc_exists) ?
                (
                    <>
                        <label>{this.renderTransporterLicensesIcon(this.state.company_infos.lc_active)} Licence communautaire  n°<strong>{this.state.company_infos.lc_number}</strong> ({this.state.company_infos.lc_copy_number} copie(s)) valable jusqu' au <strong>{this.state.company_infos.lc_end_date}</strong></label>
                        
                    </>
                ) 
                : 
                (
                    <label>{this.renderTransporterLicensesIcon(false)} Aucune information n'a été trouvée</label>
                )
            }
            </Col>
        </Row>

    }

    render() {
        if (this.state.userError)
        {
            return <UserState userStateActionResponse={this.state.userStateActionResponse}></UserState>
        }

        var user = this.securityService.getUser()
        return (
            <>
            {this.renderParent()}
            <FormLoadingIndicator loading={this.state.loading} />
            <Container >
            <HeaderComponent siret={true}></HeaderComponent>
            <Row> 
                    <Col lg="12" md="12" className="order-lg-1 order-2">
                        <Row className="mb-3">
                            <Col>
                            <Title2 justify={true}>
                                <FormattedMessage id="company.siret_input_step_title1" values={{user_summary: (user ? user.first_name : "")}}></FormattedMessage>
                            </Title2>
                            <Title3 justify={true}>
                                <FormattedMessage id="company.siret_input_step_title2"></FormattedMessage>
                            </Title3>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs="12">
                                <InputGroup className="mb-3" id="siret-error-container">
                                    <DefaultInput id="siret" icon={Resources.form_siret_icon}
                                        placeholder="ex: 442 293 775 00031"
                                        mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/]}
                                        error_parent_container="siret-error-container"></DefaultInput>
                                    <InputGroupAddon addonType="append">
                                        <DefaultButton className="t2y-secondary-button" style={{maringRight:"100px", height: "52px"}} loading={this.state.search_in_progress} onClick={(e) => this.search()} icon={Resources.form_search_icon}></DefaultButton>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" md="12" className="justify-content-text-center order-lg-2 order-1">
                            {this.renderCompanyInfos()}
                            </Col>
                        </Row>
                    </Col>
                    
                </Row>
                
                
            </Container>
            </>
        );
    }
}


export default injectIntl(SiretInputStep)

