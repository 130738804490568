
import React from "react";
import ImageWithRescue from "./ImageWithRescue";
import Config from "../../../../config";


class TruckImage extends React.Component {
    constructor(props) {
      super(props);

    }
    render() {
      

        return (<>
        <ImageWithRescue
          className={this.props.className}
          maxWidth={this.props.maxWidth}
          alt={this.props.truck ? this.props.truck.description : ""}
          imageUrl={this.props.truck ? Config.bndApi + "/truck/image/" + this.props.truck.id : null}
          defaultImage={require("../../../../assets/img/icons/common/empty_truck_image.svg").default }
        />
      </>)
    }
};

export default TruckImage