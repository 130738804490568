import React from "react";
import classnames from "classnames"

import {
    Row,
    Col,
    Button,
    Table,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardHeader,
    CardBody,
    Badge,
    CardTitle,
    Progress,
    ButtonGroup
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import ShipperService from "../../../proxies/ShipperService";
import DefaultImage from "../common/defaultImage";
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import Resources from "../../../resources";
import StringHelper from "../../../helpers/StringHelper";
import ArrayHelper from "../../../helpers/ArrayHelper";
import EmptyResult from "../common/emptyResult";
import DeleteButton from "../common/deleteButton";
import LoadingContainer from "../common/loadingContainer";
import Config from "../../../config";
import DefaultButton from "../common/defaultButton";
import RepositoryDataComponent from "../../pages/components/RepositoryDataComponent";
import Gallery from "react-grid-gallery";
import BusinessService from "../../../proxies/BusinessService";
import MapRouteComponent from "../../pages/components/map/MapRouteComponent";
import GeocodingAndNavigationService from "../../../proxies/GeocodingAndNavigationService";
import Title3 from "../common/title3";
import ReactSlidingPane from "react-sliding-pane";
import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator";
import ShipperCustomerContactInput from "../business/shipperCustomerContactInput";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import { add } from "date-fns/esm";
import DefaultInput from "../common/defaultInput";
import FormLegend from "../form/formLegend";
import Message from "../common/message";
import CivilityInput from "../common/civilityInput";
import DefaultCheckbox from "../common/defaultCheckbox";


var moment = require('moment');
require('moment/locale/fr');

class CustomerAddressContacts extends FormValidationComponent {
    SELECTION = 1
    FORM = 2

    constructor(props) {
        super(props)

        this.shipperService = new ShipperService()
        this.checkboxes = {}
        
        this.state = {
            customerContactsInputId: StringHelper.createNewGuid(),
            contacts: this.props.address.users ? this.props.address.users : [],
            defaultContact: this.props.defaultContact,
            customerContactModalIsVisible: false,
            mode: this.SELECTION,
            contactsExists: false
        }

        this.validationRules = {
            civility_code : { required: true, },
            first_name : { required: true, },
            last_name : { required: true, },
            mobile_phone_number: {
                required : false, 
                rules : [{
                    rule : this.mobilePhoneNumber.bind(this)
                }]
            },
            email : { 
                required : false,
                rules : [{
                    rule : this.email.bind(this),
                    ruleMessage: this.translateMessage("user.email_invalid_message")
                }]
            }
        }
    }

    componentWillMount() {
        this.updateContacts(this.getDefaultContact())
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    getDefaultContact()
    {
        return this.state.defaultContact
    }

    updateContacts(defaultContact, contacts = null)
    {
        contacts = !contacts ? this.state.contacts : contacts;

        contacts.forEach(c => {
            c.checked = (defaultContact && defaultContact.id == c.id) ? true: false

            if (this.checkboxes[c.id])
            {
                this.checkboxes[c.id].setValue(c.checked)
            }
        })

        this.setState({contacts: contacts, defaultContact: defaultContact})
    }
    
    isDefaultContact(contact)
    {
        const defaultContact = this.getDefaultContact()
        return defaultContact && defaultContact.id == contact.id
    }

    onDefaultContactChanged(contact, contacts = null)
    {
        var defaultContact = this.getDefaultContact();
        if (defaultContact != null && defaultContact.id == contact.id)
        {
            defaultContact = null;
        }
        else
        {
            defaultContact = contact
        }
        
        this.updateContacts(defaultContact, contacts)

        if (this.props.onDefaultContactChanged)
        {
            this.props.onDefaultContactChanged(this.props.address, defaultContact)
        }
    }

    deleteAddressContact(customerId, addressId, contactId)
    {
        this.showModalConfirmation({
            title: "", 
            component: <>Confirmez vous la suppression de ce contact pour cette opération?</>, 
            okButtonTitle: StringHelper.translate(this, "Default.yes"), cancelButtonTitle: StringHelper.translate(this, "Default.no"),
            okButtonCallback: () => {
                
                this.shipperService.deleteCustomerAddressContact(this, customerId, addressId, contactId, (response) => {

                    // Suppression de la données
                    const contacts = ArrayHelper.removeArrayItem({id: contactId}, this.state.contacts, ["id"])
                    this.setState({
                        contacts: contacts
                    });
                    
                    // Confirmation
                    this.showSuccessToastNotification("Suppression effectuée avec succès", <></>);

                    // Si il ne reste q'un seul contact, on le selectionne par défaut
                    if (contacts.length == 1)
                    {
                        this.onDefaultContactChanged(contacts[0], contacts);
                    }
                    // Sinon si c'etait le contact par défaut alors on l'annuke
                    else if (this.isDefaultContact({id: contactId}))
                    {
                        // Si il reste un seul contact, alors il est automatiquement selectionné
                        this.onDefaultContactChanged({id: contactId}, contacts);
                    }
                });
            },
            cancelButtonCallback: () => {}
        })
    }

    saveNewContact()
    {
        var address = this.props.address
        var customer = address.customer

        // Vérification des données du formulaire
        var formIsValid = this.htmlFormIsValid(null);

        // Récupération des données saisies
        var contactInfos = {
            civility_code : this.getHtmlFormControlValue("civility_code"),
            first_name : this.getHtmlFormControlValue("first_name"),
            last_name : this.getHtmlFormControlValue("last_name"),
            email: this.getHtmlFormControlValue("email"),
            mobile_phone_number : this.cleanPhoneNumber(this.getHtmlFormControlValue("mobile_phone_number"))
        }
        
        if (formIsValid)
        {
            this.shipperService.addUnknownCustomerContactsToAddress(this, customer.id, address.id, contactInfos, (response) => {
                if (response.list.length > 0)
                {
                    this.addContactsToList(response.list)
                }
            })
        }

    }

    addKnownCustomerContact()
    {
        var selectedContacts = this.shipperCustomerContactInputRef.getValues()
        var address = this.props.address
        var customer = address.customer

        // Affectation des contacts existant à l'adresse en cours
        this.shipperService.addKnownCustomerContactsToAddress(this, customer.id, address.id, selectedContacts.map(e => { return e.id}), (response) => {
            if (response.list.length > 0)
            {
                this.addContactsToList(response.list)
            }
        });
        
        this.setState({ customerContactModalIsVisible: false });
    }

    addContactsToList(contactsToAdd)
    {
        if (contactsToAdd.length > 0)
        {
            this.showSuccessToastNotification("Contact(s) ajouté(s) avec succès", <></>);

            // Ajout des nouveaux contacts à la liste
            var contacts = this.state.contacts;
            contactsToAdd.forEach(newContact => {
                contacts = ArrayHelper.replaceArrayItem(newContact, contacts, ["id"]);
            })

            // Si la liste actuelle ne compte pas de contact et qu'on en a ajouté un, on le passe automatiquement en contact par défaut
            if (this.state.contacts.length == 0 && contactsToAdd.length == 1)
            {
                this.onDefaultContactChanged(contactsToAdd[0], contacts);
            }



            this.setState({
                contacts: contacts
            });
        }

        this.setState({ customerContactModalIsVisible: false });
    }
    
    showCustomerContactsModal()
    {
        this.setState({customerContactModalIsVisible: true})
    }

    renderKnownCustomerContacts()
    {
        return <>
            <Row>
                <Col xs="12">
                    <FormGroupInput type={"multiline"} label={"Contact(s)"} htmlFor="contacts" tooltip={{message:""}}>
                        <ShipperCustomerContactInput key={this.state.customerContactsInputId} onDataBinded={(nb) => {this.setState({contactsExists: nb> 0})}}  childRef={(elt) => this.shipperCustomerContactInputRef = elt} customer={this.props.address.customer}></ShipperCustomerContactInput>
                    </FormGroupInput>
                    {
                        (this.state.contactsExists) ? 
                        (
                            <DefaultButton onClick={() => this.addKnownCustomerContact()} className="t2y-secondary-button small btn-dropdown-zindex">Ajouter ce(s) contact(s)</DefaultButton>
                        ) : (<></>)
                    }
                    
                </Col>
            </Row>
        </>
    }

    renderNewCustomerContactForm()
    {
        return <><Row>
            <Col lg="12">
                    <div className="pl-lg-4">
                        <FormLegend required={true} />
                        <hr/>
                        <Row>
                            <Col lg="12">
                                <FormGroupInput 
                                    intlId={"user.civility"} 
                                    htmlFor={"civility_code"}
                                    type="multiline">
                                    <CivilityInput required={true} manager={this} id="civility_code"></CivilityInput>
                                </FormGroupInput>
                            </Col>
                            <Col lg="12">
                                <FormGroupInput intlId={"user.first_name"} htmlFor="first_name" type="multiline">
                                    <DefaultInput required={true} childRef={(elt) => this.firstNameInputRef = elt}  name='first_name' manager={this} />
                                </FormGroupInput>
                            </Col>
                            <Col lg="12">
                                <FormGroupInput intlId={"user.last_name"} htmlFor="last_name" type="multiline">
                                    <DefaultInput required={true} childRef={(elt) => this.lastNameInputRef = elt}  name='last_name' manager={this} />
                                </FormGroupInput>
                            </Col>
                            <Col lg="12">
                                <FormGroupInput intlId={"user.mobile_phone_number"} htmlFor="mobile_phone_number" type="multiline"
                                    tooltip={{message: this.translateMessage("driver.mobile_phone_number_infos")}}
                                >  
                                    <DefaultInput required={true} childRef={(elt) => this.mobilePhoneNumberInputRef = elt}  name='mobile_phone_number' manager={this} />
                                </FormGroupInput>
                            </Col>
                            <Col lg="12">
                                <FormGroupInput intlId={"user.email"} htmlFor="email" type="multiline">
                                    <DefaultInput childRef={(elt) => this.emailInputRef = elt}  name='email' manager={this} />
                                </FormGroupInput>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className="align-item-center justify-content-center mb-3" >
                <DefaultButton
                    className="t2y-secondary-button"
                    key={"add_unknown_contact"}
                    onClick={(e) => this.saveNewContact()}
                    label={"Ajouter ce contact"}
                    icon={Resources.button_add_icon}>
                </DefaultButton>
            </Row>
        </>
    }

    renderCustomerContactsModal()
    {
        return <>
            <ReactSlidingPane
                className="some-custom-class"
                overlayClassName="some-custom-overlay-class"
                isOpen={this.state.customerContactModalIsVisible}
                title={null}
                width="40%"
                subtitle={null}
                onRequestClose={() => {
                    // triggered on "<" on left top click or on outside click
                    this.setState({ customerContactModalIsVisible: false });

                    if (this.props.onHide) 
                        this.props.onHide(null)
                }}
            >
                <FormLoadingIndicator loading={this.state.loading}>
                <div className="container customer-address-contacts-container">
                    <Row className="justify-content-center mb-5">
                        <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                            <Button className={classnames({ active: this.state.mode == this.SELECTION })} color="secondary"
                                onClick={(e) => this.setState({mode: this.SELECTION})}>
                                Selectionner un existant
                            </Button>
                            <Button className={classnames({ active: this.state.mode == this.FORM })} color="secondary"
                                onClick={(e) => this.setState({mode: this.FORM})}>
                                Créer un nouveau
                            </Button>
                        </ButtonGroup>
                    </Row>
                    {
                        (this.state.mode == this.SELECTION) ?
                        (
                            this.renderKnownCustomerContacts()
                        ) : (
                            this.renderNewCustomerContactForm()
                        )
                    }
                </div>
                </FormLoadingIndicator>

            </ReactSlidingPane>


        </>
    }

    isReadOnly()
    {
        const address = this.props.address;
        
        if (!address.customer_company_address || (this.props.readonly))
        {
            return true;
        }
        else
        {
            return !((address.type && address.type.code == "ADDRESS_TYPE_CUSTOMER") || (address.customer && address.customer.id));
        }
    }

    allowAddContact()
    {
        const address = this.props.address;
        if (!address || this.props.readonly)
        {
            return false;
        }
        else {
            return address.type && address.type.code == "ADDRESS_TYPE_CUSTOMER" && (address.customer != null && (address.customer.first_name == null))
        }
    }
    
    render() {
        const address = this.props.address;
        return (
            <>
                {this.renderParent()}
                {this.renderCustomerContactsModal()}
                {
                    
                        (this.state.contacts.length > 0) ?
                        (
                            <>
                                <hr/>
                                <Row className="mb-2">
                                    <Col xs="12">
                                        <Title3 style={{float:"left"}}>Contact(s) pour cette opération</Title3>
                                        {
                                            (this.allowAddContact()) ?
                                            (
                                                <DefaultButton onClick={() => this.showCustomerContactsModal()} style={{float:"right"}} icon={Resources.button_add_icon} className={"t2y-secondary-button small"}>Ajouter</DefaultButton>
                                            ) : (<></>)
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <Table className="t2y-secondary-table" responsive>
                                            <thead>
                                                <tr>
                                                    <th width="70%">Désignation</th>
                                                    <th>Référent</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                        {
                                            this.state.contacts.map((contact, userIndex) => {
                                                const checked = this.state.contacts.length == 1 ? true: contact.checked
                                                return <>
                                                    <tr key={"tr_contacts_" + userIndex}>
                                                        <td width="70%">{
                                                            (checked) ? 
                                                            (
                                                                <strong>{contact.summary}<br/> {contact.mobile_phone_number} / {contact.email}</strong>
                                                            ) : (
                                                                <>{contact.summary}<br/> {contact.mobile_phone_number} / {contact.email}</>
                                                            ) 
                                                        }    
                                                        </td>
                                                        <td style={{textAlign:"center"}}>
                                                            {
                                                                <DefaultCheckbox childRef={elt => this.checkboxes[contact.id] = elt} key={"contact_" + userIndex} readonly={this.state.contacts.length == 1 || this.props.readonly} onChange={(e) => this.onDefaultContactChanged(contact)} checked={checked} ></DefaultCheckbox>
                                                            }
                                                            {
                                                                (this.isReadOnly() == false) ?
                                                                (
                                                                    <DeleteButton style={{float:"right", position:"relative", top:"-30px"}} icon={Resources.button_unlink_icon} label={false} onClick={(e) => {this.deleteAddressContact(address.customer.id, address.id, contact.id)}}></DeleteButton>
                                                                ) : (<></>)
                                                            }
                                                        </td>
                                                    </tr>
                                                </>
                                            })
                                        }
                                        </tbody>
                                        </Table>
                                    </Col>  
                                </Row>
                                <hr/>
                            </>
                        ) : (
                            (this.allowAddContact() == false) ?
                            (
                                <></>
                            ) :
                            (
                                <>
                                    <Row className="justify-content-center text-center">
                                        <DefaultButton onClick={() => this.showCustomerContactsModal()} icon={Resources.button_add_icon} className={"t2y-secondary-button small"}>Ajouter un contact</DefaultButton>
                                    </Row>
                                </>
                            )
                        )
                }
            </>
        );
    }
}


export default injectIntl(CustomerAddressContacts)

