import "../../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
import { Row, Col} from "reactstrap";
import FormComponent from "./FormComponent";
import ReactDatetime from "react-datetime";
import Resources from "../../../../resources";

class FormHourInput extends FormComponent
{

    constructor(props, multiple = false)
    {
        super(props)
        this.state = {}
    }

    renderInput(props, openCalendar, closeCalendar )
    {
        function clear(){
            props.onChange({target: {value: ''}});
        }
        return (
            <div>
                <input {...props} autoComplete="OFF" />
            </div>
        );
    }

    render() {
        let validationState = this.getValidationState();
        const defaultValue = this.props.value ? this.props.value : this.getDefaultValue();
      return (
        <>
            <Row>
                <Col xs="12" className="t2y-rdt-container">
                    <i className={"t2y-rdt-icon " + Resources.time_logo}></i>
                    <ReactDatetime
                        open={this.props.open}
                        className={this.props.className}
                        style={this.props.style}
                        placement={this.props.placement}
                        renderInput={ this.renderInput }
                        inputProps={{id:this.getKey(), autoComplete:'on'}}
                        autoComplete="new-password"
                        key={this.generateUniqueId()}
                        inputProps={{id:this.getKey(), name:this.getKey()}}
                        isValidDate={(date) => {return true}}
                        defaultValue={defaultValue == null ? "" : defaultValue}
                        viewMode={"time"}
                        dateFormat={false}
                        timeConstraints={this.props.timeConstraints ?  this.props.timeConstraints : {minutes : {step:15}}}
                        locale={"fr-FR"}
                        onChange={(e) => this.props.manager ? this.props.manager.htmlInputChanged(e, this.getKey()) : this.props.onChange(e)}
                        onBlur={(e) => this.props.manager ? this.props.manager.htmlInputChanged(e, this.getKey()): (this.props.onBlur ? this.props.onBlur(e): null) }
                    />
                </Col>
                {/*
                <Col xs="5">
                    {this.renderHours()}
                </Col>
                <Col xs="1">:</Col>
                <Col xs="6">
                    {this.renderMinutes()}
                </Col>
                */}
                
            </Row>
            
        </>
      );
    }
}

 
export default FormHourInput;
