
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Button
  } from "reactstrap";
import DateHelper from "../../../helpers/DateHelper";
import { injectIntl , FormattedMessage } from 'react-intl';
import FormRepository from "../../pages/components/form/FormRepository";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

var moment = require('moment');
require('moment/locale/fr');



class DateLabel extends React.Component {

    constructor(props)
    {
        super(props)

        this.state = {
        }
    }

    componentDidMount() {}

    componentWillMount() {}

    componentWillUnmount() {}

    renderAutomaticLabel()
    {
        var intlKey = "Date.all_day";
        if (this.props.half == true)
        {
            intlKey = "Date.half_day"
        }

        return <>
            <FormattedMessage id={intlKey}></FormattedMessage>
        </>
    }

    renderDate(date, label)
    {
        // Gestion des DateTime Php
        date = date && date.date ? date.date : date;

        return <>
            {
                (label == false) ?
                (<></>)
                :
                (
                    (label == null) ?
                    (
                        <>{this.renderAutomaticLabel()}</>
                    ) : (<>{label}</>)
                )
            }
            {DateHelper.formatWithoutTime(date, this.props.intl)}
        </>
    }

    renderRange()
    {
        return <>
            <FormattedMessage id="Date.range_start"></FormattedMessage>
            {this.renderDate(this.props.start_date, false)}
            <FormattedMessage id="Date.range_end"></FormattedMessage>
            {this.renderDate(this.props.end_date, false)}
        </>
    }

    render() 
    {
        if (this.props.start_date != null && this.props.end_date != null)
        {
            return this.renderRange();
        }
        else
        {
            return this.renderDate(this.props.date, this.props.label);
        }

    }
}

export default injectIntl(DateLabel)
