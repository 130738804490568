import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import Step from "../step"
import DefaultLabel from "../../common/defaultLabel";

var moment = require('moment');
require('moment/locale/fr');

class HeaderComponent extends React.Component {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.state = {
        }
    }

    componentWillMount() {
    }

    componentDidMount() {    
    }


    
    render() {
        return <>
            <Row className="stepper-container">
                <div className="stepper">
                    <div class="stepper-step stepper-step-3 done">
                        <a href="#step-1" type="button" 
                        class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                        data-placement="top" title="" data-original-title="Basic Information">
                            <i className="far fa-user" style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content="Vos informations personnelles"></DefaultLabel>
                    </div>

                    <div class={"stepper-step stepper-step-3 " + (this.props.siret ? "current" : (this.props.error ? "warning" : "done"))}>
                        <a href="#step-1" type="button" 
                        class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                        data-placement="top" title="" data-original-title="Basic Information">
                            <i className="far fa-building" style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content="Votre société">
                        </DefaultLabel>
                    </div>

                    {
                        (this.props.siret || this.props.success) ?
                        (
                            <div class={"stepper-step stepper-step-3 " + (this.props.success ? "current" : "not-done")}>
                                <a href="#step-1" type="button" 
                                class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                                data-placement="top" title="" data-original-title="Basic Information">
                                    <i className="fas fa-flag-checkered" style={{fontSize: "30px"}}></i>
                                </a>
                                <DefaultLabel className="stepper-step-label" content="Vous êtes prêts à commencer"></DefaultLabel>
                            </div>
                        ) : 
                        (
                            <div class={"stepper-step stepper-step-3 current"}>
                                <a href="#step-1" type="button" 
                                class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                                data-placement="top" title="" data-original-title="Basic Information">
                                    <i className="fas fa-headset" style={{fontSize: "30px"}}></i>
                                </a>
                                <DefaultLabel className="stepper-step-label" content="Action impossible"></DefaultLabel>
                            </div>
                        )
                    }

                </div>
            </Row>

        </>
    }
}


export default injectIntl(HeaderComponent)

