
import React from "react";
import classnames from "classnames";
import $ from "jquery";
import queryString from 'query-string';

// reactstrap components
import {
    Container,
    Col,
    Card,
    Form,
    CardHeader,
    CardBody,
    Row,
    ButtonGroup,
    Button
} from "reactstrap";



import { injectIntl, FormattedMessage } from 'react-intl';
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import SecurityService from "../../../proxies/SecurityService";
import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator";
import DefaultInput from "./defaultInput";
import DefaultButton from "./defaultButton";
import DefaultLink from "./defaultLink";
import Title2 from "./title2";
import Title3 from "./title3";
import Resources from "../../../resources";
import DefaultImage from "./defaultImage";
import StringHelper from "../../../helpers/StringHelper";
import AuthNavbar from "../../../components/Navbars/AuthNavbar";


class SmsValidationForm extends FormValidationComponent {

    constructor(props) {
        super(props)

        this.securityService = new SecurityService();


        this.validationRules = {
            activation_code: {
                required: true
            }
        }

        this.state = {
            loading: false,
            ready: null
        }
    }

    componentDidMount() {
        super.componentDidMount()
        document.body.classList.add("bg-default");
        document.body.classList.add("t2y-bg-auth-layout");

        // Récupération du code de validation par sms
        this.smsAuthentificationProcess(false);
    }

    componentWillUnmount() {
        document.body.classList.remove("bg-default");
        document.body.classList.remove("t2y-bg-auth-layout");
    }

    confirmSmsAuthentificationProcess()
    {
        this.showModalConfirmation({
            iconComponent: <>
                <div className="text-center text-success"  style={{fontSize:"100px",}}>
                    <DefaultImage src={Resources.button_success_icon}></DefaultImage>
                </div>
            </>,
            title: "", 
            component: <>Confirmez vous l'envoi d'un nouveau code d'activation ?</>, 
            okButtonTitle: StringHelper.translate(this, "Default.yes"), cancelButtonTitle: StringHelper.translate(this, "Default.no"),
            okButtonCallback: () => {
                this.smsAuthentificationProcess(true)
            },
            cancelButtonCallback: () => {}
        })
    }

    smsAuthentificationProcess(force) {
        const parsedQueryString = queryString.parse(window.location.search);
        // Récupération de l'identifiant dans l'url
        if (parsedQueryString.id && this.notEmptyValue(parsedQueryString.id)) {
            this.securityService.getSmsForAction(this, parsedQueryString.id, window.location.href, force, { transporterId: parsedQueryString.transporterId }, (response) => {

                this.securityService.storeSmsValidationToken(response.token);
                this.setState({
                    ready: true,
                    tokenResponse: response
                })

                this.showSuccessModalNotification("", "Un code d'activation vient de vous être envoyé par SMS");
            },
                (httpErrorResponse) => {
                    httpErrorResponse.json().then((apiResponse) => {
                        this.showErrorFromResponse(apiResponse, null)
                    })
                    this.setState({ loading: false, ready: httpErrorResponse.status == "400" })
                });
        }
        else {
            this.showWarningModalNotification("", "Vous n'avez pas les autorisations nécessaires pour accéder à cette page")
        }
    }


    componentWillMount() {
        super.componentWillMount()
    }

    onSubmitEvent(e)
    {
        const parsedQueryString = queryString.parse(window.location.search);
        e.preventDefault();
        var formIsValid = this.htmlFormIsValid(e);
        if (formIsValid)
        {
            // Validation du code sms avec le token formulaire si ok alors redirection vers l'url demandée
            this.securityService.smsValidation(this, parsedQueryString.id, this.state.tokenResponse, this.activationCodeInputRef.getValue(), (response) => {
                    this.securityService.storeAdministratorToken(response.token);
                    window.location.href = window.location.href;
                },
                (httpErrorResponse) => {
                    httpErrorResponse.json().then((apiResponse) => {
                        this.showErrorFromResponse(apiResponse, null)
                    })
                    this.setState({ loading: false })
                }
            );

        }
    }

    renderReady() {
        return <>
            <Form role="form" onSubmit={e => this.onSubmitEvent(e)} >
                <Card className="bg-secondary border-0 mb-0 p-1">
                    <Row>
                        <Col lg="12" md="12" className="text-center justify-content-text-center">
                            <img src={require('../../../assets/img/illustrations/sms_activation_code_form.svg').default} style={{ width: "50%" }} />
                        </Col>
                        <Col lg="12" md="12" className="order-lg-1 order-2">
                            <Row className="mb-3">
                                <Col>
                                    <Title2>
                                        <FormattedMessage id="SmsValidation.title1"></FormattedMessage>
                                    </Title2>
                                    <Title3>
                                        <FormattedMessage id="SmsValidation.title2"></FormattedMessage>
                                    </Title3>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <DefaultInput 
                                        required={true} 
                                        childRef={(elt) => this.activationCodeInputRef = elt}  
                                        name='activation_code'
                                        icon={Resources.form_password_icon} placeholder={this.translateMessage("SmsValidation.activation_code")}
                                        manager={this} >
                                        
                                    </DefaultInput>
                                </Col>
                            </Row>
                        </Col>


                    </Row>
                    <Row className="align-item-center justify-content-center mb-3" >
                        <DefaultButton
                            type="submit"
                            className="t2y-secondary-button"
                            key={"submit_button"}
                            onClick={(e) => this.onSubmitEvent(e)}
                            label={this.translateMessage("SmsValidation.submit_button_title")}
                            icon={Resources.button_save_icon}>
                        </DefaultButton>
                    </Row>
                    <Row className="align-item-center justify-content-center mb-3" >
                        <DefaultLink small={true} onClick={(e) => this.confirmSmsAuthentificationProcess()}>{this.translateMessage("SmsValidation.new_code_button_title")}</DefaultLink>
                    </Row>

                </Card>
            </Form>
        </>
    }

    renderNotReady() {
        {
            (this.state.ready === false) ?
                (
                    <></>
                ) :
                (
                    <Card className="bg-secondary border-0 mb-0 p-1">
                        <Col lg="12" md="12" className="text-center justify-content-text-center">
                            <img src={require('../../../assets/img/illustrations/sms_activation_code_form.svg').default} style={{ width: "50%" }} />
                        </Col>
                    </Card>
                )
        }
    }

    render() {
        return <>
            {this.renderParent()}
            <AuthNavbar className="t2y-auth-navbar" />
            <FormLoadingIndicator loading={this.state.loading} />
            <div className="main-content t2y-main-auth-content" ref="mainContent">
                <Row>
                    <Container className="">
                        <Row className="justify-content-center">
                            <Col xs="12" lg="8">
                                {
                                    (this.state.ready) ?
                                    (
                                        this.renderReady()
                                    ) : (
                                        this.renderNotReady()
                                    )
                                }
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </div>
        </>
    }
}

export default injectIntl(SmsValidationForm)
