
import React from "react";
import PropTypes from 'prop-types';

import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    Col
} from "reactstrap";

import { injectIntl, FormattedMessage } from 'react-intl';
import BusinessService from "../../../proxies/BusinessService";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import DefaultLabel from "../common/defaultLabel";
import Rating from "../rating/rating";
import Title3 from "../common/title2";
import Message from "../common/message";
import WebsiteHelper from "../../../helpers/WebsiteHelper";

var moment = require('moment');
require('moment/locale/fr');

class RedirectionAlert extends React.Component {

    constructor(props) {
        super(props)

        this.businessService = new BusinessService();

        this.state = {
            loading: true,
            seconds: this.props.seconds ? this.props.seconds : 5
        }
    }


    componentWillMount() {

    }

    componentDidMount() {
        setInterval(function () {
            var currentSeconds = this.state.seconds - 1;
            this.setState(state => ({
                seconds: currentSeconds
            }));

            if (currentSeconds <= 0) {
                WebsiteHelper.goTo(this, this.props.url)
            }

        }.bind(this), 1000);
    }

    render() {
        return <>
            <Row className="justify-content-center" style={this.props.style} >
                <Message type="infos">
                    Vous allez être redirigé automatiquement dans {this.state.seconds} secondes
            </Message>
            </Row>
        </>
    }
}


export default injectIntl(RedirectionAlert)
