
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    Col,
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    NavLink,
    Modal,
    Button
  } from "reactstrap";
import { Calendar } from 'react-date-range';

import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator"
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import FormHourInput from "../../pages/components/form/FormHourInput";
import ReactDatetime from "react-datetime";
import RepositoryService from "../../../proxies/RepositoryService";

import { injectIntl , FormattedMessage } from 'react-intl';
import FormRepository from "../../pages/components/form/FormRepository";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ShipperService from "../../../proxies/ShipperService";
import DefaultPaginatedList from "../../components/common/defaultPaginatedList"
import NotificationComponent from "../components/NotificationComponent";
import PaymentMethodSummary from "../../components/business/paymentMethodSummary"
import Message from "../../components/common/message";
import Resources from "../../../resources";
import DefaultButton from "../../components/common/defaultButton";
import DefaultImage from "../../components/common/defaultImage";
import DefaultLabel from "../../components/common/defaultLabel";
import AddPaymentMethodComponent from "../../components/payment/AddPaymentMethodComponent";
import Codes from "../../../codes";
import StringHelper from "../../../helpers/StringHelper";

var moment = require('moment');
require('moment/locale/fr');

class ShipperPaymentMethods extends NotificationComponent {

    constructor(props)
    {
        super(props)

        this.shipperService = new ShipperService();

        this.state = {
            loading: false,
            paymentMethods: [],
            headerComponent: null,
            newPaymentMethodModalIsVisible: false
        }
    }

    componentWillMount()
    {
    }

    componentDidMount()
    {
    }

    toggleModal()
    {
        this.setState({newPaymentMethodModalIsVisible: !this.state.newPaymentMethodModalIsVisible})
    }

    onPaymentMethodSavedError(error)
    {
        this.showErrorModalNotification("", <>Impossible de traiter votre demande {error ? <><br/>{error}</> : <></>}</>)
    }
    
    onPaymentMethodAdded(paymentInfos)
    {
        this.toggleModal();

        if (paymentInfos.type == Codes.IBAN)
        {
            this.showCustomModalNotification("", <>
                {StringHelper.translateWithHtml(this, "Payment.iban_mandat_confirmation", "", {"mandate_rum_number": paymentInfos.mandate_rum_number})}
            </>)
        }
        else
        {
            this.showSuccessToastNotification("Moyen de paiement ajouté avec succès")
        }
        // Rechargement de la liste
        this.paymentMethodsListRef.getItems(1);
    }


    downloadSepaMandate(paymentMethodId)
    {
        this.shipperService.downloadSepaMandate(this, paymentMethodId, response => {

            const linkSource = `data:application/pdf;base64,${response.instance.base64_content}`;
            const downloadLink = document.createElement("a");
            const fileName = response.instance.filename;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        });
    }

    renderNewPaymentMethodModal()
    {
        return <Modal fade={false} className="modal-dialog-centered"  size="lg" isOpen={this.state.newPaymentMethodModalIsVisible} toggle={() => this.toggleModal()} >
            <div className="modal-content">
                <div className="modal-header pb-0">
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal()} >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <AddPaymentMethodComponent 
                        onPaymentSuccess={(paymentInfos => this.onPaymentMethodAdded(paymentInfos))}
                        onPaymentError={(error) => this.onPaymentMethodSavedError(error)}
                    />
                </div>
            </div>
        </Modal>
    }

    onListLoaded(count)
    {
        
    }

    render() {
        const url = "/api/payment/method/all/{page}/{itemsPerPage}";

        return <>
            {this.renderParent()}
            {this.renderNewPaymentMethodModal()}
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
            
            <Row className="mb-2">
                    <Col xs="12">
                        <div class="text-right">
                    <DefaultButton className="pull-right t2y-secondary-button small" onClick={evt => this.toggleModal()} icon={Resources.button_add_icon}>
                        Ajouter un moyen de paiement
                    </DefaultButton>     
                    </div>
                    </Col>
            </Row>
            <DefaultPaginatedList 
                childRef={elt => this.paymentMethodsListRef = elt}
                onLoaded={(count) => {
                    this.onListLoaded(count)
                }}
                noResultComponent={
                    <>
                    <Row className="align-item-center justify-content-center">
                        <Col md="6">
                            <Message type="default">
                                Vous n'avez renseigné aucun moyen de paiement pour le moment.
                            </Message>
                        </Col>
                    </Row>
                    </>
                }
                cache={true} 
                datasource={{mode:"GET", url:url, pageParameter:"{page}", itemsPerPageParameter: "{itemsPerPage}"}}
                columns={[
                    {name:"Type", value: (item, index) => { return <PaymentMethodSummary imageOnly={true} paymentMethod={item}></PaymentMethodSummary>}},
                    {name:"Référence", value: (item, index) => { return <PaymentMethodSummary numberOnly={true} paymentMethod={item}>
                        </PaymentMethodSummary>}},
                    {name:"Validité", value: (item, index) => { return <PaymentMethodSummary eligibilityOnly={true} paymentMethod={item}>
                        </PaymentMethodSummary>}},
                    {name:"Signature", value: (item, index) => { return <PaymentMethodSummary signInfosOnly={true} paymentMethod={item}>
                        </PaymentMethodSummary>}},
                    {name:"Mandat", value: (item, index) => { return <>
                        <PaymentMethodSummary rumOnly={true} paymentMethod={item}></PaymentMethodSummary>
                        {
                            (item.type.code == "PAYMENT_METHOD_TYPE_IBAN") ?
                            (
                                <DefaultButton style={{width:"30px", height:"30px", padding:0, marginLeft:"5px"}} small={true} className="t2y-thirdparty-alt-button" onClick={(e) => this.downloadSepaMandate(item.id)}>
                                    <DefaultImage style={{fontSize:20}} src={Resources.downloaded_file_logo}></DefaultImage>
                                </DefaultButton>
                            ) : (<></>)
                        }
                    </>}}
                ]}
            />
        </>
    }
}

export default injectIntl(ShipperPaymentMethods)