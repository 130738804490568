import "../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
import PropTypes from "prop-types";
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components
 import {
   Form
 } from "reactstrap";
import RepositoryService from "../../../proxies/RepositoryService"
import { injectIntl , FormattedMessage } from 'react-intl';

import { Multiselect } from 'multiselect-react-dropdown';
import DefaultInput from "../common/defaultInput";
import DateLabel from "../common/dateLabel";
import DefaultPopover from "../common/defaultPopover";
import StringHelper from "../../../helpers/StringHelper";
import DefaultImage from "../common/defaultImage";
import PaymenMethodNumber from "../business/paymentMethodNumber";
import TagsInput from "react-tagsinput";
import Resources from "../../../resources";

class PaymentMethodSummary extends DefaultInput     {

    constructor(props)
    {
        super(props)
        
        this.state = {
            icons: {
                "mastercard" : {src: require("../../../assets/img/icons/mastercard.svg" ).default, width:"20px"},
                "visa" : {src: require("../../../assets/img/icons/visa.svg" ).default, width:"30px"},
                "sepa": {src: require("../../../assets/img/icons/sepa.svg" ).default, width:"25px"}
            }
            
        }
    }

    componentWillMount()
    {
        super.componentWillMount();
    }

    componentDidMount()
    {
        super.componentDidMount();
    }

    renderIban()
    {
        const iban = this.props.paymentMethod
        if (this.props.imageOnly) 
        {
            return <DefaultImage style={{width:this.state.icons.sepa.width}} src={this.state.icons.sepa.src}></DefaultImage>
        }
        if (this.props.numberOnly)
        {
            return iban.iban_displayed_label
        }
        if (this.props.signInfosOnly)
        {
            return <>signé le <DateLabel label={false} start_date={iban.creation_date}></DateLabel> par {iban.iban_creator_label}</>
        }
        if (this.props.rumOnly)
        {
            return iban.iban_rum_number ? "RUM n°" + iban.iban_rum_number : ""
        }
        if (this.props.eligibilityOnly)
        {
            return <></>
        }

        
        return <>
            Mandat n°{iban.iban_rum_number} sur le compte n° {iban.iban_displayed_label} signé le <DateLabel label={false} start_date={iban.creation_date}></DateLabel> par {iban.iban_creator_label}
        </>
    }


    renderCardImage(card)
    {
        const cardBrandIconKey = "card_" + StringHelper.toCode(card.card_brand) + "_icon"

        return  <DefaultImage style={{fontSize:"20px", width: this.state.icons[card.card_brand] ? this.state.icons[card.card_brand].width : "30px"}} 
            src={this.state.icons[card.card_brand] ? this.state.icons[card.card_brand].src : (Resources[cardBrandIconKey] ? Resources[cardBrandIconKey] : Resources.card_default_icon)}>
        </DefaultImage>

    }

    renderCard()
    {
        const card = this.props.paymentMethod
        const index = StringHelper.createDomElementId();
        const cardBrandLabel = StringHelper.camelCase(card.card_brand)

        if (this.props.signInfosOnly || this.props.rumOnly)
        {
            return <></>
        }

        if (this.props.imageOnly) 
            return this.renderCardImage(card)

        if (this.props.numberOnly)
        {
            if (card.expired == false && card.expires_this_month)
            {
                return <span className="t2y-warning-color" >XXXX-XXXX-XXXX-{card.card_display_label}</span>
            }
            else if (card.expired)
            {
                return <span className="t2y-disable-color" >XXXX-XXXX-XXXX-{card.card_display_label}</span>
            }
            else
            {
                return <>XXXX-XXXX-XXXX-{card.card_display_label}</>;
            }
        }

        if (this.props.eligibilityOnly)
        {
            if (card.expired == false && card.expires_this_month)
            {
                return <>
                    <span className="t2y-warning-color" > {card.expiration_month_year}</span>
                    <DefaultPopover style={{color:"red"}} content="Cette carte expire ce mois-ci"></DefaultPopover>
                </>
            }
            else if (card.expired)
            {
                return <>
                    <span className="t2y-disable-color" > {card.expiration_month_year}</span>
                    <DefaultPopover style={{color:"var(--gris"}} content="Cette carte est expirée"></DefaultPopover>
                </>
            }
            else {
                return <>{card.expiration_month_year}</>
            }
        }

        return <>
            <label className={this.props.cardClassName + " " + (card.expired == false && card.expires_this_month ? "t2y-warning-color" : "")} htmlFor={"card" + index}>
                
                {this.renderCardImage(card)}&nbsp;
                {cardBrandLabel}&nbsp;
                XXXX-XXXX-XXXX-{card.card_display_label} valide jusqu'au&nbsp; 
                {
                (card.expired == false && card.expires_this_month) ?
                (
                    <>
                    <strong className="t2y-warning-color" > {card.expiration_month_year}</strong>
                    <DefaultPopover style={{color:"red"}} content="Cette carte expire ce mois-ci"></DefaultPopover>
                    </>
                ) : (<>{card.expiration_month_year}</>)
                }
            </label>
            {
            (card.expired) ?
            (
                <>
                <br/>
                <h5 style={{color: "#8898aa", fontWeight: "100"}} >
                    Cette carte expire avant la date de la prestation et ne peut pas être utilisée pour cette commande
                </h5>
                </>
            ) : (<></>)
            }
        </>
    }

    render()
    {
        if (!this.props.paymentMethod)
        {
            return <></>
        }

        return <>
            {
                (this.props.paymentMethod.type.code == "PAYMENT_METHOD_TYPE_CARD") ?
                (
                    this.renderCard()
                ) :
                (
                    this.renderIban()
                )
            }
        </>
    }
}

PaymentMethodSummary.propTypes = {
    paymentMethod: PropTypes.shape.isRequired,
    cardClassName: PropTypes.string,
    imageOnly: PropTypes.bool,
    eligibilityOnly: PropTypes.bool,
    signInfosOnly: PropTypes.bool
};

 
export default injectIntl(PaymentMethodSummary);