
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Container,
    Row,
    Col,
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    NavLink,
    Modal,
    Button
} from "reactstrap";

import { injectIntl, FormattedMessage } from 'react-intl';
import SecurityService from "../../../proxies/SecurityService";
import Config from "../../../config";
import StringHelper from "../../../helpers/StringHelper";
import Title1 from '../common/title1'
import Title2 from '../common/title2'
import Title3 from '../common/title3'
import DefaultLabel from "../common/defaultLabel";
import Paragraph from '../common/paragraph';
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import DefaultLink from "../common/defaultLink";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator";

class UserState extends FormValidationComponent {

    constructor(props) {
        super(props)

        this.securityService = new SecurityService();

        this.state = {
            loading: true,
            title: null,
            text: null,
            image: null,
            button: false,
            button_label: null,
            button_callback: null
        }
    }

    componentWillMount() {
    }

    redirectTo(relativeUrl)
    {
        WebsiteHelper.goTo(this, relativeUrl)
        return false;
    }

    unknownUserBadTokenRedirectToLogin(email)
    {
        this.setState({
            title: <FormattedMessage id="UserState.unknownUserBadTokenRedirectToLogin.title">{this.decodeEntities}</FormattedMessage>,
            text: <FormattedMessage id="UserState.unknownUserBadTokenRedirectToLogin.text">{this.decodeEntities}</FormattedMessage>,
            button: true,
            button_label: 'Me connecter',
            button_callback: function() {
                this.securityService.disconnect();
                this.redirectTo("/auth/login?email=" + (!email ? "" : email))
            }.bind(this)
        })
    }

    unknownUserRedirectToLogin(email) {
        this.setState({
            title: <FormattedMessage id="UserState.unknownUserRedirectToLogin.title">{this.decodeEntities}</FormattedMessage>,
            text: <FormattedMessage id="UserState.unknownUserRedirectToLogin.text">{this.decodeEntities}</FormattedMessage>,
            button: true,
            button_label: 'Me connecter',
            button_callback: function() {
                this.securityService.disconnect();
                this.redirectTo("/auth/login?email=" + (!email ? "" : email))
            }.bind(this)
        })
    }

    pendingPasswordValidation(userStateActionResponse) {
        var email = userStateActionResponse != null && userStateActionResponse.user != null ? userStateActionResponse.user.email : null;

        this.setState({
            title: <FormattedMessage id="UserState.pendingPasswordValidation.title">{this.decodeEntities}</FormattedMessage>,
            text: <FormattedMessage id="UserState.pendingPasswordValidation.text">{this.decodeEntities}</FormattedMessage>,
            button: true,
            button_label: 'Faire une nouvelle demande ',
            button_callback: function() {
                this.redirectTo("/auth/login?current_step=FORGET_PASSWORD&email=" + (!email ? "" : email))
            }.bind(this)

        })
    }

    /**
     * Envoi à nouveau du mail de validation de compte
     */
    resendRegisterConfirmationEmail()
    {
        this.securityService.resendRegisterEmail(this, this.email, this.emailResent.bind(this));
    }

    /*
    * Confirmation de l'envoi d'un nouveau mail
    */
    emailResent(response)
    {
        const message = this.translateMessage("RegisterConfirmation.resend_confirmation_message", {email : this.email})
        const title = this.translateMessage("RegisterConfirmation.resend_confirmation_title")

        this.showSuccessModalNotification(title, message);
    }

    confirmResendEmail()
    {
        this.showModalConfirmation({
            title: "", 
            component: <FormattedMessage id="RegisterConfirmation.resend_confirmation_question" />, 
            okButtonTitle: this.translateMessage("Default.yes"), 
            okButtonCallback: (response) => this.resendRegisterConfirmationEmail(),
            cancelButtonTitle: this.translateMessage("Default.no")
        })
    }
    
    pendingMailValidation(userStateActionResponse)
    {
        this.email = userStateActionResponse.user.email;
        this.setState({
            title: <FormattedMessage id="UserState.pendingMailValidation.title">{this.decodeEntities}</FormattedMessage>,
            text: <FormattedMessage id="UserState.pendingMailValidation.text">{this.decodeEntities}</FormattedMessage>,
            button: true,
            button_label: this.translateMessage("RegisterConfirmation.resend_email"),
            button_callback: function() {
                this.confirmResendEmail()
                
            }.bind(this)
        })
    }


    redirectToProfile()
    {
        this.redirectTo("/auth/login");
    }
    
    redirectToCompanyForm()
    {
        this.redirectTo("/admin/profile");
    }

    checkUserStateActionResponse(userStateActionResponse)
    {
        switch (userStateActionResponse.code) {
            case "LOGIN":
                this.unknownUserRedirectToLogin(null);
                break;
            case "LOGIN_WITH_EMAIL_BAD_TOKEN":
                this.unknownUserBadTokenRedirectToLogin(userStateActionResponse.user.email);
                break;
            case "LOGIN_WITH_EMAIL":
                this.unknownUserRedirectToLogin(userStateActionResponse.user.email);
                break;
            case "STATE_PENDING_PASSWORD_VALIDATION":
                this.pendingPasswordValidation(userStateActionResponse);
                break;
            case "STATE_PENDING_MAIL_VALIDATION":
                this.pendingMailValidation(userStateActionResponse);
                break;
            case "PROFILE":
                this.redirectToProfile();
                break;
            case "REGISTER_COMPANY":
                this.redirectToCompanyForm();
                break;
        }

        this.setState({
            loading: false
        })
    }

    componentDidMount() {
        if (this.props.userStateActionResponse)
        {
            this.checkUserStateActionResponse(this.props.userStateActionResponse)
        }
        else
        {
            var userStateActionPromise = this.securityService.checkUserStateAction(this.props.id, this.props.token);
            userStateActionPromise.then((userStateActionResponse) => {
                this.checkUserStateActionResponse(userStateActionResponse)
            });
        }
    }


    render() {
        return <>
            { this.renderParent() }
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
            <Container className="pb-5 mt-9" >
                {
                    //(this.state.loading == false) ?
                        (
                            <>
                                {
                                    (this.state.title != null) ?
                                        (
                                            <Row className="justify-content-center"> <Title2 center={true}>{this.state.title}</Title2></Row>
                                        ) : (<></>)
                                }
                                {
                                    (this.state.text != null) ?
                                        (
                                            <Paragraph center={true}>{this.state.text}</Paragraph>
                                        ) : (<></>)
                                }
                                {
                                    (this.state.button) ?
                                    (
                                        <Row className="justify-content-center">
                                            <DefaultLink small={true} onClick={() => this.state.button_callback()}>
                                                {this.state.button_label}
                                            </DefaultLink>
                                        </Row>

                                    ) : (<></>)
                                }


                            </>
                        ) //: (<></>)
                }
            </Container>
        </>
    }
}

export default injectIntl(UserState)
