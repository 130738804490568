import "../../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components
 import {
   Form
 } from "reactstrap";
import RepositoryService from "../../../../proxies/RepositoryService";
import FormSelect2Component from "./FormSelect2Component";

class FormDriversInput extends FormSelect2Component     {

    constructor(props)
    {
        super(props, false, function(element){
            return element.text;
        });
        this.repositoryService = new RepositoryService();
    }

    componentWillMount()
    {
        this.dataBind();
    }

    componentDidMount()
    {
        if(this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    dataBind(forceTruckId = null)
    {
        const truckId = forceTruckId ? forceTruckId : (this.props.truck ? this.props.truck.id : null)

        this.repositoryService.getDriversWithTruck(this, truckId, (response) => {
            var datas = []
            var withTruck = {"text": "Conducteur(s) associé(s) à ce camion", children:[]}
            var withoutTruck = {"text": "Autre(s) conducteur(s)", children:[]}

            if (this.props.emptyElement && this.props.emptyElement == true)
            {
                datas.push({id : "", text: typeof this.props.placeholder !== "undefined" ? this.props.placeholder : "..."})
            }
            
            response.drivers.forEach(element => {
                let option = {id : element.id, text: element.summary}
                datas.push(option);
                
                if (element.trucks)
                {
                    withTruck.children.push(option)
                }
                else
                {
                    withoutTruck.children.push(option)
                }
            });

            this.setState({datas : withTruck.children.length > 0 && withoutTruck.children.length > 0 ? [withTruck, withoutTruck] : datas});
        })
    }
  }
 
  export default FormDriversInput;