
import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal
  } from "reactstrap";
  
import SecurityService from '../../../../proxies/SecurityService';
// nodejs library that concatenates classes
import classnames from "classnames";
import FormInput from '../FormInput'
import FormValidationComponent from "../FormValidationComponent";
import FormLoadingIndicator from "../FormLoadingIndicator";
import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultLink from "../../../components/common/defaultLink";

class ChangeEmailModal extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    this.securityService = new SecurityService();
    this.index = 1;

    this.validationRules = {
      new_email : {
        required :true,
        rules : [
            {
            rule : this.email.bind(this),
            ruleMessage : this.translateMessage("Default.invalid_email")
            }
        ]
      }
    }

    this.state = {
        loading : false,
        visible : null,
        emailSent : false,
        formDatas : {
            email : { value: ""}
        }
    }
  }

  
  onSubmitEmailModalForm(e)
  {
    e.preventDefault();
    var formIsValid = this.htmlFormIsValid();
    if (formIsValid)
    {
        // Enregistrement des données
        var email = this.getHtmlFormControlValue("new_email");
        
        // Affichage de l'indicateur de chargement        
        this.setState({loading : true})
        // On cache la popup
        this.toggleEmailModal()
        this.securityService.changeUserEmail(this, email, this.props.userId, (response) => {
            // Si tout est ok, on affiche le message
            this.setState({emailSent : true})
            this.toggleEmailModal()

            if (this.props.onEmailChanged)
            {
                this.props.onEmailChanged(email);
            }
        })
        
        return false;
    }
  }


  toggleEmailModal()
  {
      // Récupération de la visibilité actuelle
      var currentValue = this.props.manager.state[this.props.stateKey];
    
      // Modification de la valeur
      var newVisibleState = {
      }
      newVisibleState[this.props.stateKey] = !currentValue;

      // Si la popup se ferme, on repasse en vue formulaire
      
      if ( newVisibleState[this.props.stateKey] == false)
      {
        newVisibleState.emailSent = false;
      }

      // Affectation du container parent
      this.props.manager.setState(newVisibleState)
  }


  render() {
    return  (
        <>
        {this.renderParent()}
        <FormLoadingIndicator loading={this.state.loading} />

        <Modal key={++this.index} className="modal-dialog-centered" size="md" isOpen={this.props.manager.state[this.props.stateKey]}>
            {
                (this.state.emailSent == false) ?
                (
                    <Form key={++this.index} id="form_change_email" className="needs-validation" noValidate onSubmit={e => this.onSubmitEmailModalForm(e)} >
                        <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">
                            <FormattedMessage id="ChangeEmail.title" key={++this.index} />
                            </h6>
                            <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleEmailModal()}
                            >
                            <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <FormattedMessage id="ChangeEmail.message">
                                {this.decodeEntities}
                            </FormattedMessage>

                            <Row>
                            <Col xs="12">
                                <FormGroup>
                                    <FormInput placeholder={this.translateMessage("ChangeEmail.email")} name='new_email' manager={this} />
                                </FormGroup>
                            </Col>
                        </Row>
                        </div>
                        

                        <div className="modal-footer">
                            <DefaultLink onClick={() => this.toggleEmailModal()} small={true}>
                                <FormattedMessage id="Default.close" key={++this.index} />
                            </DefaultLink>

                            <Button className="t2y-secondary-button" color="primary" type="submit">
                                <FormattedMessage id="ChangeEmail.change_email" key={++this.index} />
                            </Button>
                        </div>
                    </Form>
                )
                :
                (
                    <div>
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">
                            <FormattedMessage id="ChangeEmail.confirmation_title" key={++this.index} />
                        </h6>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleEmailModal()}
                        >
                        <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <FormattedMessage id={this.props.userId ? "ChangeCollaboratorEmail.confirmation_message" : "ChangeEmail.confirmation_message"}>
                            {this.decodeEntities}
                        </FormattedMessage>
                    </div>

                    <div className="modal-footer">
                        <DefaultLink small={true}
                            className="ml-auto"
                            color="link"
                            type="button"
                            data-dismiss="modal"
                            onClick={() => this.toggleEmailModal()}
                        >
                            <FormattedMessage id="Default.close" key={++this.index} />
                        </DefaultLink>
                    </div>
                    </div>
                )
            }
        </Modal>
      </>
    );
  }
}

export default injectIntl(ChangeEmailModal);
