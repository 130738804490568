import React from "react";
import ReactDOM from "react-dom";
import Config from "../../../../config"

import PropTypes from 'prop-types'

import {
    Card,
    CardBody,
    CardImg,
    Button,
    UncontrolledTooltip,
    CardTitle,
    Row,
    Col
} from "reactstrap";

class MapPointComponent extends React.Component {

  constructor(props)
  {
      super(props)
      this.state = {
      }
  }

  componentDidMount() {
    if (this.props.childRef)
    {
        this.props.childRef(this)
    }

    this.showPointOnMap(this.props.point, this.props.point)
  }

  showPointOnMap(center, point) 
  {
    var mapContainer = document.getElementById('map');
    var routeInstructionsContainer = document.getElementById('panel');
    
    // Step 1: initialize communication with the platform
    // In your own code, replace variable window.apikey with your own apikey
    var platform = new window.H.service.Platform({
        apikey: Config.hereApiKey
    });

    var defaultLayers = platform.createDefaultLayers();

    // Step 2: initialize a map - this map is centered over Berlin
    var map = new window.H.Map(mapContainer,
      // Set truck restriction layer as a base map
      defaultLayers.vector.normal.map,{
      center: {lat: center.latitude, lng: center.longitude},
      zoom: 12
    });
    // add a resize listener to make sure that the map occupies the whole container
    window.addEventListener('resize', () => map.getViewPort().resize());

    // Step 3: make the map interactive
    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    var behavior = new window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(map));

    var ui = window.H.ui.UI.createDefault(map, defaultLayers);

    //var group = new window.H.map.Group();
    //var markers = [];
    var marker = new window.H.map.Marker({lat:point.latitude, lng:point.longitude});
    //markers.push(marker);
    //group.addObjects(markers);
    map.addObject(marker);

      
    // get geo bounding box for the group and set it to the map
    /*map.getViewModel().setLookAtData({
        bounds: group.getBoundingBox()
    });*/
  }

  render() {
    var style = {
      width: this.props.width,
      height: this.props.height
    }

    
    return <>
      <div id="map" className="map-point" style={{width:this.props.width, height:this.props.height}} ></div>
      <div id="panel"></div>
    </>
  }
}





MapPointComponent.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  point: PropTypes.any
}
 
export default MapPointComponent;