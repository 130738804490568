

import React from "react";
import {
   Row,
   Col,
   Input
 } from "reactstrap";
import FormComponent from "./FormComponent";
import { injectIntl , FormattedMessage } from 'react-intl';
import FormInput from "../FormInput";

class FormCivilityInput extends FormComponent
{

    constructor(props)
    {
        super(props)
        this.state = {        
            value : null
        }
    }


    render() {
      // On détermine le nom du champs à utiliser
      var formDataKey = this.props.name ? this.props.name : "civility_code";

      // Récupération de la valeur à afficher
      var value = this.state.value == null ? 
        (this.props.manager.state.formDatas[formDataKey] ? this.props.manager.state.formDatas[formDataKey].value : this.state.value) : 
        this.state.value
      ;
      
      
      return (
        <>
          <br/>
          <Input type="hidden" name={this.props.name} id={this.props.name} value={value} />
            {
              /*
            
            <FormInput type="select" multiple={false} name={this.props.name} manager={this.props.manager}>
                <option value="CIVILITY_MR"></option>
                <option value="CIVILITY_MME"></option>
            </FormInput>
              */
            }
            <div className="custom-control custom-radio custom-control-inline">
              <input
                className="custom-control-input"
                id="customRadioInline1"
                name="customRadioInline1"
                type="radio"
                checked={ value == "CIVILITY_MR" ? "checked" : ""}
                onChange={() => this.setState({value: "CIVILITY_MR"})}
              />
              <label
                className="custom-control-label"
                htmlFor="customRadioInline1"
              >
                {this.props.intl.formatMessage({id: "Default.civility_mr"})}&nbsp;<i className="fas fa-male" />
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                className="custom-control-input"
                id="customRadioInline2"
                name="customRadioInline1"
                type="radio"
                checked={ value == "CIVILITY_MME" ? "checked" : ""}
                onChange={() => this.setState({value: "CIVILITY_MME"})}
              />
              <label
                className="custom-control-label"
                htmlFor="customRadioInline2"
              >
                {this.props.intl.formatMessage({id: "Default.civility_mme"})}&nbsp;<i className="fas fa-female" />
              </label>
            </div>


            
        </>
      );
    }
}
 
export default injectIntl(FormCivilityInput);