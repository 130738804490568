
import React from "react";
import PropTypes from 'prop-types';
import classnames from "classnames";

// reactstrap components
import {
    Button,
    Container,
    Form,
    FormGroup,
    UncontrolledPopover,
    UncontrolledTooltip,
    PopoverBody,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    ButtonGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText
  } from "reactstrap";
import SecurityService from "../../../proxies/SecurityService"
import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultButton from "../common/defaultButton";
import Resources from "../../../resources";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import StringHelper from "../../../helpers/StringHelper";
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import AddressComponent from "../workflow/address/addressComponent";
import Codes from "../../../codes"
import DefaultLink from "../common/defaultLink"
import Message from "../common/message";
import BusinessService from "../../../proxies/BusinessService";
import PersonCompanyInput from "../common/personCompanyInput";
import DefaultImage from "../common/defaultImage";
import LoadingIndicator from 'react-spinners/MoonLoader'
import ShipperService from "../../../proxies/ShipperService";
import DefaultInput from "../common/defaultInput";
import DefaultPopover from "../common/defaultPopover";
import GeocodingAndNavigationService from "../../../proxies/GeocodingAndNavigationService";
import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import CivilityInput from "../common/civilityInput";
import Title2 from "../common/title2";
import Title3 from "../common/title3";
import Title4 from "../common/title4";
import DefaultAutocompleteInput from "../common/defaultAutocompleteInput";

class MissionStepAddressSelection extends FormValidationComponent {

    static SELECTION_MODE_INTERNAL = "INTERNAL";
    static SELECTION_MODE_SUBCONTRACTOR = "SUBCONTRACTOR";
    static SELECTION_MODE_CUSTOMER = "CUSTOMER";

    static SUB_SELECTION_MODE_NEW_KNOWN_ADDRESS = "NEW_KNOWN_ADDRESS";
    static SUB_SELECTION_MODE_SELECT_KNOWN_ADDRESS = "SELECT_KNOWN_ADDRESS";
    
    static CUSTOMER_TYPE_COMPANY = "COMPANY";
    static CUSTOMER_TYPE_PERSON = "PERSON";
    
    constructor(props)
    {
        super(props)

        this.securityService = new SecurityService()
        this.businessService = new BusinessService()
        this.shipperService = new ShipperService();
        this.geocodingAndNavigationService = new GeocodingAndNavigationService();

        this.createValidationRules();

        this.state = {
            internalAddresses: null,
            address: this.props.address,
            subcontractorAddresses: null, 
            customerAddresses: null,
            mode: this.props.address ? Codes.CLASS_CONST_SUMMARY : Codes.CLASS_CONST_SELECTION,
            subMode: MissionStepAddressSelection.SUB_SELECTION_MODE_SELECT_KNOWN_ADDRESS,
            selectionMode: this.getSelectionMode(),
            customerType: (this.props.address && this.props.address.type.code == "ADDRESS_TYPE_CUSTOMER" && this.props.address.customer_company_address === false) ? MissionStepAddressSelection.CUSTOMER_TYPE_PERSON : MissionStepAddressSelection.CUSTOMER_TYPE_COMPANY,
            customer: this.props.address ? this.props.address.customer : null,
            customerId: this.props.address && this.props.address.customer ? this.props.address.customer.id : null,
            customerSearchInputValue: this.props.address && this.props.address.customer ? (this.props.address.customer_company_address ? this.props.address.customer.company_name : this.props.address.customer.last_name)  : null,
            options: []
        }
    }

    createValidationRules()
    {
        this.streetInputId = StringHelper.createDomElementId();
        this.street2InputId = StringHelper.createDomElementId();
        this.zipCodeInputId = StringHelper.createDomElementId();
        this.cityInputId = StringHelper.createDomElementId();
        this.addressNameInputId = StringHelper.createDomElementId();
        this.customerSearchInputId = "customer_search_input";

        this.validationRules = {
            // Gestion des champs du contact type particulier
            mobile_phone_number: {
                required : false, 
                rules : [{
                    rule : this.mobilePhoneNumber.bind(this),
                    ruleMessage: this.translateMessage("user.phone_number_invalid_message")
                }],
                linkTo: ["email"]
            },
            email : { 
                required : false,
                rules : [
                {
                    rule : this.email.bind(this),
                    ruleMessage: this.translateMessage("user.email_invalid_message")
                }, 
                {
                    id: "email_phone_number_required",
                    rule : (email) => {
                        return !(this.emptyValue(email) && this.emptyValue(this.getFormControlValue("mobile_phone_number")))
                    },
                    ruleMessage: "Merci de saisir au moins un email ou un numéro de téléphone mobile"
                }]
            }
        }

        this.validationRules[this.addressNameInputId] = {
            required : true,
            requiredMessage : "La désignation de l'adresse est obligatoire"
        }

        this.validationRules[this.streetInputId] = {
            required :true,
            requiredMessage : "L'adresse est obligatoire"
        }

        this.validationRules[this.zipCodeInputId] = {
            required: true,
            requiredMessage : "Le code postal est obligatoire"
        }

        this.validationRules[this.cityInputId] = {
            required: true,
            requiredMessage : "La ville est obligatoire"
        }
    }

    getAddressTypeFormSelectionMode()
    {
        var addressTypeCode = null;
        switch(this.state.selectionMode)
        {
            case MissionStepAddressSelection.SELECTION_MODE_CUSTOMER:
                addressTypeCode = "ADDRESS_TYPE_CUSTOMER"
                break;
            case MissionStepAddressSelection.SELECTION_MODE_INTERNAL:
                addressTypeCode = "ADDRESS_TYPE_INTERNAL"
                break;
            case MissionStepAddressSelection.SELECTION_MODE_SUBCONTRACTOR:
                addressTypeCode = "ADDRESS_TYPE_SUBCONTRACTOR"
                break;
        }

        return addressTypeCode;
    }
    getSelectionMode()
    {
        var selectionMode = null;
        if (this.props.address && this.props.address.type && this.props.address.type.code != null)
        {
            switch(this.props.address.type.code)
            {
                case "ADDRESS_TYPE_INTERNAL":
                case "ADDRESS_TYPE_BILLING":
                    selectionMode = MissionStepAddressSelection.SELECTION_MODE_INTERNAL
                    break;
                case "ADDRESS_TYPE_SUBCONTRACTOR":
                    selectionMode = MissionStepAddressSelection.SELECTION_MODE_SUBCONTRACTOR
                    break;
                case "ADDRESS_TYPE_CUSTOMER":
                    selectionMode = MissionStepAddressSelection.SELECTION_MODE_CUSTOMER
                    break;
            }
        }
        else
        {
            selectionMode = this.props.context == Codes.CLASS_CONST_LOADING_CONTEXT ? MissionStepAddressSelection.SELECTION_MODE_INTERNAL : MissionStepAddressSelection.SELECTION_MODE_CUSTOMER
        }

        return selectionMode;
    }

    addAddressToAddresses(address)
    {
        var state = this.state
        switch(address.type.code)
        {
            case "ADDRESS_TYPE_INTERNAL":
                state.internalAddresses.push(address)
                break;
            case "ADDRESS_TYPE_SUBCONTRACTOR":
                state.subcontractorAddresses.push(address)
                break;
            case "ADDRESS_TYPE_CUSTOMER":
                state.customerAddresses.push(address)
                break;
        }

        return state;
    }

    resetContactForm()
    {
        if (document.getElementById("customer_id"))
            document.getElementById("customer_id").value = "";

        if (document.getElementById("address_id"))
            document.getElementById("address_id").value = "";

        if (this.civilityInputRef)
            this.civilityInputRef.setValue("")

        if (this.fnameInputRef)
            this.fnameInputRef.setValue("")
        
        if (this.mobilePhoneNumberInputRef)
            this.mobilePhoneNumberInputRef.setValue("")
        
        if (this.emailInputRef)
            this.emailInputRef.setValue("")

        if (this.streetInputRef)
            this.streetInputRef.setValue("")
        
        if (this.street2InputRef)
            this.street2InputRef.setValue("")
        
        if (this.cityInputRef)
            this.cityInputRef.setValue("")
        
        if (this.zipCodeInputRef)
            this.zipCodeInputRef.setValue("")
    }

    _handleCustomerSearch(text, asPerson = false)
    {
        if (text != null && text.length >= 3)
        {
            this.setState({loading: true, text: text, onlyAutocomplete: true, customerAddresses: [], customerId: null, subMode: MissionStepAddressSelection.SUB_SELECTION_MODE_NEW_KNOWN_ADDRESS})
            this.shipperService.getCustomers(this, text, asPerson, (suggestions) => {
                
                this.setState({ options: suggestions.list, loading: false, value: null})
                document.getElementById("customer_id").value = "";
            })
        }
        else
        {
            this.setState({options: [], text: null, onlyAutocomplete: false, value: null, customerAddresses: [], customerId: null, subMode: MissionStepAddressSelection.SUB_SELECTION_MODE_NEW_KNOWN_ADDRESS})
            document.getElementById("customer_id").value = "";
        }

        this.setState({customerSearchInputValue: text})

        if (asPerson)
        {
            this.resetContactForm();
        }

        if (this.props.onSearch)
        {
            this.props.onSearch(text)
        }
    }

    _onCustomerChanged(customer, asPerson = false, refresh = true)
    {   
        if (!customer)
        {
            customer = {id: null, label: null}
        }

        if (document.getElementById("customer_search_input"))
        {
            document.getElementById("customer_search_input").value = asPerson ? customer.last_name : customer.label;
        }

        if (document.getElementById("customer_id"))
        {
            document.getElementById("customer_id").value = customer.id;
        }

        var newState = {
            onlyAutocomplete: false,
            customer: customer,
            customerId: customer.id,
            options: [], 
            text: null,
            customerSearchInputValue: asPerson ? customer.last_name : customer.label,
            subMode: MissionStepAddressSelection.SUB_SELECTION_MODE_SELECT_KNOWN_ADDRESS
        }

        if (asPerson)
        {
            newState.address = customer.address
            if (document.getElementById("address_id"))
            {
                document.getElementById("address_id").value = customer.address.id;
            }
            // Mise à jour des champs adresse
            this.civilityInputRef.setValue(customer.civility ? customer.civility.code : null)
            this.fnameInputRef.setValue(customer.first_name)
            this.mobilePhoneNumberInputRef.setValue(customer.mobile_phone_number)
            this.emailInputRef.setValue(customer.email)
            this.streetInputRef.setValue(customer.address.street)
            this.street2InputRef.setValue(customer.address.street2)
            this.zipCodeInputRef.setValue(customer.address.zip_code)
            this.cityInputRef.setValue(customer.address.city)
            this.addressNameInputRef.setValue(customer.address.name)
        }

        this.setState(newState)

        // Récupération de la liste des adresses du client
        if (refresh)
        {
            this.refreshCustomerAddresses(customer)
        }
    }

    componentWillMount()
    {
        // Récupération des adresses
        if (this.securityService.isConnected())
        {
            this.refreshAddresses()
        }
    }

    refreshAddresses()
    {
        if (this.state.customer)
        {
            this.refreshCustomerAddresses();
        }
        else{
            this.refreshIntenalAddresses()
        }
    }


    refreshCustomerAddresses(customer = null)
    {
        if (!customer)
        {
            customer = this.state.customer
        }

        if (customer && customer.id && this.props.readOnly == false)
        {
            this.shipperService.searchForCustomerAddress(this, customer.id, null, (response) => {
                var customerAddresses = response.list
                //var options = (customerAddresses ? customerAddresses : [])

                this.setState({ customerAddresses: customerAddresses})
            })
        }
    }

    refreshIntenalAddresses()
    {
        this.businessService.getAddresses(null, (response) => {
            var internalAddresses = []
            var subcontractorAddresses = []
            if (response.addresses != null)
            {
                response.addresses.forEach(address => {
                    // Addresse interne
                    if (["ADDRESS_TYPE_INTERNAL", "ADDRESS_TYPE_BILLING"].includes(address.type.code))
                    {
                        internalAddresses.push(address)
                    }
                    else // Sinon addresse d'un sous-traitant
                    {
                        subcontractorAddresses.push(address)
                    }
                })
            }

            this.setState({internalAddresses: internalAddresses, subcontractorAddresses: subcontractorAddresses})
        }, (httpErrorResponse) => {
            this.setState({internalAddresses: [], subcontractorAddresses: []})
        })
    }

    componentDidMount()
    {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }
    
    textFilter(event)
    {
        let filter = event.target.value.toLowerCase();
        let elements = document.getElementsByClassName("filterable")
        let nbMatchElements = 0;
        if (elements.length >= 0)
        {
            let element = null;
            for(let i = 0; i < elements.length; i++)
            {
                element = elements[i];
                if (element.innerText.toLowerCase().indexOf(filter) > -1)
                {
                    element.classList.remove( "filterable-hide");
                    nbMatchElements += 1;
                }
                else{
                    element.classList.add( "filterable-hide");
                }
            };
        }
        
        this.setState({nbOfFilteredItems : nbMatchElements, filter : filter})
    }

    switchSelectionMode(mode)
    {
        if ([MissionStepAddressSelection.SELECTION_MODE_INTERNAL, MissionStepAddressSelection.SELECTION_MODE_SUBCONTRACTOR].includes(mode))
        {
            if (!this.state.internalAddresses || !this.state.subcontractorAddresses)
            {
                this.refreshIntenalAddresses();
            }
        }
        else if (mode == MissionStepAddressSelection.SELECTION_MODE_CUSTOMER && !this.state.customerAddresses)
        {
            this.refreshCustomerAddresses()
        }

        this.setState({ selectionMode: mode, subMode: MissionStepAddressSelection.SUB_SELECTION_MODE_SELECT_KNOWN_ADDRESS })
    }

    addressFormEvent(e)
    {
        e.preventDefault();
        // Récupération des données de l'adresse
        const street = this.streetInputRef.getValue();
        const street2 = this.street2InputRef.getValue();
        const zipCode = this.zipCodeInputRef.getValue();
        const city = this.cityInputRef.getValue();

        const addressName = this.addressNameInputRef.getValue();

        var formIsValid = this.htmlFormIsValid();
        if (formIsValid == false)
        {
            return false;
        }

        // Récupération des coordonées
        this.geocodingAndNavigationService.getPointAndValidateLevel(this, street + " " + zipCode + " " + city, (geocodeInfos) => {
            // Création de la nouvelle adresse
            var addressData = {
                type_code: this.getAddressTypeFormSelectionMode(),
                customer_company_address: this.state.customerType == MissionStepAddressSelection.CUSTOMER_TYPE_COMPANY,
                customer_id: this.state.customerId,
                customer_company_name: this.state.customerSearchInputValue,
                gps_lat: geocodeInfos ? geocodeInfos.gps_lat : null,
                gps_long: geocodeInfos ? geocodeInfos.gps_long : null,
                gps_external_id: geocodeInfos ? geocodeInfos.gps_external_id : null,
                gps_level: geocodeInfos ? geocodeInfos.gps_level : null,
                street: street,
                street2: street2,
                zip_code: zipCode,
                city: city,
                name: addressName,
                country_code: "COUNTRY_FR"
            }

            if (this.state.customerType == MissionStepAddressSelection.CUSTOMER_TYPE_PERSON)
            {
                addressData.address_id = this.getHtmlFormControlValue("address_id");
                addressData.customer_civility_code = this.civilityInputRef.getValue();
                addressData.customer_first_name = this.fnameInputRef.getValue();
                addressData.customer_mobile_phone_number = this.mobilePhoneNumberInputRef.getValue();
                addressData.customer_email = this.emailInputRef .getValue();
            }

            
            this.shipperService.saveTransportOperationAddress(this, addressData, (response) => {
                var address = response.value
                // On ajoute l'adresse au listing des addresses déjà présentes en mode "plusieurs addresses"
                if (this.state.customerType != MissionStepAddressSelection.CUSTOMER_TYPE_PERSON)
                {
                    var state = this.addAddressToAddresses(address)
                    state.address = address
                    state.customer = address.customer
                    state.subMode = MissionStepAddressSelection.SUB_SELECTION_MODE_SELECT_KNOWN_ADDRESS

                    this.setState(state)
                }

                // On selectionne la nouvelle adresse
                this.addressSelectionEvent(address)

            }, (httpErrorResponse) => {
                httpErrorResponse.json().then((apiResponse) => {
                    this.showErrorFromResponse(apiResponse, null)
                })
                this.setState({ loading: false })
            });
        });
    }

    addressSelectionEvent(address)
    {
        var selectedAddress = null;
        if ([MissionStepAddressSelection.SELECTION_MODE_INTERNAL, MissionStepAddressSelection.SELECTION_MODE_SUBCONTRACTOR].includes(this.state.selectionMode))
        {
            selectedAddress = address
        }
        else if (this.state.selectionMode == MissionStepAddressSelection.SELECTION_MODE_CUSTOMER && this.state.customerType == MissionStepAddressSelection.CUSTOMER_TYPE_COMPANY)
        {
            if (!address.customer && this.state.customer)
            {
                address.customer = this.state.customer
            }

            selectedAddress = address
        }
        else if (this.state.selectionMode == MissionStepAddressSelection.SELECTION_MODE_CUSTOMER && this.state.customerType == MissionStepAddressSelection.CUSTOMER_TYPE_PERSON)
        {
            if (!address.customer && this.state.customer)
            {
                address.customer = this.state.customer
            }

            selectedAddress = address
        }

        if (this.props.onAddressSelection)
        {
            this.props.onAddressSelection(selectedAddress)
        }

        this.setState({address: selectedAddress, mode: Codes.CLASS_CONST_SUMMARY})
    }
    
    renderSelectionHeader()
    {
        return <Row className="justify-content-center">
            <Col xl="12">
                <Row className="justify-content-center">
                    <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                        <Button className={classnames({ active: this.state.selectionMode == MissionStepAddressSelection.SELECTION_MODE_INTERNAL }) + " multiChoiceBtn"} color="secondary"
                                onClick={() => this.switchSelectionMode(MissionStepAddressSelection.SELECTION_MODE_INTERNAL)}>
                            Entrepôts
                        </Button>
                        <Button className={classnames({ active: this.state.selectionMode == MissionStepAddressSelection.SELECTION_MODE_SUBCONTRACTOR }) + " multiChoiceBtn"} color="secondary"
                            onClick={() => this.switchSelectionMode(MissionStepAddressSelection.SELECTION_MODE_SUBCONTRACTOR)}>
                            Sous-traitants
                        </Button>
                        <Button className={classnames({ active: this.state.selectionMode == MissionStepAddressSelection.SELECTION_MODE_CUSTOMER }) + " multiChoiceBtn"} color="secondary"
                            onClick={() => this.switchSelectionMode(MissionStepAddressSelection.SELECTION_MODE_CUSTOMER)}>
                            Clients
                        </Button>
                    </ButtonGroup>
                </Row>
            </Col>
        </Row>
    }

    _handleAddressSearch(text)
    {
        if (text != null && text.length > 3)
        {
            this.setState({loading: true, text: text})
            this.geocodingAndNavigationService.autosuggest(this, text, (suggestions) => {
                this.setState({ options: suggestions, loading: false})
            })
        }
    }

    renderAddressForm(options = {})
    {
        return <>
        <Form onSubmit={e => this.addressFormEvent(e)} autoComplete="new-password">
            <Row className="mt-2 mb-2">
                <Col xl="12">
                <DefaultAutocompleteInput id={this.streetInputId} autoComplete='new-password'
                    manager={this}
                    childRef={(elt) => this.streetInputRef = elt} 
                    required={true}
                    value={options.street ? options.street: null}
                    placeholder={"Adresse"}
                    onSearch={ (text, suggestionsCallback) => {
                        this.geocodingAndNavigationService.autosuggest(null, text, (response) => {
                            suggestionsCallback(response)
                        })
                    }}
                    onSuggestionSelected={ (suggestion, index) => {
                        // Lors de la selection:
                        if (suggestion != null)
                        {
                            this.geocodingAndNavigationService.getAutoSuggestDetail(suggestion.gps_external_id, (response) => {
                                this.streetInputRef.setValue(response.street)
                                this.street2InputRef.onChange("")
                                this.zipCodeInputRef.onChange(response.zipCode)
                                this.cityInputRef.onChange(response.city);
                                this.addressNameInputRef.focus()
                            })
                        }
                        else
                        {
                            this.streetInputRef.clear()
                        }
                    }}
                >
                </DefaultAutocompleteInput>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xl="12">
                <DefaultInput id={this.street2InputId} autoComplete="new-password"
                    value={options.street2 ? options.street2: null}
                    childRef={(elt) => this.street2InputRef = elt} 
                    onFocus={(e) => this.streetInputRef ? this.streetInputRef.clear() : {}}
                    manager={this}
                    placeholder={"Complément"}>
                </DefaultInput>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xl="4">
                <DefaultInput id={this.zipCodeInputId} autoComplete="new-password"
                    value={options.zip_code ? options.zip_code: null}
                    childRef={(elt) => this.zipCodeInputRef = elt} 
                    onFocus={(e) => this.streetInputRef ? this.streetInputRef.clear() : {}}
                    manager={this}
                    required={true}
                    placeholder={"Code postal"}>
                </DefaultInput>
                </Col>
                <Col xl="8">
                <DefaultInput id={this.cityInputId} autoComplete="new-password"
                    manager={this}
                    required={true}
                    value={options.city ? options.city: null}
                    childRef={(elt) => this.cityInputRef = elt} 
                    onFocus={(e) => this.streetInputRef ? this.streetInputRef.clear() : {}}
                    placeholder={"Ville"}>
                </DefaultInput>
                </Col>
            </Row>
            <Row>
                <Col xl="12">
                <DefaultInput id={this.addressNameInputId} autoComplete="new-password"
                    required={true}
                    manager={this}
                    childRef={(elt) => this.addressNameInputRef = elt} 
                    placeholder={this.translateMessage("address.confirmation_form_address_name")}
                    value={options.address_name ? options.address_name: null}
                    popover={
                            <DefaultPopover embeded={true} 
                                content={StringHelper.translate(this, "address.confirmation_form_address_name_infos")} />
                        } 
                >
                </DefaultInput>
                </Col>
            </Row>
            <Row className="justify-content-center mt-2">
                <DefaultButton type="submit" className="t2y-secondary-button small" onClick={(e) => {}}>
                    {StringHelper.translate(this, "Default.workflow_next_button_label")}
                </DefaultButton>
            </Row>               
        </Form> 
        </>
    }

    renderKnownAddress(type)
    {
        // Récupération des adresses
        var addresses = this.state[type];
        
        if (addresses == null)
        {
            return <>Chargement en cours ...</>
        }
        else
        {    
            if (addresses.length == 0 || this.state.subMode == MissionStepAddressSelection.SUB_SELECTION_MODE_NEW_KNOWN_ADDRESS)
            {
                return <>
                    {this.renderAddressForm()}
                    {
                        // Gestion du bouton permettant de revenir à la liste
                        (addresses.length > 0) ?
                        (
                            <Row className="justify-content-center mt-2">
                                <DefaultLink small={true} onClick={e =>this.setState({subMode: MissionStepAddressSelection.SUB_SELECTION_MODE_SELECT_KNOWN_ADDRESS})}>
                                &lt;&lt; Revenir à la liste
                                </DefaultLink>
                            </Row>
                        ) : (<></>)
                    }
                    {/*this.renderAddressWorkflow(type)*/}
                    {/*
                    <Message type="infos" style={{marginTop: "10px"}}>
                        {StringHelper.translate(this, "address." + (this.securityService.isShipper() ? "shipper" : "transporter") + "_no_results_" + type)}
                        {
                            (this.securityService.isShipper())?
                            (
                                <DefaultButton className="t2y-thirdparty-button small"
                                    onClick={(e) => this.setState({addressPanelIsVisible: true})} 
                                    style={{paddingTop:"5px", float:"right"}}>
                                        {StringHelper.translate(this, "address.new_" + type)}
                                </DefaultButton>
                            ) : (<></>)
                        }
                    </Message>*/}
                </>
            }
            else
            {   
                const addressBlockSize = this.state.mode == Codes.CLASS_CONST_SUMMARY || addresses.length == 1 ? 
                    "12" : (addresses.length == 2 ? "6" : "4");
                const addButtonTitle = type == "subcontractorAddresses" ? "Créer un nouveau sous-traitant" : (type == "customerAddresses" ? "Créer une nouvelle adresse" : "Créer un nouvel entrepôt");
                const filterInputPlaceholder = type == "subcontractorAddresses" ? "Filter les sous-traitants" : (type == "customerAddresses" ? "Filtrer les adresses du client" : "Filter les entrepôts");

                return <>
                    <Row className="mt-3">
                        <Col xs="7">
                            <FormGroup className="mb-0">
                                <InputGroup className="input-group-alternative input-group-merge">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><i className="fas fa-search" /></InputGroupText>
                                    </InputGroupAddon>
                                    <Input key={type} placeholder={filterInputPlaceholder} type="text" onChange={this.textFilter.bind(this)} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs="5">
                            {
                                (this.securityService.isShipper()) ?
                                (
                                    <DefaultButton 
                                        className="t2y-secondary-button small" 
                                        icon={Resources.button_add_icon}
                                        onClick={e => this.setState({subMode: MissionStepAddressSelection.SUB_SELECTION_MODE_NEW_KNOWN_ADDRESS})}
                                    >
                                    {addButtonTitle}
                                    </DefaultButton>
                                ) : (<></>)
                            }
                        </Col>
                    </Row>

                    <Row className="mt-3" key={type + "_address"}>
                    {
                        addresses.map((address, index) => {
                            var isSelected =  this.state.address && this.state.address.id == address.id//(this.state.value != null && this.state.value.address_id == address.id) || this.current_address_id == address.id;
                            
                            return this.renderAddress(address, isSelected, addressBlockSize, index, type)
                        })
                    }
                    </Row>
                </>
            }
        }
    }

    renderShipperCustomersList(asPerson = false, placeHolder, icon = "far fa-address-card" )
    {
        var backgroundColor = "white"
        var textColor = "#1e2635" 
        var iconColor = "#FFC42B"
        var customerId = this.state.customer ? this.state.customer.id : null;
        var customerLabel = this.state.customer ? this.state.customer.label : null;

        return <>
            <input type="hidden" id="customer_id" value={customerId}></input>
            <div className={"btn btn-default "} style={{zIndex:"600", width: "100%", backgroundColor: backgroundColor, borderColor: textColor, color:textColor, padding:0}}>
                <span className="btn-inner--icon" style={{ color:this.props.iconColor, fontSize: "20px", float: "left", margin: "0.625rem 1.25rem" }}>
                    <DefaultImage style={{ color: iconColor}} src={icon}></DefaultImage>
                </span>
                <input
                    
                    id="customer_search_input"
                    value={this.state.customerSearchInputValue}
                    focus={this.state.focus}
                    onChange={(e)=> this._handleCustomerSearch(e.currentTarget.value, asPerson)}
                    autoComplete="new-password" 
                    style={{ border:"none", height:"50px", marginTop:"0px", float:"left", width:"80%", color:this.props.textColor}}
                    placeholder={placeHolder}
                />
                {
                    (this.state.loading) ?
                    (
                        <span class="btn-inner--icon t2y-default-input-icon-container">
                            <LoadingIndicator size={15} />
                        </span>
                    ) : (<></>)
                }
                <div style={{width:"100%", overflow:"scroll"}}  className={"dropdown-menu " + ((this.state.options.length > 0) ? "show": "")}>
                    { this.state.options.map((option, index) => 
                            <div 
                                key={"selectItem" + index} 
                                className="dropdown-item" onClick={ ()=> {this._onCustomerChanged(option, asPerson, !asPerson)}}
                                dangerouslySetInnerHTML={{__html: option.label}}
                            > 
                            </div> 
                        )
                    }
                </div>
            </div>
        </>
    }

    renderShipperCustomerAsCompany()
    {
        return <>
            {this.renderShipperCustomersList(false, StringHelper.translate(this, "address.autocomplete_shipper_customer_placeholder"))}
            {
                (this.state.customer) ?
                (
                    this.renderKnownAddress("customerAddresses")
                ) : (
                    this.renderAddressForm()
                )
            }
        </>
    }

    renderShipperCustomerAsPerson()
    {
        var address = this.props.address ? this.props.address : {};
        var customer = address && address.customer ? address.customer : {}

        return <>
            <Row>
                <Col lg="12">
                    {this.renderShipperCustomersList(true, "Nom de famille", Resources.form_lname_icon)}
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <FormGroupInput  htmlFor={"civility_code"} type="multiline">
                        <CivilityInput childRef={elt => this.civilityInputRef = elt} manager={this} required={false} id="civility_code"
                        value={customer.civility ? customer.civility.code : null}
                        >
                        </CivilityInput>
                    </FormGroupInput>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <DefaultInput id="first_name"
                        manager={this}
                        required={false}
                        value={customer.first_name}
                        childRef={(elt) => this.fnameInputRef = elt}
                        icon={Resources.form_fname_icon} placeholder={"Prénom"}>
                    </DefaultInput>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <DefaultInput id="mobile_phone_number"
                        manager={this}
                        value={customer.mobile_phone_number}
                        childRef={(elt) => this.mobilePhoneNumberInputRef = elt}
                        icon={Resources.form_mobile_phone_number_icon} placeholder={"N° de mobile"}>
                    </DefaultInput>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <DefaultInput id="email"
                        manager={this}
                        type="email"
                        value={customer.email}
                        childRef={(elt) => this.emailInputRef = elt}
                        icon={Resources.form_email_icon} placeholder={"E-mail"}>
                    </DefaultInput>
                </Col>
            </Row> 
            <input type="hidden" id="address_id"></input>

            {this.renderAddressForm({
                address_name: this.state.address ? this.state.address.name : "Domicile",
                ...address
            })}
        </>
    }

    renderSelectionComponent()
    {
        switch(this.state.selectionMode)
        {
            case MissionStepAddressSelection.SELECTION_MODE_CUSTOMER:
                return <>
                <PersonCompanyInput 
                    name="personCompany" 
                    className="mb-2 mt-2"
                    value={this.state.customerType} childRef={elt => this.personCompanyInputRef = elt}
                    onChange={(value) => {
                        const company =  (value == MissionStepAddressSelection.CUSTOMER_TYPE_COMPANY)
                        this.setState({customerType:value})
                    }}
                ></PersonCompanyInput>
                {
                    (this.state.customerType == MissionStepAddressSelection.CUSTOMER_TYPE_COMPANY) ?
                    (
                        this.renderShipperCustomerAsCompany()
                    ) : 
                    (
                        this.renderShipperCustomerAsPerson()
                    )
                }
                </>
            break;
            case "point":
                return <></>
            break;
            case MissionStepAddressSelection.SELECTION_MODE_INTERNAL:
                return this.renderKnownAddress("internalAddresses");
            break;
            case MissionStepAddressSelection.SELECTION_MODE_SUBCONTRACTOR:
                return this.renderKnownAddress("subcontractorAddresses");
            break;
        }
    }

    renderSelectionMode()
    {
        return <>
            {this.props.addressSelectionHeaderComponent}
            {this.renderSelectionHeader()}
            {this.renderSelectionComponent()}
        </>
    }

    renderAddress(address, isSelected, addressBlockSize, index, type="selected", title = null, filterable = true)
    {
        if (address == null)
            return <></>

        var textColor = "#1e2635";
        var iconColor = "#FFC42B";
        var backgroundColor = "white";

        return <Col xs={addressBlockSize} key={type + "_address_" + index} className={(filterable ? "filterable" : "") +  " mt-2"} >
            <Button onClick={() => this.state.mode != Codes.CLASS_CONST_SUMMARY  ? this.addressSelectionEvent(address) : {}} color="default" type="button" 
                style={{ height: "120px", width: "100%", color:"red", 
                    backgroundColor: !isSelected ? backgroundColor : Resources.color_selected_date, 
                    borderColor:textColor, 
                    color:textColor}}
            >
                {
                    (address.type.code == "ADDRESS_TYPE_CUSTOMER" && address.customer_company_address === false) ? 
                    (
                        <>
                        <span className="btn-inner--icon" style={{ 
                            color: !isSelected ? iconColor : "black", fontSize: "20px", float: "left", marginRight: "20px" }}>
                            <i className="fas fa-map-marker-alt"></i>
                            <span>{address.customer.summary}</span>
                        </span>
                        <span className="btn-inner--text" style={{ textAlign:"left", float: "left", color:textColor, paddingTop:"5px", width:"100%", display: "inline-block", overflow:"hidden" }}>
                            <Row>
                                <Col xs="6">
                                {address.name}
                                <br/>
                                <div>{address.street}</div>
                                <div>{address.zip_code + " " + address.city}</div>
                                </Col>
                                <Col xs="6">
                                    {
                                        (address.customer.mobile_phone_number) ?
                                        (
                                            <>  
                                                <br/>
                                                <DefaultImage src={Resources.mobile_phone_logo} className="mr-2"></DefaultImage>
                                                {address.customer.mobile_phone_number} 
                                            </>

                                        ) : (<></>)
                                    }
                                    {
                                        (address.customer.email) ?
                                        (
                                            <>  
                                                <br/>
                                                <DefaultImage src={Resources.form_email_icon} className="mr-2"></DefaultImage>{address.customer.email} 
                                            </>

                                        ) : (<></>)
                                    }
                                </Col>
                            </Row>
                        </span>
                        </>

                    ) : (
                        <>
                        <span className="btn-inner--icon" style={{ 
                            color: !isSelected ? iconColor : "black", fontSize: "20px", float: "left", marginRight: "20px" }}>
                            <i className="fas fa-map-marker-alt"></i>
                            { (title) ? (<span>{address.name}</span>) : (<></>) }
                        </span>
                        <span className="btn-inner--text" style={{ textAlign:"left", float: "left", color:textColor, paddingTop:"5px", width:"100%", display: "inline-block", overflow:"hidden" }}>
                            { (!title) ? (address.name) : (<></>) }
                            {
                                (address.customer && address.customer.company_name) ?
                                (
                                    <>
                                        &nbsp;({address.customer.company_name})
                                    </>
                                ) : (<></>)
                            }
                            <br/>
                            <div>{address.street}</div>
                            <div>{address.zip_code + " " + address.city}</div>
                        </span>
                        </>
                    )
                }

                
            </Button>
        </Col>
    }

    renderSummaryMode()
    {
        if (!this.state.address)
        {
            return <></>
        }


        return <>
            <Row>
                <Col xs="6">
                <Title4>Adresse sélectionnée</Title4>
                </Col>
                <Col xs="6" className="text-right">
                    {
                        (!this.props.readOnly) ?
                        (
                            <Row >
                                <DefaultLink small={true} 
                                    onClick={(e) => {
                                        this.setState({mode: Codes.CLASS_CONST_SELECTION, address:null})
                                        if (this.props.onAddressChangeRequest)
                                        {
                                            this.props.onAddressChangeRequest()
                                        }

                                        this.refreshAddresses()
                                    }}
                                    style={{paddingTop:"5px", width:"100%", float:"right", textAlign:"right"}}>
                                        {"selectionner une autre destination"}
                                </DefaultLink>
                            </Row>
                        ) : (
                            <></>
                        )
                    }
                </Col>
            </Row>
            <Row>
                {this.renderAddress(this.state.address, false, 12, 1, "selected", "Adresse selectionnée", false)}
            </Row>
        </>
    }

    render() {
        return  <>
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
            {this.renderParent()}
            {
                (this.props.readOnly || this.state.mode == Codes.CLASS_CONST_SUMMARY) ?
                (
                    this.renderSummaryMode()
                ) :
                (
                    this.renderSelectionMode()
                )
            }
        </>
    }
}

MissionStepAddressSelection.propTypes = {
    addressSelectionHeaderComponent: PropTypes.any,
    readOnly: PropTypes.bool,
    context: PropTypes.oneOf([Codes.CLASS_CONST_LOADING_CONTEXT, Codes.CLASS_CONST_UNLOADING_CONTEXT]),
    onAddressSelection: PropTypes.func.isRequired,
    addressSummaryAddressFooterComponent: PropTypes.any
};


export default injectIntl(MissionStepAddressSelection)