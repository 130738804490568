
import React from "react";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
import classnames from "classnames"
import queryString from 'query-string';
import $ from "jquery";
// reactstrap components
import {
    Modal,
    Button,
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse,

    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Badge,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu, 
    Media,
    DropdownItem
  } from "reactstrap";
  
import BusinessService from '../../../proxies/BusinessService'
import ShipperService from "../../../proxies/ShipperService";
import SecurityService from "../../../proxies/SecurityService";
import FormLoadingIndicator from "../components/FormLoadingIndicator";
import Resources from "../../../resources";
import ReactDatetime from "react-datetime";
import { injectIntl , FormattedMessage } from 'react-intl';
import TruckImage from "../components/Image/TruckImage";
import TruckSummaryCard from "../components/card/TruckSummaryCard";
import TruckSummary from "../../components/business/truckSummary";
import RepositoryDataComponent from "../components/RepositoryDataComponent";
import Config from '../../../config.js'
import FormValidationComponent from "../components/FormValidationComponent";
import NotificationComponent from "../components/NotificationComponent";
import Title2 from '../../components/common/title2'
import Title3 from '../../components/common/title3'
import Title4 from '../../components/common/title4'
import DefaultButton from '../../components/common/defaultButton'
import DefaultImage from '../../components/common/defaultImage'
import NoTruckDriverAlert from "../../components/business/noTruckDriverAlert";
import StringHelper from "../../../helpers/StringHelper.js";
import EmptyResult from "../../components/common/emptyResult";
import NotFoundScene from "../../components/common/notFoundScene.jsx";
import SlidingPane from "react-sliding-pane";
import PortalSearchForm from '../../components/search/portalSearchForm'
import DateHelper from "../../../helpers/DateHelper.js";
import WebsiteHelper from "../../../helpers/WebsiteHelper.js";
import Orders from "../common/Orders.jsx";
import Separator from "../../components/common/separator.jsx";
import DateLabel from "../../components/common/dateLabel.jsx";
import ArrayHelper from "../../../helpers/ArrayHelper.js";
import Rating from "../../components/rating/rating.jsx";
import DefaultPopover from "../../components/common/defaultPopover.jsx";

var moment = require('moment');
require('moment/locale/fr');

class ShipperOrders extends Orders {

    constructor(props)
    {
        super(props)
    
        this.state = {
            ...(this.getParentState())
        };
    }

    componentWillMount()
    {   
        super.componentWillMount()
    }

    componentDidMount()
    {
    }
    
    renderEmptyResult(component)
    {
        return <>
            <br/>
            {component}
        </>
    }

    renderTabContentHeader()
    {
        const headerStyle = {textAlign:"center", 
         paddingTop:"10px"}
        return <>
            <Card key={"collapse-orders"} className={"card-plain " } style={{marginBottom:0,paddingBottom:0, border:"solid 1px black"}}>
            <CardHeader role="tab" style={{marginBottom:0,paddingBottom:0}}>
            <Row>
                <Col xs="2" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_number_label")}
                </Col>
                <Col xs="2" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_date_label")}
                </Col>
                <Col xs="2" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_truck_label")}
                </Col>
                <Col xs="2" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_state_label")}
                </Col>
                <Col xs="1" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_rating_label")}
                </Col>
                <Col xs="1" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_total_ht_label")}
                </Col>
                <Col xs="2" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_payment_state_label")}
                </Col>
            </Row>
            </CardHeader>
            </Card>
        </>
    }

    renderPaymentState(order)
    {
        var paymentStateLabel = "/";
        // Uniquement la facture d'acompte
        if (order.invoices.length == 1)
        {
            const invoice = order.invoices[0];
            if (invoice.payment_state != null)
            {
                paymentStateLabel = StringHelper.translate(this, "Orders." + invoice.type.code + "." + invoice.payment_state.code)
            }
        }
        else // Si plusieurs factures, on regarde l'état de la facture de solde
        {
            const invoice = ArrayHelper.findFirst(order.invoices, (item) => { return item.type.code == "INVOICE_TYPE_SHIPPER_INVOICE"})
            if (invoice.payment_state != null)
            {
                paymentStateLabel = StringHelper.translate(this, "Orders." + invoice.type.code + "." + invoice.payment_state.code)
            }
        }

        return <>
            <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center" style={{cursor:"pointer"}}>
                        <DefaultImage src={Resources.downloaded_file_logo}></DefaultImage>
                        <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                            {paymentStateLabel}
                        </span>    
                        </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0"><FormattedMessage id="Orders.line_invoices_popover_title" /> </h6>
                    </DropdownItem>
                    {
                        (order.invoices) ?
                        (
                            order.invoices.map((invoice, orderIndex) => {
                                return <DropdownItem onClick={() => this.downloadInvoice(invoice.id)}>
                                <i className={Resources.downloaded_file_logo} />
                                <span><FormattedMessage id={"Orders.line_invoices_popover_" + invoice.type.code}/></span>
                                </DropdownItem>
                            })
                        
                        ) : (<></>)
                    }
                    
                  </DropdownMenu>
                </UncontrolledDropdown>
        
        </>
    }

    renderTabContentOrder(order, orderIndex)
    {
        const headerStyle = {textAlign:"center", paddingTop:"10px", cursor:"pointer"}
        const collapseCode = "collapse" + order.id;
        const format = this.props.intl.formatMessage({id: "Default.day_month_year_format"})
        const dayMonthFormat = this.props.intl.formatMessage({id: "Default.day_month_format"})

        return <Card key={"collapse"+ orderIndex} className={"card-plain " + collapseCode } style={{marginBottom:0,paddingBottom:0, border:"solid 1px black"}}>
            <CardHeader role="tab" style={{marginTop:0,marginBottom:0, paddingTop:0, paddingBottom:0}}  aria-expanded={this.expandedIsOpen(collapseCode, order.id)}>
                <Row>
                    <Col xs="2" style={headerStyle}>
                        <h5 className="mb-0 t2y-clickable">
                        {this.renderOrderIncidentAlert(order)}
                        <span onClick={() => this.collapsesToggle(collapseCode)}>#{order.number}</span>
                        </h5>
                    </Col>
                    <Col xs="2" style={headerStyle} onClick={() => this.collapsesToggle(collapseCode)}>
                        {this.renderOrderDates(moment(order.start_date.date).format(format), moment(order.end_date.date).format(format), order.nb_days_used)}
                    </Col>
                    <Col xs="2" style={headerStyle} >
                        <TruckSummary completeSummaryOnClick={true} properties={false} label={true} image={true} truck={order.truck}></TruckSummary>
                    </Col>
                    <Col xs="2" style={headerStyle} onClick={() => this.collapsesToggle(collapseCode)}>
                        {this.renderOrderState(order)}
                    </Col>
                    <Col xs="1" style={headerStyle} onClick={() => this.collapsesToggle(collapseCode)}>
                        {this.renderOrderRating(order)}
                    </Col>
                    <Col xs="1" style={headerStyle} onClick={() => this.collapsesToggle(collapseCode)}>
                        {this.renderTotalAmount(order)}
                        {
                            (StringHelper.notEmptyValue(order.shipper_invoice_comment)) ?
                            (
                                <DefaultPopover 
                                    placement="right"
                                    component={<DefaultImage id={"order_amount"+ orderIndex} src={Resources.comment_logo} style={{color:"red"}}></DefaultImage>}
                                    targetId={"order_amount"+ orderIndex} content={order.shipper_invoice_comment} >
                                </DefaultPopover>
                            ) : (null)
                        }
                    </Col>
                    <Col xs="2" style={headerStyle}>
                        {
                            (order.invoices.length == 0) ?
                            (
                                "/"
                            ) : (
                                this.renderPaymentState(order)
                            )
                        }
                        
                    </Col>
                </Row>
                
            </CardHeader>
            <Collapse role="tabpanel" isOpen={this.expandedIsOpen(collapseCode, order.id)}>
            <CardBody>
                {this.renderOrderArrivalTime(order)}
                {this.renderMissions(order)}


            </CardBody>
            </Collapse>
        </Card>
    }
    
    render()
    {
        return <>
            {this.renderParent()}
            {this.renderOrders()}
        </>
    }
}


export default injectIntl(ShipperOrders)