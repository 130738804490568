import React from "react"

import { injectIntl } from 'react-intl'
import SecurityService from "../../../../services/SecurityService"
import Step from "../step"
import TransporterService from "../../../../services/TransporterService"
import TruckProperties from "../../../pages/transporter/TruckProperties"

import MyLoader from "../sidePanelWorkFlowLoader"

require('moment/locale/fr')

class TruckPropertiesStep extends Step {

	constructor(props) {
		super(props)

		this.securityService = new SecurityService()
		this.transporterService = new TransporterService()
		this.validationRules = {}

		this.state = {
			property_families: [],
			property_rules: [],
			filtered_families: [],
		}
	}

	componentWillMount() {
		super.componentWillMount()

		this.setState({loading: true})

		var propertyFamilies = this.props.workflow.getWorkingValue("property_families")
		if(!propertyFamilies) {
			this.transporterService.getAllTruckPropertiesAndFamilies(this, (response) => {
				this.props.workflow.setWorkingValue("property_families", response.property_families)
				this.props.workflow.setWorkingValue("property_rules", response.property_rules)
				this.setFamiliesAndRules(response.property_families, response.property_rules)

			})
		} else {
			this.setFamiliesAndRules(propertyFamilies, this.props.workflow.getWorkingValue("property_rules"))
		}
	}

	setFamiliesAndRules(families, rules) {
		var newState = {}
		newState.property_families = families
		newState.property_rules = rules
		newState.loading = false
		var filteredFamilies = []
		families.forEach(f => {
			if(this.props.families.includes(f.code)) {
				filteredFamilies.push(f)
			}
		})
		newState.filtered_families = filteredFamilies
		this.setState(newState)
	}

	componentDidMount() {
		super.componentDidMount()
	}

	componentWillUnmount() {
		super.componentWillUnmount()
	}

	getTrukForProperties() {
		var truckInfos = this.props.workflow.getTruck()
		var propertyValues = truckInfos[this.props.families.join("_")]
		return !propertyValues ? {id: truckInfos.id, property_values: []} : {id: truckInfos.id, property_values: propertyValues}
	}

	buildTruckInfos() {
		var truckInfos = this.props.workflow.getTruck()
		// Récupération des données à enregistrer
		var propertyValues = this.truckPropertiesRef.getDataToPost().property_values

		truckInfos[this.props.families.join("_")] = propertyValues
		return truckInfos
	}

	onLeave() {
		this.props.workflow.setInput(this.buildTruckInfos())
	}

	submit(pid) {
		var formIsValid = this.htmlFormIsValid(null)

		if(formIsValid) {

			// Récupération des données du workflow et celles à enregistrer
			var truckInfos = this.buildTruckInfos()
			var propertyValues = truckInfos[this.props.families.join("_")]

			// Enregistrement des données des montants
			this.transporterService.saveTruckProperties(this, {id: truckInfos.id, property_values: propertyValues, families: this.props.families}, (response) => {
				// On remonte l'information au workflow
				super.submit(pid)

				// On passe à l'étape suivante et on met à jour la données du workflow pour les écrans suivants
				truckInfos[this.props.families.join("_")] = propertyValues
				truckInfos.rules_results = response.rules_results

				// Mise à jour des données de l'état du camion
				truckInfos.complete = response.complete
				truckInfos.complete_response = response.complete_response

				this.props.workflow.goToNextStep(pid, truckInfos)
			},
			(httpErrorResponse) => {
				// Gestion de l'erreur générique
				this.setState({loading: false})
				httpErrorResponse.json().then((apiResponse) => {
					this.showErrorFromResponse(apiResponse)
				})
			})
		}
	}

	render() {
		return (
			<>
				{
					(this.state.loading) ?
					(
						<div style={{display:"flex", justifyContent:"center", marginBottom:50}}><MyLoader/></div>
					)
					:
					(
						(this.state.filtered_families.length > 0) ?
						(
							<>
							<TruckProperties truck={this.getTrukForProperties()}
								parent={this}
								itemsPerLines={1}
								groupInputType={"multiline"}
								getEmptyValues={true}
								childRef={ref => this.truckPropertiesRef = ref}
								id="truck_properties"
								families={this.state.filtered_families}
								rules={this.state.property_rules}
								rulesResults={this.props.workflow.getTruck().rules_results}
							/>
							</>
						) : (
							<div style={{display:"flex", justifyContent:"center", marginBottom:50}}><MyLoader/></div>
						)
					)
				}
			</>
		)
	}
}


export default injectIntl(TruckPropertiesStep)

