const CollaboratorMessages = {
    "collaborator.multiselect_placeholder": "...",
    "collaborator.multiselect_empty_result": "Aucun résultat disponible",
    "collaborator.email_infos": "L'adresse email est l'identifiant du contact dans l'application",
    "collaborator.company_account_management": "Gestion du compte société",
    "collaborator.company_account_management_infos": "Permet de modifier les informations de votre société, y compris les informations bancaires.",
    "addresses.multiselect_placeholder": "...",
    "addresses.multiselect_empty_result": "Aucun résultat disponible",
    "collaborator.delete_confirmation_title" : "Confirmation de suppression",
    "collaborator.delete_confirmation_message" : "Confirmez-vous la suppression de ce collaborateur?",
    "collaborator.save_confirmation_title": "Confirmation",    
    "collaborator.save_confirmation_message": "Les données du collaborateur ont été enregistrées avec succès"
}

export default CollaboratorMessages