import React from "react"
import { injectIntl } from 'react-intl'
import Workflow from "../workflow"
import TruckDesignationStep from './truckDesignationStep'
import TruckAddressAndDriverStep from './truckAddressAndDriversStep'
import TruckAmountsStep from './truckAmountsStep'
import TruckPropertiesStep from "./truckPropertiesStep"
import StringHelper from "../../../../helpers/StringHelper"
import DefaultImage from "../../common/defaultImage"
import Resources from "../../../../resources"


class TruckWorkflow extends Workflow {

	constructor(props) {
		super(props)

		this.state = {
			visible: false,
			current_step: this.props.current_step ? this.props.current_step : "TRUCK_DESIGNATION_STEP",
			steps: {
				"TRUCK_DESIGNATION_STEP": {
					id: "TRUCK_DESIGNATION_STEP",
					position:1,
					component: <TruckDesignationStep workflow={this} id="TRUCK_DESIGNATION_STEP"></TruckDesignationStep>,
					nexts: [{
						step: "TRUCK_AMOUNTS_STEP", label: "Suivant >>",
						visible: true
					}]
				},
				"TRUCK_AMOUNTS_STEP":{
					id: "TRUCK_AMOUNTS_STEP",
					position:1,
					component: <TruckAmountsStep workflow={this} id="TRUCK_AMOUNTS_STEP"></TruckAmountsStep>,
					conditions: [],
					links: [{step: "TRUCK_DESIGNATION_STEP", label: "<< Revenir à la désignation"}],
					nexts: [{
						step: "TRUCK_ADDRESS_DRIVERS_STEP", label: "Suivant >>",
						visible: true
					}]
				},
				"TRUCK_ADDRESS_DRIVERS_STEP": {
					id: "TRUCK_ADDRESS_DRIVERS_STEP",
					position:1,
					component: <TruckAddressAndDriverStep workflow={this} type={this.props.type} id="TRUCK_ADDRESS_DRIVERS_STEP"></TruckAddressAndDriverStep>,
					conditions: [],
					links: [
						{
							step: "TRUCK_AMOUNTS_STEP", label: "<< Revenir aux tarifs ", visible: false,
							condition : function(component)
							{
								return component.allowNextStep()
							}
						},
						{
							step: "TRUCK_ADDRESS_DRIVERS_STEP", label: "<< Revenir à l'écran précédent ", visible: false,
							onClick: (component) => {
								component.hideAddressOrDriverPanel()
							},
							condition : function(component)
							{
								return !component.allowNextStep()
							}
						}
					],
					nexts: [
						{
							step: "TRUCK_PROPERTIES_TYPOLOGY_STEP", label: "Suivant >>",
							visible: false,
							condition : function(component)
							{
								return component.allowNextStep()
							}
						}
					]
				},
				"TRUCK_PROPERTIES_TYPOLOGY_STEP": {
					id: "TRUCK_PROPERTIES_TYPOLOGY_STEP",
					position:1,
					component: <TruckPropertiesStep key="0" families={["VEHICLE_TYPOLOGY"]} workflow={this} id="TRUCK_PROPERTIES_TYPOLOGY_STEP" ></TruckPropertiesStep>,
					conditions: [],
					links: [{ step: "TRUCK_ADDRESS_DRIVERS_STEP", label: "<< Revenir au lieu de stationnement et conducteurs " }],
					nexts: [{
						step: "TRUCK_PROPERTIES_FEATURES_STEP", label: "Suivant >>",
					}]
				},
				"TRUCK_PROPERTIES_FEATURES_STEP": {
					id: "TRUCK_PROPERTIES_FEATURES_STEP",
					position:1,
					component: <TruckPropertiesStep key="1" families={["VEHICLE_FEATURES"]} workflow={this} id="TRUCK_PROPERTIES_FEATURES_STEP" ></TruckPropertiesStep>,
					conditions: [],
					links: [{step: "TRUCK_PROPERTIES_TYPOLOGY_STEP", label: "<< Revenir à la typologie du véhicule"}],
					nexts: [{
						step: "TRUCK_PROPERTIES_ENVIRONMENTAL_STEP", label: "Suivant >>",
					}]
				},

				"TRUCK_PROPERTIES_ENVIRONMENTAL_STEP": {
					id: "TRUCK_PROPERTIES_ENVIRONMENTAL_STEP",
					position:1,
					component: <TruckPropertiesStep key="2" families={["ENVIRONMENTAL_ASPECT"]} workflow={this} id="TRUCK_PROPERTIES_ENVIRONMENTAL_STEP" ></TruckPropertiesStep>,
					conditions: [],
					links: [{step: "TRUCK_PROPERTIES_FEATURES_STEP", label: "<< Revenir "}],
					nexts: [{
						step: "TRUCK_PROPERTIES_APPEARANCE_STEP", label: "Suivant >>"
					}]
				},

				"TRUCK_PROPERTIES_APPEARANCE_STEP": {
					id: "TRUCK_PROPERTIES_APPEARANCE_STEP",
					position:1,
					component: <TruckPropertiesStep key="3" families={["VEHICLE_APPEARANCE"]} workflow={this} id="TRUCK_PROPERTIES_APPEARANCE_STEP" ></TruckPropertiesStep>,
					conditions: [],
					links: [{step: "TRUCK_PROPERTIES_ENVIRONMENTAL_STEP", label: "<< Revenir "}],
					nexts: [
						// Bouton si on doit remplir les données de familles spécifiques
						{
							step: "TRUCK_PROPERTIES_SPECIFIC_RULE_STEP", label: "Suivant >>",
							visible: false, condition: (component) => {
								return this.hasSpecififFamiliesToShow()
							}
						},
						// Bouton quand aucune famille supplémentaire ne doit être montrée
						{
							step: "REFRESH", visible: false, label: "Terminer", condition: (component) => {
								return !this.hasSpecififFamiliesToShow()
							}
						}
					]
				},
				"TRUCK_PROPERTIES_SPECIFIC_RULE_STEP": {
					id: "TRUCK_PROPERTIES_SPECIFIC_RULE_STEP",
					position:1,
					component: <TruckPropertiesStep key="4" families={["BENNE_FEATURES", "GRUE_FEATURES", "PLATEAU_FEATURES"]} workflow={this} id="TRUCK_PROPERTIES_SPECIFIC_RULE_STEP" ></TruckPropertiesStep>,
					conditions: [],
					links: [{step: "TRUCK_PROPERTIES_APPEARANCE_STEP", label: "<< Revenir "}],
					nexts: [{
						step: "REFRESH", label: "Terminer"
					}]
				}
			}
		}
	}

	hasSpecififFamiliesToShow() {
		var truckInfos = this.getTruck()
		var returnValue = false
		if (truckInfos.rules_results )
		{
			truckInfos.rules_results.forEach(ruleResult => {
				if (ruleResult.action == "PROPERTY_RULE_ACTION_TYPE_SHOW")
				{
					returnValue = true
				}
			})
		}

		return returnValue
	}

	getSpecificFamiliesFromRules() {
		var specificFamilies = []
		return specificFamilies
	}

	componentWillMount() {
		super.componentWillMount()
	}

	componentDidMount() {
		super.componentDidMount()
	}

	reset() {
		this.setState({
			current_step: "TRUCK_DESIGNATION_STEP",
			input: null
		})
	}

	getTruck() {
		var truck = this.state.input ? this.state.input : this.props.truck

		if (truck == null) {
			truck = {
				complete: false,
				complete_response: null,
				reference: null,
				registration: null,
				description: null,
				cost_day:null,
				cost_half_day: null,
				cost_km: null,
				cost_hour: null,
				address_id: null,
				address: null,
				drivers : [],
				property_values: [],
				rules_results: []
			}
		}

		return truck
	}

	onSlidingPanelClosed() {
		var modalOptions = {
			hideIcon: true,
			title: 'Confirmation',
			okButtonTitle:  this.translateMessage("Default.yes"),
			cancelButtonTitle:  this.translateMessage("Default.no"),
			component:  <>
				Souhaitez vous réellement quitter l'assistant de création de camion ?
			</>,
			okButtonCallback: () => {
				this.setState({visible: false})
				this.closeModal()
				this.props.parent.end()
			}
		}

		this.showModalConfirmation(modalOptions)
		//this.props.parent.end()
	}

	start(inputValue) {
		this.setState({
			input: inputValue,
			visible: true
		})
	}

	render() {
		var options = this.getSlindingBarOptions()
		options.title = StringHelper.translate(this, "truck.new_truck_title")
		options.subtitle = "" //StringHelper.translate(this, "truck.new_truck_subtitle", "")
		options.icon = <DefaultImage style={{fontSize:22}} src={Resources.close}></DefaultImage>
		options.onRequestClose = () => {
			this.onSlidingPanelClosed()
		}

		return (
			<>
				{this.renderAsSlidingPanel(options)}
			</>
		)
	}
}


export default injectIntl(TruckWorkflow)

