
import React from "react";
import classnames from "classnames";
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import BusinessService from "../../../proxies/BusinessService"
import { injectIntl , FormattedMessage } from 'react-intl';
import queryString from 'query-string';

// reactstrap components
import {
    Button,
    ButtonGroup,
    Container,
    Form,
    FormGroup,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Modal,
    CardTitle,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Alert
  } from "reactstrap";
import SearchBySiretModal from "../components/modal/SearchBySiretModal";
import FormGroupInput from "../components/form/FormGroupInput";
import FormAddress from "../components/form/FormAddress";
import GeocodingAndNavigationService from "../../../proxies/GeocodingAndNavigationService";
import DefaultCheckbox from "../../components/common/defaultCheckbox";
import DefaultPopover from "../../components/common/defaultPopover";
import DefaultButton from "../../components/common/defaultButton";
import DefaultInput from "../../components/common/defaultInput";
import SecurityService from "../../../proxies/SecurityService";
import StringHelper from "../../../helpers/StringHelper";
import FormLegend from "../../components/form/formLegend";
import Resources from "../../../resources";
import DefaultLabel from "../../components/common/defaultLabel";
import DateLabel from "../../components/common/dateLabel"
import Message from "../../components/common/message";
import ReactSlidingPane from "react-sliding-pane";
import AddNewIban from "../transporter/AddNewIban";
import FormFooter from "../../components/form/formFooter";
import PaymentMethodNumber from "../../components/business/paymentMethodNumber";
import AddressComponent from "../../components/workflow/address/addressComponent";
import AddressWorkflow from "../../components/workflow/address/addressWorkflow";
import ShipperPaymentMethods from "../shipper/ShipperPaymentMethods";

class Company extends FormValidationComponent {

    GENERAL_INFOS = 1;
    TRANSPORTER_PAYMENT_METHOD = 2;
    SHIPPER_PAYMENT_METHODS = 3;

    constructor(props)
    {
        super(props)
        
        this.businessService = new BusinessService();
        this.securityService = new SecurityService();
        this.geocodingAndNavigationService = new GeocodingAndNavigationService();

        this.validationRules = {
            street : {
                required : true,
            },
            zip_code : {
                required: true,
            },
            city : {
                required: true,
            },
            country_code: {
                required: true,
            },
            company_name : {
                required: true,
            },
            siret : {
                required: true,
                rules : [
                    { rule: this.siret.bind(this), ruleMessage: this.translateMessage("Default.invalid_siret")}
                ]
            }
        }

        this.state = {
            currentTab: this.GENERAL_INFOS,

            searchBySiretModal: false,
            ibanPublicInfos: this.securityService.getCompany(),
            loading : true,
            currentTabIndex: 1,
            selectionMode: 'global',
            uploadFileSizeAlert: false,
            ibanModalVisible: false,
            formDatas : {
                country_code : {value: "COUNTRY_FR"},
                operational_address: {value: false}
            }
        }
    }

    toggleTabs(e, index, mode) {
        e.preventDefault();

        this.setState({
            selectionMode: mode,
            currentTabIndex: index
        });
    }

    componentWillMount()
    {
        const parsedQueryString = queryString.parse(window.location.search);
        if (parsedQueryString.mode && parsedQueryString.mode == "payment_method")
        {
            this.setState({currentTab: this.securityService.isTransporter() ? this.TRANSPORTER_PAYMENT_METHOD : this.SHIPPER_PAYMENT_METHODS})
        }

        // Récupération des données du compte (utilisateur / société)
        this.securityService.me(this, response => {
            this.setState({ibanPublicInfos: response.company})
        })
    }

    componentDidMount()
    {
        // Récupération des données de facturation
        this.businessService.getCompanyInfos(this, this.bindDatas.bind(this));
    }

    bindDatas(response)
    {
        var formDatas = {
            activity_code:  { value : response.activity_code},
            lti_copy_number: {value : response.lti_copy_number},
            lti_number : { value : response.lti_number},
            lti_start_date : { value : response.lti_start_date},
            lti_end_date : { value : response.lti_end_date},
            lc_copy_number: {value : response.lc_copy_number},
            lc_number : { value : response.lc_number},
            lc_start_date : { value : response.lc_start_date},
            lc_end_date : { value : response.lc_end_date},
            operational_address: {value: response.billing_address.operational_address}
        }

        this.setState({address: response.billing_address, company_name: response.company_name, siret: response.siret, formDatas : formDatas, loading :false})

        // On ouvre le process de saisie des données société
        if (this.emptyValue(response.company_name) && this.emptyValue(response.siret))
        {
            this.setState({searchBySiretModal: true})
        }
    }

    userCanUpdateCompanyIban()
    {
        var user = this.securityService.getUser();
        return user.is_transporter && user.is_company_account_manager;
    }
    
    addressIsOk()
    {
        if (this.state.address)
        {
            return this.geocodingAndNavigationService.levelIsValid(this.state.address.gps_level)
        }
        else
        {
            return true;
        }
    }


    onOperationalAddressChanged(e)
    {
        var value = (!this.operationalAddressCkb.getValue())
        var formDatas = this.state.formDatas
        formDatas.operational_address.value = value
        //this.setState({ formDatas: formDatas })

        if (value)
        {
            this.businessService.setBillingAddressOperational(null, (response) => {
                this.showToastNotification("success", "", StringHelper.translate(this,this.securityService.isTransporter() ? "company.transporter_billing_operational_address_yes" : "company.shipper_billing_operational_address_yes"))
            })
        }
        else
        {
            this.businessService.setBillingAddressNotOperational(null, (response) => {
                this.showToastNotification("success", "", StringHelper.translate(this,this.securityService.isTransporter() ? "company.transporter_billing_operational_address_no" : "company.shipper_billing_operational_address_no"))
            })
        }
    }

    onSubmitForm(address)
    {
        var postedDatas = {
            company_name : this.getHtmlFormControlValue("company_name"),
            siret : this.getHtmlFormControlValue("siret"),
            street : address.street,
            street2 : address.street2,
            zip_code : address.zip_code,
            city : address.city,
            country_code : address.country_code,
            gps_lat: address.gps_lat,
            gps_long: address.gps_long,
            gps_level: address.gps_level,
            gps_external_id: address.gps_external_id,

            // Champs non visible
            activity_code: this.state.formDatas.activity_code.value,
            lti_copy_number: this.state.formDatas.lti_copy_number.value,
            lti_number: this.state.formDatas.lti_number.value,
            lti_start_date: this.state.formDatas.lti_start_date.value,
            lti_end_date: this.state.formDatas.lti_end_date.value,
            lc_copy_number: this.state.formDatas.lc_copy_number.value,
            lc_number: this.state.formDatas.lc_number.value,
            lc_start_date: this.state.formDatas.lc_start_date.value,
            lc_end_date: this.state.formDatas.lc_end_date.value,
        }

        //this.geocodingAndNavigationService.getAddressFields(this, postedDatas, (postedDatasWithAddressFields) => {
            const warningMessage = this.props.intl.formatMessage({id: "MissionStep.address_registered_with_warning"});
            this.businessService.saveCompanyBillingInfos(this, postedDatas, (response) => {
                this.setState({
                    address: address
                })
                if (response.warnings.length == 0)
                {
                    this.showSuccessModalNotification("Confirmation", "La nouvelle adresse de votre siège à bien été prise en compte.")
                }
                else
                {
                    
                    var component = this.prepareResponseErrorComponent(response, StringHelper.decodeEntities(["La nouvelle adresse de votre siège à bien été prise en compte.\n \nToutefois, merci de prendre en compte les alertes suivantes: "], "div", " "))
                    this.showSuccessModalNotification("Confirmation", component)
                }
            })
        //});
    }

    toggleSiretModal()
    {
        this.setState({
            searchBySiretModal: !this.state.searchBySiretModal
        })
    }

    siretConfirmation(companyInfos)
    {
        // Pré remplissage des données
        this.bindDatas(companyInfos)
    }

    renderGlobalInfos()
    {   
        return <>
            <SearchBySiretModal stateKey='searchBySiretModal' manager={this} onSiretConfirmation={this.siretConfirmation.bind(this)} />
            <Form id="company-form" className="needs-validation" autoComplete="off" noValidate onSubmit={e => this.onSubmitForm(e)} >
                <Input type="hidden" id="id" name="id" value={this.state.id} />

                <Row>
                    <Col className="order-xl-1" xl="12">
                    <FormLoadingIndicator loading={this.state.loading}>
                    <Card>
                        <CardHeader>
                            
                        <Row className="align-items-center">
                            <Col xs="6">
                            <h3 className="mb-0"></h3>
                            </Col>
                            {/*<Col className="text-right" xs="12"><DefaultButton type="submit" className="t2y-secondary-button" icon="ni ni-check-bold">&nbsp;Enregistrer</DefaultButton></Col>*/ }
                        </Row>
                        </CardHeader>
                        <CardBody>
                        

                            <Row>
                                <Col lg="5">
                                    <div className="pl-lg-4">
                                        <h6 className="heading-small text-muted mb-4">
                                            Informations générales
                                        </h6>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroupInput intlId={"Company.company_name"} htmlFor="company_name" type="multiline">
                                                    <DefaultInput childRef={elt => this.companyNameInputRef = elt} readOnly={true} name='company_name' manager={this} />
                                                </FormGroupInput>
                                                
                                            </Col>
                                            <Col lg="12">
                                                <FormGroupInput intlId={"Company.siret"} htmlFor="siret" type="multiline">
                                                    <DefaultInput childRef={elt => this.siretInputRef = elt} readOnly={true} name='siret' manager={this} />
                                                </FormGroupInput>
                                            </Col>
                                        </Row>
                                    </div>
                                    {
                                        (this.userCanUpdateCompanyIban()) ?
                                        (
                                            this.renderTransporterPaymentMethod()
                                        ) : (<></>)
                                    }
                                </Col>
                            
                                <Col lg="7">
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="12">
                                                <DefaultCheckbox id="operational_address"
                                                    childRef={(elt) => this.operationalAddressCkb = elt} 
                                                    defaultValue={this.state.formDatas.operational_address.value}
                                                    content={this.props.intl.formatMessage({id: "company.operational_address"})}
                                                    popover={
                                                        <DefaultPopover content={
                                                            StringHelper.translate(this, 
                                                            (this.securityService.isTransporter() ? 
                                                                    "company.confirmation_step.transporter_billing_operational_address" : 
                                                                    "company.confirmation_step.shipper_billing_operational_address"
                                                                )
                                                            )}
                                                        />}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="pl-lg-4">
                                        <h6 className="heading-small text-muted mb-4">
                                            Adresse
                                        </h6>
                                        <Row>
                                            {
                                                
                                                <Col lg="12">
                                                    <FormAddress 
                                                        childRef={elt => this.formAddressRef = elt}
                                                        manager={this} 
                                                        suggestions={true} 
                                                        addressIsGeocoded={this.state.addressIsGeocoded} 
                                                        addressAlertMessage={this.state.addressAlertMessage} />
                                                </Col>
                                                
                                            }
                                            {
                                            /*
                                            <Col lg="12">
                                                <FormGroupInput labelColumns="2" contentColumns="10"  label="Adresse"  htmlFor="street">
                                                    <FormInput name='street' manager={this} />
                                                </FormGroupInput>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroupInput labelColumns="2" contentColumns="10"  label="Complément" htmlFor="street2">
                                                    <FormInput name='street2' manager={this} />
                                                </FormGroupInput>
                                            </Col>
                                            <Col lg="12">
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroupInput label="Code postal" htmlFor="zip_code">
                                                            <FormInput name='zip_code' manager={this} />
                                                        </FormGroupInput>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroupInput label="Ville" htmlFor="city">
                                                            <FormInput name='city' manager={this} />
                                                        </FormGroupInput>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg="12">
                                                <FormGroupInput labelColumns="2" contentColumns="10"  label="Pays" htmlFor="street2">
                                                    <FormCountryInput name='country_code' manager={this}/>
                                                </FormGroupInput>
                                            </Col>
                                            */
                                            }
                                        </Row>
                                    </div>
                                    <FormFooter></FormFooter>
                                </Col>
                            </Row>
                                    
                        </CardBody>
                    </Card>
                    </FormLoadingIndicator>
                    </Col>

                    </Row>
                </Form>
        </>
    }

    fileChangedHandler = (event) => {
        let file = event.target.files[0];
        let fileSize = file.size;
            
        if (((fileSize / 1024) / 1024) > 5)
        {
            this.setState({uploadFileSizeAlert: true})
        }
        else {
            this.setState({uploadFileSizeAlert: false, image_file: file})
        }
    }

    renderTransporterPaymentMethod()
    {
        const ibanPublicInfos = this.state.ibanPublicInfos

        return <> 
                {
                    (ibanPublicInfos.has_iban && ibanPublicInfos.iban_state.code != 'IBAN_STATE_WATING_FOR_VALIDATION') ?
                    (
                        <>
                            <h6 className="heading-small text-muted mb-4">
                                Données bancaires enregistrées
                                <DefaultButton style={{float:"right"}} 
                                    className="t2y-secondary-button small" icon={Resources.button_edit_icon}
                                    onClick={elt => this.setState({ibanModalVisible: true})} 
                                >
                                    &nbsp;Modifier cet iban
                                </DefaultButton>
                            </h6>
                        </>
                    ) : (
                        (ibanPublicInfos.has_iban && ibanPublicInfos.iban_state.code == 'IBAN_STATE_WATING_FOR_VALIDATION') ?
                        (
                            <>
                                <Row className="align-item-center">
                                    <Col md="3"></Col>
                                    <Col md="6">
                                    <Message type="infos">
                                        <DefaultLabel>
                                        Vos données bancaires sont actuellement en cours de validation
                                        </DefaultLabel>
                                    </Message>
                                    </Col>
                                </Row>
                                <h6 className="heading-small text-muted mb-4">
                                    Données bancaires enregistrées
                                </h6>
                            </>
                        ):(<></>)
                    )
                }
                {
                    (ibanPublicInfos.has_iban) ?
                    (
                        <>
                            <Row>
                                <Col md="4">
                                    <FormGroupInput intlId={"Company.iban_owner_label"} htmlFor="company_iban_owner" type="multiline">
                                        <DefaultLabel  style={{width:"100%"}}>{ibanPublicInfos.iban_owner_label}</DefaultLabel>
                                    </FormGroupInput>
                                </Col>
                                <Col md="4">
                                    <FormGroupInput intlId={"Company.iban_public_value"} htmlFor="company_iban" type="multiline">
                                        <DefaultLabel  style={{width:"100%"}}>
                                            <PaymentMethodNumber value={ibanPublicInfos.iban_public_value} type="iban"></PaymentMethodNumber>
                                        </DefaultLabel>
                                    </FormGroupInput>
                                </Col>
                                <Col md="4">
                                    <FormGroupInput intlId={"Company.iban_creation"} htmlFor="company_creation" type="multiline">
                                        <DefaultLabel  style={{width:"100%"}}>Créé le <DateLabel label=" " date={ibanPublicInfos.iban_creation_date}></DateLabel> par {ibanPublicInfos.iban_creator.summary}</DefaultLabel>
                                    </FormGroupInput>
                                </Col>
                            </Row>
                        </>
                    ) :
                    (
                        (ibanPublicInfos.iban_state != null && ibanPublicInfos.iban_state.code == "IBAN_STATE_KO") ?
                        (
                            <>
                                <Row className="align-item-center">
                                    <Col md="3"></Col>
                                    <Col md="6">
                                        <Message type="warning">
                                            {StringHelper.decodeEntities([StringHelper.translate(this, "Company.transporter_iban_refused_alert")])}
                                            <DefaultButton style={{float:"right"}} 
                                                className="t2y-secondary-button small" 
                                                icon={Resources.button_add_icon}
                                                onClick={elt => this.setState({ibanModalVisible: true})} 
                                            >
                                                &nbsp;Ajouter des données bancaires
                                            </DefaultButton>
                                        </Message>
                                    </Col>
                                </Row>
                            </>
                        ) : 
                        (
                            <>
                                <Row className="align-item-center">
                                    <Col md="3"></Col>
                                    <Col md="6">
                                        <Message type="warning">
                                            {StringHelper.decodeEntities([StringHelper.translate(this, "Company.transporter_no_iban_alert")])}
                                            <DefaultButton style={{float:"right"}} 
                                                className="t2y-secondary-button small" 
                                                icon={Resources.button_add_icon}
                                                onClick={elt => this.setState({ibanModalVisible: true})} 
                                            >
                                                &nbsp;Ajouter des données bancaires
                                            </DefaultButton>
                                        </Message>
                                    </Col>
                                </Row>
                            </>
                        )
                    )
                }
        </>
    }

    ibanAdded(company)
    {
        this.setState({
            ibanModalVisible: false,
            ibanPublicInfos: company
        })


        this.showSuccessModalNotification(
            "Confirmation", 
            StringHelper.translate(this, "Company.new_iban_added")
        )
    }

    renderIbanModal()
    {
        if (!this.state.ibanModalVisible)
            return <></>

        return <ReactSlidingPane
            className="some-custom-class"
            overlayClassName="some-custom-overlay-class"
            isOpen={this.state.ibanModalVisible}
            title={null}
            width="40%"
            subtitle={null}
            onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                this.setState({ ibanModalVisible: false });
            }}
        >
            <AddNewIban manager={this}></AddNewIban>

        </ReactSlidingPane>
    }


    renderGeneralInfos()
    {
        return <>
            <Row>
                <Col lg="5">
                    <div className="pl-lg-4">
                        <h6 className="heading-small text-muted mb-4">
                            Informations générales
                        </h6>
                        <Row>
                            <Col lg="12">
                                <FormGroupInput intlId={"Company.company_name"} htmlFor="company_name" type="multiline">
                                    <DefaultInput value={this.state.company_name} childRef={elt => this.companyNameInputRef = elt} readOnly={true} name='company_name' manager={this} />
                                </FormGroupInput>
                                
                            </Col>
                            <Col lg="12">
                                <FormGroupInput intlId={"Company.siret"} htmlFor="siret" type="multiline">
                                    <DefaultInput value={this.state.siret}  childRef={elt => this.siretInputRef = elt} readOnly={true} name='siret' manager={this} />
                                </FormGroupInput>
                            </Col>
                        </Row>
                    </div>
                </Col>
            
                <Col lg="7">
                    <div className="pl-lg-4">
                        <h6 className="heading-small text-muted mb-4">
                            Adresse de votre siège
                        </h6>
                        <Row>
                            <Col lg="12">
                                <AddressComponent alert={true} position="map_address" address={this.state.address} mode="inline" editable={true} manager={this}
                                    onAddressSubmited={(address) => {
                                        // Enregistrement de l'adresse
                                        this.onSubmitForm(address);                
                                    }}
                                ></AddressComponent>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col lg="12">
                                <DefaultCheckbox id="operational_address"
                                    childRef={(elt) => this.operationalAddressCkb = elt} 
                                    checked={this.state.formDatas.operational_address.value}
                                    content={this.props.intl.formatMessage({id: "company.operational_address"})}
                                    onChange={e => this.onOperationalAddressChanged(e)}
                                    popover={
                                        <DefaultPopover content={
                                            StringHelper.translate(this, 
                                            (this.securityService.isTransporter() ? 
                                                    "company.confirmation_step.transporter_billing_operational_address" : 
                                                    "company.confirmation_step.shipper_billing_operational_address"
                                                )
                                            )}
                                        />}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>

            </Row>
        </>
    }

    renderAsTransporter()
    {
        return <>
            <Row className="justify-content-center mb-2 mt-3" style={{ marginRight: 0, marginLeft: 0 }}>
                <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                    <Button className={classnames({ active: this.state.currentTab === this.GENERAL_INFOS }) + " tabBtn"} color="secondary" 
                        onClick={(e) => this.setState({currentTab: this.GENERAL_INFOS}) + " tabBtn"}>
                        Informations générales
                    </Button>
                    <Button className={classnames({ active: this.state.currentTab === this.TRANSPORTER_PAYMENT_METHOD }) + " tabBtn"} color={"secondary"}
                        onClick={(e) => {this.setState({currentTab: this.TRANSPORTER_PAYMENT_METHOD})}}>
                        Coordonnées bancaires
                    </Button>
                </ButtonGroup>
            </Row>

            <Card>
                <CardBody>
                    <div className="pl-lg-4" key={StringHelper.createDomElementId()}>
                    {
                        (this.state.currentTab == this.GENERAL_INFOS) ?
                        (
                            this.renderGeneralInfos()
                        ) : (<></>)
                    }
                    {
                        (this.state.currentTab === this.TRANSPORTER_PAYMENT_METHOD) ?
                        (
                            this.renderTransporterPaymentMethod()
                        ) : (<></>)
                    }
                    </div>
                </CardBody>
            </Card>
        </>
    }


    renderAsShipper()
    {
        return <>
            <Row className="justify-content-center mb-2 mt-3">
                <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                    <Button className={classnames({ active: this.state.currentTab === this.GENERAL_INFOS }) + " tabBtn"} color="secondary" 
                        onClick={(e) => this.setState({currentTab: this.GENERAL_INFOS})}>
                        Informations générales
                    </Button>
                    <Button className={classnames({ active: this.state.currentTab === this.SHIPPER_PAYMENT_METHODS }) + " tabBtn"} color={"secondary"}
                        onClick={(e) => {this.setState({currentTab: this.SHIPPER_PAYMENT_METHODS})}}>
                        {this.securityService.isTransporter()? "Coordonnées bancaires" : "Vos moyens de paiement"}
                    </Button>
                </ButtonGroup>
            </Row>

            <Card>
                <CardBody>
                    <div className="pl-lg-4" key={StringHelper.createDomElementId()}>
                    {
                        (this.state.currentTab == this.GENERAL_INFOS) ?
                        (
                            this.renderGeneralInfos()
                        ) : (<></>)
                    }
                    {
                        (this.state.currentTab === this.SHIPPER_PAYMENT_METHODS) ?
                        (
                            <ShipperPaymentMethods></ShipperPaymentMethods>
                        ) : (<></>)
                    }
                    </div>
                </CardBody>
            </Card>
        </>
    }

    renderAddressWorkflow()
    {
        if (!this.state.addressModalVisible)
            return <></>

        return <ReactSlidingPane
            className="some-custom-class"
            overlayClassName="some-custom-overlay-class"
            isOpen={this.state.addressModalVisible}
            title={null}
            width="40%"
            subtitle={null}
            onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                this.setState({ addressModalVisible: false });

                if (this.props.onHide) 
                    this.props.onHide(null)
            }}
        >
            <FormLoadingIndicator loading={this.state.loading}>
            <div className="container">
                <AddressWorkflow 
                    creation={true} 
                    customer={false}
                    data={this.state.address}
                    current_step={"ADDRESS_CONFIRMATION_STEP"}
                    confirmationButton={
                        {
                            label: "Enregistrer"
                        }
                    }
                    autocompletePlaceHolder={StringHelper.translate(this, this.securityService.isTransporter() ? "address.autocomplete_transporter_placeholder" : "address.autocomplete_shipper_placeholder")}
                    addressConfirmationAllowOperationalAddress={false}
                    onAddressSubmited={(data) => {
                        this.setState({ addressModalVisible: false });
                        this.onSubmitForm(data)
                    }}
                ></AddressWorkflow>
            </div>
            </FormLoadingIndicator>
        </ReactSlidingPane>
    }
    

    render() {
        return  (
            <>
            {this.renderParent()}
            {this.renderAddressWorkflow()}
            {this.userCanUpdateCompanyIban() ? this.renderIbanModal() : <></>}
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
            <Container className="mt--4" fluid>
                {
                    (this.securityService.isTransporter()) ?
                    (
                        this.renderAsTransporter()
                    ) : (
                        this.renderAsShipper()
                    )
                }
            {
                
                //this.renderGlobalInfos()
            }
            </Container>
          </>
        );
    }
}


export default injectIntl(Company)