/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardText,
  CardTitle,
  CardImg,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import SecurityService from "../../../proxies/SecurityService";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import FormInput from '../components/FormInput.jsx';
import { injectIntl , FormattedMessage } from 'react-intl';
import UserState from "../../components/user/userState.jsx";
import Title2 from "../../components/common/title2.jsx";
import Title3 from "../../components/common/title3.jsx";
import Title4 from "../../components/common/title4.jsx";
import StringHelper from "../../../helpers/StringHelper.js";
import PasswordInput from "../../components/common/passwordInput.jsx";
import Paragraph from "../../components/common/paragraph.jsx";
import Resources from "../../../resources.js";
import DefaultButton from "../../components/common/defaultButton.jsx";
import WebsiteHelper from "../../../helpers/WebsiteHelper.js";

class CollaboratorAccountValidation extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    
    this.securityService = new SecurityService();

    this.validationRules = {
      password : {
        required : true,
        /*rules : [
          { 
            rule : this.email.bind(this),
            ruleMessage : this.translateMessage("Default.invalid_email")
          }
        ]*/
      },
      password_confirmation : {
          required : true,
          rules:  [
            {
                rule : this.checkConfirmationPassword.bind(this),
                ruleMessage : this.translateMessage("ChangePassword.invalid_confirmation_password")
            }
          ]
      }
    }
    this.state = {
      loading : false,
      passwordChanged : false,
      onExpirationError : false,
      seconds : 5,
      userError: false
    }
  }

  getToken()
  {
    const urlParams = new URLSearchParams (this.props.location.search)    
    return urlParams.get("token")
  }
 
  componentDidMount() {
    const token = this.getToken()
    // Si aucun token dans l'url alors on redirige sur la page de login
    if (token == null)
    {
      this.props.history.push('/auth/login');
    }
  }


  checkConfirmationPassword()
  {
      var password = this.getHtmlFormControlValue("password")
      var passwordConfirmation = this.getHtmlFormControlValue("password_confirmation")
      return passwordConfirmation == password
  }

  onSubmitForm = e => {
    e.preventDefault()
    var formIsValid = this.htmlFormIsValid(e);
    if (formIsValid)
    {
      // Récupération des donées postée
      var password = this.getHtmlFormControlValue("password")
      var passwordConfirmation = this.getHtmlFormControlValue("password_confirmation")
      // Modification du mot de passe
      this.securityService.validateCollaboratorAccount(this, {token : this.getToken(), password : password, password_confirmation : passwordConfirmation}, (response) => {
        // Si tout est ok, on affiche le message et on redirige sur la home
        this.setState({passwordChanged : true, onExpirationError: false })
        
        setInterval(function() {
          var currentSeconds = this.state.seconds - 1;
          this.setState(state => ({
            seconds: currentSeconds
          }));

          if (currentSeconds <= 0)
          {
            this.props.history.push('/admin/profile');
          }

        }.bind(this), 1000);


      }, (httpResponse) => {

        // On recherche l'erreur d'expiration
        if (httpResponse.status == "498")
        {
          this.setState({passwordChanged : false, onExpirationError: true, loading : false })
        }
        else{
          //this.securityService.handleResponseError(this, httpResponse)
          httpResponse.json().then((apiResponse) => {
            if (apiResponse.invalid_rules && apiResponse.invalid_rules.length > 0)
            {
              this.showErrorFromResponse(apiResponse)
            }
            else
            {
              this.setState({userError: true})
            }
            
          });
          
          this.setState({loading: false})
        }
      });
    }
  }

  showSuccessMessage()
  {
    return <Col md="7">
      <Card className="bg-secondary border-0 mb-0">
        <CardBody className="px-lg-5 py-lg-5">
            <CardTitle className="mb-3" tag="h3">
                <FormattedMessage id="ForgetPassword.confirmation_title">
                { this.decodeEntities }
                </FormattedMessage>
            </CardTitle>
            <CardText className="mb-4">
                <FormattedMessage id="ForgetPassword.confirmation_message">
                { this.decodeEntities }
                </FormattedMessage>
            </CardText>
        </CardBody>
      </Card>
    </Col>
  }

  showForm()
  {
      return <Col md="7">
      <Card className="bg-secondary border-0 mb-0">
        <CardBody className="px-lg-5 py-lg-5 mt-5">
          
          
          <Form onSubmit={e => this.onSubmitForm(e)} role="form">
            <Row className="mb-3  mt-5">
                <Col>
                <Title2>
                  <FormattedMessage id='CollaborationActivation.title1'>
                        {this.decodeEntities}
                    </FormattedMessage>
                </Title2>
                <Title4>
                  <FormattedMessage id='ChangePassword.title2'>
                        {this.decodeEntities}
                    </FormattedMessage>
                </Title4>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <PasswordInput id="password" 
                        creation={false}
                        childRef={(elt) => this.passwordInputRef = elt}
                        icon={Resources.form_password_icon} placeholder={this.translateMessage("ChangePassword.password")}>
                    </PasswordInput>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <PasswordInput id="password_confirmation" 
                        childRef={(elt) => this.passwordConfirmationInputRef = elt}
                        icon={Resources.form_password_icon} placeholder={this.translateMessage("ChangePassword.password_confirmation")}>
                    </PasswordInput>
                </Col>
            </Row>

            <div className="text-center">
              <DefaultButton className="my-4 t2y-secondary-button" type="submit">
                <FormattedMessage id='ChangePassword.change_password'/>
              </DefaultButton>
            </div>
          </Form>


          
        </CardBody>
      </Card>
    </Col>
  }

  showSuccessMessage()
  {

      return <Row className="mt-5"> 
        <Col lg="12" md="12" className="order-lg-1 order-2" style={{marginTop:"150px"}}>
            <Row className="mb-3">
                <Col>
                <Title2 justify={true}>
                    <FormattedMessage id="Collaborator.account_validation_title"></FormattedMessage>
                </Title2>
                <Title3 justify={true}>
                  <FormattedMessage id="Collaborator.account_validation_message" values={{seconds: this.state.seconds}}>
                      { this.decodeEntities }
                  </FormattedMessage>
                </Title3>
                </Col>
            </Row>
            
            <Row className="align-item-center justify-content-center" >
                <DefaultButton    
                    type="button"
                    className="t2y-secondary-button"
                    key={"go_to_profile"}
                    onClick={(e) =>  WebsiteHelper.goTo(this, '/admin/profile')}
                    label={StringHelper.translate(this, "CollaboratorAccountValidation.redirect_to_profile")}>
                </DefaultButton>
            </Row>
        </Col>
    </Row>
/*
      return <Col md="7">
          <Card className="bg-secondary border-0">
            <CardBody>
                <CardTitle className="mb-3" tag="h3">
                    <FormattedMessage id="Collaborator.account_validation_title">
                    { this.decodeEntities }
                    </FormattedMessage>
                </CardTitle>
                <CardText className="mb-4">
                    <FormattedMessage id="Collaborator.account_validation_message" values={{seconds: this.state.seconds}}>
                    { this.decodeEntities }
                    </FormattedMessage>
                </CardText>
                <Button
                    color="primary"
                    href="/admin/profile"
                >
                    <FormattedMessage
                    id="CollaboratorAccountValidation.redirect_to_profile"
                    />
                </Button>
                </CardBody>  
            </Card>
        </Col>*/
  }

  render() {
    if (this.state.userError)
    {
      return <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
            <Col md="8"><UserState id={this.getId()} userStateActionResponse={this.state.userStateActionResponse}></UserState>
            </Col>
        </Row>
      </Container>
    }

    return (
      <>
        {this.renderParent()}
        <Container className="mt--8 pb-5">
        <FormLoadingIndicator
          loading={this.state.loading}
        />
          <Row className="justify-content-center">
            {
                (this.state.passwordChanged == false && this.state.onExpirationError == false) ?
                (
                    this.showForm()
                )
                :
                (
                    (this.state.onExpirationError == false) ?
                    (
                        this.showSuccessMessage()
                    )
                    :
                    (
                        this.showErrorMessage()
                    )
                )
            }
            
          </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(CollaboratorAccountValidation);
