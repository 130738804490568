import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Row,
    Col,
    Container,
    Card,
    CardHeader,
    CardBody,
    Collapse
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import Step from "../step";
import Config from "../../../../config"
import ShipperService from "../../../../proxies/ShipperService";

import PaymentComponent from "../../payment/PaymentComponent"
import InjectedCreditCardComponent from "../../payment/CreditCardComponent"
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StringHelper from "../../../../helpers/StringHelper";
import Codes from "../../../../codes";
const stripePromise = loadStripe(Config.stripePublicKey);

class PaymentMethodStep extends Step {

    constructor(props) {
        super(props)
        
        this.shipperService = new ShipperService();
        this.state = {
            payment_on_invoice: this.getPaymentOnInvoice(),
            order_retainer_amount_to_pay_ttc: 0,
            order_retainer_amount_to_pay_ht: 0
        }
    }

    componentWillMount() {
        this.startLoading();
        super.componentWillMount()

        // Récupération des données
        // Récupération du résultat (camion, notes, prix, disponibilité, ...)
        const resultId = this.props.workflow.state.input;

        // Récupération des données de la recherche / commande
        this.shipperService.getSearchResultDetail(null, resultId, (response) => {

            // Récupération des données de la commande 
            // Montant à payer TTC
            // Montant à payer HT
            this.setState({
                order_id: response.order_id,
                order_start_date: response.order_start_date,
                order_end_date: response.order_end_date ? response.order_end_date : response.order_start_date,
                retainer_amount_to_pay_ttc: response.retainer_amount_to_pay_ttc,
                retainer_amount_to_pay_ht: response.retainer_amount_to_pay_ht,
            }, () => {
                if (this.getPaymentOnInvoice())
                {
                    this.onPaymentMethodSavedSuccess({
                        payment_methodExternal_id: null
                    })
                }
            })  

            this.stopLoading();
        });
        
    }

    componentDidMount() {
        super.componentDidMount()
    }

    getPaymentOnInvoice()
    {
        const currentUser = this.shipperService.getCompany()
        return currentUser.payment_on_invoice;
    }

    allowNextStep()
    {
        return false;
    }

    getOrderId()
    {
        return this.state.order_id
    }

    sepaValidationButtonClicked()
    {
        this.props.workflow.goToNextStep(this.state.pid, {order_id: this.state.order_id, payment: this.paymentInfos})
    }

    submit(pid, input)
    {
        var formIsValid = this.htmlFormIsValid();
        if (formIsValid)
        {
            this.startSubmiting()
            this.shipperService.submitOrder(this, this.state.order_id, this.paymentInfos.payment_methodExternal_id, (response) => {
                super.submit(pid)

                // Passage à l'étape suivante
                this.props.workflow.goToNextStep(pid, {order_id: this.state.order_id, payment: this.paymentInfos})
                
                this.stopSubmiting()
            }, (httpErrorResponse) => {
                this.stopSubmiting();
                this.stopLoading();
                httpErrorResponse.json().then((apiResponse) => {
                    this.showErrorFromResponse(apiResponse, null)
                })
                return false;
            })
        }
    }

    onPaymentMethodSavedSuccess(paymentInfos)
    {
        // Récupération des données de paiement pour passer à l'étape suivante
        this.paymentInfos = paymentInfos;
        /*  this.props.workflow.submitStep({id: this.props.id}, {step: "WAITING_FOR_VALIDATION"}) */
        this.props.workflow.submitStep({id: this.props.id}, {step: "WAITING_CHOOSE_FIRST_ARRIVALTIME"})
    }

    onPaymentMethodSavedError(error)
    {
        this.showError("Impossible de traiter votre demande", "")
        this.stopSubmiting();
        this.stopLoading();
    }

    renderCreditCardPaymentComponent()
    {
        return <>
            <PaymentComponent
                onlyPaymentMethod={true}
                orderId={this.state.order_id}
                ttc={this.state.retainer_amount_to_pay_ttc} 
                rescue={false} 
                lastPayment={false}
                onPaymentSuccess={(paymentInfos) => this.onPaymentMethodSavedSuccess(paymentInfos)} 
                onPaymentError={(error) => this.onPaymentMethodSavedError(error)} 
                paymentDescription={
                    <>
                    Ce transporteur n'est pas en réservation instantanée : si vous réservez ce véhicule, nous commencerons par vérifier sa disponibilité. Vos données bancaires seront pré-enregistrées, mais le montant l'acompte ne sera prélevé que si la commande est validée.
                    </>
                }
                paymentButtonTitle={StringHelper.translate(this, "order.payment_step_pay_button_title")}
                cardPaymentMethodMinExpirationDate={this.state.order_end_date}
            />
            {/*
            <Container>
                <Row> 
                    <Col lg="12" md="12" className="order-lg-1 order-2">
                        <Elements stripe={stripePromise}>
                            <InjectedCreditCardComponent
                                ttcAmount={this.state.retainer_amount_to_pay_ttc} 
                                htAmount={this.state.retainer_amount_to_pay_ht}
                                onlyPaymentMethod={true}
                                onStart={() => {}} 
                                onSuccess={(paymentInfos) => this.onPaymentMethodSavedSuccess(paymentInfos)} 
                                onError={(error) => this.onPaymentMethodSavedError(error)} 
                            />
                        </Elements>
                    </Col>
                </Row>
            </Container>
            */}
        </>
    }

    render() {
        if (this.state.payment_on_invoice)
        {
            return <>
                {this.renderParent()}
                {super.renderAsBusy()}
            </>
        }
        else 
        {
            return <>

                {this.renderParent()}
                {
                    (this.busy()) ?
                    (
                        super.renderAsBusy()
                    )
                    :
                    (
                        this.renderCreditCardPaymentComponent()
                    )
                }
            </>
        }
    }
}


export default injectIntl(PaymentMethodStep)

