
import React, { useState, useEffect } from "react"
import TruckRepresentation from "../../../components/truck/TruckRepresentation"
import { Card, CardBody, Row, Col } from "reactstrap"
import { injectIntl } from "react-intl"
import GifLoader from "../../../../components/GifLoader"
import axios from "axios"
import Config from "../../../../config"
import CustomModal from "../../../../components/CustomModal"
import Title3 from "../../../components/common/title3"

const TruckCard = (props) => {
	const [costPerDay, setCostPerDay] = useState(props.data.cost_day)
	const [costPerHour, setCostPerHour] = useState(props.data.cost_hour)
	const [costPerKm, setCostPerKm] = useState(props.data.cost_km)
	const [loading, setLoading] = useState(false)
	const [hasChanged, setHasChanged] = useState(false)
	const [error, setError] = useState("")
	const [openModal, setOpenModal] = useState(false)

	/* Gestion du changement de prix */
	const handleChange = ({ currentTarget }) => {
		const { name, value } = currentTarget
		if(value >= 0) {
			switch(name){
				case "cost-per-day":
					setCostPerDay(value)
					break
				case "cost-per-hour":
					setCostPerHour(value)
					break
				case "cost-per-km":
					setCostPerKm(value)
					break
			}
			setHasChanged(true)
		} else {
			setError("Les tarifs ne peuvent pas être négatifs !")
		}
	}

	/* Gestion de l'enregistrement */
	const handleSubmit = async() => {
		setLoading(true)
		let token = localStorage.getItem("token")

		let truck = {
			cost_day: costPerDay,
			cost_km: costPerKm,
			cost_hour: costPerHour,
		}

		await axios
			.patch(Config.bndApi + "/api/transporter/truck/" + props.data.id,
				truck,
				{headers: {
						'Authorization': 'Bearer ' + token,
					}
				}
			)
			.then((response) => (response.data.message))
			.catch((error) => {
				//console.log(error.response)
				setError("La mise à jour a échoué pour une raison inconnue")
			})
			.finally(() => {
				setLoading(false)
				setHasChanged(false)
			})
	}

	/* On surveille la variable d'erreur, si elle est remplie, on affiche une fenêtre modale */
	useEffect(() => {
		if(error !== "") {
			setOpenModal(true)
		}
	},[error])

	return loading ?
				<Card className="d-flex align-items-center justify-content-center bg-faded" style={{ minHeight: 500 }}>
					<GifLoader height={200} width={270}/>
				</Card>
			:
			<Card style={{ height: "100%", marginBottom: 20 }}>
				<CardBody>
					{props.data.complete ?
						<div className="t2y-truck-complete ribbon-top-right">
							<span>Publié</span>
						</div>
						:
						<div className="t2y-truck-uncomplete ribbon-top-right">
							<span>Brouillon</span>
						</div>
					}
					<a href={"/admin/truck/" + props.data.id }>
						<Row className="justify-content-text-center">
							<Col lg="3"></Col>
							<Col lg="6">
								<TruckRepresentation truck_id={props.data.id}></TruckRepresentation>
							</Col>
						</Row>
						<Row>
							<h2 className="w-100 text-center pt-2">
								{props.data.reference && <span className="d-block mb-1">{(
									props.data.reference.length > 30 ?
										props.data.reference.slice(0, 30).concat("...")
									: props.data.reference
								)} </span>}
								{props.data.immatriculation && <span className="d-block mb-1">{props.data.immatriculation}</span>}
							</h2>
						</Row>
					</a>
					<div className="text-center">
						<div className="mb-1">
							<h3 className="t2y-card-title">Tarifs</h3>
							<div className="row mb-1 py-1">
								<div className="col-12 col-xl-4 d-flex align-items-center justify-content-center">
									Journée
								</div>
								<div className="col-12 col-xl-8 d-flex align-items-center">
									<input
										id="cost-per-day"
										name="cost-per-day"
										value={(costPerDay || "")}
										type="number"
										className="t2y-input-number"
										step="1"
										min="0"
										onChange={handleChange}
									/>
									<span className="t2y-euro-container">€</span>
								</div>
							</div>
							<div className="row mb-1 py-1">
								<div className="col-12 col-xl-4 d-flex align-items-center justify-content-center">
									Km supp
								</div>
								<div className="col-12 col-xl-8 d-flex align-items-center">
									<input
										id="cost-per-km"
										name="cost-per-km"
										value={(costPerKm || "")}
										type="number"
										className="t2y-input-number"
										step="0.1"
										min="0"
										onChange={handleChange}
									/>
									<span className="t2y-euro-container">€</span>
								</div>
							</div>
							<div className="row mb-1 py-1">
								<div className="col-12 col-xl-4 d-flex align-items-center justify-content-center">
									Heure supp
								</div>
								<div className="col-12 col-xl-8 d-flex align-items-center">
									<input
										id="cost-per-hour"
										name="cost-per-hour"
										value={(costPerHour || "")}
										type="number"
										className="t2y-input-number"
										step="0.1"
										min="0"
										onChange={handleChange}
									/>
									<span className="t2y-euro-container">€</span>
								</div>
							</div>
						</div>
						{props.data.technical_label ?
							<h4 className="title my-2">
								{props.data.technical_label}
							</h4>
						:
							<h4 className="title my-2">
								Carrosserie à définir
							</h4>
						}
						<h3>
							<i className="fas fa-users"></i>&nbsp;
							{props.data.nb_drivers + (props.data.nb_drivers > 1 ?  " conducteurs" : " conducteur")}
						</h3>
					</div>
					<div className="text-center">
						<button
							onClick={handleSubmit}
							className={"t2y-btn-sm-primary mt-2 " + (hasChanged ? "" : "not-visible")}
						>
							Enregistrer
						</button>
					</div>
				</CardBody>
					{props.data.required_properties && props.data.required_properties > 0 ?
						<span className="text-danger text-center">
							Encore {props.data.required_properties} propriété(s) à renseigner
						</span>
					: <span style={{ "visibility": "hidden" }}> . </span>}
				<CustomModal open={openModal} setOpen={setOpenModal}>
					<div className="text-center text-warning" style={{fontSize:"13vw"}}>
						<i className="fas fa-exclamation-triangle"></i>
					</div>
					<Title3 center={true}>
						{error}
					</Title3>
				</CustomModal>
			</Card>
}

export default injectIntl(TruckCard)
