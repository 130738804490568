import React, { Component } from "react";
import queryString from 'query-string';
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
  Button,
  Label,
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import FormInput from '../components/FormInput.jsx';
import { injectIntl , FormattedMessage } from 'react-intl';
import ShipperService from "../../../proxies/ShipperService.js";
import Rating from "../../components/rating/rating.jsx";
import StringHelper from "../../../helpers/StringHelper.js";
import Title2 from "../../components/common/title2.jsx";
import Title3 from "../../components/common/title3.jsx";
import DefaultButton from "../../components/common/defaultButton.jsx";
import Separator from "../../components/common/separator.jsx";
import DefaultImage from "../../components/common/defaultImage.jsx";
import Resources from "../../../resources.js";
import FormGroupInput from "../components/form/FormGroupInput.jsx";
import DefaultInput from "../../components/common/defaultInput.jsx";

class MissionStepRating extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    
    this.shipperService = new ShipperService();

    this.validationRules = {
      comment : {
        required : true
      }
    }
    this.state = {
      loading : false,
      formDatas : {
        email : { value: ""},
        password : { value: ""}
      }
    }

    this.handleUnload = this.handleUnload.bind(this);
  }

  handleUnload(e) {
    var message = "\o/";

    (e || window.event).returnValue = message; //Gecko + IE
    return message;
  }
 
    

  componentWillUnmount() {
    //window.removeEventListener('beforeunload', this.handleUnload);
  }

  componentDidMount() {
    
    if (this.ratingRequired() == false)
    {
      this.saveRating(this.props.match.params.rating);
    }
    else
    {
      // Récupération des paramètres
      const missionId = this.props.match.params.missionId;
      const missionStepId = this.props.match.params.missionStepId;
      const userId = this.props.match.params.userId;

      this.shipperService.getMissionUnloadingRatingInfos(this, missionId, missionStepId, userId, "~", (response) => {
        const customerMessage = response.use_custom_satisfaction_survey_question ? response.custom_satisfaction_survey_question : StringHelper.translateWithHtml(this, "Rating.mission_step.default_customer_message", null, {shipper_company_name: response.shipper_company_name})
        const satisfactionMessage = StringHelper.translateWithHtml(this, "Rating.mission_step.satisfaction_message", null, {customer_message: customerMessage})

        this.setState({
          rating: response.rating,
          comment: response.comment,
          shipperCompanyName: response.shipper_company_name,
          transportOperationId: response.transport_operation_id,
          shipperReference: response.shipper_reference,
          message: <>{satisfactionMessage}</>,
          referenceMessage: StringHelper.translateWithHtml(this, "Rating.mission_step.shipper_reference_message", null, response)
        })
      });
    }
  }

  downloadFreightDocument(caller)
  {
    const missionId = this.props.match.params.missionId;

    this.shipperService.publicDownloadTransportOperationFreightDocument(caller, missionId, this.state.transportOperationId, response => {

        const linkSource = `data:application/pdf;base64,${response.instance.base64_content}`;
        const downloadLink = document.createElement("a");
        const fileName = response.instance.filename;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    });
  }

  saveRating(rating)
  {
    // Récupération des paramètres
    const missionId = this.props.match.params.missionId;
    const missionStepId = this.props.match.params.missionStepId;
    const userId = this.props.match.params.userId;

    // Enregistrement de la note
    this.shipperService.missionUnloadingRating(this, missionId, missionStepId, userId, rating, (response) => {
        if (response.warnings != null && response.warnings.length > 0)
        {
            rating = response.rating;
        }

        this.setState({
            rating_user: response.user ? response.user.summary : null,
            comment: response.comment,
            rating: rating,
            missionId: missionId,
            missionStepId: missionStepId,
            userId: userId,
            shipperCompanyName: response.shipper_company_name,
            transportOperationId: response.transport_operation_id,
            shipperReference: response.shipper_reference,
            referenceMessage: StringHelper.translateWithHtml(this, "Rating.mission_step.shipper_reference_message", null, response)
        })
    })
  }

  ratingRequired()
  {
    var rating = this.props.match.params.rating;
    if (rating == "~")
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  onSubmitForm = e => {
    e.preventDefault()
    var formIsValid = this.htmlFormIsValid(e);
    if (formIsValid)
    {
      // Récupération des donées postée
      const formData = new FormData(e.target)
      const missionId = this.props.match.params.missionId;
      const missionStepId = this.props.match.params.missionStepId;
      const userId = this.props.match.params.userId;
      
      this.shipperService.missionUnloadingRatingComment(this, missionId, missionStepId, userId, this.commentInputRef.getValue(), (response) => {
        
        this.successNotification("Confirmation", 
        <Label>Votre commentaire à bien été pris en compte</Label>, "Fermer")
        
        // Mise à jour des données suite à l'enregistrement
        this.setState({
            rating_user: response.user ? response.user.summary : null,
            comment: response.comment,
            rating: response.rating
        })
      });
    }
  }

  renderFreightDocumentForDownload()
  {
    return <>
      <Separator></Separator>
      <Row className="t2y-html-translation-container justify-content-center">
        {this.state.referenceMessage}
        <div className="">
          <DefaultButton className="t2y-secondary-button small" icon={Resources.downloaded_file_logo} onClick={(e) => this.downloadFreightDocument(this)}>
              Télécharger
          </DefaultButton>
        </div>
      </Row>
    </>
  }

  renderManuallyRating()
  {
    return <>

      <div className="justify-content-center">
        <Title2 center={true}>{StringHelper.translate(this, 'Rating.title1')}</Title2>
      </div>
      <Row className="t2y-html-translation-container">
        {this.state.message}
      </Row>

      <table style={{width:"100%"}} className="mt-4 mb-4">
        <tbody>
        <tr height="10px" style={{fontSize:"9px",fontWeight:"normal", fontStyle:"italic", color:"gray"}}>
          <td colspan="3" align="left" style={{padding:"1%"}}><div>Médiocre</div></td>
          <td colspan="3" align="left" style={{padding:"1%"}}><div>Insuffisant</div></td>
          <td colspan="2" align="left" style={{padding:"1%"}}><div>Moyen</div></td>
          <td colspan="2" align="right" style={{padding:"1%"}}><div>Très bon</div></td>
        </tr>
        <tr style={{textAlign:"center", fontSize:"13px"}} /*style="color:#ffffff;font-family:Arial,sans-serif;font-size:13px;font-weight:normal;margin:0;text-decoration:none;text-transform:none;text-align:center"*/ height="20px">
          {
            ["#de322a", "#e8622f", "#ef8134", "#f7a43a", "#f2c13f", "#f0d039", "#d3d345", "#b0d963", "#89d57f", "#63d296"].map((background, index) => {
              return <td width="10%" >
                <div style={{cursor:"pointer", background:background, padding:"10px 2px", borderRadius:"3px", color:"#ffffff", width:"100%"}} onClick={(e) => this.saveRating(index + 1)}>
                  <span 
                    style={{color:"white", textDecoration:"none"}}  target="_blank" 
                    /*data-saferedirecturl="https://www.google.com/url?q=http://test.localhost:3000/auth/rating/1/unloading/SHIPPER1_YESTERDAY_ORDER0_M2/SHIPPER1_YESTERDAY_ORDER0_M2_S2/ROB_PAT_USER_ID&amp;source=gmail&amp;ust=1619253863805000&amp;usg=AFQjCNGktCFjIy0z8D2hx7LFBqYGeFbpZQ"*/
                  >
                      {index + 1}
                  </span>
                </div>
            </td>
            })
          }
          </tr>
          </tbody>
      </table>
      {this.renderFreightDocumentForDownload()}
      
    </>
  }

  renderRating()
  {
      return <>
        <div className="justify-content-center">
          <Title2 center={true}>{StringHelper.translate(this, 'Rating.title1')}</Title2>
        </div>
        <div className="pt-4 text-center">
            <h1 className=" title">
            {parseInt(this.state.rating) + "/10"}
            </h1>
        </div>
        <div className="justify-content-center">
        {
            (this.state.rating) ?
            (
                <>
                <Rating note={parseFloat(this.state.rating) / 2.0 } center={true} size="30px"></Rating>
                <br/>
                </>
            ) : 
            (
                <></>
            )
        }
        {
            (this.state.comment) ?
            (
                <>
                <blockquote className="blockquote text-center">
                <p className="mb-0">
                    {this.state.comment}
                </p>
                <footer className="blockquote-footer">
                    <cite title="Source Title">{this.state.rating_user} </cite>
                </footer>
                </blockquote>
                </>
            )
            :
            (
                <>
                    <Form onSubmit={e => this.onSubmitForm(e)} role="form">
                        <div className="text-center">
                            <Title3>{StringHelper.translate(this, 'Rating.title2')}</Title3>

                          <DefaultInput type="textarea" manager={this} childRef={(elt) => this.commentInputRef = elt} id="comment" rows="6" type="textarea" />
                        
                        <DefaultButton className="t2y-secondary-button small mt-4" type="submit">
                            {StringHelper.translate(this, 'Rating.save_comment')}
                        </DefaultButton>
                        </div>
                    </Form>
                </>
            )
        }

        </div>
        {this.renderFreightDocumentForDownload()}
        
      </>
  }

  render() {
    return (
      <>
        {this.renderParent()}
        
        <Container className="mt-1 pb-5">
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>

          <Row className="justify-content-center">
            <Col lg="7" md="5">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                    {
                        (this.state.rating) ?
                        (
                            this.renderRating()
                        ) : (
                          (this.ratingRequired()) ?
                          (
                            this.renderManuallyRating()
                          ) : (<></>)
                        )
                    }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(MissionStepRating);
