
import React from "react";
import classnames from "classnames";

// reactstrap components
import {
    Button,
    Container,
    Form,
    Table,
    FormGroup,
    Modal,
    UncontrolledPopover,
    UncontrolledTooltip,
    PopoverBody,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Alert,
    InputGroup,
    InputGroupAddon,
    InputGroupText
  } from "reactstrap";
import Resources from "../../../resources";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
import FormInput from '../components/FormInput'
import Title3 from '../../components/common/title3'
import DefaultPopover from '../../components/common/defaultPopover'
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormGroupInput from '../components/form/FormGroupInput'
import FormHourInput from '../components/form/FormHourInput'
import TransporterService from "../../../proxies/TransporterService"
import SecurityService from "../../../proxies/SecurityService"
import FormCivilityInput from "../components/form/FormCivilityInput";
import ReactDatetime from "react-datetime";
import { injectIntl , FormattedMessage } from 'react-intl';
import StringHelper from "../../../helpers/StringHelper";
import DefaultButton from "../../components/common/defaultButton";
import DefaultImage from "../../components/common/defaultImage";
import FormFooter from "../../components/form/formFooter";
import DateHelper from "../../../helpers/DateHelper";
import BlockTitle from "../../components/common/blockTitle";
import Message from "../../components/common/message";


var moment = require('moment');
require('moment/locale/fr');

class CommercialRules extends FormValidationComponent {


    constructor(props)
    {
        super(props)
        this.transporterService = new TransporterService();
        this.securityService = new SecurityService();

        this.validationRules = {
            booking_limit_time: {
                required: false
            }

        }

        this.state = {
            loading : false,
            bookingDaysLimitIsChecked:  false,
            automaticValidationIsChecked: false,
            trucks: [],
            drivers: [],
            transporters: [],
            trucks_drivers: [],
            eventModalIsOpen: false,
            date : moment(),
            nbDays: this.getDaysInMonth(moment()),
            formDatas : {
                booking_limit_time: { value: moment("2020-01-02 " + "17:00:00")},
            },
            defaultAvailability: true
        }
    }

    getDaysInMonth(selectedDate)
    {
        return new Date(selectedDate.year(), selectedDate.month() + 1, 0).getDate();   
    }

    componentWillMount()
    {
        // Récupération des règles de planning
        this.transporterService.getCommercialRules(this, (response => {
            this.bindData(response);
        }));
    }

    bindData(response)
    {
        // Gestion des règles
        const rules = response.instance;
       

        const automaticValidationIsChecked = rules.automatic_validation;
        const bookingDaysLimitIsChecked = rules.use_booking_limit;
        const bookingDaysLimit = rules.booking_days_limit;
        const bookingLimitTime = rules.booking_limit_time;
        const defaultAvailability = rules.trucks_default_availabilities;


        // Gestion de la disponibilité des camions
        var formDatas = {
            booking_limit_time: { value: bookingLimitTime? bookingLimitTime.substring(0, 5) : null},
            booking_days_limit: { value: bookingDaysLimit }
        }


        // Gestion de la disponibilité des conducteurs
        document.getElementById("ckbmonday").checked = rules.open_monday;
        document.getElementById("ckbtuesday").checked = rules.open_tuesday;
        document.getElementById("ckbwednesday").checked = rules.open_wednesday;
        document.getElementById("ckbthursday").checked = rules.open_thursday;
        document.getElementById("ckbfriday").checked = rules.open_friday;
        document.getElementById("ckbsaturday").checked = rules.open_saturday;
        document.getElementById("ckbsunday").checked = rules.open_sunday;

        this.setState({
            formDatas: formDatas, 
            loading: false,
            bookingDaysLimitIsChecked: bookingDaysLimitIsChecked,
            automaticValidationIsChecked: automaticValidationIsChecked,
            defaultAvailability: defaultAvailability
        })
    }

    automaticValidationChecked(newState)
    {
        this.setState({automaticValidationIsChecked: newState})
    }

    bookingDaysLimitChecked(newState)
    {
        this.setState({bookingDaysLimitIsChecked: newState})

        this.validationRules.booking_limit_time.required = newState
    }

    checkAlert(confirmCallback)
    {
        var alerts = [];

        if (this.state.defaultAvailability == true)
        {
            alerts.push({
                label: 'Vous avez choisi gestion de vos disponibilités en mode "disponible par défaut"',
                subLabel: "Vous devrez tenir à jour votre planning en fermant vos indisponibilités dans le planning pour qu'elles ne soient pas proposées sur la plate-forme."
            })
        }

        if (this.state.defaultAvailability == false)
        {
            alerts.push({
                label: 'Vous avez choisi gestion de vos disponibilités en mode "inddisponible par défaut"',
                subLabel: "Vous devrez tenir à jour votre planning en ouvrant vos disponibilités dans le planning pour qu'elles soient proposées sur la plate-forme."
            })
        }

        if (this.state.automaticValidationIsChecked)
        {
            alerts.push({
                label: 'Vous avez choisi la validation des commandes en "réservation instantanée"',
                subLabel: "Bravo : c'est le mode qui correspond le mieux aux attentes des expéditeurs ! Ce fonctionnement vous engage à réaliser chaque commande, aussi vous devez tenir vos disponibilités à jour avec rigueur."
            })
        }

        if (this.state.automaticValidationIsChecked == false)
        {
            alerts.push({
                label: 'Vous avez choisi la "validation manuelle" de vos commandes',
                subLabel: "Attention : ce mode ne nécessite pas de tenir à jour vos disponibilité, mais il répond moins bien aux attentes des expéditeurs, d'une part, et vous serez mis en concurrence avec d'autres transporteurs à chaque demande : le 1er transporteur qui accepte la mission l'emporte."
            })
        }

        if (alerts.length == 0)
        {
            confirmCallback()
        }
        else
        {
            this.showModalConfirmation({
                hideIcon: true,
                headerComponent: <Title3>Votre politique commerciale - attention</Title3>,
                bodyClassName: "text-left",
                okButtonIcon: Resources.button_save_icon,
                okButtonTitle:  "J'ai compris",    
                cancelButtonTitle:  "Je modifie mes choix",    
                component:  <>
                    <div className="text-justify">Vos choix de politique commerciale requièrent votre attention&nbsp;:</div>
                    <ul>
                    {
                        alerts.map((a, index) => {
                            return <>
                                <li>
                                <div className="mt-2 mb-2 text-justify">
                                <strong>{a.label}</strong><br/>
                                <em style={{fontStyle:"initial"}}>{a.subLabel}</em>
                                </div>
                                </li>
                            </>
                        })
                    }
                    </ul>
                </>,
                okButtonCallback: () => {
                  confirmCallback()
                }
            })
        }
    }

    onSubmitForm(e)
    {
        e.preventDefault();
        var formIsValid = this.htmlFormIsValid(e);
        

        if (formIsValid)
        {
            this.checkAlert( () => {
                // Enregistrement des règles de disponibilités
                var postedData = {
                    open_monday: document.getElementById("ckbmonday").checked,
                    open_tuesday: document.getElementById("ckbtuesday").checked,
                    open_wednesday: document.getElementById("ckbwednesday").checked,
                    open_thursday: document.getElementById("ckbthursday").checked,
                    open_friday: document.getElementById("ckbfriday").checked,
                    open_saturday: document.getElementById("ckbsaturday").checked,
                    open_sunday: document.getElementById("ckbsunday").checked,
                    
                    trucks_default_availabilities: this.state.defaultAvailability,
                    automatic_validation: this.state.automaticValidationIsChecked,
                    use_booking_limit: this.state.bookingDaysLimitIsChecked,
                    booking_days_limit: document.getElementById("booking_days_limit") != null ? document.getElementById("booking_days_limit").value : null,
                    booking_limit_time: document.getElementById("booking_limit_time") != null ? document.getElementById("booking_limit_time").value : null

                }

                this.transporterService.savePlanningRules(this, postedData, (response) => {
                    // Mise à jour des données 
                    var company = this.securityService.getCompany();
                    company.configuration_last_modification_date = DateHelper.now();
                    this.securityService.storeCompany(company)

                    this.confirmMessage("Les données ont été enregistrées avec succès")
                });
            })
        }
        else
        {
            this.scrollToFirstError()
        }
    }

    renderDayCheckbox(day)
    {
        var fieldName = "ckb" + day
        return <div className="custom-control custom-checkbox custom-control-inline">
            <input
                className="custom-control-input"
                id={fieldName}
                type="checkbox"
            />
            <label
                className="custom-control-label t2y-height-auto"
                htmlFor={fieldName}
            >
                <FormattedMessage id={"Default." + day} />
            </label>
        </div>
    }

    renderDaysOfWeek()
    {
        return <Row>
            <Col lg="12">Jours ouvrés</Col>
            <Col xl="1" lg="4" className="mr-3">{ this.renderDayCheckbox('monday')}</Col>
            <Col xl="1" lg="4" className="mr-3"> { this.renderDayCheckbox('tuesday')}</Col>
            <Col xl="1" lg="4" className="mr-3">{ this.renderDayCheckbox('wednesday')}</Col>
            <Col xl="1" lg="4" className="mr-3">{ this.renderDayCheckbox('thursday')}</Col>
            <Col xl="1" lg="4" className="mr-3">{ this.renderDayCheckbox('friday')}</Col>
            <Col xl="1" lg="4" className="mr-3">{ this.renderDayCheckbox('saturday')}</Col>
            <Col xl="1" lg="4"> { this.renderDayCheckbox('sunday')}</Col>
        </Row>
    }

    renderDefaultAvailability()
    {
        return <>
            <Row>
                <Col xs="12" className="mb-2">Disponibilité par défaut de vos véhicules</Col>
                <Col xs="12" >
                    <Row >
                        <Col lg="12">
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    className="custom-control-input"
                                    id="defaultAvailable"
                                    name="customRadioInline1"
                                    type="radio"
                                    checked={this.state.defaultAvailability == true}
                                    onChange={() => this.setState({defaultAvailability: true})}
                                />
                                <label
                                    className="custom-control-label t2y-height-auto"
                                    htmlFor="defaultAvailable"
                                    style={{height:"auto !important"}}
                                >
                                    <strong>Disponible par défaut</strong>
                                    <br/>
                                    <em>Vos véhicules sont disponibles, vous devrez fermer chaque date où ils sont indisponibles</em>
                                </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    className="custom-control-input"
                                    id="defaultNotAvailable"
                                    name="customRadioInline1"
                                    type="radio"
                                    checked={ this.state.defaultAvailability == false}
                                    onChange={() => this.setState({defaultAvailability: false})}
                                />
                                <label
                                    className="custom-control-label t2y-height-auto"
                                    htmlFor="defaultNotAvailable"
                                    style={{height:"auto !important"}}
                                >
                                    <strong>Indisponible par défaut</strong>
                                    <br/>
                                    <em>Vos véhicules sont indisponibles, vous devrez ouvrir chaque date où ils sont disponibles</em>
                                </label>
                                </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    }

    renderAvailabilityLimit()
    {
        return <Row>
            <Col xs="12" className="mb-2">
                Préavis de commande 
                <DefaultPopover content="Délai minimum entre une commande et la mise à disposition du véhicule et de son conducteur : ce délai doit correspondre à votre organisation."></DefaultPopover>
            </Col>
            <Col xs="12">
                <Row>
                    <Col lg="12">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                className="custom-control-input"
                                id="noAvailabilityLimit"
                                name="availableLimitRule"
                                type="radio"
                                checked={ !this.state.bookingDaysLimitIsChecked}
                                onChange={() => this.bookingDaysLimitChecked(false)}
                            />
                            <label
                                className="custom-control-label t2y-height-auto"
                                htmlFor="noAvailabilityLimit"
                            >
                                <Row>
                                    <Col xs="12">
                                        <strong>Pas de préavis</strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <em>Vous acceptez les commandes à tout moment, y compris au dernier moment</em>
                                    </Col>
                                </Row>
                            </label>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                className="custom-control-input"
                                id="availabilityLimit"
                                name="availableLimitRule"
                                type="radio"
                                checked={ this.state.bookingDaysLimitIsChecked}
                                onChange={() => this.bookingDaysLimitChecked(true)}
                            />
                            <label
                                className="custom-control-label t2y-height-auto"
                                htmlFor="availabilityLimit"
                                style={{height:"auto !important"}}
                            >
                                <Row>
                                    <Col xs="12">
                                        <strong>Avec un préavis</strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <em>Vous acceptez les commandes au plus tard dans le délai défini ci-dessous</em>
                                    </Col>
                                </Row>
                            </label>
                        </div>
                    </Col>
                    <Col lg="12" className="mt-4 mb-4">
                        <Row>
                    {
                        (this.state.bookingDaysLimitIsChecked) ?
                        (
                            <>
                                <Col lg="5" className="mb-3">
                                <Input id="booking_days_limit" manager={this} type="select" defaultValue={this.state.formDatas.booking_days_limit.value}>
                                    <option value='0'>{this.translateMessage("Default.day0")}</option>
                                    <option value='-1'>{this.translateMessage("Default.day-1")}</option>
                                    <option value='-2'>{this.translateMessage("Default.day-2")}</option>
                                </Input>
                                </Col>
                                <Col lg="6">
                                    <Row>
                                        <Col xs="1" style={{marginTop:"10px"}}>
                                            à
                                        </Col>
                                        <Col xs="11" md="10">
                                            <FormHourInput name="booking_limit_time" manager={this} />  
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        ) : ("")
                    }
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    }

    renderAvailabilities()
    {
        return <>
        <Row>
            <Col lg="8">
                <Card>
                    <CardHeader>
                        <BlockTitle iconSrc="fas fa-calendar-alt"
                            title="DISPONIBILITÉS"
                            subTitle= "Indiquez comment vous gérez votre planning"
                            alertMessage={null}
                        />
                    </CardHeader>
                    <CardBody>
                        {this.renderDaysOfWeek()}
                        <hr/>
                        {this.renderDefaultAvailability()}
                    </CardBody>
                </Card>
            </Col>
            <Col xs="4" className={"d-none d-lg-block"}>
                <Message type="infos">Ce choix est important pour que vos disponibilités soient conformes à la réalité</Message>
            </Col>
        </Row>
        </>

    }

    renderAutomaticValidation()
    {
        return <><Row>
            <Col xs="12" className="mb-2">Mode de validation des commandes</Col>
            <Col xs="12">
                <Row>
                    <Col lg="12">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                className="custom-control-input"
                                id="withAutomaticValidation"
                                name="automaticValidation"
                                type="radio"
                                checked={this.state.automaticValidationIsChecked}
                                onChange={() => this.automaticValidationChecked(true)}
                            />
                            <label
                                className="text-justify custom-control-label t2y-height-auto"
                                htmlFor="withAutomaticValidation"
                                style={{height:"auto !important"}}
                            >
                                <strong>Réservation instantanée</strong>
                                <br/>
                                <em>Truck2You propose vos disponibilités, ce qui permet à l'expéditeur de passer commande immédiatement. Vous avez l'exclusivité de chaque commande, mais vos disponibilités doivent être tenues à jour.</em>
                            </label>
                            </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                className="custom-control-input"
                                id="noAutomaticValidation"
                                name="automaticValidation"
                                type="radio"
                                checked={!this.state.automaticValidationIsChecked}
                                onChange={() => this.automaticValidationChecked(false)}
                            />
                            <label
                                className="text-justify custom-control-label t2y-height-auto"
                                htmlFor="noAutomaticValidation"
                                style={{height:"auto !important"}}
                            >
                                <strong>Validation manuelle</strong>
                                <br/>
                                <em>Lorsqu'un expéditeur est intéressé, nous mettons en concurrence les transporteurs par e-mail / SMS : le 1er transporteur qui accepte la mission emporte la commande.</em>
                            </label>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    }

    renderOther()
    {
        return <>
        <Row>
            <Col lg="8">
                <Card>
                <CardHeader>
                    <BlockTitle iconSrc="fas fa-business-time"
                        title="LA FAÇON D'ACCEPTER VOS COMMANDES"
                        subTitle= "Définissez votre organisation pour recevoir des commandes"
                        alertMessage={null}
                    />
                </CardHeader>
                <CardBody>
                    {this.renderAvailabilityLimit()}
                    {this.renderAutomaticValidation()}
                </CardBody>
                </Card>
            </Col>
            <Col xs="4" className={"d-none d-lg-block"}>
                <Message type="infos">Ce choix vous permet d'ajuster le fonctionnement de Truck2You à votre organisation</Message>
            </Col>
        </Row>
        </>
    }

    
    render() {
        return  (
            <>
            {this.renderParent()}
            <Container className="mt--4" fluid>
                <Form className="needs-validation" noValidate onSubmit={e => this.onSubmitForm(e)} >
                    <Row>
                        <Col className="order-xl-1" xl="12">
                        <FormLoadingIndicator loading={this.state.loading}>
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center ml-3">
                                    <Col className="text-left" xs="6">
                                        <Title3>
                                            <DefaultImage src={Resources.menu_billing_logo}></DefaultImage>&nbsp;
                                            {StringHelper.translate(this, "Default.menu_commercial_rules")}
                                        </Title3>
                                    </Col>
                                    {/* <Col className="text-right" xs="6"><DefaultButton type="submit" icon={Resources.button_save_icon} className="t2y-secondary-button small"> <FormattedMessage id="Default.save" /></DefaultButton></Col> */}
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.renderAvailabilities()}
                                {this.renderOther()}
                                        
                            </CardBody>
                            <CardFooter>
                                <FormFooter></FormFooter>
                            </CardFooter>
                        </Card>
                        </FormLoadingIndicator>
                        </Col>

                        </Row>
                    </Form>
                


                
                </Container>
          </>
        );
    }
}


export default injectIntl(CommercialRules)