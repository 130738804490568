
import React from "react";
import classnames from "classnames";
import PropTypes from 'prop-types';
import $ from "jquery";

// reactstrap components
import Title2 from "../common/title2"
import Title3 from "../common/title3"
import Title4 from '../common/title4'
import Title5 from '../common/title5'
import PortalTruckSearchInput from "../search/portalTruckSearchInput"



import {
    Row,
    Col,
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    NavLink,
    Modal,
    Button,
    Container
  } from "reactstrap";
import { Calendar } from 'react-date-range';

import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator"
import FormValidationComponent from "../../pages/components/FormValidationComponent";

import { injectIntl , FormattedMessage } from 'react-intl';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import PortalDateSearchInput from "./portalDateSearchInput";
import PortalAddressSearchInput from "./portalAddressSearchInput";
import SearchService from "../../../proxies/SearchService"
import ReactBSAlert from "react-bootstrap-sweetalert";
import DefaultButton from "../common/defaultButton";
import Resources from "../../../resources";
import SecurityService from "../../../proxies/SecurityService";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import Offer from "../../../V2/Page/Search/Offer";

var moment = require('moment');
require('moment/locale/fr');

class PortalSearchForm extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.childs = []
        this.securityService = new SecurityService();
        this.searchService = new SearchService();
        this.state = {
            mode: 'public',
            forceRedirect: (this.isTruck2YouWebSiteIframe() && this.securityService.isConnected())
        }
    }

    componentWillMount()
    {
      if (!this.props.embeded && !this.props.body_safe)
      {
        document.body.className = 'g-sidenav-show g-sidenav-pinned t2y-search-form-body';
      }
    }

    componentDidMount()
    {
      if (this.props.childRef)
      {
        this.props.childRef(this)
      }
    }

    componentWillReceiveProps()
    {
    }

    isTruck2YouWebSiteIframe()
    {
      return !this.props.embeded && !this.props.body_safe
    }

    setRequest(request)
    {
      this.truckComponentRef.setValues(request)
      this.dateComponentRef.setValues(request)
      this.addressComponentRef.setValues(request.address)
    }

    redirectToResults(response)
    {
      // Vérification du nombre de résultats pour la recherche stricte
      var resultUrl = '/public/search-result/' + response.search_id;
      var target = "_blank";
      if (this.securityService.isConnected())
      {
        target = "_self";
        resultUrl = '/admin/search-results/' + response.search_id;
      }

      if (this.props.embeded)
      {
        WebsiteHelper.goTo(this, resultUrl);
      }
      else
      {
        //window.open(resultUrl, target);
        if (window.parent) { // checking if it is an iframe
          window.parent.location.href = resultUrl;
        } else {
          window.location.href = resultUrl;
        }
      }
    }

    getSearchSummary()
    {
      var truckSummary = (this.truckComponentRef ? this.truckComponentRef.getSummary() : null)

      return truckSummary;
    }

    isEmbeded()
    {
      var isEmbeded = !this.props.embeded ? false : this.props.embeded;
      return isEmbeded;
    }

    validateInput(input)
    {
      if (this.isEmbeded())
      {
        this.search()
      }
    }

    search()
    {
      var truckValues = this.truckComponentRef.getValues();
      var dateValues = this.dateComponentRef.getValues();
      var addressValues = this.addressComponentRef.getValues();

      // Vérification des données obligatoires
      if (truckValues.length == 0 || dateValues.dates.length == 0 || addressValues == null)
      {
        alert("Merci de renseigner tous les champs")
      }
      else
      {
        // Lancement de la recherche
        this.searchService.search(this, false, truckValues, dateValues, addressValues, null, (strictResponse) => {
          // Si la recherche n'est pas autorisée (département, domaine, ...)
          if (strictResponse.allowed == false)
          {
            // Si l'utilisateur n'est pas connecté, on le redirige sur le formulaire CRM
            if (strictResponse.redirect_url)
            {
              //WebsiteHelper.goTo(this, strictResponse.redirect_url, true)
              if (window.parent) { // checking if it is an iframe
                window.parent.location.href = strictResponse.redirect_url;
              } else {
                window.location.href = strictResponse.redirect_url;
              }
            }
            else // Redirection vers l'écran des résultat qui affichera un message pour la recherche non autorisée
            {
              this.redirectToResults(strictResponse)  
            }
          }
          else 
          {
            if (strictResponse.nb_results > 0)
            {
              this.redirectToResults(strictResponse)  
            }
            else {
              /*if (this.props.embeded)
              {
                this.warning("Nous élargissons la recherche ...")
              }*/
              this.searchService.search(this, true, truckValues, dateValues, addressValues, strictResponse.search_id, (extendedResponse) => {
                this.redirectToResults(extendedResponse)  
              })
            }
          }
        });
      }
    }

    warning(message) 
    {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Warning"
            confirmBtnBsStyle="warning"
            confirmBtnText="Fermer"
            btnSize=""
          >
            {message}
          </ReactBSAlert>
        )
      });
    }

    renderTitle()
    {
      var isMobile = WebsiteHelper.isPortraitMobileWidth()
      return <>
        {
          (this.props.embeded) ?
          (
            <>
              <Row>
                <Col xl="12">
                    <Title4><FormattedMessage id="Search.embeded_form_title"/></Title4>
                </Col>
              </Row>
              
            </>
          ) :
          (
            (isMobile) ?
            (
<>
              <Row>
                <Col xl="12">
                    <Title4 className="t2y-title2-25px" content="Prêts pour une livraison?"></Title4>
                </Col>
              </Row>
              <Row>
                  <Col xl="12">
                    <Title5 style={{fontSize:"15px"}} content="Trouvez votre camion avec conducteur en quelques secondes."></Title5>
                  </Col>
              </Row>
              </>
            ) : 
            (
              <>
              <Row>
                <Col xl="12">
                    <Title2 className="t2y-title2-25px" content="Prêts pour une livraison?"></Title2>
                </Col>
              </Row>
              <Row>
                  <Col xl="12">
                    <Title3 content="Trouvez votre camion avec conducteur en quelques secondes."></Title3>
                  </Col>
              </Row>
              </>
            )
          )
        }
        
      </>
    }

    renderForm()
    {
      var isMobile = WebsiteHelper.isPortraitMobileWidth()
      var isPublic = !this.props.embeded ? (this.props.public == null ? true : this.props.public) : (this.securityService.isConnected() ? false : true)
      return <>
        <Row className="mb-3">
          <Col xl="12">
            <PortalTruckSearchInput portal={this.props.portal} manager={this} embeded={this.isEmbeded()} public={isPublic} modal_className={this.props.modal_className} childRef={elt => this.truckComponentRef = elt } backgroundColor={"white"} textColor={"#1e2635"} iconColor={"#FFC42B"}/>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xl="12">
            <PortalDateSearchInput portal={this.props.portal} manager={this} public={isPublic} modal_className={this.props.modal_className} childRef={elt => this.dateComponentRef = elt }backgroundColor={"white"} textColor={"#1e2635"} iconColor={"#FFC42B"}/>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xl="12">
            <PortalAddressSearchInput portal={this.props.portal} manager={this} public={isPublic} modal_className={this.props.modal_className} childRef={elt => this.addressComponentRef = elt }backgroundColor={"white"} textColor={"#1e2635"} iconColor={"#FFC42B"}/>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xl="12" className="text-right">
            <DefaultButton onClick={(e) => {this.search()}} 
              icon={this.props.embeded ? require("../../../assets/img/icons/search-button-loupe-white.svg").default : require("../../../assets/img/icons/search-button-loupe.svg").default} 
              className={ this.props.embeded ? "t2y-thirdparty-button small" : "t2y-secondary-button small"}>
              <FormattedMessage id="Search.search_transporter_title"></FormattedMessage>
            </DefaultButton>
          </Col>
          
        </Row>
      </>
    }

    renderAlreadyConnected()
    {
      const user = this.securityService.getUser()
      const userLabel = user.summary;
      const company = this.securityService.getCompany().company_name;

      return <>
        <Row className="align-items-center justify-content-center">
          <img src={require('../../../assets/img/illustrations/already_connected.svg').default} style={{maxWidth:"30%"}} />
        </Row>
        <Row className=" text-center align-items-center justify-content-center">
          <Col xs="12" className="mb-2 mt-2">
            Vous êtes connecté en tant que <strong>{userLabel} - {company}</strong>
          </Col>
          <Col xs="12">
            <DefaultButton className="t2y-secondary-button small" onClick={e => WebsiteHelper.goToHome(this, true)}>
              {user.is_shipper ? "Accéder à mon espace expéditeur" : "Accéder à mon espace transporteur"}
            </DefaultButton>
          </Col>
        </Row>
      </>
    }

    render() {
        if(this.props.version === 2) {
            return <Offer isConnected={this.securityService.getUser() !== null } />
        }
      if (this.state.forceRedirect)
      {
        return this.renderAlreadyConnected()
      }
      else
      {
        return  (
            <>
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
            {
              (this.props.embeded) ?
              ( 
                this.state.alert
              ): (<></>)
            }
            <Container className={"t2y-search-form " + (this.props.embeded ? "t2y-search-form-embeded" : "") } style={{backgroundColor:"white", height:"100%", ...this.props.style}}>
              {this.renderTitle()}
              {this.renderForm()}
            </Container>
            </>
        );
      }
    }
}

PortalSearchForm.propTypes = {
  embeded: PropTypes.bool
};

export default injectIntl(PortalSearchForm)


