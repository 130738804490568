import React from "react";

import {
    Row,
    Col,
    Button,
    Table,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import ShipperService from "../../../proxies/ShipperService";
import DefaultImage from "../common/defaultImage";
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import Resources from "../../../resources";
import StringHelper from "../../../helpers/StringHelper";
import ArrayHelper from "../../../helpers/ArrayHelper";
import EmptyResult from "../common/emptyResult";
import DeleteButton from "../common/deleteButton";
import LoadingContainer from "../common/loadingContainer";
import Config from "../../../config";
import DefaultButton from "../common/defaultButton";
import RepositoryDataComponent from "../../pages/components/RepositoryDataComponent";
import Gallery from "react-grid-gallery";
import BusinessService from "../../../proxies/BusinessService";


var moment = require('moment');
require('moment/locale/fr');

class StepsAsRoadMap extends FormValidationComponent {

    constructor(props) {
        super(props)
        this.businessService = new BusinessService();

        this.state = {
            loading: false,
            steps: this.props.steps
        }
    }

    componentWillMount() {
        this.refresh(false)
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    canDelete(step, stepIndex, nbSteps)
    {
        return !step.readonly
    }


    canEdit(step, stepIndex, nbSteps)
    {
        return !step.readonly
    }

    canAdd(step, stepIndex, nbSteps)
    {
        return (stepIndex + 1) < nbSteps;
    }

    canMoveUp(step, stepIndex, nbSteps)
    {
        return step.readonly == false && stepIndex >= 2
    }

    canMoveDown(step, stepIndex, nbSteps)
    {
        return step.readonly == false && stepIndex < (nbSteps - 2)
    }

    moveUp(event, missionStepId)
    {
        event.preventDefault();
        this.businessService.moveUpMissionStep(this, missionStepId, (response) => {
            this.refresh(true);
            this.showSuccessToastNotification("Etape déplacée avec succès");
        })
    }

    moveDown(event, missionStepId)
    {
        event.preventDefault();
        this.businessService.moveDownMissionStep(this, missionStepId, (response) => {
            this.refresh(true);
            this.showSuccessToastNotification("Etape déplacée avec succès");
        })
    }

    refresh(force = true)
    {
        if (force)
        {
            this.businessService.getMission(this, this.props.mission.id, (response) => {
                this.setState({steps: response.steps})
            })
        }
    }

    renderSteps()
    {   
        /* Affichage des étapes */
        var timelineDatas = "";
    
        if (this.state.steps)
        {
            var nbSteps = this.state.steps.length;
            var previousStep = null;
            timelineDatas = this.state.steps.map((step, stepIndex) => {
                if (stepIndex > 0)
                {
                    previousStep = this.state.steps[stepIndex - 1];
                }

                var time = null;
                var title = null;
                var description= null;
                var images = [];
                var thumbnailWidth = 300;
                var thumbnailHeight = 200;
                
                if (step.type.code == "MISSION_STEP_TYPE_GET_TRUCK")
                {
                    title = "Adresse départ du camion (non modifiable)";
                }
                else if (step.type.code == "MISSION_STEP_TYPE_PARK_TRUCK")
                {
                    title = "Adresse de dépôt du camion (non modifiable)";
                }
                else
                {
                    title = step.address_summary
                    description = step.description
                }
                
                if (previousStep != null && previousStep.type.code  == "MISSION_STEP_TYPE_LOADING" && previousStep.type.code == step.type.code && previousStep.address_summary == step.address_summary)
                {
                    return <></>
                }
                

                return <div className="timeline-block" key={"step" + stepIndex}>
                    <span className="timeline-step badge-secondary">
                        <RepositoryDataComponent data={step.type} mode={"icon"} ></RepositoryDataComponent>
                    </span>
                    <div className="timeline-content">
                        {/*
                            (step.arrival_time) ?
                            (
                                <small className="font-weight-bold text-danger">
                                        <i className="fas fa-clock mr-1" />{step.arrival_time}
                                </small>
                            ) : ("")
                        */}
                        <div className="d-flex justify-content-between pt-1">
                            <div>
                                <span className="text-muted text-sm font-weight-bold">
                                {title}
                                </span>
                            </div>
                            {
                                // Pas d'action sur la dernière étape si elle est en lecture seule
                                (!this.props.readonly && (step.readonly == false || (stepIndex > 0 && stepIndex < nbSteps - 1))) ?
                                (
                                    <UncontrolledDropdown>
                                        <DropdownToggle size="sm" color="neutral" className="mr-0 pull-right">
                                        <i className="fas fa-ellipsis-h" />
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            { 
                                                /*(this.canEdit(step, stepIndex, nbSteps)) ?
                                                (
                                                    <DropdownItem href="#pablo" onClick={e => {
                                                        e.preventDefault()
                                                        this.setState({stepToEdit: step, sourceStep: null})
                                                        this.toggleMissionStepModal(e)
                                                    }}>
                                                        <i className="fas fa-edit"></i>
                                                        Modifier
                                                    </DropdownItem>
                                                ) : ("")*/
                                            }
                                            { 
                                                /*
                                                (this.canAdd(step, stepIndex, nbSteps)) ?
                                                (
                                                    <>
                                                        <DropdownItem href="#pablo" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({sourceStep: step, stepToEdit:null, type: "MISSION_STEP_TYPE_LOADING"})
                                                            this.toggleMissionStepModal(e)
                                                        }}>
                                                            <i className="fas fa-clone"></i>
                                                            Ajouter une étape de chargement à partir d'ici
                                                        </DropdownItem>
                                                        <DropdownItem href="#pablo" onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({sourceStep: step, stepToEdit:null, type: 'MISSION_STEP_TYPE_UNLOADING'})
                                                            this.toggleMissionStepModal(e)
                                                        }}>
                                                            <i className="fas fa-clone"></i>
                                                            Ajouter une étape de déchargement à partir d'ici
                                                        </DropdownItem>
                                                    </>
                                                ) : ("")*/
                                            }
                                            { 
                                                (this.canMoveUp(step, stepIndex, nbSteps)) ?
                                                (
                                                    <DropdownItem href="#pablo" onClick={e => this.moveUp(e, step.id)}>
                                                        <i className="fas fa-chevron-up"></i>
                                                        Déplacer vers le haut
                                                    </DropdownItem>
                                                ) : ("")
                                            }
                                            { 
                                                (this.canMoveDown(step, stepIndex, nbSteps)) ?
                                                (
                                                    <DropdownItem href="#pablo" onClick={e => this.moveDown(e, step.id)}>
                                                        <i className="fas fa-chevron-down"></i>
                                                        Déplacer vers le bas
                                                    </DropdownItem>
                                                ) : ("")
                                            }
                                            { 
                                                /*
                                                (this.canDelete(step, stepIndex, nbSteps)) ?
                                                (
                                                    <div>
                                                    <div className="dropdown-divider"></div>
                                                    <DropdownItem href="#pablo" onClick={e => {
                                                        e.preventDefault()
                                                        this.setState({stepToDelete: step})
                                                        this.toggleDeleteModal(e)
                                                    }}>
                                                        <i className="fas fa-trash-alt"></i>
                                                        Supprimer
                                                    </DropdownItem>
                                                    </div>
                                                ) : ("")*/
                                            }
                                            
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                ) : ("")
                            }
                        </div>
                        {/*
                        <h6 className="text-sm mt-1 mb-0">
                            {description}
                        </h6>
                        <div className="mt-3">
                                {
                                    (step.driver_start_date != null && step.type.code != "MISSION_STEP_TYPE_GET_TRUCK") ?
                                    (
                                        <Row>
                                            <span className="h4 font-weight-bold mb-0">
                                                <i className={Resources.start_logo}>&nbsp;</i>
                                            </span>
                                            {step.driver_start_time_as_string} 
                                        </Row>
                                    ) : (<></>)
                                }
                                {
                                    (step.driver_arrived_date != null && step.type.code != "MISSION_STEP_TYPE_GET_TRUCK") ?
                                    (
                                        <Row>
                                            <span className="h4 font-weight-bold mb-0">
                                                <i className={Resources.arrived_logo}>&nbsp;</i>
                                            </span>
                                            {step.driver_arrived_time_as_string} 
                                        </Row>
                                    ) : (<></>)
                                }
                                {
                                    (step.driver_end_date != null  && step.type.code != "MISSION_STEP_TYPE_PARK_TRUCK") ?
                                    (
                                        <Row>
                                            <span className="h4 font-weight-bold mb-0">
                                                <i className={Resources.stop_logo}>&nbsp;</i>
                                            </span>
                                            {step.driver_end_time_as_string} 
                                        </Row>
                                    ) : (<></>)
                                }
                                {
                                    (step.driver_end_comment != null && step.type.code != "MISSION_STEP_TYPE_GET_TRUCK") ?
                                    (
                                        <>
                                        <Row>
                                            <span className="h4 font-weight-bold mb-0 mt-2">
                                                <i className={Resources.driver_logo}>&nbsp;</i>
                                                Commentaire du chauffeur
                                            </span>
                                            
                                        </Row>
										<Row>
										{step.driver_end_comment} 
										</Row>
										</>
                                    ) : (<></>)
                                }
                                {
                                    (step.driver_pictures != null && step.driver_pictures.length > 0 && step.type.code != "MISSION_STEP_TYPE_GET_TRUCK") ?
                                    (
                                        
                                        <>
                                        <Row>
                                            <span className="h4 font-weight-bold mb-0 mt-2">
                                                <i className={Resources.proof_logo}>&nbsp;</i>
                                                Image(s) prise par le chauffeur ({step.driver_pictures.length})
                                            </span>
                                        </Row>
                                        <Gallery
                                        images={images}
                                        enableLightbox={true}
                                        enableImageSelection={false}/>
                                        </>
                                    ) : (<></>)
                                }
                                
                        </div>
                            */}
                    </div>
                </div>
            })
        }
        return <>
            <Card>
                
                <CardBody>

                    <CardTitle>
                        <Row className="align-items-center">
                            <Col xs="8">
                            <h3 className="mb-0">Détail des étapes de la mission</h3>
                            </Col>
                        </Row>
                    </CardTitle>
                    <div
                        className="timeline timeline-one-side"
                        data-timeline-axis-style="dashed"
                        data-timeline-content="axis"
                    >
                        {timelineDatas}
                    </div>
                </CardBody>
            </Card>
        </>


    }

    render() {
        return (
            <>
                {this.renderParent()}
                <LoadingContainer loading={this.state.loading}>
                {
                    (this.state.loading) ? 
                    (
                        <></>
                    ):
                    (
                        this.renderSteps()
                    )
                }
                </LoadingContainer>
            </>
        );
    }
}


export default injectIntl(StepsAsRoadMap)

