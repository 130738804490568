/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import queryString from 'query-string';
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import { injectIntl , FormattedMessage } from 'react-intl';

import AccountWorkflow from "../../components/workflow/account/accountWorkflow.jsx";
import Title2 from '../../components/common/title2';
import Title3 from '../../components/common/title3';

import DefaultButton from "../../components/common/defaultButton.jsx";

class RedirectToProfile extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    
    this.state = {
        loading : false,
        success: false,
        seconds : 5
    }
  }
  
  componentDidMount() {
    this.mounted = true;

    setInterval(function() {
        var currentSeconds = this.state.seconds - 1;
        this.setState(state => ({
          seconds: currentSeconds
        }));

        if (currentSeconds <= 0)
        {
          this.goToAccount();
        }

      }.bind(this), 1000);
    
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  
  goToAccount()
  {
    var resultUrl = '/admin/profile';
    window.parent.location.href = resultUrl;
  }
  
    render() {
        return <>
            <Container>
                <Row>
                       
                    <Col xs="12" className="text-center justify-content-text-center order-lg-2 order-1">
                        <img src={require('../../../assets/img/illustrations/redirect_to_profile.svg').default} style={{width: "50%"}} />
                    </Col>
               
                </Row>
                <hr/>
                <Row Row className="align-item-center justify-content-center">
                    <Title3 content={"Vous allez être redirigé automatiquement vers votre espace dans " + this.state.seconds + " seconde(s)"}></Title3>
                </Row>

                      <hr />  
                <Row Row className="align-item-center justify-content-center">
                    <DefaultButton onClick={() => this.goToAccount()} label={"Accéder directement à mon espace" } className="t2y-secondary-button" />
                </Row>
                
            </Container>

            
        </> 
    }
}

export default injectIntl(RedirectToProfile);
