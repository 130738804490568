
const Translations = {
    DEFAULT_ERROR_MESSAGE : "Une erreur est survenue",
    MODAL_ERROR_TITLE : "Impossible d'exécuter l'action demandée",
    INVALID_RULES_MESSAGE : 'Merci de corriger les éléments suivants',

    CIVILITY : 'la civilité',
    FIRST_NAME : "le prénom",
    LAST_NAME : "le nom de famille",
    COMPANY_NAME : "La raison sociale",
    SIRET : "Le siret",
    HOME_PHONE_NUMBER : "le numéro de téléphone fixe",
    MOBILE_PHONE_NUMBER : "le numéro de téléphone mobile",
    EMAIL : "l'adresse email",
    PASSWORD : "PASSWORD",
    PASSWORD_CONFIRMATION : "PASSWORD_CONFIRMATION",
    ADDRESS_COUNTRY : "ADDRESS_COUNTRY",
    ADDRESS_ZIP_CODE : "ADDRESS_ZIP_CODE",
    ADDRESS_CITY : "ADDRESS_CITY",
    ADDRESS_STREET : "ADDRESS_STREET",
    ADDRESS_NAME : "ADDRESS_NAME",
    ADDRESS_TYPE : "ADDRESS_TYPE",
    CONTACT_PHONE_NUMBER : "CONTACT_PHONE_NUMBER",
    CONTACT_FIRST_NAME : "CONTACT_FIRST_NAME",
    CONTACT_LAST_NAME : "CONTACT_LAST_NAME",
    
    TRUCK_DOMAIN : "TRUCK_DOMAIN",
    TRUCK_CATEGORY : "TRUCK_CATEGORY",
    TRUCK_REFERENCE : "TRUCK_REFERENCE",
    TRUCK_ADDRESS : "TRUCK_ADDRESS",
    TRUCK_DRIVER : "TRUCK_DRIVER",
    TRUCK_COST_PER_DAY : "TRUCK_COST_PER_DAY",
    TRUCK_COST_PER_KM : "TRUCK_COST_PER_KM",
    TRUCK_COST_PER_MINUTE : "TRUCK_COST_PER_MINUTE",
    
    ORDER_TRUCK : "ORDER_TRUCK",
    ORDER_START_DATE : "ORDER_START_DATE",
    ORDER_END_DATE : "ORDER_END_DATE",
    ORDER_START_TIME : "ORDER_START_TIME",
    ORDER_ADDRESS : "ORDER_ADDRESS",
    
    EVENT_START_DATE : "EVENT_START_DATE",
    EVENT_END_DATE : "EVENT_END_DATE",
    EVENT_TRUCK_OR_DRIVER : "EVENT_TRUCK_OR_DRIVER"
    
}

export default Translations