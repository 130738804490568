import React from "react";
import $ from "jquery"
import Config from "../../../../config"

import PropTypes from 'prop-types'

import {
    Card,
    CardBody,
    CardImg,
    Button,
    UncontrolledTooltip,
    CardTitle,
    Row,
    Col,
    Container
} from "reactstrap";

class MapRouteComponent extends React.Component {

  constructor(props)
  {
      super(props)
      this.state = {
      }
  }

  componentDidMount() {

    if (this.props.childRef)
    {
      this.props.childRef(this)
    }
    //this.showPointsOnMap()

    //window.addEventListener("load", this.showPointsOnMap.bind(this));
  }

  createIncidentMarker(s)
  {
    console.log(s);
    var outerElement = document.createElement('div'),
      innerElement = document.createElement('div');

    outerElement.style.userSelect = 'none';
    outerElement.style.webkitUserSelect = 'none';
    outerElement.style.msUserSelect = 'none';
    outerElement.style.mozUserSelect = 'none';
    outerElement.style.cursor = 'default';

    innerElement.style.color = s.type.ui_html_color;
    innerElement.style.backgroundColor = 'white';
    innerElement.style.borderRadius = '50px';
    innerElement.style.padding = '5px';
    
    innerElement.style.border = '2px solid ' + s.type.ui_html_color;
    innerElement.style.font = 'normal 12px Radikal';
    //innerElement.style.lineHeight = '12px'
    

    //innerElement.style.paddingTop = '2px';
    //innerElement.style.paddingLeft = '4px';
    if (s.title.toString().length == 1)
    {
      innerElement.style.width = '25px';
    }
    /*else {
      innerElement.style.width =  25 + (5 * s.title.length) + 'px';
    }*/

    innerElement.style.height = '30px';
    innerElement.style.width = '30px';
    innerElement.style.textAlign = 'center';

    // add negative margin to inner element
    // to move the anchor to center of the div
    innerElement.style.paddingTop = '-10px';
    //innerElement.style.marginLeft = '-10px';

    outerElement.appendChild(innerElement);

    // Add text to the DOM element
    //innerElement.innerHTML = s.title;
    innerElement.innerHTML = "<img width='14px' height='14px' src='" + s.type.ui_image_url + "' />" + 
      "<div style='position: relative;top: -32px;right: -10px;color: " + s.type.ui_html_color + ";background-color: white;border-radius: 50px;padding: 2px;border: dashed 1px " + s.type.ui_html_color   + ";font: 12px;Radikal;height: 20px;width: 21px;text-align: center;'>" + s.type.position + "</div>"
    ;

    function changeOpacity(evt) {
      //evt.target.style.opacity = 0.6;
    };

    function changeOpacityToOne(evt) {
      //evt.target.style.opacity = 1;
    };

    //create dom icon and add/remove opacity listeners
    var domIcon = new window.H.map.DomIcon(outerElement, {
      // the function is called every time marker enters the viewport
      onAttach: function(clonedElement, domIcon, domMarker) {
        clonedElement.addEventListener('mouseover', changeOpacity);
        clonedElement.addEventListener('mouseout', changeOpacityToOne);
      },
      // the function is called every time marker leaves the viewport
      onDetach: function(clonedElement, domIcon, domMarker) {
        clonedElement.removeEventListener('mouseover', changeOpacity);
        clonedElement.removeEventListener('mouseout', changeOpacityToOne);
      }
    });

    // Marker for Chicago Bears home
    var bearsMarker = new window.H.map.DomMarker({lat: s.latitude, lng: s.longitude}, {
      icon: domIcon
    });

    return bearsMarker
  }

  createMainStepMarker(s)
  {
    var outerElement = document.createElement('div'),
      innerElement = document.createElement('div');

    outerElement.style.userSelect = 'none';
    outerElement.style.webkitUserSelect = 'none';
    outerElement.style.msUserSelect = 'none';
    outerElement.style.mozUserSelect = 'none';
    outerElement.style.cursor = 'default';

    innerElement.style.color = 'black';
    innerElement.style.backgroundColor = 'white';
    innerElement.style.borderRadius = '25px';
    innerElement.style.padding = '5px';
    
    innerElement.style.border = '2px solid black';
    innerElement.style.font = 'normal 12px Radikal';
    //innerElement.style.lineHeight = '12px'
    

    //innerElement.style.paddingTop = '2px';
    //innerElement.style.paddingLeft = '4px';
    if (s.title.toString().length == 1)
    {
      innerElement.style.width = '25px';
    }
    /*else {
      innerElement.style.width =  25 + (5 * s.title.length) + 'px';
    }*/

    innerElement.style.height = '25px';
    innerElement.style.textAlign = 'center';

    // add negative margin to inner element
    // to move the anchor to center of the div
    innerElement.style.paddingTop = '-10px';
    //innerElement.style.marginLeft = '-10px';

    outerElement.appendChild(innerElement);

    // Add text to the DOM element
    innerElement.innerHTML = s.title;

    function changeOpacity(evt) {
      //evt.target.style.opacity = 0.6;
    };

    function changeOpacityToOne(evt) {
      //evt.target.style.opacity = 1;
    };

    //create dom icon and add/remove opacity listeners
    var domIcon = new window.H.map.DomIcon(outerElement, {
      // the function is called every time marker enters the viewport
      onAttach: function(clonedElement, domIcon, domMarker) {
        clonedElement.addEventListener('mouseover', changeOpacity);
        clonedElement.addEventListener('mouseout', changeOpacityToOne);
      },
      // the function is called every time marker leaves the viewport
      onDetach: function(clonedElement, domIcon, domMarker) {
        clonedElement.removeEventListener('mouseover', changeOpacity);
        clonedElement.removeEventListener('mouseout', changeOpacityToOne);
      }
    });

    // Marker for Chicago Bears home
    var bearsMarker = new window.H.map.DomMarker({lat: s.latitude, lng: s.longitude}, {
      icon: domIcon
    });

    return bearsMarker
  }

  showPointsOnMap(center, points) 
  {
    var mapContainer = document.getElementById('map');
    $(mapContainer).empty();
    var routeInstructionsContainer = document.getElementById('panel');
    $(routeInstructionsContainer).empty();
    
    // Step 1: initialize communication with the platform
    // In your own code, replace variable window.apikey with your own apikey
    var platform = new window.H.service.Platform({
        apikey: Config.hereApiKey
    });

    var defaultLayers = platform.createDefaultLayers();

    // Step 2: initialize a map - this map is centered over Berlin
    var map = new window.H.Map(mapContainer,
    // Set truck restriction layer as a base map
    defaultLayers.vector.normal.map,{
    center: {lat: center.latitude, lng: center.longitude},
    zoom: 5,
    pixelRatio: window.devicePixelRatio || 1
    });
    // add a resize listener to make sure that the map occupies the whole container
    window.addEventListener('resize', () => map.getViewPort().resize());

    // Step 3: make the map interactive
    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    var behavior = new window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(map));
    var ui = window.H.ui.UI.createDefault(map, defaultLayers);

    // Création de l'objet pour la requete
    var routeRequestParams = {
        language: 'fr-fr',
        mode: 'fastest;truck;traffic:disabled',
        representation: 'display',
        routeattributes : 'waypoints,summary,shape,legs',
        'metricSystem': 'metric'
    };

    // Pour chaque étape, on ajoute le point sur la carte
    // et on calcul la route uniquement pour les types "MISSION_EVENT_TYPE_GPS_COORDONATES"
    points.forEach(s => {
        if (s != null && s.latitude != null && s.type.code == "MISSION_EVENT_TYPE_GPS_COORDONATES")
        {
            routeRequestParams["waypoint" + s.order] = 'geo!' + s.latitude + ',' + s.longitude
        }
    })

    // Calcul des routes
    var router = platform.getRoutingService();
    var routeStyle = {
        style : {
            strokeColor: 'rgba(0, 128, 255, 0.7)',
            lineWidth: 10
        }
    }
    /*
    router.calculateRoute(routeRequestParams, function(result) {
        var route =  result.response.route[0];

        var lineString = new window.H.geo.LineString(),
        routeShape = route.shape,
        polyline,
        div = document.createElement('div');

        div.innerHTML = route.summary.text;
        div.style.color = routeStyle.style.strokeColor;
        document.getElementById('panel').appendChild(div);

        routeShape.forEach(function(point) {
            var parts = point.split(',');
            lineString.pushLatLngAlt(parts[0], parts[1]);
        });

        polyline = new window.H.map.Polyline(lineString, routeStyle);

        // Add the polyline to the map
        map.addObject(polyline);

    }, console.error);*/

    var lineString = new window.H.geo.LineString()


    var group = new window.H.map.Group();
    var markers = [];
    var nbCoordonatesMarkers = 0;
    points.forEach(s => {
        if (s != null && s.latitude != null)
        {
          var marker = null;

          if (s.type.code == "MISSION_EVENT_TYPE_GPS_COORDONATES")
          {
            var contractorIcon = new window.H.map.Icon("https://res.cloudinary.com/dl2d3weuy/image/upload/v1626363275/front_app/2784266_business_finance_map_money_placeholder_icon_tf4kwz.png", {size: {w: 16, h: 16}});
            marker = new window.H.map.Marker({lat:s.latitude, lng:s.longitude}, {icon: contractorIcon});
            
            nbCoordonatesMarkers += 1;

            lineString.pushLatLngAlt(s.latitude, s.longitude)

            /*
            var bubble = null;
            marker.addEventListener('pointerenter', function (evt) {
              // event target is the marker itself, group is a parent event target
              // for all objects that it contains
              
              bubble = new window.H.ui.InfoBubble(evt.target.getGeometry(), {

                // read custom data
                content: evt.target.getData()
              });
              // show info bubble
              ui.addBubble(bubble);
            }, false);
            marker.addEventListener('pointerleave', function(evt) {
              bubble.close();
            }, false);*/

          }
          else if (s.type.code == "MISSION_EVENT_TYPE_INCIDENT")
          {
            marker = this.createIncidentMarker(s);
            /*var incidentIcon = new window.H.map.Icon(s.type.ui_image_url, {size: {w: 32, h: 32}})
            
            marker = new window.H.map.Marker({lat:s.latitude, lng:s.longitude}, {icon: incidentIcon});
            
            lineString.pushLatLngAlt(s.latitude, s.longitude)*/
          }
          else if (s.type.code == "MISSION_EVENT_TYPE_LAST_TRUCK_GPS_COORDONATES")
          {
            var truckIcon = new window.H.map.Icon('<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M5 11v1h8v-7h-10v-1c0-.552.448-1 1-1h10c.552 0 1 .448 1 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.089-.743 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3h-1c-.552 0-1-.448-1-1v-6h-2v-2h7v2h-3zm3 5.8c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-3-2.8h-10v2h.765c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h5.53c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h1.765v-4.575l-1.711-2.929c-.179-.307-.508-.496-.863-.496h-4.426v6zm1-5v3h5l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.705zm-16-3h8v2h-8v-2z"/></svg>', {size: {w: 32, h: 32}})
            marker = new window.H.map.Marker({lat:s.latitude, lng:s.longitude}, {icon: truckIcon});
          }
          else
          {
            marker = this.createMainStepMarker(s);
          }

          if (marker)
          {
            if (s.description)
            {
              marker.setData('<div><a href="#">' + s.description + '</a></div>');
            }
            markers.push(marker);
          }
        }
    });

    if (nbCoordonatesMarkers >= 2)
    {
      var polyline = new window.H.map.Polyline(lineString, routeStyle);
      map.addObject(polyline);
    }

    //var behavior = new window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(map));
    group.addObjects(markers);
    map.addObject(group);

    group.addEventListener('tap', function (evt) {
      // event target is the marker itself, group is a parent event target
      // for all objects that it contains
      var bubble = new window.H.ui.InfoBubble(evt.target.getGeometry(), {
        // read custom data
        content: evt.target.getData()
      });
      // show info bubble
      ui.addBubble(bubble);
    }, false);


    
      
    // get geo bounding box for the group and set it to the map
    map.getViewModel().setLookAtData({
        bounds: group.getBoundingBox()
    });
  }

  render() {
    var style = {
      width: this.props.width,
      height: this.props.height
    }

    
    return <>
      <div className="t2y-sticky-thc">
      <div id="map" style={{width:this.props.width, height:this.props.height}} ></div>
      <div id="panel"></div>
      </div>
      
    </>
  }
}


export const MapPoint = {
  order: PropTypes.number,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
}



MapRouteComponent.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  points: PropTypes.array
}
 
export default MapRouteComponent;