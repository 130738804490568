import Config from '../config.js'
import ProxyService from './ProxyService'
import axios from "axios";


class SecurityService extends ProxyService {
    
    async disconnect(caller, okCallback = null)
    {
        this.clearStorage();
        if (okCallback)
        {
            okCallback();
        }
    }

    getUserStateByEmail(caller, email, okCallback, errorCallback = null)
    {
        this.get(caller, this.apiUrls["USER_STATE_BY_EMAIL"] + email, okCallback, errorCallback);
    }

    async createAccount(caller, postedData, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, this.apiUrls["USER_CREATE"], postedData, (response) => {
            var user = {
                email: postedData.email,
                type: postedData.type,
                workflow_state: "ACCOUNT_CREATED"
            }

            this.storeUser(user);

            // On rend la main à la page appelante
            okCallback(response);
        }, errorCallback);
    }

    async connect(caller, login, password, okCallback, errorCallback)
    {
        caller.setState({loading : true});
        this.post(caller, this.apiUrls["USER_CONNECT"], {"username" : login, "password" : password}, (response) => {
            // Enregistrement du token
            this.storeToken(response.token);
            // Récupération des données de l'utilisateur
            this.me(null, okCallback)
        }, errorCallback);
    }


    me(caller, okCallback)
    {
        if (caller != null)
        {
            caller.setState({loading : true});
        }
        
        this.get(null, this.apiUrls["USER_ME"], (response) => {
            // Enregistrement des données de la société et de l'utilisateur
            this.storeUserAndCompany(response);
            // On rend la main à la page appelante
            okCallback(response);
        })
    }

    syncConnect(caller, login, password)
    {
        var result = this.syncPost(caller, this.apiUrls["USER_CONNECT"], {"username" : login, "password" : password}).then(x => {return x})
        return result;
    }

    isConnectedForWorkflow()
    {
        var isConnected = this.isConnected();
        return isConnected;
    }

    async syncMe(caller)
    {
        var meResponse = null;
        var result = axios.get(this.buildUrl(this.apiUrls["USER_ME"]), { headers:this.buildHeaders()})
        .then(httpResponse => {
            meResponse = httpResponse.data;
            
            return meResponse;
        })
        .catch(error => {
            meResponse = null;
            return meResponse;
        })

        return result;
    }

    syncUserById(userId)
    {
        var meResponse = null;
        var result = axios.get(this.buildUrl(this.apiUrls["USER_BY_ID"] + userId))
        .then(httpResponse => {
            meResponse = httpResponse.data;
            
            return meResponse;
        })
        .catch(error => {
            meResponse = null;
            return meResponse;
        })

        return result;
    }

    async checkUserStateAction(userId, token = null)
    {
        var userResponse = await this.syncUserById(userId);
        var stateCode = userResponse == null || userResponse.user == null ? null : userResponse.user.state.code;
        
        var userStateActionResponse = {
            code: "",
            user: null,
            company: null
        }

        // N'existe pas
        if (userResponse == null)
        {
            userStateActionResponse.code = "LOGIN";
        }
        else if (token != null && userResponse.user.token != token)
        {
            userStateActionResponse.code = "LOGIN_WITH_EMAIL_BAD_TOKEN";
            userStateActionResponse.user = userResponse.user;
        }
        else if (stateCode == "STATE_PENDING_MAIL_VALIDATION") // En attente de validation
        {
            userStateActionResponse.code = "STATE_PENDING_MAIL_VALIDATION";
            userStateActionResponse.user = userResponse.user;
        }
        else if (stateCode == "STATE_PENDING_PASSWORD_VALIDATION") // En cours de modification de mot de passe
        {
            userStateActionResponse.code = "STATE_PENDING_PASSWORD_VALIDATION";
            userStateActionResponse.user = userResponse.user;
        }
        else if (stateCode == "STATE_ACTIVE") // Validé
        {
            var userInSession = this.getUser();
            if (userInSession != null)
            {
                // Si un token et des informations utilisateurs sont présente alors on tente un refresh
                var refreshUserResponse = await this.syncMe(null);
                // Si ca ne marche pas, on force l'utilisateur à se reconnecter
                // Pas d'utilisateur trouvé ou utilisateur différent de celui passé en paramètre
                if (refreshUserResponse == null || refreshUserResponse.user == null || refreshUserResponse.user.email != userResponse.user.email)
                {
                    //this.disconnect();
                    userStateActionResponse.code = "LOGIN";
                }
                else
                {
                    // Enregistrement des données de la société et de l'utilisateur
                    this.storeUserAndCompany(refreshUserResponse);

                    // Validé sans informations société
                    if (userResponse.has_billing_address == false)
                    {
                        // Redirection vers l'écran de login
                        userStateActionResponse.code = "REGISTER_COMPANY";
                    }
                    else
                    {
                        // Redirection vers l'écran de profile de l'utilisateur
                        userStateActionResponse.code = "PROFILE";
                    }
                }
            }
            else 
            {
                // Pas d'information en session on propose la reconnexion avec préremplissage du login
                userStateActionResponse.code = "LOGIN_WITH_EMAIL";
                userStateActionResponse.user = userResponse.user;
            }
        }

        return userStateActionResponse;
        
    }




    async resendRegisterEmail(caller, email, okCallback, errorCallback = null)
    {
        if (caller != null)
        {
            caller.setState({loading : true});
        }
        this.post(caller, this.apiUrls["USER_RESEND_REGISTER_EMAIL"], {"email" : email}, okCallback, errorCallback)
    }

    async validateEmailModification(caller, key, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, this.apiUrls["USER_VALIDATE_EMAIL_MODIFICATION"], {key : key}, (response) => {

            if (response.user.state.code == "STATE_PENDING_MAIL_VALIDATION")
            {
                okCallback(response)
            }
            else
            {
                // Enregistrement du token
                this.storeToken(response.token);
                // Récupération des données de l'utilisateur
                this.me(null, okCallback)
            }

        }, errorCallback)
    }

    async cancelEmailModification(caller, key, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, this.apiUrls["USER_CANCEL_EMAIL_MODIFICATION"], {key : key}, (response) => {
            // Enregistrement du token
            this.storeToken(response.token);
            // Récupération des données de l'utilisateur
            this.me(null, okCallback)

        }, errorCallback)
    }

    async validateAccount(caller, key, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, this.apiUrls["USER_ACCOUNT_VALIDATION"], {key : key}, (response) => {
            // Enregistrement du token
            this.storeToken(response.token);
            // Récupération des données de l'utilisateur
            this.me(null, okCallback)

        }, errorCallback)
    }

    async askForChangingMyPassword(caller, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, this.apiUrls["USER_ASK_FOR_CHANGE_IS_PASSWORD"], {}, (response) => {
            // On rend la main à la page appelante
            okCallback(response);
        }, errorCallback)
    }

    async forgetPasswordWithEmail(caller, email, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, this.apiUrls["USER_FORGET_PASSWORD_WITH_EMAIL"], {email : email}, (response) => {
            // On rend la main à la page appelante
            okCallback(response);
        }, errorCallback)
    }

    async forgetPasswordWithEmailAndUrl(caller, email, url, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, this.apiUrls["USER_FORGET_PASSWORD_WITH_EMAIL"], {email : email, url: url}, (response) => {
            // On rend la main à la page appelante
            okCallback(response);
        }, errorCallback)
    }
    

    async changePassword(caller, datas, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, this.apiUrls["USER_CHANGE_PASSWORD"], datas, (response) => {
            // Enregistrement du token
            this.storeToken(response.token);
            // Récupération des données de l'utilisateur
            this.me(null, okCallback)
        }, errorCallback)
    }

    async validateCollaboratorAccount(caller, datas, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, this.apiUrls["COLLABORATOR_VALIDATE_ACCOUNT"], datas, (response) => {
            // Enregistrement du token
            this.storeToken(response.token);
            // Récupération des données de l'utilisateur
            this.me(null, okCallback)
        }, errorCallback)
    }

    async validateDriverAccount(caller, key, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, this.apiUrls["DRIVER_ACCOUNT_VALIDATION"], {key : key}, (response) => {
            okCallback(response);
        }, errorCallback)
    }
    

    async updateUser(caller, datas, okCallback)
    {
        caller.setState({loading : true});
        this.put(caller, this.apiUrls["USER_ME"], datas, (response) => {
            this.storeUser(response.user);
            // On rend la main à la page appelante
            okCallback(response);
        });
    }

    async changeUserEmail(caller, email, userId, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        if (userId == null)
        {
            this.post(caller, this.apiUrls["USER_CHANGE_EMAIL"], {"username" : email}, (response) => {
                // Mise à jour de l'utilisateur en session
                this.storeUser(response.user);
                // On rend la main à la page appelante
                okCallback(response);
            }, errorCallback)
        }
        else{
            this.post(caller, this.apiUrls["USER_CHANGE_EMAIL"] + "/" + userId, {"username" : email}, (response) => {
                // On rend la main à la page appelante
                okCallback(response);
            }, errorCallback)
        }

    }

    async resendCollaboratorRegisterEmail(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, this.apiUrls["COLLABORATOR_RESEND_REGISTER_EMAIL"] + id, {}, (response) => {
            okCallback(response);
        }, errorCallback)
    }

    async resendEmailValidationEmail(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, this.apiUrls["USER_RESEND_UPDATE_EMAIL"] + id, {}, (response) => {
            okCallback(response);
        }, errorCallback)
    }

    getSmsForAction(caller, userId, url, force, context, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, "/sms-validation/code/generate", {
            id: userId,
            url: url,
            context: context,
            force: force
        }, (response) => {
            okCallback(response);
        }, errorCallback)
    }

    smsValidation(caller, userId, tokenResponse, activationCode, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        this.post(caller, "/sms-validation/code/validation", {
            code: activationCode,
            id: userId
        }, (response) => {
            okCallback(response);
        }, errorCallback, this.getSmsValidationToken() /*tokenResponse ? tokenResponse.token : null*/)
    }
}


export default SecurityService;