import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
    Container,
    Row,
    Col,
    Button,
    Table,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardBody,
    CardTitle,
    ButtonGroup,
    Label
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import ShipperService from "../../../proxies/ShipperService";
import DefaultImage from "../common/defaultImage";
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import Resources from "../../../resources";
import StringHelper from "../../../helpers/StringHelper";
import ArrayHelper from "../../../helpers/ArrayHelper";
import EmptyResult from "../common/emptyResult";
import DeleteButton from "../common/deleteButton";
import LoadingContainer from "../common/loadingContainer";
import DefaultButton from "../common/defaultButton";
import RepositoryDataComponent from "../../pages/components/RepositoryDataComponent";
import Gallery from "react-grid-gallery";
import BusinessService from "../../../proxies/BusinessService";

import Title3 from "../common/title3"
import Title2 from "../common/title2"
import InjectedSepaSourceComponent from "./SepaSourceComponent"
import InjectedCreditCardComponent from "./CreditCardComponent"
import InjectedSepaComponent from "./SepaComponent"
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Config from "../../../config";
import DefaultCheckbox from "../common/defaultCheckbox";
import MutedText from "../common/mutedText";
const stripePromise = loadStripe(Config.stripePublicKey);

var moment = require('moment');
require('moment/locale/fr');

class PaymentComponent extends FormValidationComponent {

    static SEPA = "SEPA";
    static CARD = "CARD";
    static SEPA_SOURCE = "SOURCE";

    constructor(props) {
        super(props)

        this.shipperService = new ShipperService();
        
        this.state = {
            loading: true,
            mode: PaymentComponent.CARD
        }
    }

    componentWillMount() {
        // Récupération des ibans
        this.shipperService.getIbansAvailable(this, (response) => {
            var mode = PaymentComponent.CARD
            if (response.payment_methods && response.payment_methods.length > 0)
            {
                mode = PaymentComponent.SEPA_SOURCE
            }

            this.setState({
                ibans: response.payment_methods,
                ibansAvailableLoaded: true,
                mode: mode
            })
        })
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }
    
    renderSepaMode()
    {
        return <>
            <Container>
                <Row> 
                    <Col lg="12" md="12" className="order-lg-1 order-2">
                        <Elements stripe={stripePromise}>
                            <InjectedSepaComponent
                                orderId={this.props.orderId}
                                lastPayment={this.props.lastPayment}
                                ttcAmount={this.props.ttc} 
                                htAmount={this.props.ht}
                                onlyPaymentMethod={this.props.onlyPaymentMethod}
                                onStart={() => {}} 
                                onSuccess={(paymentInfos) => this.props.onPaymentSuccess(paymentInfos)} 
                                onError={(error) => this.props.onPaymentError(error)} 
                                paymentButtonTitle={this.props.paymentButtonTitle ? this.props.paymentButtonTitle : "Payer"}
                            />
                        </Elements>
                    </Col>
                </Row>
            </Container>

        </>
    }

    renderCardMode()
    {
        return <>
            <Container>
                <Row> 
                    <Col lg="12" md="12" className="order-lg-1 order-2">
                        <Elements stripe={stripePromise}>
                            <InjectedCreditCardComponent
                                orderId={this.props.orderId}
                                lastPayment={this.props.lastPayment}
                                ttcAmount={this.props.ttc} 
                                htAmount={this.props.ht}
                                onlyPaymentMethod={this.props.onlyPaymentMethod}
                                onStart={() => {}} 
                                onSuccess={(paymentInfos) => this.props.onPaymentSuccess(paymentInfos)} 
                                onError={(error) => this.props.onPaymentError(error)} 
                                paymentButtonTitle={this.props.paymentButtonTitle ? this.props.paymentButtonTitle : "Payer"}
                                cardPaymentMethodMinExpirationDate={this.props.cardPaymentMethodMinExpirationDate}
                            />
                        </Elements>
                    </Col>
                </Row>
            </Container>
        </>
    }

    renderSepaSourceMode()
    {
        return <>
            <Container>
                <Row> 
                    <Col lg="12" md="12" className="order-lg-1 order-2">
                        <Elements stripe={stripePromise}>
                            <InjectedSepaSourceComponent
                                orderId={this.props.orderId}
                                lastPayment={this.props.lastPayment}
                                ttcAmount={this.props.ttc} 
                                htAmount={this.props.ht}
                                onlyPaymentMethod={this.props.onlyPaymentMethod}
                                onStart={() => {}} 
                                onSuccess={(paymentInfos) => this.props.onPaymentSuccess(paymentInfos)} 
                                onError={(error) => this.props.onPaymentError(error)} 
                                paymentButtonTitle={this.props.paymentButtonTitle ? this.props.paymentButtonTitle : "Payer"}
                            />
                        </Elements>
                    </Col>
                </Row>
            </Container>
        </>
    }

    render()
    {
        return <>
            <hr className="t2y-hr-light mt-0" />
            <Row>
                <Col md="6">
                    <Title3>
                    <FormattedMessage id="Payment.total_to_pay"></FormattedMessage>
                    </Title3>
                </Col>
                <Col md="6">
                    <Title3 right={true} content={StringHelper.formatAmountWithPattern(this.props.ttc, "€", "{amount} {devise} TTC")}></Title3>
                </Col>
                <Col md="12">
                <Label>{this.props.paymentDescription}</Label>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <Title3>Choisissez votre moyen de paiement :</Title3>
                </Col>
            </Row>
            <Row className="justify-content-center mb-2">
                <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                    <Button className={classnames({ active: this.state.mode === PaymentComponent.CARD }) + " tabBtn"} color="primary" 
                        onClick={(e) => {this.setState({mode: PaymentComponent.CARD})}}>
                        Carte bancaire
                    </Button>
                    {/*
                    <Button className={classnames({ active: this.state.mode === PaymentComponent.SEPA })} color="secondary"
                        onClick={(e) => {this.setState({mode: PaymentComponent.SEPA})}}>
                        Prélèvement SEPA
                    </Button>
                    */}
                    <Button className={classnames({ active: this.state.mode === PaymentComponent.SEPA_SOURCE }) + " tabBtn"} color="secondary"
                        onClick={(e) => {this.setState({mode: PaymentComponent.SEPA_SOURCE})}}>
                        Prélèvement SEPA
                    </Button>
                </ButtonGroup>
            </Row>
            {
                (this.state.mode == PaymentComponent.SEPA) ?
                (
                    this.renderSepaMode()
                ) :
                (
                    (this.state.mode == PaymentComponent.CARD) ?
                    (
                        this.renderCardMode()
                    ) : (
                        this.renderSepaSourceMode()
                    )
                )
            }

        </>
    }

}

PaymentComponent.propTypes = {
    onlyPaymentMethod: PropTypes.bool,
    orderId: PropTypes.string, // Identifiant de la commande 
    ttc: PropTypes.number, // Montant TTC à payer
    rescue: PropTypes.bool, // Si oui alors on est en mode récupération d'un paiement incident
    lastPayment: PropTypes.bool, // Savoir si c'est un paiement de solde ou de facture d'acompte
    onPaymentSuccess: PropTypes.func,
    onPaymentError: PropTypes.func,
    paymentDescription: PropTypes.string,
    paymentButtonTitle: PropTypes.string,
    cardPaymentMethodMinExpirationDate: PropTypes.any
};

export default injectIntl(PaymentComponent)

