import React from "react";
import styled from "styled-components";
import IconStar from "./iconStar";
import IconLeaf from "./iconLeaf";
import DefaultPopover from "../common/defaultPopover";
import StringHelper from "../../../helpers/StringHelper";

const S = {};

const iconWidth = "1.3em";
const iconHeight = "1.3em";

S.WrapperDiv = styled.div`
  display: flex;
  align-items: center;
`;

S.RatingSpan = styled.span`
  font-size: `+ iconWidth + `;
  font-weight: 700;
  padding-right: 5px;
  line-height: `+ iconHeight + `;
  font-family: Helvetica, Arial;
`;

S.BackStarsDiv = styled.div`
  display: flex;
  position: relative;
  color: #d3d3d3;
`;

S.FrontDiv = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  overflow: hidden;
  width: ${props => props.rating};
`;

function getColor(props)
{
    if (!props || !props.color)
    {
        return "#ffbc0b";
    }
    else
    {
        return props.color;
    }

}

function getIcon(type, props)
{
    const iconProps = {width: iconWidth, height: iconHeight}
    if (props && props.iconWidth)
    {
      iconProps.width = props.iconWidth
    }
    if (props && props.iconHeight)
    {
      iconProps.height = props.iconHeight
    }


    switch(type)
    {
        case "leaf":
            return <IconLeaf width={iconProps.width} height={iconProps.height} ></IconLeaf>
        break;
        default:
            return <IconStar width={iconProps.width} height={iconProps.height}></IconStar>        
        break;
    }
}

function renderStars(props)
{
  let rating = 0;
  let id = StringHelper.createDomElementId()
  if (props.note) {
    rating = Math.round(props.note * 100 / 5).toString() + "%";

    /* This is to round the rating to closest .5 or .0 */
    //(((Math.round((props.note * 10) / 5) * 5) / 10) * 20).toString() + "%";
  }

  var starsContainerStyle = props.style ? props.style : {}
  if (props.center)
  {
    starsContainerStyle.margin = "0 auto";
  }

  if (props.size)
  {
    starsContainerStyle.fontSize = props.size;
  }


  return <>
    <S.BackStarsDiv id={id}  style={ starsContainerStyle}>
    {getIcon(props.type, props)}
    {getIcon(props.type, props)}
    {getIcon(props.type, props)}
    {getIcon(props.type, props)}
    {getIcon(props.type, props)}
    <S.FrontDiv rating={rating} style={{color: getColor(props)}} className={"front-div-class"}>
      {getIcon(props.type, props)}
      {getIcon(props.type, props)}
      {getIcon(props.type, props)}
      {getIcon(props.type, props)}
      {getIcon(props.type, props)}
    </S.FrontDiv>
  </S.BackStarsDiv>

  {
    (props.note > 0) ?
    (
      <DefaultPopover targetId={id} component={<></>} content={"Note de " + (props.note *2) + "/ 10"}></DefaultPopover>
    ) : (<></>)
  }
  
  </>
}

function Rating(props) {
  return (
    <React.Fragment>
      <S.WrapperDiv >
        {props.children}
        {renderStars(props)}
      </S.WrapperDiv>
    </React.Fragment>
  );
}

export default Rating;
