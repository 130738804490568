
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    ButtonGroup,
    Button
  } from "reactstrap";
import { Calendar } from 'react-date-range';

import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator"
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import FormHourInput from "../../pages/components/form/FormHourInput";
import ReactDatetime from "react-datetime";
import RepositoryService from "../../../proxies/RepositoryService";

import { injectIntl , FormattedMessage } from 'react-intl';
import FormRepository from "../../pages/components/form/FormRepository";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DefaultInput from "./defaultInput";
import ReactPasswordStrength from 'react-password-strength'
import StringHelper from "../../../helpers/StringHelper";



import styled from 'styled-components'

const TopRightRibbonContainer = styled.div`
    top: -10px;
    right: -10px;
    width: ${props => props.big ? "150px" : "100px"};
    height: ${props => props.big ? "155px" : "100px"};
    overflow: hidden;
    position: absolute;
    text-align: ${props => props.align ? props.align : "left"};
    padding-left: 54px;
`

const TopRightRibbonContainerSpan = styled.span`
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color:  ${props => props.style && props.style.backgroundColor ? props.style.backgroundColor : "#22bb33;"};
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: ${props => props.style && props.style.color ? props.style.color : "var(--white);"};
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    font-size: 14px;
    padding-left: ${props => props.align == "center"? "0px" : "53px"};
`

const TopLeftRibbonContainer = styled.div`
    top: -10px;
    left: -10px;
    width: ${props => props.big ? "150px" : "100px"};
    height: ${props => props.big ? "155px" : "100px"};
    overflow: hidden;
    position: absolute;
    text-align: ${props => props.align ? props.align : "center"};
    padding-right: 54px;
`
const TopLeftRibbonContainerSpan = styled.span`
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color:  ${props => props.style && props.style.backgroundColor ? props.style.backgroundColor : "#22bb33;"};
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: ${props => props.style && props.style.color ? props.style.color : "var(--white);"};
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    font-size: 14px;
    padding-left: ${props => props.align == "center"? "0px" : "53px"};
`




class Ribbon extends DefaultInput {

    renderTopLeft()
    {
        var htmlContent = this.props.content;
        var className = this.props.className;

        /*
        return <div class={"t2y-driver-rating ribbon-top-left t2y-ribbon-" + this.props.type} style={{zIndex:9999}}>
            <span dangerouslySetInnerHTML={htmlContent ? {__html: htmlContent} : null}>{!htmlContent ?  this.props.children : <></>}
            </span>
        </div>*/
        return <TopLeftRibbonContainer  align={this.props.align} big={this.props.longText} className={className + " t2y-ribbon-" + this.props.type}>
            <TopLeftRibbonContainerSpan style={this.props.style} align={this.props.align} dangerouslySetInnerHTML={htmlContent ? {__html: htmlContent} : null}>{!htmlContent ?  this.props.children : <></>}</TopLeftRibbonContainerSpan>
        </TopLeftRibbonContainer>
    }

    renderTopRight()
    {
        var htmlContent = this.props.content;
        var className = this.props.className;

        return <TopRightRibbonContainer  align={this.props.align} big={this.props.longText} className={className + " t2y-ribbon-" + this.props.type}>
            <TopRightRibbonContainerSpan style={this.props.style} align={this.props.align} dangerouslySetInnerHTML={htmlContent ? {__html: htmlContent} : null}>{!htmlContent ?  this.props.children : <></>}</TopRightRibbonContainerSpan>
        </TopRightRibbonContainer>
    }

    render() {
        switch(this.props.position)
        {
            case "top-right":
                return this.renderTopRight()
                break;
            case "top-left":
                return this.renderTopLeft()
                break;
            default:
                return this.renderTopRight()
                break;
        }
    }
}


Ribbon.propTypes = {
    type: PropTypes.oneOf(["success", "warning", "default", "custom"]),
    position: PropTypes.oneOf(["top-right", "top-left", "bottom-right", "bottom-left"]),
};

export default injectIntl(Ribbon)
