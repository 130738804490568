
import React from "react";
import ArrayHelper from "../../../helpers/ArrayHelper";

const textEditor = `
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0px 6px 0 6px;
  border: none;
  vertical-align: top;
  color: var(--color);
  background-color: var(--background-color);
  font-family: inherit;
  font-size: var(--font-size);
  &:focus {
    border-color: var(--selection-color);
    outline: none;
  }
  &::placeholder {
    color: #999;
    opacity: 1;
  }
`;

export const textEditorClassname = `rdg-text-editor ${textEditor}`;

export const datas = [{id: "PROPERTY_UNIT_TONS", label: "Tonne(s)"}, {id: "PROPERTY_UNIT_KG", label: "Kg(s)"}]

export default function ReactDataGridSelector({ row, column, onRowChange }) {
    return (
      <select
        style={{"height": "99%", "width":"99%"}}
        className={""}
        value={row.weight_unit.code}
        onChange={event => {
            row.weight_unit.code = event.target.value
            onRowChange({ ...row})}
        }
        autoFocus
        >
        {datas.map(data => (
          <option key={data.id} value={data.id}>{data.label}</option>
        ))}
      </select>
    );
  }

  