
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Button
  } from "reactstrap";
import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultImage from "./defaultImage";

class DefaultLabel extends React.Component {

    constructor(props)
    {
        super(props)

        this.state = {
            color: this.props.color ? this.props.color : "primary"
        }
    }

    componentDidMount() {}

    componentWillMount() {}

    componentWillUnmount() {}

    render() 
    {
        var htmlContent = this.props.content;
        var customClassName = "t2y-label" + " " + (" t2y-label-" + this.state.color) + " " + (this.props.className);
        var imageClassName = "t2y-label-image" + " " + (" t2y-label-image-" + this.state.color);

        if (this.props.children && htmlContent == null)
        {
            return <label className={customClassName} style={{...this.props.style}}>
                {
                    (this.props.icon) ? 
                    (
                        <DefaultImage className={imageClassName + " mr-2"} src={this.props.icon}></DefaultImage>
                    ) : (<></>)
                }
                {this.props.children}
            </label>
        }
        else
        {
            return <label className={customClassName} style={{...this.props.style}} dangerouslySetInnerHTML={{__html: htmlContent}}></label>
        }
    }
}

DefaultLabel.propTypes = {
    icon: PropTypes.string,
    color: PropTypes.oneOf(["primary", "secondary", "tertiary", "quaternary"])
};

export default injectIntl(DefaultLabel)
