import "../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
import {
   Row,
 } from "reactstrap";
import { injectIntl} from 'react-intl';

class FormLegend extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {        
        }
    }

    getLabel()
    {
        if (this.props.context)
        {
          switch(this.props.context)
          {
            case "truck":
              return <>
                <label style={{float:"left"}}>Les champs &nbsp;</label>
                <div style={{float:"left"}} id="undefined-main-container" class="t2y-required-legend">*</div> 
                <label style={{float:"left"}}>sont obligatoires pour la publication du véhicule </label>
              </>
              break;
          }
        }
        else{
          return <>
            <label style={{float:"left"}}>Les champs &nbsp;</label>
            <div style={{float:"left"}} id="undefined-main-container" class="t2y-required-legend">*</div> 
            <label style={{float:"left"}}>sont obligatoires </label>
          </>
        }
    }

    render() {
      
      return (
        <>
            <Row className="justify-content-center">
                <div>
                    {this.getLabel()}
                </div>
            </Row>
        </>
      );
    }
}

 
export default injectIntl(FormLegend);
