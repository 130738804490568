/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Children } from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider } from 'react-intl'
import frIntlMessages  from './lang/fr/messages.js';

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "./assets/vendor/select2/dist/css/select2.min.css";
import "./assets/vendor/quill/dist/quill.core.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "./assets/scss/argon-dashboard-pro-react.scss?v1.0.0";
import "./assets/css/custom.css";
import "./assets/css/truck2you.css";

import AdminLayout from "./layouts/Admin.jsx";
import AuthLayout from "./layouts/Auth.jsx";
import SecurityService from "./proxies/SecurityService";
import PrivateRoute from './components/PrivateRoute.jsx';
import SmsValidationRoute from './components/SmsValidationRoute.jsx';
import Address from "./views/pages/common/Address";
import DefaultLayout from "./layouts/DefaultLayout.jsx";
import SmsValidationLayout from './layouts/SmsValidation.jsx'
import ALayout from "./layouts/A.jsx";



var securityService = new SecurityService();
const messages = {
  fr : frIntlMessages
}
const usersLocale = 'fr';

ReactDOM.render(
  <IntlProvider
        locale={usersLocale}
        messages={messages[usersLocale]}
  >
  <BrowserRouter>
    <Switch>
      <PrivateRoute path="/orders" component={ALayout}></PrivateRoute>
      <PrivateRoute path="/admin" component={AdminLayout}></PrivateRoute>
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      <Route path="/public" render={props => <DefaultLayout {...props} />} />
      <SmsValidationRoute path="/sms-validation" component={SmsValidationLayout} ></SmsValidationRoute>
      
      <Route path="/" render={() => (
        securityService.isConnected() ? (
          <Redirect to="/admin/profile"/>
        ) : (
          <Redirect to="/auth/login"/>
        )
      )}/>
    </Switch>
  </BrowserRouter>
  </IntlProvider>,
  document.getElementById("root")
);
