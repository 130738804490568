import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Container
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import ComponentAndImageStep from "../componentAndImageStep.jsx";
import DefaultLabel from "../../common/defaultLabel.jsx";
import DefaultButton from "../../common/defaultButton.jsx";
import DateHelper from "../../../../helpers/DateHelper.js";
import DefaultLink from "../../common/defaultLink.jsx";
import WebsiteHelper from "../../../../helpers/WebsiteHelper.js";
import StringHelper from "../../../../helpers/StringHelper.js";
import Codes from "../../../../codes.js";
import FormGroupInput from "../../../pages/components/form/FormGroupInput.jsx";
import FormHourInput from "../../../pages/components/form/FormHourInput.jsx";
import FormLoadingIndicator from "../../../pages/components/FormLoadingIndicator.jsx";
import ShipperService from "../../../../proxies/ShipperService.js";


class OrderArrivalTimeStep extends ComponentAndImageStep {

    constructor(props) {
        super(props)

        this.shipperService = new ShipperService();
        
        this.validationRules = {
            arrival_time: {
                required: true
            }
        }

        this.state = {
            componentSize: 7,
            imageSize: 5,
            loading: false,
            image: require('../../../../assets/img/illustrations/orderWorkflow_orderArrivalTimeStep.svg').default,
            title: 'Encore une dernière chose',
            subtitle: null,
            message: "Merci de nous préciser l'heure à laquelle le conducteur doit se présenter pour le premier chargement.",
            formDatas: {
                arrival_time: { value: "07:00"}
            }
        }
    }

    componentWillMount() {
        super.componentWillMount()
    }

    componentDidMount() {
        super.componentDidMount()
    }

    submit(pid)
    {
        // Enregistrement de l'horaire souhaité sur la commande
        const formIsValid = this.formIsValid()
        if (formIsValid)
        {
            // Enregistrement de l'horaire sur la commande
            const orderId = this.props.workflow.state.input.order_id
            const arrivalTime = this.getHtmlFormControlValue("arrival_time")
            this.shipperService.patchOrder(this, orderId, "arrival_time", arrivalTime, response => {
                // Puis passage a l'étape suivante
                super.submit(pid)

                this.props.workflow.goToNextStep(pid);
            })
        }
    }

    /*
    close()
    {
        this.props.workflow.goToNextStep();
    }*/

    renderComponent() {
        return <>
            <Container>
                <Row>
                    <Col xs="6">
                        <FormLoadingIndicator loading={this.state.loading}>
                            <FormGroupInput type="inline" labelColumns={"6"} contentColumns={"6"} label={""} >
                                <FormHourInput id="arrival_time" manager={this} className="" timeConstraints={{ minutes : {step:15}}}  />
                            </FormGroupInput>
                        </FormLoadingIndicator>
                    </Col>
                </Row>
            </Container>
        </>
    }
}


export default injectIntl(OrderArrivalTimeStep)