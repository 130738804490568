
import React from "react";
import classnames from "classnames";
import $ from "jquery";

import { injectIntl , FormattedMessage } from 'react-intl';


class Title1 extends React.Component {

    render() {
        var htmlContent = this.props.content;

        if (this.props.children && htmlContent == null)
        {
            return <h1 className="t2y-title1">
                {this.props.children}
            </h1>
        }
        else
        {
            return <h1 className="t2y-title1" dangerouslySetInnerHTML={{__html: htmlContent}}>
            </h1>
        }
    }
}

export default injectIntl(Title1)
