import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Container
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import ComponentAndImageStep from "../componentAndImageStep.jsx";
import DefaultLabel from "../../common/defaultLabel.jsx";
import DefaultButton from "../../common/defaultButton.jsx";
import DateHelper from "../../../../helpers/DateHelper.js";
import DefaultLink from "../../common/defaultLink.jsx";
import WebsiteHelper from "../../../../helpers/WebsiteHelper.js";
import StringHelper from "../../../../helpers/StringHelper.js";
import Codes from "../../../../codes.js";
import FormGroupInput from "../../../pages/components/form/FormGroupInput.jsx";
import FormHourInput from "../../../pages/components/form/FormHourInput.jsx";
import FormLoadingIndicator from "../../../pages/components/FormLoadingIndicator.jsx";
import ShipperService from "../../../../proxies/ShipperService.js";


class CallForBidsPropertyChoiceStep extends ComponentAndImageStep {

    constructor(props) {
        super(props)

        this.shipperService = new ShipperService();
        
        this.validationRules = {
            arrival_time: {
                required: true
            }
        }

        this.state = {
            componentSize: 8,
            imageSize: 4,
            loading: false,
            image: require('../../../../assets/img/illustrations/radioButtonSelection.svg').default,
            title: "Encore une dernière étape",
            subtitle: null,
            message: "",
            choice: null,
            formDatas: {
            }
        }
    }

    componentWillMount() {
        super.componentWillMount()
        this.props.workflow.renderNormalView()
    }

    componentDidMount() {
        super.componentDidMount()

    }

   
    choiceMade()
    {
        return this.state.choice != null;
    }

    onPropertyValueChecked(value)
    {
        this.setState(
            {choice: value.code}, 
            () => {this.props.workflow.reload()}
        )
    }

    submit(pid)
    {
        const input = this.getWorklowInput();
        // Mise a jour des données avant la création de l'appel d'offre
        this.props.workflow.goToNextStep(pid, {
            searchId: input.searchId,
            values: input.values + "|" + this.state.choice + "|"  
        })
    }

    renderComponent() {
        return <>
            <div key="callForBidsPropertyModal" className="t2y-html-translation-container">
            {StringHelper.translateWithHtml(this, "Orders.bids_" + this.getWorklowInput().propertyValuesToSpecify.code)}
            <br/>
            {
                this.getWorklowInput().propertyValuesToSpecify.values.map((value, index) => {
                    return <>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                id={"callForBidsPropertyModalCkb" + index}
                                className="custom-control-input"
                                name="callForBidsPropertyModalCkb"
                                type="radio"
                                checked={this.state.choice == value.code}
                                onChange={(e) => this.onPropertyValueChecked(value)}
                            />
                            <label
                                className="custom-control-label t2y-height-auto"
                                htmlFor={"callForBidsPropertyModalCkb" + index}
                                style={{height:"auto !important"}}
                            >
                                {value.label}
                            </label>
                        </div>
                        <br/>
                    </>
                })
            }
            </div>
        </>
    }
}


export default injectIntl(CallForBidsPropertyChoiceStep)