
import React, { Children } from "react";
import classnames from "classnames";
import $ from "jquery";

import { injectIntl , FormattedMessage } from 'react-intl';

class Title2 extends React.Component {

    render() {
        var customClassName = (this.props.small ? "t2y-title2-small" : "t2y-title2") + " " + (this.props.className);
        
        var htmlContent = this.props.content;
        var style = this.props.style ? this.props.style : {}

        if (this.props.center)
        {
            customClassName += " t2y-center-title"
        }

        if (this.props.right)
        {
            customClassName += " t2y-right-title"
        }

        if (this.props.justify)
        {
            customClassName += " t2y-justify"
        }
        
        if (this.props.children && htmlContent == null)
        {
            return <h2 className={customClassName} style={style}>
                {this.props.children}
            </h2>
        }
        else
        {
            return <h2 style={style} className={customClassName} dangerouslySetInnerHTML={{__html: htmlContent}}></h2>
        }
    }
}

export default injectIntl(Title2)
