
import React from "react";
import PropTypes from 'prop-types';

import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    Col
  } from "reactstrap";

import { injectIntl , FormattedMessage } from 'react-intl';
import BusinessService from "../../../proxies/BusinessService";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import DefaultLabel from "../common/defaultLabel";
import Rating from "../rating/rating";
import Title3 from "../common/title2";
import Message from "../common/message";
import WebsiteHelper from "../../../helpers/WebsiteHelper";

var moment = require('moment');
require('moment/locale/fr');

class PaymentMethodNumber extends React.Component {

    constructor(props) {
        super(props)

        this.businessService = new BusinessService();

        this.state = {
            loading: true,
            seconds: this.props.seconds ? this.props.seconds : 5
        }
    }

    componentDidMount()
    {
    }

    asBlockOf(value, blockSize = 4, separator = "-")
    {
        if (value == null || value.length < blockSize)
        {
            return value
        }

        const length = value.length;
        const nbBlocks = parseInt(length / blockSize) + (length % blockSize > 0 ? 1 : 0)
        //console.log(nbBlocks)
        var formattedValue = "";
        for(var i = 0; i < nbBlocks; i++)
        {
            formattedValue += separator + value.substr(i * blockSize, 4);
        }

        return formattedValue.substr(separator.length);
    }

    renderIban()
    {
        return this.asBlockOf(this.props.value, 4, "-");
    }

    renderCardNumber()
    {
        return this.asBlockOf(this.props.value, 4, "-");
    }

    render() {
        switch(this.props.type)
        {
            case "iban": 
                return this.renderIban();
            break;
            case "card_number":
                return this.renderCardNumber();
            break;
            default:
                return <></>
            break;
        }
    }
}

PaymentMethodNumber.propTypes = {
    value: PropTypes.string.isRequired,
    type:  PropTypes.oneOf(["iban", "card_number"]).isRequired
};

export default injectIntl(PaymentMethodNumber)
