import React, { useEffect, useState } from "react"
import WebsiteHelper from "../../../helpers/WebsiteHelper"

const UtilityCustom = ({ setDisplay }) => {
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		const iframe = document.getElementById("iframeV2")
		if(iframe && iframe.parentNode) {
			iframe.addEventListener("load", onLoad)
		}
	}, [])

	function onLoad() {
		setLoaded(true)
	}

	useEffect(() => {
		if (loaded) {
			let win = window.frames.iframeV2
			win.postMessage({
				token: localStorage.getItem("token")
			}, process.env.REACT_APP_FRONT_V2_DOMAIN)
		}
	}, [loaded])

	useEffect(() => {
		function receiveMessage(event) {
			if (event.origin !== process.env.REACT_APP_FRONT_V2_DOMAIN) return
      if(window.self === window.top) {
        console.log(event.data)
        if(event.data.goToMain) {
          setDisplay(0)
        } else if(event.data.goToHome) {
          WebsiteHelper.goTo(this, "/admin/orders")
        }
      } else if(event.data.goToMain || event.data.goToHome) {
        window.location.href = process.env.REACT_APP_WORDPRESS_MAIN_DOMAIN
      }

      if(event.data.goToContact) {
        window.location.href = process.env.REACT_APP_WORDPRESS_MAIN_DOMAIN + "/contactez-nous";
      }
		}
		window.addEventListener("message", receiveMessage, false)

		// N'oubliez pas de supprimer l'écouteur d'événements lorsque le composant est démonté
		return () => {
			window.removeEventListener("message", receiveMessage, false)
		}
	}, [])

	useEffect(() => {
		if (loaded) {
			let win = window.frames.iframeV2
			win.postMessage({
				token: localStorage.getItem("token")
			}, process.env.REACT_APP_FRONT_V2_DOMAIN)
		}
	}, [loaded])

	return (
		<div className="iframe-wrapper"
				 style={{ height: "99vh"}}
		>
			{!loaded &&
          <div className="loader">
              Chargement...
          </div>
			}
			<iframe
				id='iframeV2'
				style={{ width: "100%", height: "100%", border: "none" }}
				className="iframe-V2"
				src={`${process.env.REACT_APP_FRONT_V2_DOMAIN}/utility-custom`}
				name="iframeV2"
			/>
		</div>

	)
}

export default UtilityCustom