/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardText,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import SecurityService from "../../../proxies/SecurityService";
import { injectIntl , FormattedMessage } from 'react-intl';
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormInput from '../components/FormInput.jsx';
import FormInputValidationMessages from '../components/FormInputValidationMessages.jsx';
import DefaultButton from "../../components/common/defaultButton";

class RegisterConfirmation extends FormValidationComponent {
  constructor(props)
  {
    super(props);

    this.state = {
      loading : false
    }
    this.securityService = new SecurityService();
  }

  componentDidMount()
  {
    // Si aucune donnée alors redirection vers la page de connexion
    const email = this.getEmail();
    const accountType = this.getAccountType();

    if (this.notEmptyValue(email) == false || this.notEmptyValue(accountType) == false)
    {
      this.props.history.push('/auth/login');
    }
  }

  /**
   * Récupération du type de compte en mémoire
   */
  getAccountType()
  {
    const accountType = this.securityService.getData("register_account_type");
    if (this.notEmptyValue(accountType) )
    {
      return this.translateMessage("RegisterConfirmation." + accountType)
    }

  }

  /**
   * Récupération de l'email en mémoire
   */
  getEmail()
  {
    return this.securityService.getData("register_email")
  }


  /**
   * Envoi à nouveau du mail de validation de compte
   */
  resendRegisterConfirmationEmail()
  {
    this.securityService.resendRegisterEmail(this, this.getEmail(), this.emailResended.bind(this));
  }

  /*
  * Confirmation de l'envoi d'un nouveau mail
  */
  emailResended(response)
  {
    const message = this.translateMessage("RegisterConfirmation.resend_confirmation_message", {email : this.getEmail()})
    const title = this.translateMessage("RegisterConfirmation.resend_confirmation_title")

    this.confirmMessage(message, title);
  }

  render() {
    return (
      <>
        {this.renderParent()}
        <AuthHeader
          title={this.translateMessage("RegisterConfirmation.title1")}
          lead=""
        />
        <Container className="mt--8 pb-5">
          <FormLoadingIndicator loading={this.state.loading} />

          <Row className="justify-content-center">
            <Col md="8">
              <Card className="bg-secondary border-0">
                <CardBody>
                    <CardTitle className="mb-3" tag="h3">
                      {this.translateMessage("RegisterConfirmation.title2")}
                    </CardTitle>
                    <CardText className="mb-4">
                      <FormattedMessage
                        id="RegisterConfirmation.message"
                        values={{
                          email: this.getEmail(),
                          type: this.getAccountType()
                        }}
                      >
                        {this.decodeEntities}
                      </FormattedMessage>
                    </CardText>
                    <DefaultButton
                      className={"t2y-secondary-button"}
                      onClick={this.resendRegisterConfirmationEmail.bind(this)}
                    >
                      <FormattedMessage
                        id="RegisterConfirmation.resend_email"
                      />
                    </DefaultButton>
                  </CardBody>  
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(RegisterConfirmation);
