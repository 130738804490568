import Config from '../config.js'
import ProxyService from './ProxyService'
import axios from "axios";

class AdministratorService extends ProxyService {

    getTransporterIbanToValidate(caller, transporterId, okCallback, errorCallback = null)
    {
        if (caller) 
        {
            caller.setState({loading : true});
        }
        const apiUrl = this.apiUrls["TRANSPORTER_GET_PAYMENT_METHOD"] + transporterId;
        this.get(caller, apiUrl, okCallback, errorCallback, this.getAdministratorToken());
    }

    getTransporterIbanAttachment(caller, transporterId, okCallback, errorCallback = null)
    {
        if (caller) 
        {
            caller.setState({loading : true});
        }
        const apiUrl = this.apiUrls["TRANSPORTER_GET_PAYMENT_METHOD_ATTACHMENT"].replace('{transporterId}', transporterId);
        this.get(caller, apiUrl, okCallback, errorCallback, this.getAdministratorToken());
    }

    acceptPaymentMethod(caller, transporterId, okCallback, errorCallback = null)
    {
        if (caller) 
        {
            caller.setState({loading : true});
        }
        const apiUrl = this.apiUrls["TRANSPORTER_ACCEPT_PAYMENT_METHOD"].replace('{transporterId}', transporterId);
        this.post(caller, apiUrl, {}, okCallback, errorCallback, this.getAdministratorToken());
    }

    refusePaymentMethod(caller, transporterId, okCallback, errorCallback = null)
    {
        if (caller) 
        {
            caller.setState({loading : true});
        }
        const apiUrl = this.apiUrls["TRANSPORTER_REFUSE_PAYMENT_METHOD"].replace('{transporterId}', transporterId);
        this.post(caller, apiUrl, {}, okCallback, errorCallback, this.getAdministratorToken());
    }

    getTransportersPaymentLot(caller, paymentLotId, okCallback, errorCallback)
    {
        if (caller) 
        {
            caller.setState({loading : true});
        }
        const apiUrl = this.apiUrls["TRANSPORTER_GET_PAYMENT_LOT"] + paymentLotId;
        this.get(caller, apiUrl, okCallback, errorCallback, this.getAdministratorToken());
    }

    getPaymentLotAttachmentsAsZip(caller, paymentLotId, okCallback, errorCallback)
    {
        if (caller) 
        {
            caller.setState({loading : true});
        }
        const apiUrl = this.apiUrls["TRANSPORTER_GET_PAYMENT_LOT_ATTACHMENTS_AS_ZIP"].replace("{paymentLotId}", paymentLotId);
        this.get(caller, apiUrl, okCallback, errorCallback, this.getAdministratorToken());
    }

    paymentLotSent(caller, paymentLotId, okCallback, errorCallback)
    {
        if (caller) 
        {
            caller.setState({loading : true});
        }
        const apiUrl = this.apiUrls["TRANSPORTER_PAYMENT_LOT_SENT"].replace("{paymentLotId}", paymentLotId);
        this.post(caller, apiUrl, {}, okCallback, errorCallback, this.getAdministratorToken());
    }

    paymentLotRefused(caller, paymentLotId, okCallback, errorCallback)
    {
        if (caller) 
        {
            caller.setState({loading : true});
        }
        const apiUrl = this.apiUrls["TRANSPORTER_PAYMENT_LOT_REFUSED"].replace("{paymentLotId}", paymentLotId);
        this.post(caller, apiUrl, {}, okCallback, errorCallback, this.getAdministratorToken());
    }


}



export default AdministratorService;