import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import DefaultInput from '../../../components/common/defaultInput';
import DefaultCheckbox from '../../../components/common/defaultCheckbox';
import DefaultPopover from '../../../components/common/defaultPopover';
import Message from '../../../components/common/message';
import BusinessService from "../../../../proxies/BusinessService";
import GeocodingAndNavigationService from "../../../../proxies/GeocodingAndNavigationService";
import Step from "../step"
import Resources from "../../../../resources";
import StringHelper from "../../../../helpers/StringHelper";
import { add } from "date-fns";
import AddressComponent from "./addressComponent";
import Separator from "../../common/separator";
import Title2 from "../../common/title2";
import Title3 from "../../common/title3";
import Title4 from "../../common/title4";
import ShipperCustomerInput from "../../business/shipperCustomerInput";
import ShipperService from "../../../../proxies/ShipperService";
import CustomerAddressContacts from "../../mission/CustomerAddressContacts";

var moment = require('moment');
require('moment/locale/fr');

class AddressConfirmationStep extends Step {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.shipperService = new ShipperService();
        this.geocodingAndNavigationService = new GeocodingAndNavigationService();

        this.validationRules = {
            street : {
                required : true
            },
            zip_code : {
                required : true
            },
            city : {
                required : true
            },
            address_name : {
                required : true
            },
            customer_search_input: {
                required: true
            },
            first_name: {
                required: true,
            },
            last_name: {
                required: true,
            },
            mobile_phone_number: {
                rules: [
                    { rule: this.mobilePhoneNumber.bind(this) }
                ]
            },
            email: {
                rules: [
                    { rule: this.email.bind(this) }
                ]
            }
        }

        this.state = {
            loading: false,
            address_name: false
        }
    }

    componentWillMount() {
        super.componentWillMount()
    }

    componentDidMount() {
        super.componentDidMount()
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }

        var address = this.props.workflow.data ? this.props.workflow.data : null;
        if (address == null && this.props.address != null)
        {
            address = this.props.address
        }
        
        if (address != null && address.id == null)
        {
            this.streetInputRef.setValue(address.street)
            this.street2InputRef.setValue(address.street2)
            this.zipCodeInputRef.setValue(address.zip_code)
            this.cityInputRef.setValue(address.city)

            if (address.name)
            {
                this.setState({address_name: true})
                if (this.addressNameInputRef)
                {
                    this.addressNameInputRef.setValue(address.name)
                }
            }
        }
    }
    
    submit(pid)
    {
        var formIsValid = this.htmlFormIsValid();
        var data = this.props.workflow.data;
        var customer = data ? data.customer : null

        if (formIsValid)
        {
            // 
            const customerCompanyAddress = data.customer_company_address

            const street = this.streetInputRef ? this.streetInputRef.getValue() : data.street;
            const street2 = this.street2InputRef ? this.street2InputRef.getValue() : data.street2;
            const zipCode = this.zipCodeInputRef ? this.zipCodeInputRef.getValue() : data.zip_code;
            const city = this.cityInputRef ? this.cityInputRef.getValue() : data.city;

            const company = this.shipperCustomerInputRef ? this.shipperCustomerInputRef.isCompany() : true;
            const companyName = this.getHtmlFormControlValue("customer_search_input");
            const firstName = this.getHtmlFormControlValue("first_name");
            const lastName = this.getHtmlFormControlValue("last_name");
            const mobilePhoneNumber = this.getHtmlFormControlValue("mobile_phone_number");
            const email = this.getHtmlFormControlValue("email");

            //Calcul des coordonnées géographiques et vérification du niveau de géocodage
            this.geocodingAndNavigationService.getPointAndValidateLevel(this, street + " " + zipCode + " " + city, (geocodeInfos) => {
                    
                    if (geocodeInfos == null)
                    {
                        this.showError("L'adresse renseignée ne peut pas être géocodée. Merci d'en saisir une nouvelle", "")
                    }
                    else
                    {   
                        if (data == null)
                        {
                            data = geocodeInfos;
                        }

                        super.submit(pid)
                        // Récupération des données
                        this.setState({loading: true})

                        // On remonte l'information à la page parent
                        if (data.id == null)
                        {
                            data.street = street;
                            data.street2 = street2;
                            data.zip_code = zipCode;
                            data.city = city;
                            data.country_code = "COUNTRY_FR";
                            data.name = this.addressNameInputRef ? this.addressNameInputRef.getValue() : null;
                        }

                        data.customer_company_address = customerCompanyAddress;
                        data.gps_lat = geocodeInfos.gps_lat
                        data.gps_long = geocodeInfos.gps_long
                        data.gps_external_id = geocodeInfos.gps_external_id
                        data.gps_level = geocodeInfos.gps_level

                        if (this.props.workflow.isCustomerMode())
                        {
                            data.customer = {
                                id: customer ? customer.id : null,//this.getHtmlFormControlValue("customer_id"), //customer ? customer.id : null,
                                label: this.getHtmlFormControlValue("customer_search_input"), //customer ? customer.label : null,
                                first_name: firstName,
                                last_name: lastName,
                                mobile_phone_number: mobilePhoneNumber,
                                email: email,
                                company: company
                            }

                        }

                        // Si c'est une nouvelle adresse interne, on l'enregistre
                        if ((StringHelper.notEmptyValue(data.name) || this.props.workflow.isCustomerMode()) && data.id == null)
                        {
                            if (this.props.workflow.isCustomerMode() == false)
                            {
                                // Enregistrement de l'adresse
                                data.type_code = "ADDRESS_TYPE_INTERNAL"
                                this.businessService.createAddress(this, data, (response)  => {
                                    data.id = response.id;
                                    this.props.workflow.data = data;
                                    this.props.onAddressSubmited(data, () => {
                                        this.props.workflow.goToNextStep(pid)
                                    });
                                })
                            }
                            else // Enregistrement de l'adresse du client (et du client si il n'existe pas)
                            {
                                this.shipperService.saveCustomerAddress(this, data, (response) => {
                                    if (this.notEmptyValue(data.customer.first_name))
                                    {
                                        data.customer.label = data.customer.first_name + " " + data.customer.last_name
                                    }

                                    data.id = response.id;
                                    data.type_code = "ADDRESS_TYPE_CUSTOMER"
                                    data.customer.id  = response.customer_id
                                    this.props.workflow.data = data;
                                    this.props.onAddressSubmited(data, () => {
                                        this.props.workflow.goToNextStep(pid)
                                    });
                                })

                            }
                        }
                        else
                        {

                            data.customer = customer
                            this.props.workflow.data = data;
                            this.props.onAddressSubmited(data, () => {
                                this.props.workflow.goToNextStep(pid)
                            });
                        }
                    }
                }
            );

            
        }
    }

    operationalAddressChanged(e)
    {
        this.setState({address_name: !this.state.address_name})
    }

    renderManualAddress()
    {
        return <>
            <Container style={{overflow:"scroll"}}>
                <FormLoadingIndicator loading={this.state.loading}>
                <Message type="infos">
                    Merci de valider, et corriger au besoin, les données de l'adresse selectionnée
                </Message>
                <Row className="mb-2">
                    <Col xl="12">
                    <DefaultInput id="street"
                        childRef={(elt) => this.streetInputRef = elt} 
                        required={true}
                        placeholder={"Adresse"}>
                    </DefaultInput>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xl="12">
                    <DefaultInput id="street2" 
                        childRef={(elt) => this.street2InputRef = elt} 
                        placeholder={"Complément"}>
                    </DefaultInput>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xl="4">
                    <DefaultInput id="zip_code" 
                        childRef={(elt) => this.zipCodeInputRef = elt} 
                        required={true}
                        placeholder={"Code postal"}>
                    </DefaultInput>
                    </Col>
                    <Col xl="8">
                    <DefaultInput id="city" 
                        required={true}
                        childRef={(elt) => this.cityInputRef = elt} 
                        placeholder={"Ville"}>
                    </DefaultInput>
                    </Col>
                </Row>
                {
                    (this.props.addressConfirmationAllowOperationalAddress == true)?
                    (
                        <>
                        <hr/>
                        <Row>
                            {
                                (!this.props.creation) ?
                                (
                                    <Col xl="12">
                                        <DefaultCheckbox id="operational_address"
                                                onChange={(e) => this.operationalAddressChanged(e)}
                                                content={this.props.intl.formatMessage({id: "address.confirmation_operational_address"})}
                                                popover={
                                                    <DefaultPopover content={
                                                        StringHelper.translate(this, "address.confirmation_operational_address_infos")} />
                                                } 
                                        />
                                    </Col>
                                ) : (<></>)
                            }
                            
                            {
                                (this.state.address_name || (this.props.creation != null && this.props.creation)) ?
                                (
                                    <Col xl="12">
                                    <DefaultInput id="address_name"
                                        required={true}
                                        childRef={(elt) => this.addressNameInputRef = elt} 
                                        placeholder={this.translateMessage("address.confirmation_form_address_name")}
                                        popover={
                                                <DefaultPopover embeded={true} 
                                                    content={StringHelper.translate(this, "address.confirmation_form_address_name_infos")} />
                                            } 
                                    >
                                    </DefaultInput>
                                    </Col>
                                ) : (<></>)
                            }
                            <hr/>
                        </Row>
                        </>
                    ) : (<>
                    
                    {
                        (this.props.customer && this.props.customer == true) ?
                        (
                            <>
                                <Separator></Separator>
                                <input type="hidden" id="customer_id" />
                                <Row className="mb-2">
                                    <Col xl="12">
                                    <Title4>
                                        <FormattedMessage id="address.confirmation_customer_infos"></FormattedMessage>
                                    </Title4>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col xl="12">
                                        <ShipperCustomerInput required={true} childRef={(elt) => this.shipperCustomerInputRef = elt} company={this.props.workflow.data ? this.props.workflow.data.company : true} value={this.props.workflow.data ? this.props.workflow.data.customer : null}></ShipperCustomerInput>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col xl="12">
                                        <DefaultInput id="customer_address_name"
                                            childRef={(elt) => this.addressNameInputRef = elt} 
                                            placeholder={this.translateMessage("address.confirmation_form_address_name")}
                                            popover={
                                                    <DefaultPopover embeded={true} 
                                                        content={StringHelper.translate(this, "address.confirmation_form_address_name_infos")} />
                                                } 
                                        >
                                        </DefaultInput>
                                    </Col>
                                </Row>
                                <Separator />
                            </>
                        ) : (<></>)
                    }
                    </>)
                }
                </FormLoadingIndicator>
                </Container>
            </>
    }

    renderOperationalAddress()
    {
        return <>
            <AddressComponent address={this.props.workflow.data}></AddressComponent>
            <CustomerAddressContacts childRef={elt => this.customerAddressContactsRef = elt} address={this.props.workflow.data}></CustomerAddressContacts>
        </>
    }

    render() {
        
        if (this.props.workflow.data && this.props.workflow.data.id)
        {
            return this.renderOperationalAddress();
        }
        else
        {
            return this.renderManualAddress();
        }
    }
}


export default injectIntl(AddressConfirmationStep)

