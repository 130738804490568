import React from "react";

import { injectIntl } from 'react-intl';
import Workflow from "../workflow";
import DriverIdentityStep from "./driverIdentityStep"


class DriverWorkflow extends Workflow {

    constructor(props) {
        super(props)


        this.state = {
            visible: false,
            current_step: this.props.current_step ? this.props.current_step : "DRIVER_IDENTITY_STEP",
            steps: {
                "DRIVER_IDENTITY_STEP": {
                    id: "DRIVER_IDENTITY_STEP",
                    position:1,
                    component: <DriverIdentityStep onDriverSubmited={this.props.onDriverSubmited} workflow={this} id="DRIVER_IDENTITY_STEP"></DriverIdentityStep>,
                    nexts: [
                        {   step: null, label: "Terminer",
                            visible: true
                        }
                    ]
                }
            }
        }
    }

    componentWillMount() {
        super.componentWillMount();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    reset()
    {
        this.setState({
            current_step: "DRIVER_IDENTITY_STEP",
            input: null
        })
    }

    onSlidingPanelClosed()
    {
        if (this.props.parent)
        {
            this.props.parent.end();   
        }
    }

    render() {
        return (
            <>
                {this.renderCurrentStep()}
            </>
        );
    }
}


export default injectIntl(DriverWorkflow)

