import React from "react";
// react library for routing
import { Route, Switch } from "react-router-dom";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import PageNotFound from "../components/notFound/PageNotFound.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import Config from "../config.js"
import routes from "../routes.js";

class Admin extends React.Component {
  state = {
    sidenavOpen: Config.showSideBar
  };
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        const innnerComponent = prop.component
        
        return (
          <Route
            path={prop.layout + prop.path}
            component={innnerComponent}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    });
  };
  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light";
  };
  render() {
    return (
      <>
        {
          (Config.showSideBar) ?
          (
            <Sidebar
              {...this.props}
              routes={routes}
              toggleSidenav={this.toggleSidenav}
              sidenavOpen={this.state.sidenavOpen}
              logo={{
                innerLink: "/",
                imgSrc: require("../assets/img/brand/argon-react.png").default,
                imgAlt: "..."
              }}
            />
          ) : ("")
        }
        <AdminNavbar className="t2y-admin-navbar" />
        <div className="main-content t2y-main-admin-content" ref="mainContent" /*onClick={this.closeSidenav}*/>
          <Switch>
            {this.getRoutes(routes)}
            <Route path={"/admin/*"} component={PageNotFound}/>
          </Switch>
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

export default Admin;
