
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Button
  } from "reactstrap";
import Spinner from 'react-spinners/BarLoader'
import ClipLoader from 'react-spinners/ClipLoader'
import DefaultImage from "./defaultImage";
import StringHelper from "../../../helpers/StringHelper";

class DefaultButton extends React.Component {

    constructor(props)
    {
        super(props)

        this.state = {
        }
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    componentWillMount() {}

    componentWillUnmount() {}

    render() {
        if (this.props.loading || this.state.loading)
        {
            return <Button className={"t2y-default-button btn-icon btn-2 btn-loading "  + this.props.className} 
                style={this.props.style} key={this.props.key} color="primary" type="button">
                {
                    (this.props.label) ?
                    (
                        <>
                            {this.props.loading_label ? "" : this.props.loading_label}
                            <Spinner></Spinner>
                        </>
                    )
                    :
                    (<><ClipLoader size="22" /></>)
                }
                
            </Button>
        }
        else
        {
            return <Button className={"t2y-default-button btn-icon btn-2 " + this.props.className } key={this.props.key} color="primary" 
                type={!this.props.type ? "button" : this.props.type} 
                style={this.props.style}
                onClick={this.props.onClick}
            >
                {
                    (this.props.icon || this.props.svg) ?
                    (
                        <>
                        <span className="btn-inner--icon">
                            <DefaultImage key={StringHelper.createDomElementId()} src={this.props.icon ? this.props.icon : this.props.svg} />
                            {
                                /*(this.props.icon) ?
                                (
                                    <i className={this.props.icon} />
                                )
                                :
                                (
                                    <>
                                    <img
                                        alt="..."
                                        className=" "
                                        src={this.props.svg}
                                        style={{ width: "20px"}}
                                    />
                                    </>
                                )*/
                            }
                            
                        </span>
                        {
                            (this.props.label) ?
                            (
                                <span className="btn-inner--text">{this.props.label}</span>
                            ) : 
                            (
                                <span className="btn-inner--text">{this.props.children}</span>
                            )
                        }
                        </>
                    )
                    : (
                        <span className="btn-inner--text">{this.props.label ? this.props.label : this.props.children}</span>
                    )
                }
            </Button>
        }
    }
}

export default DefaultButton
