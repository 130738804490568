// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://stripe.com/docs/payments/accept-a-payment#web

import React, { useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer, IbanElement} from '@stripe/react-stripe-js';
import axios from "axios";
import $ from "jquery";
// reactstrap components
import {
  Input,
  Alert,
  Button,
  Row,
  Col
} from "reactstrap";
import ShipperService from "../../../proxies/ShipperService.js";
import Resources from '../../../resources';
import { injectIntl , FormattedMessage } from 'react-intl';
import Message from '../common/message.jsx';
import DefaultButton from '../common/defaultButton.jsx';
import DefaultCheckbox from '../common/defaultCheckbox.jsx';
import DefaultPopover from '../common/defaultPopover.jsx';
import DateLabel from '../common/dateLabel.jsx';
import FormLoadingIndicator from '../../pages/components/FormLoadingIndicator.jsx';
import Title3 from '../common/title3.jsx';
import Title2 from '../common/title3.jsx';
import MutedText from '../common/mutedText.jsx';
import StringHelper from '../../../helpers/StringHelper.js';
import FormValidationComponent from '../../pages/components/FormValidationComponent.jsx';
import FormGroupInput from '../../pages/components/form/FormGroupInput.jsx';
import DefaultInput from '../common/defaultInput'
import Codes from '../../../codes.js';

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {

  base: {
    margin: "10px",
    borderRadius: "25px",
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  }
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: "FR",
  style: IBAN_STYLE,
  hideIcon: true,
  classes: {
    base: "btn t2y-default-input-container stripe-input-element",
    focus:  "btn t2y-default-input-container stripe-input-element",
    complete: "t2y-required-with-value",
    empty: "t2y-required",
    invalid: "t2y-required"
  }
};

class SepaSourceComponent extends FormValidationComponent {

  constructor(props) {
    super(props);

    this.shipperService = new ShipperService();
    this.intl = this.props.intl;

    this.state = {
      paymentSourceIdToUse: null,
      isProcessing: false,
      checkoutError: null,
      ibansAvailableLoaded: false
    };
  }

  setProcessingTo(value)
  {
    this.setState({isProcessing: value})
  }

  setCheckoutError(error)
  {
    this.setState({checkoutError: error})
  }

  componentWillMount()
  {
    // Récupération des méthodes de paiement déjà enregistrées
    if (!this.props.forceNewAndReusable)
    {
      this.shipperService.getIbansAvailable(this, (response) => {
        this.setState({
          ibans: response.payment_methods,
          ibansAvailableLoaded: true,
          paymentSourceIdToUse: response.payment_methods.length > 0 ? response.payment_methods[0].payment_external_id : "new"
        })
      })
    }
    else
    {
      this.setState({
        ibans: [],
        ibansAvailableLoaded: true,
        paymentSourceIdToUse: "new"
      })
    }
  }

  handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (this.state.paymentSourceIdToUse == null)
    {
      this.errorNotification("", "Merci de selectionner un iban existant ou d'en saisir un nouveau.");
      return false;
    }

    this.setState({loading: true})
    var currentComponent = this;

    const {orderId, lastPayment, onlyPaymentMethod, htAmount, ttcAmount, stripe, elements, onStart, onSuccess, onError, paymentButtonTitle} = this.props;
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    onStart()
    // Get a reference to a mounted IbanElement. Elements knows how
    // to find your IbanElement because there can only ever be one of
    // each type of element.
    const ibanElement = elements.getElement(IbanElement);
    var paymentMethodId = null;
    var sourceChargeId = null;
    var newPaymentMethod = false;
    var mandateRumNumber = null;
    var hasError = false;
    if (this.state.paymentSourceIdToUse == "new")
    {
      // Vérification des données saisies
      const name = this.getHtmlFormControlValue("name")
      const email = this.getHtmlFormControlValue("email")
      if (!this.notEmptyValue(name) || !this.notEmptyValue(email) || !this.email(email))
      {
        this.errorNotification("", "Merci de renseigner correctement les champs nom et adresse email.")
        this.setState({loading: false})
        return false;
      }

      // ============================================================
      // ============================================================
      // Création de la source à partir des données de l'iban
      // ============================================================
      // ============================================================
      const createSourceResponse =  await stripe.createPaymentMethod({
        type: 'sepa_debit',
        sepa_debit: ibanElement,
        billing_details: {
          name: name,
          email: email
        }
      });

      if (createSourceResponse.error)
      {
        currentComponent.setState({loading: false})
        onError(createSourceResponse.error.message);
        this.setCheckoutError(createSourceResponse.error.message);
        this.setProcessingTo(false);
        return;
      }
      newPaymentMethod = true;
      paymentMethodId = createSourceResponse.paymentMethod.id
      mandateRumNumber = createSourceResponse.paymentMethod.sepa_debit.fingerprint
      //rumNumber = createSourceResponse.source.
      // On attache la source au client et on finalise les données du mandat (ip, status)
      const attachPaymentSourceResponse = await axios.post(this.shipperService.getUrlFor("SHIPPER_ATTACH_PAYMENT_METHOD_TO_SHIPPER"), {
        payment_method_id: paymentMethodId,
        meta_data: createSourceResponse.paymentMethod,
        // Ce moyen de paiement sera obligatorement réutilisable
        reuse_type_code: "PAYMENT_METHOD_REUSE_TYPE_ALWAYS"
      }, { headers: this.shipperService.buildHeaders()}).catch(function (error) {
        hasError = true;
        currentComponent.setState({loading: false})
        onError(error)
      })
    }
    else
    {
      // Utilisation du mode de paiment déjà connu
      paymentMethodId = this.state.paymentSourceIdToUse;
    }
    if (hasError == false)
    {
      if (!onlyPaymentMethod)
      {
        // ============================================================
        // Création de la tentative de paiement
        // ============================================================
        var intentError = null
        const paymentIntentResponse = await axios.post(this.shipperService.getUrlFor("SHIPPER_CREATE_PAYMENT_INTENT_IBAN"), {
          amount: ttcAmount,
          order_id: orderId,
        }, { headers: this.shipperService.buildHeaders()})
        .catch(error => {
          intentError = error
        });

        if (intentError == null)
        {
          sourceChargeId = paymentIntentResponse.data.id;
          const clientIndentSecret = paymentIntentResponse.data.transaction_id;
          const confirmPaymentResponse = await stripe.confirmSepaDebitPayment(clientIndentSecret, {
            payment_method: paymentMethodId
          })

          // const confirmPaymentResponse = await stripe.confirmPayment(clientSecret, {
          //   payment_method: paymentMethodId
          // });

          if (confirmPaymentResponse.error) {
            currentComponent.setState({loading: false})
            onError(confirmPaymentResponse.error.message);
            this.setCheckoutError(confirmPaymentResponse.error.message);
            this.setProcessingTo(false);
            return;
          }

        }
        else {
          currentComponent.setState({loading: false})
          onError(intentError.response);
          this.setProcessingTo(false);
          return;
        }
      }

      if (newPaymentMethod == true)
      {
        this.shipperService.sendSepaMandateByEmail(null, paymentMethodId, () => {});
      }

      // Action effectuée avec succès
      onSuccess({
        amount_paied_ttc: ttcAmount,
        amount_paied_ht: htAmount,
        payment_external_id: sourceChargeId,
        payment_methodExternal_id: paymentMethodId,
        new_payment_method: newPaymentMethod,
        mandate_rum_number: mandateRumNumber,
        type: Codes.IBAN
      });
    }
  };

  renderIbanInformationMessage(sepaAlert = false)
  {
    var content = null;
    var sepaMandatAlert = StringHelper.translateWithHtml(this, "Payment.sepa_mandat_alert")
    var useFinalPayment = <FormattedMessage id={this.props.forceNewAndReusable ? "Payment.iban_use_for_futur_payment" : "Payment.iban_use_for_final_payment"}></FormattedMessage>
    if (this.props.lastPayment)
    {
      if (this.state.paymentSourceIdToUse == "new")
      {
        content = <div id="mandate-acceptance" className="mandate-acceptance" style={{width:"97%", float:"right"}}>{sepaMandatAlert}</div>
      }
      else
      {
        content = null;
      }
    }
    else
    {
      if (sepaAlert)
      {
        content = <><div id="mandate-acceptance" className="mandate-acceptance" style={{width:"97%", float:"right"}}>{sepaMandatAlert}<br/>{useFinalPayment}</div></>
      }
      else
      {
        content = <>{useFinalPayment}</>
      }
    }
    if (content == null)
    {
      return <></>
    }
    else
    {
      return <Row>
        <Message type="default">{content}</Message>
      </Row>
    }
  }

  render() {
    const {stripe} = this.props;
    return (
      <>
      {this.renderParent()}
      <FormLoadingIndicator loading={this.state.loading} overlay={false}></FormLoadingIndicator>
      <form onSubmit={this.handleSubmit}>

        {
          (this.state.ibansAvailableLoaded) ?
          (
            <>
              {
                (this.state.ibans && this.state.ibans.length > 0) ?
                (
                  <>
                    {
                      this.state.ibans.map((iban, index) => {
                        return <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id={"iban" + index}
                            name="customRadio"
                            type="radio"
                            checked={this.state.paymentSourceIdToUse == iban.payment_external_id}
                            onClick={(e) => this.setState({paymentSourceIdToUse: iban.payment_external_id})}
                          />
                          <label className="custom-control-label" htmlFor={"iban" + index}>
                            Mandat n°{iban.iban_rum_number} sur le compte n° {iban.iban_displayed_label}<br/>signé le <DateLabel label={false} start_date={iban.creation_date}></DateLabel> par {iban.iban_creator_label}
                          </label>
                        </div>
                      })
                    }
                    <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          id={"new_iban"}
                          name="customRadio"
                          type="radio"
                          checked={this.state.paymentSourceIdToUse == "new"}
                          onClick={(e) => this.setState({paymentSourceIdToUse: "new"})}
                        />
                        <label className="custom-control-label" htmlFor={"new_iban"}>
                          Créer un mandat sur un nouveau compte ?
                        </label>
                    </div>

                    {
                      (this.state.paymentSourceIdToUse != "new") ?
                      (
                        this.renderIbanInformationMessage(false)
                      ) : (<></>)
                    }
                  </>
                ) : ("")
              }
              {
                (this.state.paymentSourceIdToUse == "new") ?
                (
                  <>
                    <Row>
                      <Col>
                          <FormGroupInput intlId={"sepa_user.name"} htmlFor="name" type="multiline">
                              <DefaultInput childRef={(elt) => this.contactNameInputRef = elt}  required={true} name='name' manager={this} />
                          </FormGroupInput>
                      </Col>

                      <Col>
                        <FormGroupInput intlId={"sepa_user.email"} htmlFor="email" type="multiline">
                              <DefaultInput type="email" childRef={(elt) => this.contactEmailInputRef = elt}  required={true} name='email' manager={this} />
                          </FormGroupInput>
                      </Col>
                    </Row>
                    <FormGroupInput intlId={"sepa.iban"} htmlFor="name" type="multiline">
                      <IbanElement  options={IBAN_ELEMENT_OPTIONS} />
                    </FormGroupInput>

                    {this.renderIbanInformationMessage(true)}
                  </>
                ) : ("")
              }

              <Row className="justify-content-center" style={{marginTop:"20px"}}>
                <DefaultButton type="submit" className="t2y-secondary-button" disabled={!stripe} color="success">
                  {this.props.paymentButtonTitle}
                </DefaultButton>
              </Row>
            </>
          ) : ("")
        }


      </form>
      </>
    );
  }
}

const InjectedSepaSourceComponent = ({orderId, lastPayment, onlyPaymentMethod, htAmount, ttcAmount, onStart, onSuccess, onError, intl, paymentButtonTitle, forceNewAndReusable}) => {

    return (
      <>
        <ElementsConsumer>
        {({elements, stripe}) => (
            <SepaSourceComponent intl={intl}
              orderId={orderId}
              lastPayment={lastPayment}
              onlyPaymentMethod={onlyPaymentMethod}
              forceNewAndReusable={forceNewAndReusable}
              htAmount={htAmount}
              ttcAmount={ttcAmount}
              elements={elements}
              stripe={stripe}
              onStart={onStart}
              onSuccess={onSuccess}
              onError={onError}
              paymentButtonTitle={paymentButtonTitle} />
        )}
        </ElementsConsumer>
      </>
    );
};

export default injectIntl(InjectedSepaSourceComponent);
