import React from "react";
import {
	Form,
	Row,
	Col,
	Modal,
} from "reactstrap";
import DefaultButton from "../common/defaultButton";
import DefaultLink from '../common/defaultLink';
import SecurityService from "../../../proxies/SecurityService";
import NotificationComponent from "../../pages/components/NotificationComponent";
import ReactSlidingPane from "react-sliding-pane";

var moment = require('moment');
require('moment/locale/fr');

class Workflow extends NotificationComponent {

	constructor(props) {
		super(props)

		this.securityService = new SecurityService();

		this.references = []
		this.pids = []
		this.state = {
			size: "xl"
		}
	}

	componentWillMount()
	{
		super.componentWillMount();
	}

	componentDidMount()
	{
		super.componentDidMount();
		if (this.props.childRef)
		{
			this.props.childRef(this)
		}
	}

	generateUniqueId() {
		/*
		var dt = new Date().getTime();
		var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = (dt + Math.random()*16)%16 | 0;
			dt = Math.floor(dt/16);
			return (c=='x' ? r :(r&0x3|0x8)).toString(16);
		});
		return uuid;
		*/
		return Math.random().toString(16).slice(-4);
	}


	notConnected() {
		this.securityService.disconnect();
	}

	addRef(id, ref) {
		var references = this.references;

		// Si le composant existe déjà, on le remplace pour éviter les problème de state
		var existingRef = this.getRef(id);
		if (existingRef[0] != null) {

			references.splice(existingRef[1], 1)
		}

		references.push(
			{
				id: id,
				ref: ref
			}
		)

		this.references = references;
	}

	// Recherche d'un composant et de sa position dans les références
	getRef(expectedId) {
		var ref = null;
		var index = -1;
		var refIndex = -1;
		this.references.forEach(x => {
			index += 1;
			if (x.id == expectedId) {
				ref = x;
				refIndex = index
			}
		})

		return [ref, refIndex];
	}

	addPid(pid, nextStep) {
		var pids = this.pids;
		pids.push(
			{
				pid: pid,
				next: nextStep
			}
		)

		this.pids = pids;
	}

	getPid(expectedPid) {
		var pid = null;
		this.pids.forEach(x => {
			if (x.pid == expectedPid) {
				pid = x
			}
		})

		return pid
	}

	prepareSubmit(step, button)
	{
		this.step = step
		this.button = button
	}

	// On exécute l'evenement submit rattaché au bouton
	submitStep(step, button, input = null) {
		const pid = this.generateUniqueId();

		if (input != null)
		{
			this.setInput(input)
		}

		this.references.forEach(reference => {
			if (button.step == "CANCEL")
			{
				this.onModalClose()
				return false;
			} else if(button.step === "REFRESH") {
				this.onModalClose()
				window.location.reload()
			} else {
				if (reference.id == step.id && button.step) {
					this.addPid(pid, button.step)
					reference.ref.submit(pid)
				}
			}
		})
	}

	goToNextStepWithoutPid(stepId)
	{
		const pid = this.generateUniqueId();
	}

	setInput(input)
	{
		this.setState({
			input: input
		})
	}

	goToSpecificStep(stepCode, input = null)
	{
		this.setState({
			current_step: stepCode,
			input: (input ? input : this.state.input)
		})
	}

	goToNextStep(expectedPid, input = null) {
		const pid = this.getPid(expectedPid)

		if (pid != null) {
			// On redirige vers l'étape suivante
			if (pid.next != null || pid.next != "EXIT") {
				this.setState({
					current_step: pid.next,
					input: (input ? input : this.state.input)
				})
			}
			else  //  fin du process
			{
				this.exit()
			}
		}
		else {
			this.exit()
		}
	}

	restore(childrenStepId) {
		console.log("restore " + this.props.id)
		if (this.state.steps) {
			for (const [id, step] of Object.entries(this.state.steps)) {
				if (id == childrenStepId) {
					if (step.next) {
						this.setState({ current_step: step.next })
						return;
					}
				}

			}
		}
		this.exit();
	}

	exit() {
		if (this.props.workflow) {
			this.props.workflow.restore(this.props.id)
		}
		else {
			// Fermeture de la fenetre du workflow
			if  (this.state.visible)
			{
				this.setState({ visible: false })
				console.log("Fin du processus " + this.props.id)

				if (this.props.parent) {
					this.props.parent.end();
				}
			}
		}
	}

	checkConditionResult(nextStepCode, currentStep) {
		var nextStep = nextStepCode;
		if (typeof nextStepCode === "function") {
			nextStep = nextStepCode(this, currentStep)
		}


		if (nextStep == "CURRENT")
			return true;

		if (nextStep == "EXIT") {
			this.exit()
			return false;
		}

		this.setState({ current_step: nextStep })
		return false;
	}

	checkStep(step) {
		if (!step) {
			this.exit()
			return false;
		}

		if (step.conditions != null && step.conditions.length > 0) {
			step.conditions.forEach(condition => {
				var result = condition.condition();
				if (result) {
					this.checkConditionResult(condition.step_on_success, step)
				}
				else {
					this.checkConditionResult(condition.step_on_failure, step)
				}

			})
		}
		return true;
	}


	checkWorkflowConditionResult(nextStep) {
		if (nextStep == "CURRENT")
			return true;

		/*if (nextStep == "EXIT") {
			this.exit()
			return false;
		}*/

		this.goToSpecificStep(nextStep)
		return false;
	}

	checkWorkflow(workflow)
	{
		if (!workflow) {
			return false;
		}

		if (workflow.conditions != null && workflow.conditions.length > 0) {
			workflow.conditions.forEach(condition => {
				var result = condition.condition();
				if (result) {
					this.checkWorkflowConditionResult(condition.step_on_success)
				}
				else {
					this.checkWorkflowConditionResult(condition.step_on_failure)
				}

			})
		}
		return true;
	}

	disableNexts(id) {

	}

	setWorkingValue(key, value)
	{
		this.setState({key: value})
	}

	getWorkingValue(key)
	{
		return this.state[key] ? this.state[key] : null;
	}

	hideAlert() {
		this.setState({ alert: null })
	}

	showError(message, title) {
		this.showModalNotification("error", <label dangerouslySetInnerHTML={{ __html: message }}></label>)
		//this.errorNotification(title, <label dangerouslySetInnerHTML={{ __html: message }}></label>, "Fermer");
	}

	showConfirmation(message, title) {
		alert("Confirmation : " + title + " => " + message)
	}


	renderWorkflow(item) {
		var result = this.checkWorkflow(item)
	
		if (result == false) {
			return <></>
		}

		return <>
			{item.component}
		</>
	}

	reload() {
		this.setState({ reload: true })
	}

	renderLinks(item) {
		// Récupération du pointeur sur le composant
		var ref = null;
		if (item.id) {
			var refInfos = this.getRef(item.id)
			if (refInfos && refInfos.length > 0 && refInfos[0] !== null) {
				ref = refInfos[0].ref
			}
		}

		if (!item.links || item.links.length == 0) {
			return <></>
		}
		else {
			return <>
				{item.links.map((link, index) => {
					// Vérification de la condition de visiblité du bouton
					var visible = true;
					if (ref && link.condition)
						(
							visible = link.condition(ref)
						)
					else {
						// Initialisation de la visiblité au démarrage
						visible = link.visible == null ? true : link.visible
					}

					if (visible) {
						var label = null;
						if (typeof link.label === "function") {
							label = link.label(ref)
						}
						else {
							label = link.label
						}

						return <DefaultLink key={"link-" + index} style={{ marginRight: "10px" }} small={true} onClick={(e) => {
							this.setState({ current_step: link.step })

							// Vérification de la présence de la function onLeave
							if (ref != null && typeof ref.onLeave === "function")
							{
								ref.onLeave()   
							}

							if (link.onClick)
							{
								link.onClick(ref);
							}
						}}>
							{label}
						</DefaultLink>
					}
					else{
						return <></>
					}
				})}
			</>
		}
	}

	renderNexts(item) {
		// Récupération du pointeur sur le composant
		var ref = null;
		if (item.id) {
			var refInfos = this.getRef(item.id)
			if (refInfos && refInfos.length > 0 && refInfos[0] !== null) {
				ref = refInfos[0].ref
			}
		}

		if (!item.nexts || item.nexts.length == 0) {
			return <></>
		}
		else {
			return <>
				{item.nexts.map((next, index) => {
					// Vérification de la condition de visiblité du bouton
					var visible = true;
					if (ref && next.condition)
						(
							visible = next.condition(ref)
						)
					else {
						// Initialisation de la visiblité au démarrage
						visible = next.visible == null ? true : next.visible
					}

					if (visible) {
						var label = null;
						if (typeof next.label === "function") {
							label = next.label(ref)
						}
						else {
							label = next.label
						}

						const buttonClassName = next.className ? next.className : "t2y-secondary-button"

						return <DefaultButton
							id={item.id + "_nextBtn"}
							type="submit"
							className={buttonClassName}
							key={"step_button" + index}
							onClick={(e) => this.prepareSubmit(item, next)} //this.submitStep(item, next)}
							label={label}
							icon={next.icon}>
						</DefaultButton>
					}
					else {
						return <></>
					}

				})}
			</>
		}
	}

	onSubmitForm(event)
	{
		event.preventDefault();
		if (this.step && this.button)
		{
			if (this.button.confirmation)
			{
				var options = {}
				options.iconStyle = this.button.confirmation.iconStyle
				options.iconColor = this.button.confirmation.iconColor
				options.title = this.button.confirmation.title
				options.component = this.button.confirmation.component
				options.cancelButtonTitle = this.button.confirmation.cancelButtonTitle
				options.okButtonTitle =  this.button.confirmation.okButtonTitle
				options.okButtonClassName = this.button.confirmation.okButtonClassName
				options.okButtonCallback = () => {
					
					this.submitStep(this.step, this.button);
				}

				this.showModalConfirmation(options)
			}
			else
			{
				this.submitStep(this.step, this.button);
			}
		}
	}


	renderStep(item) {
		// On vérifie que l'étape doit bien être affiché et qu'il ne faut pas passer à l'étape suivante
		var result = this.checkStep(item)
	
		if (result == false) {
			return <></>
		}
		
		return <>
			<Form className="needs-validation" noValidate onSubmit={e => {return this.onSubmitForm(e)}} >
			<Row>
				<Col xl="12">
					{item.component}
				</Col>
			</Row>
			{
				(item.hideNextsAndLinks) ?
				(
					<></>
				) : 
				(
					<>
						<Row className="align-item-center justify-content-center mb-3" >
							{this.renderNexts(item)}
						</Row>
						<Row className="align-item-center justify-content-center">
							{this.renderLinks(item)}
						</Row>
					</>
				)
			}
			</Form>
		</>


	}

	getCurrentStep()
	{
		return this.state.steps[this.state.current_step];
	}

	renderCurrentStepTitle()
	{
		var current = this.state.steps[this.state.current_step];

		if (current && current.type && current.type == "workflow") {
			return null
		}
		else
		{
			return current.title ? current.title : null 
		}
	}

	renderCurrentStep() {
		var current = this.state.steps[this.state.current_step];
		
		if (current && current.type && current.type == "workflow") {
			return <>
				{this.renderParent()}
				{this.state.alert}
				{this.renderWorkflow(current)}
			</>
		}
		else {
			return <>
				{this.renderParent()}
				{this.state.alert}
				{this.renderStep(current)}
			</>
		}

	}

	getSlindingBarOptions()
	{
		return {
			icon: null,
			hideHeader: false,
			shouldCloseOnEsc: true,
			closeIcon: null,
			title: null,
			subtitle: null,
			className: 'ome-custom-class',
			overlayClassName: "some-custom-overlay-class",
			onRequestClose: () => {
				// triggered on "<" on left top click or on outside click
				this.setState({ visible: false });

				// Si une méthode existe sur le workflow on l'exécute
				if (typeof this.onSlidingPanelClosed === "function")
				{
					this.onSlidingPanelClosed()
				}
			}
		}
	}

	renderAsSlidingPanel(options = null) {
		if (options == null)
		{
			options = this.getSlindingBarOptions()
		}

		return <>
			<ReactSlidingPane
				shouldCloseOnEsc={options.shouldCloseOnEsc}
				closeIcon={options.icon}
				hideHeader={options.hideHeader}
				className={options.className}
				overlayClassName={options.overlayClassName}
				isOpen={this.state.visible}
				title={options.title}
				subtitle={options.subtitle}
				width="600px"
				style={{overflow:"scroll"}}
				onRequestClose={options.onRequestClose}
			>
				<div className="container">
					{this.renderCurrentStep()}
				</div>
			</ReactSlidingPane>
		</>
	}

	renderLargeView() {
		this.setState({ size: "xl" })
	}

	renderNormalView() {
		this.setState({ size: "lg" })
	}

	setSize(size) {
		this.setState({ size: size })
	}


	onModalClose()
	{
		if (this.beforeClosingModal)
		{
			this.beforeClosingModal()
		}
		else
		{
			this.setState({ visible: false })
		}
	}

	renderAsModal() {
		return <>
			<Modal fade={true} className="modal-dialog-centered" size={this.state.size} isOpen={this.state.visible} toggle={() => this.onModalClose()} >
				<div className="modal-content">
					<div className="modal-header pb-0">
						{this.renderCurrentStepTitle()}
						<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.onModalClose()} >
						<span style={{fontSize:"40px"}} aria-hidden={true}>×</span>
						</button>
					</div>
					<div className="modal-body pt-0">

						{this.renderCurrentStep()}
					</div>
				</div>
			</Modal>
		</>
	}

}


export default Workflow

