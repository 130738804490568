import React from "react";
import { injectIntl } from 'react-intl';

class FormInputValidationMessages extends React.Component {

    constructor(props)
    {
        super(props)
        this.intl  = this.props.intl;
    }

    componentDidMount() { }

    getRequiredMessage()
    {
        var message = typeof this.props.validationRequiredMessage !== "undefined" && this.props.validationRequiredMessage !== null ? 
            this.props.validationRequiredMessage : this.intl.formatMessage({"id" : "Default.required_field"});
        return message;
    }

    getInvalidMessage()
    {
        return typeof this.props.validationInvalidMessage !== "undefined" && this.props.validationInvalidMessage !== null ? 
            this.props.validationInvalidMessage : this.intl.formatMessage({"id" : "Default.invalid_field"});
    }

    getValidMessage()
    {
        return typeof this.props.validationValidMessage !== "undefined" && this.props.validationValidMessage !== null ? 
            this.props.validationValidMessage : "";
    }

    render() {
      return (
        <>
            {
                (typeof this.props.validationState !== "undefined" && this.props.validationState !== null && this.props.validationState !== "") ?
                (
                    (this.props.validationState === "required") ?
                    (
                        <div className="invalid-feedback" style={{display:'block'}}>{this.getRequiredMessage()}</div>
                    )
                    :
                    (
                        (this.props.validationState === "valid") ?
                        (
                            <div className="invalid-feedback" style={{display:'block'}}>{this.getValidMessage()}</div>
                        )
                        :
                        (
                            <div className="invalid-feedback" style={{display:'block'}}>{this.getInvalidMessage()}</div>
                        )
                    )
                )
                :
                (
                    <div />
                )
            }
        </>
      );
    }
  }
 
  export default injectIntl(FormInputValidationMessages);