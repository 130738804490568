
import React from "react";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
// reactstrap components
import {
    Container,
    Row,
    Col
  } from "reactstrap";
  

import queryString from 'query-string';
import { injectIntl, FormattedMessage } from 'react-intl';
import SecurityService from '../../../proxies/SecurityService'
import DataList from "../components/DataList";
import CollaboratorCard from '../components/card/AddressCard';
import Resources from "../../../resources";
import AddressCard from "../components/card/AddressCard";
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import StringHelper from "../../../helpers/StringHelper.js";


class Addresses extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.securityService = new SecurityService();
        this.state = {
            user : this.securityService.getUser()
        }
    }

    componentDidMount()
    {
        const parsedQueryString = queryString.parse(window.location.search);
        if (parsedQueryString.mode && parsedQueryString.mode == "success")
        {
            this.showSuccessToastNotification(
                StringHelper.translate(this, "address." + (this.securityService.isTransporter() ? "transporter" : "shipper") + "_save_confirmation_title"),    
                StringHelper.translate(this, "address." + (this.securityService.isTransporter() ? "transporter" : "shipper") + "_save_confirmation_message")
            )
        }
    }
 
    render() {
        return (
            <>
            {this.renderParent()}
            <Container className="mt--4" fluid>
            <Row>
                <Col xl="12">
                <DataList
                    context="transporter_addresses"
                    title={StringHelper.translate(this, "Default.menu_" + (this.securityService.isTransporter() ? "transporter" : "shipper") + "_addresses")}
                    titleIcon={Resources.addresses_logo}
                    allowAddButton={true}
                    addButtonTitle="Ajouter une adresse"
                    addButtonUrl="/admin/new-address"
                    allowRemovedButton={true}
                    removedButtonTitle='Voir les adresses supprimées'
                    notRemovedButtonTitle='Voir les adresses non supprimées'
                    apiUrl={this.state.user.is_shipper ? "/api/shipper/addresses" : "/api/transporter/addresses"}
                    apiRemovedUrl={this.state.user.is_shipper ? "/api/shipper/addresses/deleted" : "/api/transporter/addresses/deleted"}
                    apiResponseProperty={"addresses"}
                    cardTemplate={AddressCard}
                />
                </Col>
            </Row>
              
            </Container>
            </>
        )
    }
}

export default injectIntl(Addresses)