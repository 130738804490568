/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";


class Logo extends React.Component {
  render() {
    return (
      <>
        <img src={require('../../assets/img/logo/truck2you_logo.svg').default} style={{maxWidth:this.props.maxWidth}} />
      </>
    );
  }
}

export default Logo;