
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Container,
} from "reactstrap";

import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultLabel from "./defaultLabel";
import DefaultImage from "./defaultImage";


class MutedText extends React.Component {
    
    render() {
        return<>
            <Container className="t2y-muted-text-container">
                {
                    (this.props.icon || this.props.svg) ?
                    (
                        <>
                        <span className="btn-inner--icon" style={{marginRight: "10px"}}>
                            <DefaultImage src={this.props.icon ? this.props.icon : this.props.svg} />
                        </span>
                        </>
                    ) : (<></>)
                }
                <span className="btn-inner--text">
                    <DefaultLabel content={this.props.content} className="t2y-muted-text">
                        {this.props.children}
                    </DefaultLabel>
                </span>
            </Container>
       </>
    }
}

export default injectIntl(MutedText)
