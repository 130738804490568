import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import ComponentAndImageStep from "../componentAndImageStep.jsx";
import DefaultLabel from "../../common/defaultLabel.jsx";
import DefaultButton from "../../common/defaultButton.jsx";
import DateHelper from "../../../../helpers/DateHelper.js";
import DefaultLink from "../../common/defaultLink.jsx";
import WebsiteHelper from "../../../../helpers/WebsiteHelper.js";
import Codes from "../../../../codes.js";
import StringHelper from "../../../../helpers/StringHelper.js";
import Message from "../../common/message"


class WaitingForValidationStep extends ComponentAndImageStep {

    constructor(props) {
        super(props)
        
        this.state = {
            loading: true,
            image: require('../../../../assets/img/illustrations/orderWorkflow_waitingForValidationStep.svg').default,
            title: 'Félicitations',
            subtitle: 'Votre demande de réservation a été prise en compte',
            message: null
        }
    }

    componentWillMount() {
        super.componentWillMount()
    }

    componentDidMount() {
        super.componentDidMount()

        const input = this.props.workflow.state.input
        if (input && input.payment && input.payment.type == Codes.IBAN && input.payment.new_payment_method == true)
        {
            this.showCustomModalNotification("", <>
                {StringHelper.translateWithHtml(this, "Payment.iban_mandat_confirmation", "", {"mandate_rum_number": input.payment.mandate_rum_number})}
            </>)
        }
    }

    submit(pid)
    {
        super.submit(pid)
        this.close()
    }

    close()
    {
        this.props.workflow.goToNextStep();
    }

    goToOrder()
    {
        const orderId = this.props.workflow.state.input.order_id;
        WebsiteHelper.goTo(this, '/admin/orders?orderId=' + orderId + "&date=" + DateHelper.toMysqlDateFormat(DateHelper.now()));
    }


    renderComponent() {
        return <>
            <Container>
                <Row className="">
                    <DefaultLabel className="text-justify" content="Nous avons envoyé votre demande au transporteur responsable du camion que vous avez choisi. Nous sommes dans l'attente de sa réponse."></DefaultLabel>
                    <DefaultLabel className="text-justify" content="Vous pouvez suivre l'état de votre commande en temps réel."></DefaultLabel>
                </Row>
                <Row className="justify-content-center">
                    <DefaultButton label="Accéder à ma commande" className="t2y-secondary-button" onClick={(e) => this.goToOrder()}></DefaultButton>
                </Row>
                <Row className="justify-content-center">
                    <DefaultLink small={true} onClick={(e) => this.close()}>
                        <FormattedMessage id="Default.close"></FormattedMessage>
                    </DefaultLink>
                </Row>
            </Container>
        </>
        {/*
        return <>
            
            <br />
            <br />
            .
            <br />
        </>
        */}
    }
}


export default injectIntl(WaitingForValidationStep)