import React, { Component } from "react";
import queryString from 'query-string';
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
  Button,
  Label,
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import FormInput from '../components/FormInput.jsx';
import { injectIntl , FormattedMessage } from 'react-intl';
import ShipperService from "../../../proxies/ShipperService.js";
import Rating from "../../components/rating/rating.jsx";

class OrderRating extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    
    this.shipperService = new ShipperService();

    this.validationRules = {
      comment : {
        required : true
      }
    }
    this.state = {
      loading : false,
      formDatas : {
        email : { value: ""},
        password : { value: ""}
      }
    }

    this.handleUnload = this.handleUnload.bind(this);
  }

  handleUnload(e) {
    var message = "\o/";

    (e || window.event).returnValue = message; //Gecko + IE
    return message;
  }
 
    

  componentWillUnmount() {
    //window.removeEventListener('beforeunload', this.handleUnload);
  }

  componentDidMount() {

    if (!this.props.match.params.id == false)
    {
        this.loadDatas()
    }
    
    // Récupération des paramètres
    var rating = this.props.match.params.rating;
    const orderId = this.props.match.params.orderId;
    const userId = this.props.match.params.userId;

    // Enregistrement de la note
    this.shipperService.orderRating(this, orderId, userId, rating, (response) => {
        if (response.warnings != null && response.warnings.length > 0)
        {
            rating = response.rating;
        }

        this.setState({
            rating_user: response.user ? response.user.summary : null,
            comment: response.comment,
            rating: rating,
            order_id: orderId,
            user_id: userId
        })
    })

  }

  onSubmitForm = e => {
    e.preventDefault()
    var formIsValid = this.htmlFormIsValid(e);
    if (formIsValid)
    {
      // Récupération des donées postée
      const formData = new FormData(e.target)
      
      this.shipperService.orderRatingComment(this, this.state.order_id, this.state.user_id, this.getHtmlFormControlValue("comment"), (response) => {
        
        this.successNotification("Confirmation", 
        <Label>Votre commentaire à bien été pris en compte</Label>, "Fermer")
        
        // Mise à jour des données suite à l'enregistrement
        this.setState({
            rating_user: response.user ? response.user.summary : null,
            comment: response.comment,
            rating: response.rating
        })
      });

    }
  }

  renderRating()
  {
      return <>
        <div className="pt-4 text-center">
            <h1 className=" title">
            {parseInt(this.state.rating) + "/10"}
            </h1>
        </div>
        <div className="justify-content-center">
        {
            (this.state.rating) ?
            (
                <>
                <Rating note={parseFloat(this.state.rating) / 2.0 } center={true} size="30px"></Rating>
                <br/>
                </>
            ) : 
            (
                <></>
            )
        }
        {
            (this.state.comment) ?
            (
                <>
                <blockquote className="blockquote text-center">
                <p className="mb-0">
                    {this.state.comment}
                </p>
                <footer className="blockquote-footer">
                    <cite title="Source Title">{this.state.rating_user} </cite>
                </footer>
                </blockquote>
                </>
            )
            :
            (
                <>
                    <Form onSubmit={e => this.onSubmitForm(e)} role="form">
                        <div className="text-center">
                            <h3>Merci de saisir un commentaire pour expliquer votre note ( a modifier pour les 2 types de notes)</h3>
                        <FormInput manager={this} id="comment" rows="6" type="textarea" />
                        <Button className="my-4" color="info" type="submit">
                            <FormattedMessage id='Rating.save_comment' defaultMessage={`Rating.save_comment`} />
                        </Button>
                        </div>
                    </Form>
                </>
            )
        }

        </div>
        
      </>
  }

  render() {
    return (
      <>
        {this.renderParent()}
        <AuthHeader
          title={this.intl.formatMessage({"id" : "Rating.title1", "defaultMessage" : "!Rating.title1"})}
        />
        <Container className="mt--8 pb-5">
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>

          <Row className="justify-content-center">
            <Col lg="7" md="5">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                    {
                        (this.state.rating) ?
                        (
                            this.renderRating()
                        ) : <></>
                    }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(OrderRating);
