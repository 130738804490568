const UserMessages = {
    "user.civility": "Civilité",
    "user.first_name": "Prénom",
    "user.last_name" : "Nom de famille",
    "user.mobile_phone_number" : "Tel. Mobile",
    "user.home_phone_number": "Tel. Fixe",
    "user.name" : "Nom et Prénom",
    "user.email" : "Email",
    "user.update_email": "Modifier l'email",
    "user.email_invalid_message" : "Email non valide",
    "user.phone_number_invalid_message" : "Numéro de téléphone non valide",
    "sepa_user.name": "Nom et Prénom du signataire habilité",
    "sepa_user.email": "E-mail du signataire habilité",
    "sepa.iban": "Iban",
    "card.number": "Numéro de carte",
    "card.expiration": "Expiration",
    "card.cvc": "Crypto",
    "user.device_first_connection": "Date de première connexion",
    "user.device_last_connection": "Date de dernière connexion",
    "user.device_applications": "Applications installées",
    "user.device_system": "Système d'exploitation",
    "user.device_system_version": "Version",
    
}

export default UserMessages;
