import "../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
import {
   Row,
   Col,
   Input
 } from "reactstrap";
import { injectIntl} from 'react-intl';
import DefaultInput from "./defaultInput";


class BooleanListInput extends DefaultInput
{
    constructor(props)
    {
        super(props)
        this.state = {        
            
        }
    }

    renderOptions()
    {
        return <>
            <option key="bool__1" value="-1">{this.props.intl.formatMessage({id: "Default.i_dont_known"})}</option>
            <option key="bool_1" value="1">{this.props.intl.formatMessage({id: "Default.yes"})}</option>
            <option key="bool_0" value="0">{this.props.intl.formatMessage({id: "Default.no"})}</option>
        </>
    }

    render() {
      
      return (
        <>
            {this.rendeInner(
                <DefaultInput value={this.props.value} type="select" name={this.props.name} manager={this.props.manager}>
                    {this.renderOptions()}
                </DefaultInput>
            )}
        </>
      );
    }
}

 
export default injectIntl(BooleanListInput);
