const OrderMessages = {
    "Orders.in_progress_tab_label" : "En cours et à venir",
    "Orders.completed_tab_label" : "Clôturées",
    "Orders.canceled_tab_label": "Annulées",
    "Orders.bids_tab_label": "Appel d'offres",
    "Orders.order_id_tab_label": "Détail de votre commande",
    "Orders.header_number_label": "N° commande",
    "Orders.header_date_label": "Date(s)",
    "Orders.header_truck_label": "Véhicule",
    "Orders.header_state_label": "Statut commande",
    "Orders.header_rating_label": "Note",
    "Orders.header_total_ht_label": "Total HT",
    "Orders.header_payment_state_label": "Statut paiement",
    "Orders.line_date_1_day": "{date} \n (1 journée)",
    "Orders.line_date_0.5_day": "{date} \n (1/2 journée)",
    "Orders.line_date_more_than_1_day": "du {startDate}\nau {endDate}\n({nb} journées)",
    "Orders.transporter_waiting_for_validation_label": "Acceptez-vous cette commande ?",
    "Orders.transporter_waiting_for_validation_accept_label": "Accepter",
    "Orders.transporter_waiting_for_validation_refuse_label": "Refuser",
    "Orders.acceptance_confirmation_alert_message": "Confirmez vous la validation de cette commande ?",
    "Orders.refusal_confirmation_alert_message": "Confirmez vous le refus de cette commande ?",
    "Orders.acceptance_confirmation_message": `Félicitation!
    
    Votre accord a été enregistré.
    Vous êtes maintenant en charge de cette commande.
`    ,
    "Orders.refusal_confirmation_message": "La commande a bien été refusée",
    "Orders.INVOICE_TYPE_RETAINER.PAYMENT_STATE_PAIED": "Acompte payé",
    "Orders.INVOICE_TYPE_RETAINER.PAYMENT_STATE_INCIDENT": "Incident paiement",
    "Orders.INVOICE_TYPE_SHIPPER_INVOICE.PAYMENT_STATE_PAIED": "Solde payé",
    "Orders.INVOICE_TYPE_SHIPPER_INVOICE.PAYMENT_STATE_INCIDENT": "Incident paiement",
    "Orders.transporter_payment_state.INVOICE_STATE_NOT_RECEIVED": "Attente de votre facture",
    "Orders.transporter_payment_state.INVOICE_STATE_WAITING_FOR_VALIDATION": "Attente validation facture",
    "Orders.transporter_payment_state.INVOICE_STATE_RECEIVED": "Paiement programmé",
    "Orders.transporter_payment_state.PAYMENT_STATE_PAIED": "Payé",
    "Orders.line_transporter_popover_title": "Action(s)",
    "Orders.line_transporter_popover_facturation": "Télécharger l'appel à facturation",
    "Orders.line_transporter_popover_upload_invoice": "Transmettre votre facture",
    "Orders.line_transporter_popover_uploaded_invoice": "Télécharger votre facture",
    
    "Orders.line_invoices_popover_title": "Vos factures à télécharger",
    "Orders.line_invoices_popover_INVOICE_TYPE_RETAINER" : "Facture d'acompte",
    "Orders.line_invoices_popover_INVOICE_TYPE_SHIPPER_INVOICE" : "Facture de solde",
    "Orders.line_driver_alert": "Au moins une mission sans conducteur",
    "Orders.line_incident_alert": "Au moins une mission en incident",
    "Orders.line_expected_arrival_time": "Prise de poste chez le donneur d’ordre à {date}",

    "Orders.missions.header_date": "Date",
    "Orders.missions.header_first_loading_city": "1er chargement",
    "Orders.missions.header_steps": "Etapes",
    "Orders.missions.header_state": "Statut",
    "Orders.missions.header_distance": "Nb Km",
    "Orders.missions.header_duration": "Nb Heure(s)",
    "Orders.missions.header_rating": "Note client",
    "Orders.missions.MISSION_NOT_STARTED": "Planifié",
    "Orders.missions.MISSION_STARTED": "en cours",
    "Orders.missions.MISSION_COMPLETED": "Réalisé",
    "Orders.missions.transporter_button_title": "Détail",
    "Orders.missions.shipper_button_title": "Détail",
    "Orders.missions.report_button_title": "Rapport de mission",
    "Orders.missions.driver_alert": "Auncun conducteur pour cette mission",
    "Orders.missions.incident_alert": "Présence d'un incident sur cette mission",

    "Orders.bids_DOMAIN":"Merci de préciser le domaine d'activité du véhicule avant de créer votre appel d'offres",
    "Orders.bids_BODY":"Merci de préciser le type de véhicule avant de créer votre appel d'offres",
    "Orders.bids_PTAC":"Merci de préciser le poids total autorisé en charge du véhicule avant de créer votre appel d'offres",
    "Orders.bids_PTRA":"Merci de préciser le poids total roulant autorisé du véhicule avant de créer votre appel d'offres",
    "Orders.bids_SILHOUETTE":"Merci de préciser le nombre d'essieux / roues motrices du véhicule avant de créer votre appel d'offres"
}

export default OrderMessages