
import React from "react";


class ImageWithRescue extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        failed: false,
        rounded : (typeof this.props.rounded !== "undefined") ? this.props.rounded : true,
        shadow: (typeof this.props.shadow !== "undefined") ? this.props.shadow : true,
        thumbnail: (typeof this.props.thumbnail !== "undefined") ? this.props.thumbnail : true,
      };
    }
    _onError = () => {
      this.setState({ failed: true });
    }
    render() {
      var cssStyle ={};
      if (this.props.maxWidth)
      {
        cssStyle["maxWidth"] = this.props.maxWidth
      }
      if (this.props.width)
      {
        cssStyle["width"] = this.props.width
      }
      if (this.props.height)
      {
        cssStyle["height"] = this.props.height
      }

      if (this.props.backgroundColor)
      {
        cssStyle["backgroundColor"] = this.props.backgroundColor
      }

      var avatar = "avatar";
      if (this.props.avatar === false)
      {
        avatar = "";
      }

      return (<>
        <img
            alt={this.props.alt}
            style={cssStyle}
            className={avatar + " " + (this.state.rounded ? "rounded-circle" : "" ) + " " + (this.state.thumbnail ? " img-thumbnail " : "") + " img-center " + (this.state.shadow ? " shadow shadow-lg--hover " : " ") + this.props.className}
            src={
                typeof this.props.imageUrl !== "undefined" && this.props.imageUrl !== null && this.props.imageUrl !== "" && this.state.failed == false ? 
                    this.props.imageUrl :
                    this.props.defaultImage
                    
            }
            onError={this._onError}
        />
      </>)
    }
};


export default ImageWithRescue