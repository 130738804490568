// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://stripe.com/docs/payments/accept-a-payment#web

import React, { useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer, CardCvcElement, CardNumberElement, CardExpiryElement} from '@stripe/react-stripe-js';
import axios from "axios";
import $ from "jquery";
// reactstrap components
import {
  Input,
  Alert,
  Button,
  Row,
  Col
} from "reactstrap";
import ShipperService from "../../../proxies/ShipperService.js";
import Resources from '../../../resources';
import { injectIntl , FormattedMessage } from 'react-intl';
import Message from '../common/message.jsx';
import DefaultButton from '../common/defaultButton.jsx';
import DefaultCheckbox from '../common/defaultCheckbox.jsx';
import DefaultPopover from '../common/defaultPopover.jsx';
import DateLabel from '../common/dateLabel.jsx';
import FormLoadingIndicator from '../../pages/components/FormLoadingIndicator.jsx';
import Title3 from '../common/title3.jsx';
import Title2 from '../common/title3.jsx';
import MutedText from '../common/mutedText.jsx';
import StringHelper from '../../../helpers/StringHelper.js';
import FormValidationComponent from '../../pages/components/FormValidationComponent.jsx';
import DefaultImage from '../common/defaultImage.jsx';
import FormGroupInput from '../../pages/components/form/FormGroupInput.jsx';
import Codes from '../../../codes.js';


const CARD_STYLE = {

    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
};

const CARD_ELEMENT_OPTIONS = {
  style: CARD_STYLE,
  hideIcon: true,
  hidePostalCode: true,
  classes: {
    base: "btn t2y-default-input-container stripe-input-element",
    focus:  "btn t2y-default-input-container stripe-input-element",
    complete: "t2y-required-with-value",
    empty: "t2y-required",
    invalid: "t2y-required"
  }
};


class CreditCardComponent extends FormValidationComponent {

  constructor(props) {
    super(props);
    
    this.shipperService = new ShipperService();
    this.intl = this.props.intl;

    this.state = {
      paymentMethodIdToUse: null,
      isProcessing: false,
      checkoutError: null,
      reuseCardChecked: true,
      cardsAvailableLoaded: false,
			errorEncountered: false,
    };
  }

  setProcessingTo(value)
  {
    this.setState({isProcessing: value})
  }

  setCheckoutError(error)
  {
    this.setState({checkoutError: error})
  }

  toggleReuseCard(e)
  {
    this.setState({reuseCardChecked : !this.state.reuseCardChecked})
  }

  componentWillMount()
  {
    // Récupération des méthodes de paiement déjà enregistrées
    if (!this.props.forceNewAndReusable)
    {
      this.shipperService.getCardsAvailable(this, this.props.cardPaymentMethodMinExpirationDate, (response) => {
        this.setState({
          cards: response.payment_methods,
          cardsAvailableLoaded: true,
          paymentMethodIdToUse: response.payment_methods.length > 0 ? (
            response.payment_methods[0].expired == false ? response.payment_methods[0].payment_external_id : "new"
          ) : "new"
        })
      })
    }
    else{
      this.setState({
        cards: [],
        cardsAvailableLoaded: true,
        paymentMethodIdToUse: "new"
      })
    }
  }

  handleSubmit = async (event) => {
    if(!this.state.loading) {
      // Block native form submission.
      event.preventDefault();

      if (this.state.paymentMethodIdToUse == null) {
        this.errorNotification("", "Merci de selectionner une carte bancaire existante ou d'en saisir une nouvelle.");
        return false;
      }

      this.setState({loading: true})
      var currentComponent = this;

      const {orderId, lastPayment, onlyPaymentMethod, htAmount, ttcAmount, stripe, elements, onStart, onSuccess, onError} = this.props;

      if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    onStart()
    
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardNumberElement);

      var paymentMethodId = null;
      var paymentIntentId = null;
      var newPaymentMethod = false;
      var hasError = false;
      if (this.state.paymentMethodIdToUse == "new") {
        // ============================================================
        //          GESTION D'UNE NOUVELLE METHODE DE PAIMENT
        // ============================================================
        // Enregistrement d'une nouvelle carte bleue
        const paymentMethodResponse = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        if (paymentMethodResponse.error) {
          currentComponent.setState({loading: false})
          onError(paymentMethodResponse.error.message);
          this.setCheckoutError(paymentMethodResponse.error.message);
          this.setProcessingTo(false);
          return;
        }

        newPaymentMethod = true
        paymentMethodId = paymentMethodResponse.paymentMethod.id;

        // On rattache le mode de paiement au compte client pour le prélèvement du solde de la commande
        const attachPaymentMethodResponse = await axios.post(this.shipperService.getUrlFor("SHIPPER_ATTACH_PAYMENT_METHOD_TO_SHIPPER"), {
          payment_method_id: paymentMethodId,
          meta_data: paymentMethodResponse.paymentMethod,
          // Si le client accepte de réutiliser ce moyen de paiement pour ses futures commandes
          reuse_type_code: this.props.forceNewAndReusable ? "PAYMENT_METHOD_REUSE_TYPE_ALWAYS" : (this.reuseCardRef.getValue() ? "PAYMENT_METHOD_REUSE_TYPE_ALWAYS" :"PAYMENT_METHOD_REUSE_TYPE_THIS_ORDER")
        }, { headers: this.shipperService.buildHeaders()}).catch(function (error) {
          hasError = true;
          currentComponent.setState({loading: false})
          onError(error)})
      } else {
        // Utilisation du mode de paiment déjà connu
        paymentMethodId = this.state.paymentMethodIdToUse;
      }

      if (hasError == false) {
        if (!onlyPaymentMethod) {
          // ============================================================
          // Création de la tentative de paiement
          // ============================================================
          var intentError = null
          const paymentIntentResponse = await axios.post(this.shipperService.getUrlFor("SHIPPER_CREATE_PAYMENT_INTENT_CARD"), {
            amount: ttcAmount,
            order_id: orderId
          }, { headers: this.shipperService.buildHeaders()})
          .catch(error => {
            intentError = error
          });

          if (intentError == null) {
            //console.log(paymentIntentResponse);
            const clientSecret = paymentIntentResponse.data.transaction_id;
            paymentIntentId = paymentIntentResponse.data.id;

            const confirmCardPaymentResponse = await stripe.confirmCardPayment(clientSecret, {
              payment_method: paymentMethodId
            });
  
            //console.log(confirmCardPaymentResponse)
  
            if (confirmCardPaymentResponse.error) {
              currentComponent.setState({loading: false})
  
              //console.log(confirmCardPaymentResponse.error)
              onError(confirmCardPaymentResponse.error.message);
              this.setCheckoutError(confirmCardPaymentResponse.error.message);
              this.setProcessingTo(false);
              return;
            }
          } else {
            currentComponent.setState({loading: false})
            onError(intentError.response);
            this.setProcessingTo(false);
            this.setState({errorEncountered: true})
            return;
          }
        }
  
  
        // Action effectuée avec succès
        onSuccess({
          amount_paied_ttc: ttcAmount,
          amount_paied_ht: htAmount,
          payment_external_id: paymentIntentId,
          payment_methodExternal_id: paymentMethodId,
          new_payment_method: newPaymentMethod,
          type: Codes.CARD
        });
      }
    }
  };

  renderCardInformationMessage()
  {
    if (this.props.lastPayment == true)
    {
      return <></>
    }
    else 
    {
      return <Row className="align-item-center justify-content-center">
        <Message type="default">
          {StringHelper.translate(this, this.props.forceNewAndReusable ? "Payment.card_use_for_futur_payment" : "Payment.card_use_for_final_payment")}
        </Message>
      </Row>
    }
  }

  render() {
    const {stripe} = this.props;
    return (
      <>
      {this.renderParent()}
      <FormLoadingIndicator loading={this.state.loading} overlay={false}></FormLoadingIndicator>
      <form onSubmit={this.handleSubmit}>
        {
          (this.state.cardsAvailableLoaded) ?
          (
            <>
              {
                (this.state.cards && this.state.cards.length > 0) ? 
                (
                  <>
                    {
                      this.state.cards.map((card, index) => {
                        const selected = index == 0 && card.expired == false;

                        const cardBrandLabel = StringHelper.camelCase(card.card_brand)
                        const cardBrandIconKey = "card_" + StringHelper.toCode(card.card_brand) + "_icon"
                        return <div className={"custom-control custom-radio  mb-3"}>
                              <input id={"card" + index} checked={this.state.paymentMethodIdToUse == card.payment_external_id} disabled={card.expired} name="customRadio" type="radio" className="custom-control-input" 
                                onClick={(e) => this.setState({paymentMethodIdToUse: card.payment_external_id})}
                              />
                          
                          <label className={"custom-control-label " + (card.expired == false && card.expires_this_month ? "t2y-warning-color" : "")} htmlFor={"card" + index}>
                           {cardBrandLabel}&nbsp;
                           <DefaultImage style={{fontSize:"20px"}} src={Resources[cardBrandIconKey] ? Resources[cardBrandIconKey] : Resources.card_default_icon}></DefaultImage>&nbsp;
                           XXXX-XXXX-XXXX-{card.card_display_label} valide jusqu'au&nbsp; 
                          {
                            (card.expired == false && card.expires_this_month) ?
                            (
                              <>
                                <strong className="t2y-warning-color" > {card.expiration_month_year}</strong>
                                <DefaultPopover style={{color:"red"}} content="Cette carte expire ce mois-ci"></DefaultPopover>
                              </>
                            ) : (<>{card.expiration_month_year}</>)
                          }
                          </label>
                          {
                            (card.expired) ?
                            (
                              <>
                                <br/>
                                <h5 style={{color: "#8898aa", fontWeight: "100"}} >
                                  Cette carte expire avant la date de la prestation et ne peut pas être utilisée pour cette commande
                                </h5>
                              </>
                            ) : (<></>)
                          }
                        </div>
                      })
                    }
                    <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          id={"new_card"}
                          name="customRadio"
                          type="radio"
                          checked={this.state.paymentMethodIdToUse == "new"}
                          onClick={(e) => this.setState({paymentMethodIdToUse: "new"})}
                        />
                        <label className="custom-control-label" htmlFor={"new_card"}>
                          Ajouter une nouvelle carte
                        </label>
                    </div>

                    {
                      (this.state.paymentMethodIdToUse != "new") ? 
                      (
                        this.renderCardInformationMessage()
                      ) : (<></>)
                    }
                  </>
                ) : ("")
              }
              {
                (this.state.paymentMethodIdToUse == "new") ? 
                (
                  <>
                    <Row>
                      <Col md="6" sm="12">
                        <FormGroupInput intlId={"card.number"} htmlFor="cardNumber" type="multiline">
                          <CardNumberElement id="cardNumber" options={CARD_ELEMENT_OPTIONS} />
                        </FormGroupInput>
                      </Col>
                      <Col md="3" sm="6">
                        <FormGroupInput intlId={"card.expiration"} htmlFor="cardExpiry" type="multiline">
                          <CardExpiryElement id="cardExpiry" options={CARD_ELEMENT_OPTIONS} />
                        </FormGroupInput>
                      </Col>
                      <Col md="3" sm="6">
                        <FormGroupInput intlId={"card.cvc"} htmlFor="cardCvc" type="multiline">
                            <CardCvcElement id="cardCvc" options={CARD_ELEMENT_OPTIONS} />
                        </FormGroupInput>
                      </Col>
                    </Row>
                    
                    {
                      (!this.props.forceNewAndReusable) ?
                      (
                        <>
                          <DefaultCheckbox id="reuse_card" childRef={elt => this.reuseCardRef = elt} content="Enregistrer ce numéro de carte pour mes futures commandes" checked={true}
                            popover={
                              <DefaultPopover content={this.props.intl.formatMessage({id: "Payment.reuse_card"})} />
                            }
                          />
                        </>
                      ) : (<></>)
                    }

                    {this.renderCardInformationMessage()}
                  </>
                ) : ("")
              }
              
              <Row className="justify-content-center" style={{marginTop:"20px"}}>
								{(() => {
									if(!this.state.errorEncountered) {
										return <DefaultButton type="submit" className="t2y-secondary-button" disabled={!stripe && !this.state.loading} color="success">
											{this.props.paymentButtonTitle}
										</DefaultButton>
									} else {
										return <p style={{color: "red"}}>Il y a eu une erreur durant votre recherche, Veuillez refaire la manipulation dans quelques minutes</p>
									}
								})()}
              </Row>
            </>
          ):
          (
            ""
          )
        }
        
        
      </form>
      </>
    );
  }
}

const InjectedCreditCardComponent = ({orderId, lastPayment, onlyPaymentMethod, htAmount, ttcAmount, onStart, onSuccess, onError, intl, paymentButtonTitle, cardPaymentMethodMinExpirationDate, forceNewAndReusable}) => {

    return (
      <>
        <ElementsConsumer>
        {({elements, stripe}) => (
            <CreditCardComponent intl={intl} 
              orderId={orderId}
              lastPayment={lastPayment}
              forceNewAndReusable={forceNewAndReusable}
              onlyPaymentMethod={onlyPaymentMethod}
              htAmount={htAmount} 
              ttcAmount={ttcAmount} 
              elements={elements} 
              stripe={stripe} 
              onStart={onStart} 
              onSuccess={onSuccess} 
              onError={onError}
              paymentButtonTitle={paymentButtonTitle}
              cardPaymentMethodMinExpirationDate={cardPaymentMethodMinExpirationDate} />
        )}
        </ElementsConsumer>
      </>
    );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.


export default injectIntl(InjectedCreditCardComponent);
