import "../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
import {
   Row,
   Col,
   Input
 } from "reactstrap";
import { injectIntl} from 'react-intl';
import DefaultInput from "./defaultInput";


class IntegerListInput extends DefaultInput
{
    constructor(props)
    {
        super(props)
        this.state = {        
            
        }
    }

    renderOptions()
    {
        const min = this.props.min ? parseInt(this.props.min) : 0;
        const max = this.props.max ? parseInt(this.props.max) : 200;
        const step = this.props.step ? parseInt(this.props.step) : 1;

        var a = [
            <option key={this.props.name + "_option0"} value="">...</option>
        ]
        for(var i = min; i < max; i+= step) {
            a.push(<option key={this.props.name + '_option' + i} value={i}>{i}</option>)
        };
        return a;
    }

    render() {
      
      return (
        <>
            {this.rendeInner(
                <DefaultInput value={this.props.value} type="select" name={this.props.name} manager={this.props.manager}>
                    {this.renderOptions()}
                </DefaultInput>
            )}
        </>
      );
    }
}

 
export default injectIntl(IntegerListInput);
