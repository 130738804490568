import React from "react";
import Config from "../config.js";
import Translations from "../translations.js";
import WebsiteHelper from "../helpers/WebsiteHelper";

class ProxyService {
	constructor() {
		this.apiUrls = {
			HELP_RESOURCE: "/help",
			COLLABORATOR_RESEND_REGISTER_EMAIL: "/api/resend-collaborator-account/",
			DRIVER_ACCOUNT_VALIDATION: "/user/transporter/driver/unlock-account",

			TRANSPORTER_ADD_NEW_PAYMENT_METHOD: "/api/transporter/payment-method",
			TRANSPORTER_GET_PAYMENT_METHOD: "/transporter/payment-method/", //{transporterId}
			TRANSPORTER_GET_PAYMENT_METHOD_ATTACHMENT: "/transporter/payment-method/{transporterId}/attachment",
			TRANSPORTER_ACCEPT_PAYMENT_METHOD: "/transporter/payment-method/{transporterId}/accept",
			TRANSPORTER_REFUSE_PAYMENT_METHOD: "/transporter/payment-method/{transporterId}/refuse",
			TRANSPORTER_GET_PAYMENT_LOT: "/transporter/payment-lot/", //{paymentLotId}
			TRANSPORTER_GET_PAYMENT_LOT_ATTACHMENTS_AS_ZIP: "/transporter/payment-lot/{paymentLotId}/attachments",
			TRANSPORTER_PAYMENT_LOT_REFUSED: "/transporter/payment-lot/{paymentLotId}/refused",
			TRANSPORTER_PAYMENT_LOT_SENT: "/transporter/payment-lot/{paymentLotId}/sent",
			TRANSPORTER_COMMERCIAL_RULES: "/api/transporter/commercial/rules",
			TRANSPORTER_GET_ORDERS: "/api/transporter/orders/",
			TRANSPORTER_GET_MISSION: "/api/transporter/mission/",
			TRANSPORTER_GET_MISSION_STEP: "/api/transporter/mission/step/",
			TRANSPORTER_GET_COMPANY_INFOS: "/api/transporter/company",
			TRANSPORTER_SAVE_COMPANY_BILLING_INFOS: "/api/transporter/billing",
			TRANSPORTER_GET_ADDRESS: "/api/transporter/address/",
			TRANSPORTER_GET_ADDRESSES: "/api/transporter/addresses",
			TRANSPORTER_UPDATE_ADDRESS: "/api/transporter/address/",
			TRANSPORTER_CREATE_ADDRESS: "/api/transporter/address",
			TRANSPORTER_ACTIVE_ADDRESS: "/api/transporter/address/active/", //{addressId}

			TRANSPORTER_CREATE_COLLABORATOR: "/api/transporter/collaborator",
			TRANSPORTER_UPDATE_COLLABORATOR: "/api/transporter/collaborator",
			TRANSPORTER_GET_COLLABORATOR: "/api/transporter/collaborator/",
			TRANSPORTER_DELETE_COLLABORATOR: "/api/transporter/collaborator/",
			TRANSPORTER_ACTIVE_COLLABORATOR: "/api/transporter/collaborator/active/",
			TRANSPORTER_CREATE_DRIVER: "/api/transporter/driver",
			TRANSPORTER_UPDATE_DRIVER: "/api/transporter/driver",
			TRANSPORTER_GET_DRIVER: "/api/transporter/driver/",
			TRANSPORTER_DELETE_DRIVER: "/api/transporter/driver/",
			TRANSPORTER_ACTIVE_DRIVER: "/api/transporter/driver/active/",
			TRANSPORTER_RESEND_SMS_FOR_DRIVER: "/api/transporter/driver/resend-sms/id",
			TRANSPORTER_PLANNING: "/api/transporter/availability/",
			TRANSPORTER_SAVE_PLANING_RULES: "/api/transporter/availability",
			TRANSPORTER_CREATE_EVENT: "/api/transporter/event",
			TRANSPORTER_ACCEPT_ORDER: "/order/accept/", //{orderId}/{userId}/{orderDecisionId}
			TRANSPORTER_REFUSE_ORDER: "/order/refuse/", //{orderId}/{userId}/{orderDecisionId}
			TRANSPORTER_ORDER_DECISION_ID: "/api/order/decision/", //{orderId}/{truckId}
			TRANSPORTER_CREATE_TRUCK: "/api/transporter/truck",
			TRANSPORTER_UPDATE_TRUCK: "/api/transporter/truck",
			TRANSPORTER_GET_TRUCK: "/api/transporter/truck/",
			TRANSPORTER_DELETE_TRUCK: "/api/transporter/truck/",
			TRANSPORTER_ACTIVE_TRUCK: "/api/transporter/truck/active/",
			TRANSPORTER_PROPERTIES_AND_FAMILIES: "/api/transporter/truck-properties",
			TRANSPORTER_EXECUTE_SP_TRUCK_RULE: "/api/transporter/truck-rule/",
			TRANSPORTER_CHANGE_MISSION_DRIVER: "/api/shipper/mission/{missionId}/driver",
			TRANSPORTER_REMOVE_MISSION_DRIVER: "/api/shipper/mission/{missionId}/driver",
			TRANSPORTER_GET_TRUCKS_FOR_ORDER_PROPOSAL: "/api/transporter/trucks/order/{orderId}",
			TRANSPORTER_GET_TRUCK: "/api/transporter/truck/",
			MISSION_GET_REPORT_AS_PDF: "/api/mission/{missionId}/report/pdf",

			SHIPPER_GET_ORDERS: "/api/shipper/orders/",
			SHIPPER_GET_MISSION: "/api/shipper/mission/",
			SHIPPER_SAVE_MISSION: "/api/shipper/mission",
			SHIPPER_SAVE_CONFIGURATION: "/api/shipper/configuration",
			SHIPPER_MISSION_TRANSPORT_OPERATION_DEPARTURE_DEFAULT_CONTACT: "/api/shipper/mission/{missionId}/transport-operation/departure/contact/{transportOperationId}",
			SHIPPER_MISSION_TRANSPORT_OPERATION_ARRIVAL_DEFAULT_CONTACT: "/api/shipper/mission/{missionId}/transport-operation/arrival/contact/{transportOperationId}",
			SHIPPER_MISSION_TRANSPORT_OPERATION_DEPARTURE: "/api/shipper/mission/{missionId}/transport-operation/departure/{transportOperationId}",
			SHIPPER_MISSION_TRANSPORT_OPERATION_ARRIVAL: "/api/shipper/mission/{missionId}/transport-operation/arrival/{transportOperationId}",
			SHIPPER_MISSION_TRANSPORT_OPERATION_OPTIONS: "/api/shipper/mission/{missionId}/transport-operation/options/{transportOperationId}",
			SHIPPER_MISSION_TRANSPORT_OPERATION_GOOD: "/api/shipper/mission/{missionId}/transport-operation/{transportOperationId}/good/{goodId}",
			SHIPPER_MISSION_TRANSPORT_OPERATION_GET_ALL: "/api/shipper/mission/{missionId}/transport-operation",
			SHIPPER_MISSION_TRANSPORT_OPERATION_GET_BY_ID: "/api/shipper/mission/{missionId}/transport-operation/{transportOperationId}",
			SHIPPER_MISSION_TRANSPORT_OPERATION_FEIGHT_DOCUMENT: "/api/shipper/mission/{missionId}/transport-operation/{transportOperationId}/pdf",

			SHIPPER_ADD_TRANSPORT_OPERATION_ADDRESS: "/api/shipper/transport-operation/address",
			SHIPPER_SAVE_TRANSPORT_OPERATION_CONTACT: "/api/shipper/transport-operation/contact",

			SHIPPER_SEARCH_CALL_FOR_BIDS: "/api/search/bids/{searchId}",
			SHIPPER_GET_COMPANY_INFOS: "/api/shipper/company",
			SHIPPER_SAVE_COMPANY_BILLING_INFOS: "/api/shipper/billing",
			SHIPPER_MANUAL_ORDER_PAYMENT_INFOS: "/api/shipper/order/billing/{orderId}",
			SHIPPER_PAY_INVOICE_AFTER_INCIDENT: "/api/shipper/invoice/payment",
			SHIPPER_GET_ADDRESS: "/api/shipper/address/",
			SHIPPER_GET_ADDRESS_CONTACTS: "/api/shipper/address/{addressId}/contacts",
			SHIPPER_DELETE_CUSTOMER_ADDRESS_CONTACT: "/api/shipper/customer/{customerId}/address/{addressId}/contact/{contactId}",
			SHIPPER_ADD_CUSTOMER_CONTACTS_TO_ADDRESS: "/api/shipper/customer/{customerId}/address/{addressId}/contact",
			SHIPPER_ADD_CUSTOMER_NEW_CONTACT_TO_ADDRESS: "/api/shipper/customer/{customerId}/address/{addressId}/contact",
			SHIPPER_GET_CUSTOMER_ADDRESS_CONTACTS: "/api/shipper/customer/{customerId}/address/{addressId}/contact",
			SHIPPER_GET_ADDRESSES: "/api/shipper/addresses",
			SHIPPER_ACTIVE_ADDRESS: "/api/shipper/address/active/", //{addressId}
			SHIPPER_UPDATE_ADDRESS: "/api/shipper/address/",
			SHIPPER_CREATE_ADDRESS: "/api/shipper/address",
			SHIPPER_CREATE_COLLABORATOR: "/api/shipper/collaborator",
			SHIPPER_UPDATE_COLLABORATOR: "/api/shipper/collaborator",
			SHIPPER_GET_COLLABORATOR: "/api/shipper/collaborator/",
			SHIPPER_DELETE_COLLABORATOR: "/api/shipper/collaborator/",
			SHIPPER_ACTIVE_COLLABORATOR: "/api/shipper/collaborator/active/",
			SHIPPER_DELETE_MISSION_STEP: "/api/shipper/mission/step/",
			SHIPPER_MOVE_MISSION_STEP_UP: "/api/shipper/mission/step/move/up/", // {id}
			SHIPPER_MOVE_MISSION_STEP_DOWN: "/api/shipper/mission/step/move/down/", // {id}
			SHIPPER_SAVE_MISSION_STEP: "/api/shipper/mission/step",
			SHIPPER_GET_MISSION_STEP: "/api/shipper/mission/step/",
			SHIPPER_QUICK_SEARCH: "/api/shipper/search/quick",
			SHIPPER_SEARCH_RESULTS: "/api/shipper/search/", //{searchId}/{order}/{from}/{nb}",
			SHIPPER_SEARCH_RESULT_DETAIL: "/api/search/result/", //{resultId}"
			SHIPPER_CREATE_ORDER: "/api/order",
			SHIPPER_CANCEL_ORDER: "/api/order/{orderId}",
			SHIPPER_SUBMIT_ORDER: "/api/order/submit/", //{orderId}
			SHIPPER_PATCH_ORDER: "/api/order/patch/{orderId}/{property}", //{orderId}
			SHIPPER_VALIDATE_ORDER: "/api/order/validate/", //{id}"
			SHIPPER_CREATE_RETAINER_INVOICE: "/api/order/retainer/invoice/", //{id}
			SHIPPER_SEARCH_APPLY_FILTERS: "/api/shipper/search/filter/", //{searchId}"
			SHIPPER_CREATE_PAYMENT_INTENT_CARD: "/api/payment/intent/card",
			SHIPPER_CREATE_PAYMENT_INTENT_IBAN: "/api/payment/intent/iban",
			SHIPPER_CREATE_CHARGE_IBAN: "/api/payment/source/charge",
			SHIPPER_ATTACH_PAYMENT_METHOD_TO_SHIPPER: "/api/payment/method/attach",
			SHIPPER_ATTACH_PAYMENT_SOURCE_TO_SHIPPER: "/api/payment/source/attach",

			SHIPPER_GET_ALL_REUSABLE_PAYMENT_METHODS: "/api/payment/method/all",
			SHIPPER_GET_CARDS_AVAILABLE: "/api/payment/methods/card",
			SHIPPER_GET_IBANS_AVAILABLE: "/api/payment/methods/iban",
			SHIPPER_ORDER_RATING: "/rating/{rating}/order/{orderId}/{userId}",
			SHIPPER_ORDER_RATING_COMMENT: "/rating-comment/order/{orderId}/{userId}",
			SHIPPER_MISSION_RATING: "/rating/{rating}/mission/{missionId}/{userId}",
			SHIPPER_MISSION_RATING_COMMENT: "/rating-comment/mission/{missionId}/{userId}",

			SHIPPER_MISSION_UNLOADING_RATING: "/rating/{rating}/unloading/{missionId}/{missionStepId}/{userId}",
			SHIPPER_MISSION_UNLOADING_RATING_INFOS: "/rating/{rating}/unloading/{missionId}/{missionStepId}/{userId}",
			SHIPPER_MISSION_UNLOADING_RATING_COMMENT: "/rating-comment/unloading/{missionId}/{missionStepId}/{userId}",

			SHIPPER_MISSION_STEP_RATING: "/rating/{rating}/mission-step/{missionStepId}/{userId}",
			SHIPPER_MISSION_STEP_RATING_COMMENT: "/rating-comment/mission-step/{missionStepId}/{userId}",
			SHIPPER_GET_CUSTOMERS_BY_CRITERIA: "/api/shipper/customers",
			SHIPPER_GET_CUSTOMER_CONTACTS: "/api/shipper/customer/{customerId}/contacts",
			SHIPPER_SAVE_CUSTOMER_ADDRESS: "/api/shipper/customer/address",
			SHIPPER_SEARCH_FOR_CUSTOMER_ADDRESS: "/api/shipper/customer/{customerId}/address",
			SHIPPER_DOWNLOAD_SEPA_MANDATE: "/api/payment/methods/{paymentMethodId}/mandate/pdf",
			SHIPPER_SEND_SEPA_MANDATE: "/api/payment/methods/{paymentMethodId}/mandate/email",

			PUBLIC_SEARCH_RESULTS: "/search/results/", //{searchId}/{order}/{from}/{nb}",
			ORDER_GET_INVOICE_AS_PDF: "/api/order/invoice/", //{invoiceId}
			TRUCK_GET_SUMMARY: "/api/truck/summary/", //{truckId}
			TRANSPORTER_CLONE_TRUCK: "/api/transporter/truck/clone/", //{truckId}

			GET_ORDERS_BY_STATES: "/api/orders/{states}",

			ORDER_GET_TRANSPORTER_INVOICE_BILLING_INFOS: "/manual/order/{orderId}/invoice/transporter/{transporterId}",
			ORDER_GET_SHIPPER_INVOICE_BILLING_INFOS: "/manual/order/{orderId}/invoice/shipper/{shipperId}",
			ORDER_UPLOAD_TRANSPORTER_INVOICE: "/manual/invoice/{invoiceId}/transporter/{transporterId}",
			ORDER_VALIDATE_TRANSPORTER_INVOICE: "/manual/invoice/{invoiceId}/transporter/{transporterId}/{stateCode}",
			ORDER_GET_INVOICING_REQUEST_AS_PDF: "/api/order/{orderId}/invoicing-request/pdf",
			ORDER_GET_PUBLIC_INVOICING_REQUEST_AS_PDF: "/order/{orderId}/invoicing-request/pdf/{transporterId}",
			ORDER_GET_INVOICING_REQUEST_AS_HTML: "/api/order/{orderId}/invoicing-request/html",
			ORDER_GET_PUBLIC_INVOICING_REQUEST_AS_HTML: "/order/{orderId}/invoicing-request/html/{transporterId}",
			USER_CREATE: "/user/create",
			USER_CONNECT: "/api/user/login_check",
			USER_ME: "/api/user/me",
			USER_STATE_BY_EMAIL: "/user/state/", // email
			USER_BY_ID: "/user/", // {userId}
			USER_RESEND_REGISTER_EMAIL: "/user/resend-register-email",
			USER_FORGET_PASSWORD_WITH_EMAIL: "/user/forget-password",
			USER_ASK_FOR_CHANGE_IS_PASSWORD: "/api/user/forget-password",
			USER_ACCOUNT_VALIDATION: "/user/validate-account",
			USER_VALIDATE_EMAIL_MODIFICATION: "/user/validate-email-modification",
			USER_CANCEL_EMAIL_MODIFICATION: "/user/cancel-email-modification",
			USER_CHANGE_PASSWORD: "/user/change-password",
			USER_CHANGE_EMAIL: "/api/user/update-mail",
			USER_RESEND_UPDATE_EMAIL: "/api/user/resend-update-mail/", //{id}
			USER_CREATE_COMPANY_INFOS_BY_SIRET: "/api/user/company/",
			COLLABORATOR_VALIDATE_ACCOUNT: "/user/validate-collaborator-account",

			GET_MOBILE_APP_INFOS_BY_CODE: "/requirements/app/", //{platformTypeCode}/{mobileAppCode}
			GET_COMPANY_INFOS_BY_SIRET: "/company/infos/",
			COMPANY_SET_BILLING_NOT_OPERATIONAL: "/api/user/company/billing/no-operational",
			COMPANY_SET_BILLING_OPERATIONAL: "/api/user/company/billing/operational",
			CALL_FOR_BIDS_GET_ALL: "/api/search/bids",
			CALL_FOR_BIDS_GET_BY_ID: "/api/search/bids/{callForBidsId}",
			CANCEL_CALL_FOR_BIDS: "/api/search/bids/{callForBidsId}/cancel",
			REFUSE_CALL_FOR_BIDS_RESULT: "/api/search/bids/{callForBidsResultId}/refuse",
			TRANSPORTER_CALL_FOR_BIDS_RESULT_AVAILABILITIES: "/api/search/bids/{callForBidsResultId}/availabilities",
			TRANSPORTER_CALL_FOR_BIDS_RESULT_PROPOSAL: "/api/search/bids/{callForBidsResultId}/proposal",
		};
	}

	getUrlFor(key) {
		return this.buildUrl(this.apiUrls[key]);
	}

	buildUrl(relativeUrl) {
		return Config.bndApi + relativeUrl;
	}

	buildHeaders() {
		var token = this.getToken();
		return this.buildHeadersWithToken(token);
	}

	buildHeadersWithToken(token) {
		if (token == null) {
			token = this.getToken();
		}

		return {
			"Content-Type": "application/json",
			Authorization: token === null ? "" : "Bearer " + token,
		};
	}

	forceLogin(apiResponse, httpResponse) {
		return (
			(typeof httpResponse.status !== "undefined" && (httpResponse.status == 401 || httpResponse.status == 403)) ||
			(typeof apiResponse.code !== "undefined" && (apiResponse.code == 401 || apiResponse.code == 403)) ||
			(typeof apiResponse.status_code !== "undefined" && (apiResponse.status_code == 401 || apiResponse.status_code == 403))
		);
	}

	showErrorMessage(caller, apiResponse) {
		let message = "";
		let nbRequiredFields = typeof apiResponse.required_fields !== "undefined" ? apiResponse.required_fields.length : 0;
		let nbInvalidRules = typeof apiResponse.invalid_rules !== "undefined" ? apiResponse.invalid_rules.length : 0;
		let showTitle = nbRequiredFields > 1 || nbInvalidRules.length > 1;

		if (typeof apiResponse.required_fields !== "undefined" && apiResponse.required_fields.length > 0) {
			if (showTitle) {
				message += "<p>" + caller.translateMessage("REQUIRED_FIELD_MESSAGE") + "</p>";
			}
			message += "<ul style='text-align:left;list-style-type: none;'>";
			apiResponse.required_fields.forEach((element) => {
				message += "<li>" + caller.translateMessage(element) + "</li>";
			});
			message += "</ul>";
		}

		if (typeof apiResponse.invalid_rules !== "undefined" && apiResponse.invalid_rules.length > 0) {
			if (showTitle) {
				message += "<p>" + caller.translateMessage("INVALID_RULES_MESSAGE") + "</p>";
			}
			message += "<ul style='text-align:left;list-style-type: none;'>";
			apiResponse.invalid_rules.forEach((element) => {
				message += "<li>" + caller.translateMessage(element) + "</li>";
			});
			message += "</ul>";
		}

		if (message === "") {
			message = Translations.DEFAULT_ERROR_MESSAGE;
		}

		caller.showErrorModalNotification(Translations.MODAL_ERROR_TITLE, <div dangerouslySetInnerHTML={{ __html: message }}></div>);
		//caller.errorMessage(message, Translations.MODAL_ERROR_TITLE);
	}

	handleForceLogin(apiResponse, httpResponse) {
		if (this.forceLogin(apiResponse, httpResponse)) {
			// On supprime les données de session avant la redirection
			this.clearStorage();
			// Redirection vers l'écran de connexion
			WebsiteHelper.goTo(this, "/auth/login");
			return true;
		} else {
			return false;
		}
	}

	handleResponseError(caller, httpResponse) {
		if (caller !== null) {
			caller.setState({ loading: false });
			httpResponse.json().then((apiResponse) => {
				if (this.forceLogin(apiResponse, httpResponse)) {
					// On supprime les données de session avant la redirection
					this.clearStorage();
					// Redirection vers l'écran de connexion
					WebsiteHelper.goTo(this, "/auth/login");
					return;
				} else {
					this.showErrorMessage(caller, apiResponse);
				}
				/*
                caller.setState({
                    errorNotificationModal : true,
                    errorNotificationModalTitle : Translations.MODAL_ERROR_TITLE,
                    errorNotificationModalMessage : message
                });*/
			});
		}

		throw new Error("HTTP error, status = " + httpResponse.status);
	}
	handleError(caller, error) {
		if (caller !== null) {
			caller.setState({ loading: false });
		}
		console.error(error.message);
		return false;
	}

	handleHttpResponse(caller, httpResponse, okCallback = null, koCallback = null) {
		if (!httpResponse.ok) {
			if (koCallback === null) {
				this.handleResponseError(caller, httpResponse);
			} else {
				koCallback(httpResponse);
			}
			return false;
		} else {
			httpResponse.json().then((response) => {
				if (caller !== null) {
					caller.setState({ loading: false }, () => {
						if (okCallback !== null) {
							okCallback(response);
						}
					});
				} else {
					if (okCallback !== null) {
						okCallback(response);
					}
				}
			});
		}
		return true;
	}

	get(caller, relativeUrl, okCallback = null, koCallback = null, token = null) {
		return fetch(this.buildUrl(relativeUrl), {
			method: "GET",
			headers: token ? this.buildHeadersWithToken(token) : this.buildHeaders(),
		})
		.then((httpResponse) => {
			return this.handleHttpResponse(caller, httpResponse, okCallback, koCallback);
		})
		.catch((error) => {
			return this.handleError(caller, error);
		});
	}

	delete(caller, relativeUrl, okCallback = null, koCallback = null) {
		return fetch(this.buildUrl(relativeUrl), {
			method: "DELETE",
			headers: this.buildHeaders(),
		})
		.then((httpResponse) => {
			return this.handleHttpResponse(caller, httpResponse, okCallback, koCallback);
		})
		.catch((error) => {
			return this.handleError(caller, error);
		});
	}

	put(caller, relativeUrl, body, okCallback = null, koCallback = null) {
		return fetch(this.buildUrl(relativeUrl), {
			method: "PUT",
			headers: this.buildHeaders(),
			body: JSON.stringify(body),
		})
		.then((httpResponse) => {
			return this.handleHttpResponse(caller, httpResponse, okCallback, koCallback);
		})
		.catch((error) => {
			return this.handleError(caller, error);
		});
	}

	post(caller, relativeUrl, body, okCallback = null, koCallback = null, token = null) {
		return fetch(this.buildUrl(relativeUrl), {
			method: "POST",
			headers: this.buildHeadersWithToken(token),
			body: JSON.stringify(body),
		})
		.then((httpResponse) => {
			return this.handleHttpResponse(caller, httpResponse, okCallback, koCallback);
		})
		.catch((error) => {
			return this.handleError(caller, error);
		});
	}

	syncPost(caller, relativeUrl, body) {
		return fetch(this.buildUrl(relativeUrl), {
			method: "POST",
			headers: this.buildHeaders(),
			body: JSON.stringify(body),
		}).then((httpResponse) => {
			return httpResponse.json();
		});
	}

	/* Méthode permettant de savoir si l'utilisateur possède un token en session */
	isConnected() {
		var token = this.getToken();
		if (typeof token !== "undefined" && token !== null && token !== "") {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Méthode permettant de vérifier qu'un token existe pour une action administrateur (validée a date par sms)
	 * @param {string} url
	 */
	checkAdministratorToken(url) {
		var token = this.getAdministratorToken();
		if (typeof token !== "undefined" && token !== null && token !== "") {
			return true;
		} else {
			return false;
		}
	}

	/*
    Methode permettant de vérifier que le token en session est valide
    */
	// verifyToken() {}

	// hasCompanyInfos(company) {
		
	// 	if (typeof company !== "undefined" && company !== null && typeof company.siret !== "undefined" && company.siret != null) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }

	// getLang() {
	// 	return "fr";
	// }

	// setCompanyInfos(company) {
	// 	var user = this.getUser();
	// 	user.company = company;
	// 	this.storeUser(user);
	// }

	storeData(key, data) {
		localStorage.setItem(key, data);
	}

	getData(key) {
		return localStorage.getItem(key);
	}

	clearStorage() {
		localStorage.clear();
	}

	storeAdministratorToken(token) {
		this.storeData("administrator_token", token);
	}

	getAdministratorToken() {
		return this.getData("administrator_token");
	}

	storeSmsValidationToken(token) {
		this.storeData("sms_validation_token", token);
	}

	getSmsValidationToken() {
		return this.getData("sms_validation_token");
	}

	storeToken(token) {
		this.storeData("token", token);
	}

	getToken() {
		return this.getData("token");
	}

	// storeUser(user) {
	// 	this.storeData("user", JSON.stringify(user));
	// }

	// storeCompany(company) {
	// 	this.storeData("company", JSON.stringify(company));
	// }

	storeUserAndCompany(response) {
		// Enregistrement des données de la société
		if (response.company) {
			var company = response.company;
			if (response.billing_address) {
				company.gps_lat = response.billing_address.gps_lat;
				company.gps_long = response.billing_address.gps_long;
			}
			this.storeCompany(company);
		} else {
			this.storeCompany(null);
		}
		// Enregistrement des données de l'utilisateur
		this.storeUser(response.user);
	}

	// getUser() {
    //     let reduxStore = store.getState();
	// 	//let user = this.getData("user");
	// 	let user = reduxStore.auth.user;
        

	// 	if (typeof user !== undefined && user !== null) {
	// 		try {
	// 			return JSON.parse(user);
	// 		} catch (e) {
	// 			return null;
	// 		}
	// 	} else {
	// 		return null;
	// 	}
	// }

	// getCompany() {
    //     let reduxStore = store.getState();
	// 	//let user = this.getData("user");
	// 	let company = reduxStore.company.company;

	// 	if (typeof company !== undefined && company !== null) {
	// 		try {
	// 			return JSON.parse(company);
	// 		} catch (e) {
	// 			return null;
	// 		}
	// 	} else {
	// 		return null;
	// 	}
	// }

	// getUserDesignation(user) {
	// 	// récupération du nom et du prénom sinon on retour l'email
	// 	return user?.first_name === null || user?.last_name === null ? user?.email : user?.first_name + " " + user?.last_name;
	// }

	// getUserBusiness() {
	// 	var user = this.getUser();
	// 	return user?.is_shipper ? "Client" : user?.is_transporter ? "Offreur" : "";
	// }

	// isTransporter() {
	// 	var user = this.getUser();
	// 	if (!user) {
	// 		return false;
	// 	} else {
	// 		return user?.is_transporter;
	// 	}
	// }

	// isShipper() {
	// 	var user = this.getUser();
	// 	return user?.is_shipper;
	// }

	// getUserImageUrl(user) {
	// 	var user = this.getUser();
	// 	return user?.image_url == "" ? null : user?.image_url;
	// }

}

export default ProxyService;