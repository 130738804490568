import "../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components
 import {
   Form
 } from "reactstrap";
import Select2 from "react-select2-wrapper";
import DefaultInput from "./defaultInput";
import StringHelper from "../../../helpers/StringHelper";
import DateHelper from "../../../helpers/DateHelper";

class DefaultSelect2 extends DefaultInput     {

    constructor(props, multiple = false, drawOption = null)
    {
        super(props)
        this.drawOption = drawOption;
        this.multiple = multiple;
        this.mounted = false;
        this.state = {
            loading: true,
            buffer : null,
            datas : []
        }
    }

    componentWillMount(){
        super.componentWillMount();

    }

    componentDidMount() {
        super.componentDidMount();

        if (!this.props.noAutoBinding)
        {
            this.dataBind((component) => {
                this.setState({loading: false})
            });
        }

        this.mounted = true;
    }

    addImageToOption(text, image)
    {
        if (typeof image === "undefined" || image == null || image == "")
            return text;

        return "<span><img width='20px' class='' src='" + image + "' />&nbsp;" + text + "</span>"
    }

    onChange(e)
    {
        if (this.props.manager)
        {
            this.props.manager.htmlInputChanged(e, this.getKey())
        }

        var newValue = null
        if (this.props.manager)
        {
            newValue = this.props.manager.getFormControlValue(this.getKey()); 
        }
        else
        {
            newValue = e.target.value;
        }

        if (e.target.value == "")
        {
            newValue = [];
        }

        this.setValue(newValue);
    }

    onBlur(e)
    {
        if (this.props.manager)
        {
            this.props.manager.htmlInputChanged(e, this.getKey())
        }
    }
    
    getValue()
    {
        if (this.value)
        {
            const value = this.value;
            // Suppression de l'ancienne valeur pour ne pas la forcer lors de la prochaine modification
            this.value = null;
            return value;
        }
        else
        {
            return (this.props.manager ? this.props.manager.getFormControlValue(this.getKey()) : this.value);
        }
    }

    setValue(value)
    {
        this.value = value

        // Une valeur qui n'est pas un tableau ou un tableau qui n'est pas vide
        if (value != null && (Array.isArray(value) == false  || (Array.isArray(value) && value.length > 0 )))
        {
            const elementId = this.getKey() + "-main-container"
            var domContainerElement = document.getElementById(elementId);
            if (domContainerElement)
            {
                domContainerElement.classList.remove("t2y-required-with-value");
                domContainerElement.classList.remove("t2y-required");
                domContainerElement.classList.add("t2y-required-with-value");
            }
        }
    }

    hasValue()
    {
        return this.getValue() != null
    }

    render() {
        
        let validationState = this.getValidationState();
        var className = "form-control searching-in-progress " + (this.props.className? this.props.className : "") + " " + this.className ? this.className : ""
        
        const actor = "me"
        return (
            <>
                {
                    (this.state.loading) ?
                    (
                        this.rendeInner(
                        <Select2
                            disabled={true}
                            options={{
                                placeholder: !this.props.loadingPlaceholder ? "..." : this.props.loadingPlaceholder
                            }}
                        ></Select2>
                        )
                    )
                    :
                    (
                        this.rendeInner(
                            <Select2
                                disabled={this.props.readOnly}
                                key={this.getId()}
                                id={this.getId()}
                                name={this.getName()}
                                className={className}
                                defaultValue={this.multiple ? null : this.getValue()}
                                value={this.multiple ? this.getValue() : null}
                                options={{
                                    className: "searching-in-progress",
                                    placeholder: typeof this.props.placeholder !== "undefined" ? this.props.placeholder : "...", 
                                    templateResult : this.drawOption ? this.drawOption : (elt) => { return elt.text},
                                    templateSelection : this.drawOption ? this.drawOption : (elt) => { return elt.text},
                                    escapeMarkup: (m) => { return m;}
                                }}
                                multiple={this.multiple}
                                data={!this.state.datas ? [] : this.state.datas}
                                onBlur={this.props.onBlur ? this.props.onBlur : this.onBlur.bind(this)}
                                onChange={this.props.onChange ? this.props.onChange : this.onChange.bind(this)}
                            />
                        )
                    )
                }
            </>
      );
    }
  }
 
  export default DefaultSelect2;