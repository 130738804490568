// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://stripe.com/docs/payments/accept-a-payment#web

import React, { useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer, IbanElement} from '@stripe/react-stripe-js';
import axios from "axios";
import $ from "jquery";
// reactstrap components
import {
  Input,
  Alert,
  Button,
  Row,
  Col
} from "reactstrap";
import ShipperService from "../../../proxies/ShipperService.js";
import Resources from '../../../resources';
import { injectIntl , FormattedMessage } from 'react-intl';
import Message from '../common/message.jsx';
import DefaultButton from '../common/defaultButton.jsx';
import DefaultCheckbox from '../common/defaultCheckbox.jsx';
import DefaultPopover from '../common/defaultPopover.jsx';
import DateLabel from '../common/dateLabel.jsx';
import FormLoadingIndicator from '../../pages/components/FormLoadingIndicator.jsx';
import Title3 from '../common/title3.jsx';
import Title2 from '../common/title3.jsx';
import MutedText from '../common/mutedText.jsx';
import StringHelper from '../../../helpers/StringHelper.js';
import FormValidationComponent from '../../pages/components/FormValidationComponent.jsx';
import FormGroupInput from '../../pages/components/form/FormGroupInput.jsx';
import DefaultInput from '../common/defaultInput'

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  }
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: 'FR',
  style: IBAN_STYLE,
};

class SepaComponent extends FormValidationComponent {

  constructor(props) {
    super(props);
    
    this.shipperService = new ShipperService();
    this.intl = this.props.intl;

    this.state = {
      paymentMethodIdToUse: null,
      isProcessing: false,
      checkoutError: null,
      ibansAvailableLoaded: false,
			errorEncountered: false,
    };
  }

  setProcessingTo(value)
  {
    this.setState({isProcessing: value})
  }

  setCheckoutError(error)
  {
    this.setState({checkoutError: error})
  }

  componentWillMount()
  {
    // Récupération des méthodes de paiement déjà enregistrées
    this.shipperService.getIbansAvailable(this, (response) => {
      this.setState({
        ibans: response.payment_methods,
        ibansAvailableLoaded: true,
        paymentMethodIdToUse: response.payment_methods.length > 0 ? response.payment_methods[0].payment_external_id : "new"
      })
    })
  }

  handleSubmit = async (event) => {
    if(!this.state.loading) {
      // Block native form submission.
      event.preventDefault();
      
      if (this.state.paymentMethodIdToUse == null) {
        this.errorNotification("", "Merci de selectionner un iban existant ou d'en saisir un nouveau.");
        return false;
      }
  
      this.setState({loading: true})
      var currentComponent = this;
  
      const {orderId, lastPayment, onlyPaymentMethod, htAmount, ttcAmount, stripe, elements, onStart, onSuccess, onError, paymentButtonTitle} = this.props;

      if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

      onStart()
      // Get a reference to a mounted IbanElement. Elements knows how
      // to find your IbanElement because there can only ever be one of
      // each type of element.
      const ibanElement = elements.getElement(IbanElement);
  
      var paymentMethodId = null;
      var paymentIntentId = null;
      var hasError = false;
      if (this.state.paymentMethodIdToUse == "new") {
        // Vérification des données saisies
        const name = this.getHtmlFormControlValue("name")
        const email = this.getHtmlFormControlValue("email")
        if (!this.notEmptyValue(name) || !this.notEmptyValue(email) || !this.email(email)) {
          this.errorNotification("", "Merci de renseigner correctement les champs nom et adresse email.")
          this.setState({loading: false})
          return false;
        }
  
        // ============================================================
        //          GESTION D'UNE NOUVELLE METHODE DE PAIMENT
        // ============================================================
        // Enregistrement d'un nouvel iban
        const paymentMethodResponse = await stripe.createPaymentMethod({
          type: 'sepa_debit',
          sepa_debit: ibanElement,
          billing_details: {
            name: name,
            email: email
          }
        });
  
        
        if (paymentMethodResponse.error) {
          currentComponent.setState({loading: false})
          onError(paymentMethodResponse.error.message);
          this.setCheckoutError(paymentMethodResponse.error.message);
          this.setProcessingTo(false);
          return;
        }
        
        paymentMethodId = paymentMethodResponse.paymentMethod.id;
        
        // On rattache le mode de paiement au compte client pour le prélèvement du solde de la commande
        const attachPaymentMethodResponse = await axios.post(this.shipperService.getUrlFor("SHIPPER_ATTACH_PAYMENT_METHOD_TO_SHIPPER"), {
          payment_method_id: paymentMethodId,
          meta_data: paymentMethodResponse.paymentMethod,
          // Si le client accepte de réutiliser ce moyen de paiement pour ses futures commandes
          reuse_type_code: this.reuseCardRef.getValue() ? "PAYMENT_METHOD_REUSE_TYPE_ALWAYS" :"PAYMENT_METHOD_REUSE_TYPE_THIS_ORDER"
        }, { headers: this.shipperService.buildHeaders()}).catch(function (error) {
          hasError = true;
          currentComponent.setState({loading: false})
          onError(error)})
      } else {
        // Utilisation du mode de paiment déjà connu
        paymentMethodId = this.state.paymentMethodIdToUse;
      }
  
      if (hasError == false) {
        if (!onlyPaymentMethod) {
          // ============================================================
          // Création de la tentative de paiement
          // ============================================================
          var intentError = null
          const paymentIntentResponse = await axios.post(this.shipperService.getUrlFor("SHIPPER_CREATE_PAYMENT_INTENT_IBAN"), {
            amount: ttcAmount,
            order_id: orderId
          }, { headers: this.shipperService.buildHeaders()})
          .catch(error => {
            intentError = error
          });;

          if (intentError == null) {
            //console.log(paymentIntentResponse);
            const clientSecret = paymentIntentResponse.data.transaction_id;
            paymentIntentId = paymentIntentResponse.data.id;
  
            const confirmSepaPaymentResponse = await stripe.confirmSepaDebitPayment(clientSecret, {
              payment_method: paymentMethodId
            });
  
            //console.log(confirmSepaPaymentResponse)
  
            if (confirmSepaPaymentResponse.error) {
              currentComponent.setState({loading: false})
  
              //console.log(confirmSepaPaymentResponse.error)
              onError(confirmSepaPaymentResponse.error.message);
              this.setCheckoutError(confirmSepaPaymentResponse.error.message);
              this.setProcessingTo(false);
              return;
            }
          } else {
            currentComponent.setState({loading: false})
            onError(intentError.response);
            this.setProcessingTo(false);
            this.setState({errorEncountered: true})
            return;
          }
        }
  
  
        // Action effectuée avec succès
        onSuccess({
          amount_paied_ttc: ttcAmount,
          amount_paied_ht: htAmount,
          payment_external_id: paymentIntentId,
          payment_methodExternal_id: paymentMethodId
        });
      }
    }
  };

  renderIbanInformationMessage(sepaAlert = false)
  {
    var content = null;
    var sepaMandatAlert = <FormattedMessage id="Payment.sepa_mandat_alert"></FormattedMessage>
    var useFinalPayment = <FormattedMessage id="Payment.iban_use_for_final_payment"></FormattedMessage>
    if (this.props.lastPayment)
    {
      if (this.state.paymentMethodIdToUse == "new")
      {
        content = <div id="mandate-acceptance" className="mandate-acceptance" style={{width:"97%", float:"right"}}><FormattedMessage id="Payment.sepa_mandat_alert"></FormattedMessage></div>
      }
      else
      {
        content = null;
      }
    }
    else
    {
      if (sepaAlert)
      {
        content = <><div id="mandate-acceptance" className="mandate-acceptance" style={{width:"97%", float:"right"}}>{sepaMandatAlert}<br/><br/>{useFinalPayment}</div></>
      }
      else
      {
        content = <>{useFinalPayment}</>
      }
    }
    if (content == null)
    {
      return <></>
    }
    else
    {
      return <Row>
        <Message type="default">{content}</Message>
      </Row>
    }
  }

  render() {
    const {stripe} = this.props;
    return (
      <>
      {this.renderParent()}
      <FormLoadingIndicator loading={this.state.loading} overlay={false}></FormLoadingIndicator>
      <form onSubmit={this.handleSubmit}>
      
        {
          (this.state.ibansAvailableLoaded) ?
          (
            <>
              {
                (this.state.ibans && this.state.ibans.length > 0) ? 
                (
                  <>
                    {
                      this.state.ibans.map((iban, index) => {
                        return <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id={"iban" + index}
                            name="customRadio"
                            type="radio"
                            checked={this.state.paymentMethodIdToUse == iban.payment_external_id}
                            onClick={(e) => this.setState({paymentMethodIdToUse: iban.payment_external_id})}
                          />
                          <label className="custom-control-label" htmlFor={"iban" + index}>
                            Mandat n°{iban.iban_rum_number} sur le compte n° {iban.iban_displayed_label} signé le <DateLabel label={false} start_date={iban.creation_date}></DateLabel> par {iban.iban_creator_label}
                          </label>
                        </div>
                      })
                    }
                    <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          id={"new_iban"}
                          name="customRadio"
                          type="radio"
                          checked={this.state.paymentMethodIdToUse == "new"}
                          onClick={(e) => this.setState({paymentMethodIdToUse: "new"})}
                        />
                        <label className="custom-control-label" htmlFor={"new_iban"}>
                          Créer un mandat sur un nouveau compte ?
                        </label>
                    </div>

                    {
                      (this.state.paymentMethodIdToUse != "new") ? 
                      (
                        this.renderIbanInformationMessage(false)
                      ) : (<></>)
                    }
                  </>
                ) : ("")
              }
              {
                (this.state.paymentMethodIdToUse == "new") ? 
                (
                  <>
                    <Row>
                      <Col>
                          <FormGroupInput intlId={"sepa_user.name"} htmlFor="name" type="multiline">
                              <DefaultInput childRef={(elt) => this.contactNameInputRef = elt}  required={true} name='name' manager={this} />
                          </FormGroupInput>
                      </Col>

                      <Col>
                        <FormGroupInput intlId={"sepa_user.email"} htmlFor="email" type="multiline">
                              <DefaultInput type="email" childRef={(elt) => this.contactEmailInputRef = elt}  required={true} name='email' manager={this} />
                          </FormGroupInput>
                      </Col>
                    </Row>
                    <FormGroupInput type="multiline">
                    <IbanElement
                        options={IBAN_ELEMENT_OPTIONS}
                    />
                    </FormGroupInput>
                    <hr/>
                    {this.renderIbanInformationMessage(true)}
                    <hr/>
                    <DefaultCheckbox id="reuse_iban" childRef={elt => this.reuseCardRef = elt} content="Enregistrer cet iban pour mes futures commandes" checked={true}
                      popover={
                        <DefaultPopover content={this.props.intl.formatMessage({id: "Payment.reuse_iban"})} />
                      }
                    />
                  </>
                ) : ("")
              }
              
              <Row className="justify-content-center" style={{marginTop:"20px"}}>
                {(() => {
                  if(!this.state.errorEncountered) {
                    return <DefaultButton type="submit" className="t2y-secondary-button"  disabled={!stripe && !this.state.loading} color="success">
                      {this.props.paymentButtonTitle}
                    </DefaultButton>
                  } else {
                    return <p style={{color: "red"}}>Il y a eu une erreur durant votre recherche, Veuillez refaire la manipulation dans quelques minutes</p>
                  }
                })()}
              </Row>
            </>
          ) : ("")
        }
        
        
      </form>
      </>
    );
  }
}

const InjectedSepaComponent = ({orderId, lastPayment, onlyPaymentMethod, htAmount, ttcAmount, onStart, onSuccess, onError, intl, paymentButtonTitle, forceNewAndReusable}) => {

    return (
      <>
        <ElementsConsumer>
        {({elements, stripe}) => (
            <SepaComponent intl={intl} 
              orderId={orderId}
              lastPayment={lastPayment}
              onlyPaymentMethod={onlyPaymentMethod}
              forceNewAndReusable={forceNewAndReusable}
              htAmount={htAmount} 
              ttcAmount={ttcAmount} 
              elements={elements} 
              stripe={stripe} 
              onStart={onStart} 
              onSuccess={onSuccess} 
              onError={onError}
              paymentButtonTitle={paymentButtonTitle} />
        )}
        </ElementsConsumer>
      </>
    );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.


export default injectIntl(InjectedSepaComponent);
