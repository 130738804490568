import OrderMessages from "./fr_order.js"
import RepositoryMessage from "./fr_repository.js"
import accountMessages from './account.js'
import collaboratorMessages from './collaborator.js'
import driverMessages from './driver.js'
import workflowMessages from './workflow.js'
import searchMessages from './search.js'
import userMessages from './user.js'
import address from './address.js'
import { Search } from 'react-bootstrap-table2-toolkit'

const Translations = {
    ...RepositoryMessage,
    ...OrderMessages,
    ...accountMessages,
    ...collaboratorMessages,
    ...driverMessages,
    ...workflowMessages,
    ...searchMessages,
    ...userMessages,
    ...address,

    "Auth.menu.help": "J'ai besoin d'aide",
    "Default.transport_pdf_invoice_required" : "La facture au format pdf est obligatoire !",
    "Default.finished": "Terminer",
    "Default.workflow_next_button_label": "Suivant",
    "Date.half_day": "La demi-journée du ",
    "Date.all_day": "La journée du ",
    "Date.range_start": "Du ",
    "Date.range_end": " au ",
    "Default.person": "Particulier",
    "Default.company": "Société",
    "Default.validate": "Valider",
    "Default.cancel": "Annuler",
    "Default.civility_mr" : "Monsieur",
    "Default.civility_mme" : "Madame",
    "Default.shipper" : "Expéditeur",
    "Default.transporter" : "Transporteur",
    "Default.street": "Adresse",
    "Default.street2": "Complément",
    "Default.zip_code": "Code postal",
    "Default.city": "Ville",
    "Default.country": "Pays",
    "Default.address_name": "Nom interne",
    "Default.suggestions": "Rechercher",
    "Default.edit_address": "Modifier l'adresse",
    "Default.create_address": "Saisie manuelle de l'adresse",
    "Default.undo_edit_address": "Rechercher l'adresse",
    "Default.click_on_map": "Je choisi l'emplacement sur une carte",
    "Default.address_registered_with_warning": "Adresse enregistrée avec des alertes",
    "Default.address_successfully_registered": "Adresse enregistrée avec succès",
    "Default.loading_placeholder": "Chargement en cours ...",
    "Default.day_month_format": "DD/MM",
    "Default.day_month_year_format": "DD/MM/YYYY",
    "Default.i_dont_known": "Je ne sais pas",
    "Default.yes": "Oui",
    "Default.no": "Non",
    "Default.ok": "Oui",
    "Default.accept": "Accepter",
    "Default.search" : "Rechercher",
    "Default.welcome" : "Bienvenue!!",
    "Default.my_profile" : "Mon profil",
    "Default.change_my_password": "Modifier mon mot de passe",
    "Default.quit" : "Me déconnecter",
    "Default.close" : "Fermer",
    "Default.day0": "le jour même",
    "Default.day-1": "la veille",
    "Default.day-2": "2 jours à l'avance",
    "Default.monday": "Lundi",
    "Default.tuesday": "Mardi",
    "Default.wednesday": "Mercredi",
    "Default.thursday": "Jeudi",
    "Default.friday": "Vendredi",
    "Default.saturday": "Samedi",
    "Default.sunday": "Dimanche",
    "Default.country_code" : "COUNTRY_FR",
    "Default.fetch_loading_message": "Chargement des données ...",
    "Default.required_field" : 'Champ obligatoire',
    "Default.invalid_field" : 'Champ invalide',
    "Default.invalid_email" : "Merci de saisir un email valide",
    "Default.invalid_siret" : "Merci de saisir un numéro de SIRET valide",
    "Default.invalid_mobile_phone_number" : "Merci de saisir un numéro de téléphone mobile valide",
    "Default.invalid_home_phone_number" : "Merci de saisir un numéro de téléphone fixe valide",
    "Default.save" : "Enregistrer",
    "Default.delete" : "Supprimer",
    "Default.confirmation": "Confirmation",
    "Default.delete_title": "Confirmation de suppression",
    "Default.delete_message" : "Confirmez-vous la suppression de l'élément ?",
    "Default.active": "Annuler la suppression?",
    "Default.active_title": "Confirmation",
    "Default.active_message" : "Confirmez-vous l'annulation de la suppression de l'élément ?",
    "Default.address_suggestion_placeholder": "...",
    "Default.address_suggestion_empty_results": "Pas de résultats",
    "Default.INVOICE_STATE_RECEIVED": "Facture acceptée",
    "Default.INVOICE_STATE_NOT_RECEIVED": "Facture refusée",
    "Default.INVOICE_TYPE_RETAINER": "Facture d'acompte",
    "Default.INVOICE_TYPE_SHIPPER_INVOICE": "Facture de solde",
    "Default.android_app_download_title": "Télécharger l'application",
    "Default.list_refresh_button": "Rafraîchir les données",

    "Default.menu_operationnal_title": "Mon organisation opérationnelle",
    "Default.menu_other_title": "Autres liens",
    "Default.menu_company": "Données de facturation",
    "Default.menu_company_description": "Accédez aux données de ma société",
    "Default.menu_shipper_configuration": "Mes règles de gestion",
    "Default.menu_shipper_configuration_description": "Paramétrer les notifications envoyées à mes clients, ...",

    "Default.menu_transporter_collaborators" : "Mes collaborateurs",
    "Default.menu_transporter_collaborators_description" : "Créer des comptes à mes collaborateurs",
    "Default.menu_shipper_collaborators" : "Mes collaborateurs",
    "Default.menu_shipper_collaborators_description" : "Créer des comptes à mes collaborateurs",
    "Default.menu_collaborators": "Mes collaborateurs",
    
    "Default.menu_drivers": "Mes conducteurs",
    "Default.menu_drivers_description": "Gérer mes conducteurs et activer leur compte",
    
    "Default.menu_shipper_orders": "Mes commandes",
    "Default.menu_transporter_orders": "Mes commandes",
    "Default.menu_orders": "Mes commandes",
    
    "Default.menu_trucks": "Mes véhicules",
    "Default.menu_trucks_description": "Gérer mes véhicules et leur tarif",
    "Default.breadcrumb_trucks_title": "revenir à &laquo;Mes véhicules&raquo;",
    "Default.breadcrumb_drivers_title": "revenir à &laquo;Mes conducteurs&raquo;",
    "Default.breadcrumb_collaborators_title": "revenir à &laquo;Mes collaborateurs&raquo;",
    "Default.breadcrumb_transporter_addresses_title": "revenir à &laquo;Mes lieux de stationnement&raquo;",
    "Default.breadcrumb_shipper_addresses_title": "revenir à &laquo;Mes entrepôts&raquo;",

    "Default.menu_planning": "Mes disponibilités",

    "Default.menu_commercial_rules": "Mes règles commerciales",
    
    "Default.menu_transporter_addresses": "Mes lieux de stationnement",
    "Default.menu_transporter_addresses_description": "Gérer les lieux de stationnement de mes véhicules",
    "Default.menu_shipper_addresses": "Mon carnet d’adresses",
    "Default.menu_shipper_addresses_description": "Accéder à mes entrepôts, à ceux de vos sous traitants et au aux lieux de livraison de mes clients",
    "Default.menu_addresses": "Mes adresses",
    
    "Default.menu_shipper_search": "Rechercher un véhicule",

    "Default.datalist_sorry_title": "Nous sommes désolés",
    "Default.datalist_sorry_default_description": "Aucun élément ne correspond à votre recherche",

    "Default.STATE_ACTIVE": 'Actif',
    "Default.STATE_PENDING_MAIL_VALIDATION": 'Validation email en cours',
    "Default.STATE_PENDING_PASSWORD_VALIDATION": 'Validation du mot de passe en cours',
    "Default.STATE_PENDING_SMS_VALIDATION": 'Validation du numéro de mobile en cours',
    "Default.STATE_AVAILABLE": 'Disponible',
    "Default.STATE_NOT_AVAILABLE": 'Non disponible',
    "Default.STATE_REMOVED": 'Supprimé',
    "Default.ORDER_STATE_CREATION": "En cours de création",
    "Default.ORDER_STATE_VALIDATED": "Commande validée", 

    "PaymentSepa.pay": "Payer",
    "PaymentCb.pay": "Payer",
    "PaymentCb.add_new_card": "Saisir une nouvelle carte ?",
    "PaymentCb.reuse_card_order_only": "",
    "PaymentCb.reuse_card_always": "",
    "Payment.sepa_mandat_alert": `En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez :
(A) Truck2You (BOOKED AND DELIVERED SAS) à envoyer des instructions à votre banque pour débiter votre compte (via son prestataire de paiement : Stripe). 
(B) votre banque à débiter votre compte conformément à ces instructions.
Vous bénéficiez d'un droit à  remboursement de votre banque selon les termes et conditions de la convention que vous avez passé avec elle. Un remboursement doit être réclamé dans les 8 semaines à compter de la date à laquelle votre compte a été débité. Vos droits sont expliqués dans une déclaration que vous pouvez obtenir auprès de votre banque. Vous serez notifié par Truck2You de chaque débit.`,
    "SearchBySiretModal.step1_title": "Information de votre société",
    "SearchBySiretModal.step1_message": "Vous n'avez pas encore renseigné vos informations.\nMerci de saisir votre numéro de SIRET pour les pré-remplir.",
    "SearchBySiretModal.step1_siret_placeholder": "N° siret ...",
    "SearchBySiretModal.step1_search": "Suivant >>",
    "SearchBySiretModal.step2_title": "Voici ce que nous avons trouvé",
    "SearchBySiretModal.step2_message": "Vous trouverez ci dessous les informations correspondantes au n° SIRET",
    "SearchBySiretModal.step2_back_to_step1": "Saisir un autre siret",
    "SearchBySiretModal.step2_select": "C'est bien ma société",
    "SearchBySiretModal.step3_title": "Confirmation",
    "SearchBySiretModal.step3_message": "Les données selectionnées ont été pré-remplies dans la page.\nMerci de vérifier l'ensemble des informations, de les compléter au besoin, puis de cliquer sur le bouton d'enregistrement pour qu'elles soient prises en compte",
    "SearchBySiretModal.step3_close": "Fermer",
    "SearchBySiretModal.step2_license_title": "Vos licences transport",
    "SearchBySiretModal.step2_company_title": "Informations de votre société",
    "SearchBySiretModal.company_not_found": "Société non trouvée",

    "Payment.reuse_card" : "Les données bancaires seront stockées et sécurisées",
    "Payment.reuse_iban" : "Les données bancaires seront stockées et sécurisées",
    "Payment.card_use_for_futur_payment" : "Ce numéro de carte sera enregistré pour vos futures commandes",
    "Payment.card_use_for_final_payment" : "Cette carte sera utilisée pour payer l'acompte et pour encaisser le solde de la prestation à la fin de son exécution.",
    "Payment.iban_use_for_futur_payment" : "Ce mandat de prélèvement sera enregistré pour vos futures commandes",
    "Payment.iban_use_for_final_payment" : "Ce mandat de prélèvement sera utilisé pour payer l'acompte et pour encaisser le solde de la prestation à la fin de son exécution.",
    "Payment.total_to_pay": "Montant à payer",
    "Payment.iban_mandat_confirmation": `Truck2You (BOOKED AND DELIVERED SAS) peut désormais collecter des paiements de votre compte par prélèvement bancaire SEPA (via son prestataire de paiement : Stripe).

    Votre Référence Unique de Mandat (RUM) est : {mandate_rum_number}. Le mandat SEPA vient de vous être envoyé par e-mail et est disponible à tout moment dans votre espace client Truck2You.

    Vous serez notifié de chaque prélèvement par e-mail, et le prélèvement apparaitra sur votre relevé bancaire sous le nom Truck2You.
    
    `,
    "PROPERTY_UNIT_TONS.short_label" : "/t",
    "PROPERTY_UNIT_METERS.short_label": "/m",
    "PROPERTY_UNIT_KM_PER_HOUR.short_label" : "km/h",
    "PROPERTY_UNIT_KG.short_label" : "/kg",
    "PROPERTY_UNIT_M3.short_label": "/㎥",
    "PROPERTY_UNIT_KG.long_label": "Kg(s)",
    "PROPERTY_UNIT_TONS.long_label" : "Tonne(s)",

    "PROPERTY_UNIT_TONS.truck_summary_short_label" : "/t",
    "PROPERTY_UNIT_METERS.truck_summary_short_label": "/m",
    "PROPERTY_UNIT_KM_PER_HOUR.truck_summary_short_label" : "km/h",
    "PROPERTY_UNIT_KG.truck_summary_short_label" : "/kg",
    "PROPERTY_UNIT_M3.truck_summary_short_label": "/㎥",

    "ADDRESS_TYPE_INTERNAL.short_label" : "Interne",
    "ADDRESS_TYPE_SUBCONTRACTOR.short_label" : "Sous-traitant",
    "ADDRESS_TYPE_BILLING.short_label": "Siège social",
    MODIFICATION_MESSAGE: "Vos notifications",
    WARNING_MESSAGE: "Mais l'action exécutée a remonté les alertes suivantes:",
    GEOCODING_INFORMATIONS: "L'adresse saisie n'a pas été géocodée ou possède des données de géocodage érronée&lt;br /&gt;",

    ADDRESS_NAME: "La désignation de cette adresse",
    ADDRESS_STREET: "Adresse",
    ADDRESS_ZIP_CODE: "Code postal",
    ADDRESS_CITY: "Ville",
    DEFAULT_ERROR_MESSAGE : "Une erreur est survenue",
    MODAL_ERROR_TITLE : "Impossible d'exécuter l'action demandée",
    REQUIRED_FIELD_MESSAGE : 'Les éléments suivants sont manquants, merci de les renseigner:',
    INVALID_RULES_MESSAGE : 'Merci de corriger les éléments suivants',
    WARNING_MESSAGE: "L'action demandée a été exécutée avec des alertes",
    CIVILITY : 'la civilité',
    FIRST_NAME : "le prénom",
    LAST_NAME : "le nom de famille",
    COMPANY_NAME : "La raison sociale",
    SIRET : "Le siret",
    HOME_PHONE_NUMBER : "le numéro de téléphone fixe",
    MOBILE_PHONE_NUMBER : "le numéro de téléphone mobile",
    EMAIL : "l'adresse email",
    PASSWORD : "PASSWORD",
    PASSWORD_CONFIRMATION : "PASSWORD_CONFIRMATION",
    ADDRESS_COUNTRY : "ADDRESS_COUNTRY",
    ADDRESS_TYPE : "ADDRESS_TYPE",
    CONTACT_PHONE_NUMBER : "Merci de saisir au moins un numéro de téléphone",
    CONTACT_FIRST_NAME : "CONTACT_FIRST_NAME",
    CONTACT_LAST_NAME : "CONTACT_LAST_NAME",
    CUSTOMER_COMPANY_NAME : "La raison sociale de votre client",
    CUSTOMER_LAST_NAME : "Le nom de famille de votre client",
    
    TRUCK_DOMAIN : "TRUCK_DOMAIN",
    TRUCK_CATEGORY : "TRUCK_CATEGORY",
    TRUCK_REFERENCE : "TRUCK_REFERENCE",
    TRUCK_ADDRESS : "TRUCK_ADDRESS",
    TRUCK_DRIVER : "TRUCK_DRIVER",
    TRUCK_COST_PER_DAY : "TRUCK_COST_PER_DAY",
    TRUCK_COST_PER_KM : "TRUCK_COST_PER_KM",
    TRUCK_COST_PER_MINUTE : "TRUCK_COST_PER_MINUTE",
    
    ORDER_TRUCK : "ORDER_TRUCK",
    ORDER_START_DATE : "ORDER_START_DATE",
    ORDER_END_DATE : "ORDER_END_DATE",
    ORDER_START_TIME : "ORDER_START_TIME",
    ORDER_ADDRESS : "ORDER_ADDRESS",

    INVOICE_NUMBER: "Le numérode la facture",
    INVOICE_DATE: "La date de la facture",
    
    EVENT_START_DATE : "EVENT_START_DATE",
    EVENT_END_DATE : "EVENT_END_DATE",
    EVENT_TRUCK_OR_DRIVER : "EVENT_TRUCK_OR_DRIVER",
    RULE_PASSWORD_FORMAT : "Le mot de passe choisi n'est pas au bon format",
    RULE_FNAME_FORMAT : "Le prénom n'est pas au bon format",
    RULE_LNAME_FORMAT : "Le nom de famille n'est pas au bon format",
    RULE_MOBILE_PHONE_NUMBER_ALREADY_EXISTS: "Ce numéro de téléphone portable est déjà associé à un autre utilisateur.",
    TRUCK_REFERENCE : "L'identifiant ou l'immatriculation du véhicule",
    TRUCK_REFERENCE_ONLY : "L'identifiant du véhicule",
    TRUCK_IMMATRICULATION : "L'immatriculation du véhicule",
    TRUCK_ADDRESS: "Le lieu de stationnement du véhicule",
    TRUCK_DRIVER: "Le ou les conducteur(s) affecté(s)",
    TRUCK_COST_PER_DAY: "Le coût journalier",
    TRUCK_COST_PER_KM: "Le coùt du km supplémentaire",
    TRUCK_COST_PER_HOUR: "Le coùt de l'heure supplémentaire",
    RULE_REGISTRATION_FORMAT: "Merci de saisir un numéro d'immatriculation valide",
    EVENT_TRUCK_ASSIGNED_TO_AN_ORDER: "Impossible de modifier une date possédant une mission",
    "DOMAIN":"Domaine d'activité du véhicule",
"BODY":"Type de véhicule",
"PTAC":"Poids Total Autorisé en Charge",
"PTRA":"Poids Total Roulant Autorisé",
"SILHOUETTE":"Essieux / roues motrices",
"UNLOADED_WEIGHT":"Masse du véhicule à vide",
"PAY_LOAD":"Masse maximale du chargement supportée par la véhicule",
"OVERALL_LENGTH":"Longueur totale du véhicule",
"OVERALL_WIDTH":"Largeur totale du véhicule",
"OVERALL_HEIGHT":"Hauteur totale du véhicule (hors chargement)",
"CRIT_AIR":"Classification Crit'Air",
"EURO_POLLUTION_STANDARD":"Norme environnementale du véhicule",
"FUEL":"Type d'énergie du véhicule",
"SPEED_LIMIT":"Vitesse maximum du véhicule",
"COLOR":"Couleur principale du véhicule",
"PRESENCE OF A BRAND":"Présence d'une signalétique sur le véhicule (autre que la marque du véhicule)",
"TRAY_USEFUL_LENGTH":"Longueur intérieure utiilisable du plateau",
"TRAY_USEFUL_WIDTH":"Largeur intérieure utiilisable du plateau",
"DROP_SIDES_HEIGHT":"Ridelles latérales du plateau évitant le glissement de la cargaison",
"FRONT_POST_HOLDER":"Support à l'avant avec butées d'arrêt permettant de transporter des poteaux",
"REAR_POST_HOLDER":"Support à l'arrière avec butées d'arrêt permettant de transporter des poteaux",
"BACK_SIDE_HEIGHT":"Face à l'arrière du plateau évitant le glissement de la cargaison",
"POSITION":"Position de la grue sur le véhicule",
"CAPACITY_TONS":"Charge maximale supportée par la grue à sa portée maximale",
"CAPACITY_METERS":"Portée maximale de la grue (extensions hydrauliques)",
"JIB":"Présence d'un JIB (extension du bras de la grue pour atteindre des positions hautes éloignées)",
"ROTATOR":"Possibilité de faire pivoter hydrauliquement le crochet",
"HOOK":"Crochet d'arrimage de la charge",
"PLACO_FORK":"Fourche de manutention permettant de manipuler des plaques de plâtre",
"TYPE_OF_OPENING":"Type d'ouverture de la benne",
"TIPPER_BACKDOOR":"Type de porte arrière / ouverture",
"TIPPER_SIDE_FACE":"Type de faces latérales",
"TIPPER_BACHAGE":"Présence d'une bâche de protection du chargement",
"TIPPER_BODY_TYPE": "Matière de la benne",
"TYPE_CHASSIS": "Le type de châssis",
"TIPPER_USEFUL_LENGTH": "La longueur utile de la benne",
"TIPPER_USEFUL_WIDTH": "La largeur utile de la benne",
"TIPPER_USEFUL_HEIGHT": "La hauteur utile de la benne",
"TIPPER_USEFUL_VOLUME": "Le volume de la benne",
"RULE_CALL_FOR_BIDS_ALREADY_EXISTS": "Un appel d'offres a déjà été créé pour cette recherche. <br/>Merci de relancer la recherche pour créer un nouvel appel d'offres.",
"RULE_CALL_FOR_BIDS_TRUCK_REFUSED_OR_REMOVED": "Vous ne pouvez plus faire de proposition sur cet appel d'offres",
"RULE_TOKEN_EXPIRED": "Votre jeton a expiré. Merci de rafraîchir la page pour recevoir un nouveau code.",
"RULE_ACTIVATION_CODE_ALREADY_SENT": "Un code d'activation vous a déjà été envoyé par sms. Si vous ne retrouvez pas, merci de cliquer sur le bouton &laquo;Recevoir un nouveau code&raquo;",
"RULE_BAD_ACTIVATION_CODE": "Ce code d'activation n'existe pas. Merci de vérifier votre saisie.",
"RULE_IBAN_NOT_WAITING_FOR_VALIDATION": "L'iban que vous essayez de visualiser n'est plus en attente de validation",
"RULE_IBAN_WAITING_FOR_VALIDATION": "Nous ne pouvons pas prendre en compte ce nouvel iban car il y en a déjà un en cours de validation.",
    RULE_DRIVER_NOT_AVAILABLE: "Ce conducteur n'est pas disponible pour la période demandée.",
    RULE_ORDER_VALIDATION_HAS_EXPIRED: "Cette commande ne peut plus être validée.",
    RULE_TRUCK_REFERENCE_ALREADY_EXISTS: "Un véhicule existe déjà avec le même identifiant ou la même immatriculation.",
    RULE_UNKNOWN_ACCOUNT_TYPE: "Imposible de créer un compte pour un type inconnu",
    RULE_BAD_GEOCODING_LEVEL: "La voie saisie n'est pas assez précise.",
    RULE_EMPTY_COMPANY_BILLING_INFOS: "Les données de votre société ne sont pas renseignées",
    RULE_ACCOUNT_ALREADY_EXISTS: "Le compte utilisé existe déjà",
    RULE_BAD_TRANSPORTER_LICENSE_DATES: "La licence de transport n'est pas valide",
    RULE_MOBILE_PHONE_FORMAT: "Le numéro de téléphone n'est pas valide",
    RULE_EMAIL_FORMAT: "L'adresse email n'est pas valide",
    RULE_ORDER_HAS_EXPIRED: "Votre commande a expiré. Merci de faire une nouvelle recherche.",
    RULE_ORDER_CANCELLATION_NOT_POSSIBLE: "Impossible d'annuler cette commande",
    RULE_VALIDATED_ORDER_CANCELLATION_NOT_POSSIBLE: "Impossible d'annuler cette commande car elle a été validée par le transporteur. Merci de rafraîchir la page pour afficher le nouvel état de cette commande.",
    ORDER_STATE_VALIDATED: "La commande a déjà été validée par le transporteur.",
    TRUCK_OR_DRIVER_NO_LONGER_AVAILIABLE: "Ce véhicule n'est plus disponible pour la période souhatée. Merci de relander la recherche.",
    RULE_SMS_NOT_SENT: "Les données du conducteurs ont été enregistrées avec succès mais le SMS n'a pas pu être envoyé.",
    ORDER_INVALID_DATES: "La ou les dates selectionnées ne sont pas valides",
    STATE_CANCELED: "Impossible de prendre en compte votre décision car l'expéditeur à annuler sa commande.",
    "Orders.dates" : "Du {start_date} au {end_date}",
    "Orders.status" : "Statut : {state}",
    "Orders.steps" : "{nb} étape(s)",
    "OrderAcceptation.title1": "Acceptation de la commande",
    "OrderRefusal.title1": "Refus de la commande",

    //Message dans écran de listing
    "Orders.datalist_sorry_title" : "Nous sommes désolés",
    "Orders.first_search_title" : "Faites votre première recherche ",
    "Orders.datalist_sorry_default_description": "Il n'y a aucune commande disponible pour cette période",
    "CallForBids.datalist_sorry_default_description": "Il n'y a aucun appel d'offres disponible pour cette période",
    "CallForBids.results_datalist_sorry_default_description_before_countdown" : "Attente de proposition : les transporteurs ont",
    "CallForBids.results_datalist_sorry_default_description_after_countdown" : "pour répondre",
    "CallForBids.header_expected_truck" : "Véhicule recherché",
    "CallForBids.header_expected_dates": "Période souhaitée",
    "CallForBids.header_state": "Etat",
    "CallForBids.results.header_truck": "Véhicule",
    "CallForBids.results.header_reference": "Référence",
    "CallForBids.results.header_amounts": "Prix H.T",
    "CallForBids.results.header_amounts_package": "Forfait",
    "CallForBids.results.header_amounts_days": "Pour {days} jour(s)",
    
    "CallForBids.results.header_package_amounts": "Prix H.T",
    "CallForBids.results.header_package_amounts": "Prix unitaure H.T",
    "CallForBids.results.header_period_amounts": "Prix total H.T",
    "CallForBids.results.header_added_amounts" : "Suppléments",
    "CallForBids.results.header_added_km_amounts": "Heure supplémentaire",
    "CallForBids.results.header_added_hour_amounts": "Km supplémentaire",
    "CallForBids.results.propose_this_truck": "Répondre à cette demande avec ce véhicule",
    "CallForBids.shipper_cancel_button_title": "Annuler cet appel d'offres",
    "CallForBids.shipper_cancel_message": "Vous pouvez décidez d'annuler votre appel d'offres en cliquant sur le bouton suivant",

    "Trucks.datalist_sorry_default_description": "Aucun véhicule n’est disponible",
    "Trucks.datalist_sorry_filter_description": "Aucun véhicule ne correspond à votre recherche",
    "Collaborators.datalist_sorry_default_description": "Aucun collaborateur n’est disponible",
    "Collaborators.datalist_sorry_filter_description": "Aucun collaborateur ne correspond à votre recherche",
    "Drivers.datalist_sorry_default_description": "Aucun conducteur n’est disponible",
    "Drivers.datalist_sorry_filter_description": "Aucun conducteur ne correspond à votre recherche",
    "Transporter_addresses.datalist_sorry_default_description": "Aucun lieu de stationnement n’est disponible",
    "Transporter_addresses.datalist_sorry_filter_description": "Aucun lieu de stationnement ne correspond à votre recherche",
    "Shipper_addresses.datalist_sorry_default_description": "Aucun entrepôt n’est disponible",
    "Shipper_addresses.datalist_sorry_filter_description": "Aucun entrepôt ne correspond à votre recherche",

    "MissionStep.address_successfully_registered": "Etape enregistrée avec succès",
    "MissionStep.address_registered_with_warning": "Les données de l'étape ont été enregistrée.",
    "MissionStep.delete_title": "Suppression",
    "MissionStep.delete_message" : "Confirmez vous la suppression de cette étape?",
    "MissionStep.address_selection_unloading_message": "Merci de sélectionner une adresse pour le déchargement",
    "MissionStep.address_selection_loading_message": "Merci de sélectionner une adresse pour le chargement",
    "MissionStep.address_tab_unloading_title": "Destination",
    "MissionStep.address_tab_loading_title": "Lieu de chargement",
    "MissionStep.contact_tab_unloading_title": "Client",
    "MissionStep.contact_tab_loading_title": "Contact",
    

    "QuickSearch.go_to_results": "Voir les camions disponibles",
    "QuickSearch.retry": "Modifier ma recherche",

    "Rating.title1": "Evaluation de la mission",
    "Rating.title2": "Merci de saisir un commentaire pour expliquer votre note",
    "Rating.save_comment": "Envoyer mon commentaire",
    "Rating.mission_step.default_customer_message" : `{shipper_company_name} est soucieuse de la qualité des livraisons. Merci de nous indiquer si la livraison qui vient d’être effectuée vous satisfait ?`,
    "Rating.mission_step.satisfaction_message" : `{customer_message}
    
    Sur une note de 1 à 10, comment jugez-vous la livraison ?

    `,
    "Rating.mission_step.shipper_reference_message" : `Vous trouverez ci dessous la preuve de la livraison n° {shipper_reference} de votre commande {shipper_company_name}.`,
    


    "NoTruckDriverAlert.planning_no_truck_no_driver" : "Vous devez avoir au moins un conducteur et un véhicule publié pour gérer vos disponibilités.",
    "NoTruckDriverAlert.planning_no_truck" : "Vous devez avoir au moins un véhicule publié pour gérer vos disponibilités.",
    "NoTruckDriverAlert.planning_no_driver" : "Vous devez avoir au moins un conducteur pour gérer vos disponibilités.",
    "NoTruckDriverAlert.planning_no_links" : "Vous devez avoir au moins un conducteur associé à au moins un véhicule pour gérer vos disponibilités.",

    "NoTruckDriverAlert.orders_no_truck_no_driver" : "Vous devez avoir au moins un conducteur et un véhicule publié pour gérer vos commandes.",
    "NoTruckDriverAlert.orders_no_truck" : "Vous devez avoir au moins un véhicule publié pour gérer vos commandes.",
    "NoTruckDriverAlert.orders_no_driver" : "Vous devez avoir au moins un conducteur pour gérer vos commandes.",
    "NoTruckDriverAlert.orders_no_links" : "Vous devez avoir au moins un conducteur associé à au moins un véhicule pour gérer vos commandes.",

    "MissionProgress.MISSION_EVENT_TYPE_START" : "Démarrage de la mission",
    "MissionProgress.MISSION_EVENT_TYPE_END" : "Fin de la mission",
    "MissionProgress.MISSION_EVENT_STEP_START_TO_LOADING_PLACE": "Départ pour chargement ",
    "MissionProgress.MISSION_EVENT_STEP_ARRIVED" : "Arrivée sur site",
    "MissionProgress.MISSION_EVENT_STEP_START_TO_UNLOADING_PLACE": "Départ pour déchargement",
    "MissionProgress.MISSION_EVENT_STEP_START_TO_PARK_TRUCK": "Départ vers le lieu de stationnement",
    "MissionProgress.MISSION_EVENT_STEP_END_LOADING": "Fin de chargement pour {target}",
    "MissionProgress.MISSION_EVENT_STEP_END_UNLOADING": "Fin de déchargement de {target}",
    "MissionProgress.MISSION_EVENT_BREAK_START": "Début de pause du conducteur",
    "MissionProgress.MISSION_EVENT_BREAK_END": "Fin de pause du conducteur",
    "MissionProgress.INCIDENT_IMPACT_OK": "Incident n'ayant pas d'impact sur la mission",
    "MissionProgress.INCIDENT_IMPACT_DELAY": "Incident générant du retard sur la mission",
    "MissionProgress.INCIDENT_IMPACT_KO": "Incident rendant impossible la finalisation de la mission",

    "TransportOperation.external_id": "Votre numéro de livraison",
    "TransportOperation.external_id_message": "Correspond à votre identifiant interne pour ce chargement",


    "PaymentLot.creation_date": "Date de création",
    "PaymentLot.state": "Etat",
    "PaymentLot.paied_button": "Fichier envoyé à la banque",
    "PaymentLot.refused_button": "Fichier refusé par la banque",
    "PaymentLot.nb_payment_lines": "Nombre de ligne(s)",    
    "PaymentLot.amounts": "Montant payé"
    
}

export default Translations