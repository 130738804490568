import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import Workflow from "../workflow";
import BusinessService from "../../../../proxies/BusinessService";
import ContactHomeStep from "./contactHomeStep";
import ContactEmailStep from "./contactEmailStep";

var moment = require('moment');
require('moment/locale/fr');

class ContactWorkflow extends Workflow {

    constructor(props) {
        super(props)
        this.businessService = new BusinessService();

        this.loginStepRef = null;
        

        this.state = {
            visible: false,
            current_step: "CONTACT_HOME",
            steps: {
                "CONTACT_HOME": {
                    position: 1,
                    component: <ContactHomeStep embeded={this.props.embeded} workflow={this} id="CONTACT_HOME"></ContactHomeStep>
                },
                "CONTACT_EMAIL": {
                    position: 1,
                    component: <ContactEmailStep embeded={this.props.embeded} workflow={this} id="CONTACT_EMAIL"></ContactEmailStep>
                }
            }
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    start(inputValue)
    {
        this.setState({ 
            input: inputValue,
            visible: true
        })
    }

    render() {
        return (
            <>
                {this.renderCurrentStep()}
            </>
        );
    }
}


export default injectIntl(ContactWorkflow)

