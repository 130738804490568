/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";

import { injectIntl , FormattedMessage } from 'react-intl';

import Resources from "../../../resources";
var moment = require('moment');
require('moment/locale/fr');



class RepositoryDataComponent extends React.Component {

    constructor(props)
    {
        super(props)
    }

    getLabel()
    {
        if (!this.props.data)
            return "";

        return this.props.intl.formatMessage({id: "Repository." + this.props.data.code, defaultMessage:this.props.data.label});
    }

    renderLabel()
    {
        return this.getLabel()
    }

    renderUi()
    {
        // On vérifie si il y a un icon type class
        if (this.props.data.ui_icon_web != null)
        {
            return <i className={this.props.data.ui_icon_web} title={this.getLabel()}></i>
        }


        return <></> 
    }

    render() {
        return (
            <>
            {
                (["icon", "icon_label"].includes(this.props.mode))?
                (
                    this.renderUi()
                ) : (<></>)
            }
            {
                (this.props.mode != "icon") ?
                (
                    this.renderLabel()
                ) : (<></>)
                
            }
            {
                (["label_icon"].includes(this.props.mode))?
                (
                    this.renderUi()
                ) : (<></>)
            }
            </>
        )
    }

}



RepositoryDataComponent.propTypes = {
    data: PropTypes.shape,
    mode: PropTypes.oneOf(['icon','label', 'label_icon', 'icon_label']),
};


export default injectIntl(RepositoryDataComponent);
