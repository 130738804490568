
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    Col,
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    NavLink,
    Modal,
    Button
  } from "reactstrap";
import { Calendar } from 'react-date-range';

import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator"
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import FormHourInput from "../../pages/components/form/FormHourInput";
import ReactDatetime from "react-datetime";
import RepositoryService from "../../../proxies/RepositoryService";

import { injectIntl , FormattedMessage } from 'react-intl';
import FormRepository from "../../pages/components/form/FormRepository";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

var moment = require('moment');
require('moment/locale/fr');

class Title3 extends React.Component {

    render() {
        var htmlContent = this.props.content;
        var customClassName = "t2y-title3 " + (this.props.className);
        var style = this.props.style ? this.props.style : {}
        
        if (this.props.center)
        {
            customClassName += " t2y-center-title"
        }

        if (this.props.right)
        {
            customClassName += " t2y-right-title"
        }
        
        if (this.props.justify)
        {
            customClassName += " t2y-justify"
        }
        
        if (this.props.children)
        {
            return <h3 style={style} className={customClassName}>{this.props.children}</h3>
        }
        else
        {
            return <h3 style={style} className={customClassName} dangerouslySetInnerHTML={{__html: htmlContent}}></h3>
        }
    }
}

export default injectIntl(Title3)