import React from "react";

import {
    Row,
    Col,
    Button,
    Table,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardHeader,
    CardBody,
    Badge,
    CardTitle,
    Progress
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import ShipperService from "../../../proxies/ShipperService";
import DefaultImage from "../common/defaultImage";
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import Resources from "../../../resources";
import StringHelper from "../../../helpers/StringHelper";
import ArrayHelper from "../../../helpers/ArrayHelper";
import EmptyResult from "../common/emptyResult";
import DeleteButton from "../common/deleteButton";
import LoadingContainer from "../common/loadingContainer";
import Config from "../../../config";
import DefaultButton from "../common/defaultButton";
import RepositoryDataComponent from "../../pages/components/RepositoryDataComponent";
import Gallery from "react-grid-gallery";
import BusinessService from "../../../proxies/BusinessService";
import MapRouteComponent from "../../pages/components/map/MapRouteComponent";
import GeocodingAndNavigationService from "../../../proxies/GeocodingAndNavigationService";
import Rating from "../rating/rating"
import Title2 from "../common/title2";
import Title3 from "../common/title3";
import Title4 from "../common/title4";
import Title5 from "../common/title5";
import DateHelper from "../../../helpers/DateHelper";


var moment = require('moment');
require('moment/locale/fr');

class MissionProgress extends FormValidationComponent {

    static thumbnailWidth = 200;
    static thumbnailHeight = 150;


    constructor(props) {
        super(props)
        this.businessService = new BusinessService();
        this.geocodingAndNavigationService = new GeocodingAndNavigationService();

        this.state = {
            isTransporter: this.businessService.getUser().is_transporter,
            loading: false,
            steps: this.props.steps
        }
    }

    componentWillMount() {
        
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }

        // Affichage des informations sur la carte
        if (this.props.mission && this.props.mission.tour)
        {
            var mapPoints = [];
            var stepIndex = 0;
            // Gestion des check points
            [this.props.mission.tour.map_points, this.props.mission.tour.unique_map_steps].forEach(stepArray => {
                stepArray.forEach((step) => {
                    if (step.coordonates != null && step.coordonates.gps_lat != null)
                    {
                        mapPoints.push({
                            order: stepIndex,
                            latitude: step.coordonates.gps_lat,
                            longitude: step.coordonates.gps_long,
                            type: step.type,
                            title: step.map_position ? step.map_position : "",
                            description: step.start_date
                        })
                    }
                    stepIndex += 1;
                })
            })
            
/*

            this.props.events.forEach((event, eventIndex) => {
                if (event.coordinates != null && event.coordinates.gps_lat != null)
                {
                    //console.log(event.coordinates)
                    mapPoints.push({
                        order: eventIndex + 1,
                        latitude: event.coordinates.gps_lat,
                        longitude: event.coordinates.gps_long,
                        type: event.type,
                        title: "Etape " + eventIndex + 1,
                        description: null
                    })
                }
            })
*/
            if (mapPoints.length > 1)
            {
                this.mapRouteComponentRef.showPointsOnMap(this.geocodingAndNavigationService.getCenter(mapPoints), mapPoints)
            }
        }
    }

    renderSummary()
    {
        var startKm = this.props.mission ? this.props.mission.start_km : null;
        var endKm = this.props.mission ? this.props.mission.end_km : null;
        var endDate = this.props.mission ? this.props.mission.end_date : null;
        var distance = this.props.mission ? this.props.mission.nb_km : null;
        var totalBreakDuration = this.props.mission ? this.props.mission.total_break_duration: null;
        var totalBreakDurationAsString = this.props.mission ? this.props.mission.total_break_duration_as_string: null;

        var duration = this.props.mission ? this.props.mission.duration_as_string : null;
        var nbSteps = this.props.mission ? this.props.steps.length - 2 : 0;
        var nbCompletedSteps = ArrayHelper.count(this.props.steps, (item) => {
            return item.driver_loading_unloading_end_date != null;
        })

        /*if (nbCompletedSteps > 0)
        {
            nbCompletedSteps -= 2;
        }*/

        nbCompletedSteps = 0;
        if (nbSteps > 0)
        {
            this.state.steps.forEach(s => {
                if (s.state.code == "MISSION_STEP_STATE_COMPLETED" && ["MISSION_STEP_TYPE_LOADING", "MISSION_STEP_TYPE_UNLOADING"].includes(s.type.code))
                {
                    nbCompletedSteps += 1;
                }
            })
        }

        var stepPercent = Math.round(nbCompletedSteps * 100 / nbSteps)
        var stepPercentColor = stepPercent >= 100 ? "success" : (stepPercent <= 33) ? "danger" : "warning"

        return <>
            <Row>
                <Col xl="3">
                    <Row>
                        <Col xs="12">
                            <span className="h2 font-weight-bold mb-0">
                                <i className="fas fa-route">&nbsp;</i>
                                {distance ? distance + " km" : "0 km"} 
                            </span>
                            {
                                (!endKm && distance > 0) ?
                                (
                                    <em> (estimation)</em>
                                ) : (null)
                            }
                        </Col>
                    </Row>
                </Col>
                <Col xl="3">
                    <Row>
                        <Col xl="12">
                            <span className="h2 font-weight-bold mb-0">
                                <i className="fas fa-stopwatch">&nbsp;</i>
                                {duration ? duration : "..."} 
                            </span>
                            {
                                (!endDate && duration != null) ?
                                (
                                    <em> (estimation)</em>
                                ) : (null)
                            }
                        </Col>
                    </Row>
                </Col>
                {
                    (totalBreakDuration != null && totalBreakDuration > 0) ?
                    (
                        <Col xl="3">
                            <Row>
                                <Col xl="12">
                                    <span className="h2 font-weight-bold mb-0">
                                        <i className="far fa-pause-circle"></i>
                                        {totalBreakDurationAsString} 
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    ) : (null)
                }
                <Col xl="3">
                    <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-1 mt-1">
                        Réalisation
                        </CardTitle>
                        <span className="mr-2">{nbCompletedSteps} étapes sur {nbSteps} soit {stepPercent} % de réalisé</span>
                        <div>
                            <Progress
                            max="100"
                            value={stepPercent}
                            barClassName={"bg-" + stepPercentColor}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </>

        {/*

        

        return <>
            
                    <Row>
                        <Col xl="3">
                            <Row>
                                <Col xs="12">
                                    <span className="h2 font-weight-bold mb-0">
                                        <i className="far fa-play-circle">&nbsp;</i>{startKm ? startKm + " km" : "..."}
                                    </span>
                                </Col>
                                <Col xs="12">
                                    <span className="h2 font-weight-bold mb-0">
                                        <i className="far fa-clock">&nbsp;</i>
                                        {this.props.mission.start_time_as_string ? this.props.mission.start_time_as_string : "..."} 
                                    </span>
                                </Col>
                            </Row>
                        </Col> 
                        <Col xl="3">
                            <Row>
                                <Col xs="12">
                                    <span className="h2 font-weight-bold mb-0">
                                        <i className="fas fa-flag-checkered">&nbsp;</i>{endKm ? endKm + " km" : "..."}
                                    </span>
                                </Col>
                                <Col xs="12">
                                    <span className="h2 font-weight-bold mb-0">
                                        <i className="far fa-clock">&nbsp;</i>
                                        {this.props.mission.end_time_as_string ? this.props.mission.end_time_as_string : "..."} 
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="3">
                            <Row>
                                <Col xs="12">
                                    <span className="h2 font-weight-bold mb-0">
                                        <i className="fas fa-route">&nbsp;</i>
                                        {distance ? distance + " km " : "..."} 
                                    </span>
                                </Col>
                                <Col xl="12">
                                    <span className="h2 font-weight-bold mb-0">
                                        <i className="fas fa-stopwatch">&nbsp;</i>
                                        {duration ? duration : "..."} 
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="3">
                            <div className="col">
                                <CardTitle tag="h5" className="text-uppercase text-muted mb-1 mt-1">
                                Réalisation
                                </CardTitle>
                                <span className="mr-2">{nbCompletedSteps} étapes sur {nbSteps} soit {stepPercent} % de réalisé</span>
                                <div>
                                    <Progress
                                    max="100"
                                    value={stepPercent}
                                    barClassName={"bg-" + stepPercentColor}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
        </>*/}
    }

    renderEvent(event, eventIndex, previousEvent)
    {
        
        return <div className="">
            <span className={"timeline-step badge badge-" + event.type}>
                <DefaultImage src={Resources[event.type]}></DefaultImage>
            </span>
            <div className="timeline-content" style={{width: "100%", maxWidth: "100%"}}>
                <Row>
                    <Col xs="1"><small className={"text-muted font-weight-bold time-" + event.type}>{event.time_as_string}</small></Col>
                    <Col xs="11"><h5 className={"mt-1 mb-0 title-" + event.type}>{StringHelper.translate(this, "MissionProgress." + event.type, "", {"target" : event.contact == null ? "" : event.contact.summary})}</h5></Col>
                </Row>
                {
                    (
                        event.address != null && (
                            previousEvent == null || 
                            previousEvent.address == null ||
                            (
                                previousEvent != null && 
                                previousEvent.address != null && 
                                previousEvent.address.summary != event.address.summary
                            )
                        )
                    ) ?
                    (
                        <p className="text-sm mt-1 mb-0">{event.address.summary}</p>
                    ) : (<></>)
                }
                {
                    (event.images != null && event.images.length > 0) ?
                    (
                        <div className="mt-3 mb-3" style={{width:"100%", overflow:"auto"}}>
                            <Gallery
                                images={event.images.map(picture => {
                                    return {
                                        src: picture.url,
                                        thumbnail: picture.url.replace('--\/', '--\/w_'+ MissionProgress.thumbnailWidth+ ',h_' + MissionProgress.thumbnailHeight + ',c_crop\/'),
                                        thumbnailWidth: MissionProgress.thumbnailWidth,
                                        thumbnailHeight: MissionProgress.thumbnailHeight,
                                        isSelected: false,
                                        caption: picture.comment
                                    }
                                })}
                                enableLightbox={true}
                                enableImageSelection={false}/>
                        </div>
                    ) : (<></>)
                }
                {
                    (event.comment != null && this.emptyValue(event.comment) == false) ?
                    (
                        <Row>
                            <Col xs="1" className="mt-1"><DefaultImage src={Resources.comment_logo}></DefaultImage></Col>
                            <Col xs="11">
                                <p className="text-sm mt-1 mb-0">
                                    {event.comment}
                                </p>
                            </Col>
                        </Row>
                        
                    ) : (<></>)
                }
                {
                    (event.validator != null && event.validator.summary != null ) ?
                    (
                        <Row>
                            <Col xs="12">
                            <Badge color="success" pill style={{width:"auto"}}>
                                <p className="text-sm mt-1 mb-0">
                                    <DefaultImage style={{fontSize:"20px", marginRight:"10px", float:"left"}} src={Resources.signature_logo}></DefaultImage>
                                    
                                    {
                                        (event.rating) ?
                                        (
                                            <Rating note={parseFloat(event.rating) / 2.0} style={{marginTop:"-10px", marginLeft:"10px"}}>
                                                <>signé par {event.validator.summary}</>
                                            </Rating>
                                        ) : (
                                            <>signé par {event.validator.summary}</>
                                        )
                                    }
                                </p>
                            </Badge>
                            </Col>
                        </Row>
                    ) : (<></>)
                    

                }
            </div>
        </div>
    }

    renderIncidents(step, startDate, endDate)
    {
        if (!endDate)
        {
            endDate = DateHelper.nowAsTime();
        }

        if (step.incidents && step.incidents.length > 0)
        {
            return step.incidents.map((incident, index) => {
                const incidentTime = incident.time_as_string.substring(0, 5)
                
                if (incidentTime > startDate && incidentTime <= endDate)
                {
                    return <>
                        <Row>
                            <Col xs="1" style={{maxWidth:"4%"}}><DefaultImage className={incident.impact.code} src={incident.type.ui_icon_web}></DefaultImage></Col>
                            <Col xs="1" className={incident.impact.code} style={{paddingRight:0}}>{incidentTime}</Col>
                            <Col xs="10"><span className={incident.impact.code} >{incident.type.label} (incident n°{incident.position} {incident.delay > 0 ? " retard de " + incident.delay_as_string : ""})</span>
                                {
                                    (incident.comment != null && this.emptyValue(incident.comment) == false) ?
                                    (
                                        <Row>
                                            <Col xs="1" className={"mt-1"}><DefaultImage src={Resources.comment_logo}></DefaultImage></Col>
                                            <Col xs="11">
                                                <p className="text-sm mt-1 mb-0">
                                                    {incident.comment}
                                                </p>
                                            </Col>
                                        </Row>
                                        
                                    ) : (<></>)
                                }
                                {
                                    (incident.images != null && incident.images.length > 0) ?
                                    (
                                        <div className="mt-3 mb-3" style={{width:"100%", overflow:"auto"}}>
                                            <Gallery
                                                images={incident.images.map(picture => {
                                                    return {
                                                        src: picture.url,
                                                        thumbnail: picture.url.replace('--\/', '--\/w_'+ MissionProgress.thumbnailWidth+ ',h_' + MissionProgress.thumbnailHeight + ',c_crop\/'),
                                                        thumbnailWidth: MissionProgress.thumbnailWidth,
                                                        thumbnailHeight: MissionProgress.thumbnailHeight,
                                                        isSelected: false,
                                                        caption: picture.comment
                                                    }
                                                })}
                                                enableLightbox={true}
                                                enableImageSelection={false}/>
                                        </div>
                                    ) : (<></>)
                                }
                            </Col>
                        </Row>


                        

                    </>
                }
                else
                {
                    return null;
                }
            })


        }
    }

    renderTour()
    {
        
        return <>
        <div
            className="timeline timeline-one-side"
            data-timeline-axis-style="dashed"
            data-timeline-content="axis"
        >
            {
                (this.props.mission.tour.start_date) ?
                (
                    <div>
                        <span className={"timeline-step badge badge-MISSION_EVENT_TYPE_START t2y-rounded"}>
                        <DefaultImage src={Resources.MISSION_EVENT_TYPE_START}></DefaultImage>
                        </span>
                        <div className="timeline-content" style={{width: "100%", maxWidth: "100%"}}>
                        <Row>
                            <Col xs="12"><p className={"text-md mt-1 mb-0 font-weight-bold"}>Démarrage de la mission</p></Col>
                        </Row>
                        </div>
                    </div>
                ) : (null)
            }
            {
                    this.props.mission.tour.main_steps.map((step, stepIndex) => {
                    var timeLineContentStyle = {width: "100%", maxWidth: "100%"}
                    if (step.as_title1)
                    {
                        timeLineContentStyle.marginLeft = 0;

                    }

                    var afterStartIncident = this.renderIncidents(step, step.start_date, step.arrived_date)
                    
                    var beforeEndIncident = <></>
                    if (step.arrived_date)
                    {
                        beforeEndIncident = this.renderIncidents(step, step.arrived_date ? step.arrived_date : step.start_date, step.end_date)
                    }
                     

                    return <>
                        <div className="">
                            <div>
                                {
                                    (step.as_title1) ?
                                    (
                                        <></>
                                    ) : (
                                        <span className={"timeline-step " + (step.map_position ? "t2y-rounded" : "")}>{step.map_position ? step.map_position  : ""}</span>
                                    )
                                }
                                
                                <div className="timeline-content" style={timeLineContentStyle}>
                                <Row style={step.as_subtitle ? {marginRight:20} : {}}>
                                    <Col xs="12">
                                        <p className={"text-md mt-1 mb-0 font-weight-bold title-" + step.type.code + (step.as_subtitle ? " t2y-timeline-subtitle " : "") + (step.as_title1 ? " t2y-timeline-title " : "")}>{step.title}</p>
                                        {
                                            (step.address != null) ?
                                            (
                                                <p className="text-md mt-1 mb-0">{step.address.summary}</p>
                                            ) : (<></>)
                                        }
                                        {
                                            (step.start_date) ?
                                            (
                                                <Row>
                                                <Col xs="1" style={{maxWidth:"4%"}}><DefaultImage src={Resources.map_marker}></DefaultImage></Col>
                                                <Col xs="1" style={{paddingRight:0}}>{step.start_date}</Col>
                                                <Col xs="10">Départ
                                                    
                                                </Col>
                                                </Row>
                                            ) : (null)
                                        }
                                        { afterStartIncident }
                                        {
                                            (step.arrived_date) ?
                                            (
                                                <Row>
                                                <Col xs="1" style={{maxWidth:"4%"}}><DefaultImage src={Resources.map_marker}></DefaultImage></Col>
                                                <Col xs="1" style={{paddingRight:0}}>{step.arrived_date}</Col>
                                                <Col xs="10"> Arrivée
                                                </Col>
                                                </Row>
                                            ) : (null)
                                        }
                                        { beforeEndIncident }
                                        {
                                            (step.end_date) ?
                                            (
                                                <Row>
                                                    <Col xs="1" style={{maxWidth:"4%"}}><DefaultImage src={Resources.button_truck_icon}></DefaultImage></Col>
                                                    <Col xs="1" style={{paddingRight:0}}>{step.end_date}</Col>
                                                    <Col xs="8">{step.type.code == "MISSION_STEP_TYPE_LOADING" ? "Fin de chargement" : "Fin de déchargement" }</Col>
                                                </Row>
                                            ) : (null)
                                        }

                                        {
                                            (step.images != null && step.images.length > 0) ?
                                            (
                                                <div className="mt-3 mb-3" style={{width:"100%", overflow:"auto"}}>
                                                    <Gallery
                                                        images={step.images.map(picture => {
                                                            return {
                                                                src: picture.url,
                                                                thumbnail: picture.url.replace('--\/', '--\/w_'+ MissionProgress.thumbnailWidth+ ',h_' + MissionProgress.thumbnailHeight + ',c_crop\/'),
                                                                thumbnailWidth: MissionProgress.thumbnailWidth,
                                                                thumbnailHeight: MissionProgress.thumbnailHeight,
                                                                isSelected: false,
                                                                caption: picture.comment
                                                            }
                                                        })}
                                                        enableLightbox={true}
                                                        enableImageSelection={false}/>
                                                </div>
                                            ) : (<></>)
                                        }

                                        {
                                            (step.comment != null && this.emptyValue(step.comment) == false) ?
                                            (
                                                <Row>
                                                    <Col xs="1" className="mt-1"><DefaultImage src={Resources.comment_logo}></DefaultImage></Col>
                                                    <Col xs="11">
                                                        <p className="text-sm mt-1 mb-0">
                                                            {step.comment}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                
                                            ) : (<></>)
                                        }

                                        {
                                            (step.validator != null && step.validator.summary != null ) ?
                                            (
                                                <Row>
                                                    <Col xs="12">
                                                    <Badge color="success" pill style={{width:"auto"}}>
                                                        <p className="text-sm mt-1 mb-0">
                                                            <DefaultImage style={{fontSize:"20px", marginRight:"10px", float:"left"}} src={Resources.signature_logo}></DefaultImage>
                                                            
                                                            {
                                                                (step.rating) ?
                                                                (
                                                                    <Rating note={parseFloat(step.rating) / 2.0} style={{marginTop:"-10px", marginLeft:"10px"}}>
                                                                        <>signé par {step.validator.summary}</>
                                                                    </Rating>
                                                                ) : (
                                                                    <>signé par {step.validator.summary}</>
                                                                )
                                                            }
                                                        </p>
                                                    </Badge>
                                                    </Col>
                                                </Row>
                                            ) : (<></>)
                                            

                                        }
                                    </Col>
                                </Row>
                                </div>
                            </div>
                        </div>
                    </>
                })
            }

            {
                (this.props.mission.tour.definitive_incident) ?
                (
                    <></>
                ) : (null)  
            }
            {
                (this.props.mission.tour.park_truck_start_date) ?
                (
                    <div>
                        <span className={"timeline-step badge badge-MISSION_STEP_TYPE_PARK_TRUCK t2y-rounded"}>
                        <DefaultImage src={Resources.MISSION_STEP_TYPE_PARK_TRUCK}></DefaultImage>
                        </span>
                        <div className="timeline-content" style={{width: "100%", maxWidth: "100%"}}>
                            <Row>
                                <Col xs="12"><p className={"text-md mt-1 mb-0 font-weight-bold"}>Fin de la mission</p>
                                <Row>
                                    <Col xs="1" style={{maxWidth:"4%"}}><DefaultImage src={Resources.map_marker}></DefaultImage></Col>
                                    <Col xs="1">{this.props.mission.tour.park_truck_start_date}</Col>
                                    <Col xs="8">Départ vers le lieu de stationnement</Col>
                                </Row>
                                {
                                    (this.props.mission.tour.end_date) ?
                                    (
                                        <Row>
                                        <Col xs="1" style={{maxWidth:"4%"}}><DefaultImage src={Resources.map_marker}></DefaultImage></Col>
                                        <Col xs="1">{this.props.mission.tour.end_date}</Col>
                                        <Col xs="8"> Arrivée</Col>
                                        </Row>
                                    ) : (null)
                                }
                                
                                </Col>
                            </Row>
                        </div>
                    </div>

                ) : (null)
            }

        </div>
        </>
    }

    renderTimeLine()
    {
        var previousEvent = null;
        return <>
                    <div
                        className="timeline timeline-one-side"
                        data-timeline-axis-style="dashed"
                        data-timeline-content="axis"
                    >
                        {
                            
                            this.props.events.map((event, eventIndex) => {
                                if (event.type != "MISSION_EVENT_TYPE_NONE" && event.type != "MISSION_EVENT_TYPE_GPS_COORDONATES")
                                {
                                    var component = this.renderEvent(event, eventIndex, previousEvent)
                                    previousEvent = event;
                                    return component
                                }
                                else 
                                {
                                    return <></>
                                }
                            })
                        }
                    </div>
        </>
    }

    renderMap()
    {
        return <>    
            <MapRouteComponent childRef={(elt) => this.mapRouteComponentRef = elt} width="500px" height="500px"></MapRouteComponent>
        </>
    }
    
    render() {
        return (
            <>
                {this.renderParent()}
                <Row>
                    <Col xl="12">
                        {/*this.renderSummary()*/}
                    </Col>
                </Row>
                <Card>
                <CardHeader className="bg-transparent">
                    {this.renderSummary()}
                </CardHeader>
                <CardBody>
                <Row>
                    {
                    <Col className="order-xl-2" xl="5">
                        <Row>    
                            {
                                (this.state.isTransporter || (this.props.mission && this.props.mission.in_progress)) ?
                                (
                                    <Col lg="12">
                                        {this.renderMap()}
                                    </Col>
                                ) : (<></>)
                            }
                        </Row>
                    </Col>}
                    <Col className="order-xl-1" xl="7">
                        {this.renderTour()}

                        {/*this.renderTimeLine()*/}
                    </Col>
                </Row>

                </CardBody>
              </Card>
            </>
        );
    }
}


export default injectIntl(MissionProgress)

