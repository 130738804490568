import "../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components
 import {
   Form
 } from "reactstrap";
import RepositoryService from "../../../proxies/RepositoryService"
import { injectIntl , FormattedMessage } from 'react-intl';

import { Multiselect } from 'multiselect-react-dropdown';
import DefaultInput from "../common/defaultInput";
import StringHelper from "../../../helpers/StringHelper";
import TagsInput from "react-tagsinput";
import DefaultLink from "../common/defaultLink";
import ReactSlidingPane from "react-sliding-pane";
import DriverWorkflow from "../workflow/driver/driverWorkflow";
import Resources from "../../../resources";

class DriversInput extends DefaultInput     {

    constructor(props)
    {
        super(props, true, function(element){
            return element.text;
        });
        this.multiselectRef = React.createRef();
        this.repositoryService = new RepositoryService();

        this.state = {
            loading: false,
            driverPanelIsVisible: false,
            datas: [],
            selectedValues: []
        }
    }

    componentWillMount()
    {
        super.componentWillMount();
        this.dataBind();
    }

    componentDidMount()
    {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }

        super.componentDidMount()
    }

    hasValue()
    {
        return this.getValues() && this.getValues().length > 0;
    }

    dataBind(callback = null)
    {
        this.repositoryService.getDrivers(this, (response) => {
            var datas = []

            if (this.props.emptyElement && this.props.emptyElement == true)
            {
                datas.push({id : "", name: typeof this.props.placeholder !== "undefined" ? this.props.placeholder : "..."})
            }
            
            response.drivers.forEach(element => {
                datas.push({id : element.id, name: element.summary});
                    
            });

            this.setState({datas : datas});
            
            if (callback)
            {
                callback(this)
            }
        })
    }
    setValues(values, refresh = false)
    {
        this.setState({selectedValues: values})
    }

    clear()
    {
        if (this.multiselectRef && this.multiselectRef.current)
        {
            this.multiselectRef.current.resetSelectedValues();
        }

        this.setState({datas: [], selectedValues: []})
    }

    getValues()
    {
        return this.state.selectedValues && this.state.selectedValues.length > 0 ? 
            this.state.selectedValues :
            (this.props.values ? this.props.values : [])
        ;
    }

    onSelect(selectedList, selectedItem) {
        this.setValues(selectedList)
    }
     
    onRemove(selectedList, removedItem) {
        this.setValues(selectedList)
    }

    renderDriverWorkflow()
    {
        if (!this.state.driverPanelIsVisible)
            return <></>

        return <ReactSlidingPane
            className="some-custom-class"
            overlayClassName="some-custom-overlay-class"
            isOpen={this.state.driverPanelIsVisible}
            title={null}
            width="40%"
            subtitle={null}
            onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                this.setState({ driverPanelIsVisible: false });

                if (this.props.onHide) 
                    this.props.onHide(null)
            }}
        >
            <DriverWorkflow 
                id="driverCreationWorkflow"
                onDriverSubmited={(driverInfos) => { 
                    
                    var selectedValues = this.getValues();
                    selectedValues.push({id: driverInfos.id, name: driverInfos.summary})

                    this.setState({ driverPanelIsVisible: false, loading: false });

                    this.clear()
                    this.dataBind(input => {
                        input.setValues(selectedValues)
                    })

                    /*
                    this.setState({loading: true})

                    var formDatas = this.state.formDatas;
                    formDatas.drivers.value.push(driverInfos.id)
                    this.setState({ formDatas: formDatas, driverPanelIsVisible: false, loading: false });


                    var selectedValues = this.driversInputRef.getValues();
                    selectedValues.push({id: driverInfos.id, name: driverInfos.summary})
                    this.driversInputRef.clear();
                    this.driversInputRef.dataBind(input => {
                        // Ajout du conducteur aux valeurs déjà selectionnées
                        input.setValues(selectedValues)
                        // Confirmation de la création pour l'utilisateur
                        this.showSuccessModalNotification("Confirmation", "Le conducteur à été créé avec succès et a été ajouté à la fiche du véhicule. Il ne reste plus qu'à valider la fiche pour prendre en compte la modification")
                    })*/
                }}>
            </DriverWorkflow>

        </ReactSlidingPane>

    }
    
    renderMultiSelect()
    {
        return <Multiselect
            id="driver-input"
            singleSelect={this.props.multiple == null ? false : !this.props.multiple }
            ref={this.multiselectRef}
            options={this.state.datas} // Options to display in the dropdown
            selectedValues={this.getValues()}
            onSelect={this.onSelect.bind(this)} // Function will trigger on select event
            onRemove={this.onRemove.bind(this)} 
            displayValue="name" // Property name to display in the dropdown options
            placeholder={StringHelper.translate(this, "driver.multiselect_placeholder")}
            emptyRecordMsg={StringHelper.translate(this, "driver.multiselect_empty_result")}
            hidePlaceholder={true}
            style={{
                chips: { background: "var(--jaune)", color: "var(--gris-fonce)", fontSize:"16px"},
                option: {
                }
            }}
        />
    }

    render()
    {
        return <>
            {this.rendeInner(this.renderMultiSelect())}
            {
                (this.props.add) ?
                (
                    <>
                        {this.renderDriverWorkflow()}
                        <DefaultLink 
                            icon={Resources.button_add_icon}
                            small={true} 
                            onClick={(e) => this.setState({driverPanelIsVisible: true})} 
                            style={{paddingTop:"5px", float:"right"}}>
                                Créer un nouveau conducteur
                        </DefaultLink>
                    </>
                ) : (<></>)
            }

        </>
    }
  }
 
  export default injectIntl(DriversInput);