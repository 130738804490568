
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    Col,
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    NavLink,
    Modal,
    Button
  } from "reactstrap";
import { Calendar } from 'react-date-range';

import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator"
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import FormHourInput from "../../pages/components/form/FormHourInput";
import ReactDatetime from "react-datetime";
import RepositoryService from "../../../proxies/RepositoryService";

import { injectIntl , FormattedMessage } from 'react-intl';
import FormRepository from "../../pages/components/form/FormRepository";
import MaskedInput from 'react-text-mask'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import FormComponent from "../../pages/components/form/FormComponent";
import DefaultImage from "./defaultImage";
import DefaultPopover from "./defaultPopover";
import StringHelper from "../../../helpers/StringHelper";
import { renderToString } from "react-dom/server";
import DefaultLabel from "./defaultLabel";
import DateHelper from "../../../helpers/DateHelper";

var moment = require('moment');
require('moment/locale/fr');

class DefaultInput extends FormComponent {

    constructor(props)
    {
        super(props)

        this.inputRef = React.createRef();
        this.state = {
            value: null
        }
    }

    componentDidMount()
    {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    componentWillMount()
    {

    }


    getRequiredWithoutValueClass()
    {
        return "t2y-required"
    }

    getRequiredWithValueClass()
    {
        return  "t2y-required-with-value"
    }

    setValue(value)
    {
        this.setState({value: value})
    }

    getValue()
    {
        return this.state.value != null ? this.state.value : this.props.value;
    }

    onChange(e)
    {
        this.setState({ value: e})
        if (this.props.manager)
        {
            this.props.manager.checkHtmlInput(this.getKey())
        }
    }

    focus()
    {
        this.inputRef.current.focus()
    }

    hasValue()
    {
        var value = this.getValue();

        if (typeof value !== "undefined" && value  && value != null && value != "")
        {
            if (this.props.type == "number" && value == 0)
            {
                return false;
            }
            else
            {
                return true;
            }
        }
        else
        {
            return false;
        }
    }

    rendeInner(control)
    {
        if (this.props.type == "hidden")
            return control;
        

        var className = "t2y-default-container";
        var readOnlyTargetId = StringHelper.createDomElementId();
        if (this.props.required)
        {
            className += " " + (this.hasValue() ? this.getRequiredWithValueClass() : this.getRequiredWithoutValueClass()) // " t2y-required" + (this.hasValue() ? "-with-value" : "")
        }

        if (this.props.readOnly && this.props.readOnly == true)
        {
            className += " t2y-readonly "
        }

        if (this.props.computed && this.props.computed == true)
        {
            className += " t2y-readonly "
        }

        if (this.containerClassName)
        {
            className += " " + this.containerClassName
        }

        return <>
        <div id={this.getKey() + "-main-container"} className={className}>
            <div className={"btn " + /*btn-default */ " t2y-default-input-container " + (this.props.type ? "t2y-" + this.props.type : "") } >
                {
                    (this.props.icon) ?
                    (
                        <span className="btn-inner--icon t2y-default-input-icon-container" >
                            <DefaultImage src={this.props.icon}></DefaultImage>
                        </span>
                    ) : (<></>)
                }
                {control}
                {
                    (this.props.append) ?
                    (
                        <span class="btn-inner--icon t2y-default-input-icon-container" dangerouslySetInnerHTML={{__html: 
                            React.isValidElement(this.props.append) ? renderToString(this.props.append) : this.props.append}}></span>
                    ) : (<></>)
                }
                {
                    (this.props.popover)?
                    (
                        <span className="btn-inner--icon t2y-default-input-icon-container" style={{color: "black", float:"right"}}>
                            {this.props.popover}
                        </span>
                    ) : (<></>)
                }
                {
                    (this.props.readOnly && this.props.readOnly == true) ?
                    (
                        <DefaultPopover content="Cette donnée n'est pas modifiable" component={
                        <div className="t2y-readonly-icon" id={readOnlyTargetId}>
                            <i className="fas fa-lock"></i>
                        </div>} targetId={readOnlyTargetId} />
                    ) : (<></>)
                }
                {
                    (this.props.computed && this.props.computed == true) ?
                    (
                        <DefaultPopover content={this.props.computedDescription} component={
                        <div className="t2y-readonly-icon" id={readOnlyTargetId}>
                            <i className="fas fa-calculator"></i>
                        </div>} targetId={readOnlyTargetId} />
                    ) : (<></>)
                }
            </div>  
        </div>
        {
            (this.props.message) ?
            (
                <div>
                    <DefaultLabel className="ty2-default-input-description">{this.props.message}</DefaultLabel>
                </div>
            ) : (<></>)
        }
        </>
    }

    buildProps()
    {
        var customClass = "t2y-default-input-control " + this.props.className
        if (this.props.icon && this.props.popover)
        {
            customClass += " t2y-with-icon-and-popover"
            
        }
        else if (this.props.icon)
        {
            customClass += " t2y-with-icon"
        }
        else if (this.props.popover)
        {
            customClass += " t2y-with-popover"
        }
        else if (this.props.append)
        {
            customClass += " t2y-with-append"
        }

        var props = {
            "error-parent-container" : this.props.error_parent_container ? this.props.error_parent_container : this.getKey() + "-main-container",
            "id" : this.getKey(),
            "name" : this.getKey(),
            "className" : customClass,
            "onChange": (e)=> {
                this.onChange(e.currentTarget.value)
                if (this.props.onChange)
                {
                    this.props.onChange(e)
                }
            },
            "onBlur": (e) => {
                if (this.props.onBlur)
                {
                    this.props.onBlur(e);
                }
            },
            "onFocus": (e) => {
                if (this.props.onFocus)
                {
                    this.props.onFocus(e);
                }
            },
            "autoComplete" : "new-password" ,
            "value" : this.getValue(),
            "placeholder": !this.props.placeholder ? "..." : this.props.placeholder,
            "mask": this.props.mask,
            "type": this.props.type,
            "rows": this.props.rows,
            "manager": this.props.manager,
            "focus": this.props.focus
        }

        if (this.props.autoComplete)
        {
            props["autocomplete"] = this.props.autoComplete
        }

        if (this.props.readOnly && this.props.readOnly == true)
        {
            props["disabled"] = "disabled"
        }

        if (this.props.computed && this.props.computed == true)
        {
            props["disabled"] = "disabled"
        }

        return props
    }

    render() {
        var component = null
        
        if (this.props.mask)
        {
            component = <MaskedInput {...this.buildProps()}/>
        }
        else if (this.props.type && this.props.type == "textarea")
        {
            component = <textarea {...this.buildProps()} style={{width:"100%", height:"100%"}} />
        }
        else if (this.props.type && this.props.type == "select")
        {
            component = <select {...this.buildProps()}>{this.props.children}</select>
        }
        else
        {
            component = <input ref={this.inputRef}  {...this.buildProps()} />
        }

        return this.rendeInner(
                component
        )
    }
}

export default DefaultInput
