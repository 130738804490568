
import React from "react";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
// reactstrap components
import {
    Container,
    Row,
    Col
  } from "reactstrap";
  
import queryString from 'query-string';
import { injectIntl, FormattedMessage } from 'react-intl';
import SecurityService from '../../../proxies/SecurityService'
import DataList from "../components/DataList";
import DriverCard from '../components/card/DriverCard';
import Resources from "../../../resources";
import StringHelper from "../../../helpers/StringHelper.js";
import FormValidationComponent from "../components/FormValidationComponent.jsx";

class Drivers extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.securityService = new SecurityService();
        this.state = {
            user : this.securityService.getUser()
        }
    }

    componentDidMount()
    {
        const parsedQueryString = queryString.parse(window.location.search);
        if (parsedQueryString.mode && parsedQueryString.mode == "success")
        {
            this.showSuccessToastNotification(
                StringHelper.translate(this, "driver.save_confirmation_title"),    
                StringHelper.translate(this, "driver.save_confirmation_message")
            )
        }
    }
 
    render() {
        return (
            <>
            {this.renderParent()}
            <Container className="mt--4" fluid>
            <Row>
                <Col xl="12">
                <DataList
                    context={"drivers"}
                    title={StringHelper.translate(this, "Default.menu_drivers")}
                    titleIcon={Resources.drivers_logo}
                    allowAddButton={true}
                    addButtonTitle="Ajouter un conducteur"
                    addButtonUrl="/admin/new-driver"
                    allowRemovedButton={true}
                    removedButtonTitle='Voir les conducteurs supprimés'
                    notRemovedButtonTitle='Voir les conducteurs non supprimés'
                    apiUrl={"/api/transporter/drivers"}
                    apiRemovedUrl={"/api/transporter/drivers/deleted"}
                    apiResponseProperty={"drivers"}
                    cardTemplate={DriverCard}
                />
                </Col>
            </Row>
              
            </Container>
            </>
        )
    }
}


export default injectIntl(Drivers)