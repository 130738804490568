import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";
import { GeolocatedProps, geolocated } from "react-geolocated";

import {
    Container,
    ButtonGroup,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import DefaultButton from '../../common/defaultButton'
import { renderToString } from 'react-dom/server'
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import SecurityService from "../../../../proxies/SecurityService";
import GeocodingAndNavigationService from "../../../../proxies/GeocodingAndNavigationService";
import Step from "../step"
import Resources from "../../../../resources";
import { add } from "date-fns";
import Message from "../../common/message";
import DefaultImage from "../../common/defaultImage";
import ShipperCustomerInput from "../../business/shipperCustomerInput";
import ShipperService from "../../../../proxies/ShipperService";
import AddressWorkflow from "./addressWorkflow";
import DefaultLink from "../../common/defaultLink";
import StringHelper from "../../../../helpers/StringHelper";

var moment = require('moment');
require('moment/locale/fr');

class AddressSelectionStep extends Step {

    constructor(props) {
        super(props)
        
        this.securityService = new SecurityService();
        this.businessService = new BusinessService();
        this.shipperService = new ShipperService();
        this.geocodingAndNavigationService = new GeocodingAndNavigationService();
        this.current_address_id = null;
        this.state = {
            internalAddresses: null,
            subcontractorAddresses: null, 
            selectionMode: this.props.context == "MISSION_STEP_TYPE_UNLOADING" ? "client" : "internal", /* subcontractor, client, internal */
            onlyAutocomplete: false,
            allowGeolocalisation: false,
            options: [],
            showAutoSuggest: false,
            isCompany: true
        }
    }

    componentWillMount() {
        super.componentWillMount()

        // Vérification de l'activation de la géolocalisation
        if (this.props.addressSelectionGeolocalisation == true && this.props.isGeolocationAvailable && this.props.isGeolocationEnabled && this.state.onlyAutocomplete == false)
        {
            this.setState({allowGeolocalisation: true})
        }

        // Récupération des adresses
        if (this.securityService.isConnected())
        {
            this.businessService.getAddresses(null, (response) => {
                var internalAddresses = []
                var subcontractorAddresses = []
                if (response.addresses != null)
                {
                    response.addresses.forEach(address => {
                        // Addresse interne
                        if (["ADDRESS_TYPE_INTERNAL", "ADDRESS_TYPE_BILLING"].includes(address.type.code))
                        {
                            internalAddresses.push(address)
                        }
                        else // Sinon addresse d'un sous-traitant
                        {
                            subcontractorAddresses.push(address)
                        }
                    })
                }

                this.setState({internalAddresses: internalAddresses, subcontractorAddresses: subcontractorAddresses})
            }, (httpErrorResponse) => {
                this.setState({internalAddresses: [], subcontractorAddresses: []})
            })
        }
    }

    componentDidMount() {
        super.componentDidMount()
    }

    allowNextStep()
    {
        return this.state.value && (this.state.value.address_id != null || (
            this.state.value.summary != null &&
            this.state.value.gps_lat != null && 
            this.state.value.gps_long != null
        ));
    }

    setValues(address, nextStep) {
        this.props.workflow.data = address;
        
        this.props.workflow.goToSpecificStep(nextStep)
    }

    getKnownAddress(address)
    {
        var data = {
            id: address.id,
            name: address.name,
            address_id: address.id,
            gps_external_id: address.gps_external_id,  
            gps_lat: address.gps_lat,
            gps_long: address.gps_long,
            gps_level: address.gps_level,
            summary: address.name,
            street: address.street,
            street2: address.street2,
            zip_code: address.zip_code,
            city: address.city,
            country_code: "COUNTRY_FR",
            customer: address.customer
        }

        if (address.customer)
        {
            this.shipperService.getCustomerAddressContacts(this, address.customer.id, address.id, (response) => {
                data.users = response.list;
                
                if (data.users.length == 1)
                {
                    data.default_contact_id = data.users[0].id
                }
                data.type = {code: "ADDRESS_TYPE_CUSTOMER"}
                data.customer_company_address = this.state.isCompany ? true : false

                // Enregistrement des données 
                this.props.onAddressSubmited(data, () => {
                    this.setState({value: data})
                    this.setValues(data, this.props.forceContact ? "ADDRESS_CONTACT_STEP" : "ADDRESS_SUMMARY_STEP")
                })
            })
        }
        else
        {
            this.shipperService.getAddressContacts(this, address.id, (response) => {
                data.users = response.list;
                if (data.users.length == 1)
                {
                    data.default_contact_id = data.users[0].id
                }
                
                // Enregistrement des données 
                this.props.onAddressSubmited(data, () => {
                    this.setState({value: data})
                    this.setValues(data, this.props.forceContact ? "ADDRESS_CONTACT_STEP" : "ADDRESS_SUMMARY_STEP")
                })
            })
        }
    }

    getPoint()
    {
        if (this.props.coords == null)
        {
            this.props.workflow.showError("Votre position ne peux pas être utilisée.", "Action impossible")
            return;
        }

        var latitude = this.props.coords.latitude;
        var longitude = this.props.coords.longitude;

        var result = this.geocodingAndNavigationService.reverse(this, latitude, longitude, (response) => {
            if (response == null)
            {
                this.props.workflow.showError("Votre position ne peux pas être utilisée.", "Action impossible")
            }
            else
            {
                this.setValues(response, "ADDRESS_CONFIRMATION_STEP")
            }
        });
    }

    _handleSearch(text)
    {
        if (text != null && text.length > 3)
        {
            this.setState({loading: true, text: text, onlyAutocomplete: true})

            if (this.props.workflow.isCustomerMode() && this.shipperCustomerInputRef.hasCustomerId())
            {
                this.shipperService.searchForCustomerAddress(this, this.shipperCustomerInputRef.getCustomerId(), text, (response) => {
                    var customerAddresses = response.list
                    var hereSuggestions = this.state.hereSuggestions;
                    var options = (customerAddresses ? customerAddresses : []).concat(hereSuggestions)

                    options.push({
                        label: renderToString(<>
                            <strong>
                                <DefaultImage src={"far fa-frown"}/>
                                Aucun résultat ne correspond à l'adresse que je recherche
                            </strong>
                        </>)
                    })
                    
                    this.setState({ options: options, loading: false, customerAddresses: customerAddresses})
                })
            }

            // Recherche dans la base de données de Here
            this.geocodingAndNavigationService.autosuggest(this, text, (hereSuggestions) => {
                
                var options = (this.state.customerAddresses ? this.state.customerAddresses : []).concat(hereSuggestions)

                options.push({
                    label: renderToString(<>
                        <strong>
                            <DefaultImage src={"far fa-frown"}/>
                            Aucun résultat ne correspond à l'adresse que je recherche
                        </strong>
                    </>)
                })

                

                this.setState({ options: options, loading: false, hereSuggestions: hereSuggestions})
            })
        }
        else
        {
            this.setState({options: [], text: null, onlyAutocomplete: false})
        }
    }

    _onChanged(gpsInfos)
    {
        if (gpsInfos != null)
        {
            if (gpsInfos.id)
            {
                //console.log(gpsInfos)
                this.getKnownAddress(gpsInfos)
                return
            }


            this.geocodingAndNavigationService.getAutoSuggestDetail(gpsInfos.gps_external_id, (response) => {
                /*
                //console.log(response)
                document.getElementById("gps_external_id").value = response.gps_external_id;
                document.getElementById("gps_lat").value = response.gps_lat;
                document.getElementById("gps_long").value = response.gps_long;
                document.getElementById("gps_level").value = response.gps_level;
                document.getElementById("address_label").value = response.label;
                document.getElementById("street").value = response.street;
                document.getElementById("zip_code").value = response.zipCode;
                document.getElementById("city").value = response.city;
                document.getElementById("country").value = response.country;
                
                document.getElementById("search_input").value = response.label;
                */

                const address = {
                    address_id: null,
                    gps_external_id: response.gps_external_id,  
                    gps_lat: response.gps_lat,
                    gps_long: response.gps_long,
                    gps_level: response.gps_level,
                    summary: response.label,
                    street: response.street,
                    street2: null,
                    zip_code: response.zipCode,
                    city: response.city,
                    country_code: response.country,
                    customer: this.props.workflow.isCustomerMode() ? this.shipperCustomerInputRef.getValue() : {id: null, label: null},
                    company: this.props.workflow.isCustomerMode() ? this.shipperCustomerInputRef.isCompany() : true
                }

                this.setState({
                    onlyAutocomplete: false,
                    value: address
                })

                //console.log(response)
                //this._exit();
                // On vide la liste des propositions
                this.setState({options: [], text: null, onlyAutocomplete: false})

                this.setValues(address, "ADDRESS_CONFIRMATION_STEP")

                if (this.props.onSelected) {
                    this.props.onSelected(response)
                }
            })
        }
        else
        {
            this.setState({value: null})
        }
    }

    onCustomerSelection(customer)
    {
        this.shipperService.searchForCustomerAddress(this, customer.id, null, (response) => {
            var customerAddresses = response.list
            var options = (customerAddresses ? customerAddresses : [])

            this.setState({ options: options, loading: false, hereSuggestions: [], customerAddresses: customerAddresses})
        })
    }

    onCustomerSearch(text)
    {
        // On vide le champs de recherche d'addresse
        var input = document.getElementById("search_input")
        if (input) 
            input.value = "";
        // On vide les propositions
        this.setState({ options: [], loading: false, hereSuggestions: [], customerAddresses: [], showAutoSuggest: text.length > 0})
    }

    onCustomerTypeChange(isCompany)
    {
        this.setState({isCompany: isCompany})
    }

    renderLoadingContext()
    {
        return <Row className="justify-content-center">
            <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                <Button className={classnames({ active: this.state.selectionMode === "internal" }) + " multiChoiceBtn"} color="secondary"
                        onClick={() => this.switchSelectionMode("internal")}>
                    Entrepôts
                </Button>
                <Button className={classnames({ active: this.state.selectionMode === "subcontractor" }) + " multiChoiceBtn"} color="secondary"
                    onClick={() => this.switchSelectionMode("subcontractor")}>
                    Sous-traitants
                </Button>
                <Button className={classnames({ active: this.state.selectionMode === "client" }) + " multiChoiceBtn"} color="secondary"
                    onClick={() => this.switchSelectionMode("client")}>
                    Clients
                </Button>
            </ButtonGroup>
        </Row>
    }

    renderUnloadingContext()
    {
        return <Row className="justify-content-center">
            <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                <Button className={classnames({ active: this.state.selectionMode === "client" })} color="secondary"
                    onClick={() => this.switchSelectionMode("client")}>
                    Client
                </Button>
                <Button className={classnames({ active: this.state.selectionMode === "subcontractor" })} color="secondary"
                    onClick={() => this.switchSelectionMode("subcontractor")}>
                    Sous-traitant
                </Button>
                <Button className={classnames({ active: this.state.selectionMode === "internal" })} color="secondary"
                        onClick={() => this.switchSelectionMode("internal")}>
                    Entrepôt
                </Button>
            </ButtonGroup>
        </Row>
    }
    
    renderTruckContext()
    {
        return <Row className="justify-content-center">
            <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                <Button className={classnames({ active: this.state.selectionMode === "internal" })} color="secondary"
                        onClick={() => this.switchSelectionMode("internal")}>
                    Lieu de stationnement
                </Button>
                <Button className={classnames({ active: this.state.selectionMode === "client" })} color="secondary"
                    onClick={() => this.switchSelectionMode("client")}>
                    Nouvelle adresse
                </Button>
            </ButtonGroup>
        </Row>
    }

    renderHeaders()
    {
        switch(this.props.context)
        {
            case "MISSION_STEP_TYPE_UNLOADING":
                return this.renderUnloadingContext();
            break;
            case "MISSION_STEP_TYPE_LOADING":
                return this.renderLoadingContext();
            break;
            case "TRUCK":
                return this.renderTruckContext();
            break
        }
    }

    renderCurrent()
    {
        if (this.props.creation && this.props.creation == true)
        {
            return this.renderAutocomplete()
        }

        switch(this.state.selectionMode)
        {
            case "client":
                if (this.props.workflow.isCustomerMode())
                {
                    return this.renderShipperCustomer()
                }
                else{
                    return this.renderAutocomplete();
                }
            break;
            case "point":
                return <></>
            break;
            case "internal":
                return this.renderKnownAddress("internalAddresses");
            break;
            case "subcontractor":
                return this.renderKnownAddress("subcontractorAddresses");
            break;
        }

        return <></>
    }

    switchSelectionMode(mode)
    {
        var value = null;

        this.setState({
            selectionMode: mode,
            value: value
        })
    }

    renderShipperCustomer()
    {
        return <>
            <ShipperCustomerInput 
                childRef={(elt) => this.shipperCustomerInputRef = elt} 
                onChange={(customer) => this.onCustomerSelection(customer)}
                onSearch={(text) => this.onCustomerSearch(text)}
                onCustomerTypeChange={isCompany => this.onCustomerTypeChange(isCompany)}
            >
            </ShipperCustomerInput>
        
        {
            (this.state.showAutoSuggest || this.state.isCompany == false) ?
            (
                this.renderAutocomplete()
            ) : (<></>)
        }

        </>
    }

    renderAutocomplete() 
    {
        return <>
            <Row className="mt-3">
                <Col xl="12">
                <div className="btn btn-default" style={{width: "100%", backgroundColor:this.props.backgroundColor, borderColor:this.props.textColor, color:this.props.textColor, padding:0}}>
                <span className="btn-inner--icon" style={{ color:this.props.iconColor, fontSize: "20px", float: "left", margin: "0.625rem 1.25rem" }}>
                    <i className="fas fa-map-marker-alt"></i>
                </span>
                <input
                    
                    id="search_input"
                    focus={this.state.focus}
                    onChange={(e)=> this._handleSearch(e.currentTarget.value)}
                    autoComplete="OFF" 
                    style={{ border:"none", height:"50px", marginTop:"0px", float:"left", width:"80%", color:this.props.textColor}}
                    placeholder={this.props.autocompletePlaceHolder ? this.props.autocompletePlaceHolder : this.translateMessage("address.autocomplete_shipper_placeholder")}
                />
                <div style={{width:"100%", overflow:"scroll"}}  className={"dropdown-menu " + ((this.state.options.length > 0) ? "show": "")}>
                    { this.state.options.map((option, index) => 
                            <div 
                                key={"selectItem" + index} 
                                className="dropdown-item" onClick={ ()=> this._onChanged(option)}
                                dangerouslySetInnerHTML={{__html: option.html ? option.html : option.label}}
                            > 
                            </div> 
                        )
                    }
                </div>
            </div>
                </Col>
            </Row>
            {
                (this.state.allowGeolocalisation) ?
                (
                    <>
                    <Row style={{lineHeight:"20px"}}>
                        <Col xs="4">
                            <hr/>
                        </Col>
                        <Col xs="4" style={{marginTop:"20px", textAlign:"center"}}>
                            OU
                        </Col>
                        <Col xs="4">
                            <hr/>
                        </Col>
                    </Row>
                    <Row className="    ">
                        <Col xl="12" className="text-center">
                            <DefaultButton icon="fas fa-crosshairs" onClick={(e) => this.getPoint() } className="t2y-secondary-button" >
                                <FormattedMessage id="utiliser ma position actuelle"></FormattedMessage>
                            </DefaultButton>
                        </Col>
                    </Row>
                    </>
                ) : (<></>)
            }
        </>
    }

    renderAddressWorkflow(type)
    {
        if (!this.state.addressPanelIsVisible)
            return <></>

        return <div style={{zIndex:"9999"}}><ReactSlidingPane
            className="renderAddressWorkflowClass"
            overlayClassName="renderAddressWorkflowOverlayClass"
            isOpen={this.state.addressPanelIsVisible}
            style={{zIndex: "9999"}}
            title={null}
            width="40%"
            subtitle={null}
            onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                this.setState({ addressPanelIsVisible: false });

                if (this.props.onHide) 
                    this.props.onHide(null)
            }}
        >
            <FormLoadingIndicator loading={this.state.loading}>
            <div className="container">
                <AddressWorkflow 
                    creation={true} 
                    customer={false}
                    autocompletePlaceHolder={
                        StringHelper.translate(this, "address.autocomplete_" + (this.securityService.isShipper() ? "shipper" : "transporter") + "_placeholder")
                    }
                    addressConfirmationAllowOperationalAddress={true}
                    onAddressSubmited={(data) => { 
                        this.setState({loading: true})
                        data.type_code = (type == "internalAddresses" ? "ADDRESS_TYPE_INTERNAL" : "ADDRESS_TYPE_SUBCONTRACTOR")
                        data.skip_contact = true
                        // Création de la nouvelle adresse
                        this.businessService.createAddress(null, data, (response) => {
                            // Récupération de l'identifiant de la nouvelle adresse
                            data.id = response.id;
                            this.getKnownAddress(data)
                        })
                    }
                }></AddressWorkflow>
            </div>
            </FormLoadingIndicator>
        </ReactSlidingPane>
        </div>
    }

    renderKnownAddress(type)
    {
        // Récupération des adresses
        var addresses = this.state[type];
            

        if (addresses == null)
        {
            return <>Chargement en cours ...</>
        }
        else
        {
            
            if (addresses.length == 0)
            {
                return <>
                    {this.renderAddressWorkflow(type)}
                    <Message type="infos" style={{marginTop: "10px"}}>
                        {StringHelper.translate(this, "address." + (this.securityService.isShipper() ? "shipper" : "transporter") + "_no_results_" + type)}
                        {
                            (this.securityService.isShipper())?
                            (
                                <DefaultButton className="t2y-thirdparty-button small"
                                    onClick={(e) => this.setState({addressPanelIsVisible: true})} 
                                    style={{paddingTop:"5px", float:"right"}}>
                                        {StringHelper.translate(this, "address.new_" + type)}
                                </DefaultButton>
                            ) : (<></>)
                        }
                    </Message>
                </>
            }
            else
            {
                return <>
                    {this.renderAddressWorkflow(type)}
                    {
                        addresses.map(address => {
                            var isSelected = (this.state.value != null && this.state.value.address_id == address.id) || this.current_address_id == address.id;

                            return <>
                                <Row className="mt-3">
                                    <Col xs="12">
                                <Button onClick={() => this.getKnownAddress(address)} color="default" type="button" 
                                    style={{ width: "100%", color:"red", 
                                        backgroundColor: !isSelected ? this.props.backgroundColor : Resources.color_selected_date, 
                                        borderColor:this.props.textColor, 
                                        color:this.props.textColor}}
                                >
                                    <span className="btn-inner--icon" style={{ 
                                        color: !isSelected ? this.props.iconColor : "black", fontSize: "20px", float: "left", marginRight: "20px" }}>
                                        <i className="fas fa-map-marker-alt"></i>
                                    </span>
                                    <span className="btn-inner--text" style={{ textAlign:"left", float: "left", color:this.props.textColor, paddingTop:"5px" }}>
                                        {address.name}
                                        <br/>
                                        <div>{address.street}</div>
                                        <div>{address.zip_code + " " + address.city}</div>
                                    </span>
                                </Button>
                                </Col>  
                                </Row>
                            </>
                        })
                    }
                    {
                        (this.securityService.isShipper())?
                        (
                            <DefaultLink small={true} 
                                onClick={(e) => this.setState({addressPanelIsVisible: true})} 
                                style={{paddingTop:"5px", float:"right"}}>
                                    {StringHelper.translate(this, "address.new_" + type)}
                            </DefaultLink>
                        ) : (<></>)
                    }
                </>
            }
        }
    }


    render() {
        return (
            <>
                {/*
                <Input type="hidden" id="gps_external_id" />
                <Input type="hidden" id="gps_lat" />
                <Input type="hidden" id="gps_long" />
                <Input type="hidden" id="gps_level" />
                <Input type="hidden" id="address_label" />
                <Input type="hidden" id="street" />
                <Input type="hidden" id="zip_code" />
                <Input type="hidden" id="city" />
                <Input type="hidden" id="country" />
                */}
                {this.props.addressSelectionComponent}
                <Row className="justify-content-center">
                    <Col xl="12">
                        {this.renderHeaders()}
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xl="12">
                        {this.renderCurrent()}
                    </Col>
                </Row>
            </>
        );
    }
}


export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(injectIntl(AddressSelectionStep));

