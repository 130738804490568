import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import ComponentAndImageStep from "../componentAndImageStep.jsx";
import DefaultLabel from "../../common/defaultLabel.jsx";
import DefaultButton from "../../common/defaultButton.jsx";
import DateHelper from "../../../../helpers/DateHelper.js";
import DefaultLink from "../../common/defaultLink.jsx";
import WebsiteHelper from "../../../../helpers/WebsiteHelper.js";
import Codes from "../../../../codes.js";
import StringHelper from "../../../../helpers/StringHelper.js";
import Message from "../../common/message"
import ShipperService from "../../../../proxies/ShipperService.js";


class RefuseCallForBidsResultStep extends ComponentAndImageStep {

    constructor(props) {
        super(props)

        this.shipperService = new ShipperService();
        
        this.state = {
            loading: true,
            image: require('../../../../assets/img/illustrations/undraw_cancel_t2Y.svg').default,
            title: '',
            subtitle: 'Traitement en cours ...',
            message: null
        }
    }

    componentWillMount() {
        super.componentWillMount()

        this.shipperService.refuseCallForBidsResult(this, this.props.workflow.state.input, (response) => {
            this.setState({
                title:"Confirmation",
                subtitle: 'La proposition du transporteur a été déclinée'
            })

            this.props.parent.refuse()
        })
    }

    componentDidMount() {
        super.componentDidMount()
    }

    isLoading()
    {
        return this.state.loading
    }

    submit(pid)
    {
        this.props.workflow.setState({visible: false, current_step: "SEARCH_RESULT_DETAIL"})
        
    }
    
    renderComponent() {

        return <>
            
            <br />
            <br />
            <br />
        </>
        
        /*
        return <>
            <Container>
                <Row className="">
                    <DefaultLabel className="text-justify" content="Nous avons envoyé votre demande au transporteur responsable du camion que vous avez choisi. Nous sommes dans l'attente de sa réponse."></DefaultLabel>
                    <DefaultLabel className="text-justify" content="Vous pouvez suivre l'état de votre commande en temps réel."></DefaultLabel>
                </Row>
                <Row className="justify-content-center">
                    <DefaultButton label="Accéder à ma commande" className="t2y-secondary-button" onClick={(e) => this.goToOrder()}></DefaultButton>
                </Row>
                <Row className="justify-content-center">
                    <DefaultLink small={true} onClick={(e) => this.close()}>
                        <FormattedMessage id="Default.close"></FormattedMessage>
                    </DefaultLink>
                </Row>
            </Container>
        </>
        */
    }
}


export default injectIntl(RefuseCallForBidsResultStep)