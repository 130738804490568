const SearchMessages = {
    "Search.embeded_form_title": 'Rechercher',
    "Search.filters_title": "Filtrer par:",
    "Search.filters_price_title": "Prix",
    "Search.filters_distance_title": "Distance",
    "Search.results_title": "Recherche de camions à proximité de {place}",
    "Search.results_subtitle": "Nous avons selectionné {number} offre(s) correspondant à vos critères",
    "Search.results_extended_message" : "Nous n’avons pas trouvé de résultats pour tous vos critères. Nous vous proposons cependant des résultats à des dates approchantes ou des véhicules équivalents.",
    "Search.results_order_best" : "Les plus pertinent",
    "Search.results_order_best_light" : "pertinent",
    "Search.results_order_distance" : "Les plus proches",
    "Search.results_order_distance_light" : "proches",
    "Search.results_order_price": "Les moins chers",
    "Search.results_order_price_light": "chers",
    "Search.results_order_rating": "Les mieux notés",
    "Search.results_order_rating_light": "notés",
    "Search.results_see_detail": "Details de l'offre",
    "Search.detail_verify_transporteur": "Transporteur vérifié",
    "Search.search_transporter_title": "Rechercher un transporteur",

    "PROPERTY_TYPE_BOOL0": "Non",
    "PROPERTY_TYPE_BOOL1": "Oui",
    "PROPERTY_TYPE_BOOL-1": "Non renseigné"
}

export default SearchMessages;