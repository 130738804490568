import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import ShipperService from "../../../../proxies/ShipperService";
import GeocodingAndNavigationService from "../../../../proxies/GeocodingAndNavigationService";
import Step from "../step"
import AddressComponent from "./addressComponent";
import { add } from "date-fns";
import Message from "../../common/message";
import ShipperCustomerContactInput from "../../business/shipperCustomerContactInput";
import Title3 from "../../common/title3";
import DefaultLink from "../../common/defaultLink";
import DefaultPopover from "../../common/defaultPopover"
import DeleteButton from "../../common/deleteButton";
import StringHelper from "../../../../helpers/StringHelper";
import ArrayHelper from "../../../../helpers/ArrayHelper";
import DefaultLabel from "../../common/defaultLabel";
import DefaultButton from "../../common/defaultButton";
import Resources from "../../../../resources";
import CustomerAddressContacts from "../../mission/CustomerAddressContacts";

var moment = require('moment');
require('moment/locale/fr');

class AddressContactStep extends Step {

    constructor(props) {
        super(props)
        
        
        this.state = {
            loading: false
        }
    }

    componentWillMount() {
        super.componentWillMount()

    }

    componentDidMount() {
        super.componentDidMount()
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    getAddress()
    {
        if (this.props.workflow.data)
        {
            return this.props.workflow.data;
        }
        else
        {
            return this.props.address
        }
    }

    render() {
        var address = this.getAddress();

        return <>
            {JSON.stringify(address)}
        </>
    }
}


export default injectIntl(AddressContactStep)

