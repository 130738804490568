
import React from "react";
// reactstrap components
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu, 
    Media,
    DropdownItem
} from "reactstrap";
import Resources from "../../../resources";
import { injectIntl , FormattedMessage } from 'react-intl';
import TruckSummary from "../../components/business/truckSummary";
import Title4 from '../../components/common/title4'
import DefaultButton from '../../components/common/defaultButton'
import DefaultImage from '../../components/common/defaultImage'
import NoTruckDriverAlert from "../../components/business/noTruckDriverAlert";
import StringHelper from "../../../helpers/StringHelper.js";
import WebsiteHelper from "../../../helpers/WebsiteHelper.js";
import Orders from "../common/Orders.jsx";
import Separator from "../../components/common/separator.jsx";
import Message from "../../components/common/message.jsx";
import TransporterService from "../../../proxies/TransporterService.js";
import DefaultPopover from "../../components/common/defaultPopover.jsx";
import FormGroupInput from "../components/form/FormGroupInput.jsx";
import TrucksForOrderProposalInput from "../../components/business/trucksForOrderProposalInput.jsx";
import FormDriversInput from "../components/form/FormDriversInput.jsx";

var moment = require('moment');
require('moment/locale/fr');

class TransporterOrders extends Orders {

    constructor(props)
    {
        super(props)
    
        this.transporterService = new TransporterService();
        this.state = {
            ...(this.getParentState()),
            truckDriverAlert: false,
        };
    }

    refreshCompany(callback)
    {
        var company = this.businessService.getCompany();
        if (company.nb_completed_trucks == 0 || company.nb_drivers == 0 || company.nb_driver_truck_links == 0)
        {
            // Les données sont vide, on rafraichi avec un me
            this.securityService.me(this, (response) => {
                // On rafraichi la page
                callback(response.company);
            })
        }
        else
        {
            callback(company)
        }
    }

    componentWillMount()
    {   
        // Récupération des données du parc
        this.refreshCompany((company) => {
            if (company.nb_completed_trucks == 0 || company.nb_drivers == 0 || company.nb_driver_truck_links == 0)
            {
                this.setState({truckDriverAlert: true})
            }
            else{
                super.componentWillMount()
            }
        })
    }

    componentDidMount()
    {
    }
    
    getTransporterInvoiceId(order)
    {
        var invoices = []
        if (order.transporter_payment_state.code != "INVOICE_STATE_NOT_RECEIVED" && order.invoices.length > 0)
        {
            order.invoices.forEach(i => {
                if (i.type.code == "INVOICE_TYPE_TRANSPORTER_INVOICE")
                {
                    invoices.push(i);
                }
            })
        }

        if (invoices.length > 0)
        {
            return invoices[0].id;
        }
        else
        {
            return null;
        }
    }

    confirmTheAcceptanceOfTheOrder(order)
    {
        var driverIds = null
        var truckId = null

        // Pour les transporteurs alternatifs, on vérifie les données obligatoires
        if (order.alternative_transporter)
        {
            driverIds = this.driversInputRef ? this.driversInputRef.getValue() : null
            truckId = this.state.truck ? this.state.truck.id : null

            if (driverIds == null || driverIds.length == 0 || truckId == null)
            {
                this.showErrorModalNotification("", "Merci de saisir un véhicule et un conducteur avant d'accepter la commande")
                return 
            }
        }

        var modalOptions = {
            hideIcon: true,
            title: 'Confirmation',
            okButtonTitle:  this.translateMessage("Default.yes"),    
            cancelButtonTitle:  this.translateMessage("Default.no"),    
            component:  <>
                {StringHelper.translate(this, "Orders.acceptance_confirmation_alert_message")}
            </>,
            okButtonCallback: () => {
                // On appel de le endpoint d'acceptation
                this.transporterService.getOrderDecision(this, order.id, (order.truck ? order.truck.id : order.alternative_transporter_first_truck_id), (decisionResponse) => {
                    this.transporterService.acceptOrder(this, order.id, decisionResponse.user_id, decisionResponse.order_decision_id, {
                        driver_id: !driverIds || driverIds.length == 0 ? null : driverIds[0],
                        truck_id: truckId
                    }, (response) => {
                        // On recharge les données de l'onglet
                        this.loadOrders(this.getCurrentTab(), true)

                        this.showSuccessModalNotification("", StringHelper.translateWithHtml(
                                this, "Orders.acceptance_confirmation_message", null, null, "div", "other"
                            )
                        );
            

                    }, (httpErrorResponse) => {
                        this.setState({loading: false})
            
                        httpErrorResponse.json().then(apiResponse => {
                            this.showErrorFromResponse(apiResponse, "");
                        });
                    });
                });

                this.closeModal();
            }
        }

        this.showModalConfirmation(modalOptions);
    }
    
    confirmTheRefusalOfTheOrder(order)
    {
        var modalOptions = {
            hideIcon: false,
            title: 'Confirmation',
            okButtonTitle:  this.translateMessage("Default.yes"),    
            cancelButtonTitle:  this.translateMessage("Default.no"),    
            component:  <>
                {StringHelper.translate(this, "Orders.refusal_confirmation_alert_message")}
            </>,
            okButtonCallback: () => {
                // On appel de le endpoint de refus
                this.transporterService.getOrderDecision(this, order.id, (order.truck ? order.truck.id : order.alternative_transporter_first_truck_id), (decisionResponse) => {
                    this.transporterService.refuseOrder(this, order.id, decisionResponse.user_id, decisionResponse.order_decision_id, (response) => {
                        // On recharge les données de l'onglet
                        this.loadOrders(this.getCurrentTab(), true)

                        this.showSuccessModalNotification("", StringHelper.translate(this, "Orders.refusal_confirmation_message"));
            
                    }, (httpErrorResponse) => {
                        this.setState({loading: false})
            
                        httpErrorResponse.json().then(apiResponse => {
                            this.showErrorFromResponse(apiResponse, "");
                        });
                    });
                });
                this.closeModal();
            }
        }

        this.showModalConfirmation(modalOptions);
    }


    renderEmptyResult(component)
    {
        return <>
            <br/>
            {component}
        </>
    }

    renderPaymentState(order)
    {
        const transporterInvoiceId = this.getTransporterInvoiceId(order)
        var paymentStateLabel = order.number + "/";
        if (order.transporter_payment_state != null)
        {
            paymentStateLabel = StringHelper.translate(this, "Orders.transporter_payment_state." + order.transporter_payment_state.code)
        }
        
        return <>
            <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center" style={{cursor:"pointer"}}>
                        <DefaultImage src={Resources.downloaded_file_logo}></DefaultImage>
                        <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                            {paymentStateLabel}
                        </span>    
                        </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0"><FormattedMessage id="Orders.line_transporter_popover_title" /> </h6>
                    </DropdownItem>
                    <DropdownItem className="t2y-clickable" onClick={() => this.downloadInvoicingRequest(order.id)}>
                        <i className={Resources.downloaded_file_logo} />
                        <span><FormattedMessage id={"Orders.line_transporter_popover_facturation"}/> </span>
                    </DropdownItem>
                    {
                        (order.transporter_payment_state.code == "INVOICE_STATE_NOT_RECEIVED" && order.invoices.length > 0) ?
                        (
                            <DropdownItem className="t2y-clickable" onClick={() => WebsiteHelper.goTo(this, "/auth/manual-invoice-upload?orderId=" + order.id + "&invoiceId=" + order.invoices[0].id + "&transporterId=" + order.transporter.id, true)}>
                                <i className={Resources.goto_logo} />
                                <span><FormattedMessage id={"Orders.line_transporter_popover_upload_invoice"}/> </span>
                            </DropdownItem>
                        ) : (
                            (transporterInvoiceId != null) ?
                            (
                                <DropdownItem className="t2y-clickable" onClick={() => this.downloadInvoice(transporterInvoiceId)}>
                                    <i className={Resources.downloaded_file_logo} />
                                    <span><FormattedMessage id={"Orders.line_transporter_popover_uploaded_invoice"}/> </span>
                                </DropdownItem>
                            )  : (null)
                        )
                    }
                        
                    
                  </DropdownMenu>
                </UncontrolledDropdown>
        
        </>
    }

    renderTabContentHeader()
    {
        const headerStyle = {textAlign:"center", 
         paddingTop:"10px"}
        return <>
            <Card key={"collapse-orders"} className={"card-plain " } style={{marginBottom:0,paddingBottom:0, border:"solid 1px black"}}>
            <CardHeader role="tab" style={{marginBottom:0,paddingBottom:0}}>
            <Row>
                <Col xs="2" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_number_label")}
                </Col>
                <Col xs="2" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_date_label")}
                </Col>
                <Col xs="2" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_truck_label")}
                </Col>
                <Col xs="2" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_state_label")}
                </Col>
                <Col xs="1" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_rating_label")}
                </Col>
                <Col xs="1" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_total_ht_label")}
                </Col>
                <Col xs="2" style={headerStyle}>
                    {StringHelper.translate(this, "Orders.header_payment_state_label")}
                </Col>
            </Row>
            </CardHeader>
            </Card>
        </>
    }

    renderWaitingForValidation(order)
    {
        return <>
            <Separator></Separator>
            {
                (order.refused) ? 
                (
                    <Row>
                    <Col xs="12">
                        <Message type="infos">Vous avez déjà refusé cette demande.</Message>
                    </Col>
                    </Row>
                ) : (<></>)
            }

            <Message type="default">
                Attention :<br/>
                <ul>
                    <li>vous êtes en concurrence avec d'autres transporteurs, le premier qui accepte la mission la gagne.</li>
                    {
                        (order.alternative_transporter) ?
                        (
                            <>
                            <li>cette demande n'est pas à votre tarif habituel ; si vous acceptez la mission, elle sera rémunérée au  tarif indiqué ci-dessus : Tarif proposé : {order.ht_total_amount_as_string} € HT
                                <ul>
                                    <li>Forfait journée : {order.unit_cost_as_string} € HT</li>
                                    <li>Supplément kilométrique : {order.cost_km_as_string} € HT</li>
                                    <li>Supplément horaire : {order.cost_hour_as_string} € HT</li>
                                </ul>
                            </li>

                            </>
                        ) : (<></>)
                    }
                </ul>
            </Message>
            <Row>
                <Col md="9">
                    <Title4>{StringHelper.translate(this, "Orders.transporter_waiting_for_validation_label")}</Title4>
                </Col>
                {
                    (order.alternative_transporter) ?
                    (
                        <>
                            <Col lg="12">
                                <FormGroupInput intlId={"Véhicule proposé"} htmlFor="trucks" type="multiline">
                                    <TrucksForOrderProposalInput required={true}
                                        id="trucks"
                                        orderId={order.id} 
                                        emptyElement={false} 
                                        multiple={false} 
                                        onChange={(evt) => {
                                            const selectedValue = evt.target.value == "" ? null : evt.target.value
                                            const currentValue = this.state.truck ? this.state.truck.id : null
                                            // Si la valeur selectionnée est différente ce la précédente alors
                                            // on recharge la liste des conducteurs pour le camion
                                            if (selectedValue !=  currentValue)
                                            {
                                                this.trucksInputRef.setValue(selectedValue)
                                                this.setState({truck: (selectedValue == null ? null : {id: evt.target.value })})
                                                if (this.driversInputRef)
                                                {
                                                    this.driversInputRef.dataBind(selectedValue)
                                                }
                                            }
                                        }}
                                        childRef={(elt) => this.trucksInputRef = elt}  
                                        manager={this} 
                                        name="trucks"></TrucksForOrderProposalInput>
                                </FormGroupInput>
                            </Col>
                            {
                                (this.state.truck) ?
                                (
                                    <Col xs="12">
                                        <FormGroupInput intlId={"Conducteur proposé"} htmlFor="driver_id" type="multiline">
                                            <FormDriversInput truck={this.state.truck} childRef={elt => this.driversInputRef = elt} required={true} manager={this} name="drivers"></FormDriversInput>
                                        </FormGroupInput>
                                    </Col>
                                ) : (<></>)
                            }
                            <Col md="9">

                            </Col>
                        </>

                    ) : (<></>)
                }
                <Col md="1">
                    <DefaultButton className="t2y-removed-button-alt small " onClick={(e) => this.confirmTheRefusalOfTheOrder(order)}>
                    {StringHelper.translate(this, "Orders.transporter_waiting_for_validation_refuse_label")}
                    </DefaultButton>
                </Col>
                <Col md="1">
                    <DefaultButton className="btn-success small ml-5"  onClick={(e) => this.confirmTheAcceptanceOfTheOrder(order)}>
                    {StringHelper.translate(this, "Orders.transporter_waiting_for_validation_accept_label")}
                    </DefaultButton>
                </Col>
            </Row>
            
            
        </>
    }

    renderTabContentOrder(order, orderIndex)
    {
        const headerStyle = {textAlign:"center", paddingTop:"10px"}
        const collapseCode = "collapse" + order.id;
        const format = this.props.intl.formatMessage({id: "Default.day_month_year_format"})
        const dayMonthFormat = this.props.intl.formatMessage({id: "Default.day_month_format"})

        return <Card key={"collapse"+ orderIndex} className={"card-plain " + collapseCode } style={{marginBottom:0,paddingBottom:0, border:"solid 1px black"}}>
            <CardHeader role="tab" style={{marginTop:0,marginBottom:0, paddingTop:0, paddingBottom:0}} aria-expanded={this.expandedIsOpen(collapseCode, order.id)}>
                <Row className="t2y-clickable">
                    <Col xs="2" style={headerStyle} >
                        <h5 className={"mb-0 t2y-clickable "} >
                            {
                                (order.missing_driver_alert) ?
                                (
                                    <DefaultPopover 
                                    placement="right"
                                    component={<span id={"order_"+ orderIndex} className={order.missing_driver_alert ? "driver-alert-icon" : ""}>&nbsp;</span>}
                                    targetId={"order_"+ orderIndex} content={StringHelper.translate(this, "Orders.line_driver_alert")} ></DefaultPopover>
                                    
                                ) : (<></>)
                            }
                            {this.renderOrderIncidentAlert(order)}
                            
                            
                            <span onClick={() => this.collapsesToggle(collapseCode)}>#{order.number}</span>
                        </h5>
                    </Col>
                    <Col xs="2" style={headerStyle} onClick={() => this.collapsesToggle(collapseCode)}>
                        {this.renderOrderDates(moment(order.start_date.date).format(format), moment(order.end_date.date).format(format), order.nb_days_used)}
                    </Col>
                    <Col xs="2" style={headerStyle} >
                        {
                            (order.truck) ?
                            (
                                <TruckSummary completeSummaryOnClick={true} properties={false} label={true} image={true} truck={order.truck}></TruckSummary>
                            ) : (<></>)
                        }
                    </Col>
                    <Col xs="2" style={headerStyle}  onClick={() => this.collapsesToggle(collapseCode)}>
                        {this.renderOrderState(order)}
                    </Col>
                    <Col xs="1" style={headerStyle}  onClick={() => this.collapsesToggle(collapseCode)}>
                        {this.renderOrderRating(order)}
                    </Col>
                    <Col xs="1" style={headerStyle} >
                        <span id={"amount" + orderIndex}>{StringHelper.formatAmount(order.ht_total_amount)}</span>
                        {
                            (StringHelper.notEmptyValue(order.transporter_invoice_comment)) ?
                            (
                                <DefaultPopover 
                                    placement="right"
                                    component={<DefaultImage id={"order_amount"+ orderIndex} src={Resources.comment_logo} style={{color:"red"}}></DefaultImage>}
                                    targetId={"order_amount"+ orderIndex} content={order.transporter_invoice_comment} >
                                </DefaultPopover>
                            ) : (null)
                        }
                    </Col>
                    <Col xs="2" style={headerStyle}>
                        {
                            (order.transporter_payment_state == null) ?
                            (
                                "/"
                            ) : (
                                this.renderPaymentState(order)
                            )
                        }
                    </Col>
                </Row>
                
            </CardHeader>
            <Collapse role="tabpanel" isOpen={this.expandedIsOpen(collapseCode, order.id)}>
            <CardBody>
                {this.renderOrderArrivalTime(order)}
                {this.renderMissions(order)}
                {
                    (order.state.code == "ORDER_STATE_WAITING_TRANSPORTER_VALIDATION") ?
                    (
                        <>
                           {this.renderWaitingForValidation(order)}
                        </>
                    ) :
                    (<></>)
                }
                        
            </CardBody>
            </Collapse>
        </Card>
    }
    
    render()
    {
        // Si pas de véhicule / conducteur / liaison conducteur / véhicule
        
        if (this.state.truckDriverAlert)
        {
            return <NoTruckDriverAlert context={"orders"}></NoTruckDriverAlert>
        } 
        else // Sinon affichage des commandes
        {
            return <>
                {this.renderParent()}
                {this.renderOrders()}
            </>
        }
    }
}


export default injectIntl(TransporterOrders)