import React from "react";

// reactstrap components
import { NavItem, NavLink } from "reactstrap";

class Links extends React.Component {

    render() {
        return (
        <></>
        )
    }
}

export default Links;