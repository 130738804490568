
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
  } from "reactstrap";

import queryString from 'query-string';
import { injectIntl, FormattedMessage } from 'react-intl';
import SecurityService from '../../../proxies/SecurityService'
import DataList from "../components/DataList";
import CollaboratorCard from '../components/card/CollaboratorCard';
import Resources from "../../../resources";
import StringHelper from "../../../helpers/StringHelper";
import FormValidationComponent from "../components/FormValidationComponent";

class Collaborators extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.securityService = new SecurityService();
        this.state = {
            user : this.securityService.getUser()
        }
    }
 
    componentDidMount()
    {
        const parsedQueryString = queryString.parse(window.location.search);
        if (parsedQueryString.mode && parsedQueryString.mode == "success")
        {
            this.showSuccessToastNotification(
                StringHelper.translate(this, "collaborator.save_confirmation_title"),    
                StringHelper.translate(this, "collaborator.save_confirmation_message")
            )
        }
    }

    render() {
        return (
            <>
            {this.renderParent()}
            <Container className="mt--4" fluid>
            <Row>
                <Col xl="12">
                <DataList
                    context="collaborators"
                    title={StringHelper.translate(this, "Default.menu_collaborators")}
                    titleIcon={Resources.drivers_logo}
                    allowAddButton={true}
                    addButtonTitle="Ajouter un collaborateur"
                    addButtonUrl="/admin/new-collaborator"
                    allowRemovedButton={true}
                    removedButtonTitle='Voir les collaborateurs supprimés'
                    notRemovedButtonTitle='Voir les collaborateurs non supprimés'
                    apiUrl={this.state.user.is_shipper ? "/api/shipper/collaborators" : "/api/transporter/collaborators"}
                    apiRemovedUrl={this.state.user.is_shipper ? "/api/shipper/collaborators/deleted" : "/api/transporter/collaborators/deleted"}
                    apiResponseProperty={"collaborators"}
                    cardTemplate={CollaboratorCard}
                />
                </Col>
            </Row>
              
            </Container>
            </>
        )
    }
}


export default injectIntl(Collaborators)