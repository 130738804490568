import "../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components
 import {
   Form
 } from "reactstrap";
import ShipperService from "../../../proxies/ShipperService"
import { injectIntl , FormattedMessage } from 'react-intl';

import { Multiselect } from 'multiselect-react-dropdown';
import DefaultInput from "../common/defaultInput";
import StringHelper from "../../../helpers/StringHelper";
import TagsInput from "react-tagsinput";
import Message from "../common/message";

class ShipperCustomerContactInput extends DefaultInput     {

    constructor(props)
    {
        super(props, true, function(element){
            return element.text;
        });
        this.multiselectRef = React.createRef();
        this.shipperService = new ShipperService();

        this.state = {
            loading: true,
            datas: [],
            selectedValues: []
        }
    }

    componentWillMount()
    {
        super.componentWillMount();
        this.dataBind();
    }

    componentDidMount()
    {
        super.componentDidMount();
    }

    hasValue()
    {
        return this.getValues() && this.getValues().length > 0;
    }

    dataBind(callback = null)
    {
        this.shipperService.getCustomerContacts(this, this.props.customer.id, (response) => {
            var datas = []

            if (this.props.emptyElement && this.props.emptyElement == true)
            {
                datas.push({id : "", name: typeof this.props.placeholder !== "undefined" ? this.props.placeholder : "..."})
            }
            
            response.list.forEach(element => {
                datas.push({id : element.id, name: element.summary});
            });

            this.setState({datas : datas});
            
            if (callback)
            {
                callback(this)
            }

            if (this.props.onDataBinded)
            {
                this.props.onDataBinded(datas.length)
            }
        })
    }
    setValues(values, refresh = false)
    {
        this.setState({selectedValues: values})
    }

    clear()
    {
        this.multiselectRef.current.resetSelectedValues();
        this.setState({datas: [], selectedValues: []})
    }

    getValues()
    {
        return this.state.selectedValues;
    }

    onSelect(selectedList, selectedItem) {
        this.setValues(selectedList)
    }
     
    onRemove(selectedList, removedItem) {
        this.setValues(selectedList)
    }

    renderMultiSelect()
    {
        return <div>
            <Multiselect
            id="shipper-customer-contacts-input"
            className={"t2y-default-input-control"}
            ref={this.multiselectRef}
            options={this.state.datas} // Options to display in the dropdown
            selectedValues={this.getValues()}
            onSelect={this.onSelect.bind(this)} // Function will trigger on select event
            onRemove={this.onRemove.bind(this)} 
            displayValue="name" // Property name to display in the dropdown options
            placeholder={StringHelper.translate(this, "collaborator.multiselect_placeholder")}
            emptyRecordMsg={StringHelper.translate(this, "collaborator.multiselect_empty_result")}
            hidePlaceholder={true}
            style={{
                chips: { background: "var(--jaune)", color: "var(--gris-fonce)", fontSize:"16px"},
                option: {
					color: "black",
					background: "white",
					fontWeight: "normal",
					fontSize: "15px",
				}
            }}
        />
        </div>
    }

    render()
    {
        if (this.state.loading || this.state.datas.length > 0)
        {
            return this.rendeInner(this.renderMultiSelect())
        }
        else
        {
            if (this.state.datas.length == 0)
            {
                return <Message type="warning">
                    Aucun contact référencé pour cette adresse
                </Message>
            }
        }
        
    }
  }
 
  export default injectIntl(ShipperCustomerContactInput);