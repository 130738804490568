
import React from "react";
import classnames from "classnames";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
import FormInput from '../components/FormInput'
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import BusinessService from "../../../proxies/BusinessService"
import SecurityService from "../../../proxies/SecurityService"
import { injectIntl , FormattedMessage } from 'react-intl';

// reactstrap components
import {
    Button,
    Container,
    Form,
    FormGroup,
    UncontrolledPopover,
    UncontrolledTooltip,
    PopoverBody,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    InputGroup,
    InputGroupAddon
  } from "reactstrap";
import Resources from "../../../resources";
import FormAddressesInput from "../components/form/FormAddressesInput";
import FormUploadImage from "../components/FormUploadImage";
import FormCivilityInput from "../components/form/FormCivilityInput";
import FormHeader from "../components/form/FormHeader";
import FormGroupInput from "../components/form/FormGroupInput";
import DefaultInput from "../../components/common/defaultInput";
import CivilityInput from "../../components/common/civilityInput";
import OperationalAddressesInput from "../../components/business/operationalAddressesInput";
import DefaultCheckbox from "../../components/common/defaultCheckbox";
import DefaultPopover from "../../components/common/defaultPopover";
import StringHelper from "../../../helpers/StringHelper.js";
import WebsiteHelper from "../../../helpers/WebsiteHelper.js";
import FormFooter from "../../components/form/formFooter";
import Codes from "../../../codes.js";
import Ribbon from "../../components/common/ribbon.jsx";
import DefaultImage from "../../components/common/defaultImage.jsx";
import DefaultLink from "../../components/common/defaultLink.jsx";
import ChangeEmailModal from "../components/modal/ChangeEmailModal.jsx";
import Message from "../../components/common/message.jsx";

class Collaborator extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.businessService = new BusinessService();
        this.securityService = new SecurityService();

        this.createValidationRules();
        this.createDefaultState();
    }

    createDefaultState()
    {
        this.state = {
            changeEmailModal: false,
            loading : false,
            instance : null,
            companyAccountManagementIsChecked:  false,
            formDatas : {
                company_account_management: { value : false },
                civility_code: {value: null},
                image_url: { value : null }
            }
        }
    }

    createValidationRules()
    {
        this.validationRules = {
            civility_code : { required: true, },
            first_name : { required: true, },
            last_name : { required: true, },
            email : { required: true, rules : [{
                rule : this.email.bind(this)
            }]},
            mobile_phone_number: { 
                rules : [{
                    rule : this.mobilePhoneNumber.bind(this)
                }]
            },
            home_phone_number: { 
                rules : [{
                    rule : this.homePhoneNumber.bind(this)
                }]
            }
        }
    }

    componentWillMount()
    {
        // Récupération des données du collaborateur
        if (!this.props.match.params.id == false)
        {
            this.businessService.getCollaborator(this, this.props.match.params.id, this.bindData.bind(this));
        }
    }

    componentDidMount()
    {
        this.emailInputRef.setValue(this.collaborator ? this.collaborator.email : null)
    }

    bindData(response)
    {
        var collaborator = response.user;
        
        let addresses = []
        response.addresses.forEach(adr => {
            addresses.push({id: adr.id, name: adr.name})
        })

        this.civilityInputRef.setValue(collaborator.civility ? collaborator.civility.code : null)
        this.firstNameInputRef.setValue(collaborator.first_name)
        this.lastNameInputRef.setValue(collaborator.last_name)
        this.emailInputRef.setValue(collaborator.email)
        this.mobilePhoneNumberInputRef.setValue(collaborator.mobile_phone_number)
        this.homePhoneNumberInputRef.setValue(collaborator.home_phone_number)
        this.companyAccountCkbRef.setValue(!collaborator.is_company_account_manager ? false : collaborator.is_company_account_manager)
        this.addressesInputRef.setValues(addresses)
        
        var formDatas = {
            addresses : { value : addresses},
            image_url : { value : collaborator.image_url },
            email : { value : collaborator.email}
        }

        this.collaborator = collaborator

        this.setState({
            instance: collaborator,
            id: collaborator.id, 
            stateLabel: collaborator.state_label,
            formDatas: formDatas, 
            loading: false, 
            companyAccountManagementIsChecked: this.companyAccountCkbRef.getValue()})
    }

    companyAccountManagementChanged()
    {
        this.setState({
            companyAccountManagementIsChecked:  !this.state.companyAccountManagementIsChecked
        })
    }
 
    onSubmitForm(e)
    {
        e.preventDefault();
        var formIsValid = this.htmlFormIsValid(e);
        if (formIsValid)
        {
            var postedData = {
                id : this.getHtmlFormControlValue("id"),
                civility_code : this.getHtmlFormControlValue("civility_code"),
                first_name : this.getHtmlFormControlValue("first_name"),
                last_name : this.getHtmlFormControlValue("last_name"),
                email: this.collaborator ? this.collaborator.email : this.getHtmlFormControlValue("email"),
                mobile_phone_number : this.cleanPhoneNumber(this.getHtmlFormControlValue("mobile_phone_number")),
                home_phone_number : this.cleanPhoneNumber(this.getHtmlFormControlValue("home_phone_number")),
                company_account_management: this.companyAccountCkbRef.getValue(),
                image_url: this.getHtmlFormControlValue("image_url"),
                addresses : this.addressesInputRef.getValues().map(adr => { return adr.id }),
            }

            this.businessService.saveCollaborator(this, postedData, (response) => {
                this.setState({
                    id : response.instance.id,
                    instance : response.instance
                })

                WebsiteHelper.goTo(this, "/admin/collaborators?mode=success")
                //this.showSuccessModalNotification("Confirmation", "Les données du collaborateur ont été enregistrées avec succès")
            });
        }
    }

    onDeleteAction(event)
    {
        this.businessService.deleteCollaborator(this, this.state.id, (response) => {
            this.updateState(response)

            this.showSuccessModalNotification("Confirmation", "Les collaborateur a été supprimé avec succès")
        });
    }

    onActiveAction(event)
    {
        this.businessService.activeCollaborator(this, this.state.id, (response) => {
            this.updateState(response)
            this.showSuccessModalNotification("Confirmation", "Les collaborateur a été ré-activé avec succès")
        });
    }

    onChangePasswordAction(event)
    {
        this.securityService.forgetPasswordWithEmail(this, this.getHtmlFormControlValue("email"), (response) => {
            this.updateState(response)
            this.showSuccessModalNotification("Confirmation", "La procédure de modification de mot de passe a été envoyée par mail")
        });
    }

    onEmailValidationAction(event)
    {
        this.securityService.resendEmailValidationEmail(this, this.state.id, (response) => {
            this.updateState(response)
            this.showSuccessModalNotification("Confirmation", "La notification de création de compte a été envoyée")
        });
    }

    onRegisterValidationAction(event)
    {
        this.securityService.resendCollaboratorRegisterEmail(this, this.state.id, (response) => {
            this.updateState(response)
            this.showSuccessModalNotification("Confirmation", "La notification de création de compte a été envoyée")
        });
    }

    toggleEmailModal()
    {
        const currentValue = this.state.changeEmailModal
        this.setState({changeEmailModal: !currentValue});
    };
    
    
    renderChangeEmailPopup()
    {
      return <ChangeEmailModal userId={this.collaborator ? this.collaborator.id : null} manager={this}  
        stateKey='changeEmailModal'   
        onEmailChanged={(email) => {
            this.collaborator.new_username = email;
            this.setState({change: StringHelper.createDomElementId()})
        }}
      />
    }

    render() {
        return  (
            <>
            {this.renderParent()}
            {this.renderChangeEmailPopup()}
            
            <Container className="" fluid>
                <Form className="needs-validation" noValidate onSubmit={e => this.onSubmitForm(e)} >
                    <Input type="hidden" id="id" name="id" value={this.state.id} />
                    <Row>
                        <Col className="order-xl-1" xl="12">
                        <FormLoadingIndicator loading={this.state.loading}>
                        <Card>
                            <CardHeader>
                                <FormHeader 
                                    instance={this.state.instance} 
                                    breadCrumb={true}
                                    breadCrumbStyle={null}
                                    breadCrumbTitle1={"Default.breadcrumb_collaborators_title"}
                                    breadCrumbTitle1Click={() => WebsiteHelper.goTo(this, "/admin/collaborators")}
                                    breadCrumbTitle2={this.state.instance ? this.state.instance.summary : ""}
                                    allowDeleteButton={false}
                                    allowActiveButton={true} 
                                    disableSubmitButton={true} 
                                    onActiveAction={this.onActiveAction.bind(this)}
                                    customActionButtons={[
                                        {
                                            generate: true,
                                            targetId: "btnRegisterValidation", 
                                            buttonIcon : "fas fa-user-circle",
                                            buttonText : "Mail de création de compte",
                                            buttonColor: "t2y-primary-button-color",
                                            modalTitle : "Confirmation", 
                                            modalMessage : "Souhaitez vous envoyer à nouveau la notification création de compte?", 
                                            modalKoButtonText: StringHelper.translate(this, "Default.cancel"),
                                            onAction: this.onRegisterValidationAction.bind(this)
                                        },
                                        /*{
                                            generate: true,
                                            targetId: "btnEmailValidation", 
                                            buttonIcon : "fas fa-at",
                                            buttonText : "Changement d'email",
                                            buttonColor: "t2y-primary-button-color",
                                            modalTitle : "Confirmation", 
                                            modalMessage : "Souhaitez vous envoyer à nouveau la notification de valiation d'email?", 
                                            modalKoButtonText: StringHelper.translate(this, "Default.cancel"),
                                            
                                            onAction: this.onEmailValidationAction.bind(this)
                                        },*/
                                        { 
                                            generate: true,
                                            targetId: "btnChangePassword", 
                                            buttonIcon: 'fas fa-key',
                                            buttonText: 'Changement de mot de passe',
                                            buttonColor: "t2y-primary-button-color",
                                            modalTitle : "Confirmation", 
                                            modalMessage : "Souhaitez vous que l'utilisateur change son mot de passe?", 
                                            modalKoButtonText: StringHelper.translate(this, "Default.cancel"),
                                            
                                            onAction: this.onChangePasswordAction.bind(this)
                                        },
                                        {
                                            generate: true,
                                            targetId: "btnDeleteCollaborator",
                                            buttonIcon: Resources.button_delete_icon,
                                            buttonText: StringHelper.translate(this, "Default.delete"), 
                                            buttonColor: "warning",
                                            modalTitle : StringHelper.translate(this, "collaborator.delete_confirmation_title"), 
                                            modalMessage : StringHelper.translate(this, "collaborator.delete_confirmation_message"), 
                                            modalKoButtonText: StringHelper.translate(this, "Default.cancel"),
                                            modalOkButtonText: StringHelper.translate(this, "Default.delete"), 
                                            
                                            onAction: this.onDeleteAction.bind(this)
                                        }
                                    ]}
                                />

                                
                            </CardHeader>
                            <CardBody>
                                {
                                    (this.state.id && this.state.instance.state.code == Codes.WAITING_FOR_COLLABORATOR_VALIDATION) ?
                                    (
                                        <Ribbon type="warning" position="top-left" longText={false}>
                                            en attente
                                        </Ribbon>
                                    ) : (<></>)
                                }
                                <Row>
                                <Col lg="7">
                                        <div className="pl-lg-4">
                                            <h6 className="heading-small text-muted mb-4">
                                                Identité
                                            </h6>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroupInput 
                                                        intlId={"user.civility"} 
                                                        htmlFor={"civility_code"}
                                                        type="multiline">
                                                        <CivilityInput childRef={elt => this.civilityInputRef = elt} manager={this} required={true} id="civility_code"></CivilityInput>
                                                    </FormGroupInput>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroupInput intlId={"user.first_name"} htmlFor="first_name" type="multiline">
                                                        <DefaultInput required={true} childRef={(elt) => this.firstNameInputRef = elt}  name='first_name' manager={this} />
                                                    </FormGroupInput>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroupInput intlId={"user.last_name"} htmlFor="last_name" type="multiline">
                                                        <DefaultInput required={true} childRef={(elt) => this.lastNameInputRef = elt}  name='last_name' manager={this} />
                                                    </FormGroupInput>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroupInput intlId={"user.mobile_phone_number"} htmlFor="mobile_phone_number" type="multiline">  
                                                        <DefaultInput childRef={(elt) => this.mobilePhoneNumberInputRef = elt}  name='mobile_phone_number' manager={this} />
                                                    </FormGroupInput>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroupInput intlId={"user.home_phone_number"} htmlFor="home_phone_number" type="multiline">  
                                                        <DefaultInput childRef={(elt) => this.homePhoneNumberInputRef = elt}  name='home_phone_number' manager={this} />
                                                    </FormGroupInput>
                                                </Col>
                                                <Col lg="12">
                                                    {
                                                        (!this.state.instance) ?
                                                        (
                                                            <FormGroupInput intlId={"user.email"} htmlFor="email" type="multiline"
                                                                tooltip={{message: this.translateMessage("collaborator.email_infos")}}
                                                            >
                                                                <DefaultInput childRef={(elt) => this.emailInputRef = elt}  required={true} name='email' manager={this} />
                                                            </FormGroupInput>
                                                        ) : 
                                                        (
                                                            <>
                                                                <FormGroupInput intlId={"user.email"} htmlFor="email" type="multiline">
                                                                    <InputGroup>
                                                                        <DefaultInput readOnly={true} childRef={elt => this.emailInputRef = elt} value={this.collaborator.email } />
                                                                        <InputGroupAddon addonType="append">
                                                                        &nbsp;  
                                                                        <DefaultLink small={true} onClick={this.toggleEmailModal.bind(this)} style={{marginTop:"10px"}}>
                                                                            <DefaultImage src="ni ni-email-83"></DefaultImage>
                                                                            <span className="btn-inner--text">&nbsp;
                                                                            <FormattedMessage id="user.update_email" />
                                                                            </span>
                                                                        </DefaultLink>
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                    
                                                                </FormGroupInput>
                                                                {
                                                                    (this.collaborator && this.collaborator.new_username) ?
                                                                    (
                                                                        <Message type="infos">
                                                                            Le nouvel email {this.collaborator.new_username} est en attente de validation
                                                                        </Message>
                                                                    ) : (<></>)
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                
                                <Col lg="5">
                                    <div className="pl-lg-4">
                                        <h6 className="heading-small text-muted mb-4">
                                            Role
                                        </h6>
                                        <Row>
                                            <Col lg="12">
                                                <DefaultCheckbox id="operational_address"
                                                    childRef={(elt) => this.companyAccountCkbRef = elt} 
                                                    defaultValue={this.state.companyAccountManagementIsChecked}
                                                    content={this.props.intl.formatMessage({id: "collaborator.company_account_management"})}
                                                    popover={
                                                        <DefaultPopover content={StringHelper.translate(this, "collaborator.company_account_management_infos")} />
                                                    }
                                                />
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="street2">
                                                        Responsable des sites
                                                    </label>
                                                    <OperationalAddressesInput multiple={true} value={this.getFormControlValue("addresses")} childRef={(elt) => this.addressesInputRef = elt}  manager={this} name="addresses"></OperationalAddressesInput>

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <h6 className="heading-small text-muted mb-4">
                                            Photo
                                        </h6>
                                        <Row>
                                            <Col lg="12">
                                                <FormUploadImage mode="inline"  oldImageUrl={this.state.formDatas.image_url.value} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                </Row> 
                            </CardBody>
                            <CardFooter>
                                <FormFooter instance={this.state.instance}></FormFooter>
                            </CardFooter>
                        </Card>
                        </FormLoadingIndicator>
                        </Col>

                        </Row>
                    </Form>
                


                
                </Container>
          </>
        );
    }
}


export default injectIntl(Collaborator)