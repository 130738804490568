import ProxyService from './ProxyService'

class RepositoryService extends ProxyService {
    
    getDomains(caller, okCallback)
    {
        this.get(null, '/truck/domain', okCallback);
    }

    getCategoriesByDomainId(caller, domainId, okCallback)
    {
        this.get(null, '/truck/category/' + domainId, okCallback)
    }

    getCivilities(caller, okCallback)
    {
        this.get(null, "/repositories/CIVILITY", (response) => {
            okCallback(response);
        })
    }

    getRepositoriesWithParents(caller, familyCode, okCallback)
    {
        this.get(null, "/repositories/" + familyCode + "?parent=1", (response) => {
            okCallback(response);
        })
    }

    getRepositories(caller, familyCode, okCallback)
    {
        this.get(null, "/repositories/" + familyCode, (response) => {
            okCallback(response);
        })
    }

    getRepositoriesByParentsCodes(caller, codes, okCallback)
    {
        this.get(null, "/sub-repositories/" + JSON.stringify(codes), (response) => {
            okCallback(response);
        })
    }

    getRepositoriesByParent(caller, familyCode, parentCode, okCallback)
    {
        this.get(null, "/repositories/" + familyCode + "/" + parentCode, (response) => {
            okCallback(response);
        })
    }

    getRepositoriesByGroupingCode(caller, groupingCode, okCallback)
    {
        this.get(null, "/repositories/group/" + groupingCode, (response) => {
            okCallback(response);
        })
    }

    getCountries(caller, okCallback)
    {
        this.get(null, "/repositories/COUNTRY", (response) => {
            okCallback(response);
        })
    }

    getCollaborators(caller, okCallback, errorCallback = null)
    {
        const user = this.getUser();
        const apiUrl = user.is_shipper ?  "/api/shipper/collaborators" : "/api/transporter/collaborators";

        this.get(null, apiUrl, (response) => {
            okCallback(response);
        }, errorCallback)
    }

    getAddresses(caller, okCallback, errorCallback = null)
    {
        const user = this.getUser();
        const apiUrl = user.is_shipper ?  "/api/shipper/addresses" : "/api/transporter/addresses";

        this.get(null, apiUrl, (response) => {
            okCallback(response);
        }, errorCallback)
    }

    getTrucks(caller, okCallback, errorCallback = null)
    {
        const apiUrl = "/api/transporter/trucks";
        this.get(null, apiUrl, (response) => {
            okCallback(response);
        }, errorCallback)
    }

    getDrivers(caller, okCallback, errorCallback = null)
    {
        const apiUrl = "/api/transporter/drivers";
        this.get(null, apiUrl, (response) => {
            okCallback(response);
        }, errorCallback)
    }

    getDriversWithTruck(caller, truckId, okCallback, errorCallback = null)
    {
        if (caller)
        {
            caller.setState({loading: true})
        }

        const apiUrl = "/api/transporter/drivers?truck_id=" + truckId;
        this.get(caller, apiUrl, (response) => {
            okCallback(response);
        }, errorCallback)
    }


}


export default RepositoryService;