import React, { useState } from "react"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"

const CustomModal= ({ children, open, setOpen, showCloseIcon = true, styles, disabledCss = false}) => {
	let contentGlobalCss = "d-flex align-items-center justify-content-center t2y-modal-content flex-column"
	if(disabledCss) {
		contentGlobalCss = ""
	}
  const closeModal = () => {
    setOpen(false);
  };

	const closeIcon = <span className="t2y-modal-close-icon" onClick={() => closeModal()} >x</span>
	return <>
	<Modal
		styles={styles}
		closeOnEsc={false}
		closeOnOverlayClick={false}
		showCloseIcon={showCloseIcon}
		open={open}
		center
		closeIcon={closeIcon}
		classNames={{
			overlay: "t2y-modal-overlay",
			modal: "t2y-modal",
		}}
	>
		<div className={contentGlobalCss}>
			{children}
		</div>
	</Modal>
	</>
}

export default CustomModal;