import "../../../../assets/vendor/select2/dist/css/select2.min.css";

import React, { Children } from "react";
import PropTypes from 'prop-types'
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    Row,
    Col,
    Button,
    Label,
    Input,
    Breadcrumb,
    BreadcrumbItem,
} from "reactstrap";
import { injectIntl , FormattedMessage } from 'react-intl';
import NotificationComponent from "../NotificationComponent";
import FormValidationComponent from "../FormValidationComponent";
import DefaultButton from "../../../components/common/defaultButton";
import StringHelper from "../../../../helpers/StringHelper";

class FormHeader extends FormValidationComponent {

    constructor(props)
    {
        super(props)
       
        this.state = {
            deleteModal: false,
            activeModal: false,
            customModal: false
        }

        if (this.props.customActionButtons != null)
        {
            this.props.customActionButtons.forEach(button => {
                this.state[button.targetId] = false
            })
        }
    }

    componentDidMount() {}

    getStateLabel()
    {
        if (this.props.instance && this.props.instance.state && this.props.instance.state.label)
        {
            return this.props.instance.state.label;
        }
        else
        {
            return this.props.instance && this.props.instance.state_label ? this.props.instance.state_label : ""; 
        }
    }

    getStateCode()
    {
        if (this.props.instance && this.props.instance.state && this.props.instance.state.code)
        {
            return this.props.instance.state.code
        }
        else
        {
            return this.props.instance && this.props.instance.state_code ? this.props.instance.state_code : ""; 
        }
    }

    getFormatedStateCode()
    {
        var stateCode = this.getStateCode();
        return stateCode !== "" ? "Default." + this.getStateCode() : "";
    }

    isRemoved()
    {
        return this.getStateCode() === "STATE_REMOVED";
    }

    showSubmitButton()
    {
        return typeof this.props.disableSubmitButton === "undefined" || this.props.disableSubmitButton == false;
    }
    
    getId()
    {
        return this.props.instance && this.props.instance.id ? this.props.instance.id : null
    }

    toggleDeleteModal(event)
    {
        this.setState({deleteModal: !this.state.deleteModal})
    };

    onDeleteAction(event)
    {
        this.setState({deleteModal: false});
        this.props.onDeleteAction(event)
    }

    renderDeleteModal()
    {
        return <Modal className="modal-dialog-centered" isOpen={this.state.deleteModal} toggle={() => this.toggleDeleteModal()}>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
          <FormattedMessage id="Default.delete_title" />
          </h6>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleDeleteModal()} >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            <FormattedMessage id="Default.delete_message" />
          </p>
        </div>
        <div className="modal-footer">
          <Button className="ml-auto" color="secondary" data-dismiss="modal" type="button" onClick={() => this.toggleDeleteModal()} >
            <FormattedMessage id="Default.no" />
          </Button>
          <Button color="danger" type="button" onClick={this.onDeleteAction.bind(this)}>
            <FormattedMessage id="Default.yes" />
          </Button>
        </div>
      </Modal>
    }

    toggleActiveModal(event)
    {
        this.setState({activeModal: !this.state.activeModal})
    };

    onActiveAction(event)
    {
        this.setState({activeModal: false});
        this.props.onActiveAction(event)
    }

    renderActiveModal()
    {
        return <Modal className="modal-dialog-centered" isOpen={this.state.activeModal} toggle={() => this.toggleActiveModal()}>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
          <FormattedMessage id="Default.active_title" />
          </h6>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleActiveModal()} >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            <FormattedMessage id="Default.active_message" />
          </p>
        </div>
        <div className="modal-footer">
          <Button className="ml-auto" color="secondary" data-dismiss="modal" type="button" onClick={() => this.toggleActiveModal()} >
            <FormattedMessage id="Default.no" />
          </Button>
          <Button color="warning" type="button" onClick={this.onActiveAction.bind(this)}>
            <FormattedMessage id="Default.yes" />
          </Button>
        </div>
      </Modal>
    }

    toggleCustomModal(event, targetId)
    {
        var currentValue = typeof this.state[targetId] === "undefined" || this.state[targetId] === null ? false : this.state[targetId];
        var newValue = {}
        newValue[targetId] = !currentValue;
        this.setState(newValue)
    };

    onCustomAction(event, targetId)
    {
        // On cache la popup
        this.toggleCustomModal(event, targetId);
        // On exécute l'action rattachée
        if (this.props.customActionButtons != null)
        {
            this.props.customActionButtons.forEach(button => {
                if (button.targetId == targetId && typeof button.onAction !== "undefined" && button.onAction !== null)
                {
                    button.onAction(event)
                }
            })
        }
    }

    renderCustomModal(buttonInfos)
    {
        var okButtonComponent = buttonInfos.modalOkButtonText ? buttonInfos.modalOkButtonText : <FormattedMessage id="Default.yes" />
        var koButtonComponent = buttonInfos.modalKoButtonText ? buttonInfos.modalKoButtonText : <FormattedMessage id="Default.no" />

        return <Modal key={buttonInfos.targetId} className="modal-dialog-centered" isOpen={this.state[buttonInfos.targetId]} toggle={(e) => this.toggleCustomModal(e, buttonInfos.targetId)}>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
              {buttonInfos.modalTitle}
          </h6>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={(e) => this.toggleCustomModal(e, buttonInfos.targetId)} >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
          {buttonInfos.modalMessage}
          </p>
          {
              (buttonInfos.modalSubMessage) ?
              (
                <i>
                {buttonInfos.modalSubMessage}
                </i>      
              ) : (<></>)
          }
        </div>
        <div className="modal-footer">
          <Button color={(buttonInfos.koButtonColor ? buttonInfos.koButtonColor : "secondary")}  className="ml-auto" data-dismiss="modal" type="button" onClick={(e) => this.toggleCustomModal(e, buttonInfos.targetId)} >
            {koButtonComponent}
          </Button>
          <Button color={(buttonInfos.buttonColor ? buttonInfos.buttonColor : "default")} type="button" onClick={(e) => this.onCustomAction(e, buttonInfos.targetId)}>
            {okButtonComponent}
          </Button>
        </div>
      </Modal>
    }

    renderCustomModals()
    {
        if (this.props.customActionButtons != null)
        {
            return this.props.customActionButtons.map(button => {
                return this.renderCustomModal(button)
            })
        }
        
    }

    renderDynamicActionButton(buttonInfos)
    {
        /*
        return <Button key={buttonInfos.targetId} type="button" color={buttonInfos.buttonColor} size="xs" id={buttonInfos.targetId} onClick={(e) => this.toggleCustomModal(e, buttonInfos.targetId)}>
            <span className="btn-inner--icon d-xl-none">
                <i className={buttonInfos.buttonIcon} />
            </span>
            
            <span className="btn-inner--text  d-none d-xl-block">&nbsp;
                <i className={buttonInfos.buttonIcon} />&nbsp;
                {buttonInfos.buttonText}
            </span>
        </Button>*/
        return <DropdownItem key={buttonInfos.targetId} href="#" onClick={e => this.toggleCustomModal(e, buttonInfos.targetId)}>
                <i className={buttonInfos.buttonIcon} />&nbsp;{buttonInfos.buttonText}
            </DropdownItem>
    }

    renderBreadCrumb()
    {
        if (!this.props.breadCrumb)
        {
            return <></>
        }
        else
        {
            return <Breadcrumb style={this.props.breadCrumbStyle}>
                <BreadcrumbItem>
                    <a href="#"  className="t2y-clickable" onClick={e => this.props.breadCrumbTitle1Click ? this.props.breadCrumbTitle1Click() : {}}>
                        {StringHelper.translateWithHtmlWithoutClass(this, this.props.breadCrumbTitle1)}
                    </a>
                </BreadcrumbItem>
                {
                    (this.props.breadCrumbTitle2) ?
                    (
                        <BreadcrumbItem aria-current="page" className="active">
                            {this.props.breadCrumbTitle2}
                        </BreadcrumbItem>
                    ) : (<></>)
                }
                
            </Breadcrumb>
        }
    }

    render() {
        // Affectation de l'evenement sur les boutons "custom"
        const childrenWithProps = React.Children.map(this.props.children, child => {
            if (this.isRemoved() == false)
            {
                return React.cloneElement(child, { onClick: (e) => this.toggleCustomModal(e, child.props.id) })
            }
        });

        var dynamicChildren = "";
        if (this.props.customActionButtons != null && this.props.instance && (this.props.instance.state == null || this.props.instance.state.code != "STATE_REMOVED"))
        {
            dynamicChildren = this.props.customActionButtons.map(button => {
                if (this.isRemoved() == false && button.generate)
                {
                    return this.renderDynamicActionButton(button)
                }
            })
        }

        return (
            <>
            {this.renderParent()}
            {this.isRemoved() == false && this.props.allowDeleteButton ? (this.renderDeleteModal()) : ("")}
            {this.isRemoved() == true  && this.props.allowActiveButton ? (this.renderActiveModal()) : ("")}

            {this.renderCustomModals()}
            <Row className="align-items-center">
                {
                    (this.props.breadCrumb) ?
                    (
                        <Col className="text-left" sm="10"   style={{height:10}}>
                            {this.renderBreadCrumb()}
                        </Col>
                    ) :
                    (
                        <Col xs="4">
                            <h3 className="mb-0">
                                {
                                    (this.getFormatedStateCode() !== "" && 1 == 2) ?
                                    (
                                        <FormattedMessage id={this.getFormatedStateCode()} />
                                    ): ("")
                                }
                                
                            </h3>
                        </Col>
                    )
                }
                
                <Col className="text-right" sm="2" >
                    { (this.getId()) ? (childrenWithProps) : ("") }
                    {
                        (this.isRemoved() === false && this.showSubmitButton()) ?
                        (
                            <>
                            <DefaultButton type="submit" 
                                className="t2y-secondary-button small" 
                                icon="ni ni-check-bold"><FormattedMessage id="Default.save" />
                            </DefaultButton>
                            </>
                        ) : ("")
                    }
                    {
                        (this.getId() && this.props.allowDeleteButton && this.isRemoved() === false) ?
                        (
                            <Button type="button"
                                color="danger"
                                size="xs"
                                onClick={(e) => { this.toggleDeleteModal(e)}}
                            >
                                <span className="btn-inner--icon d-xl-none">
                                    <i className="fas fa-trash-alt" />
                                </span>
                                
                                <span className="btn-inner--text d-none d-xl-block">&nbsp;
                                    <i className="fas fa-trash-alt" />&nbsp;
                                    <FormattedMessage id="Default.delete" />
                                </span>
                            </Button>
                        ) : ("")
                    }
                    {
                        (this.getId() && this.props.allowActiveButton && this.isRemoved()) ?
                        (
                            <Button type="button" color="warning" size="xs" onClick={(e) => { this.toggleActiveModal(e)}} >
                                <span className="btn-inner--icon d-xl-none">
                                    <i className="far fa-check-square" />
                                </span>
                                
                                <span className="btn-inner--text d-none d-xl-block">&nbsp;
                                    <i className="far fa-check-square" />&nbsp;
                                    <FormattedMessage id="Default.active" />
                                </span>
                            </Button>
                        ) : ("")
                    }
                    {
                        (this.getId() && dynamicChildren !== "") ?
                        (
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    size="sm"
                                    color="neutral"
                                    className="mr-0 t2y-dropdown-button"
                                >
                                    <i className="fas fa-cogs" />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {dynamicChildren}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )
                        :
                        (
                            ""
                        )
                    }
                    
                </Col>
                
            </Row>
            </>
        )
        this.renderCustomModals()
    }
  }
 
  FormHeader.propTypes = {
    instance: PropTypes.any,
    breadCrumb: PropTypes.any,
    disableSubmitButton: PropTypes.bool,
    allowActiveButton: PropTypes.bool,
    allowDeleteButton: PropTypes.bool,
    onDeleteAction: PropTypes.func,
    onActiveAction: PropTypes.func,
    customActionButtons: PropTypes.array
  }

  export default injectIntl(FormHeader);

