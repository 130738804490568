import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import Title2 from '../../common/title2'
import Title3 from '../../common/title3'
import Title4 from '../../common/title4'
import DefaultLabel from '../../common/defaultLabel'
import MapPointComponent from "../../../pages/components/map/MapPointComponent";
import DefaultButton from "../../common/defaultButton";
import AddressWorkflow from "./addressWorkflow";
import ReactSlidingPane from "react-sliding-pane";
import FormLoadingIndicator from "../../../pages/components/FormLoadingIndicator";
import StringHelper from "../../../../helpers/StringHelper";
import NotificationComponent from "../../../pages/components/NotificationComponent";
import GeocodingAndNavigationService from "../../../../proxies/GeocodingAndNavigationService";


var moment = require('moment');
require('moment/locale/fr');

class AddressComponent extends NotificationComponent {

    constructor(props) {
        super(props)
        this.geocodingAndNavigationService = new GeocodingAndNavigationService();

        this.state = {
            addressModalVisible: false
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }


    

    renderMap()
    {
        return <>
            <MapPointComponent 
                point={{latitude: this.props.address.gps_lat, longitude: this.props.address.gps_long}} 
                childRef={(elt) => this.mapComponent = elt} ref={this.mapComponent} 
                width="100%" height="300px">
            </MapPointComponent>
        </>
    }

    renderAddress()
    {
        return <>
            <Row className="justify-content-center text-center">
                {
                    (this.props.address && this.props.address.customer && this.props.address.customer)?
                    (
                        <Col xl="12">
                            <Title2 center={true} 
                                content={this.props.address.customer.summary ? this.props.address.customer.summary : this.props.address.customer.label}>
                            </Title2>
                        </Col>
                    ) : (<></>)
                }
                {
                    (this.props.address.id) ?
                    (
                        <Col xl="12"><Title4 center={true} content={this.props.address.name}></Title4></Col>
                    ) : (<></>)
                }
                <Col xl="12"><DefaultLabel content={this.props.address.street}></DefaultLabel></Col>
                {
                    (this.props.address.street2) ?
                    (
                        <Col xl="12"><DefaultLabel content={this.props.address.street2}></DefaultLabel></Col>
                    ) : (<></>)
                }
                <Col xl="12"><DefaultLabel content={this.props.address.zip_code + " " + this.props.address.city} ></DefaultLabel></Col>

                {
                    (this.props.address.customer_company_address != null && this.props.address.customer_company_address === false && this.props.address.customer != null) ?
                    (
                        <>
                        {
                            (this.props.address.customer.mobile_phone_number) ?
                            (
                                <Col xl="12"><DefaultLabel content={this.props.address.customer.mobile_phone_number}></DefaultLabel></Col>
                            ) : (<></>)
                        }
                        {
                            (this.props.address.customer.email) ?
                            (
                                <Col xl="12"><DefaultLabel content={this.props.address.customer.email}></DefaultLabel></Col>
                            ) : (<></>)
                        }
                        </>
                    ) : (<></>)
                }
            </Row>
            {
                (this.props.editable) ?
                (
                    <Row className="align-item-center justify-content-center mb-3">
                        <DefaultButton onClick={(e) => this.props.manager.setState({addressModalVisible: true})} className="t2y-secondary-button small">Modifier l'adresse</DefaultButton>
                    </Row>
                ) : (<></>)
            }
            {this.props.addressFooterComponent}
        </>
    }

    renderAlert()
    {
        return  <Alert color="none" className="border border-warning">
            <Row>
                <Col xs="2">
                    <i className="text-warning fas fa-info-circle fa-lg" />
                </Col>
                <Col xs="10" className="text-warning">
                    <p><strong>L'adresse n'est pas assez précise pour permettre un géocodage correct.</strong></p>
                    <p>Pour remédier à ce problème, merci de modifier cette adresse. </p>
                </Col>
            </Row>
        </Alert>
    }

    render() {
        if (this.props.address == null)
        {
            return <>
            </>
        }

        return (
            <>
            <Container>
                {
                    (this.props.alert && !this.geocodingAndNavigationService.levelIsValid(this.props.address.gps_level)) ?
                    (
                        this.renderAlert()
                    ) : (<></>)
                }
                <Row>
                    <Col md={this.props.mode == "inline" ? "6" : "12"}>{this.props.position == "address_map" ? this.renderAddress() : this.renderMap()}</Col>
                    <Col md={this.props.mode == "inline" ? "6" : "12"}>{this.props.position == "address_map" ? this.renderMap(): this.renderAddress()}</Col>
                </Row>
            </Container>
            </>
        );
    }
}

AddressComponent.defaultProps = {
    mode:"inline",
    position:"address_map",
    editable: false,
    alert: false
};

AddressComponent.propTypes = {
    mode: PropTypes.oneOf(["inline", "multiline"]),
    position: PropTypes.oneOf(["map_address", "address_map"]),
    editable: PropTypes.bool,
    alert: PropTypes.bool,
    address: PropTypes.shape({
        gps_lat: PropTypes.string,
        gps_lat: PropTypes.string,
        name:PropTypes.string,
        street:PropTypes.string,
        street2:PropTypes.string,
        zip_code:PropTypes.string,
        city:PropTypes.string,
        customer: PropTypes.shape
    })
};


export default injectIntl(AddressComponent)

