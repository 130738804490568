import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Row,
    Col,
} from "reactstrap";

import DefaultInput from "../../common/defaultInput";
import Message from '../../common/message';
import FormGroupInput from '../../../pages/components/form/FormGroupInput'

import { injectIntl } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import TransporterService from "../../../../proxies/TransporterService";
import Step from "../step";
import StringHelper from "../../../../helpers/StringHelper";
import FormLegend from "../../form/formLegend";
import CivilityInput from '../../common/civilityInput'


class DriverIdentityStep extends Step {

    constructor(props) {
        super(props)
        
        this.transporterService = new TransporterService();

        this.validationRules = {
            civility_code : { required: true, },
            first_name : { required: true, },
            last_name : { required: true, },
            mobile_phone_number: {
                required : true, 
                rules : [{
                    rule : this.mobilePhoneNumber.bind(this)
                }]
            },
            email : { 
                required : false,
                rules : [{
                    rule : this.email.bind(this),
                    ruleMessage: this.translateMessage("user.email_invalid_message")
                }]
            }
        }

        this.state = {
        }
    }

    componentWillMount() {
        super.componentWillMount()
    }

    componentDidMount() {
        super.componentDidMount()
    }

    componentWillUnmount() {
        super.componentWillUnmount()
    }


    submit(pid)
    {
        var formIsValid = this.htmlFormIsValid(null);

        // Récupération des données saisies
        var driverInfos = {
            civility_code : this.getHtmlFormControlValue("civility_code"),
            first_name : this.getHtmlFormControlValue("first_name"),
            last_name : this.getHtmlFormControlValue("last_name"),
            email: this.getHtmlFormControlValue("email"),
            mobile_phone_number : this.cleanPhoneNumber(this.getHtmlFormControlValue("mobile_phone_number"))
        }
        
        if (formIsValid)
        {
            this.transporterService.createDriver(this, driverInfos, (response) => {
                driverInfos.id = response.id
                driverInfos.summary = response.instance.summary
                if (this.props.onDriverSubmited)
                {
                    this.props.onDriverSubmited(driverInfos);
                }
            })
        }
        else
        {
            this.scrollToFirstError("#form-container", 0)
        }

        /*

        if (StringHelper.notEmptyValue(reference) || StringHelper.notEmptyValue(registration))
        {
            // Enregistrement des données du camion
            var truckInfos = this.props.workflow.getTruck();
            truckInfos.reference = reference;
            truckInfos.registration = registration;
            truckInfos.description = description;            

            this.transporterService.saveTruckReference(this, truckInfos, (response) => {
                // On remonte l'information au workflow
                super.submit(pid)

                // On stock l'id du camion
                truckInfos.id = response.id
                truckInfos.complete = response.complete;
                truckInfos.complete_response = response.complete_response; 

                // On passe à l'étape suivante et on met à jour la données du workflow pour les écrans suivants
                this.props.workflow.goToNextStep(pid, truckInfos)
            }, 
            (httpErrorResponse) => { 
                // Gestion de l'erreur générique
                this.setState({loading: false})
                httpErrorResponse.json().then((apiResponse) => {
                    this.showErrorFromResponse(apiResponse)
                })
            })
        }
        else
        {
            this.showErrorModalNotification("", "Merci de renseigner l'identifiant ou l'immatriculation du véhicule")
        }*/
    }
    
    render() {
        return (
            <>
                {this.renderParent()}
                <FormLoadingIndicator loading={this.state.loading} ></FormLoadingIndicator>
                <Row id="form-container">
                    <Col lg="12">
                            <div className="pl-lg-4">
                                <Row className="justify-content-center">
                                    <Message type="infos">
                                        Bienvenue dans l'écran de création d'un conducteur.
                                    </Message>
                                </Row>
                                <FormLegend required={true} />
                                <hr/>
                                <Row>
                                    <Col lg="12">
                                        <FormGroupInput 
                                            intlId={"user.civility"} 
                                            htmlFor={"civility_code"}
                                            type="multiline">
                                            <CivilityInput required={true} manager={this} id="civility_code"></CivilityInput>
                                        </FormGroupInput>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroupInput intlId={"user.first_name"} htmlFor="first_name" type="multiline">
                                            <DefaultInput required={true} childRef={(elt) => this.firstNameInputRef = elt}  name='first_name' manager={this} />
                                        </FormGroupInput>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroupInput intlId={"user.last_name"} htmlFor="last_name" type="multiline">
                                            <DefaultInput required={true} childRef={(elt) => this.lastNameInputRef = elt}  name='last_name' manager={this} />
                                        </FormGroupInput>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroupInput intlId={"user.mobile_phone_number"} htmlFor="mobile_phone_number" type="multiline"
                                            tooltip={{message: this.translateMessage("driver.mobile_phone_number_infos")}}
                                        >  
                                            <DefaultInput required={true} childRef={(elt) => this.mobilePhoneNumberInputRef = elt}  name='mobile_phone_number' manager={this} />
                                        </FormGroupInput>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroupInput intlId={"user.email"} htmlFor="email" type="multiline">
                                            <DefaultInput childRef={(elt) => this.emailInputRef = elt}  name='email' manager={this} />
                                        </FormGroupInput>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
            </>
        );
    }
}


export default injectIntl(DriverIdentityStep)

