
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Alert, Container, Row, Col
  } from "reactstrap";
import { Calendar } from 'react-date-range';

import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator"
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import FormHourInput from "../../pages/components/form/FormHourInput";
import ReactDatetime from "react-datetime";
import RepositoryService from "../../../proxies/RepositoryService";

import { injectIntl , FormattedMessage } from 'react-intl';
import FormRepository from "../../pages/components/form/FormRepository";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DefaultImage from "./defaultImage";
import Resources from "../../../resources";

var moment = require('moment');
require('moment/locale/fr');

class Message extends React.Component {


    getSvg()
    {
        if (this.props.image)
        {
            return this.props.image;
        }

        switch(this.props.type)
        {
            case "infos":
                return this.renderSvg(require("../../../assets/img/icons/infos.svg").default)
                break;
            case "default":
                return <DefaultImage src={Resources.info_logo} style={{fontSize:"30px"}}></DefaultImage>
                break;
            case "warning":
                return this.renderSvg(require("../../../assets/img/icons/warning.svg").default)
                break;
            case "success":
                return this.renderSvg(require("../../../assets/img/icons/success.svg").default)
                break;
        }
    }

    iconIsVisible()
    {
        return this.props.type && (this.props.icon == true || this.props.icon == null)
    }

    renderSvg(svg)
    {
        return <img
            alt="..."
            className=" "
            src={svg}
            style={{ width: this.props.oneLine ? "20px" : "40px"}}
        />
    }


    renderIcon()
    {
        return <>
            <span className={"alert-inner--icon t2y-alert-" + this.props.type + "-inner--icon"}>
                {this.getSvg()}
            </span>{" "}
        </>
    }

    render() {
        var style = this.props.style ? this.props.style : {}
        if (this.props.oneLine)
        {
            style.paddingTop = "7px"
            style.paddingBottom = "2px"
            style.paddingLeft = "0px"
        }

        return<>
            <Alert className={"alert-default t2y-alert-" + (this.props.type ? this.props.type : "default") + " " + (this.props.className ? this.props.className : "")} style={style}>
                <Container>
                    <Row>
                        {
                            (this.iconIsVisible()) ?
                            (
                                <Col xl="1" xs="2" style={{padding: "0", textAlign: "center"}}>
                                    {this.renderIcon()}
                                </Col>
                            ) : (<></>)
                        }
                        <Col xl={this.iconIsVisible() ? "10" : "12"} xs={this.iconIsVisible() ? "9" : "12"} style={{padding: "0", marginLeft: "20px"}}>
                            <span className={"alert-inner--text t2y-alert-" + this.props.type + "-inner--text"}>
                                {this.props.children}
                            </span>
                        </Col>
                    </Row>
                </Container>
                
            </Alert>
       </>
    }
}

export default injectIntl(Message)
