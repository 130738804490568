import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";

import DefaultInput from "../../common/defaultInput";
import PasswordInput from "../../common/passwordInput";
import Resources from "../../../../resources";
import Title2 from "../../common/title2"
import Title3 from "../../common/title3"


import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import SecurityService from "../../../../proxies/SecurityService";
import Step from "../step";
import DefaultButton from "../../common/defaultButton";
import DefaultLink from "../../common/defaultLink";
import StringHelper from "../../../../helpers/StringHelper";

var moment = require('moment');
require('moment/locale/fr');

class CreateAccountNotificationStep extends Step {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.securityService = new SecurityService();
        this.accountType = null;
        this.email = null;

        this.state = {
            loading: false
        }
    }

    getEmail()
    {
        return this.email;
    }

    getAccountType()
    {
        return this.translateMessage("Default." + this.accountType);
    }

    componentWillMount() {
        super.componentWillMount()
        this.accountType = this.securityService.getData("register_account_type");
        this.email = this.securityService.getData("register_email");
        // Suppression des données de session pour ne pas retomber sur cet écran par hasard
        this.securityService.clearStorage();
    }

    /**
     * Envoi à nouveau du mail de validation de compte
     */
    resendRegisterConfirmationEmail()
    {
        this.securityService.resendRegisterEmail(this, this.getEmail(), this.emailResent.bind(this));
    }

    /*
    * Confirmation de l'envoi d'un nouveau mail
    */
    emailResent(response)
    {
        const message = this.translateMessage("RegisterConfirmation.resend_confirmation_message", {email : this.getEmail()})
        const title = this.translateMessage("RegisterConfirmation.resend_confirmation_title")

        this.successNotification(title, message);
    }

    confirmResendEmail()
    {
        this.confirmNotification("", <FormattedMessage id="RegisterConfirmation.resend_confirmation_question" />, this.translateMessage("Default.yes"), (response) => {
            this.resendRegisterConfirmationEmail()
        }, this.translateMessage("Default.no"), (response) => {})
    }
    
    render() {
        return (
            <>
            {this.renderParent()}
            <FormLoadingIndicator loading={this.state.loading} />
            <Container className="t2y-html-translation-container">
                <Row> 
                    <Col xs="12" className="order-lg-1 order-2">
                        <Row className="mb-3 text-center">
                        <Col className="text-center justify-content-text-center order-lg-2 order-1">
                            <img src={require('../../../../assets/img/illustrations/accountWorkflow_createAccountMailSent.svg').default} style={{width:"25%"}} />
                        </Col>
                        </Row>
                        <Row className="mb-3 justify-content-center text-center">
                            <Title3 center={true}>
                                {StringHelper.translateWithHtml(this, "RegisterConfirmation.title2", null, {
                                    email: this.getEmail(),
                                    type: this.getAccountType(), 
                                }, "p", "text-center")}
                            </Title3>
                        </Row>
                        <Row className="mb-3 text-center">
                            <Col>
                                {StringHelper.translateWithHtml(this, "RegisterConfirmation.message", null, {
                                    email: this.getEmail(),
                                    type: this.getAccountType()
                                }, "p", "text-center")}
                                {
                                    /*
                                        <DefaultLink small={true}  onClick={this.confirmResendEmail.bind(this)}>
                                            <FormattedMessage id="RegisterConfirmation.resend_email"/>
                                        </DefaultLink>
                                    */
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            </>
        );
    }
}


export default injectIntl(CreateAccountNotificationStep)

