    
import React from "react";
import classnames from "classnames";

// reactstrap components
import {
    Button,
    Container,
    Form,
    FormGroup,
    UncontrolledPopover,
    UncontrolledTooltip,
    PopoverBody,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
  } from "reactstrap";
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultLabel from '../../components/common/defaultLabel';
import FormGroupInput from '../components/form/FormGroupInput'
import DefaultInput from "../../components/common/defaultInput";
import Resources from "../../../resources";
import Message from "../../components/common/message";
import DefaultPopover from "../../components/common/defaultPopover";
import DefaultButton from "../../components/common/defaultButton";

class TruckWizard extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        
        this.state = {
            current: 'designation',
            designation: 'not-done',
            price: 'forbidden',
            place: 'forbidden',
            drivers: 'forbidden',
            properties: 'forbidden'
        }
    }

    componentWillMount()
    {
    }

    componentWillUpdate()
    {
    }
    
    renderDesignation()
    {
        return <>
            <Row>
            <Col lg="12">
                    <div className="pl-lg-4">
                        <Row className="justify-content-center">
                            <Message type="infos">
                            Merci de renseigner l'identifiant ou l'immatriculation du véhicule
                            </Message>
                        </Row>
                        <hr/>
                        <Row>
                            <Col lg="6">
                                <FormGroupInput label={"Identifiant"} 
                                    tooltip={{message:"Il s'agit de la façon dont vous identifiez ce véhicule en interne.Cette information ne sera pas transmise aux expéditeurs."}}
                                    htmlFor={"reference"}>
                                    <DefaultInput icon={Resources.form_address_city} name="reference" manager={this} popover={
                                        <DefaultPopover content="qsmldksqldkjqsd qsdlkqsd qsl"/>
                                    } />
                                </FormGroupInput>
                            </Col>
                            <Col lg="6">
                                <FormGroupInput label={"Immatriculation"} htmlFor="immatriculation">
                                    <DefaultInput name='immatriculation' manager={this} popover={
                                        <DefaultPopover />
                                    } />
                                </FormGroupInput>
                            </Col>
                            <Col lg="12">
                                <FormGroupInput label={"Description"} htmlFor="description" labelColumns="2" contentColumns="10">
                                    <DefaultInput type="textarea" rows="6" name='description' manager={this} />
                                </FormGroupInput>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    }

    renderPlace()
    {
        return <>
            Lieu de stationnement
        </>
    }

    renderDrivers()
    {
        return <>
            Conducteurs
        </>
    }

    renderProperties()
    {
        return <>
            Propriétés principales
        </>
    }

    renderWizard()
    {
        let nbSteps = 5;
        return <>
            <Row className="stepper-container mt-5">
                <div className="stepper no-before">
                    <div className={"stepper-step stepper-step-" + nbSteps + " " + (this.state.designation)}>
                        <a onClick={(e) => { this.setState({current: "designation"}) }} 
                        class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                        data-placement="top" title="" data-original-title="Basic Information">
                            <i className="fas fa-map-marked-alt" style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content={"Général"}></DefaultLabel>
                    </div>

                    <div className={"stepper-step stepper-step-" + nbSteps + " " + (this.state.place) }>
                        <a onClick={(e) => { if (this.state.place != "forbidden") {this.setState({current: "place"})} }} 
                        class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                        data-placement="top" title="" data-original-title="Basic Information">
                            <i className="far fa-user" style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content={"Stationnement"}></DefaultLabel>
                    </div>

                    <div className={"stepper-step stepper-step-" + nbSteps + " " + (this.state.drivers) }>
                        <a onClick={(e) => { if (this.state.drivers != "forbidden") {this.setState({current: "freight"})} }} 
                        class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                        data-placement="top" title="" data-original-title="Basic Information">
                            <i class="fas fa-truck-loading" style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content="Conducteur"></DefaultLabel>
                    </div>

                    <div className={"stepper-step stepper-step-" + nbSteps + " " + (this.state.price) }>
                        <a onClick={(e) => { if (this.state.price != "forbidden") {this.setState({current: "other"})} }} 
                            class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                            data-placement="top" title="" data-original-title="Basic Information">
                            <i className="fas fa-cog" style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content="Prix"></DefaultLabel>
                    </div>

                    <div className={"stepper-step stepper-step-" + nbSteps + " " + (this.state.other) }>
                        <a onClick={(e) => { if (this.state.allowOther) {this.setState({current: "other"})} }} 
                            class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                            data-placement="top" title="" data-original-title="Basic Information">
                            <i className="fas fa-cog" style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content="Propriétés"></DefaultLabel>
                    </div>

                </div>
            </Row>

        </>
    }

    renderContent()
    {
        switch(this.state.current)
        {
            case "designation":
                return this.renderDesignation();
                break;
            case "place":
                return this.renderPlace();
                break;
        }
    }

    renderFooter()
    {
        return <>
            <Row className="modal-footer">
                <DefaultButton className="t2y-secondary-button">
                    Suivant &gt;&gt;
                </DefaultButton>
            </Row>
        </>
    }

    render() {
        return  (
            <>
            {this.renderParent()}
            <FormLoadingIndicator loading={this.state.loading}>
                <div className="container">
                {this.renderWizard()}
                {this.renderContent()}


                {this.renderFooter()}
            </div>
            </FormLoadingIndicator>
            
          </>
        );
    }
}


export default injectIntl(TruckWizard)