const AccountMessages = {
    "UserState.pendingMailValidation.title": `Votre compte est en attente de validation`,
    "UserState.pendingMailValidation.text": `Une email vous a été envoyé dernièrement pour finaliser la procédure de validation.


    Si vous souhaitez recevoir à nouveau cette email, merci de cliquer sur le lien suivant pour recommencer cette procédure.
    `,
    "UserState.unknownUserBadTokenRedirectToLogin.title": "Impossible d'effectuer l'action demandée",
    "UserState.unknownUserBadTokenRedirectToLogin.text": `Vous avez probablement utilisé un lien d'un ancien mail pour vous reconnecter à l'application.
    Malheureusement cette page n'est plus valide.
    
    Merci de cliquer sur le lien suivant afin de vous connecter`,

    "UserState.unknownUserRedirectToLogin.title": "Impossible d'effectuer l'action demandée",
    "UserState.unknownUserRedirectToLogin.text": "Merci de cliquer sur le lien suivant afin de vous connecter",
    
    "UserState.pendingPasswordValidation.title" : `Votre compte est en attente de modification de mot de passe`,
    "UserState.pendingPasswordValidation.text" : `Une email vous a été envoyé dernièrement pour finaliser cette procédure.


    Si vous souhaitez recevoir à nouveau cette email, merci de cliquer sur le lien suivant pour recommencer cette procédure.
    `,
    "Login.title1" : "Espace Membre",
    "Login.title2" : "Merci de saisir vos informations de connexion",
    "Login.first_name": "Prénom",
    "Login.last_name" : "Nom",
    "Login.mobile_phone_number" : "Tel. Mobile",
    "Login.email" : "Adresse e-mail",
    "Login.password" : "Mot de passe",
    "Login.remember_me" : "Se souvenir de moi",
    "Login.connect" : "Se connecter",
    "Login.forget_password" : "Mot de passe oublié ?",
    "Login.register" : "Je n'ai pas encore de compte",

    "Login.login_check_error_message": "Vous avez saisi un identifiant ou un mot de passe invalide",
    "Login.login_check_error_title" : "Connexion impossible",

    "ForgetPassword.title1" : "Vous avez oublié votre mot de passe?",
    "ForgetPassword.title2" : "Merci de saisir votre email pour démarrer la procédure de changement de mot de passe.",
    "ForgetPassword.email" : "Votre email",
    "ForgetPassword.send_email" : "Changer mon mot de passe",
    "ForgetPassword.bad_email" : "L'adresse email que vous avez saisie est inconnue",
    "ForgetPassword.confirmation_title" : "Votre demande de modification de mot de passe a bien été prise en compte.",
    "ForgetPassword.confirmation_message" : `Vous allez recevoir rapidement un mail contenant les instructions pour créer votre nouveau mot de passe.`,
    "CollaborationActivation.title1" : "Dernière étape avec l'activation de votre compte",
    "ChangePassword.title1" : "Modification de votre mot de passe",
    "ChangePassword.title2" : `Merci de choisir un mot de passe d'au moins 6 caractères contenant au moins 3 des 4 types suivants : majuscules, minuscules, chiffres ou caractères spéciaux`,
    "ChangePassword.invalid_confirmation_password": "Les deux mots de passe ne sont pas identiques",
    "ChangePassword.change_password" : "Modifier mon mot de passe",
    "ChangePassword.password" : "Votre nouveau mot de passe",
    "ChangePassword.password_confirmation" : "Confirmation de votre nouveau mot de passe",
    "ChangePassword.validation_title" : "Mot de passe modifié avec succès",
    "ChangePassword.validation_message" : `Vous allez être redirigé vers l'application dans {seconds} secondes.
    
    Si vous n'êtes pas redirigé automatiquement, vous pouvez cliquer sur le bouton suivant

    `,
    "Register.title1" : "Inscription",
    "Register.title2" : "Créez votre compte gratuit en quelques secondes",

    "Register.step1_title": "Vous êtes: ",

    "Register.choice_title" : "Merci de renseigner les informations suivantes",
    "Register.shipper_choice" : "EXPÉDITEUR : Faire transporter vos marchandises",
    "Register.transporter_choice" : "TRANSPORTEUR : Proposer vos services de location / transport",
    "Register.email" : "Adresse e-mail",
    "Register.password" : "Mot de passe",
    "Register.create_account" : "Créer mon compte",
    "Register.login" : "Je possède déjà un compte",

    "Register.required_account_type" : "Merci de selectionner ce que vous voulez",
    "Register.login_check_error_message": "Vous avez saisi un identifiant ou un mot de passe invalide",
    "Register.login_check_error_title" : "Connexion impossible",


    "RegisterConfirmation.title1" : " ",
    "RegisterConfirmation.title2" : `Votre compte « &lt;strong&gt;{type}&lt;/strong&gt; » a été créé, un lien de validation a été envoyé par e-mail`,
    "RegisterConfirmation.message" : `Un e-mail de validation vous a été envoyé à l’adresse &lt;a href="#" &gt;{email}&lt;/a&gt;, il vous suffit de cliquer sur le lien pour valider votre compte.
    
    Si vous ne recevez pas cet e-mail d’ici quelques minutes, veuillez vérifier dans vos messages indésirables, ou nous contacter `,
    "RegisterConfirmation.resend_email" : "Me renvoyer le mail de validation",
    "RegisterConfirmation.resend_confirmation_question" : "Confirmez vous le renvoi de l'email de validation?",
    "RegisterConfirmation.shipper" : "EXPÉDITEUR",
    "RegisterConfirmation.transporter" : "OFFREUR",
    "RegisterConfirmation.resend_confirmation_title" : "Confirmation",
    "RegisterConfirmation.resend_confirmation_message" : "Un mail de validation vient d'être renvoyé à l'adresse {email}",

    "AccountValidation.title1" : "Validation de votre compte",
    "AccountValidation.loading_message" : "Validation du compte en cours ...",
    "AccountValidation.validation_title" : "Compte &lt;strong&gt;{type}&lt;/strong&gt; validé!",
    "AccountValidation.validation_message" : `Vous allez être redirigé vers l'application dans {seconds} secondes.
    
    Si vous n'êtes pas redirigé automatiquement, vous pouvez cliquer sur le bouton suivant

    `,

    "AccountValidation.validation_error_title" : `Impossible de valider le compte`,
    "AccountValidation.redirect_to_profile" : "Accéder à mon espace {type}",
    "CollaboratorAccountValidation.redirect_to_profile" : "Accéder à mon espace",

    "AccountValidation.RULE_ACCOUNT_ALREADY_VALIDATED" : "Votre compte a déjà été validé.",
    "AccountValidation.RULE_INVALID_TOKEN" : "Le compte n'existe pas.",
    "AccountValidation.RULE_EMPTY_TOKEN" : "Le compte n'existe pas.",
    "AccountValidation.RULE_TOKEN_EXPIRED" : `La clé utilisée a expiré.
    
    Un nouveau mail vient de vous être envoyé
    `,

    "Profile.civility_code" : "Civilité",
    "Profile.first_name": "Prénom",
    "Profile.last_name": "Nom",
    "Profile.mobile_phone_number": "Tél. Mobile",
    "Profile.home_phone_number": "Tél. Fixe",
    "Profile.email": "Adresse mail",
    "Profile.update_email": "Modifier mon email",
    
    "Profile.required_first_name" : "Votre prénom est obligatoire",
    "Profile.required_last_name" : "Votre nom de famille est obligatoire",
    "Profile.required_civility_code" : "Votre civilité est obligatoire",
    "Profile.required_mobile_phone_number" : "Votre numéro de téléphone mobile est obligatoire",

    "ChangeEmail.email" : "Nouvel email",
    "ChangeEmail.title" : "Vous souhaitez modifier l'adresse email?",
    "ChangeEmail.message" : `Merci de saisir un nouvel email.`,
    "ChangeEmail.change_email" : "Modifier",
    "ChangeEmail.confirmation_title" : "Adresse email modifiée avec succès",
    "ChangeEmail.confirmation_message" : `Vous allez recevoir une notification vous indiquant comment valider ce nouvel email.`,
    "ChangeCollaboratorEmail.confirmation_message" : `Votre collaborateur va recevoir une notification lui indiquant comment valider ce nouvel email.`,
    
    "Collaborator.account_validation_title" : "Compte activé avec succès",
    "Collaborator.account_validation_message" : `Vous allez être redirigé vers l'application dans {seconds} secondes.`,

        
    "Driver.account_validation_title" : "Compte activé avec succès",
    "Driver.account_simple_validation_message" : `Dans quelques jours vous recevrez un sms vous demandant d’installer l’application Truck2you qui vous servira à exécuter vos missions Truck2you.`,
    "Driver.account_validation_message" : `Vous pouvez maintenant utiliser l'application pour réaliser les missions qui vous sont affectées.`,
    "Driver.account_validation_android_message": `Avant de pouvoir réaliser vos premières missions, merci de télécharger l'application Truck2You&copy; en cliquant sur le lien ci dessous.
    
    `,
    "Driver.account_validation_ios_error_message": `Pour l'heure l'application mobile Truck2You&copy; n'est pas disponible sous IOS.
    
    Merci de revenir sur cette page via votre appareil &lt;strong&gt;android&lt;/strong&gt;.
    `,
    "Driver.account_validation_other_device_error_message": `Pour l'heure l'application mobile Truck2You&copy; n'est pas disponible sur votre navigateur.
    
    Merci de revenir sur cette page via votre appareil &lt;strong&gt;android&lt;/strong&gt;.
    `,
    "Driver.account_validation_android_download_title": "Télécharger l'application",

    "Company.company_name": "Raison sociale",
    "Company.siret": "Siret",
    "Company.iban_owner_label": "Titulaire du compte",
    "Company.iban_public_value": "Iban",
    "Company.iban_creation": "Information de création",
    "Company.transporter_no_iban_alert": `Vous n'avez renseigné aucune données bancaires.
Sans ces dernières, il nous sera impossible de vous payer.

`,
    "Company.transporter_iban_refused_alert": `L'iban que vous avez soumis à validation a été refusé.
    
    Merci d'en saisir un nouveau.`,
    "Company.new_iban_message": "Bienvenue dans l'écran d'ajout de noouvelles coordonnées bancaires.",
    "Company.new_iban_owner_label": "Titulaire du compte",
    "Company.new_iban": "Iban du compte",
    "Company.new_iban_attachment": "Fichier Pdf ou image de cet iban",
    "Company.new_iban_invalid_format_message": "Iban incorrect",
    "Company.new_iban_upload": "Soumettre mon iban pour validation",
    "Company.new_iban_added": "Vos données bancaires ont été ajoutées avec succès. Nous vous ferons un retour par mail lorsque ces dernières seront validées par nos services.",


    "SmsValidation.title1": "Validation de vos droits",
    "SmsValidation.title2": "Merci de renseigner le code que vous avez reçu par sms",
    "SmsValidation.description": "",
    "SmsValidation.activation_code": "Code d'activation reçu par sms",
    "SmsValidation.submit_button_title": "Envoyer mon code",
    "SmsValidation.new_code_button_title": "Recevoir un nouveau code"
}

export default AccountMessages