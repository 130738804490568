import React from "react";
// react library for routing
import { Route, Switch } from "react-router-dom";
import PageNotFound from "../components/notFound/PageNotFound.jsx";

import routes from "../routes.js";

class DefaultLayout extends React.Component {
  componentDidMount() {
  }
  componentWillUnmount() {
  }
  componentDidUpdate(e) {
  }
  
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/public") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <Switch>
          {this.getRoutes(routes)}
          <Route path={"/public/*"} component={PageNotFound}/>
        </Switch>
      </>
    );
  }
}

export default DefaultLayout;
