import React, { useEffect, useState } from "react"
import './Admin.css'

import IframeV2 from "../../IframeV2"

const Admin = ({props}) => {
	return <div>
		<IframeV2/>
	</div>
}

export default Admin