import React from "react";
import styled from "styled-components";

const S = {};

S.span = styled.span`
  width: 1.8rem;
  height: 1.8rem;
`;

S.svg = styled.svg`
  /* display: inline-block;
  vertical-align: middle; */
  flex-shrink: 0;
`;

function IconLeaf(props) {
  return (
    <S.svg
      viewBox="0 0 24 24"
      width={props.width}
      height={props.height}
      aria-hidden="true"
    >
      <path
        fill="currentColor"
        d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z"
      />
    </S.svg>
  );
}

export default IconLeaf;
