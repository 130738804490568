import React from "react";

import {
    Row,
    Col,
    Button,
    Table
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import ShipperService from "../../../proxies/ShipperService";
import DefaultImage from "../common/defaultImage";
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import Resources from "../../../resources";
import StringHelper from "../../../helpers/StringHelper";
import ArrayHelper from "../../../helpers/ArrayHelper";
import EmptyResult from "../common/emptyResult";
import DeleteButton from "../common/deleteButton";
import LoadingContainer from "../common/loadingContainer";
import Config from "../../../config";
import DefaultLabel from "../common/defaultLabel"
import DefaultButton from "../common/defaultButton";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import Title3 from "../common/title3";
import Title4 from "../common/title4";
import Title5 from "../common/title5";


var moment = require('moment');
require('moment/locale/fr');

class TransporterOperationsList extends FormValidationComponent {

    constructor(props) {
        super(props)
        this.shipperService = new ShipperService();

        this.state = {
            loading: true,
            operations: null
        }
    }

    componentWillMount() {
        this.refresh()
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    count()
    {
        return this.state.operations ? this.state.operations.length : 0
    }

    refresh()
    {
        this.shipperService.getTransportOperationsForMission(this, this.props.missionId, (response) => {
            this.setState({
                operations: response.list,
                loading: false
            })
        })
    }

    downloadFreightDocument(caller, missionId, transportOperationId)
    {
        this.shipperService.downloadTransportOperationFreightDocument(caller, missionId, transportOperationId, response => {

            const linkSource = `data:application/pdf;base64,${response.instance.base64_content}`;
            const downloadLink = document.createElement("a");
            const fileName = response.instance.filename;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        });
    }

    edit(transportOperation)
    {
        this.props.onEdit(transportOperation)
    }
    
    delete(transportOperation)
    {
        this.showModalConfirmation({
            title: "", 
            component: <>Confirmez vous la suppression de cette opération de transport</>, 
            okButtonTitle: StringHelper.translate(this, "Default.yes"), cancelButtonTitle: StringHelper.translate(this, "Default.no"),
            okButtonCallback: () => {
                
                this.shipperService.deleteOperationTransport(this, this.props.missionId, transportOperation.id, (response) => {

                    // Suppression de la données
                    this.setState({
                        operations: ArrayHelper.removeArrayItem(transportOperation, this.state.operations, ["id"])
                    });

                    // Confirmation
                    this.showSuccessToastNotification("Suppression effectuée avec succès", <></>);
                });
            },
            cancelButtonCallback: () => {}
        })
    }

    renderAddresses(element)
    {
        return <>
            <td onClick={(e) => this.edit(element)} className="t2y-clickable">
                <div className="t2y-clickable">
                <DefaultLabel className={WebsiteHelper.onlyVisibleOnMiddle() + " t2y-clickable"}>
                    {element.departure_address.name}<br/>
                    {element.departure_address.zip_code} {element.departure_address.city}
                </DefaultLabel>
                <DefaultLabel className={WebsiteHelper.onlyVisibleOnLarge() + " t2y-clickable"}>
                    <strong>{element.departure_address.name}</strong><br/>
                    {element.departure_address.zip_code} {element.departure_address.city}
                </DefaultLabel>
                {(element.departure_expected_time) ? (<><br/><DefaultLabel style={{color: "red"}}>à {element.departure_expected_time}</DefaultLabel></>) : (<></>)}
                </div>
            </td>
            <td onClick={(e) => this.edit(element)} className="t2y-clickable">
                <DefaultLabel className={WebsiteHelper.onlyVisibleOnMiddle() + " t2y-clickable"}>
                    {element.arrival_address.name}<br/>
                    {element.arrival_address.zip_code} {element.arrival_address.city}
                </DefaultLabel>
                <DefaultLabel className={WebsiteHelper.onlyVisibleOnLarge() + " t2y-clickable"}>
                    <strong>{element.arrival_address.name}</strong><br/>
                    {element.arrival_address.zip_code} {element.arrival_address.city}
                </DefaultLabel>
                {(element.arrival_expected_time) ? (<><br/><DefaultLabel style={{color: "red"}}>à {element.arrival_expected_time}</DefaultLabel></>) : (<></>)}
            </td>
        </>
    }

    render() {
        return (
            <>
                {this.renderParent()}
                <LoadingContainer loading={this.props.loading || this.state.loading || this.state.operations == null}>
                {
                    (this.props.loading || this.state.operations == null) ? 
                    (
                        <></>
                    ):
                    (
                        (this.state.operations.length > 0) ?
                        (
                            <>
                            <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">Départ</th>
                                <th scope="col">Arrivée</th>
                                <th scope="col">Quantité</th>
                                <th scope="col">Poids total</th>
                                <th scope="col">Valeur</th>
                                <th scope="col" />
                                </tr>
                            </thead>
                            <tbody>   
                            {
                            this.state.operations.map((element, index) => {
                                if (element.state.code == "STATE_REMOVED")
                                {
                                    return <></>
                                }
                                else 
                                {
                                    return <>
                                        <tr className="t2y-clickable">
                                        {/*
                                        <th style={{width:"50%"}} onClick={(e) => this.edit(element)}>
                                            {
                                                (element.external_id && StringHelper.notEmptyValue(element.external_id)) ?
                                                ( 
                                                    <><Row className="mb-3"><Col md="12">Ref: {element.external_id}</Col></Row></>
                                                ) : (<></>)
                                            }
                                            
                                        </th>*/}
                                        {this.renderAddresses(element)}
                                        <td onClick={(e) => this.edit(element)}>
                                            {StringHelper.formatNumber(element.total_quantity, 2, ".", " ")}
                                        </td>
                                        <td  onClick={(e) => this.edit(element)}>
                                            {element.total_weight_in_kg > 1000 ? (StringHelper.formatNumber(element.total_weight_in_kg / 1000, 2, ".", " ") + " t") : StringHelper.formatNumber(element.total_weight_in_kg, 2, ".", " ") + " kg"}
                                        </td>
                                        <td  onClick={(e) => this.edit(element)}>
                                            {StringHelper.formatAmount(element.total_amount, 2, ".", " ")}
                                        </td>
                                        <td>
                                            {/*
                                            <DefaultButton small={true} className="t2y-thirdparty-button" onClick={(e) => this.edit(element)}>
                                                <DefaultImage src={this.props.readonly ? Resources.button_eye_icon : Resources.button_edit_icon}></DefaultImage>
                                            </DefaultButton>*/}
                                            {
                                                (element.state.code == "STATE_DRAFT") ?
                                                (
                                                    (this.props.readonly == false) ?
                                                    (
                                                        <DeleteButton label={false} onClick={(e) => {this.delete(element)}}></DeleteButton>
                                                    )
                                                    : (<></>)
                                                ) : 
                                                (
                                                    <DefaultButton small={true} className="t2y-thirdparty-alt-button" onClick={(e) => this.downloadFreightDocument(this, this.props.missionId, element.id)}>
                                                        <DefaultImage style={{fontSize:30}} src={Resources.button_freight_document}></DefaultImage>
                                                    </DefaultButton>
                                                )
                                            }
                                        </td>
                                        </tr>
                                    </>
                                }
                            })
                            }
                            </tbody>
                            </Table>
                            </>
                        ) : (
                            <>
                                <EmptyResult showTitle={false} description={"Aucune opération de transport à afficher"}></EmptyResult>
                            </>   
                        )
                    )
                }
                </LoadingContainer>
            </>
        );
    }
}


export default injectIntl(TransporterOperationsList)

