/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import queryString from 'query-string';
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import TransporterService from "../../../proxies/TransporterService";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import FormInput from '../components/FormInput.jsx';
import { injectIntl , FormattedMessage } from 'react-intl';

import AccountWorkflow from "../../components/workflow/account/accountWorkflow.jsx";
import Title2 from '../../components/common/title2';
import Title3 from '../../components/common/title3';

import DefaultButton from "../../components/common/defaultButton.jsx";

class OrderAcceptation extends FormValidationComponent {
  
    constructor(props) {
        super(props);

        this.transporterService = new TransporterService();
        this.state = {
            loading: true,
            success: false,
            order: null
        }
    }
  
    componentDidMount() {
        this.mounted = true;

        // Appel de l'api pour valier la commande
        const orderId = this.props.match.params.orderId;
        const userId = this.props.match.params.userId;
        const orderDecisionId = this.props.match.params.orderDecisionId;
        this.transporterService.acceptOrder(this, orderId, userId, orderDecisionId, {}, (response) => {
            this.setState({
                loading: false, 
                success: true,
                order: response.order
            })

        }, (httpErrorResponse) => {
            this.setState({loading: false})

            httpErrorResponse.json().then(apiResponse => {
                this.showErrorFromResponse(apiResponse, "");
            });
        });
    }

    componentWillUnmount(){
        this.mounted = false;
    }
  
    render() {
        return <>
            {this.renderParent()}
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
            {
                (this.state.order) ?
                (
                    <Container className="">
                        <Row className="justify-content-center">
                            <Col xs="12" lg="8">
                            <Card className="bg-secondary border-0 mb-0 p-1">
                                <Container>
                                <Row>
                                    <Col xs="12" className="order-lg-1 order-2">
                                        <Row className="mb-3">
                                            <Col>
                                                <Title2>
                                                La commande n° {this.state.order.number} a bien été validée.
                                                </Title2>
                                                <Title3>
                                                Un mail de confirmation vient d'être envoyé à l'expéditeur.
                                                </Title3>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                </Container>
                            </Card>
                            </Col>
                        </Row>
                    </Container>
                ) : (<></>)
            }

        </> 
    }
}

export default injectIntl(OrderAcceptation);
