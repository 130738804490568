
import React from "react";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    DropdownMenu,
    DropdownItem,
    Collapse,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,Navbar,
    NavItem,
    NavLink,
    Nav,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    CardFooter
  } from "reactstrap";
  
import SecurityService from '../../../proxies/SecurityService';
// nodejs library that concatenates classes
import classnames from "classnames";

import FormCivilityInput from "../components/form/FormCivilityInput";
import FormGroupInput from "../components/form/FormGroupInput";
import FormInput from '../components/FormInput'
import FormValidationComponent from "../components/FormValidationComponent";
import FormUploadImage from "../components/FormUploadImage";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import ChangeEmailModal from '../components/modal/ChangeEmailModal.jsx'
import { injectIntl , FormattedMessage } from 'react-intl';
import SearchBar from "../components/SearchBar";
import DefaultLink from "../../components/common/defaultLink";
import DefaultImage from "../../components/common/defaultImage";
import CivilityInput from "../../components/common/civilityInput";
import DefaultInput from "../../components/common/defaultInput";
import Message from "../../components/common/message";
import DefaultButton from "../../components/common/defaultButton.jsx";
import Resources from "../../../resources.js";
import StringHelper from "../../../helpers/StringHelper.js";
import FormFooter from "../../components/form/formFooter";

class Profile extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    this.securityService = new SecurityService();
    this.user = this.securityService.getUser();

    this.validationRules = {
      civility_code : {
        required :true,
        requiredMessage : this.translateMessage("Profile.required_civility_code")
      },
      first_name : {
        required : true,
        requiredMessage : this.translateMessage("Profile.required_first_name")
      },
      last_name : {
        required :true,
        requiredMessage : this.translateMessage("Profile.required_last_name")
      },
      mobile_phone_number : {
        required : true,
        requiredMessage : this.translateMessage("Profile.required_mobile_phone_number"),
        rules : [
          { 
            rule : this.mobilePhoneNumber.bind(this),
            ruleMessage : this.translateMessage("Default.invalid_mobile_phone_number")
          }
        ]
      },
      home_phone_number : {
        rules : [
          {
            rule : this.homePhoneNumber.bind(this),
            ruleMessage : this.translateMessage("Default.invalid_home_phone_number")
          }
        ]
      }
    }
    this.state = {
      loading : false,
      changeEmailModal : false,
      formDatas : {
        mobile_phone_number : { value: this.user.mobile_phone_number},
        email : { value: this.user.email}
      }
    }
  }

  componentDidMount()
  {
    this.civilityInputRef.setValue(this.user.civility ? this.user.civility.code : null)
    this.firstNameInputRef.setValue(this.user.first_name)
    this.lastNameInputRef.setValue(this.user.last_name)
    this.emailInputRef.setValue(this.user.email)
    this.mobilePhoneNumberInputRef.setValue(this.user.mobile_phone_number)
    this.homePhoneNumberInputRef.setValue(this.user.home_phone_number)
  }


  toggleEmailModal()
  {
    const currentValue = this.state.changeEmailModal
    this.setState({
      changeEmailModal: !currentValue
    });
  };
 
  onSubmitForm(e)
  {
    e.preventDefault();
    var formIsValid = this.htmlFormIsValid(e);
    if (formIsValid)
    {
      var postedData = {
        civility_code : this.getHtmlFormControlValue("civility_code"),
        first_name : this.getHtmlFormControlValue("first_name"),
        last_name : this.getHtmlFormControlValue("last_name"),
        mobile_phone_number : this.cleanPhoneNumber(this.getHtmlFormControlValue("mobile_phone_number")),
        home_phone_number : this.cleanPhoneNumber(this.getHtmlFormControlValue("home_phone_number")),
        image_url : document.getElementById("image_url").value
      }
      
      // Enregistrement des données
      this.securityService.updateUser(this, postedData, (response) => {
        // La modification s'est bien passée
        this.showSuccessModalNotification("Confirmation", "Modification effectuée avec succès");
      })
    }
  }

  renderChangeEmailPopup()
  {
    return <ChangeEmailModal manager={this} stateKey='changeEmailModal' 
      onEmailChanged={(email) => {
          this.user.new_username = email;
          this.setState({change: StringHelper.createDomElementId()})
      }}
    />
  }


  render() {
    return  (
        <>
        {this.renderParent()}
        {this.renderChangeEmailPopup()}
        
        <Container className="mt--4" fluid>
          <Form className="needs-validation" noValidate onSubmit={e => this.onSubmitForm(e)} >
            <Row>
              <Col className="order-md-2" md="4">
                <FormUploadImage oldImageUrl={this.securityService.getUserImageUrl()} defaultImageUrl={this.securityService.getDefaultUserImageUrl(null)} />
              </Col>
              <Col className="order-md-1" md="8">
                <FormLoadingIndicator loading={this.state.loading}>
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h3 className="mb-0">{StringHelper.translate(this, "Default.my_profile")}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                      <div className="pl-lg-4">
                          <Row>
                              <Col lg="6">
                                <FormGroupInput 
                                      intlId={"user.civility"} 
                                      htmlFor={"civility_code"}
                                      type="multiline">
                                      <CivilityInput childRef={elt => this.civilityInputRef = elt} manager={this} required={true} id="civility_code"></CivilityInput>
                                  </FormGroupInput>
                              </Col>
                          </Row>
                          <Row>
                          <Col lg="6">
                            <FormGroupInput intlId={"user.first_name"} htmlFor="first_name" type="multiline">
                              <DefaultInput required={true} childRef={(elt) => this.firstNameInputRef = elt}  name='first_name' manager={this} />
                            </FormGroupInput>
                          </Col>
                          <Col lg="6">
                            <FormGroupInput intlId={"user.last_name"} htmlFor="last_name" type="multiline">
                                <DefaultInput required={true} childRef={(elt) => this.lastNameInputRef = elt}  name='last_name' manager={this} />
                            </FormGroupInput>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroupInput intlId={"user.mobile_phone_number"} htmlFor="mobile_phone_number" type="multiline">  
                              <DefaultInput required={true} childRef={(elt) => this.mobilePhoneNumberInputRef = elt}  name='mobile_phone_number' manager={this} />
                            </FormGroupInput>
                          </Col>
                          <Col lg="6">
                            <FormGroupInput intlId={"user.home_phone_number"} htmlFor="home_phone_number" type="multiline">  
                              <DefaultInput childRef={(elt) => this.homePhoneNumberInputRef = elt}  name='home_phone_number' manager={this} />
                            </FormGroupInput>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroupInput intlId={"Profile.email"} htmlFor="email" type="multiline">
                              <InputGroup>
                                <DefaultInput readOnly={true} childRef={elt => this.emailInputRef = elt} defaultValue={this.getFormControlValue("email")} />
                                  <InputGroupAddon addonType="append">
                                  &nbsp;  
                                  <DefaultLink small={true} onClick={this.toggleEmailModal.bind(this)} style={{marginTop:"10px"}}>
                                    <DefaultImage src="ni ni-email-83"></DefaultImage>
                                    <span className="btn-inner--text">&nbsp;
                                      <FormattedMessage id="Profile.update_email" />
                                    </span>
                                  </DefaultLink>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroupInput>
                            {
                                (this.user && this.user.new_username) ?
                                (
                                    <Message type="infos">
                                        Le nouvel email {this.user.new_username} est en attente de validation
                                    </Message>
                                ) : (<></>)
                            }
                          </Col>

                        </Row>
                      </div>                    
                  </CardBody>
                  <CardFooter>
                    <FormFooter></FormFooter>
                  </CardFooter>
                </Card>
                </FormLoadingIndicator>
              </Col>
            </Row>
          </Form>
        </Container>
      </>
    );
  }
}

export default injectIntl(Profile);
