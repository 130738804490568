
import React from "react";

// reactstrap components
import {
    Button
  } from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import DefaultImage from "./defaultImage";
import DefaultButton from "./defaultButton";
import StringHelper from "../../../helpers/StringHelper";
import Resources from "../../../resources";

class DeleteButton extends DefaultButton {

    constructor(props)
    {
        super(props)

        this.state = {
        }
    }

    componentDidMount() {}

    componentWillMount() {}

    componentWillUnmount() {}

    render() {
        var label = this.props.label ? this.props.label : (this.props.label === false ? null : StringHelper.translate(this, "Default.delete"))
        var icon = this.props.icon ? this.props.icon : Resources.button_delete_icon

        return <Button className={"t2y-default-button btn-icon btn-2 t2y-removed-button " + this.props.className } key={this.props.key} color="primary" 
            type={!this.props.type ? "button" : this.props.type} 
            style={this.props.style}
            onClick={this.props.onClick}
        >
            <>
                <span className="btn-inner--icon">
                    <DefaultImage src={icon} />
                </span>
                {
                    (label) ?
                    (
                        <span className="btn-inner--text">{label}</span>
                    ) : (this.props.children) ?
                    (
                        <span className="btn-inner--text">{this.props.children}</span>
                    ) : (<></>)
                }
            </>
            
        </Button>
    }
}

export default injectIntl(DeleteButton)
