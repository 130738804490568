
import React from "react";
import PropTypes from 'prop-types';
import classnames from "classnames";

// reactstrap components
import {
    Button,
    Container,
    Form,
    FormGroup,
    UncontrolledPopover,
    UncontrolledTooltip,
    PopoverBody,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    InputGroup,
    InputGroupAddon,
    InputGroupText
  } from "reactstrap";
import SecurityService from "../../../proxies/SecurityService"
import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultButton from "../common/defaultButton";
import Resources from "../../../resources";
import Title2 from "../common/title2";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import StringHelper from "../../../helpers/StringHelper";
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import DefaultLink from "../common/defaultLink"
import DefaultInput from "../common/defaultInput"
import Codes from "../../../codes";
import ShipperService from "../../../proxies/ShipperService";
import Title3 from "../common/title3";
import Title4 from "../common/title4";
import CivilityInput from "../common/civilityInput";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator";
import DefaultImage from "../common/defaultImage";

class MissionStepContactSelection extends FormValidationComponent {

    static SUB_SELECTION_MODE_NEW_CONTACT = "SUB_SELECTION_MODE_NEW_CONTACT";
    static SUB_SELECTION_MODE_SELECT_KNOWN_CONTACT = "SUB_SELECTION_MODE_SELECT_KNOWN_CONTACT";

    constructor(props)
    {
        super(props)

        this.securityService = new SecurityService()
        this.shipperService = new ShipperService()

        this.validationRules = {
            last_name: {
                required: true,
                requiredMessage : "La nom de famille est obligatoire"
            },
            mobile_phone_number: {
                required : false, 
                rules : [{
                    rule : this.mobilePhoneNumber.bind(this),
                    ruleMessage: this.translateMessage("user.phone_number_invalid_message")
                }],
                linkTo: ["email"]
            },
            email : { 
                required : false,
                rules : [
                {
                    rule : this.email.bind(this),
                    ruleMessage: this.translateMessage("user.email_invalid_message")
                }, 
                {
                    id: "email_phone_number_required",
                    rule : (email) => {
                        return !(this.emptyValue(email) && this.emptyValue(this.getFormControlValue("mobile_phone_number")))
                    },
                    ruleMessage: "Merci de saisir au moins un email ou un numéro de téléphone mobile"
                }]
            }
        }

        this.state = {
            address: this.props.address,
            contact: this.props.contact,
            mode: this.props.address ? (this.props.contact ? Codes.CLASS_CONST_SUMMARY : Codes.CLASS_CONST_SELECTION) : Codes.CLASS_CONST_HIDDEN,
            subMode: MissionStepContactSelection.SUB_SELECTION_MODE_SELECT_KNOWN_CONTACT,
            contacts: null
        }
    }

    componentWillMount()
    {
        this.refreshContacts()
    }

    componentDidMount()
    {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    textFilter(event)
    {
        let filter = event.target.value.toLowerCase();
        let elements = document.getElementsByClassName("filterable")
        let nbMatchElements = 0;
        if (elements.length >= 0)
        {
            let element = null;
            for(let i = 0; i < elements.length; i++)
            {
                element = elements[i];
                if (element.innerText.toLowerCase().indexOf(filter) > -1)
                {
                    element.classList.remove( "filterable-hide");
                    nbMatchElements += 1;
                }
                else{
                    element.classList.add( "filterable-hide");
                }
            };
        }
        
        this.setState({nbOfFilteredItems : nbMatchElements, filter : filter})
    }

    refreshContacts(address = null)
    {
        if (!address)
            address = this.state.address

        if (this.props.readOnly)
        {
            this.setState({contacts: []})
            return false;
        }
        // Récupération des contacts de l'adresse interne (entrepôt / sous-traitant)
        else if (address && address.type && address.type.code != "ADDRESS_TYPE_CUSTOMER")
        {
            this.shipperService.getAddressContacts(this, address.id, (response) => {
                this.setState({
                    contacts: response.list,
                    mode: response.list.length == 0 ? Codes.CLASS_CONST_NEW : this.state.mode
                })
            });
        }
         // Récupération des contacts de l'adresse destinataire moral / physique
        else if (address && address.customer && address.customer_company_address)
        {
            this.shipperService.getCustomerContacts(this, address.customer.id, (response) => {
                this.setState({
                    contacts: response.list,
                    mode: response.list.length == 0 ? Codes.CLASS_CONST_NEW : this.state.mode
                })
            });
        }
    }

    contactFormEvent(event)
    {
        event.preventDefault();

        var formIsValid = this.htmlFormIsValid();
        if (formIsValid)
        {
            // Récupération des données du contact et enregistrement
            this.shipperService.saveTransportOperationContact(this, {
                address_type_code: this.state.address.type.code,
                address_id: this.state.address.id,
                first_name: this.fnameInputRef.getValue(),
                last_name: this.lnameInputRef.getValue(),
                civility_code: this.civilityInputRef.getValue(),
                mobile_phone_number: this.mobilePhoneNumberInputRef.getValue(),
                email:  this.emailInputRef.getValue()
            }, (response) => {
                this.contactSelectionEvent(response.value)
                this.setState({
                    contact: response.value,
                    mode: Codes.CLASS_CONST_SUMMARY,
                    subMode: MissionStepContactSelection.SUB_SELECTION_MODE_SELECT_KNOWN_CONTACT
                })
            }, (httpErrorResponse) => {
                httpErrorResponse.json().then((apiResponse) => {
                    this.showErrorFromResponse(apiResponse, null)
                })
                this.setState({ loading: false })
            });
        }
    }

    contactSelectionEvent(contact)
    {
        if (this.props.onContactSelection)
        {
            this.props.onContactSelection(contact)
        }

        this.setState({contact: contact, mode: contact == null ? this.state.mode : Codes.CLASS_CONST_SUMMARY})
    }

    reset()
    {
        this.setState({contacts: [], mode: Codes.CLASS_CONST_HIDDEN, contact: null, address: null})
    }

    setAddress(address)
    {
        this.setState({contacts: [], contact: null, address: address, mode: Codes.CLASS_CONST_SELECTION})
        this.refreshContacts(address)
    }

    renderNoContactCard(contactsBlockSize)
    {
        var textColor = "#1e2635";
        var iconColor = "#FFC42B";
        var backgroundColor = "white";
        var filterable = true;

        return <Col className="mb-2" xs={contactsBlockSize} key={"no_contact_" + 1} className={(filterable ? "filterable" : "") + " mt-2"} >
            <Button onClick={() => this.contactSelectionEvent(null)} color="default" type="button" 
                style={{ height: "120px", width: "100%", color:"red", 
                    backgroundColor: backgroundColor,
                    borderColor:textColor, 
                    color:textColor}}
            >
                <span className="btn-inner--icon" style={{ 
                    color: "black", fontSize: "16px", float: "left", marginRight: "20px" }}>
                    <i className={Resources.no_contact_logo + " mr-2"}></i>
                    Aucun contact
                </span>
            </Button>
        </Col> 
    }

    renderContact(contact, isSelected, contactsBlockSize, index, type="selected", title = null, filterable = true)
    {
        var textColor = "#1e2635";
        var iconColor = "#FFC42B";
        var backgroundColor = "white";

        return <Col className="mb-2" xs={contactsBlockSize} key={"_contacts_" + index} className={(filterable ? "filterable" : "") + " mt-2"} >
            <Button onClick={() => this.state.mode != Codes.CLASS_CONST_SUMMARY ? this.contactSelectionEvent(contact) : {}} color="default" type="button" 
                style={{ height: "120px", width: "100%", color:"red", 
                    backgroundColor: !isSelected ? backgroundColor : Resources.color_selected_date, 
                    borderColor:textColor, 
                    color:textColor}}
            >
                <span className="btn-inner--icon" style={{ 
                    color: !isSelected ? iconColor : "black", fontSize: "20px", float: "left", marginRight: "20px" }}>
                    <i className={Resources.user_logo}></i>
                    { (title) ? (<span>{contact.summary}</span>) : (<></>) }
                </span>
                <span className="btn-inner--text" style={{ textAlign:"left", float: "left", color:textColor, paddingTop:"5px", width:"100%", display: "inline-block", overflow:"hidden" }}>
                    { (!title) ? (contact.summary) : (<></>) }
                    {
                        (contact.mobile_phone_number) ?
                        (
                            <>  
                                <br/>
                                <DefaultImage src={Resources.mobile_phone_logo} className="mr-2"></DefaultImage>
                                {contact.mobile_phone_number} 
                            </>

                        ) : (<></>)
                    }
                    {
                        (contact.email) ?
                        (
                            <>  
                                <br/>
                                <DefaultImage src={Resources.form_email_icon} className="mr-2"></DefaultImage>{contact.email} 
                            </>

                        ) : (<></>)
                    }
                </span>
            </Button>
        </Col> 
    }

    renderContactsList()
    {
        const contacts = this.state.contacts
        
        if (contacts == null || this.state.loading)
        {
            return <>Chargement des contacts en cours ...</>
        }
        else if (contacts.length == 0 || this.state.subMode == MissionStepContactSelection.SUB_SELECTION_MODE_NEW_CONTACT)
        {
            return <>
                {this.renderContactForm()}
                {
                    // Gestion du bouton permettant de revenir à la liste
                    (contacts.length > 0) ?
                    (
                        <Row className="justify-content-center mt-2">
                            <DefaultLink small={true} onClick={e =>this.setState({subMode: MissionStepContactSelection.SUB_SELECTION_MODE_SELECT_KNOWN_ADDRESS})}>
                            &lt;&lt; Revenir à la liste
                            </DefaultLink>
                        </Row>
                    ) : (<></>)
                }
            </>
        }
        else {
            const contactsBlockSize = this.state.mode == Codes.CLASS_CONST_SUMMARY || contacts.length == 1 ? "6" : (contacts.length == 2 ? "4" : "4");
            const filterInputPlaceholder = "Filtrer les contacts";
            const addButtonTitle = "Créer un contact";

            return  <>
                <Row className="mt-3">
                    <Col xs="8">
                        <FormGroup className="mb-0">
                            <InputGroup className="input-group-alternative input-group-merge">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><i className="fas fa-search" /></InputGroupText>
                                </InputGroupAddon>
                                <Input key={"contact"} placeholder={filterInputPlaceholder} type="text" onChange={this.textFilter.bind(this)} />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        {
                            (this.securityService.isShipper()) ?
                            (
                                <DefaultButton 
                                    className="t2y-secondary-button small" 
                                    icon={Resources.button_add_icon}
                                    onClick={e => this.setState({subMode: MissionStepContactSelection.SUB_SELECTION_MODE_NEW_CONTACT})}
                                >
                                {addButtonTitle}
                                </DefaultButton>
                            ) : (<></>)
                        }
                    </Col>
                </Row>
                <Row className="mt-3" key={"_contacts"}>
                {
                    contacts.map((contact, index) => {
                        var isSelected =  this.state.contact && contact.id == this.state.contact.id
                        var textColor = "#1e2635";
                        var iconColor = "#FFC42B";
                        var backgroundColor = "white";
                        if (this.state.mode != Codes.CLASS_CONST_SUMMARY || (this.state.mode == Codes.CLASS_CONST_SUMMARY && isSelected))
                        {
                            return this.renderContact(contact, isSelected, contactsBlockSize, index, "contact")
                        }
                    })
                }
                {this.renderNoContactCard(contactsBlockSize)}
                </Row>
                {
                    (this.shipperService.isShipper())?
                    (
                        (this.state.mode == Codes.CLASS_CONST_SELECTION) ?
                        (
                            // On propose d'en créer un nouveau
                            <></>
                        ) : 
                        (
                            // Si un seul contact possible alors on propose directement d'en créer un nouveau
                            <Row>
                            {
                                // Sinon on modifie
                                <DefaultLink small={true} 
                                    onClick={(e) => this.setState({mode: Codes.CLASS_CONST_SELECTION, contact: null})} 
                                    style={{paddingTop:"5px", width:"100%", float:"right", textAlign:"right"}}>
                                        {"Sélectionner un autre contact"}
                                </DefaultLink>
                            }
                            </Row>
                        )
                    ) : (<></>)
                }
            </>
        }
    }

    renderContactForm()
    {
        return <>
            <Form className="needs-validation" noValidate onSubmit={e => this.contactFormEvent(e)}>
            <Row>
                <Col lg="6">
                    <FormGroupInput  htmlFor={"civility_code"} type="multiline">
                        <CivilityInput childRef={elt => this.civilityInputRef = elt} manager={this} required={false} id="civility_code"></CivilityInput>
                    </FormGroupInput>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <DefaultInput id="last_name" required={true} 
                        manager={this}
                        //value={this.getInternalValue().last_name}
                        childRef={(elt) => this.lnameInputRef = elt}
                        icon={Resources.form_lname_icon} placeholder={"Nom de famille"}>
                    </DefaultInput>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <DefaultInput id="first_name"
                        manager={this}
                        //value={this.getInternalValue().first_name}
                        childRef={(elt) => this.fnameInputRef = elt}
                        icon={Resources.form_fname_icon} placeholder={"Prénom"}>
                    </DefaultInput>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <DefaultInput id="mobile_phone_number"
                        manager={this}
                        //value={this.getInternalValue().mobile_phone_number}
                        childRef={(elt) => this.mobilePhoneNumberInputRef = elt}
                        icon={Resources.form_mobile_phone_number_icon} placeholder={"N° de mobile"}>
                    </DefaultInput>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <DefaultInput id="email"
                        manager={this}
                        type="email"
                        //value={this.getInternalValue().email}
                        childRef={(elt) => this.emailInputRef = elt}
                        icon={Resources.form_email_icon} placeholder={"E-mail"}>
                    </DefaultInput>
                </Col>
            </Row>
            <Row className="justify-content-center mt-2">
           
                <DefaultButton 
                    className="t2y-thirdparty-button small" 
                    icon={Resources.close}
                    onClick={e => 
                        this.contactSelectionEvent(null)
                    }
                >
                    Aucun contact
                </DefaultButton>

                <DefaultButton type="submit" className="t2y-secondary-button small" onClick={(e) => {}}>
                    {StringHelper.translate(this, "Default.workflow_next_button_label")}
                </DefaultButton>
            </Row>               
        </Form>
        </>
    }

    renderSelectionMode()
    {
        return <>
            {this.props.contactSelectionHeaderComponent}
            {this.renderContactsList()}
        </>
    }

    renderSummaryMode()
    {
        if (!this.state.contact)
        {
            return <></>
        }

        return <>
             <Row>
                <Col xs="6">
                <Title4>Contact sélectionnée</Title4>
                </Col>
                <Col xs="6" className="text-right">
                    {   
                        (!this.props.readOnly) ?
                        (
                            <Row >
                                <DefaultLink small={true} 
                                    onClick={(e) => {
                                        this.setState({mode: Codes.CLASS_CONST_SELECTION, contact:null})
                                        if (this.props.onContactChangeRequest)
                                        {
                                            this.props.onContactChangeRequest()
                                        }

                                        this.refreshContacts()


                                    }}
                                    style={{paddingTop:"5px", width:"100%", float:"right", textAlign:"right"}}>
                                        {"Sélectionner un autre contact"}
                                </DefaultLink>
                            </Row>
                        ) : (
                            <></>
                        )
                    }
                </Col>
            </Row>
            <Row>
                {this.renderContact(this.state.contact, false, 12, 1, "selected", "Destinataire selectionné", false)}
            </Row>
        </>
    }

    render() {
        if (this.state.mode == Codes.CLASS_CONST_HIDDEN)
            return <></>
        
        return <>
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
            {this.renderParent()}
            {
                (this.props.readOnly || this.state.mode == Codes.CLASS_CONST_SUMMARY) ?
                (
                    this.renderSummaryMode()
                ) :
                (
                    this.renderSelectionMode()
                )
            }
        </>
    }
}


MissionStepContactSelection.propTypes = {
    contactSelectionHeaderComponent: PropTypes.any,
    onContactChangeRequest: PropTypes.func,
    readOnly: PropTypes.bool.isRequired,
    address: PropTypes.any,
    mode: PropTypes.oneOf([Codes.CLASS_CONST_SELECTION, Codes.CLASS_CONST_SUMMARY])
};


export default injectIntl(MissionStepContactSelection)