
const showWarning = (step) => {
  document.getElementById("draft-warning").style.display = "flex"
  document.getElementById(step.id + "_nextBtn").style.display = "none"
  document.getElementById(step.id + "_confirmNextBtn").classList.remove("d-none");
  document.getElementById(step.id + "_cancelNextBtn").classList.remove("d-none");
}

const InvalidInputList = (step) => {
  var invalidInputArray = []
  if (document.getElementsByClassName("t2y-required").length) {
    invalidInputArray.push("missing-value")
    showWarning(step)
  }
  if (document.getElementsByClassName("invalid-feedback").length) {
    invalidInputArray.push("invalid-feedback")
  }
  return invalidInputArray;

};



export { InvalidInputList };