/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import queryString from 'query-string';
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import SecurityService from "../../../proxies/SecurityService"
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import FormInput from '../components/FormInput.jsx';
import { injectIntl , FormattedMessage } from 'react-intl';
import AccountWorkflow from '../../components/workflow/account/accountWorkflow'

class Login extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    
    this.securityService = new SecurityService();
    this.email = null;

    this.state = {
      loading : false,
    }
  }
 
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  
  end()
  {
    // On redirige sur l'écran principal de l'application
    this.props.history.push('/admin/profile');
  }

  render() {
    const urlParams = new URLSearchParams (this.props.location.search)    
    var email = urlParams.get("email")
    var currentStep = urlParams.get("current_step")
    if (currentStep == null || currentStep == "")
    {
      currentStep = "LOGIN"
    }

    return (
      <>
        {this.renderParent()}
        <Container className="">
          <Row className="justify-content-center">
            <Col xs="12" lg="8">
              <Card className="bg-secondary border-0 mb-0 p-1">
                <AccountWorkflow id="ACCOUNT_WORKFLOW" embeded={true} email={email} current_step={currentStep} parent={this} />
              </Card>
              </Col>
            </Row>
        </Container>

      </>
    );
  }
}

export default injectIntl(Login);
