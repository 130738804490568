
import React from "react";
import PropTypes from 'prop-types';
import classnames from "classnames";
// reactstrap components
import {
    Container,
    Row,
    Col
  } from "reactstrap";
  

import "../../../assets/vendor/select2/dist/css/select2.min.css";

import Select2 from "react-select2-wrapper";
import queryString from 'query-string';
import { injectIntl, FormattedMessage } from 'react-intl';
import SecurityService from '../../../proxies/SecurityService'
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import ReactDataGrid, {TextEditor} from "react-data-grid";
import ReactDataGridSelector from "../../components/common/reactDataGridSelector.tsx";
import StringHelper from "../../../helpers/StringHelper";
import ShipperService from "../../../proxies/ShipperService";
import FormLoadingIndicator from "../components/FormLoadingIndicator";
import DefaultImage from "../../components/common/defaultImage";
import Resources from "../../../resources";
import ArrayHelper from "../../../helpers/ArrayHelper";
import Message from "../../components/common/message";
import DefaultButton from "../../components/common/defaultButton";




class FreightDocumentFreight extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.securityService = new SecurityService();
        this.shipperService = new ShipperService();

        this.columns = this.buildColumns(this)

        this.state = {
            rows: this.props.transportOperation.good_items,
            transportOperation: this.props.transportOperation
        }
    }

    componentWillMount()
    {
        this.addNewRow()
    }

    componentDidMount()
    {  
    }
    
    close()
    {
        // Si aucune ligne n'est renseignée alors on alerte l'utilisateur
        if (this.state.rows.length == 0 || this.state.rows.length == 1 && this.state.rows[0].id == null)
        {
            this.showModalConfirmation({
                title: "", 
                component: <>Souhaitez vous réellement quitter l'écran des marchandises sans en avoir saisie une?</>, 
                okButtonTitle: StringHelper.translate(this, "Default.yes"), 
                cancelButtonTitle: StringHelper.translate(this, "Default.no"),
                okButtonCallback: () => {
                    if (this.props.onClose)
                    {
                        this.props.onClose()
                    }
                },
                cancelButtonCallback: () => {}
            })
        }
        else 
        {
            if (this.props.onClose)
            {
                this.props.onClose()
            }
        }
    }

    buildColumns(instance)
    {
        var transportOperation = this.props.transportOperation
        var readOnly = this.props.readOnly
        return [
            { key: 'label', name: 'Marchandise', editor: this.props.readOnly ? undefined : TextEditor},
            { key: 'quantity', width: 70, name: 'Qté', editor: this.props.readOnly ? undefined : TextEditor,
                formatter: ({ row }) => row.quantity ? StringHelper.formatNumber(row.quantity.replace(",", "."), 2, ".", " ") : null
            },
            { key: 'weight', width: 70, name: 'Poids /u', editor: this.props.readOnly ? undefined : TextEditor,
                formatter: ({ row }) => row.weight ? StringHelper.formatNumber(row.weight.replace(",", ".") , 2, ".", " ") : null
            },
            { key: 'weight_unit.code', name: 'Unité', width:70,
                editorOptions: { editOnClick: true }, 
                editor: this.props.readOnly ? undefined : ReactDataGridSelector,
                formatter: ({ row }) => StringHelper.translate(this, row.weight_unit.code + ".long_label")
            },
            { key: "amount", width:100, name: 'Valeur', editor: this.props.readOnly ? undefined : TextEditor, 
                formatter: ({ row }) => row.amount ? StringHelper.formatNumber(row.amount.replace(",", "."), 2, ".", " ") + "€" : null
            },
            {
                name: '', width:10, formatter({ row }) {
                    
                    return (
                        <>
                        {
                            (row.id && readOnly == false) ?
                            (
                                <DefaultImage className="t2y-clickable" style={{color: "red"}} src={Resources.button_delete_icon} 
                                    onClick={(e) => {
                                        instance.showModalConfirmation({
                                            title: "", 
                                            component: <>Confirmez vous la suppression de cette marchandise?</>, 
                                            okButtonTitle: StringHelper.translate(instance, "Default.yes"), 
                                            cancelButtonTitle: StringHelper.translate(instance, "Default.no"),
                                            okButtonCallback: () => {
                                                instance.shipperService.deleteOperationTransportGoodItem(instance, {
                                                    id: transportOperation.id,
                                                    //mission_id: this.props.mission.id,
                                                    good_id: row.id
                                                }, (response) => {
                                                    var rows = ArrayHelper.removeArrayItem(row, instance.state.rows, ["id"]);
                                                    instance.setState({rows: rows})
                                                    
                                                    if (instance.props.onChange)
                                                    {
                                                        instance.props.onChange(rows)
                                                    }

                                                    instance.addNewRow()
                                                    instance.showSuccessToastNotification("Suppression effectuée avec succès", "")
                                                });
                                                
                                
                                            },
                                            cancelButtonCallback: () => {}
                                        })


                                    }}>
                                </DefaultImage>
                            ) : (<></>)
                        }
                        </>
                      );
                }
            }
        ];
    }


    deleteRowConfirmation(row)
    {
        this.showModalConfirmation({
            title: "", 
            component: <>Confirmez vous la suppression de cette marchandise?</>, 
            okButtonTitle: StringHelper.translate(this, "Default.yes"), 
            cancelButtonTitle: StringHelper.translate(this, "Default.no"),
            okButtonCallback: () => {
                var transportOperation = this.props.transport_operation;

                this.shipperService.deleteOperationTransportGoodItem(this, {
                    id: transportOperation.id,
                    //mission_id: this.props.mission.id,
                    good_id: row.id
                }, (response) => {
                    var rows = ArrayHelper.removeArrayItem(row, this.state.rows, ["id"]);
                    this.setState({rows: rows})

                    this.showSuccessToastNotification("Suppression effectuée avec succès", "")
                });
                

            },
            cancelButtonCallback: () => {}
        })
    }

    addNewRow()
    {
        var rows = this.state.rows

        var addNewRow = true;
        rows.forEach(r => {
            if (r.id == null)
            {
                addNewRow = false
            }
        })

        if (addNewRow && this.props.readOnly == false)
        {
            rows.push({
                id: null,
                label: null,
                quantity: 0,
                weight: 0,
                amount: 0,
                error: false,
                weight_unit: { code: "PROPERTY_UNIT_KG"},
                weight_unit_label: "Kg(s)"
            }) 
            this.setState({rows: rows})
        }
    }

    renderErrorMessage()
    {
        if (
            // Si première saisie
            (this.state.rows.length == 1 && this.state.rows[0].id == null) ||
            (ArrayHelper.findFirst(this.state.rows, (row) => { return row.error === true})) != null
        )
        {
            return <>
                <Message type="default">
                    Pour qu'une ligne de marchandise soit validée, merci de vérifier que le type de marchandise est renseignée, la quantité est supérieure à 0, le poids par unité est supérieure à 0.
                </Message>
            </>
        }
        else
        {
            return <></>
        }
    }

    setRows(rows, newRows)
    {
        // Récupération de l'index
        const rowIndex = newRows.indexes[0]
        const columnName = newRows.column.key
        // Récupération de la ligne modifiée
        var updatedRow = rows[rowIndex];
        
        if (
            this.emptyValue(updatedRow.label) || 
            this.emptyFloatValue(updatedRow.quantity) || 
            this.emptyFloatValue(updatedRow.weight)
        )
        {
            rows[rowIndex].error = true;
            this.setState({rows, rows})
        }
        else
        {
            rows[rowIndex].error = false;
            this.setState({rows: rows})

            //if ((updatedRow.id == null && columnName == "amount") || updatedRow.id != null)
            {
                // Enregistrement en base de données
                this.shipperService.saveOperationTransportGoodItem(this, {
                    id: this.props.transportOperation.id,
                    //mission_id: this.props.mission.id,
                    good_id: updatedRow.id,
                    good_label: updatedRow.label,
                    good_amount: updatedRow.amount,
                    good_quantity: updatedRow.quantity,
                    good_weight: updatedRow.weight,
                    good_weight_unit_code: updatedRow.weight_unit.code,
                    good_description: null
                }, (response) => {
                    // Mise en mémoire de la données
                    rows[rowIndex].id = response.id;
                    
                    // Information pour l'utilisateur
                    //this.showSuccessToastNotification("Modification enregistrée avec succès")
                    // Mise à jour de l'écran
                    this.setState({rows: rows})

                    if (this.props.onChange)
                    {
                        this.props.onChange(rows);
                    }


                    this.addNewRow()
                })
            }
        }
    }
 
    render() {
        return (
            <>
            {this.renderParent()}
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
            {
                this.renderErrorMessage()
            }
            <ReactDataGrid
                columns={this.columns}
                rows={this.state.rows}
                onRowsChange={this.setRows.bind(this)}
                rowClass={row => row.error ? "t2y-data-grid-row-error" : ""}
            />
            {
                (this.props.readOnly == false) ?
                (
                    <Row className="justify-content-center mt-2">
                        <DefaultButton className="t2y-secondary-button" onClick={(e) => this.close()}>
                            {StringHelper.translate(this, "Default.finished")}
                        </DefaultButton>
                    </Row>
                ) : (<></>)
            }
            </>
        )
    }
}

FreightDocumentFreight.propTypes = {
    onClose: PropTypes.func,
    transportOperation: PropTypes.any,
    readOnly: PropTypes.bool.isRequired
};


export default injectIntl(FreightDocumentFreight)