/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import $ from "jquery";
// reactstrap components
import {
  Button,
  CardImgOverlay,
  CardText,
  CardTitle,
  Card,
  CardImg,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";

class Pricing extends React.Component {

  onMouseOver(domSelector)
  {
    $(".card-shipper, .card-transporter").removeClass("zoom-in").removeClass("shadow-lg");
    $(".card-transporter-overlay, .card-shipper-overlay").css("display", "none");
    $(domSelector).addClass("zoom-in").addClass("shadow-lg");
    $(domSelector + "-overlay").show();
    
  }

  render() {
    return (
      <>
        <AuthHeader title="Choose the best plan for your business" lead="" />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="10">
              <div className="pricing card-group flex-column flex-md-row mb-3">
                <Card className="card-pricing card-shipper border-0 text-center mb-4" onMouseOver={(e) => this.onMouseOver(".card-shipper")} >
                  <CardHeader className="bg-transparent">
                    <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                    Expéditeur
                    </h4>
                  </CardHeader>
                  <CardBody className="px-lg-7">
                    <div className="display-2">
                    <a href="#">
                      <img
                          alt="..."
                          className=" img-center img-fluid shadow shadow-lg--hover"
                          src={require("../../../assets/img/icons/common/register_shipper_icon.svg").default}
                          style={{ width: "100%" }}
                        />
                    </a>
                    </div>
                    <hr/>
                    <span className="text-muted">
                    vous cherchez une solution de transport de vos marchandises
                    </span>
                  </CardBody>
                  <CardFooter>
                    
                  </CardFooter>
                </Card>
                <Card className="card-pricing card-transporter bg-gradient-secondary zoom-in shadow-lg rounded border-0 text-center mb-4"  onMouseOver={(e) => this.onMouseOver(".card-transporter")} >
                  <CardImg 
                    className=" img-center img-fluid shadow shadow-lg--hover"
                    src={require("../../../assets/img/icons/common/empty_truck_image.svg").default}
                    style={{}}
                    top
                  />
                  <CardImgOverlay className="d-flex align-items-center card-transporter-overlay" style={{display:"none"}}>
                    <div>
                      <CardTitle className="h2 text-white mb-2">
                        Offreur
                      </CardTitle>
                      <CardText>
                      vous mettez à disposition vos solutions de transport
                      </CardText>
                    </div>
                  </CardImgOverlay>
                  <CardBody className="px-lg-7">
                    <div className="pt-4 text-center">
                    <h5 className="h3 title">
                      <span className="d-block mb-1">Offreur</span>
                      <small className="h4 font-weight-light text-muted">
                      
                      </small>
                    </h5>
                    </div>
                  </CardBody>
                  <CardFooter className="bg-transparent">
                    
                  </CardFooter>
                </Card>
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-lg-center px-3 mt-5">
            <div>
              <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                <i className="ni ni-building text-primary" />
              </div>
            </div>
            <Col lg="6">
              <p className="text-white">
                <strong>The Arctic Ocean</strong> freezes every winter and much
                of the sea-ice then thaws every summer, and that process will
                continue whatever.
              </p>
            </Col>
          </div>
          <Row className="row-grid justify-content-center">
            <Col lg="10">
              <Table className="table-dark mt-5" responsive>
                <thead>
                  <tr>
                    <th className="px-0 bg-transparent" scope="col">
                      <span className="text-light font-weight-700">
                        Features
                      </span>
                    </th>
                    <th className="text-center bg-transparent" scope="col">
                      Bravo Pack
                    </th>
                    <th className="text-center bg-transparent" scope="col">
                      Alpha Pack
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-0">IMAP/POP Support</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Email Forwarding</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Active Sync</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Multiple domain hosting</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <span className="text-sm text-light">
                        Limited to 1 domain only
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Additional storage upgrade</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">30MB Attachment Limit</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">-</td>
                  </tr>
                  <tr>
                    <td className="px-0">Password protected / Expiry links</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">-</td>
                  </tr>
                  <tr>
                    <td className="px-0">Unlimited Custom Apps</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">-</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Pricing;
