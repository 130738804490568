
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    Col,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    NavLink,
    Modal,
    Button
  } from "reactstrap";
import Resources from "../../../resources";
import FormLoadingIndicator from "../components/FormLoadingIndicator";
import FormValidationComponent from "../components/FormValidationComponent";
import FormGroupInput from "../components/form/FormGroupInput";
import FormHourInput from "../components/form/FormHourInput";
import ReactDatetime from "react-datetime";
import RepositoryService from "../../../proxies/RepositoryService";
import BusinessService from "../../../proxies/BusinessService";
import ShipperService from "../../../proxies/ShipperService";

import { injectIntl , FormattedMessage } from 'react-intl';
import WebsiteHelper from "../../../helpers/WebsiteHelper";
var moment = require('moment');
require('moment/locale/fr');

class SearchBar extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.repositoryService = new RepositoryService();
        this.businessService = new BusinessService();
        this.shipperService = new ShipperService();

        this.state = {
            dateLabel: null,
            nbDomainsChecked: 0,
            nbBodiesChecked: 0,
            nbDaysSelected: 1,
            searchStartDate: null,
            searchEndDate: null,
            searchShowSundayCkb: false,
            searchShowSaturdayCkb: false,
            domains: [],
            bodies: [],
            lonelyDomain: null,
            lonelyBody: null,
            selectedDomains : [],
            selectedBodies: [],
            addressId: null,
            selectedAddress: null,
            addresses: [],
            nbResults: 0,
            showSuccessModal: false,
            searchId: null,
            formDatas : {
                search_start_time: { value: moment("2020-01-02 " + "07:00:00")},
            }
        }
    }

    loadDatas()
    {
        // Récupération des domaines
        this.repositoryService.getRepositories(this, "PROPERTY_DOMAIN", (response) => {
            const domains = response.datas;

            this.setState({domains : domains});
        })
        
        this.businessService.getAddresses(null, (response) => {
            const addresses = response.addresses;
            var addressId = null;
            var selectedAddress = null;
            if (addresses.length == 1)
            {
                addressId = addresses[0].id;
                selectedAddress = addresses[0];
            }

            this.setState({addresses : addresses, addressId: addressId, selectedAddress: selectedAddress});            
        })
    }

    componentWillMount()
    {
        this.loadDatas();
    }

    componentWillReceiveProps()
    {
        //this.loadDatas();
    }

    toggleSuccessModal(e)
    {
        this.setState({showSuccessModal: !this.state.showSuccessModal})   
    }

    quickSearch(e)
    {
        // Récupération des dates
        const startDate = this.state.searchStartDate;
        var endDate = this.state.searchEndDate;
        const startTime = this.getFormControlValue("search_start_time")
        const halfDay = this.state.nbDaysSelected < 1;

        if (this.state.nbDaysSelected <= 1)
        {
            endDate = startDate;
        }

        // Récupération des domaines
        const selectedDomains = this.state.selectedDomains;

        // Récupération des carrosseries
        const selectedBodies = this.state.selectedBodies;

        // Récupération de l'adresse
        const selectedAddressId = this.state.addressId;

        if (startDate == null || endDate == null || selectedDomains.length == 0 || selectedBodies.length == 0 || selectedAddressId == null)
        {
            this.errorMessage("Merci de vérifier vos critères de recherche");
        }
        else
        {
            var postedDatas = {
                start_date: startDate.format("YYYY-MM-DD"),
                end_date: endDate.format("YYYY-MM-DD"),
                start_time: startTime,
                half_day: halfDay,
                address_id: selectedAddressId,
                use_saturday: this.state.nbDaysSelected <= 1 ? false : this.state.searchUseSaturday,
                use_sunday: this.state.nbDaysSelected <= 1 ? false : this.state.searchUseSunday,
                properties: [
                    {
                        code: "BODY",
                        values: selectedBodies
                    }
                ]
            };

            this.shipperService.quickSearch(this, postedDatas, (response) => {
                const nbResults = response.nb_results;
                const searchId = response.search_id;

                if (nbResults > 0)
                {
                    // Redirection vers l'écran de résultat
                    WebsiteHelper.goTo(this, '/admin/search-result/' + searchId + "?nb_results=" + nbResults);
                }
                else {
                    this.setState({searchId: null, nbResults: 0, showSuccessModal: false})
                    this.errorMessage("Votre recherche n'a donné aucun résultat");
                }
            })
        }

    }

    nbDaysSelectedChecked(nbDaysSelected)
    {
        this.setState({
            nbDaysSelected : nbDaysSelected,
            searchUseSaturday: false,
            searchUseSunday: false,
            searchEndDate: null
        })
    }

    domainClicked(ckb, domainId, domainCode, event)
    {
        var currentValue = this.state[domainId] == null? false : this.state[domainId];

        var newState = {};
        newState[domainId] = !currentValue;
        newState.nbDomainsChecked = this.state.nbDomainsChecked + (!currentValue ? 1 : -1)

        this.setState(newState)

        // Rechargement des carrosseries
        var parentsCodes = [];
        $(".domain-ckb").each(function(){
            if ($(this).is(":checked"))
            {
                parentsCodes.push($(this).attr("data-code"));
            }
        });

        this.repositoryService.getRepositoriesByParentsCodes(this, parentsCodes, (response) => {
            const datas = response.datas;
            this.setState({bodies: datas, selectedDomains: parentsCodes})
        })
    }

    bodyClicked(ckb, bodyId, bodyCode, event)
    {
        var currentValue = this.state[bodyId] == null? false : this.state[bodyId];

        var newState = {};
        newState[bodyId] = !currentValue;
        newState.nbBodiesChecked = this.state.nbBodiesChecked + (!currentValue ? 1 : -1)

        var selectedBodies = [];
        $(".body-ckb").each(function(){
            if ($(this).is(":checked"))
            {
                selectedBodies.push($(this).attr("data-code"));
            }
        });
        newState.selectedBodies = selectedBodies;

        this.setState(newState)
    }

    addressClicked(ckb, elementId, addressId, event)
    {
        const addresses = this.state.addresses;
        var selectedAddress = null;
        addresses.forEach(address => {
            if (address.id == addressId)
            {
                selectedAddress = address;
            }
        })

        this.setState({addressId : addressId, selectedAddress: selectedAddress})
    }

    isStartDateValid( current ){
        return current >= moment();
    };

    
    isEndDateValid( current ){
        if (this.state.searchStartDate)
        {
            return current >=this.state.searchStartDate;
        }
        else
        {
            return false;
        }
        
    };

    renderStartDate()
    {
        if (this.state.searchStartDate)
        {
            if (this.state.nbDaysSelected < 1)
            {
                return "Demi journée du " + this.state.searchStartDate.format("DD/MM/YYYY");
            }
            else if (this.state.nbDaysSelected == 1)
            {
                return "Journée du " + this.state.searchStartDate.format("DD/MM/YYYY");
            }
            else
            {
                return "Du " + this.state.searchStartDate.format("DD/MM/YYYY");
            }

        }
        else
        {
            return "Du ...";
        }
    }

    renderEndDate()
    {
        if (this.state.searchEndDate)
        {
           return "au " + this.state.searchEndDate.format("DD/MM/YYYY");
        }
        else
        {
            return "au ...";
        }
    }

    renderDomains()
    {   
        var selectedDomain = null;
        if (this.state.nbDomainsChecked == 1)
        {
            this.state.domains.forEach(domain => {
                if (domain.code == this.state.selectedDomains[0])
                {
                    selectedDomain = domain;
                }
            })

            if (selectedDomain != null)
            {
                return selectedDomain.label;
            }
        }

        if (selectedDomain == null)
        {
            return this.state.nbDomainsChecked + " domaine(s)";
        }
    }

    renderBody()
    {
        var selectedBody  = null;
        if (this.state.nbBodiesChecked == 1)
        {
            this.state.bodies.forEach(body => {
                if (body.code == this.state.selectedBodies[0])
                {
                    selectedBody = body;
                }
            })

            if (selectedBody != null)
            {
                return selectedBody.label;
            }
        }

        return this.state.nbBodiesChecked + " carrosserie(s)"
    }

    renderAddress()
    {
        return this.state.addressId ? this.state.selectedAddress.name : "..."
    }

    endDateSelected(e)
    {
        var startDate = new Date(this.state.searchStartDate.format("YYYY-MM-DD"));
        var endDate = new Date(e.format("YYYY-MM-DD"));

        var nbDays = new Number(((endDate.getTime() / 86400000) - (startDate.getTime() / 86400000)) + 1).toFixed(0);

        
        // Récupération du jour de la semaine pour la date début
        var startDayOfWeek = startDate.getDay();
        var endDayOfWeek = endDate.getDay();
        
        var showSaturdayCkb = false;
        var showSundayCkb = false;
        // Gestion du samedi
        if (startDayOfWeek > 5 || endDayOfWeek > 5)
        {
            showSaturdayCkb = true;
        }

        // Gestion du dimanche
        if (startDayOfWeek == 0 || endDayOfWeek == 0 || (startDayOfWeek > 5 && nbDays > 1))
        {
            showSundayCkb = true;
        }

        // Gestion du weekend complet
        if (nbDays >= 7)
        {
            showSaturdayCkb = true;
            showSundayCkb = true;
        }


        // Détection d'un samedi et / ou dimanche
        this.setState({
            searchEndDate: e, 
            searchShowSaturdayCkb: showSaturdayCkb,
            searchShowSundayCkb: showSundayCkb
        })
    }


    render() {
        return  (
            <>
                {this.renderParent()}
                <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
                <div id="myOverlay" className="rapid-search-overlay" style={{display: this.props.visible == true ? "block": "none"}}>
                    <a href="#" className="closebtn" onClick={this.props.onClose} title="Fermer">×</a>
                    <div className="rapid-search-overlay-content">
                        <Row className="bg-info p-2 rounded">
                        <Col xs="6" lg={this.state.nbDaysSelected <= 1 ? "3" : "2"}>
                        <UncontrolledDropdown nav>
                            <DropdownToggle className="nav-link" color="" tag="a">
                                <NavLink className="nav-link text-white" href="#">
                                    <i className="far fa-calendar-alt fa-2x mr-2" />{this.renderStartDate()}
                                </NavLink>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-lg py-0 p2 p-2" left>
                                <Row>
                                    <div className="px-3 py-3">
                                        <h6 className="text-sm text-muted m-0">
                                        Premier jour ... 
                                        </h6>
                                    </div>
                                    <Col lg="12">
                                            <Row>
                                                <Col lg="12">
                                                    <ReactDatetime style={{border : "none"}} 
                                                        multiple={false} 
                                                        input={false}
                                                        isValidDate={ this.isStartDateValid.bind(this) }
                                                        closeOnSelect={false} id="search_start_date" 
                                                        timeFormat={false} dateFormat="DD/MM/YYYY" 
                                                        locale={"fr-FR"} onChange={(e) => { this.setState({searchStartDate: e})}} />
                                                </Col>
                                            </Row>
                                            <hr/>
                                            <Row>
                                                <Col xs="12">
                                                    <div className="custom-control custom-radio mb-3">
                                                        <input
                                                            className="custom-control-input"
                                                            id="customRadio1"
                                                            name="customRadio"
                                                            type="radio"
                                                            defaultChecked={this.state.nbDaysSelected == 1 ? "checked" : ""}
                                                            onClick={e => this.nbDaysSelectedChecked(1)}
                                                        />
                                                        <label className="custom-control-label" htmlFor="customRadio1">Une journée</label>
                                                    </div>
                                                </Col>
                                                <Col xs="12">
                                                    <div className="custom-control custom-radio">
                                                        <input
                                                            className="custom-control-input"
                                                            id="customRadio2"
                                                            name="customRadio"
                                                            type="radio"
                                                            defaultChecked={this.state.nbDaysSelected > 1 ? "checked" : ""}
                                                            onClick={e => this.nbDaysSelectedChecked(2)}
                                                        />
                                                        <label className="custom-control-label" htmlFor="customRadio2">Plusieurs jours</label>
                                                    </div>
                                                </Col>
                                                <Col xs="12">
                                                    <div className="custom-control custom-radio">
                                                        <input
                                                            className="custom-control-input"
                                                            id="customRadio3"
                                                            name="customRadio"
                                                            type="radio"
                                                            defaultChecked={this.state.nbDaysSelected < 1 ? "checked" : ""}
                                                            onClick={e => this.nbDaysSelectedChecked(0.5)}
                                                        />
                                                        <label className="custom-control-label" htmlFor="customRadio3">Une demi journée</label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <hr/>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroupInput labelColumns={"6"} contentColumns={"6"} label={"A partir de"} >
                                                        <FormHourInput id="search_start_time" manager={this}  />
                                                    </FormGroupInput>
                                                </Col>
                                            </Row>
                                    </Col>
                                </Row>
                                <DropdownItem
                                    className="text-center text-info font-weight-bold py-3"
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                    >
                                    Fermer
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        
                        </Col>
                        {
                            (this.state.nbDaysSelected > 1) ?
                            (
                                <Col xs="6"  lg="2">
                                <UncontrolledDropdown nav>
                                    <DropdownToggle className="nav-link" color="" tag="a">
                                        <NavLink className="nav-link text-white" href="#">
                                            <i className="far fa-calendar-alt fa-2x  mr-2" />{this.renderEndDate()}
                                        </NavLink>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-lg py-0 " left>
                                        <div className="px-3 py-3">
                                            <h6 className="text-sm text-muted m-0">
                                            Dernier jour ... 
                                            </h6>
                                        </div>
                                        <Row>
                                            <Col lg="12">
                                                <Row>
                                                    <Col lg="12">
                                                        <ReactDatetime style={{border : "none"}} 
                                                            multiple={false} 
                                                            input={false}
                                                            isValidDate={ this.isEndDateValid.bind(this) }
                                                            closeOnSelect={false} id="search_end_date" 
                                                            timeFormat={false} dateFormat="DD/MM/YYYY" 
                                                            locale={"fr-FR"} onChange={(e) => this.endDateSelected(e)} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        {
                                            (this.state.searchShowSaturdayCkb) ?
                                            (
                                                <>
                                                <Row>
                                                <Col xs="12">
                                                    <div className="custom-control custom-radio mb-3">
                                                        <input
                                                            className="custom-control-input"
                                                            id="customWeekendRadio1"
                                                            name="customWeekendRadio"
                                                            type="checkbox"
                                                            defaultChecked={this.state.searchUseSaturday ? "checked" : ""}
                                                            onClick={e => {this.setState({searchUseSaturday: !this.state.searchUseSaturday})}}
                                                        />
                                                        <label className="custom-control-label" htmlFor="customWeekendRadio1">Utiliser le samedi</label>
                                                    </div>
                                                </Col>
                                                </Row>
                                                </>
                                            ): (<></>)
                                        }
                                        {
                                            (this.state.searchShowSundayCkb) ?
                                            (
                                                <>
                                                <Row>
                                                <Col xs="12">
                                                    <div className="custom-control custom-radio">
                                                        <input
                                                            className="custom-control-input"
                                                            id="customWeekendRadio2"
                                                            name="customWeekendRadio"
                                                            type="checkbox"
                                                            defaultChecked={this.state.searchUseSunday > 1 ? "checked" : ""}
                                                            onClick={e => {this.setState({searchUseSunday: !this.state.searchUseSunday})}}
                                                        />
                                                        <label className="custom-control-label" htmlFor="customWeekendRadio2">Utiliser le dimanche</label>
                                                    </div>
                                                </Col>
                                                </Row>
                                                <hr/>
                                                </>
                                            ) : (<></>)
                                        }
                                        <DropdownItem
                                            className="text-center text-info font-weight-bold py-3"
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                            >
                                            Fermer
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                </Col>
                            ): ("")
                        }
                        <Col xs="6"  lg="2">
                        <UncontrolledDropdown nav>
                            <DropdownToggle className="nav-link" color="" tag="a">
                                <NavLink className="nav-link text-white" href="#">
                                    <i className="fas fa-tags fa-2x mr-2" />{this.renderDomains()}
                                </NavLink>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-lg py-0 p2 p-2" left>
                                <Row>
                                    <div className="px-3 py-3">
                                        <h6 className="text-sm text-muted m-0">
                                        Domaines
                                        </h6>
                                    </div>
                                    <Col lg="12">
                                        {
                                            this.state.domains.map(domain => {
                                                const domainId = "domain_" + domain.id;
                                                return <div key={domainId} className="custom-control custom-checkbox">
                                                    <input
                                                        checked={ this.state[domainId] ? "checked" : ""}
                                                        className="custom-control-input domain-ckb"
                                                        id={domainId}
                                                        data-code={domain.code}
                                                        data-label={domain.label}
                                                        type="checkbox"
                                                        onChange={(e) => this.domainClicked(this, domainId, domain.code, e)}
                                                    />
                                                    <label className="custom-control-label" htmlFor={domainId}>
                                                    {domain.label}
                                                    </label>
                                                </div>
                                            })
                                        }

                                    </Col>
                                </Row>
                                <DropdownItem
                                    className="text-center text-info font-weight-bold py-3"
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                    >
                                    Fermer
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        </Col>
                        <Col xs="6"  lg="2">
                            <UncontrolledDropdown nav>
                            <DropdownToggle className="nav-link" color="" tag="a">
                                <NavLink className="nav-link text-white" href="#">
                                    <i className="fas fa-truck-moving fa-2x mr-2" />{this.renderBody()}
                                </NavLink>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-lg py-0 p2 p-2" left>
                                <Row>
                                    <div className="px-3 py-3">
                                        <h6 className="text-sm text-muted m-0">
                                        Carrosserie
                                        </h6>
                                    </div>
                                    <Col lg="12">
                                        {
                                            this.state.bodies.map(body => {
                                                const bodyId = "body_" + body.id;
                                                return <div className="custom-control custom-checkbox">
                                                    <input
                                                        checked={ this.state[bodyId] ? "checked" : ""}
                                                        className="custom-control-input body-ckb"
                                                        id={bodyId}
                                                        type="checkbox"
                                                        data-code={body.code}
                                                        data-label={body.label}
                                                        onChange={(e) => this.bodyClicked(this, bodyId, body.code, e)}
                                                    />
                                                    <label className="custom-control-label" htmlFor={bodyId}>
                                                    {body.label}
                                                    </label>
                                                </div>
                                            })
                                        }

                                    </Col>
                                </Row>
                                <DropdownItem
                                    className="text-center text-info font-weight-bold py-3"
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                    >
                                    Fermer
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        </Col>
                        <Col xs="6"  lg="2">
                            <UncontrolledDropdown nav>
                            <DropdownToggle className="nav-link" color="" tag="a">
                                <NavLink className="nav-link text-white" href="#">
                                    <i className="fas fa-map-marked-alt fa-2x mr-2" />{this.renderAddress()}
                                </NavLink>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-lg py-0 p2 p-2" left>
                                <Row>
                                    <div className="px-3 py-3">
                                        <h6 className="text-sm text-muted m-0">
                                        Adresse du premier chargement 
                                        </h6>
                                    </div>
                                    <Col lg="12">
                                    {
                                        this.state.addresses.map(address => {
                                            const addressId = "address" + address.id;
                                            return <div className="custom-control custom-radio" key={addressId}>
                                            <input
                                                checked={ this.state.addressId == address.id ? "checked" : ""}
                                                className="custom-control-input address-ckb"
                                                id={addressId}
                                                name="address"
                                                data-label={address.name}
                                                type="radio"
                                                onChange={(e) => this.addressClicked(this, addressId, address.id, e)}
                                            />
                                            <label className="custom-control-label" htmlFor={addressId}>
                                              {address.name}
                                            </label>
                                          </div>
                                        })
                                    }
                                    </Col>
                                </Row>
                                <DropdownItem
                                    className="text-center text-info font-weight-bold py-3"
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                    >
                                    Fermer
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        </Col>
                        <NavLink className="nav-link text-white border-white"  href="#" onClick={(e) => this.quickSearch(e)}>
                            <i className="fa fa-search fa-2x mr-2" /> Rechercher
                        </NavLink>
                        
                </Row>
          
                    </div>
                </div>
          </>
        );
    }
}


export default injectIntl(SearchBar)


