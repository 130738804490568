/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardText,
  CardTitle,
  CardImg,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import SecurityService from "../../../proxies/SecurityService";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import { injectIntl , FormattedMessage } from 'react-intl';
import PasswordInput from '../../components/common/passwordInput';
import Paragraph from '../../components/common/paragraph';
import DefaultButton from '../../components/common/defaultButton';
import Title1 from '../../components/common/title1';
import Title2 from '../../components/common/title2';
import Title3 from '../../components/common/title3';
import Resources from "../../../resources.js";
import UserState from "../../components/user/userState.jsx";
import Config from "../../../config.js";
import RedirectionAlert from '../../components/business/redirectionAlert'
import StringHelper from "../../../helpers/StringHelper";
import WebsiteHelper from "../../../helpers/WebsiteHelper";

class ChangePassword extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    
    this.securityService = new SecurityService();

    this.validationRules = {
      password : {
        required : true,
        /*rules : [
          { 
            rule : this.email.bind(this),
            ruleMessage : this.translateMessage("Default.invalid_email")
          }
        ]*/
      },
      password_confirmation : {
          required : true,
          rules:  [
            {
                rule : this.checkConfirmationPassword.bind(this),
                ruleMessage : this.translateMessage("ChangePassword.invalid_confirmation_password")
            }
          ]
      }
    }
    this.state = {
      loading : false,
      passwordChanged : false,
      onExpirationError : false,
      seconds : 5,

      userError: false
    }
  }

  getAccountType()
  {
    const accountType = this.securityService.getUser().is_shipper ? "shipper" : "transporter";

    return this.translateMessage("RegisterConfirmation." + accountType)
  }

  getToken()
  {
    const urlParams = new URLSearchParams (this.props.location.search)    
    return urlParams.get("token")
  }

  getId()
  {
    const urlParams = new URLSearchParams (this.props.location.search)    
    return urlParams.get("id")
  }
 
  componentDidMount() {
    const token = this.getToken()
    const id = this.getId();
    // Si aucun token dans l'url alors on redirige sur la page de login
    if (token == null || id == null)
    {
      this.props.history.push('/auth/login');
    }
    else
    {
      // Récupération de l'identifiant dans l'url
      this.setState({loading: true})
      var userStateActionPromise = this.securityService.checkUserStateAction(id, token);
      userStateActionPromise.then((userStateActionResponse) => {
        if (userStateActionResponse.code == "LOGIN_WITH_EMAIL_BAD_TOKEN" || (userStateActionResponse.user && userStateActionResponse.user.state.code != "STATE_ACTIVE"))
        {
          this.setState({userError: true, userStateActionResponse: userStateActionResponse})
        }
        else
        {
          document.getElementById("password").focus();
        }

        this.setState({loading: false})
      });
      
    }
  }


  checkConfirmationPassword()
  {
      var password = this.getHtmlFormControlValue("password")
      var passwordConfirmation = this.getHtmlFormControlValue("password_confirmation")
      return passwordConfirmation == password
  }

  onSubmitForm = e => {
    e.preventDefault()
    var formIsValid = this.htmlFormIsValid(e);
    if (formIsValid)
    {
      // Récupération des donées postée
      var password = this.getHtmlFormControlValue("password")
      var passwordConfirmation = this.getHtmlFormControlValue("password_confirmation")
      // Modification du mot de passe
      this.setState({loading: true})
      this.securityService.changePassword(this, {token : this.getToken(), password : password, password_confirmation : passwordConfirmation}, (response) => {
        // Si tout est ok, on affiche le message et on redirige sur la home
        var url = WebsiteHelper.cleanUrl(response.user.return_url != null ? response.user.return_url : '/admin/profile')
        // Si aucune information société, alors on reroute sur l'écran permettant de les modifier
        if (this.securityService.hasCompanyInfos() == false)
        {
          url = "/auth/login"
        }
        
        this.setState({
          passwordChanged : true, 
          onExpirationError: false,
          url: url
        })

        this.successNotification("Confirmation", "Votre mot de passe à été modifé avec succés.", "Fermer");

      }, (httpResponse) => {

        // On recherche l'erreur d'expiration
        if (httpResponse.status == "498")
        {
          this.setState({passwordChanged : false, onExpirationError: true, loading : false })
        }
        else{
          httpResponse.json().then((apiResponse) => {
            if (apiResponse.invalid_rules && apiResponse.invalid_rules.length > 0)
            {
              this.showErrorFromResponse(apiResponse)
            }
            else
            {
              this.setState({userError: true})
            }
            
          });
          
          this.setState({loading: false})
        }
      });
    }
  }

  showSuccessMessage()
  {
    return <Col md="8" style={{marginTop:"150px"}}>
      <Card className="bg-secondary border-0 mb-0">
        <CardBody className="px-lg-5 py-lg-5">
            <CardTitle className="mb-3" tag="h3">
                <FormattedMessage id="ForgetPassword.confirmation_title">
                { this.decodeEntities }
                </FormattedMessage>
            </CardTitle>
            <CardText className="mb-4">
                <FormattedMessage id="ForgetPassword.confirmation_message">
                { this.decodeEntities }
                </FormattedMessage>
            </CardText>
        </CardBody>
      </Card>
    </Col>
  }

  showForm()
  {
      return <Col md="8" style={{marginTop:"150px"}}>
      <Card className="bg-secondary border-0 mb-0">
        <CardBody className="px-lg-5 py-lg-5">
          <div className="pt-4 text-center">
            <h5 className="h3 title">
            
            </h5>
          </div>
          
          <Form onSubmit={e => this.onSubmitForm(e)} role="form">
            <Row className="mb-3">
                <Col>
                <Title2>
                  <FormattedMessage id='ChangePassword.title1'>
                        {this.decodeEntities}
                    </FormattedMessage>
                </Title2>
                <Paragraph>
                  {StringHelper.translateWithHtml(this, 'account.password_information')}
                </Paragraph>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <PasswordInput id="password" 
                        creation={false}
                        childRef={(elt) => this.passwordInputRef = elt}
                        icon={Resources.form_password_icon} placeholder={this.translateMessage("ChangePassword.password")}>
                    </PasswordInput>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <PasswordInput id="password_confirmation" 
                        childRef={(elt) => this.passwordConfirmationInputRef = elt}
                        icon={Resources.form_password_icon} placeholder={this.translateMessage("ChangePassword.password_confirmation")}>
                    </PasswordInput>
                </Col>
            </Row>

            <div className="text-center">
              <DefaultButton className="my-4 t2y-secondary-button" type="submit">
                <FormattedMessage id='ChangePassword.change_password'/>
              </DefaultButton>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  }

  showSuccessMessage()
  {
      return <Col md="7">
          <Card className="bg-secondary border-0">
            <CardBody>
                <CardTitle className="mb-3" tag="h3">
                    <FormattedMessage id="ChangePassword.validation_title">
                    { this.decodeEntities }
                    </FormattedMessage>
                </CardTitle>
                <CardText className="mb-4">
                    <FormattedMessage id="ChangePassword.validation_message" values={{seconds: this.state.seconds}}>
                    { this.decodeEntities }
                    </FormattedMessage>
                </CardText>
                <Button
                    className="t2y-secondary-button"
                    color="primary"
                    href={this.state.url}
                >
                    <FormattedMessage
                    id="AccountValidation.redirect_to_profile" values={{type: this.getAccountType() }}
                    />
                </Button>
                </CardBody>  
            </Card>
        </Col>
  }

  render() {
    if (this.state.userError)
    {
      return <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
            <Col md="8"><UserState id={this.getId()} userStateActionResponse={this.state.userStateActionResponse}></UserState>
            </Col>
        </Row>
      </Container>
    }

    return (
      <>
        {this.renderParent()}
        <Container className="mt--8 pb-5">
        <FormLoadingIndicator
          loading={this.state.loading}
        />
          <Row className="justify-content-center">
            {
                (this.state.passwordChanged == false && this.state.onExpirationError == false) ?
                (
                    this.showForm()
                )
                :
                (
                    (this.state.onExpirationError == false) ?
                    (
                        //this.showSuccessMessage()
                        <RedirectionAlert style={{marginTop:"200px"}} url={this.state.url}></RedirectionAlert>
                    )
                    :
                    (
                        this.showErrorMessage()
                    )
                )
            }
            
          </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(ChangePassword);
