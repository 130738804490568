import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Row,
    Col,
} from "reactstrap";

import DefaultInput from "../../common/defaultInput";
import Message from '../../common/message';
import FormGroupInput from '../../../pages/components/form/FormGroupInput'

import { injectIntl } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import TransporterService from "../../../../proxies/TransporterService";
import Step from "../step";
import StringHelper from "../../../../helpers/StringHelper";
import FormLegend from "../../form/formLegend";


class TruckDesignationStep extends Step {

    constructor(props) {
        super(props)
        
        this.transporterService = new TransporterService();

        this.validationRules = {
        }

        this.state = {
        }
    }

    componentWillMount() {
        super.componentWillMount()
    }

    componentDidMount() {
        super.componentDidMount()

        // Pré-remplissage des champs
        var truck = this.props.workflow.getTruck();
        this.referenceInputRef.setValue(truck.reference);
        this.registrationInputRef.setValue(truck.registration);
        this.descriptionInputRef.setValue(truck.description);
    }

    componentWillUnmount() {
        super.componentWillUnmount()
    }


    submit(pid)
    {
        var formIsValid = this.htmlFormIsValid(null);

        // Récupération des données saisies
        const reference = this.getHtmlFormControlValue("reference")
        const registration = this.getHtmlFormControlValue("registration")
        const description = this.getHtmlFormControlValue("description")

        if (StringHelper.notEmptyValue(reference) || StringHelper.notEmptyValue(registration))
        {
            // Enregistrement des données du camion
            var truckInfos = this.props.workflow.getTruck();
            truckInfos.reference = reference;
            truckInfos.registration = registration;
            truckInfos.description = description;            

            this.transporterService.saveTruckReference(this, truckInfos, (response) => {
                // On remonte l'information au workflow
                super.submit(pid)

                // On stock l'id du camion
                truckInfos.id = response.id
                truckInfos.complete = response.complete;
                truckInfos.complete_response = response.complete_response; 

                // Récupération des données sur la durée et le km autorisé dans le package
                truckInfos.bnd_hours_per_day = response.bnd_hours_per_day;
                truckInfos.bnd_minutes_per_day = response.bnd_minutes_per_day;
                truckInfos.bnd_distance_per_day = response.bnd_distance_per_day;
                truckInfos.bnd_hours_per_half_day = response.bnd_hours_per_half_day;
                truckInfos.bnd_minutes_per_half_day = response.bnd_minutes_per_half_day;
                truckInfos.bnd_distance_per_half_day = response.bnd_distance_per_half_day;
                
                // On passe à l'étape suivante et on met à jour la données du workflow pour les écrans suivants
                this.props.workflow.goToNextStep(pid, truckInfos)
            }, 
            (httpErrorResponse) => { 
                // Gestion de l'erreur générique
                this.setState({loading: false})
                httpErrorResponse.json().then((apiResponse) => {
                    this.showErrorFromResponse(apiResponse)
                })
            })
        }
        else
        {
            this.showErrorModalNotification("", "Merci de renseigner l'identifiant ou l'immatriculation du véhicule")
        }
    }
    
    render() {
        return (
            <>
                {this.renderParent()}
                <FormLoadingIndicator loading={this.state.loading} ></FormLoadingIndicator>
                <Row>
                    <Col lg="12">
                            <div className="pl-lg-4">
                                <Row className="justify-content-center">
                                    <Message type="infos">
                                    Merci de renseigner l'identifiant ou l'immatriculation du véhicule
                                    </Message>
                                </Row>
                                <FormLegend required={true} context="truck" />
                                <hr/>
                                <Row>
                                    <Col lg="12">
                                        <FormGroupInput label={StringHelper.translate(this, "truck.edit_reference")} 
                                            tooltip={{message: StringHelper.translate(this, "truck.edit_reference_message")}}
                                            htmlFor={"reference"}
                                            type="multiline">
                                            <DefaultInput required={true} childRef={(elt) => this.referenceInputRef = elt} name="reference" manager={this} />
                                        </FormGroupInput>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroupInput label={"Immatriculation"} htmlFor="registration" type="multiline">
                                            <DefaultInput required={true} childRef={(elt) => this.registrationInputRef = elt}  name='registration' manager={this} />
                                        </FormGroupInput>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroupInput 
                                            label={StringHelper.translate(this, "truck.edit_description")} 
                                            htmlFor="description" 
                                            labelColumns="2" 
                                            contentColumns="10" 
                                            type="multiline"
                                            tooltip={{message: StringHelper.translate(this, "truck.edit_description_message")}}
                                        >
                                            <DefaultInput childRef={(elt) => this.descriptionInputRef = elt}  type="textarea" rows="6" name='description' manager={this} />
                                        </FormGroupInput>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
            </>
        );
    }
}


export default injectIntl(TruckDesignationStep)

