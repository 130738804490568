
import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
    Card,
} from "reactstrap";
import SecurityService from "../../../proxies/SecurityService"
import { injectIntl } from 'react-intl';
import DefaultButton from "../common/defaultButton";
import Resources from "../../../resources";
import Title2 from "../common/title2";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import StringHelper from "../../../helpers/StringHelper";

class NoTruckDriverAlert extends React.Component {

    constructor(props)
    {
        super(props)
        this.securityService = new SecurityService();

        this.state = {
            company: this.securityService.getCompany()
        }
    }

    componentWillMount()
    {

    }

    renderTruckButton()
    {
        return <Row className="justify-content-center ">
            <Col xs="6">
             {   
                (this.state.company.nb_trucks == 0) ?
                (
                    <DefaultButton 
                        onClick={() => WebsiteHelper.goTo(this, "/admin/trucks?mode=add") }
                        icon={Resources.button_truck_icon} className="t2y-secondary-button">Ajouter un camion</DefaultButton>
                ) : 
                (
                    <DefaultButton onClick={() => WebsiteHelper.goTo(this, "/admin/trucks") } icon={Resources.button_truck_icon} className="t2y-secondary-button">Publier un véhicule</DefaultButton>
                )
            }
            </Col>
        </Row>
    }

    renderDriverButton()
    {
        return <>
            <Row className="justify-content-center mt-3">
                <Col xs="6">
                <DefaultButton 
                    onClick={() => WebsiteHelper.goTo(this, "/admin/new-driver") }
                    icon={Resources.button_driver_icon} className="t2y-secondary-button">Ajouter un conducteur</DefaultButton>
                </Col>
            </Row>
        </>
        
    }
    renderText()
    {
        if (this.state.company.nb_completed_trucks === 0 && this.state.company.nb_drivers === 0)
        {
            return StringHelper.translate(this, "NoTruckDriverAlert." + this.props.context + "_no_truck_no_driver")
        }
        else if (this.state.company.nb_completed_trucks === 0)
        {
            return StringHelper.translate(this, "NoTruckDriverAlert." + this.props.context + "_no_truck")
        }
        else if (this.state.company.nb_drivers === 0)
        {
            return StringHelper.translate(this, "NoTruckDriverAlert." + this.props.context + "_no_driver")
        }
        else if (this.state.company.nb_driver_truck_links === 0)
        {
            return StringHelper.translate(this, "NoTruckDriverAlert." + this.props.context + "_no_links")
        }
        else {
            return <></>
        }
    }

    renderCallToActions()
    {
        if (this.state.company.nb_completed_trucks == 0 && this.state.company.nb_drivers == 0)
        {
            return <>
                {this.renderTruckButton()}
                {this.renderDriverButton()}
            </>
        }
        else if (this.state.company.nb_completed_trucks == 0)
        {
            return  this.renderTruckButton()
        }
        else if (this.state.company.nb_drivers == 0)
        {
            return this.renderDriverButton()
        }
        else {
            return <></>
        }
    }

    render() {
        return  (
            <>
                <Container className="">
                    <Row className="justify-content-center text-center">
                        <Col xs="12" lg="8">
                        <Card className="bg-secondary border-0 mb-0 p-1">
                            <Title2 center={true}>
                            {this.renderText()}
                            </Title2>
                            {this.renderCallToActions()}
                        </Card>
                        </Col>
                    </Row>
                </Container>
          </>
        );
    }
}


export default injectIntl(NoTruckDriverAlert)