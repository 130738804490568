/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import queryString from 'query-string';
import $ from "jquery";
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
    Modal,
    Alert,
    Table,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardText,
  CardBody,
  CardImg,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import BusinessService from "../../../proxies/BusinessService.js";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import FormGroupInput from '../components/form/FormGroupInput.jsx';
import FormInput from '../components/FormInput.jsx';
import ReactDatetime from "react-datetime";

import Config from "../../../config.js"
import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultButton from "../../components/common/defaultButton.jsx";
import InvoiceSummary from "./InvoiceSummary.jsx"
import DefaultInput from "../../components/common/defaultInput.jsx";
import DefaultLabel from "../../components/common/defaultLabel.jsx";
import DefaultLink from "../../components/common/defaultLink.jsx";
var moment = require('moment');
require('moment/locale/fr');

class ManualInvoiceValidation extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    
    this.businessService = new BusinessService();

    this.validationRules = {
        invoice_number: {
            required: false,
            rules: [
                {
                    rule: ((value) => {
                        // Si accépté alors le numéro de facture est obligatoire
                        if (this.state.state_code == "INVOICE_STATE_RECEIVED")
                        {
                            return this.notEmptyValue(value)
                        }
                        else
                        {
                            return true;
                        }
                    }).bind(this),
                    ruleMessage: "Le numéro de facture est obligatoire"
                }
            ]
        }
    }
    this.state = {
        order_id: null,
        transporter_id: null,
        confirmation: false,
        show_confirmation_modal: false,
        loading : false,
        image_file: null,
        upload_file_alert: false,
        confirmation_message: null,
        state_code: null,
        formDatas : {
            comment: {value: null},
            invoice_number: { value: null},
            invoice_date: { value: moment()}
        }
    }
  }
 
  componentDidMount() {
      // Récupération des données de la facture
      const parsedQueryString = queryString.parse(window.location.search);
      this.businessService.getTransporterInvoiceBillingInfos(this, parsedQueryString.orderId, parsedQueryString.transporterId, (response) => {
        var formDatas = this.state.formDatas;
        formDatas.invoice_date.value = moment(response.invoice.date_as_string, "DD/MM/YYYY")
        this.setState({
            invoice: response.invoice,
            order_id: parsedQueryString.orderId,
            transporter_id: parsedQueryString.transporterId,
            formDatas: formDatas
        })

        if (response.invoice.state.code != "INVOICE_STATE_WAITING_FOR_VALIDATION")
        (
            this.showErrorModalNotification("Cette facture a déjà été traitée.")
        )
      });
  }

  componentWillUnmount(){
  }

  validate(stateCode)
  {
    this.setState({show_confirmation_modal: false})
    var formIsValid = this.htmlFormIsValid();
    if (formIsValid)
    {
        var dataToPost = {
            comment: this.getHtmlFormControlValue("comment"),
            invoice_number:  this.getHtmlFormControlValue("invoice_number")
        }

        this.businessService.validateTransporterManualInvoice(this, this.state.transporter_id, this.state.invoice.id, stateCode, dataToPost, (response) => {
            this.setState({confirmation: true})
            this.showSuccessModalNotification("", "La facture a été traitée et le transporteur en a été informé.")
        }, (httpErrorResponse) => {
            httpErrorResponse.json().then((apiResponse) => {
                this.showErrorFromResponse(apiResponse, null)
            })
            this.setState({ loading: false})
        })
    }
  }

  fileChangedHandler = (event) => {
    let file = event.target.files[0];
    let fileSize = file.size;
        
    if (((fileSize / 1024) / 1024) > 5)
    {
        this.setState({upload_file_alert: true})
    }
    else {
        this.setState({upload_file_alert: false, image_file: file})
    }
  }
  renderFormPart()
  {
    return <>
        {
            (typeof this.state.invoice.images[0].comment !== "undefined" && this.notEmptyValue(this.state.invoice.images[0].comment)) ?
            (
                <Row>
                    <Col lg="12">
                        <FormGroupInput label={"Commentaire du transporteur"}>
                            <DefaultLabel>
                                {this.state.invoice.images[0].comment}
                            </DefaultLabel>
                        </FormGroupInput>
                    </Col>
                </Row>
            ) : (<></>)
        }
        <Row>
            <Col lg="12">
                <FormGroupInput htmlFor="file" label={"Facture du transporteur"}>
                    <DefaultLink small={true} target="_blank" href={Config.bndApi + "/manual/invoice/" + this.state.invoice.id + "/transporter/" + this.state.transporter_id} >
                        Voir la facture du transporteur
                    </DefaultLink>
                </FormGroupInput>
            </Col>
        </Row>
        <Row>
            <Col lg="12">
                <FormGroupInput label={"Numéro de facture"} htmlFor="number"  type="inline">
                    <DefaultInput name="invoice_number" manager={this} required={true} />
                </FormGroupInput>
            </Col>
        </Row>
        <Row>
            <Col lg="12">
                <FormGroupInput htmlFor="file" label={"Commentaire éventuel"} type="inline">
                    <DefaultInput type="textarea" childRef={elt => this.commentInputRef = elt} rows="6"  name="comment" placeholder={"Indiquez ici les remarques à transmettre au transporteur en cas de refus de sa facture"} manager={this} />
                </FormGroupInput>
            </Col>
        </Row>
    </>
  }
  
    toggleConfirmationModal(event)
    {
        this.setState({show_confirmation_modal: !this.state.show_confirmation_modal})
    }

    showConfirmationModal(stateCode)
    {
        if (stateCode == "INVOICE_STATE_NOT_RECEIVED" && this.notEmptyValue(this.commentInputRef.getValue()) == false)
        {
            this.showWarningModalNotification("", "Merci de saisir un commentaire pour justifier votre refus")
        }
        else
        {
            this.setState({
                show_confirmation_modal: true,
                state_code: stateCode,
                confirmation_message: "Confirmez vous le passage de la facture à l'état \"" + this.translateMessage("Default." + stateCode) + "\""
            })
        }
    }


  renderConfirmationModal()
  {
      return <Modal className="modal-dialog-centered" isOpen={this.state.show_confirmation_modal} toggle={() => this.toggleConfirmationModal()}>
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
        <FormattedMessage id="Default.confirmation" />
        </h6>
        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleConfirmationModal()} >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <p>
          <FormattedMessage id={this.state.confirmation_message} />
        </p>
      </div>
      <div className="modal-footer">
        <Button className="ml-auto" color="secondary" data-dismiss="modal" type="button" onClick={() => this.toggleConfirmationModal()} >
          <FormattedMessage id="Default.no" />
        </Button>
        <Button color={"success"} type="button" onClick={(e) => this.validate(this.state.state_code)}>
          <FormattedMessage id="Default.yes" />
        </Button>
      </div>
    </Modal>
  }

  render() {
    
    return (
      <>
        {this.renderConfirmationModal()}
        {this.renderParent()}
        <AuthHeader
        />
        <Container className="m3 pb-5">
        <FormLoadingIndicator
          loading={this.state.loading}
        />
        <Row className="justify-content-center">
            <Col md="12">
                <Card className="bg-secondary border-0">
                    <CardBody>
                        {
                            (this.state.confirmation) ?
                            (
                                <></>
                            ) : 
                            (
                                (this.state.invoice) ?
                                (
                                    ( this.state.invoice.state.code != "INVOICE_STATE_WAITING_FOR_VALIDATION") ?
                                    (<></>)
                                    :(
                                        <>
                                            <Form onSubmit={e => this.onSubmitForm(e)} role="form">
                                                <InvoiceSummary 
                                                    orderId={this.state.order_id}
                                                    transporterId={this.state.transporter_id}
                                                    invoice={this.state.invoice} title="Appel à facturation : rappel des éléments à vérifier dans la facture">
                                                </InvoiceSummary>

                                                <CardTitle className="mb-3" tag="h3">
                                                Facture du transporteur
                                                </CardTitle>
                                                <CardText className="mb-4">
                                                {
                                                    (this.state.invoice) ?
                                                    (
                                                        this.renderFormPart()
                                                    ) : (<></>)
                                                }

                                                </CardText>

                                                <div className="text-center">
                                                <Button className="my-4" color="danger" type="button" onClick={(e) => this.showConfirmationModal("INVOICE_STATE_NOT_RECEIVED")}>
                                                    <FormattedMessage id='ManualInvoiceUpload.ko' defaultMessage={`Refuser la facture`} />
                                                </Button>
                                                <DefaultButton className="t2y-secondary-button small" type="button" onClick={(e) => this.showConfirmationModal("INVOICE_STATE_RECEIVED")}>
                                                    <FormattedMessage id='ManualInvoicValidation.ok' defaultMessage={`Accepter la facture`} />
                                                </DefaultButton>
                                                </div>
                                            </Form>
                                        </>
                                    )
                                )
                                :
                                (
                                    <></>
                                )
                            )
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(ManualInvoiceValidation);
