import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Table,
    Row,
    Col,
    Button,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import DefaultLabel from "../../components/common/defaultLabel";
import ReactSlidingPane from "react-sliding-pane";
import AddressWorkflow from "../../components/workflow/address/addressWorkflow";
import BusinessService from "../../../proxies/BusinessService";
import StringHelper from "../../../helpers/StringHelper";
import DefaultCheckbox from "../../components/common/defaultCheckbox";
import DefaultPopover from "../../components/common/defaultPopover";
import FormHourInput from "../components/form/FormHourInput"
import FormLoadingIndicator from "../components/FormLoadingIndicator";
import FormValidationComponent from "../components/FormValidationComponent";
import DefaultInput from "../../components/common/defaultInput";
import Resources from "../../../resources";
import DefaultButton from "../../components/common/defaultButton";
import GeocodingAndNavigationService from "../../../proxies/GeocodingAndNavigationService";
import ShipperService from "../../../proxies/ShipperService";
import Message from "../../components/common/message";
import Title4 from "../../components/common/title4";
import DeleteButton from "../../components/common/deleteButton";
import FormLegend from "../../components/form/formLegend";
import FormGroupInput from "../components/form/FormGroupInput";
import DefaultImage from "../../components/common/defaultImage";
import DefaultLink from "../../components/common/defaultLink";
import Separator from "../../components/common/separator";
import ArrayHelper from "../../../helpers/ArrayHelper";
import FreightTypeInput from "../../components/business/freightTypeInput";
import GoodItemLabelInput from "../../components/business/goodItemLabelInput";
import MissionStepAddressAndContactSelection from "../../components/business/missionStepAddressAndContactSelection";
import Codes from "../../../codes";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import FreightDocumentFreight from "./FreightDocumentFreight";

var moment = require('moment');
require('moment/locale/fr');

class TransportOperation extends FormValidationComponent {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.shipperService = new ShipperService();
        this.geocodingAndNavigationService = new GeocodingAndNavigationService();
        this.company = this.businessService.getCompany();

        this.validationRules = {
            external_id: {
                required: this.externalIdRequired()
            },
            good_label : {
                required: true
            },
            good_quantity : {
                required: true
            },
            good_weight: {
                required: true
            }
        }

        this.state = {
            id: null,
            loading: true,
            visible: true,
            arrivalTimeIsChecked: false,
            freight_edit_mode: false,
            freight_edited_item: null,
            transport_operation: null,
            internal_freight_id_tab: 'not-done',
            departure_address_tab: this.externalIdRequired() ? 'forbidden' : "not-done",
            arrival_address_tab: 'forbidden',
            freight_tab: 'forbidden',
            options_tab: 'forbidden',
            formDatas : {
                external_id: null
            },
            current: this.externalIdRequired() ? "internal_freight_id_tab" : "departure_address_tab",
        }
    }

    componentWillMount() {
        // Récupération des données de l'opération de transport
        if (this.props.id)
        {
            // Récupération des données de la lettre de voiture
            this.shipperService.getTransportOperationById(this, this.props.mission.id, this.props.id, (response) => {
                
                var transportOperation = response.instance;
                transportOperation.good_items.forEach(element => {
                    element.weight_unit_code = element.weight_unit.code
                });

                this.setState({
                    arrivalTimeIsChecked: transportOperation.arrival_time != null,
                    departureExpectedTimeIsChecked: transportOperation.departure_expected_time != null,
                    arrivalExpectedTimeIsChecked: transportOperation.arrival_expected_time != null,
                })

                // Mise à jour de l'état de l'écran
                this.updateState(transportOperation, true);
            })
        }
        else {
            this.setState({
                departureExpectedTimeIsChecked: this.props.firstTransportOperation && this.props.defaultOrderDepartureTime ? true : false,
                transport_operation: {
                    departure_expected_time: this.props.firstTransportOperation ? this.props.defaultOrderDepartureTime : null,
                    good_items: [],
                    no_arrival_contact_id: false,
                    no_departure_contact_id: false
                },
                loading: false
            }, () => {
                // Pré enregistrement de l'heure de prise de poste sur l'opération de transport
                if (this.props.firstTransportOperation && this.props.defaultOrderDepartureTime != null)
                {
                    this.submitOptions("departure_expected_time", this.props.defaultOrderDepartureTime)
                }
            })
        }
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    externalIdRequired()
    {
        const company = this.businessService.getCompany()
        return company.internal_freight_id_required
    }

    addressContactCompleted(transportOperation, prefix)
    {
        const address = transportOperation[prefix + "_address"];
        const contact = transportOperation[prefix + "_default_contact"];
        const noContactFlag = transportOperation["no_" + prefix + "_contact_id"];

        if (address && (contact != null || noContactFlag))
        {
            return true;
        }
        // Si c'est l'adresse d'un client de type particulier alors c'est bon
        else if (address && address.type && address.type.code == "ADDRESS_TYPE_CUSTOMER" && address.customer && address.customer.last_name && address.customer_company_address == false)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    updateState(transportOperation, next = false)
    {   
        const internalFreightIdTab = transportOperation.external_id ? "done" : "not-done";
        const departureAddressTab = this.addressContactCompleted(transportOperation, "departure") ? 'done' : 'not-done'
        const arrivalDepartureTab = departureAddressTab != "done" ? "forbidden" : (this.addressContactCompleted(transportOperation, "arrival") ? 'done' : 'not-done')
        const freightTab = departureAddressTab == "done" && arrivalDepartureTab == "done" ? "done" : "forbidden"

        this.setState(
            {
                transport_operation: transportOperation,
                internal_freight_id_tab: internalFreightIdTab,
                departure_address_tab: departureAddressTab,
                arrival_address_tab: arrivalDepartureTab,
                freight_tab: freightTab,
                //options_tab: transportOperation.arrival_address != null && transportOperation.good_items && transportOperation.good_items.length > 0 ? "not-done" : "forbidden"
            },
            () => {
                if (next)
                {
                    this.nextStep(transportOperation)
                }
            }
        )
        
    }

    isReadOnly()
    {
        return this.props.transporter || this.props.readonly
    }

    nextStep(transportOperation)
    {
        if(!transportOperation)
        {
            transportOperation = {}
        }

        var current = this.state.current;
        if (this.state.arrival_address_tab == "done")
        {
            current = "freight_tab"
        }
        else if (this.state.departure_address_tab == "done")
        {
            current = "arrival_address_tab"
        }
        else if (transportOperation.external_id || this.externalIdRequired() == false)
        {
            current = "departure_address_tab"
        }
        else {
            current = "internal_freight_id_tab"
        }

        this.setState({current: current})
    }


    allowDepartureAddressTab()
    {
        // En mode transporteur, on affiche l'onglet que si une adresse existe
        if (this.props.transporter)
        {
            return this.state.transport_operation && this.state.transport_operation.departure_address;
        }
        else // Sinon on se base sur la présence d'un identifiant obligatoire ou non
        {
            var externalIdRequired = this.externalIdRequired();
            
            return this.state.transport_operation && (
                (externalIdRequired && this.state.transport_operation.external_id) ||
                (!externalIdRequired)
            )
        }
    }

    allowArrivalAddressTab()
    {
        // En mode transporteur, on affiche l'onglet que si une adresse existe
        if (this.props.transporter)
        {
            return this.state.transport_operation && this.state.transport_operation.arrival_address;
        }
        else // Sinon on se base sur la présence de l'adresse de départ 
        {
            return this.state.transport_operation && this.state.transport_operation.departure_address;
        }
    }

    allowFreightTab()
    {
        return this.state.transport_operation && this.state.transport_operation.arrival_address;
    }

    allowOptions()
    {
        return this.state.transport_operation && this.state.transport_operation.arrival_address;
    }

    renderWizard()
    {
        if (this.state.current == "freight_tab" && this.state.freight_edit_mode ==  true)
            return <></>

        const externalIdExists = this.state && this.state.transport_operation && this.state.transport_operation.external_id

        return <>
            <Row className="stepper-container mt-5">
                <div className="stepper no-before">
                    {
                        (this.company.internal_freight_id_required || externalIdExists) ?
                        (
                            <div className={"stepper-step stepper-step-4 " + (this.state.internal_freight_id_tab) + (this.state.current == "internal_freight_id_tab" ? " current" : "")}>
                                <a onClick={(e) => { this.setState({current: "internal_freight_id_tab"}) }} 
                                class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                                data-placement="top" title="" data-original-title="Basic Information">
                                    <i className={Resources.reference} style={{fontSize: "30px"}}></i>
                                </a>
                                <DefaultLabel className="stepper-step-label" content={"Référence"}></DefaultLabel>
                            </div>
                        ) : (<></>)
                    }
                    <div className={"stepper-step stepper-step-4 " + (this.state.departure_address_tab) + (this.state.current == "departure_address_tab" ? " current" : "")}>
                        <a onClick={(e) => { if (this.state.departure_address_tab != "forbidden") { this.setState({current: "departure_address_tab"})}} } 
                        class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                        data-placement="top" title="" data-original-title="Basic Information">
                            <i className={Resources.loading} style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content={"Chargement"}></DefaultLabel>
                    </div>

                    <div className={"stepper-step stepper-step stepper-step-4 " + (this.state.arrival_address_tab)  + (this.state.current == "arrival_address_tab" ? " current" : "")}>
                        <a onClick={(e) => { if (this.state.arrival_address_tab != "forbidden") { this.setState({current: "arrival_address_tab"})}} } 
                        class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                        data-placement="top" title="" data-original-title="Basic Information">
                            <i className={Resources.unloading} style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content={"Déchargement"}></DefaultLabel>
                    </div>

                    <div className={"stepper-step stepper-step-4 " + (this.state.freight_tab) + (this.state.current == "freight_tab" ? " current" : "")}>
                        <a onClick={(e) => { if (this.state.freight_tab != "forbidden")  this.setState({current: "freight_tab"})} }
                        class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                        data-placement="top" title="" data-original-title="Basic Information">
                            <i class={Resources.freight} style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content="Marchandise">
                        </DefaultLabel>
                    </div>
                    {/*
                    <div className={"stepper-step stepper-step-5 " + (this.state.options_tab) + (this.state.current == "options_tab" ? " current" : "")}>
                        <a onClick={(e) => { if (this.allowOptions())  this.setState({current: "options_tab"})} }
                            class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                            data-placement="top" title="" data-original-title="Basic Information">
                            <i className="fas fa-cog" style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content="Options"></DefaultLabel>
                    </div>
                    */}
                </div>
            </Row>
        </>
    }

    // Affectation de l'adresse de chargement à la lettre de voiture
    submitDepartureAddress(address, callback)
    {
        // Mise en mémoire des informations
        var transportOperation = this.state.transport_operation;
        transportOperation.departure_address = address;

        // Enregistrement de l'information en base de données
        this.shipperService.saveOperationTransportDepartureAddress(this, {
            id: transportOperation.id,
            mission_id: this.props.mission.id,
            departure_address_id: address.id,
            departure_customer_address: address.customer ? (address.customer.id ? true : false) : false,
            departure_default_contact_id: address.default_contact_id
        }, (response) => {
            transportOperation.id = response.id;
            transportOperation.state = response.state;

            // Et passage des autres onglets en modifiable
            this.updateState(transportOperation)
            callback();

            //this.nextStep(transportOperation);
        })
    }

    // Affectation de l'adresse de déchargement à la lettre de voiture
    submitArrivalAddress(address, callback)
    {
        // Mise en mémoire des informations
        var transportOperation = this.state.transport_operation;
        transportOperation.arrival_address = address;
        
        this.shipperService.saveOperationTransportArrivalAddress(this, {
            id: transportOperation.id,
            mission_id: this.props.mission.id,
            arrival_address_id: address.id,
            arrival_customer_address: address.customer ? (address.customer.id ? true : false) : false,
            arrival_default_contact_id: address.default_contact_id
        }, (response) => {
            transportOperation.id = response.id;
            transportOperation.state = response.state;

            // Et passage des autres onglets en modifiable
            this.updateState(transportOperation)
            callback();
            //this.nextStep(transportOperation);
        })
    }

    submitOptions(key, value, description = null)
    {
        this.resetHtmlMessages(key);
        var transportOperation = this.state.transport_operation;
        transportOperation[key] = value;
        
        if (key == "external_id")
        {
            transportOperation.description = this.transportOperationDescriptionInputRef.getValue()
            if (this.emptyValue(transportOperation.external_id))
            {
                this.showHtmlError(key, StringHelper.translate(this, "Default.required_field"));
                return false;
            }
        }

        this.shipperService.saveOperationTransportOptions(this, 
            {
                id: transportOperation.id,
                mission_id: this.props.mission.id,
                arrival_time: transportOperation.arrival_time,
                call_of_the_driver: transportOperation.call_of_the_driver,
                departure_notification: transportOperation.departure_notification,
                eta_notification: transportOperation.eta_notification,
                notification_the_day_before: transportOperation.notification_the_day_before,
                external_id: transportOperation.external_id,
                description: transportOperation.description,
                departure_expected_time: transportOperation.departure_expected_time,
                arrival_expected_time: transportOperation.arrival_expected_time,
                no_arrival_contact_id: transportOperation.no_arrival_contact_id,
                no_departure_contact_id: transportOperation.no_departure_contact_id
                
            }, (response) => {
                // Mise à jour des données
                transportOperation.id = response.id
                this.setState({transport_operation: transportOperation})
                this.updateState(transportOperation)

                if (key == "external_id")
                {
                    this.nextStep(transportOperation)
                }
            }
        )
    }

    removeGoodFromTransportOperation(good)
    {
        var transportOperation = this.state.transport_operation;

        this.shipperService.deleteOperationTransportGoodItem(this, {
            id: transportOperation.id,
            mission_id: this.props.mission.id,
            good_id: good.id
        }, (response) => {
            transportOperation.good_items = ArrayHelper.removeArrayItem(good, transportOperation.good_items, ["id"]);
            this.updateState(transportOperation);

            this.showSuccessToastNotification("Suppression effectuée avec succès", "")
        });
    }

    confirmFreightGoodDeletion(good)
    {
        this.showModalConfirmation({
            title: "",
            component: <>Confirmez vous la suppression de cet élément ?</>,
            okButtonCallback: () => {
                this.removeGoodFromTransportOperation(good);
            }
        })
    }

    onArrivalTimeChecked() 
    {
        var checked = !this.state.arrivalTimeIsChecked;
        this.setState({arrivalTimeIsChecked: checked})

        if (checked == false)
        {
            this.submitOptions("arrival_time", null)
        }
    }

    submitGoodForm(e)
    {
        var transportOperation =  this.state.transport_operation;
        var formIsValid = this.htmlFormIsValid(null);

        if (formIsValid)
        {
            // Récupération des données postées
            var good = this.state.freight_edited_item;

            good.id = !good.id ? null : good.id;
            good.label = this.autocompleteLabelInputRef.getValue();//this.labelInputRef.getValue();
            good.amount = this.amountInputRef.getValue();
            good.quantity = this.quantityInputRef.getValue();
            good.weight = this.weightInputRef.getValue();
            good.weight_unit_code = this.weightUnitInputRef.getValue();
            good.description = this.descriptionInputRef.getValue();

            
            // Enregistrement en base de données
            this.shipperService.saveOperationTransportGoodItem(this, {
                id: transportOperation.id,
                mission_id: this.props.mission.id,
                good_id: good.id,
                good_label: good.label,
                good_amount: good.amount,
                good_quantity: good.quantity,
                good_weight: good.weight,
                good_weight_unit_code: good.weight_unit_code,
                good_description: good.description
            }, (response) => {
                // Mise en mémoire de la données
                good.id = response.id;
                good.state = response.state;
                transportOperation.good_items = ArrayHelper.replaceArrayItem(good, transportOperation.good_items, ["id"])
                // Information pour l'utilisateur
                this.showSuccessToastNotification("Modification enregistrée avec succès")
                // Mise à jour de l'écran
                this.updateState(transportOperation);
                this.setState({
                    freight_edited_item: null,
                    freight_edit_mode: false
                })
            })
        }
    }

    editGoodItem(good, editMode = true)
    {
        this.setState(
            {
                freight_edit_mode: editMode,
                freight_edited_item: (!good ? {id: null} : good)
            }, () => {

                if (good && good.id)
                {
                    this.autocompleteLabelInputRef.setValue(good.label)
                    //this.labelInputRef.setValue(good.label)
                    this.descriptionInputRef.setValue(good.description)
                    this.amountInputRef.setValue(good.amount)
                    this.quantityInputRef.setValue(good.quantity)
                    this.weightInputRef.setValue(good.weight)
                    this.weightUnitInputRef.setValue(good.weight_unit_code)
                }
            }
        )
    }

    renderDepartureAddressTab()
    {
        const address = this.state.transport_operation ? this.state.transport_operation.departure_address : null
        const contact = this.state.transport_operation ? this.state.transport_operation.departure_default_contact : null
        const context = Codes.CLASS_CONST_LOADING_CONTEXT
        
        return <>
             <Row className="mb-2">
                <Col md="12" lg="7" xl="5" style={{float:"right"}} className="float-right pt-2">
                    <DefaultCheckbox 
                        right={false}
                        key="departure_expected_time_ckb"
                        readonly={this.props.readonly}
                        id="use_departure_time"
                        checked={this.state.departureExpectedTimeIsChecked}
                        onChange={(e) => {
                            var checked = !this.state.departureExpectedTimeIsChecked;
                            this.setState({departureExpectedTimeIsChecked: checked})
                    
                            if (checked == false)
                            {
                                this.submitOptions("departure_expected_time", null)
                            }
                        }}
                        component={<>
                            <Title4 className="ml-2">&nbsp;Horaire d'arrivée souhaité
                            <DefaultPopover style={{position:"absolute"}} content="
                                Indiquez un horaire uniquement si il est imératif.
                                Le conducteur devra alors arriver à cet horaire pour cette étape">
                            </DefaultPopover> 
                            </Title4>
                            
                        </>}
                    >
                    </DefaultCheckbox>
                </Col>
                <Col md="12" lg="3" className="departure_time" style={{display: this.state.departureExpectedTimeIsChecked? "block": "none"}}>
                    <FormHourInput key="departure_expected_time_input"  name="departure_expected_time" value={this.state.transport_operation && this.state.transport_operation.departure_expected_time ? this.state.transport_operation.departure_expected_time.substring(0, 5) : null} onChange={(e) => {}} onBlur={(e) => { 
                        this.submitOptions("departure_expected_time", this.getHtmlFormControlValue("departure_expected_time"))}
                    }/>
                </Col>
            </Row>

            <MissionStepAddressAndContactSelection key="loading_address_and_contact"
                childRef={elt => this.departureAddressAndContactSelectionRef = elt}
                addressSelectionHeaderComponent={<><Message type="infos">Merci de préciser l'adresse de chargement</Message></>}
                contactSelectionHeaderComponent={<><Message type="infos">Merci de préciser le contact pour le chargement</Message></>}
                readOnly={this.props.transporter || this.props.readonly}
                contact={contact}
                address={address}
                context={context}
                skipContact={this.state.transport_operation.no_departure_contact_id}
                onAddressChangeRequest={() => {this.setState({departure_address_tab: "not-done"})}}
                onContactChangeRequest={() => {this.setState({departure_address_tab: "not-done"})}}
                onAddressSelection={(address) => this.submitDepartureAddress(address, () => {})}
                onContactSelection={(contact) => {
                    //Aucun contact de saisie
                    if (contact == null)
                    {
                        this.submitOptions("no_departure_contact_id", true)
                    }
                    else // Un contact de saisi, on met à jour l'opération de transport
                    {
                        var contactId = contact ? contact.id : null

                        var postedData = {
                            id: this.state.transport_operation.id,
                            mission_id: this.props.mission.id,
                            departure_contact_id: address.customer ? null : contactId,
                            departure_customer_address_contact_id: address.customer ? contactId : null
                        }

                        this.shipperService.saveDepartureAddressDefaultContact(this, postedData, (response) => {
                            var transportOperation = this.state.transport_operation
                            transportOperation.departure_default_contact = contact;
                            this.updateState(transportOperation)
                        })
                    }
                }}
            >
            </MissionStepAddressAndContactSelection>
            {
                (this.state.transport_operation.no_departure_contact_id && this.isReadOnly() == false && this.state.departure_address_tab == "done") ?
                (
                    this.renderNoContactSelected("no_departure_contact_id", () => this.setState({departure_address_tab: "not-done"}))
                ) : (<></>)
            }
            {
                (this.isReadOnly() || this.state.departure_address_tab != "done") ? (<></>) :
                (
                    <Row className="justify-content-center mt-2">
                        <DefaultButton className="t2y-secondary-button" onClick={(e) => this.nextStep(this.state.transport_operation)}>
                            {StringHelper.translate(this, "Default.workflow_next_button_label")}
                        </DefaultButton>
                    </Row>
                )
            }
        {/*
            <AddressWorkflow 
                forceContact={true}
                current_step={this.props.transporter ? "ADDRESS_SUMMARY_STEP": null}
                key="DEPARTURE_ADDRESS_WORKFLOW"
                id="DEPARTURE_ADDRESS_WORKFLOW"
                context={"MISSION_STEP_TYPE_LOADING"}
                parent={this}
                skipAddressConfirmation={true}
                addressSelectionComponent={<><Message type="infos">Merci de préciser l'adresse de chargement</Message></>}
                addressSelectionGeolocalisation={false}
                addressConfirmationAllowOperationalAddress={false}
                onAddressSubmited={(address, callback) => this.submitDepartureAddress(address, callback)}
                childRef={(elt) => this.departureAddressWorkflowRef = elt}
                address={this.state.transport_operation ? this.state.transport_operation.departure_address : null}
                readonly={this.props.transporter || this.props.readonly}
                customer={true}
                defaultContact={this.state.transport_operation.departure_default_contact}
                onDefaultContactChanged={(address, contact) => {
                    var contactId = contact ? contact.id : null

                    var postedData = {
                        id: this.state.transport_operation.id,
                        mission_id: this.props.mission.id,
                        departure_contact_id: address.customer ? null : contactId,
                        departure_customer_address_contact_id: address.customer ? contactId : null
                    }

                    this.shipperService.saveDepartureAddressDefaultContact(this, postedData, (response) => {})
                }}
            >
            </AddressWorkflow>*/}
        </>
    }

    renderArrivalAddressTab()
    {
        const address = this.state.transport_operation ? this.state.transport_operation.arrival_address : null
        const contact = this.state.transport_operation ? this.state.transport_operation.arrival_default_contact : null
        const context = Codes.CLASS_CONST_UNLOADING_CONTEXT
        
        return <>
            <Row className="mb-2">
                <Col md="12" lg="7" xl="5" style={{float:"right"}} className="float-right pt-2">
                    <DefaultCheckbox 
                        right={false}
                        key="arrival_expected_time_ckb"
                        readonly={this.props.readonly}
                        id="use_arrival_time"
                        checked={this.state.arrivalExpectedTimeIsChecked}
                        onChange={(e) => {
                            var checked = !this.state.arrivalExpectedTimeIsChecked;
                            this.setState({arrivalExpectedTimeIsChecked: checked})
                    
                            if (checked == false)
                            {
                                this.submitOptions("arrival_expected_time", null)
                            }
                        }}
                        component={<>
                            <Title4 className="ml-2">&nbsp;Horaire d'arrivée souhaité
                            <DefaultPopover style={{position:"absolute"}} content="
                                Indiquez un horaire uniquement si il est imératif.
                                Le conducteur devra alors arriver à cet horaire pour cette étape">
                            </DefaultPopover> 
                            </Title4>
                        </>}
                    >
                    </DefaultCheckbox>
                </Col>
                <Col md="12" lg="3" className="arrival_time" style={{display: this.state.arrivalExpectedTimeIsChecked? "block": "none"}}>
                    <FormHourInput key="arrival_expected_time_input"  name="arrival_expected_time" value={this.state.transport_operation && this.state.transport_operation.arrival_expected_time ? this.state.transport_operation.arrival_expected_time.substring(0, 5) : null} onChange={(e) => {}} onBlur={(e) => { 
                        this.submitOptions("arrival_expected_time", this.getHtmlFormControlValue("arrival_expected_time"))}
                    }/>
                </Col>
            </Row>
            
            <MissionStepAddressAndContactSelection key="unloading_address_and_contact"
                addressSelectionHeaderComponent={<><Message type="infos">Merci de préciser l'adresse de déchargement</Message></>}
                contactSelectionHeaderComponent={<><Message type="infos">Merci de préciser le contact pour le déchargement</Message></>}
                readOnly={this.props.transporter || this.props.readonly}
                contact={contact}
                address={address}
                context={context}
                skipContact={this.state.transport_operation.no_arrival_contact_id}
                onAddressChangeRequest={() => {this.setState({arrival_address_tab: "not-done"})}}
                onContactChangeRequest={() => {this.setState({arrival_address_tab: "not-done"})}}
                onAddressSelection={(address) => this.submitArrivalAddress(address, () => {})}
                onContactSelection={(contact) => {
                    //Aucun contact de saisie
                    if (contact == null)
                    {
                        this.submitOptions("no_arrival_contact_id", true)
                    }
                    else
                    {
                        var contactId = contact ? contact.id : null

                        var postedData = {
                            id: this.state.transport_operation.id,
                            mission_id: this.props.mission.id,
                            arrival_contact_id: address.customer ? null : contactId,
                            arrival_customer_address_contact_id: address.customer ? contactId : null
                        }

                        this.shipperService.saveArrivalAddressDefaultContact(this, postedData, (response) => {
                            var transportOperation = this.state.transport_operation
                            transportOperation.arrival_default_contact = contact;
                            this.updateState(transportOperation)
                        })
                    }
                }}
            >
            </MissionStepAddressAndContactSelection>


            {
                (this.state.transport_operation.no_arrival_contact_id  && this.isReadOnly() == false && this.state.arrival_address_tab == "done") ?
                (
                    this.renderNoContactSelected("no_arrival_contact_id", () => this.setState({arrival_address_tab: "not-done"}))
                ) : (<></>)
            }
            {
                (this.isReadOnly() || this.state.arrival_address_tab != "done") ? (<></>) :
                (
                    <Row className="justify-content-center mt-2">
                        <DefaultButton className="t2y-secondary-button" onClick={(e) => this.nextStep(this.state.transport_operation)}>
                            {StringHelper.translate(this, "Default.workflow_next_button_label")}
                        </DefaultButton>
                    </Row>
                )
            }
        </>
        /*
        return <>
            <AddressWorkflow 
                current_step={this.props.transporter ? "ADDRESS_SUMMARY_STEP": null}
                key="ARRIVAL_ADDRESS_WORKFLOW"
                id="ARRIVAL_ADDRESS_WORKFLOW"
                context={"MISSION_STEP_TYPE_UNLOADING"}
                parent={this}
                addressSelectionComponent={<><Message type="infos">Merci de préciser l'adresse de déchargement</Message></>}
                addressSelectionGeolocalisation={false}
                addressConfirmationAllowOperationalAddress={false}
                skipAddressConfirmation={true}
                onAddressSubmited={(address, callback) => this.submitArrivalAddress(address, callback)}
                childRef={(elt) => this.arrivalAddressWorkflowRef = elt}
                address={this.state.transport_operation ? this.state.transport_operation.arrival_address : null}
                readonly={this.props.transporter || this.props.readonly}
                customer={true}
                defaultContact={this.state.transport_operation.arrival_default_contact}
                onDefaultContactChanged={(address, contact) => {
                    var contactId = contact ? contact.id : null

                    var postedData = {
                        id: this.state.transport_operation.id,
                        mission_id: this.props.mission.id,
                        arrival_contact_id: address.customer ? null : contactId,
                        arrival_customer_address_contact_id: address.customer ? contactId : null
                    }

                    this.shipperService.saveArrivalAddressDefaultContact(this, postedData, (response) => {})
                }}
            >
            </AddressWorkflow>
        </>*/
    }

    renderFreightTab()
    {
        // Si on est en mode listing
        if (this.state.freight_edit_mode == false)
        {
            // Si aucune information n'est renseigné alors on met un message
            if (!this.state.transport_operation.good_items || this.state.transport_operation.good_items.length == 0)
            {
                if (this.state.transport_operation.state.code == "STATE_DRAFT" && this.props.readonly == false)
                {
                    return <>
                        <Message type="infos">
                            <Title4 className="text-justify">Vous n'avez pas encore détaillé la marchandise transportée</Title4>
                            <DefaultButton className="t2y-thirdparty-button" style={{float: "right"}} onClick={() => this.editGoodItem(null)}>
                                Commencer
                            </DefaultButton>
                        </Message>
                    </>
                }
                else {
                    return <Message type="infos">
                            <Title4 className="text-justify">Aucune marchandise n'a été précisée pour cette opération de transport</Title4>
                    </Message>
                }
            }
            else // Sinon on liste les lignes de détail 
            {
                return <>
                    {
                        (this.state.transport_operation && this.state.transport_operation.state.code == "STATE_DRAFT" && !this.props.readonly) ?
                        (
                            <Row className="justify-content-center">
                                <DefaultButton small={true} className="t2y-thirdparty-button" onClick={() => 
                                        this.setState(
                                            {
                                                freight_edit_mode: true,
                                                freight_edited_item: {id: null}
                                            }
                                        )
                                    }>
                                    Ajouter une nouvelle ligne
                                </DefaultButton>
                            </Row>
                        ) :  (<>
                            <Message type="warning">
                                La marchandise transportée ne peut plus être modifiée.
                            </Message>
                        </>)
                    }
                    <Separator></Separator>
                    <Table className="align-items-center t2y-table-fixed" responsive>
                        <thead className="thead-light">
                            <tr>
                            <th width="40%" scope="col">Libellé</th>
                            <th width="10%"  scope="col">Quantité</th>
                            <th width="15%"  scope="col">Poids unitaire</th>
                            <th width="15%"  scope="col">Valeur</th>
                            <th width="10%"  scope="col" />
                            </tr>
                        </thead>
                        <tbody>   
                    {
                        this.state.transport_operation.good_items.map((good, index) => {

                            return <tr className="t2y-clickable">
                                <th width="40%" scope="row"  onClick={(e) => this.editGoodItem(good)} style={{wordWrap: "break-word", whiteSpace:"initial"}}>
                                    <DefaultImage style={{paddingRight:10}} src={Resources["TRANSPORT_OPERATION_" + good.state.code]}></DefaultImage>
                                    {good.label}
                                </th>
                                <td width="10%"  onClick={(e) => this.editGoodItem(good)}>
                                    {good.quantity}
                                </td>
                                <td width="15%"  onClick={(e) => this.editGoodItem(good)}>
                                    {good.weight} {StringHelper.translate(this, good.weight_unit_code + ".short_label")}
                                </td>
                                <td width="15%"  onClick={(e) => this.editGoodItem(good)}>
                                    {good.amount ? (good.amount + "€") : ""}
                                </td>
                                <td width="10%">
                                    {
                                        (good.state.code == "STATE_DRAFT" && this.props.readonly == false) ?
                                        (
                                            <DeleteButton className="ml--3" label={false} onClick={(e) => this.confirmFreightGoodDeletion(good)}></DeleteButton>
                                        ) : (<></>)
                                    }
                                    
                                </td>
                            </tr>
                            
                        })
                    }
                    </tbody>
                    </Table>
                </>
            }
        }
        else // Sinon si on est en mode edition
        {
            return <Row>
                <Col lg="12">
                    <div className="pl-lg-4">
                        <FormLegend required={true} />
                        <Separator/>
                        
                        <Row>
                            <Col lg="12" style={{zIndex:9999}}>
                                <FormGroupInput label={"Marchandise transportée "} 
                                    htmlFor={"good_label"}
                                    type="multiline">
                                        <GoodItemLabelInput manager={this} name="good_label" required={true} childRef={elt => this.autocompleteLabelInputRef = elt} ></GoodItemLabelInput>
                                </FormGroupInput>
                            </Col>
                            {/*
                            <Col lg="12">
                                <FormGroupInput label={"Marchandise transportée "} 
                                    htmlFor={"good_label"}
                                    type="multiline">
                                    <DefaultInput required={true} childRef={(elt) => this.labelInputRef = elt} name="good_label" manager={this} />
                                </FormGroupInput>
                            </Col>*/}
                            <Col lg="12">
                                <FormGroupInput label={"Quantité"} htmlFor="good_quantity" type="inline">
                                    <DefaultInput type="number" 
                                        append={<><DefaultImage src={"fas fa-calculator"}></DefaultImage> </>}
                                    required={true} childRef={(elt) => this.quantityInputRef = elt}  name='good_quantity' manager={this} />
                                </FormGroupInput>
                            </Col>
                            <Col lg="10">
                                <FormGroupInput label={"Poids unitaire"} htmlFor="good_weight" type="inline">
                                    <DefaultInput type="number" 
                                        append={<><DefaultImage src={"fas fa-weight-hanging"}></DefaultImage> </>}
                                        required={true} childRef={(elt) => this.weightInputRef = elt}  name='good_weight' manager={this}>
                                    </DefaultInput>
                                    
                                </FormGroupInput>
                            </Col>
                            <Col lg="2">
                                <FormGroupInput htmlFor="good_weigt_unit" type="inline">
                                    <DefaultInput type="select" value="PROPERTY_UNIT_TONS"  childRef={(elt) => this.weightUnitInputRef = elt}  name='good_weigt_unit' manager={this}>
                                        <option key={"PROPERTY_UNIT_TONS"} value="PROPERTY_UNIT_TONS">Tonne(s)</option>
                                        <option key={"PROPERTY_UNIT_KG"} value="PROPERTY_UNIT_KG">Kg(s)</option>
                                    </DefaultInput>
                                    
                                </FormGroupInput>
                            </Col>
                            <Col lg="12">
                                <FormGroupInput label={"Valeur commerciale"} htmlFor="good_amount" type="inline">
                                    <DefaultInput type="number" 
                                        append={<><DefaultImage  src={Resources.form_euro}></DefaultImage> </>}
                                        required={false} childRef={(elt) => this.amountInputRef = elt}  name='good_amount' manager={this} />
                                </FormGroupInput>
                            </Col>
                            <Col lg="12">
                                <FormGroupInput label={"Description"} htmlFor="good_description" labelColumns="2" contentColumns="10" type="multiline">
                                    <DefaultInput rows="4" childRef={(elt) => this.descriptionInputRef = elt}  type="textarea" name='good_description' manager={this} />
                                </FormGroupInput>
                            </Col>
                        </Row>
                        {
                            (this.state.transport_operation.state.code == "STATE_DRAFT") ?
                            (
                                <>
                                    <Row className="justify-content-center">
                                        <DefaultButton className="t2y-secondary-button" onClick={(e) => this.submitGoodForm(e)}>Enregistrer</DefaultButton>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <DefaultLink small={true} onClick={(e) => this.editGoodItem(null, false)}>Annuler</DefaultLink>
                                    </Row>
                                </>
                            ) : (<></>)
                        }
                    </div>
                </Col>
            </Row>

        }
    }

    renderOptionsTab()
    {
        return <>
            <Row>
                <Col lg="12">
                    <DefaultCheckbox 
                        readonly={this.props.readonly}
                        id="use_arrival_time"
                        checked={this.state.arrivalTimeIsChecked}
                        onChange={(e) => this.onArrivalTimeChecked()}
                        content="Horaire impératif d'arrivée"
                        popover={<DefaultPopover content="
                            Indiquez un horaire uniquement si il est imératif.
                            Le conducteur devra alors arriver à cet horaire pour cette étape">
                        </DefaultPopover>}
                        >
                    </DefaultCheckbox>
                </Col>
                <Col lg="12" className="arrival_time" style={{display: this.state.arrivalTimeIsChecked? "block": "none"}}>
                    <FormHourInput name="arrival_time" value={this.state.transport_operation && this.state.transport_operation.arrival_time ? this.state.transport_operation.arrival_time.substring(0, 5) : null} onChange={(e) => {}} onBlur={(e) => { 
                        this.submitOptions("arrival_time", this.getHtmlFormControlValue("arrival_time"))}
                    }/>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col lg="12">
                    <DefaultCheckbox 
                        readonly={this.props.readonly}
                        id="notification_the_day_before"
                        onChange={(elt) => this.submitOptions("notification_the_day_before", !this.state.transport_operation.notification_the_day_before)}
                        childRef={(elt) => this.notificationTheDayBeforeRef = elt}
                        checked={this.state.transport_operation ? this.state.transport_operation.notification_the_day_before : false}
                        content="Notification la veille">
                    </DefaultCheckbox>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <DefaultCheckbox 
                        readonly={this.props.readonly}
                        id="departure_notification"
                        onChange={(elt) => this.submitOptions("departure_notification", !this.state.transport_operation.departure_notification)}
                        childRef={(elt) => this.departureNotificationRef = elt}
                        checked={this.state.transport_operation ? this.state.transport_operation.departure_notification : false}
                        content="Notification de départ">
                    </DefaultCheckbox>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <DefaultCheckbox 
                        readonly={this.props.readonly}
                        id="call_of_the_driver"
                        onChange={(elt) => this.submitOptions("call_of_the_driver", !this.state.transport_operation.call_of_the_driver)}
                        childRef={(elt) => this.callOfTheDriverRef = elt}
                        checked={this.state.transport_operation ? this.state.transport_operation.call_of_the_driver : false}
                        content="Appel préalable du chauffeur">
                    </DefaultCheckbox>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <DefaultCheckbox 
                        readonly={this.props.readonly}
                        id="eta_notification"
                        onChange={(elt) => this.submitOptions("eta_notification", !this.state.transport_operation.eta_notification)}
                        childRef={(elt) => this.etaNotificationRef = elt}
                        checked={this.state.transport_operation ? this.state.transport_operation.eta_notification : false}
                        content="Notification ETA -15 minutes">
                    </DefaultCheckbox>
                </Col>
            </Row>
        </>
    }

    renderExternalId()
    {
        const readOnly = this.props.transporter || this.props.readonly
        const company = this.businessService.getCompany();
        const label = company.internal_freight_id_label ? company.internal_freight_id_label : "Numéro de livraison"
        const comment = company.internal_freight_id_comment ? company.internal_freight_id_comment : null
        const required = company.internal_freight_id_required ? company.internal_freight_id_required : false

        return <>
            <Row>
                <Col lg="12">
                    <FormGroupInput label={label} htmlFor="external_id" type="multiline" tooltip={{message: comment}}>
                        <DefaultInput focus={true} readOnly={readOnly} required={required} name="external_id" value={this.state.transport_operation && this.state.transport_operation.external_id ? this.state.transport_operation.external_id : null}/>
                    </FormGroupInput>
                    <FormGroupInput label={"Description"} htmlFor="description" type="multiline">
                        <DefaultInput readOnly={readOnly} type="textarea" rows={6} name="description" childRef={elt => this.transportOperationDescriptionInputRef = elt} value={this.state.transport_operation && this.state.transport_operation.description ? this.state.transport_operation.description : null}/>
                    </FormGroupInput>
                </Col>
            </Row>
            {
                (readOnly) ? 
                (<></>) :
                (
                    <Row className="justify-content-center">
                        <DefaultButton className="t2y-secondary-button" onClick={(e) => 
                            this.submitOptions("external_id", this.getHtmlFormControlValue("external_id"), this.getHtmlFormControlValue("description"))}>
                            {StringHelper.translate(this, "Default.workflow_next_button_label")}
                        </DefaultButton>
                    </Row>
                )
            }
        </>
    }

    renderContent()
    {
        switch(this.state.current)
        {
            case "internal_freight_id_tab":
                return this.renderExternalId();
                break;
            case "departure_address_tab":
                return this.renderDepartureAddressTab();
            break;
            case "arrival_address_tab":
                return this.renderArrivalAddressTab();
            break;
            case "freight_tab":
                //return this.renderFreightTab();
                return <FreightDocumentFreight 
                    transportOperation={this.state.transport_operation} 
                    readOnly={this.isReadOnly()} 
                    onClose={ () => this.props.onHide()}
                    onChange={(items) => {
                        // Mise à jour des marchandise sur l'opération de transport
                        var transportOperation = this.state.transport_operation;
                        transportOperation.good_items = items;

                        this.setState({transport_operation: transportOperation})
                    }}
                ></FreightDocumentFreight>
            break;
            case "options_tab":
                return this.renderOptionsTab();
            break;
        }
    }

    renderNoContactSelected(field, callback = null)
    {
        return <>
            <Message type="infos" className="mt-2">
                Aucun contact n'est sélectionné pour cette étape de {field == "no_departure_contact_id" ? "chargement" : "déchargement"}.

                <DefaultButton className="t2y-thirdparty-button small" onClick={e => {
                        this.submitOptions(field, false)
                        if (callback)
                        {
                            callback()
                        }
                }}>
                    Sélectionner un contact
                </DefaultButton>
            </Message>
        </>
    }

    render() {
        return <>
            {this.renderParent()}
            <ReactSlidingPane
                className="some-custom-class"
                overlayClassName="some-custom-overlay-class"
                isOpen={this.state.visible}
                title={null}
                width={WebsiteHelper.getSlidingPanelWidth()}
                subtitle={null}
                shouldCloseOnEsc={false}
                onRequestClose={() => {
                    // triggered on "<" on left top click or on outside click
                    this.setState({ visible: false });

                    if (this.props.onHide) 
                        this.props.onHide(null)
                }}
            >
                <FormLoadingIndicator loading={this.state.loading}>
                <div className="container">
                        {this.renderWizard()}

                        {
                            (this.state.transport_operation) ?
                            (
                                this.renderContent()
                            ) : (<></>)
                        }
                        
                </div>
                </FormLoadingIndicator>

            </ReactSlidingPane>

        </>
    }
}


export default injectIntl(TransportOperation)

