
import React from "react";
import classnames from "classnames";
import queryString from 'query-string';
import Config from '../../../config.js';

// reactstrap components
import {
    Alert,
    Badge,
    Button,
    Input,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    Modal
  } from "reactstrap";
import Resources from "../../../resources";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
import ShipperService from '../../../proxies/ShipperService'
import { injectIntl , FormattedMessage } from 'react-intl';
import FormGroupInput from "../components/form/FormGroupInput";
import FormLoadingIndicator from "../components/FormLoadingIndicator";
import FormInput from "../components/FormInput";
import InjectedCreditCardCheckoutForm from "../components/payment/CreditCardOldForm";
import InjectedIbanCheckoutForm from "../components/payment/IbanForm";
import FormValidationComponent from "../components/FormValidationComponent"

import Ratings from "../../components/rating/rating"

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Rating from "../../components/rating/rating";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
const stripePromise = loadStripe(Config.stripePublicKey);


var moment = require('moment');
require('moment/locale/fr');

const SVGIcon = (props) =>
  <svg className={props.className} pointerEvents="none">
    <use xlinkHref={props.href} />
  </svg>;

class SearchResult extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.shipperService = new ShipperService();

        this.state = {
            showPaiementConfirmationModal: false,
            showLoadingResultModal : false,
            showTruckDetailModal: false,
            truckDetail: {},
            from: 1,
            nbResultsPerPage: 10,
            loading:true,
            searchInProgress: false,
            showOrderCreationModal: false,
            orderCreationMessage: null
        }
    }

    componentWillMount()
    {
        const parsedQueryString = queryString.parse(window.location.search);
        this.setState({
            nbResults: parsedQueryString.nb_results
        });
        this.getResults(this.state.from, this.state.nbResultsPerPage, true)
    }

    componentDidMount()
    {
        // Gestion des données bancaires

    }

    getResults(from, nb, showLoadingResultModal)
    {
        // Récupération de la queryString
        const parsedQueryString = queryString.parse(window.location.search);

        // Récupération des paramètres
        const searchId = this.props.match.params.searchId;
        this.setState({
            from: from,
            searchId: searchId,
            showLoadingResultModal: showLoadingResultModal,
            searchInProgress: true
        })

        // Affichage des résultats
        this.shipperService.getSearchResult(this, {
            searchId : searchId,
            from: from,
            nb: nb,
            order: document.getElementById("ddlOrderBy")? document.getElementById("ddlOrderBy").value : "rating~DESC"
        }, (response) => {
            this.setState({
                truckDetail: {
                    start_date: response.start_date,
                    end_date: response.end_date,
                    start_time: response.start_time,
                    half_day: response.half_day
                },
                results: response.results,
                showLoadingResultModal: false,
                searchInProgress: false
            })
        })
    }

    applyFilters(e)
    {
        this.shipperService.applyFilters(this, this.state.searchId, {
            cost_day_max: document.getElementById("cost_day_max").value,
            distance_max: document.getElementById("distance_max").value
        }, (response) => {
            this.setState({
                nbResults : response.nb_results
            });
           
            this.getResults(1, this.state.nbResultsPerPage, false);
        });
    }

    startPayment()
    {
        this.setState({
            showPaiementConfirmationModal: false,
            //showTruckDetailModal: false,
            loading: true,
            showOrderCreationModal: true,
            orderCreationMessage: "Paiement de l'acompte"
        });
    }

    paymentError(error)
    {
        this.setState({
            showOrderCreationModal: false
        })
        alert(error);
    }

    /**
     *  paymentInfos: {
            amoutPaiedTtc: ttcAmount,
            amoutPaiedHt: htAmount,
            paymentExternalId: paymentIntentId,
            paymentMethodExternalId: paymentMethodId
        }
     */
    createOrder(paymentInfos)
    {
        this.setState({
            showPaiementConfirmationModal: false,
            //showTruckDetailModal: false,
            loading: true,
            showOrderCreationModal: true,
            orderCreationMessage: "Création de la commande"
        });
        
        var postedDatas = {
            address_id: this.state.truckDetail.shipper_address ? this.state.truckDetail.shipper_address.id : null,
            latitude: this.state.truckDetail.shipper_address ? this.state.truckDetail.shipper_address.gps_lat : null,
            longitude: this.state.truckDetail.shipper_address ? this.state.truckDetail.shipper_address.gps_long : null,

            // Date de début
            start_date : moment(this.state.truckDetail.start_date.date).format("DD/MM/YYYY"),
            // Date de fin
            end_date: moment(this.state.truckDetail.end_date.date).format("DD/MM/YYYY"),
            // demi journée
            half_day: this.state.truckDetail.half_day,
            // Heure de début
            arrival_time: this.state.truckDetail.start_time,
            // Samedi inclu
            use_saturday : false,
            // Dimanche inclu
            use_sunday: false,
            // identifiant du camion
            truck_id: this.state.truckDetail.truck_id,
            // identifiant de la recherche pour tracer la réussite de la recherche
            search_id: this.state.searchId
        }
        // Création de la commande  
        this.shipperService.createOrder(this, postedDatas, (response) => {
            const orderId = response.id;
            this.setState({orderCreationMessage: "Validation de la commande"});

            // Validation de la commande
            this.shipperService.validateOrder(this, orderId, {}, (response) => {
                this.setState({orderCreationMessage: "Génération de la facture d'acompte"});


                // Enregistrement du payment
                this.shipperService.createRetainerInvoice(this, orderId, {
                    id: orderId,
                    retainer_amount_ttc: paymentInfos.amountPaiedTtc,
                    retainer_amount_ht: paymentInfos.amountPaiedHt,
                    payment_external_id: paymentInfos.paymentExternalId,
                    payment_method_external_id: paymentInfos.paymentMethodExternalId
                }, (response) => {
                    this.setState({orderCreationMessage: "Redirection vers la commande"});
                    setTimeout(() => {
                        // Confirmation de la commande
                        const url = "/admin/orders?orderId=" + orderId + "&date=" + moment(this.state.truckDetail.start_date.date).format("YYYY-MM-DD");
                        WebsiteHelper.goTo(this, url)
                    }, 2000) 
                });
            })
            
        });
    }

    renderLoadingResultModal()
    {
        return <Modal key={"loadingResultModal"} className="modal-dialog-centered" isOpen={this.state.showLoadingResultModal}>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
              Chargement
          </h6>
        </div>
        <div className="modal-body">
          <p>
          Affichage des {this.state.nbResults}  résultat(s)
          </p>
        </div>
      </Modal>
    }

    renderOrderCreationModal()
    {
        return <Modal key={"orderCreationModal"} className="modal-dialog-centered" isOpen={this.state.showOrderCreationModal}>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
              Traitement en cours
          </h6>
        </div>
        <div className="modal-body">
          <p>
          {this.state.orderCreationMessage}
          </p>
        </div>
      </Modal>
    }

    renderPagination()
    {   
        const divideResult = this.state.nbResults / this.state.nbResultsPerPage 
        const nbButtons = Math.floor(divideResult) + ((divideResult - Math.floor(divideResult)) > 0 ? 1 : 0);
        const nbButtonsToShow = 10;

        if (nbButtons == 0)
        {
            return "Pas de résultats"
        }
        else{
            var buttonIndexes = [];
            
            if (nbButtons > nbButtonsToShow)
            {

                var min = this.state.from - 5;
                if (min < 1)
                {
                    min = 1;
                }

                var max = this.state.from + 5;
                if (max > nbButtons)
                {
                    max = nbButtons;
                }

                if (min > 1)
                {
                    buttonIndexes.push(1);
                }
                if (min > 2)
                {
                    buttonIndexes.push("...");
                }

                // Premiers boutons
                for(var i = min; i <= max ; i++)
                {
                    buttonIndexes.push(i);
                }

                if (max < nbButtons - 1)
                {
                    buttonIndexes.push("...");
                }
                if (max < nbButtons)
                {
                    buttonIndexes.push(nbButtons);
                }
            }
            else
            {
                for(var i = 0; i < nbButtons; i++)
                {
                    buttonIndexes.push(i + 1);
                }    
            }

            
            return <nav aria-label="...">
                <Pagination  className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
                <PaginationItem className={this.state.from > 1 ?"":  "disabled"}>
                    <PaginationLink
                    href="#pablo"
                    onClick={e => this.getResults(1, this.state.nbResultsPerPage, false)}
                    tabIndex="-1"
                    >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {
                    buttonIndexes.map(index => {
                        return <>
                            <PaginationItem className={(index == this.state.from ? "active" : "") + " " + (index == "..." ? "disabled": "")}>
                                <PaginationLink href="#pablo" onClick={e => this.getResults(index, this.state.nbResultsPerPage, false)}>
                                {index}
                                </PaginationLink>
                            </PaginationItem>
                        </>
                    })
                }
                
                <PaginationItem className={this.state.from < nbButtons ?"":  "disabled"}>
                    <PaginationLink
                    href="#pablo"
                    onClick={e => this.getResults(nbButtons, this.state.nbResultsPerPage, false)}
                    >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
                </Pagination>
            </nav> 
        }
    }

    showTruckDetailModal(truckId)
    {
        // Récupération des données du camion
        this.shipperService.getSearchResultDetail(this, this.state.searchId, truckId, (response) => {
            
            // Récupération des données
            this.setState({
                truckDetail: response.instance,
                showTruckDetailModal: true
            })
        })
    }

    toggleTruckDetailModal(e)
    {
        this.setState({
            showTruckDetailModal: !this.state.showTruckDetailModal
        })
    }

    showPaiementConfirmationModal()
    {
        this.setState({
            showPaiementConfirmationModal: true
        })
    }

    togglePaiementConfirmationModal(e)
    {
        this.setState({
            showPaiementConfirmationModal: !this.state.showPaiementConfirmationModal
        })
    }

    renderRating(rating, color)
    {
        return <Rating note={parseFloat(rating)} type={"default"} color={color} />
    }

    renderEnvironmentalNote(rating, color)
    {
        return <Rating note={parseFloat(rating)}  type={"leaf"} color={color} />
    }   

    renderPaiementConfirmationModal()
    {
        return <Modal className="modal-dialog-centered" isOpen={this.state.showPaiementConfirmationModal} toggle={() => this.togglePaiementConfirmationModal()}>
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                    Confirmation de création de la commande
                    </h6>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.togglePaiementConfirmationModal()} >
                    <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>
                    Confirmez vous la création de la commande de :<br/>
                    <strong>{this.state.truckDetail.amount_to_pay_ht ? this.state.truckDetail.amount_to_pay_ht : ""} € HT </strong>
                    &nbsp;pour&nbsp;<strong>{this.state.truckDetail.nb_days_used ? this.state.truckDetail.nb_days_used : ""} Jours</strong>
                    </p>
                </div>
                <div className="modal-footer">
                    <Button color="success" type="button" onClick={this.createOrder.bind(this)}>
                    <FormattedMessage id="Default.yes" />
                    </Button>
                    <Button className="ml-auto" color="secondary" data-dismiss="modal" type="button" onClick={() => this.togglePaiementConfirmationModal()} >
                    <FormattedMessage id="Default.no" />
                    </Button>
                </div>
            </Modal>
    }

    renderDateLabel()
    {
        const startDate = this.state.truckDetail.start_date ? moment(this.state.truckDetail.start_date.date).format("DD/MM/YYYY") : "";
        const endDate = this.state.truckDetail.end_date ? moment(this.state.truckDetail.end_date.date).format("DD/MM/YYYY") : "";
        const startTime = this.state.truckDetail.start_time ? this.state.truckDetail.start_time.substring(0, 5) :"07:00";
        const halfDay = this.state.truckDetail.half_day;
        var dateLabel = "Du " + startDate + " au " + endDate;
        if (halfDay)
        {
            dateLabel = "Demi-journée du " + startDate;
        }
        else if (startDate == endDate)
        {
            dateLabel = "Journée du " + startDate;
        }

        return dateLabel;
    }

    renderTruckDetailModal()
    {
        if (!this.state.truckDetail)
            return "";
        const startTime = this.state.truckDetail.start_time ? this.state.truckDetail.start_time.substring(0, 5) :"07:00";

        return <Modal key={"truckDetailModal"} className="modal-dialog-centered  modal-xl" isOpen={this.state.showTruckDetailModal} toggle={(e) => this.toggleTruckDetailModal(e)}>
            <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">

            </h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={(e) => this.toggleTruckDetailModal(e)} >
                <span aria-hidden={true}>×</span>
            </button>
            </div>
            <div className="modal-body">
                    <Row>
                        <Col xl="6">
                            <Card>
                                <CardHeader className="border-0">
                                    <h3 className="mb-0">Votre sélection</h3>
                                </CardHeader>
                                <CardBody>
                                    <FormGroupInput label="Date(s) demandée(s)">

                                    </FormGroupInput>
                                    <FormGroupInput label="Heure de départ">
                                        <label>{startTime.replace(":", "h")}</label>
                                    </FormGroupInput>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader className="border-0">
                                    <h3 className="mb-0">Notre proposition</h3>

                                    <div className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
                                        <strong>
                                        {this.state.truckDetail.amount_to_pay_ht ? this.state.truckDetail.amount_to_pay_ht : ""} € HT
                                        </strong>
                                        &nbsp;pour&nbsp;
                                        <strong>
                                        {this.state.truckDetail.nb_days_used ? this.state.truckDetail.nb_days_used : ""} Jour(s) de location
                                        </strong>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    {
                                        (this.state.truckDetail.properties) ? 
                                        (
                                            this.state.truckDetail.properties.map(propertyValue => {
                                                return <FormGroupInput label={propertyValue.property_label}>
                                                    <label>{propertyValue.value}</label>
                                                </FormGroupInput>
                                            })
                                        ) : ("")
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="6">
                            <Card>
                                <CardHeader className="border-0">
                                    <h3 className="mb-0">Information complémentaire</h3>
                                </CardHeader>
                                <CardBody>
                                    <label className="form-control-label">
                                        Le forfait journée comprend:
                                        <br/>
                                        <label>{this.state.truckDetail.bnd_km_per_day} kilomètres</label>
                                        <br/>
                                        <label>{this.state.truckDetail.bnd_hours_per_day} heures de travail (*)</label>
                                    </label>
                                    <hr/>
                                    <FormGroupInput label="Km supplémentaire">
                                        <label>{this.state.truckDetail ? this.state.truckDetail.cost_km: ""} €</label>
                                    </FormGroupInput>
                                    <FormGroupInput label="Heure supplémentaire">
                                        <label>{this.state.truckDetail ? this.state.truckDetail.cost_hour : ""} €</label>
                                    </FormGroupInput>
                                    <FormGroupInput label="Distance d'approche">
                                        <label>{this.state.truckDetail ? Math.round(this.state.truckDetail.distance) : ""} Km</label>
                                    </FormGroupInput>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row  className="justify-content-center">
                        <Col xl="6">
                            <Card>
                                <CardHeader className="border-0">
                                    <h3 className="mb-0">Mode de paiement carte</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row className="justify-content-center">
                                        <Col xs="12" xl="6">
                                            <Elements stripe={stripePromise}>
                                                <InjectedCreditCardCheckoutForm 
                                                    ttcAmount={this.state.truckDetail.retainer_amount_to_pay_ttc} 
                                                    htAmount={this.state.truckDetail.retainer_amount_to_pay_ht}
                                                    onStart={() => this.startPayment()} 
                                                    onSuccess={(paymentInfos) => this.createOrder(paymentInfos)} 
                                                    onError={(error) => this.paymentError(error)} 
                                                />
                                            </Elements>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="6">
                            <Card>
                                <CardHeader className="border-0">
                                    <h3 className="mb-0">Mode de paiement IBAN</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row className="justify-content-center">
                                        <Col xs="12" xl="6">
                                            <Elements stripe={stripePromise}>
                                                <InjectedIbanCheckoutForm 
                                                    ttcAmount={this.state.truckDetail.retainer_amount_to_pay_ttc} 
                                                    htAmount={this.state.truckDetail.retainer_amount_to_pay_ht}
                                                    onStart={() => this.startPayment()} 
                                                    onSuccess={(paymentInfos) => this.createOrder(paymentInfos)} 
                                                    onError={(error) => this.paymentError(error)} 
                                                />
                                            </Elements>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                
            </div>
            {
                /*
                <div className="modal-footer">
                <Button className="ml-auto" color="secondary" data-dismiss="modal" type="button" onClick={(e) => this.toggleTruckDetailModal(e)} >
                    <FormattedMessage id="Default.close" />
                </Button>
                </div>
                */
            }
            
        </Modal>
    }

    renderSortIcon(key, label)
    {
        var currentValue = document.getElementById("ddlOrderBy") ? document.getElementById("ddlOrderBy").value : "";
        var items = currentValue.split('~');
        if (items[0] == key)
        {
            if(items[1] == "ASC")
            {
                return <><strong><i class="fas fa-sort-up"></i>&nbsp;{label}</strong></>
            }
            else
            {
                return <><strong><i class="fas fa-sort-down"></i>&nbsp;{label}</strong></>
            }
        }
        else
        {
            return label;
        }
    }

    render() {
        return  (
            <>
            {this.renderParent()}
            {this.renderLoadingResultModal()}
            {this.renderTruckDetailModal()}
            {this.renderPaiementConfirmationModal()}
            {this.renderOrderCreationModal()}
            <SimpleHeader name={"Votre recherche: " + this.renderDateLabel()} description={""} icon={Resources.search_result_logo}  />
            <Container className="mt--6" fluid>
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>

            <Row>
                <div className="col">
                <Card>
                    <CardHeader className="border-0">
                    <h3 className="mb-0">{this.state.nbResults} Résultat(s)</h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl="4">
                                <Card className="bg-secondary">
                                    <CardHeader className="border-0">
                                    <Row>
                                        <Col xs="4">
                                    <h3 className="mb-0">Trier par</h3>
                                    </Col>
                                        
                                            <Col xs="8">
                                                <Input type="select" id="ddlOrderBy" className="form-control" onChange={(e) => this.getResults(1, this.state.nbResultsPerPage, false)}>
                                                    <option value="distance~ASC">Distance croissante</option>
                                                    <option value="distance~DESC">Distance décroissante</option>
                                                    <option value="cost_day~ASC">Prix croissant</option>
                                                    <option value="cost_day~DESC">Prix décroissant</option>
                                                    <option value="rating~ASC">Satisfaction croissante</option>
                                                    <option selected={true} value="rating~DESC">Satisfaction décroissante</option>
                                                    <option value="environmental_note~ASC">Note environnementale croissante</option>
                                                    <option value="environmental_note~DESC">Note environnementale décroissante</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                    
                                    </CardHeader>
                                </Card>
                                <Card className="bg-secondary">
                                    <CardHeader className="border-0">
                                    <h3 className="mb-0">Filtres</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xl="12">
                                                <FormGroupInput label="Montant Max" htmlFor="cost_day_max">
                                                    <FormInput type="number" append={"<i class='fas fa-euro-sign'></i>"} name='cost_day_max' manager={this} />
                                                </FormGroupInput>
                                            </Col>
                                            <Col xl="12">
                                                <FormGroupInput label="Distance Max" htmlFor="distance_max">
                                                    <FormInput type="number" append={"/ km"} name='distance_max' manager={this} />
                                                </FormGroupInput>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Button type="button" onClick={(e) => this.applyFilters(e)}>Affiner ma recherche</Button>
                                    </CardFooter>

                                </Card>
                            </Col>
                            <Col xl="8">
                                {
                                    ((this.state.results && this.state.results.length > 0) || this.state.showLoadingResultModal) ? 
                                    (
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                <th scope="col">{this.renderSortIcon("rating", "Satisfaction")}</th>
                                                <th scope="col">{this.renderSortIcon("cost_day", "Prix")}</th>
                                                <th scope="col">{this.renderSortIcon("distance", "Distance")}</th>
                                                <th scope="col">Km Supp</th>
                                                <th scope="col">H Supp</th>
                                                <th scope="col">{this.renderSortIcon("environmental_note", "Note env.")}</th>
                                                <th scope="col" />
                                                </tr>
                                            </thead>
                                            
                                            <tbody className="list">
                                            {
                                                (this.state.results) ?
                                                (
                                                    this.state.results.map(result => {
                                                        return <tr className="m-1">
                                                            <td>
                                                            {this.renderRating(result.rating, "#FFD433")}
                                                            </td>
                                                            <td>{result.cost_day} €</td>
                                                            <td>{Math.round(result.distance)} Km</td>
                                                            <td>{result.cost_km} €</td>
                                                            <td>{result.cost_hour} €</td>
                                                            <td>{this.renderEnvironmentalNote(result.environmental_note, "#80FF33")}</td>
                                                            <td>
                                                                <Button type="button" color="secondary" onClick={(e) => this.showTruckDetailModal(result.truck_id)}><i class="fa fa-search" />Voir le détail</Button>
                                                            </td>
                                                        </tr>
                                                    })
                                                ) : ("")
                                            }
                                            </tbody>
                                        </Table>
                                    )
                                    :
                                    (
                                        <Row className="text-center">
                                            <Col xl="12">
                                            <Alert color="warning">
                                            <strong>Désolé,</strong> aucun camion disponible pour votre recherche...
                                            </Alert>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="py-4">
                    {this.renderPagination()}
                    </CardFooter>
                </Card>
                </div>
            </Row>
          
                

            </Container>
          </>
        );
    }
}


export default injectIntl(SearchResult)