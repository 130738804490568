import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    Container
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import SecurityService from "../../../../proxies/SecurityService";
import DefaultInput from "../../common/defaultInput";
import Resources from "../../../../resources";
import Title2 from "../../common/title2"
import Title3 from "../../common/title3"
import Step from "../step";
import PasswordInput from "../../common/passwordInput";
import DefaultButton from "../../common/defaultButton";
import FormGroupInput from "../../../pages/components/form/FormGroupInput";

var moment = require('moment');
require('moment/locale/fr');

class ContactHomeStep extends Step {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.securityService = new SecurityService();

        this.validationRules = {
        }

        this.state = {
        }
    }

    componentWillMount() {
        super.componentWillMount()
    }

    componentDidMount() {
        super.componentDidMount()
    }

    submit(pid)
    {
        var formIsValid = this.htmlFormIsValid();
        if (formIsValid)
        {
            super.submit(pid)
        }
    }

    render() {
        return (
            <>
            <Container>
            <Row> 
                    Page de contact
                    
                </Row>
                
                
            </Container>
            </>
        );
    }
}


export default injectIntl(ContactHomeStep)

