import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";

import DefaultInput from "../../common/defaultInput";
import PasswordInput from "../../common/passwordInput";
import Resources from "../../../../resources";
import Title2 from "../../common/title2"
import Title3 from "../../common/title3"


import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import SecurityService from "../../../../proxies/SecurityService";
import UserState from '../../user/userState'
import Step from "../step";
import StringHelper from "../../../../helpers/StringHelper";

var moment = require('moment');
require('moment/locale/fr');

class ForgetPasswordStep extends Step {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.securityService = new SecurityService();
        this.validationRules = {
            email : {
                required : true,
                rules : [
                { 
                    rule : this.email.bind(this),
                    ruleMessage : this.translateMessage("Default.invalid_email")
                }
                ]
            }
        }

        this.state = {
            userError: false
        }
    }

    componentWillMount() {
        super.componentWillMount()
    }

    componentDidMount() {
        super.componentDidMount()

        if (this.props.email && StringHelper.notEmptyValue(this.props.email))
        {
            document.getElementById("email").value = this.props.email;
        }
        else if (this.props.workflow.state.input)
        {
            document.getElementById("email").value = this.props.workflow.state.input;
        }

        document.getElementById("email").focus();
    }

    componentWillUnmount() {
        super.componentWillUnmount()
    }

    submit(pid)
    {
        var formIsValid = this.htmlFormIsValid(null);

        if (formIsValid)
        {
            super.submit(pid)

            const email = this.getHtmlFormControlValue("email");
            const url = this.props.workflow.props.workflow ? this.props.workflow.props.workflow.getUrl() : null
            
            this.securityService.forgetPasswordWithEmailAndUrl(this, email, url, (response) => {
                this.props.workflow.goToNextStep(pid)
            }, (httpResponse) => {
                this.setState({loading: false})

                httpResponse.json().then(apiResponse =>
                {
                    if (apiResponse.user != null)
                    {
                        this.setState({userError: true, userId: apiResponse.user.id, nexts: false, links: false})
                        this.props.workflow.reload();
                    }
                    else 
                    {
                        const errorTitle = this.translateMessage("DEFAULT_ERROR_MESSAGE");
                        const errorMessage = this.translateMessage("ForgetPassword.bad_email");
                        this.showError(errorMessage, errorTitle)
                    }
                })
            });
        }
    }
    
    render() {
        if (this.state.userError)
        {
            return <UserState id={this.state.userId}></UserState>
        }

        return (
            <>
            {this.renderParent()}
            <FormLoadingIndicator loading={this.state.loading} />
            <Container>
                <Row> 
                    <Col lg="12" md="12" className="text-center justify-content-text-center order-lg-1 order-1">
                        <img src={require('../../../../assets/img/illustrations/accountWorkflow_forgetPasswordStep.svg').default} style={{width:"50%"}} />
                    </Col>
                    <Col lg="12" md="12" className="order-lg-1 order-2">
                        <Row className="mb-3">
                            <Col>
                            <Title2 content={this.props.intl.formatMessage({"id" : "ForgetPassword.title1"})}></Title2>
                            <Title3>
                                <FormattedMessage id='ForgetPassword.title2'>{this.decodeEntities}</FormattedMessage>
                            </Title3>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <DefaultInput id="email" 
                                    childRef={(elt) => this.emailInputRef = elt} 
                                    icon={Resources.form_email_icon} placeholder={"votre email"}>
                                </DefaultInput>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container>
            </>
        );
    }
}


export default injectIntl(ForgetPasswordStep)

