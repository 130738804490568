import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import Step from "../step"

var moment = require('moment');
require('moment/locale/fr');

class RegisterCompanyStep extends Step {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.state = {
        }
    }

    componentWillMount() {
        super.componentWillMount()
    }

    componentDidMount() {
        super.componentDidMount()
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }
    
    render() {
        return (
            <>
                Confirmation de l'enregistrement des données de la société
            </>
        );
    }
}


export default injectIntl(RegisterCompanyStep)

