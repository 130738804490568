import React from "react";

import {
	Row,
	Col,
	Modal,
	Button,
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import RepositoryService from '../../../proxies/RepositoryService'
import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator";
import ImageWithRescue from "../../pages/components/Image/ImageWithRescue";
import ReactBSAlert from "react-bootstrap-sweetalert";
import DefaultLink from "../common/defaultLink";
import DefaultButton from "../common/defaultButton";
import DefaultImage from "../common/defaultImage";
import DefaultLabel from "../common/defaultLabel";
import Message from "../common/message";
import StringHelper from "../../../helpers/StringHelper";

class PortalTruckSearchInput extends React.Component {

	constructor(props) {
		super(props);

		this.repositoryService = new RepositoryService();

		this.state = {
			modal: false,
			summary: null,

			current_list: "PROPERTY_DOMAIN",
			current_values: [],
			current_selection: [],
			render_values: false,

			lists: {
				"PROPERTY_DOMAIN": {
					property_code: "DOMAIN",
					level: 0,
					selected: [],
					values: [],
					previous: null,
					next: "PROPERTY_BODY",
					multi: false,
					icon: true
				},
				"PROPERTY_BODY": {
					property_code: "BODY",
					level: 1,
					selected: [],
					values: [],
					previous: "PROPERTY_DOMAIN",
					next: this.isPublic() && !this.props.embeded  ? null : "PROPERTY_SILHOUETTE",
					//next: this.isPublic() && !this.useMultipleSelection()? null : "PROPERTY_SILHOUETTE",
					//next: "PROPERTY_SILHOUETTE",
					multi: this.useMultipleSelection(),
					icon: true
				},
				"PROPERTY_SILHOUETTE": {
					property_code: "SILHOUETTE",
					level: 2,
					selected: [],
					values: [],
					previous: "PROPERTY_BODY",
					next: "PROPERTY_PTAC",
					multi: this.useMultipleSelection(),
					icon: true
				},
				"PROPERTY_PTAC": {
					property_code: "PTAC",
					level: 3,
					selected: [],
					values: [],
					previous: "PROPERTY_SILHOUETTE",
					next: null,
					multi: this.useMultipleSelection(),
					icon: false
				}
			}
		}
	}

	componentWillMount() {
		
	}

	componentDidMount() {
		this.props.childRef(this)
	}

	getSummary()
	{
		if (this.state.current_selection.length > 0)
		{
			return this.state.current_selection.length + " véhicule(s) selectionné(s)"
		}
		else
		{
			return null;
		}
	}

	getListSelectedValuesAsString(list, truncate = 0)
	{
		var summary = "";
		var remaining = list.selected.length
		if (list.selected.length > 0)
		{
			list.selected.forEach(item => {
				if (truncate == 0)
				{
					summary += item.label + ","
					remaining -= 1
				}
				else
				{
					var newSummary = summary + item.label
					if (newSummary.length < truncate)
					{
						summary += item.label + ","
						remaining -= 1;
					}
				}
			})

			summary = summary.substring(0, summary.length - 1)
			if (remaining > 0)
			{
				summary += " (+" + remaining + ")"
			}
		}

		return summary
	}

	getSelectedValues(options = {})
	{
		options.truncate = !options.truncate ? 0 : options.truncate;
		options.asHtml = !options.asHtml ? false : options.asHtml;
		
		var lists = this.state.lists;
		var currentList = lists[this.state.current_list];

		var currentSummary = "";
		var lines = [];
		if (currentList)
		{

			for(var i = currentList.level; i > 0; i--)
			{
				var tmpSummary = this.getListSelectedValuesAsString(currentList, options.truncate)
				
				currentSummary = tmpSummary + "\n" + currentSummary

				currentList = lists[currentList.previous];
			}
		}

		return options.asHtml ? StringHelper.decodeEntities([currentSummary], "p", "t2y-decode-entities-defaul-p") : currentSummary

		//return currentSummary
	}

	getValues()
	{
		// On retourne les données de chaque list
		var values = []

		for (const [key, value] of Object.entries(this.state.lists)) {
			if (value.selected.length > 0)
			{
				values.push({
					code: value.property_code,
					values: (value.selected ? value.selected.map(x => { return x.code}) : [])
				})
			}
		}
		  
		return values;
	}

	setValues(request) {
		var lists = this.state.lists;
		var currentSelection = [];
		var currentLevel = -1;    
		var currentList = null;    

		//console.log(request);

		request.properties.forEach(property => {
			for (const [listCode, list] of Object.entries(lists)) {
				// Affectation des valeurs selectionnées
				if (list.property_code == property.code)
				{
					list.selected = property.values;

					if (list.level > currentLevel)
					{
						currentSelection = property.values;
						currentList = listCode;
	
						currentLevel = list.level
					}
				}
			}
		})

		this.setState({
			modal: false,
			current_list: currentList,
			current_selection: currentSelection,
			lists: lists,
			loading: false
		});

		if(currentSelection.length == 1)
		{
			this.prepareNextListPropositions(currentList, currentSelection)
		}
		   
	}

	isPublic() {
		return this.props.public == null ? true : this.props.public
	}

	useMultipleSelection()
	{
		return true;
		//return this.repositoryService.isConnected() || this.props.embeded;
	}

	loadRoot(currentFamilyCode) {
		// Récupération des domaines
		this.repositoryService.getRepositories(this, "PROPERTY_DOMAIN", (response) => {
			var lists = this.state.lists;
			lists[this.state.current_list].values = response.datas;

			this.setState({
				lists: lists,
				current_values: response.datas,
				render_values: true
			});


			this.renderListValues(response.datas, lists[this.state.current_list].icon)
		})
	}

	getSubValues(familyCode, selectedValues, callback = null )
	{
		this.repositoryService.getRepositoriesByParent(this, familyCode, selectedValues[0].code, (response) => {
			callback(response.datas)
		})
	}


	loadChildren(familyCode, selectedValues) {
		//console.log(familyCode)
		//console.log(selectedValues)

		this.getSubValues(familyCode, selectedValues, (datas) => {
			var lists = this.state.lists;
			lists[this.state.current_list].values = datas;

			this.setState({
				lists: lists,
				current_values: datas,
				render_values: true
			});

			this.renderListValues(datas, lists[this.state.current_list].icon)
		})
	}

	toggleModal(validation) {
		var modal = !this.state.modal
		
		this.setState({ modal: modal, render_values: false }, () => {
			if (modal == false && validation == true)
			{
				this.props.manager.validateInput(this)
			}
		})
	}

	prepareNextListPropositions(currentList, selected)
	{
		this.setState({loading: true})
		this.getSubValues(currentList, selected, (datas) => {
			
			if (datas && datas.length > 1)
			{
				var expectedNbDatas = 3;
				var realNbDatas =  datas.length <= expectedNbDatas ? datas.length : expectedNbDatas;
				var propositions = ""
				if (realNbDatas > 0)
				{
					for(var i=0; i < realNbDatas; i++)
					{
						propositions += datas[i].label + ", "
					}
					propositions += "..."
				}

				this.state.lists[this.state.current_list].propositions = propositions
			}
			else
			{
				this.state.lists[this.state.current_list].propositions = null
			}

			this.setState({
				lists : this.state.lists,
				loading: false
			})
		})
	}

	renderPreviousList() {
		var lists = this.state.lists;
		var currentList = this.state.current_list;

		if (lists[currentList].previous) {
			// Suppression de la selection actuelle
			lists[currentList].selected = []
			// Suppression des donées de la liste
			lists[currentList].values = []


			// Retour à la liste précédente
			if (this.state.lists[lists[currentList].previous].selected.length == 1 && lists[currentList].previous != "PROPERTY_DOMAIN")
			{
				this.prepareNextListPropositions( lists[currentList].previous, this.state.lists[lists[currentList].previous].selected)
			}
			
			this.setState({
				lists: lists,
				current_values: [],
				current_selection: this.state.lists[lists[currentList].previous].selected,
				current_list: lists[currentList].previous
			})

		}
	}

	renderNextList(selectedItem, forceNext = false) {

		var lists = this.state.lists;
		var currentList = lists[this.state.current_list];

		this.state.lists[this.state.current_list].propositions = null;
		this.setState({
			lists: lists
		})

		if (currentList.next && (!this.useMultipleSelection() || currentList.multi == false || forceNext)) {
			currentList.selected = [selectedItem]


			this.setState({
				lists: lists,
				current_list: currentList.next
			})

			this.renderCurrentList();
		}
		else {
			// Si la valeur existe déjà, on la supprime
			var existingSelectedItems = currentList.selected.filter(function (item) {
				return item.code == selectedItem.code
			});

			if (existingSelectedItems && existingSelectedItems.length > 0 ) {
				currentList.selected = currentList.selected.filter(function (item) {
					return item.code !== selectedItem.code
				})
			}
			else {
				currentList.selected.push(selectedItem)
			}
			this.setState({
				lists: lists,
				current_selection: currentList.selected
			})

			// récupération des valeurs suivantes
			if (currentList.selected.length == 1)
			{
				this.prepareNextListPropositions(this.state.current_list, currentList.selected);
			}
			
		}
	}

	renderValue(value, selected, itemsPerLine, icon) {
		var containerColSize = 12 / itemsPerLine
		var imageColSize = itemsPerLine == 1 ? 2 : 4;
		var textColSize = itemsPerLine == 1 ? 10 : 8;

		if (icon == false)
		{
			textColSize += imageColSize
			imageColSize = 0
		}

		var url = value.ui_image_url ? value.ui_image_url : "";

		return <>
			<Col xs={containerColSize} className="line" onClick={() => this.renderNextList(value, false)} style={{padding:"2px"}}>
				<Row className={"t2y-truck-search-repository" + (selected ? " selected" : "")}>
					{
						(icon) ?
						(
							<Col className="t2y-truck-search-repository-image" xs={imageColSize} style={{paddingRight: 0}}>
								<ImageWithRescue avatar={true} rounded={false} thumbnail={true} imageUrl={url}></ImageWithRescue>
							</Col>
						) : (<></>)
					}
					<Col xs={textColSize} className={"t2y-truck-search-repository-label " + (!icon ? "without-image" : "")} style={{paddingLeft:0, textAlign: "left", paddingTop:"10px"}}  >
						<span>{value.label}</span>
						
					</Col>
				</Row>
			</Col>
		</>
	}

	renderListValues(values, icon) {
		const selected = this.state.lists[this.state.current_list].selected
		var nbLines=  4;
		var itemsPerLine = values.length > nbLines ? 2 : 1

		return <Row>
			{values.map(v => {
				return <>
					{this.renderValue(v, selected.find(x => x.code == v.code), itemsPerLine, icon)}
				</>
			})
			}
		</Row>
	}

	renderCurrentList() {
		var list = this.state.lists[this.state.current_list];
		if (list.values.length == 0) {
			if (list.level == 0) {
				this.loadRoot(this.state.current_list);
			}
			else {
				// Récupération de la selection dans la liste précédente
				var previousSelectedValues = this.state.lists[list.previous].selected
				this.loadChildren(this.state.current_list, previousSelectedValues);
			}

			return <>
				<FormLoadingIndicator loading={true}></FormLoadingIndicator>
			</>
		}
		else {
			return this.renderListValues(list.values, list.icon)
		}
	}

	renderSweetAlert()
	{
		this.setState({
			alert: (
			  <ReactBSAlert
				custom
				style={{ display: "block", marginTop: "-200px" }}
				confirmBtnBsStyle="primary"
				confirmBtnText="Super"
				btnSize=""
			  >
				{this.renderCurrentList()}
			  </ReactBSAlert>
			)
		  });
	}

	renderModalHeader()
	{
		const list = this.state.lists[this.state.current_list];
		
		return <div className="modal-header">
			{this.getSpecificListFirstSelectedLabel(list.previous, " \\ ", 0)}
		</div>
	}

	getSpecificListFirstSelectedLabel(listCode, separator, minLevel = 0)
	{
		if (listCode == null)
		{
			return "";
		}
		else
		{
			const list = this.state.lists[listCode];
			if (list && list.level >= minLevel)
			{
				const selectedValues = list.selected;
				if (selectedValues && selectedValues.length > 0)
				{
					var label = this.getSpecificListFirstSelectedLabel(list.previous, separator, minLevel) + separator + selectedValues[0].label;
					if (label && label.indexOf(separator) == 0)
					{
						label = label.substring(separator.length);
					}
					

					return label.trim();
				}
				else
				{
					return "";
				}
			}
			else
			{
				return "";
			}
		}
	}

	getCurrentSelectionLabel()
	{
		//var previousListCode = this.state.lists[t].previous;
		var label = this.getSpecificListFirstSelectedLabel(this.state.current_list, " ", 1);
		/*if (previousListCode)
		{
			label = this.getSpecificListFirstSelectedLabel(previousListCode) + " " +  this.state.lists[this.state.current_list].selected[0].label
		}
		else{
			label = this.state.lists[this.state.current_list].selected[0].label
		}

		return label.trim();
		*/
	   return label;

	}

	renderNextListInformation()
	{
		if (
			// Liste autorisant la multi selection
			(this.state.lists[this.state.current_list].multi)  && 
			// Une seule selection
			(this.state.lists[this.state.current_list].selected.length == 1) && 
			// et plusieurs valeurs possible
			(this.state.lists[this.state.current_list].values.length > 1) &&
			// Il y a une liste apres disponible
			(this.state.lists[this.state.current_list].next != null)
		)
		{
			// Récupération de la liste suivante
			if (this.state.lists[this.state.current_list].propositions != null)
			{
				return <>
				{
					(this.props.embeded) ?
					(
						<Message type="infos" style={{marginTop:20}}>
							<DefaultLabel>
								Il existe plusieurs véhicule de type de "{this.getCurrentSelectionLabel()}"
							</DefaultLabel>
							{
								<DefaultLabel>
									{this.state.lists[this.state.current_list].propositions}
								</DefaultLabel>
							}
							<br/>
							<DefaultButton className="t2y-thirdparty-button small" onClick={() => this.renderNextList(this.state.current_selection[0], true)}>
							Préciser ma recherche 
							</DefaultButton>
						</Message>    
					) : 
					(
						<>
						<Row style={{marginTop:20}}>
							<Col xs="1" style={{padding: "0", textAlign: "center"}}>
								<img
									alt="..."
									className=" "
									src={require("../../../assets/img/icons/infos.svg").default}
									style={{ width: "20px"}}
								/>
							</Col>
							<Col xs="11">
								<DefaultLabel>
									Il existe plusieurs véhicule de type de "{this.getCurrentSelectionLabel()}"  <DefaultButton className="t2y-thirdparty-button small"  small={true} onClick={() => this.renderNextList(this.state.current_selection[0], true)}>Préciser ma recherche </DefaultButton>
								</DefaultLabel>
							</Col>
						</Row>    
						</>
					)
				}
				</>
			}
			else
			{
				return <></>
			}
			
		}
		else
		{
			return <>
			</>
		}
	}

	renderModal() {
		var className = "fullscreen-modal " + this.props.modal_className
		var size=null
		
		return <>
			<Modal
				fade={false}
				size={size}
				className={className}
				isOpen={this.state.modal}
				toggle={() => this.toggleModal(false)}
			>
				{
					(this.useMultipleSelection() && this.props.embeded) ?
					(
						this.renderModalHeader()
					) : (<></>)
				}
				<div className="modal-body">
					{this.renderCurrentList()}
					{this.renderNextListInformation()}
					
				</div>
				{
					(this.state.loading == false) ?
					(
						<div className="modal-footer" style={this.props.embeded ? null : {paddingTop: 0}}>
							{
								(this.state.current_list != "PROPERTY_DOMAIN") ?
									(
										<DefaultLink small={true} onClick={() => this.renderPreviousList()}>
											Revenir
										</DefaultLink>
									) : (<></>)
							}
							{
								(this.state.lists[this.state.current_list].next == null && this.state.current_selection.length > 0) ?
								(
									<DefaultButton className="t2y-secondary-button small" icon="fas fa-check" onClick={() => this.toggleModal(true)}>
										<FormattedMessage id="Default.validate"></FormattedMessage>
									</DefaultButton>
								) 
								: 
								(
									(this.state.lists[this.state.current_list].multi) ?
									(
										<>
											{
												// Si au moins une selection
												(this.state.lists[this.state.current_list].selected.length >= 1) ?
												(
													<DefaultButton className="t2y-secondary-button small" icon="fas fa-check"  onClick={() => this.toggleModal(true)}>
														<FormattedMessage id="Default.validate"></FormattedMessage>
													</DefaultButton>
												) : (<></>)
											
											}
										</>
									)
									:
									(<></>)
								)
							}

						</div>
					)
					: (<></>)
				}
				
			</Modal>

		</>
	}

	render() {
		return <>
			<FormLoadingIndicator context={this} loading={this.state.loading}></FormLoadingIndicator>
			{this.renderModal()}
			{
				(this.state.summary == null) ?
					(
						<Button title={this.getSelectedValues()}   onClick={() => this.toggleModal(false)} color="default" type="button" className="t2y-search-input t2y-search-input-truck" >
							<span className="btn-inner--icon">
								<DefaultImage src={"SVG:" + require("../../../assets/img/icons/search-input-truck.svg").default} />
							</span>
							<span className="btn-inner--text" style={{  
								marginTop:(this.state.lists[this.state.current_list].level == 3 ? "-3px" : "")}}>
								{
									(this.state.current_selection.length == 0) ?
										(
											"Selectionner le véhicule"
										) :
										(
											this.getSelectedValues({truncate : 30, asHtml: true})
											//this.state.current_selection.length + " véhicule(s) selectionné(s)"
										)
								}
							</span>
						</Button>
					)
					:
					(<></>)
			}



		</>
	}
}

export default injectIntl(PortalTruckSearchInput)
