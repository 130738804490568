
import React from "react";
import PropTypes from 'prop-types';
import classnames from "classnames";

import DefaultInput from "./defaultInput";
import Resources from "../../../resources";
import LoadingIndicator from 'react-spinners/MoonLoader'


class DefaultAutocompleteInput extends DefaultInput {

    constructor(props)
    {
        super(props)

        this.state = {
        }
    }

    componentDidMount()
    {
        super.componentDidMount()
    }

    componentWillMount()
    {
        super.componentWillMount()
    }

    clear()
    {
        this.setState({options: [], loading: false})
    }
    
    setValue(value)
    {
        super.setValue(value)
        // Réinitialisation des suggestions
        this.clear()
    }

    render() {
        var props = this.buildProps()
        props.onChange = ((e) => {
            const text = e.target.value
            
            super.onChange(text)

            if (this.props.onSearch)
            {
                this.setState({loading: true})

                this.props.onSearch(text, (suggestions) => {
                    this.setState({options: suggestions, loading: false})
                })
            }
        })

        var component = <>
            <input {...props} onKeyUp={(evt) => {
                evt = evt || window.event;
                if (evt.keyCode == 27) {
                    this.clear()
                }
            }} style={{width:"90%"}} />
            {
                (this.state.loading) ?
                (
                    <span class="btn-inner--icon t2y-default-input-icon-container">
                        <LoadingIndicator size={15} />
                    </span>
                ) : (<></>)
            }
            
        </>

        return <>
            <div>
            {this.rendeInner(component)}
            {
                (!this.state.options) ?
                (
                    <></>
                ) : 
                (
                    <div  className={"dropdown-menu " + ((this.state.options.length > 0) ? "show": "")} onBlur={e => {e.preventDefault(); this.clear()}}>
                        { this.state.options.map((option, index) => 
                                <div 
                                    key={"selectItem" + index} 
                                    className="dropdown-item t2y-clickable" onClick={ ()=> {
                                        if(this.props.onSuggestionSelected)
                                        {
                                            this.props.onSuggestionSelected(option, index)
                                        } 
                                    }}
                                    dangerouslySetInnerHTML={{__html: option.html ? option.html : option.label}}
                                > 
                                </div> 
                            )
                        }
                    </div>
                )
            }
            </div>
        </>

    }
}


DefaultAutocompleteInput.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onSuggestionSelected: PropTypes.func.isRequired
};


export default DefaultAutocompleteInput
