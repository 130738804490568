import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input,
  UncontrolledCollapse,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";
import SecurityService from '../../proxies/SecurityService'
import { injectIntl , FormattedMessage } from 'react-intl';
import ReactDatetime from "react-datetime";
import UserImage from '../../views/pages/components/Image/UserImage'
import Resources from "../../resources";
import FormGroupInput from "../../views/pages/components/form/FormGroupInput";
import FormInput from "../../views/pages/components/FormInput";
import SearchBar from "../../views/pages/components/SearchBar";
import SlidingPane from "react-sliding-pane";
import PortalSearchForm from '../../views/components/search/portalSearchForm'
import Config from '../../config'
import WebsiteHelper from "../../helpers/WebsiteHelper";
import Title3 from "../../views/components/common/title3";
import Title4 from "../../views/components/common/title4";
import Paragraph from "../../views/components/common/paragraph";
import NotificationComponent from "../../views/pages/components/NotificationComponent";
import LoadingOverlay from "react-loading-overlay";
import FormLoadingIndicator from "../../views/pages/components/FormLoadingIndicator";


class AdminNavbar extends NotificationComponent {

  constructor(props) {
    super(props);
    this.intl  = this.props.intl;
    this.securityService = new SecurityService();
    this.user = this.securityService.getUser();
    this.company = this.securityService.getCompany();
    this.notificationsTrigger = null;

    this.state = {
      searchVisible : false,
      notifications : []
    }
  }

  componentWillMount()
  {
    this.notificationsTrigger = setInterval(() => {
      this.updateNotifications()
    }, 1000);
  }

  componentWillUnmount()
  {
    if (this.notificationsTrigger != null)
    {
      clearInterval(this.notificationsTrigger)
    }
  }


  confirmPasswordModification()
  {
    var modalOptions = {
      hideIcon: true,
      title: 'Confirmation',
      okButtonTitle:  this.translateMessage("Default.yes"),    
      cancelButtonTitle:  this.translateMessage("Default.no"),    
      component:  <>
          Confirmer vous la modification de votre mot de passe?
      </>,
      okButtonCallback: () => {
        // initialisation de l'action côté serveur
        this.securityService.askForChangingMyPassword(this, (response) => {
          // Redirection vers l'écran de modification de mot de passe
          WebsiteHelper.goTo(this, "/auth/change-password?token=" + response.token + "&id=" + response.user.id)
        })
      }
    }
    this.showModalConfirmation(modalOptions);
  }

  logout()
  {
    this.securityService.disconnect(this, () => {});
  }

  translateMessage(id, values = {})
    {
        var message = this.intl.formatMessage({"id" : id, "defaultMessage" : "!" + id}, values);
        if (message)
        {
            message = message.replace("&lt;", "<").replace("&gt;", ">");
        }
        return message
    }


  // function that on mobile devices makes the search open
  openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function() {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  onSearchClose(e)
  {
    this.setState({searchVisible: false})
  }

  renderSearchPanel()
  {
    if(this.state.searchVisible)
    {
      var width = window.innerWidth < 1400 ? "90%" : "30%";

      return <SlidingPane
          width={width}
          className="some-custom-class"
          overlayClassName="some-custom-overlay-class"
          isOpen={this.state.searchVisible}
          title=""
          subtitle={null}
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            this.setState({ searchVisible: false });
          }}
        >
          <Container>
              <PortalSearchForm portal={true} modal_className="force-right" body_safe={true} public={false} />
          </Container>
        </SlidingPane>
    }
    else{
      return <></>
    }
  }

  updateNotifications() {
    var company = this.securityService.getCompany();

    if (!this.user || !company) {
      return false;
    }

    var notifications = [];
    if (this.user.is_transporter && company && company.has_iban == false && this.user.is_company_account_manager) {
      notifications.push({
        icon: Resources.menu_alert_iban,
        message: "Vous n'avez renseigné aucune données bancaires",
        description: "Votre rémunération ne pourra commencer qu'avec des données bancaires validées",
        url: "/admin/company?mode=payment_method",
      })
    }

    // Gestion de la modification de la configuration
    if (this.user.is_transporter && this.user.is_company_account_manager && company.configuration_last_modification_date == null) {
      notifications.push({
        icon: Resources.commercial_rules_logo,
        message: "Votre politique commerciale n’a pas encore été définie",
        description: "Définissez vos regles de commandes et de mise à jour de vos disponibilités",
        url: "/admin/commercial",
      })
    }
    if (this.user.is_shipper && this.user.is_company_account_manager && company.configuration_last_modification_date == null) {
      notifications.push({
        icon: Resources.menu_shipper_config_logo,
        message: "Vos règles de gestion n'ont pas encore été paramétrées",
        description: "Choisissez les notifications envoyées à vos clients, activez l'enquête satisfaction, fixez votre référence interne",
        url: "/admin/config",
      })
    }

    if (notifications.length == 0 && this.notificationsTrigger != null) {
      clearInterval(this.notificationsTrigger)
    }

    this.setState({notifications: notifications})
  }

  render() {

    return (
      <Navbar
          className={classnames(
            "avbar-horizontal  navbar-main navbar-expand border-bottom t2y-admin-navbar"
          )}
        >
          {this.renderParent()}
          <FormLoadingIndicator loading={this.state.loading} />
          <Container style={{marginLeft: "inherit", marginRight: "inherit", maxWidth: "100%"}}>
          <NavbarBrand to={null} onClick={(e) => WebsiteHelper.goToHome(this) /*this.restart(e)*/} tag={Link}>
              <img
                alt="..."
                style={{maxWidth:"80%"}}
                src={require('../../assets/img/logo/t2y_logo_menu.png').default}
              />
            </NavbarBrand >
            <Collapse navbar isOpen={true}>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>
                {
                  (this.state.notifications.length > 0) ?
                  (
                    <UncontrolledDropdown nav className="t2y-menu-item-link">
                      <DropdownToggle className="nav-link" color="" tag="a">
                        <i style={{fontSize:"2em"}}  className={Resources.menu_alert} >
                        <Badge color="danger" style={{position:"absolute", right:"3px", top:"-1px", fontFamily:"Radikal"}} className="badge-md badge-circle badge-floating border-white">{this.state.notifications.length}</Badge>
                        </i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden" right>
                        <div className="px-3 py-3">
                          <h6 className="text-sm text-muted m-0">
                            <FormattedMessage id="MODIFICATION_MESSAGE"></FormattedMessage>
                          </h6>
                        </div>
                        <ListGroup flush>
                        {
                          this.state.notifications.map((notification, index) => {
                            return <>
                              <ListGroupItem className="list-group-item-action" href="#" onClick={e => WebsiteHelper.goTo(this, notification.url) } tag="a">
                                <Row className="align-items-center">
                                  <Col className="col-auto"><i style={{fontSize:"2em"}} className={notification.icon} /></Col>
                                  <div className="col ml--2">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div>
                                        {notification.message}
                                      </div>
                                    </div>
                                    <Paragraph small={true} className="d-none d-lg-block">
                                      {notification.description}
                                    </Paragraph>
                                  </div>
                                </Row>
                              </ListGroupItem>
                            </>
                          })
                        }
                        </ListGroup>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  ) : (<></>)
                }
                							{
								// Accès aux gestionaire admin
								this.user.email.includes("@truck2you.com") &&
								<>
									<NavItem>
										<NavLink to="#" onClick={() => WebsiteHelper.goTo(this, "/admin/gestion")} tag={Link} className="t2y-menu-item-link">
											<i style={{ fontSize: "2em" }} className={Resources.button_eye_icon} id="GestionNav" />
											<UncontrolledTooltip delay={0} placement="bottom" target="GestionNav" className="t2y-default-popover">
												<a>Page de gestion</a>
											</UncontrolledTooltip>
										</NavLink>
									</NavItem>
								</>
							}
                {
                  // Accès aux commandes
                  <NavItem>
                    <NavLink to={WebsiteHelper.getHomeUrl()}tag={Link} className="t2y-menu-item-link">
                        <i style={{fontSize:"2em"}} className={Resources.orders_logo} id="ordersNav" />
                        <UncontrolledTooltip delay={0} placement="bottom" target="ordersNav" className="t2y-default-popover">
                          <FormattedMessage id={this.user.is_transporter ? "Default.menu_transporter_orders" : "Default.menu_shipper_orders"} />
                        </UncontrolledTooltip>
                    </NavLink>
                  </NavItem>
                }
                {
                  // Accès aux planning
                  (this.user.is_company_account_manager && this.user.is_transporter) ?
                  (
                    <>
                    <NavItem>
                      <NavLink to="/admin/planning" tag={Link} className="t2y-menu-item-link">
                          <i style={{fontSize:"2em"}} className={Resources.planning_logo} id="planningNav" />
                          <UncontrolledTooltip delay={0} placement="bottom" target="planningNav" className="t2y-default-popover">
                            <FormattedMessage id={"Default.menu_planning"} />
                          </UncontrolledTooltip>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/admin/commercial" tag={Link} className="t2y-menu-item-link">
                          <i style={{fontSize:"2em"}} className={Resources.commercial_rules_logo} id="commercialRulesNav" />
                          <UncontrolledTooltip delay={0} placement="bottom" target="commercialRulesNav" className="t2y-default-popover">
                            <FormattedMessage id={"Default.menu_commercial_rules"} />
                          </UncontrolledTooltip>
                      </NavLink>
                    </NavItem>
                    </>
                  ) : ("")
                }
                {
                  (this.user.is_shipper) ?
                  (
                    <>
                      <NavItem>
                        <NavLink id="searchNavLinkForEvent" tag={Link} className="t2y-menu-item-link" to={WebsiteHelper.getSearchUrl()}>
                            <i style={{fontSize:"2em"}} className={Resources.search_logo} id="searchNav" />
                            <UncontrolledTooltip delay={0} placement="bottom" target="searchNav">
                              <FormattedMessage id={"Default.menu_shipper_search"} />
                            </UncontrolledTooltip>
                            <span className="nav-link-inner--text d-none">
                              <FormattedMessage id={"Default.menu_shipper_search"}></FormattedMessage>
                            </span>
                        </NavLink>
                      </NavItem>
                      {this.renderSearchPanel()}
                    </>
                  )
                  :("")
                }
                {
                  // Accès aux planning
                  (this.user.is_company_account_manager) ?
                  (
                    <UncontrolledDropdown nav className="t2y-menu-item-link">
                      <DropdownToggle className="nav-link" color="" tag="a">
                        <i style={{fontSize:"2em"}}  className={Resources.menu_admin} />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden" right>
                        <div className="px-3 py-3">
                          <h6 className="text-sm text-muted m-0">
                            <FormattedMessage id="Default.menu_operationnal_title"></FormattedMessage>
                          </h6>
                        </div>

                          <ListGroup flush>
                          {
                            (this.user.is_transporter) ?
                            (
                              <ListGroupItem className="list-group-item-action" href="#" onClick={e => WebsiteHelper.goTo(this, "/admin/trucks") } tag="a">
                                <Row className="align-items-center">
                                  <Col className="col-auto"><i style={{fontSize:"2em"}} className={Resources.trucks_logo} /></Col>
                                  <div className="col ml--2">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div>
                                        <Title4><FormattedMessage id="Default.menu_trucks"></FormattedMessage></Title4>
                                      </div>
                                    </div>
                                    <Paragraph small={true} className="d-none d-lg-block">
                                      <FormattedMessage id="Default.menu_trucks_description"></FormattedMessage>
                                    </Paragraph>
                                  </div>
                                </Row>
                              </ListGroupItem>
                            ) : (<></>)
                          }
                          {
                            (this.user.is_transporter) ?
                            (
                              <ListGroupItem className="list-group-item-action" href="#" onClick={e => WebsiteHelper.goTo(this, "/admin/drivers") } tag="a">
                                <Row className="align-items-center">
                                  <Col className="col-auto"><i style={{fontSize:"2em"}} className={Resources.drivers_logo} /></Col>
                                  <div className="col ml--2">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div>
                                        <Title4><FormattedMessage id="Default.menu_drivers"></FormattedMessage></Title4>
                                      </div>
                                    </div>
                                    <Paragraph small={true} className="d-none d-lg-block">
                                      <FormattedMessage id="Default.menu_drivers_description"></FormattedMessage>
                                    </Paragraph>
                                  </div>
                                </Row>
                              </ListGroupItem>
                            ) : (<></>)
                          }
                          
                          <ListGroupItem className="list-group-item-action" href="#" onClick={e => WebsiteHelper.goTo(this, "/admin/addresses") } tag="a">
                            <Row className="align-items-center">
                              <Col className="col-auto"><i style={{fontSize:"2em"}} className={Resources.addresses_logo} /></Col>
                              <div className="col ml--2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <Title4><FormattedMessage 
                                      id={this.user.is_transporter ? "Default.menu_transporter_addresses" : "Default.menu_shipper_addresses"}>
                                    </FormattedMessage></Title4>
                                  </div>
                                </div>
                                <Paragraph small={true} className="d-none d-lg-block">
                                  <FormattedMessage 
                                    id={this.user.is_transporter ? "Default.menu_transporter_addresses_description" : "Default.menu_shipper_addresses_description"}>
                                  </FormattedMessage>
                                </Paragraph>
                              </div>
                            </Row>
                          </ListGroupItem>
                          
                          <div className="px-3 py-3">
                            <h6 className="text-sm text-muted m-0"><FormattedMessage id="Default.menu_other_title"></FormattedMessage></h6>
                          </div>
                          <ListGroupItem className="list-group-item-action" href="#" onClick={e => WebsiteHelper.goTo(this, "/admin/company") } tag="a">
                            <Row className="align-items-center">
                              <Col className="col-auto"><i style={{fontSize:"2em"}} className={Resources.menu_billing_logo} /></Col>
                              <div className="col ml--2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <Title4>{this.securityService.getCompany().company_name}</Title4>
                                  </div>
                                </div>
                                <Paragraph small={true} className="d-none d-lg-block">
                                  <FormattedMessage id="Default.menu_company_description"></FormattedMessage>
                                </Paragraph>
                              </div>
                            </Row>
                          </ListGroupItem>
                          <ListGroupItem className="list-group-item-action" href="#" onClick={e => WebsiteHelper.goTo(this, "/admin/collaborators") } tag="a">
                            <Row className="align-items-center">
                              <Col className="col-auto"><i style={{fontSize:"2em"}} className={Resources.collaborators_logo} /></Col>
                              <div className="col ml--2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <Title4><FormattedMessage 
                                      id={this.user.is_transporter ? "Default.menu_transporter_collaborators" : "Default.menu_shipper_collaborators"}>
                                    </FormattedMessage></Title4>
                                  </div>
                                </div>
                                <Paragraph small={true} className="d-none d-lg-block">
                                  <FormattedMessage 
                                    id={this.user.is_transporter ? "Default.menu_transporter_collaborators_description" : "Default.menu_shipper_collaborators_description"}>
                                  </FormattedMessage>
                                </Paragraph>
                              </div>
                            </Row>
                          </ListGroupItem>

                          {
                            (this.user.is_company_account_manager && this.user.is_shipper) ?
                            (
                              <ListGroupItem className="list-group-item-action" href="#" onClick={e => WebsiteHelper.goTo(this, "/admin/config") } tag="a">
                              <Row className="align-items-center">
                                <Col className="col-auto"><i style={{fontSize:"2em"}} className={Resources.menu_shipper_config_logo} /></Col>
                                <div className="col ml--2">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <Title4><FormattedMessage  id={"Default.menu_shipper_configuration"}></FormattedMessage></Title4>
                                    </div>
                                  </div>
                                  <Paragraph small={true} className="d-none d-lg-block">
                                    <FormattedMessage 
                                      id={"Default.menu_shipper_configuration_description"}>
                                    </FormattedMessage>
                                  </Paragraph>
                                </div>
                              </Row>
                            </ListGroupItem>
                            ) : (<></>)
                          }
                        </ListGroup>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  ) : (<></>)
                }
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center" style={{cursor:"pointer"}}>
                      <UserImage user={this.securityService.getUser()} />
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {this.securityService.getUserDesignation()}
                        </span>
                        
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0"><FormattedMessage id="Default.welcome" /> </h6>
                    </DropdownItem>
                    <DropdownItem href="/admin/profile">
                      <i className="ni ni-single-02" />
                      <span><FormattedMessage id="Default.my_profile" /></span>
                    </DropdownItem>
                    <DropdownItem onClick={() => this.confirmPasswordModification()}>
                      <i className={Resources.form_password_icon} />
                      <span><FormattedMessage id="Default.change_my_password" /></span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      href="#"
                      onClick={e => this.logout() }
                    >
                      <i className="ni ni-user-run" />
                      <span><FormattedMessage id="Default.quit" /></span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
    )


  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark"
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};

export default injectIntl(AdminNavbar);
