import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Container
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import ComponentAndImageStep from "../componentAndImageStep.jsx";
import DefaultLabel from "../../common/defaultLabel.jsx";
import DefaultButton from "../../common/defaultButton.jsx";
import DateHelper from "../../../../helpers/DateHelper.js";
import DefaultLink from "../../common/defaultLink.jsx";
import WebsiteHelper from "../../../../helpers/WebsiteHelper.js";
import StringHelper from "../../../../helpers/StringHelper.js";
import Codes from "../../../../codes.js";
import FormGroupInput from "../../../pages/components/form/FormGroupInput.jsx";
import FormHourInput from "../../../pages/components/form/FormHourInput.jsx";
import FormLoadingIndicator from "../../../pages/components/FormLoadingIndicator.jsx";
import ShipperService from "../../../../proxies/ShipperService.js";
import Resources from "../../../../resources.js";


class CallForBidsConfirmationStep extends ComponentAndImageStep {

    constructor(props) {
        super(props)

        this.shipperService = new ShipperService();
        
        this.validationRules = {
            arrival_time: {
                required: true
            }
        }

        this.state = {
            componentSize: 7,
            imageSize: 5,
            loading: false,
            image: require('../../../../assets/img/illustrations/orderWorkflow_orderPaymentConfirmationStep.svg').default,
            title: StringHelper.translate(this, "call_for_bids.confirmation_step.title"),
            subtitle: null,
            message: StringHelper.translate(this, "call_for_bids.confirmation_step.message"),
            formDatas: {
            }
        }
    }

    componentWillMount() {
        super.componentWillMount()
        this.props.workflow.renderNormalView()
    }

    componentDidMount() {
        super.componentDidMount()
    }

    goToCallForBids()
    {
        const callForBidsId = this.props.workflow.state.input;
        WebsiteHelper.goTo(this, '/admin/orders?mode=call_for_bids&id=' + callForBidsId)
    }

    renderComponent() {
        return <>
            <Container>
                <hr/>
                <Row className="justify-content-center">
                    <DefaultButton icon={Resources.search_logo} label="Accéder à mon appel d'offres" className="t2y-secondary-button small" onClick={(e) => this.goToCallForBids()}></DefaultButton>
                </Row>
            </Container>
        </>
    }
}


export default injectIntl(CallForBidsConfirmationStep)