import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Row,
    Col,
    Container,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Table
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import Step from "../step";
import Config from "../../../../config"
import ShipperService from "../../../../proxies/ShipperService";

import InjectedCreditCardComponent from "../../payment/CreditCardComponent"
import PaymentComponent from "../../payment/PaymentComponent"
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import DateLabel from "../../common/dateLabel";
import StringHelper from "../../../../helpers/StringHelper";
import TransporterService from "../../../../proxies/TransporterService";
import DateHelper from "../../../../helpers/DateHelper";
import Message from '../../common/message'
import TruckSummary from "../../business/truckSummary";
const stripePromise = loadStripe(Config.stripePublicKey);


class CallForBidsTransporterDatesStep extends Step {

    constructor(props) {
        super(props)
        
        this.transporterService  = new TransporterService();
        this.state = {
            openedCollapses: [],
            availabilities: null
        }
    }

    componentWillMount() {
        this.startLoading();
        super.componentWillMount()

        // Récupération du résultat de l'appel d'offre à proposer
        const result = this.props.workflow.state.input;
        const resultId = result.id

        this.transporterService.getCallForBidsResultAvailabilities(this, resultId, (response) => {

            var dates = [];
            if (response.match_expected)
            {
                dates = response.desired_days.map(d => { return DateHelper.toMysqlDateFormat(DateHelper.phpDateToJavascript(d))})
                this.dates = true;
            }

            this.setState({
                availabilities: response,
                dates: dates,
                callForBids: response.call_for_bids,
                callForBidsResult: response.call_for_bids_result
            }, () => {
                if (dates.length > 0)
                {
                    this.props.workflow.reload()
                }
            })
        }, (httpErrorResponse) => {
            httpErrorResponse.json().then((apiResponse) => {
                this.showErrorFromResponse(apiResponse, null)
            })
            this.setState({ loading: false })
        })
    }

    componentDidMount() {
        super.componentDidMount()
    }

    hasSelectedDates()
    {
        return this.state.dates && this.state.dates.length > 0
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
            openedCollapses: []
            });
        } else {
            this.setState({
            openedCollapses: [collapse]
            });
        }
    };


    submit(pid)
    {
        this.startSubmiting()
        // Génération de la proposition avec les dates selectionnées
        var formIsValid = this.htmlFormIsValid();
        if (formIsValid)
        {
            const callForBidsResultId = this.props.workflow.state.input.id;
            // Création du brouillon de commande
            var datasToPost = {
                dates: this.state.dates,
                half_day: this.state.availabilities.half_day
            }

            this.transporterService.makeAProposal(this, callForBidsResultId, datasToPost, (response) => {
                super.submit(pid)

                // Récupération de l'identifiant de la commande
                this.props.workflow.goToNextStep(pid)

                this.stopSubmiting()
            }, (httpErrorResponse) => {
                if (httpErrorResponse.status == 401)
                {
                    // Gestion de l'utilisateur non connecté
                    this.props.workflow.notConnected();
                }
                else 
                {
                    this.stopSubmiting();
                    this.stopLoading();
                    httpErrorResponse.json().then((apiResponse) => {
                        this.showErrorFromResponse(apiResponse)
                    })
                }

                this.stopSubmiting()
            })
        }
    }

    renderDate(date, halfDay, index)
    {
        const collapseId = "collapse" + index;
        return <>
            <Row>
            {
                (this.state.availabilities.desired_days.length == 1) ?
                (
                    <>
                        <Col xl="12">
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    id={"dateRadio" + index}
                                    name="customRadio"
                                    type="radio"
                                    onClick={(e) => {
                                        this.setState({dates: [
                                            DateHelper.toMysqlDateFormat(DateHelper.phpDateToJavascript(date.start_date))
                                        ]}, () => {
                                            this.dates = true;
                                            this.props.workflow.reload()
                                        });
                                        
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={"dateRadio" + index}>
                                    {/*<DateLabel date={date.start_date} half={halfDay}></DateLabel>*/}
                                    {
                                        halfDay ?
                                        "la demi-journée du " +  DateHelper.dateAsString(date.dates[0][0]) : 
                                        "la journée du " + DateHelper.dateAsString(date.dates[0][0])
                                    }
                                </label>
                            </div>
              
                        </Col>
                    </>
                )
                :
                (   
                    <>
                        <Card className="card-plain" style={{width: "100%"}}>
                            <CardHeader
                            role="tab"
                            onClick={() => this.collapsesToggle(collapseId)}
                            aria-expanded={this.state.openedCollapses.includes(collapseId)}
                            >
                            <h5 className="mb-0">
                                    Départ le <DateLabel date={date.start_date} label={false}></DateLabel>
                                    {/*{date.dates.length} proposition(s) au départ du <DateLabel date={date.start_date} label={false}></DateLabel>*/}
                            </h5>
                            </CardHeader>
                            <Collapse
                            role="tabpanel"
                            isOpen={this.state.openedCollapses.includes(collapseId)}
                            >
                            <CardBody>
                                <Row>
                                    {
                                        date.dates.map((dates, dateIndex) => {
                                            return <Col xl="12">
                                                <div className="custom-control custom-radio mb-3">
                                                    <input
                                                        className="custom-control-input"
                                                        id={"multi" + index + "-dateRadio" + dateIndex}
                                                        name={"customRadio" + index}
                                                        type="radio"
                                                        onClick={(e) => {
                                                            this.setState({
                                                                dates: dates.map(d => { return DateHelper.toMysqlDateFormat(DateHelper.mysqlDateToJavascript(d))})
                                                            }, () => {
                                                                this.dates = true;
                                                                this.props.workflow.reload()
                                                            })
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor={"multi" + index + "-dateRadio" + dateIndex}>
                                                        {DateHelper.datesAsStringPeriod(dates)}
                                                    </label>
                                                </div>
                                
                                            </Col>
                                        })
                                    }

                                    {/*
                                    <Col xl="4">
                                    {
                                        date.dates.map((dates, dateIndex) => {
                                            return <>
                                                <Row>
                                                    Fin de la mission <DateLabel date={dates[dates.length - 1]} label={false}></DateLabel>
                                                    <DefaultLink className="ml-2" small={true}  onClick={(e) => this.renderRangeDetail(index, dates)}>
                                                        Voir le détail
                                                    </DefaultLink>
                                                </Row>
                                            </>
                                        })
                                    }
                                    </Col>
                                    <Col xl="8" className="t2y-date-range-container">
                                        {
                                            this.renderCalendar(index)
                                        }
                                    </Col>
                                    */}
                                </Row>
                            </CardBody>
                            </Collapse>
                        </Card>
                    </>
                )
            }
            </Row>
        </>
    }

    renderTruckComponent()
    {
        const result = this.props.workflow.state.input;
        const bids = this.state.callForBids;
        const truck = this.props.workflow.state.input;
        return <>
                    <Table classnames="table" responsive>
                        <thead>
                            <tr>
                                <th>{StringHelper.translate(this, "CallForBids.results.header_truck")}</th>
                                <th>{StringHelper.translate(this, "CallForBids.results.header_reference")}</th>
                                <th>{StringHelper.translate(this, "CallForBids.results.header_amounts")}</th>
                                <th>{StringHelper.translate(this, "CallForBids.results.header_added_amounts")}</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td width="15%"><TruckSummary completeSummaryOnClick={false} properties={false} reference={false} label={true} image={false} truck={result.truck}></TruckSummary></td>
                            <td width="15%"><TruckSummary completeSummaryOnClick={false} properties={false} reference={true} label={false} image={false} truck={result.truck}></TruckSummary></td>
                            <td>
                                {StringHelper.translate(this, "CallForBids.results.header_amounts_package")} {StringHelper.formatAmount(bids.half_day ? result.truck.cost_half_day : result.truck.cost_day)}
                                <br/>
                                <strong>{StringHelper.translate(this, "CallForBids.results.header_amounts_days", null, {days: bids.half_day ? "1/2" : bids.days.length})} {StringHelper.formatAmount(bids.half_day ? result.truck.cost_half_day : result.truck.cost_day * bids.days.length)}</strong>
                            </td>
                            <td>
                                {StringHelper.formatAmount(result.truck.cost_km)} / km
                                <br/>
                                {StringHelper.formatAmount(result.truck.cost_hour)} / h
                            </td>
                        </tr>
                        </tbody>
                    </Table>
        </>
    }

    renderDatesSelection()
    {
        var message = StringHelper.translate(this, "call_for_bids.one_dates_selection_message");
        message = message.replace("#{expectedTruckDescription}", this.state.callForBids.expected_truck_description)
            .replace("#{expectedPeriodAsString}", this.state.callForBids.expected_period_as_string);
        
        
        var component = <></>
        // Date(s) recherché = date(s) proposée(s)
        if (this.state.availabilities.match_expected)
        {
            component = <>
                <strong>{message}</strong>
                {this.renderTruckComponent()}
                <hr/>
            </>

        } // Date(s) recherché != date(s) proposée(s) mais un seul choix possible
        else if (this.state.availabilities.length == 1)
        {
            component = <>
                <strong>{message}</strong>
                {this.renderTruckComponent()}
                <br/>
                <><strong>{(this.state.availabilities.proposed_days[0].dates[0].length == 1) ? "à la date du " : "aux dates suivantes : "}</strong></>
                {DateHelper.datesAsStringPeriod(this.state.availabilities.proposed_days[0].dates[0])}
                <hr/>
            </>

        } // Date(s) recherché != date(s) proposée(s) et plusieurs choix  possibles
        else
        {
            component = <>
                <strong>{message}</strong>
                {this.renderTruckComponent()}
                <Message type="infos"><FormattedMessage id="call_for_bids.multiple_dates_selection_message" /></Message>
                <Col xl="12">
                    <div className="accordion">
                    {
                        this.state.availabilities.proposed_days.map((date, index) => {
                            return <>
                                {this.renderDate(date, this.state.availabilities.half_day, index)}
                            </>
                        })
                    }
                    </div>
                </Col>
            </>
        }

        return <Row>
            <Col xs="12">
                {component}
            </Col>
        </Row>
    }

    renderContent()
    {
        return <>
        {
            (this.state.availabilities) ? 
            (
                this.renderDatesSelection()
            ) : (
                <>
                    Récupération des disponibilité de ce camion
                </>
            )
        }
        </>
    }

    render() {
        {
            return <>
                {this.renderParent()}
                {
                    (this.busy()) ?
                    (
                        super.renderAsBusy()
                    )
                    :
                    (
                        this.renderContent()
                    )
                }
            </>
        }
    }
}


export default injectIntl(CallForBidsTransporterDatesStep)

