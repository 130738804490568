import "../../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
import classnames from "classnames";
import {
    Row,
    Col,
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText ,
    Card,
    CardHeader,
    CardBody
} from "reactstrap";
import BusinessService from "../../../../proxies/BusinessService";
import TruckProperties from "../../transporter/TruckProperties";
import TruckImage from "../Image/TruckImage";
import FormGroupInput from "../form/FormGroupInput";
import TruckRepresentation from "../../../components/truck/TruckRepresentation";


class TruckSummaryCard extends React.Component {

    constructor(props)
    {
        super(props)
        this.businessService = new BusinessService();
        this.state = {
            truck : null,
            loading: false
        }
    }

    loadDatas()
    {
        if (this.props.truckId)
        {
            this.businessService.getTruckSummary(this, this.props.truckId, (response) => {
                this.setState({truck : response.instance})
            })
        }
    }

    componentDidMount()
    {
        this.loadDatas();
    }

    render() {
        
      return (
        <>
            {
              (this.state.loading) ? 
              (
                "Chargement des données du camion..."
              ) : 
              (
                  <Row>
                      <Card>
                        <CardBody>
                            <Row className="justify-content-text-center">
                                <Col lg="3"></Col>
                                <Col lg="6">
                                {/*<TruckImage className="missions_truck_image_url" truck={this.state.truck} /> */}
                                <TruckRepresentation  truck_id={this.state.truck ? this.state.truck.id : null}></TruckRepresentation>
                                </Col>
                            </Row>
                            <hr/>
                            <Row className="justify-content-text-center">
                            {
                                (this.state.truck && this.state.truck.property_values) ? 
                                (
                                    this.state.truck.property_values.map(propertyValue => {
                                        return <Col xs="12"><FormGroupInput label={propertyValue.property_label} type="multiline">
                                            <label> : {propertyValue.value}</label>
                                        </FormGroupInput>
                                        </Col>
                                    })
                                ) : ("")
                            }
                            </Row>
                        </CardBody>
                    </Card>
                  </Row>
              )
            }
            
        </>
      );
    }
  }
 
  export default TruckSummaryCard;