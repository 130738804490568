
import React from "react";



class Separator extends React.Component {
    
    render() {
        return<>
            <hr style={{marginTop: "10px", marginBottom: "10px"}} />
       </>
    }
}

export default Separator
