import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Modal,
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Resources from "../../../../resources";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import AccountWorkflow from "../account/accountWorkflow";
import ResultDetailStep from "./resultDetailStep";
import PaymentStep from "./paymentStep";
import PaymentMethodStep from "./paymentMethodStep";
import WaitingForValidationStep from "./waitingForValidationStep";
import OrderPaymentConfirmationStep from './orderPaymentConfirmationStep'
import WebsiteHelper from "../../../../helpers/WebsiteHelper";
import StringHelper from "../../../../helpers/StringHelper";
import Title2 from "../../common/title2"
import ShipperService from "../../../../proxies/ShipperService";
import OrderArrivalTimeStep from "./OrderArrivalTimeStep";
import RefuseCallForBidsResultStep from "./refuseCallForBidsResultStep";

var moment = require('moment');
require('moment/locale/fr');

class OrderWorkflow extends Workflow {

    constructor(props) {
        super(props)
        
        this.shipperService = new ShipperService();

        this.state = {
            visible: false,
            size: "lg",
            current_step: "ACCOUNT",
            steps: {
                "ACCOUNT": {
                    id: "ACCOUNT",
                    position:1,
                    type: "workflow",
                    component: <AccountWorkflow embeded={true} id="ACCOUNT" workflow={this} type="shipper"></AccountWorkflow>,
                    next: "SEARCH_RESULT_DETAIL",
                    conditions: [
                        {
                            condition: function(){
                                return this.shipperService.hasCompanyInfos()
                            }.bind(this),
                            step_on_success: "SEARCH_RESULT_DETAIL",
                            step_on_failure: "CURRENT"
                        }
                    ],
                },
                "SEARCH_RESULT_DETAIL": {
                    id: "SEARCH_RESULT_DETAIL",
                    position: 2,
                    type: "step",
                    component: <ResultDetailStep size='xl' id="SEARCH_RESULT_DETAIL" disableOrder={this.props.disableOrder} workflow={this} childRef={(elt) => this.resultDetailStepRef = elt} ></ResultDetailStep>,
                    hideNextsAndLinks: true,
                    nexts : [
                        {
                            label: this.translateMessage("order.result_detail_step_manual_validation_payment_button_label"),                       
                            icon: Resources.button_cart_icon,
                            visible: false,
                            step: "PAYMENT_METHOD",
                            condition: (function(component) { return component.allowPaymentMethodStep()})
                        },
                        {
                            label: this.translateMessage("order.result_detail_step_auto_validation_payment_button_label"),
                            icon: Resources.button_cart_icon,
                            visible: false,
                            step: "PAYMENT",
                            condition: (function(component) { return component.allowPaymentStep()})
                        },
                        {
                            label: this.translateMessage("order.result_detail_step_manual_validation_payment_go_back_button_label"), 
                            icon: Resources.button_payment_icon,
                            visible: false,
                            step: "PAYMENT_METHOD",
                            condition: (function(component) { return component.goBackToPaymentMethodStep()})
                        },
                        {
                            label: this.translateMessage("order.result_detail_step_auto_validation_payment_go_back_button_label"),
                            icon: Resources.button_payment_icon,
                            visible: false,
                            step: "PAYMENT",
                            condition: (function(component) { return component.goBackToPaymentStep()})
                        },
                        {
                            label: "Refuser ce véhicule",
                            visible: false,
                            className: "t2y-removed-button-alt",
                            step: "REFUSE_CALL_FOR_BIDS_RESULT",
                            condition: (function(component) { return component.isCallForBidsResult()}),
                            confirmation: {
                                title: "Refus de la proposition",
                                component: <>Êtes-vous sûr de refuser de cette proposition&nbsp;?</>,
                                okButtonTitle: "Refuser la proposition",
                                cancelButtonTitle: "Ne rien faire",
                                iconStyle: {fontSize:"60px"},
                                iconColor: "red",
                                okButtonClassName: "t2y-danger-button"
                            }
                        }
                    ]
                },
                "REFUSE_CALL_FOR_BIDS_RESULT": {
                    id: "REFUSE_CALL_FOR_BIDS_RESULT",
                    title: "",
                    position: 2,
                    type: "step",
                    component: <RefuseCallForBidsResultStep parent={this.props.parent} size="lg" id="REFUSE_CALL_FOR_BIDS_RESULT" workflow={this} childRef={(elt) => this.refuseCallForBidsStepRef = elt} ></RefuseCallForBidsResultStep>,
                    nexts : [ { 
                        label: "Fermer",  
                        visible: false, 
                        condition: (function(component) { return component.isLoading() == false}), 
                        step: "EXIT" 
                    } ],
                    links: []
                },
                "PAYMENT_METHOD": {
                    id: "PAYMENT_METHOD",
                    title: <Title2>{StringHelper.translate(this, this.getPaymentOnInvoice() ? "order.bypass_payment_method_step_title" : "order.payment_method_step_title")}</Title2>,
                    position: 2,
                    type: "step",
                    component: <PaymentMethodStep size="lg" id="PAYMENT_METHOD" workflow={this} childRef={(elt) => this.paymentMethodStepRef = elt} ></PaymentMethodStep>,
                    nexts : [
                        {
                            label: "Je x", 
                            icon: Resources.button_cart_icon,
                            visible: false,
                            condition: (function(component) { return component.allowNextStep()})
                        }
                    ],
                    links: [
                        {
                            label: "Retour au détail de la commande",step: "SEARCH_RESULT_DETAIL", visible: true
                        }
                    ]
                },
                "WAITING_CHOOSE_FIRST_ARRIVALTIME": {
                    id: "WAITING_CHOOSE_FIRST_ARRIVALTIME",
                    position: 2,
                    type: "step",
                    component: <OrderArrivalTimeStep id="WAITING_CHOOSE_FIRST_ARRIVALTIME" size="lg" workflow={this} childRef={(elt) => this.orderArrivalTimeStepRef = elt} ></OrderArrivalTimeStep>,
                    nexts : [
                        {
                            label: "Enregistrer",
                            icon: Resources.button_save_icon,
                            visible: true,
                            step: "WAITING_FOR_VALIDATION"
                        }
                    ],
                },
                "WAITING_FOR_VALIDATION": {
                    id: "WAITING_FOR_VALIDATION",
                    position: 2,
                    type: "step",
                    component: <WaitingForValidationStep size="lg" id="WAITING_FOR_VALIDATION" workflow={this} childRef={(elt) => this.waitingForValidationStepRef = elt} ></WaitingForValidationStep>,
                    nexts : [] //[ { label: "Fermer",  visible: true, step: "EXIT" } ]
                },
                "PAYMENT": {
                    id: "PAYMENT",
                    position: 2,
                    title: <Title2>{StringHelper.translate(this, this.getPaymentOnInvoice() ? "order.bypass_payment_method_step_title" : "order.payment_method_step_title")}</Title2>,
                    type: "step",
                    component: <PaymentStep id="PAYMENT" size="lg" workflow={this} childRef={(elt) => this.paymentStepRef = elt} ></PaymentStep>,
                    nexts : [
                        {
                            label: "Je x", 
                            icon: Resources.button_cart_icon,
                            visible: false,
                            condition: (function(component) { return component.allowNextStep()})
                        }
                    ],
                    links: [
                        {
                            label: "Retour au détail de la commande",step: "SEARCH_RESULT_DETAIL", visible: true
                        }
                    ]
                },
                "CONFIRMATION_CHOOSE_FIRST_ARRIVALTIME": {
                    id: "CONFIRMATION_CHOOSE_FIRST_ARRIVALTIME",
                    position: 2,
                    type: "step",
                    component: <OrderArrivalTimeStep id="CONFIRMATION_CHOOSE_FIRST_ARRIVALTIME" size="lg" workflow={this} childRef={(elt) => this.orderArrivalTimeStepRef = elt} ></OrderArrivalTimeStep>,
                    conditions: [
                        {
                            condition: function(){
                                return this.state.version === 2
                            }.bind(this),
                            step_on_success: "ORDER_PAYMENT_CONFIRMATION",
                            step_on_failure: "CURRENT"
                        }
                    ],
                    nexts : [
                        {
                            label: "Enregistrer", 
                            icon: Resources.button_save_icon,
                            visible: true,
                            step: "ORDER_PAYMENT_CONFIRMATION"
                        }
                    ],
                },
                "ORDER_PAYMENT_CONFIRMATION": {
                    id: "ORDER_PAYMENT_CONFIRMATION",
                    position: 3,
                    type: "step",
                    component: <OrderPaymentConfirmationStep id="ORDER_PAYMENT_CONFIRMATION" size="lg" workflow={this} childRef={(elt) => this.orderPaymentConfirmationStepRef = elt} ></OrderPaymentConfirmationStep>,
                    nexts : []
                }
            }
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    getPaymentOnInvoice()
    {
        const currentUser = this.shipperService.getCompany()
        if (!currentUser)
        {
            return false;
        }
        else
        {
            return currentUser.payment_on_invoice;
        }
    }

    notConnected()
    {
        super.notConnected();
        this.setState({
            current_step: "ACCOUNT"
        })
    }

    start(inputValue)
    {
        this.setState({ 
            input: inputValue,
            visible: true
        })
    }

    startV2(inputValue, dates, half_day) {
        this.setState({
            input: inputValue,
            dates: dates,
            half_day: half_day,
            version: 2,
            visible: true
        })
    }

    getUrl()
    {
        return window.location.href + "?resultId=" + this.state.input
    }

    restore()
    {
        this.goToSpecificStep("SEARCH_RESULT_DETAIL")
    }

    showSearchTransporterErrorMessage()
    {
        this.showModalConfirmation({
            iconComponent: <div className="text-center text-warning" style={{fontSize:"100px"}}>
                <i className="fas fa-exclamation-triangle"></i>
            </div>,
            okButtonTitle: "Accéder à mon espace",
            cancelButtonTitle: "Rester sur cette page",
            okButtonCallback: () => {
                WebsiteHelper.goToHome(this)
            },
            cancelButtonCallback: () => {},
            component: <>
                Vous venez de vous connecter à un compte transporteur, mais seuls les comptes expéditeurs peuvent faire des recherches / passer des commandes !
                <br/>
                Que souhaitez vous faire ?    
            </>
        })
    }

    beforeClosingModal()
    {
        var askForConfirmation = false;
        var currentStep = this.getCurrentStep();
        var component = null
        if (["PAYMENT", "PAYMENT_METHOD"].includes(currentStep.id) && !this.props.callForBids)
        {
            askForConfirmation = true
            component = currentStep.id == "PAYMENT"? this.paymentStepRef : this.paymentMethodStepRef
        }
        else if (currentStep.id == "SEARCH_RESULT_DETAIL") 
        {
            // Vérification d'une réservation en cours
            askForConfirmation = (this.resultDetailStepRef.goBackToPaymentMethodStep() || this.resultDetailStepRef.goBackToPaymentStep())
            component = this.resultDetailStepRef;
        }

        if (askForConfirmation)
        {
            var options = {
                title: "Attention",
                component: <>Si vous interrompez le processus de paiement, vous allez perdre la priorité pour ce véhicule.<br/> Que voulez-vous faire ?</>,
                okButtonTitle: "Libérer le véhicule",
                cancelButtonTitle: "Passer commande",
                okButtonCallback: () => {
                    // Annulation de la réservation
                    if (component)
                    {
                        // Récupération de l'identifiant de la commande
                        var orderId = component.getOrderId();
                        this.shipperService.cancelOrder(this, orderId, (response) => {})
                    }
                    // Fermeture du workflow
                    this.setState({ visible: false })
                    this.goToSpecificStep("SEARCH_RESULT_DETAIL")
                }
            }
            this.showModalConfirmation(options)
        }
        else
        {
            this.setState({ visible: false })
        }
    }
    
    render() {
        return (
            <>
                {this.renderParent()}
                {/*this.renderAsSlidingPanel()*/}
                {this.renderAsModal()}
                {/*this.renderCurrentStep() */}
            </>
        );
    }
}


export default injectIntl(OrderWorkflow)

