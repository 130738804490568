import ProxyService from './ProxyService'
import axios from "axios";
import DateHelper from '../helpers/DateHelper';

class BusinessService extends ProxyService {

    createCompanyBillinfInfosFromSiret(caller, siret, operationalAddress, okCallback, errorCallback = null)
    {
        let apiUrl = this.apiUrls["USER_CREATE_COMPANY_INFOS_BY_SIRET"] + siret;
        this.put(caller, apiUrl, {
            operational_address: operationalAddress
        }, okCallback, errorCallback);
    }

    getCompanyInfos(caller, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_GET_COMPANY_INFOS"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_GET_COMPANY_INFOS"];
        }
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    getCompanyInfosBySiret(caller, siret, okCallback, errorCallback = null)
    {
        let apiUrl = this.apiUrls["GET_COMPANY_INFOS_BY_SIRET"];
        this.get(caller, apiUrl + siret, okCallback, errorCallback);
    }

    saveCompanyBillingInfos(caller, postedData, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_SAVE_COMPANY_BILLING_INFOS"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_SAVE_COMPANY_BILLING_INFOS"];
        }
        this.put(caller, apiUrl, postedData, okCallback, errorCallback);
    }

    setBillingAddressNotOperational(caller, okCallback, errorCallback = null)
    {
        if (caller)
        {
            caller.setState({loading : true});
        }

        let apiUrl = this.apiUrls["COMPANY_SET_BILLING_NOT_OPERATIONAL"];
        this.post(caller, apiUrl, {}, okCallback, errorCallback);
    }

    setBillingAddressOperational(caller, okCallback, errorCallback = null)
    {
        if (caller)
        {
            caller.setState({loading : true});
        }

        let apiUrl = this.apiUrls["COMPANY_SET_BILLING_OPERATIONAL"];
        this.post(caller, apiUrl, {}, okCallback, errorCallback);
    }

    getAddress(caller, id, okCallback)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_GET_ADDRESS"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_GET_ADDRESS"];
        }

        this.get(caller, apiUrl + id, okCallback);
    }

    getAddresses(caller, okCallback, errorCallback)
    {
        if (caller != null)
        {
            caller.setState({loading : true});
        }

        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_GET_ADDRESSES"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_GET_ADDRESSES"];
        }

        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    createAddress(caller, datas, okCallback)
    {
        if (caller != null)
        {
            caller.setState({loading : true});
        }
        
        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_CREATE_ADDRESS"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_CREATE_ADDRESS"];
        }
        this.post(caller, apiUrl, datas, okCallback);
    }

    saveAddress(caller, datas, okCallback)
    {
        caller.setState({loading : true});
        
        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_UPDATE_ADDRESS"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_UPDATE_ADDRESS"];
        }
        this.put(caller, apiUrl + datas.id, datas, okCallback);
    }

    deleteAddress(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        
        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_UPDATE_ADDRESS"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_UPDATE_ADDRESS"];
        }
        this.delete(caller, apiUrl + id, okCallback, errorCallback);
    }

    activeAddress(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_ACTIVE_ADDRESS"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_ACTIVE_ADDRESS"];
        }
        this.post(caller, apiUrl + id, {}, okCallback, errorCallback);
    }

    getCollaborator(caller, id, okCallback)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_GET_COLLABORATOR"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_GET_COLLABORATOR"];
        }

        this.get(caller, apiUrl + id, okCallback);
    }

    deleteCollaborator(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_DELETE_COLLABORATOR"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_DELETE_COLLABORATOR"];
        }

        this.delete(caller, apiUrl + id, okCallback);
    }

    createCollaborator(caller, datas, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_CREATE_COLLABORATOR"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_CREATE_COLLABORATOR"];
        }
        this.post(caller, apiUrl, datas, okCallback, errorCallback);
    }

    saveCollaborator(caller, datas, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        
        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_UPDATE_COLLABORATOR"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_UPDATE_COLLABORATOR"];
        }
        this.put(caller, apiUrl, datas, okCallback, errorCallback);
    }

    activeCollaborator(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_ACTIVE_COLLABORATOR"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_ACTIVE_COLLABORATOR"];
        }
        this.post(caller, apiUrl + id, {}, okCallback, errorCallback);
    }

    getOrders(caller, date, orderId, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_GET_ORDERS"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_GET_ORDERS"];
        }

        apiUrl = apiUrl + (date.month() + 1)+ "/" + date.year()
        if (orderId != null)
        {
            apiUrl += "?order_id=" + orderId  
        }

        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    getOrdersByStates(caller, orderId, orderNumber, states, startDate, endDate, okCallback, errorCallback)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        if (states == null || states.length == 0)
        {
            states = ["~"]
        }

        let apiUrl = this.apiUrls["GET_ORDERS_BY_STATES"].replace("{states}", states.join("|")) + "?";

        if (startDate != null)
        {
            apiUrl = apiUrl + "&start_date=" + DateHelper.toString(startDate, "YYYY-MM-DD")
        }

        if (endDate != null)
        {
            apiUrl = apiUrl + "&end_date=" + DateHelper.toString(endDate, "YYYY-MM-DD")
        }

        if (orderId != null)
        {
            apiUrl = apiUrl + "&order_id=" + orderId
        }

        if (orderNumber != null)
        {
            apiUrl = apiUrl + "&order_number=" + orderNumber
        }
        
        
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    getMission(caller, id, okCallback, errorCallback = null)
    {
        if (caller != null)
        {
            caller.setState({loading : true});
        }
        
        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_GET_MISSION"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_GET_MISSION"];
        }
        this.get(caller, apiUrl + id, okCallback, errorCallback);
    }

    saveMission(caller, datas, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_SAVE_MISSION"];
        this.put(caller, apiUrl, datas, okCallback, errorCallback);
    }

    saveMissionStep(caller, datas, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_SAVE_MISSION_STEP"];
        if (datas.id == null)
        {
            this.put(caller, apiUrl, datas, okCallback, errorCallback);
        }
        else
        {
            this.post(caller, apiUrl, datas, okCallback, errorCallback);
        }
    }

    getMissionStep(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        let apiUrl = this.apiUrls["TRANSPORTER_GET_MISSION_STEP"];
        if (user.is_shipper)
        {
            apiUrl = this.apiUrls["SHIPPER_GET_MISSION_STEP"];
        }
        this.get(caller, apiUrl + id, okCallback, errorCallback);
    }

    deleteMissionStep(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_DELETE_MISSION_STEP"];
        this.delete(caller, apiUrl + id, okCallback, errorCallback);
    }

    moveUpMissionStep(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MOVE_MISSION_STEP_UP"];
        this.post(caller, apiUrl + id, {}, okCallback, errorCallback);
    }

    moveDownMissionStep(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MOVE_MISSION_STEP_DOWN"];
        this.post(caller, apiUrl + id, {}, okCallback, errorCallback);
    }

    downloadInvoice(caller, invoiceId, okCallback,errorCallback = null)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        let apiUrl = this.apiUrls["ORDER_GET_INVOICE_AS_PDF"];
        this.get(caller, apiUrl + invoiceId, okCallback, errorCallback);
    }

    downloadInvoicingRequestAsPdf(caller, orderId, transporterId, okCallback,errorCallback = null)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        let apiUrl = transporterId == null ? 
            this.apiUrls["ORDER_GET_INVOICING_REQUEST_AS_PDF"].replace('{orderId}', orderId) : 
            this.apiUrls["ORDER_GET_PUBLIC_INVOICING_REQUEST_AS_PDF"].replace('{orderId}', orderId).replace("{transporterId}", transporterId)

        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    downloadInvoicingRequestAsHtml(caller, orderId, transporterId, okCallback,errorCallback = null)
    {
        caller.setState({loading : true});

        let user = this.getUser();
        let apiUrl = transporterId == null ? 
            this.apiUrls["ORDER_GET_INVOICING_REQUEST_AS_HTML"].replace('{orderId}', orderId) : 
            this.apiUrls["ORDER_GET_PUBLIC_INVOICING_REQUEST_AS_HTML"].replace('{orderId}', orderId).replace("{transporterId}", transporterId)

        this.get(caller, apiUrl, okCallback, errorCallback);
    }


    // downloadMissionReport(caller, missionId, okCallback,errorCallback = null)
    // {
    //     caller.setState({loading : true});
    //
    //     let user = this.getUser();
    //     let apiUrl = this.apiUrls["MISSION_GET_REPORT_AS_PDF"].replace("{missionId}", missionId);
    //
    //     this.get(caller, apiUrl, okCallback, errorCallback);
    // }

    getTruckSummary(caller, truckId, okCallback, errorCallback = null)
    {
        if (caller)
        {
            caller.setState({loading : true});
        }

        let user = this.getUser();
        let apiUrl = this.apiUrls["TRUCK_GET_SUMMARY"];
        this.get(caller, apiUrl + truckId, okCallback, errorCallback);
    }

    getTransporterInvoiceBillingInfos(caller, orderId, transporterId, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["ORDER_GET_TRANSPORTER_INVOICE_BILLING_INFOS"].replace("{orderId}", orderId).replace("{transporterId}", transporterId);
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    getShipperInvoiceBillingInfos(caller, orderId, shipperId, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["ORDER_GET_SHIPPER_INVOICE_BILLING_INFOS"].replace("{orderId}", orderId).replace("{shipperId}", shipperId);
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    uploadTransporterManualInvoice(caller, transporterId, invoiceId, formData, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["ORDER_UPLOAD_TRANSPORTER_INVOICE"].replace("{invoiceId}", invoiceId).replace("{transporterId}", transporterId);
        const token = this.getToken();
        let headers = {
            "Content-Type": "multipart/form-data; boundary=" + Math.random().toString().substr(2),
            "Authorization" : (token === null ? "" : "Bearer " + token)
        };

        axios.post(this.buildUrl(apiUrl), formData, { // receive two parameter endpoint url ,form data 
        })
        .then(res => { // then print response status
            caller.setState({loading : false});
            okCallback({})
        })
        .catch(function (error) {
            caller.setState({loading : false});
            // handle error
            console.log(error);
        })
    }

    validateTransporterManualInvoice(caller, transporterId, invoiceId, stateCode, datasToPost, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});

        let apiUrl = this.apiUrls["ORDER_VALIDATE_TRANSPORTER_INVOICE"].replace("{invoiceId}", invoiceId).replace("{transporterId}", transporterId).replace("{stateCode}", stateCode);
        this.post(caller, apiUrl, datasToPost, okCallback, errorCallback);
    }
    

    getMobileAppInfos(caller, platformTypeCode, mobileAppCode, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["GET_MOBILE_APP_INFOS_BY_CODE"] + platformTypeCode + "/" + mobileAppCode
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    getCallForBids(caller, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["CALL_FOR_BIDS_GET_ALL"]
        this.get(caller, apiUrl, okCallback, errorCallback);
    }
}


export default BusinessService;
