/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Login from "./views/pages/auth/Login.jsx";
import Register from "./views/pages/auth/Register.jsx";
import RegisterConfirmation from "./views/pages/auth/RegisterConfirmation.jsx"
import AccountValidation from "./views/pages/auth/AccountValidation";
import ForgetPassword from "./views/pages/auth/ForgetPassword";
import CollaboratorAccountValidation from "./views/pages/auth/CollaboratorAccountValidation";
import DriverAccountValidation from "./views/pages/auth/DriverAccountValidation";
import Profile from "./views/pages/auth/Profile.jsx";
import ManualInvoiceUpload from "./views/pages/auth/ManualInvoiceUpload.jsx";
import ManualInvoiceValidation from "./views/pages/auth/ManualInvoiceValidation.jsx";
import ManualInvoicePayment from './views/pages/shipper/ManualInvoicePayment.jsx';
import Book from './V2/Book/Book.jsx';
import Admin from "./V2/Page/Admin/Admin.jsx";

import Company from "./views/pages/common/Company";
import Orders from "./views/pages/common/Orders";
import Mission from "./views/pages/common/Mission";
import Collaborators from "./views/pages/common/Collaborators";
import Collaborator from "./views/pages/common/Collaborator";
import Addresses from "./views/pages/common/Addresses";
import Address from "./views/pages/common/Address";
import Drivers from "./views/pages/transporter/Drivers";
import Driver from "./views/pages/transporter/Driver";
import Trucks from "./views/pages/transporter/Trucks";
import Truck from "./views/pages/transporter/Truck";
import Planning from './views/pages/transporter/Planning';
import CommercialRules from './views/pages/transporter/CommercialRules';

import Buttons from "./views/pages/components/Buttons.jsx";
import Cards from "./views/pages/components/Cards.jsx";
import Components from "./views/pages/forms/Components.jsx";
import Elements from "./views/pages/forms/Elements.jsx";
import Google from "./views/pages/maps/Google.jsx";
import Grid from "./views/pages/components/Grid.jsx";
import Icons from "./views/pages/components/Icons.jsx";
import Lock from "./views/pages/examples/Lock.jsx";
import Notifications from "./views/pages/components/Notifications.jsx";
import Pricing from "./views/pages/examples/Pricing.jsx";
import Timeline from "./views/pages/examples/Timeline.jsx";
import Typography from "./views/pages/components/Typography.jsx";
import Validation from "./views/pages/forms/Validation.jsx";
import Vector from "./views/pages/maps/Vector.jsx";
import ChangePassword from "./views/pages/auth/ChangePassword";
import SearchResult from "./views/pages/search/SearchResult";
import PublicSearchResult from "./views/pages/components/search/SearchResult";
import PublicLogin from './views/pages/public/Login';
import PublicRegister from './views/pages/public/Register';

import PortalSearchForm from "./views/components/search/portalSearchForm";
import OrderRating from "./views/pages/rating/OrderRating";
import MissionRating from "./views/pages/rating/MissionRating";
import MissionStepRating from "./views/pages/rating/MissionStepRating";

import OrderAcceptation from "./views/pages/public/OrderAcceptation";
import OrderRefusal from "./views/pages/public/OrderRefusal";
import ValidateEmailModification from "./views/pages/auth/ValidateEmailModification";
import CancelEmailModification from "./views/pages/auth/CancelEmailModification";
import MobileApp from "./views/pages/public/MobileApp.jsx";
import TransporterIPaymentMethodValidation from './views/pages/validation/TransporterIPaymentMethodValidation'
import TransportersTransferValidation from "./views/pages/validation/TransportersTransferValidation.jsx";
import Config from "./views/pages/shipper/Config.jsx";
import SecurityService from "./proxies/SecurityService.js";
import TransporterOrders from "./views/pages/transporter/TransporterOrders.jsx";
import ShipperOrders from './views/pages/shipper/ShipperOrders.jsx';

const routes = [  
  {
    collapse: true,
    name: "Comptes",
    icon: "ni ni-ungroup text-orange",
    state: "accountCollapse",
    views: [
      {
        path: "/transporter-iban",
        name: "iban-validation",
        vibible: false,
        component: TransporterIPaymentMethodValidation,
        layout: "/sms-validation"
      },
      {
        path: "/transporters-transfer",
        name: "transporter-transfer-validation",
        vibible: false,
        component: TransportersTransferValidation,
        layout: "/sms-validation"
      },
      {
        path: "/app-download/:code",
        name: "app-download",
        visible: false,
        component: MobileApp,
        layout: "/auth"
      },
      {
        path: "/login",
        name: "Login",
        component: Login,
        layout: "/auth"
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
        layout: "/auth"
      },
      {
        path: "/forget-password",
        name: "ForgetPassword",
        component: ForgetPassword,
        layout: "/auth"
      },
      {
        path: "/change-password",
        name: "ChangePassword",
        component: ChangePassword,
        layout: "/auth",
        visible: false
      },
      {
        path: "/register-confirmation",
        name: "RegisterConfirmation",
        component: RegisterConfirmation,
        layout: "/auth",
        visible: false
      },
      {
        path: "/account-validation",
        name: "AccountValidation",
        component: AccountValidation,
        layout: "/auth",
        visible: false
      },
      {
        path: "/validate-email-modification",
        name: "ValidateEmailModification",
        component: ValidateEmailModification,
        layout: "/auth",
        visible: false
      },
      {
        path: "/cancel-email-modification",
        name: "CancelEmailModification",
        component: CancelEmailModification,
        layout: "/auth",
        visible: false
      },
      {
        path: "/collaborator-validation",
        name: "CollaboratorAccountValidation",
        component: CollaboratorAccountValidation,
        layout: "/auth",
        visible: false
      },
      {
        path: "/driver-validation",
        name: "DriverAccountValidation",
        component: DriverAccountValidation,
        layout: "/auth",
        visible: false
      },
      {
        path: "/manual-invoice-upload",
        name: "ManualInvoiceUpload",
        component: ManualInvoiceUpload,
        layout: "/auth",
        visible: false
      },
      {
        path: "/manual-invoice-validation",
        name: "ManualInvoiceValidation",
        component: ManualInvoiceValidation,
        layout: "/auth",
        visible: false
      }
    ]
  },    
  {
    name: "Utilisateur",
    icon: "ni ni-single-02",
    collapse: true,
    views: [
      {
        path: "/profile",
        name: "Mon profil",
        component: Profile,
        layout: "/admin"
      }
    ]
  },
  {
    name: "Commun",
    icon: "ni ni-book-bookmark",
    collapse: true,
    state: "commonCollapse",
    views: [
      {
        path: "/company",
        name: "Informations Société",
        component : Company,
        layout: "/admin"
      },
      {
        path: "/book",
        name: "Catalogue de composant",
        component : Book,
        layout: "/admin"
      },
      {
        path: "/gestion",
        name: "Page de gestion",
        component : Admin,
        layout: "/admin"
      },
      {
        path: "/collaborators",
        name: "Mes collaborateurs",
        component: Collaborators,
        layout: "/admin"
      },
      {
        path: "/new-collaborator",
        name: "Ajouter une collaborateur",
        component: Collaborator,
        layout: "/admin"
      },
      {
        path: "/collaborator/:id",
        name: "Voir un collaborateur spécifique",
        visible: false,
        component: Collaborator,
        layout: "/admin"
      },
      {
        path: "/addresses",
        name: "Mes adresses",
        component: Addresses,
        layout: "/admin"
      },
      {
        path: "/new-address",
        name: "Ajouter une adresse",
        component: Address,
        layout: "/admin"
      },
      {
        path: "/address/:id",
        name: "Voir une adresse spécifique",
        visible: false,
        component: Address,
        layout: "/admin"
      },
      {
        path: "/orders",
        name: "Voir mes commandes",
        component: (new SecurityService().isTransporter() ? TransporterOrders : ShipperOrders),
        layout: "/admin"
      },
      {
        path: "/:orderNumber",
        name: "Voir mes commandes",
        component: (new SecurityService().isTransporter() ? TransporterOrders : ShipperOrders),
        layout: "/orders"
      },
      {
        path: "/mission/:id",
        name: "Voir mes mission spécifique",
        visible: false,
        component: Mission,
        layout: "/admin"
      }
    ]
  },
  { 
    name: "Expéditeur",
    icon: "ni ni-single-02",
    collapse: true,
    state: "shipperCollapse",
    views: [
      {
        path: "/login/:type",
        name: 'Login',
        visible: false,
        component: PublicLogin,
        layout: '/public'
      },
      {
        path: "/register/:type",
        name: 'Register',
        visible: false,
        component: PublicRegister,
        layout: '/public'
      },
      {
        path: "/order/accept/:orderId/:userId/:orderDecisionId",
        name: 'OrderAcceptation',
        visible: false,
        component: OrderAcceptation,
        layout: '/auth'
      },
      {
        path: "/order/refuse/:orderId/:userId/:orderDecisionId",
        name: 'OrderRefusal',
        visible: false,
        component: OrderRefusal,
        layout: '/auth'
      },
      {
        path: "/search",
        name: 'Recherche sur le portail',
        visilbe: false,
        component: () => <PortalSearchForm version={2}/>,
        layout: '/public'
      },
      {
        path: "/search-result/:searchId",
        name: "Résultat de recherche",
        visible: false,
        component: SearchResult,
        layout: "/admin"
      },
      {
        path: "/search-result",
        name: "Nouvelle recherche dans l'application",
        visilbe: false,
        component: PublicSearchResult,
        layout: '/admin'
      },
      {
        path: "/search-result/:searchId",
        name: 'Résultat de recherche sur le portail',
        visilbe: false,
        component: PublicSearchResult,
        layout: '/public'
      },
      {
        path: "/search-result",
        name: 'Résultat de recherche sur le portail',
        visilbe: false,
        component: PublicSearchResult,
        layout: '/public'
      },
      {
        path: "/search-results/:searchId",
        name: 'Résultat de recherche sur le portail',
        visilbe: false,
        component: PublicSearchResult,
        layout: '/admin'
      },
      {
        path: "/manual-invoice-payment",
        name: "ManualInvoicePayment",
        component: ManualInvoicePayment,
        layout: "/admin",
        visible: false
      },
      {
        path: "/config",
        name: "ShipperConfig",
        component: Config,
        layout: "/admin",
        visible: false
      },
      {
        path: "/rating/:rating/unloading/:missionId/:missionStepId/:userId",
        name: "Page de notation sur le ou les déchargement d'un bénéficiaire",
        component: MissionStepRating,
        visible: false,
        layout: '/auth'
      },
      {
        path: "/rating/:rating/mission/:missionId/:userId",
        name: "Page de notation de la mission",
        component: MissionRating,
        visible: false,
        layout: '/auth'
      },
      {
        path: "/rating/:rating/order/:orderId/:userId",
        name: "Page de notation de la commande",
        component: OrderRating,
        visible: false,
        layout: '/auth'
      }
    ]
  },
  {
    name: "Transporter",
    icon: "ni ni-delivery-fast",
    collapse: true,
    state: "transporterCollapse",
    views: [
      {
        path: "/planning",
        name: "Planning",
        component: Planning,
        layout: "/admin"
      },
      {
        path: "/commercial",
        name: "CommercialRules",
        component: CommercialRules,
        layout: "/admin"
      },
      {
        path: "/trucks",
        name: "Mes camions",
        component: Trucks,
        layout: "/admin"
      },
      {
        path: "/new-truck",
        name: "Ajouter un camion",
        component: Truck,
        layout: "/admin"
      },
      {
        path: "/truck/:id",
        name: "Voir un camion spécifique",
        visible: false,
        component: Truck,
        layout: "/admin"
      },
      {
        path: "/drivers",
        name: "Mes chauffeurs",
        component: Drivers,
        layout: "/admin"
      },
      {
        path: "/new-driver",
        name: "Ajouter un chauffeur",
        component: Driver,
        layout: "/admin"
      },
      {
        path: "/driver/:id",
        name: "Voir un chauffeur spécifique",
        visible: false,
        component: Driver,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Dashboards",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    views: [
      
    ]
  },
  {
    collapse: true,
    name: "Examples",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        component: Pricing,
        layout: "/auth"
      },
      {
        path: "/login",
        name: "Login",
        component: Login,
        layout: "/auth"
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
        layout: "/auth"
      },
      {
        path: "/lock",
        name: "Lock",
        component: Lock,
        layout: "/auth"
      },
      {
        path: "/timeline",
        name: "Timeline",
        component: Timeline,
        layout: "/admin"
      },
    ]
  },
  {
    collapse: true,
    name: "Components",
    icon: "ni ni-ui-04 text-info",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        component: Buttons,
        layout: "/admin"
      },
      {
        path: "/cards",
        name: "Cards",
        component: Cards,
        layout: "/admin"
      },
      {
        path: "/grid",
        name: "Grid",
        component: Grid,
        layout: "/admin"
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: Notifications,
        layout: "/admin"
      },
      {
        path: "/icons",
        name: "Icons",
        component: Icons,
        layout: "/admin"
      },
      {
        path: "/typography",
        name: "Typography",
        component: Typography,
        layout: "/admin"
      },
      {
        collapse: true,
        name: "Multi Level",
        state: "multiCollapse",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => {},
            layout: "/"
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => {},
            layout: "/"
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => {},
            layout: "/"
          }
        ]
      }
    ]
  },
  {
    collapse: true,
    name: "Forms",
    icon: "ni ni-single-copy-04 text-pink",
    state: "formsCollapse",
    views: [
      {
        path: "/elements",
        name: "Elements",
        component: Elements,
        layout: "/admin"
      },
      {
        path: "/components",
        name: "Components",
        component: Components,
        layout: "/admin"
      },
      {
        path: "/validation",
        name: "Validation",
        component: Validation,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Maps",
    icon: "ni ni-map-big text-primary",
    state: "mapsCollapse",
    views: [
      {
        path: "/google",
        name: "Google",
        component: Google,
        layout: "/admin"
      },
      {
        path: "/vector",
        name: "Vector",
        component: Vector,
        layout: "/admin"
      }
    ]
  }
];

export default routes;
