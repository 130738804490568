
import React from "react";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
// reactstrap components
import {
    Container,
    Row,
    Col
  } from "reactstrap";
  
import queryString from 'query-string';
import { injectIntl, FormattedMessage } from 'react-intl';
import SecurityService from '../../../proxies/SecurityService'
import DataList from "../components/DataList";
import TruckCard from '../components/card/TruckCard';
import DefaultLink from "../../components/common/defaultLink";
import Title3 from "../../components/common/title3";
import TruckWorkflow from "../../components/workflow/truck/truckWorkflow";
import NotificationComponent from "../components/NotificationComponent";
import FormValidationComponent from "../components/FormValidationComponent";
import StringHelper from "../../../helpers/StringHelper.js";
import Resources from "../../../resources.js";
import WebsiteHelper from "../../../helpers/WebsiteHelper.js";

class Trucks extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.securityService = new SecurityService();
        this.state = {
            user : this.securityService.getUser()
        }
    }

    componentDidMount()
    {
        const parsedQueryString = queryString.parse(window.location.search);
        if (parsedQueryString.mode && parsedQueryString.mode == "add")
        {
            this.truckCreationWorkflowRef.setState({visible: true})
        }
        else if (parsedQueryString.mode && parsedQueryString.mode == "success")
        {
            this.showSuccessToastNotification(
                StringHelper.translate(this, "truck.save_confirmation_title"),
                StringHelper.translate(this, "truck.save_confirmation_message")
            )
        }
    }

    end()
    {
        // Récupération du camion dans les données du processus
        const truckInfos =  this.truckCreationWorkflowRef.getTruck();

        // On informe l'utilisateur uniquement dans le cas ou un camion a été créé
        if (truckInfos.id && truckInfos.id != null)
        {
            // Si le camion est complet
            if (truckInfos.complete)
            {
                this.showSuccessModalNotification(this.translateMessage("truck.complete_creation_title"), <>
                    {StringHelper.translateWithHtml(this, "truck.complete_creation_message", null, null, "div", "text-center")}
                    <Row className="justify-content-center">
                    <DefaultLink small={true}  onClick={() => WebsiteHelper.goTo(this, "/admin/planning")}>
                        {StringHelper.translateWithHtml(this, "truck.complete_creation_planning_link", null, null, "div", "text-center")}
                    </DefaultLink>
                    </Row>
                </>)
            }
            else
            {
                var errorComponent = this.prepareResponseErrorComponent(truckInfos.complete_response, 
                    <>Ci dessous les données manquantes:</>
                )

                var message = <>
                    <Row>
                        <Col lg="6">
                            <div className="text-center" style={{fontSize:"100px", color:"#ff9966"}}>
                                <i className="fas fa-exclamation-triangle"></i>
                            </div>
                            <Title3 center={true}>Les données du véhicule ont été enregistrées en mode &laquo;Brouillon&raquo;.</Title3>
                            <Row className="justify-content-center text-muted lead text-center">
                                <br/>
                                Ce dernier n'est pas encore disponible à la location car certaines informations ne sont pas renseignées.
                                <br/>
                                <br/>    
                            </Row>
                        </Col>
                        <Col lg="6">
                            <div className="text-muted lead t2y-required-fields">
                            {errorComponent}
                            </div>
                            <p style={{textAlign: "justify"}}>
                                <em>Vous pouvez compléter cette fiche à tout moment.</em>
                            </p>
                        </Col>
                        
                    </Row>
                </>

                this.showCustomModalNotification("Information", message, null, true)
            }
            
            this.truckCreationWorkflowRef.reset()
            this.dataListRef.refresh()
        }
    }
 
    render() {
        return (
            <>
            {this.renderParent()}
            <TruckWorkflow childRef={elt => this.truckCreationWorkflowRef = elt} parent={this}></TruckWorkflow>
            {
                <Container className="mt--4" fluid>
                    <Row>
                        <Col xl="12">
                        <DataList
                            context="trucks"
                            title={StringHelper.translate(this, "Default.menu_trucks")}
                            titleIcon={Resources.trucks_logo}
                            childRef={elt => this.dataListRef = elt}
                            allowAddButton={true}
                            addButtonTitle="Ajouter un véhicule"
                            addButtonUrl="/admin/new-truck"
                            allowRemovedButton={true}
                            removedButtonTitle='Voir les véhicules supprimés'
                            notRemovedButtonTitle='Voir les vehicules non supprimés'
                            apiUrl={"/api/transporter/trucks"}
                            apiRemovedUrl={"/api/transporter/trucks/deleted"}
                            apiResponseProperty={"trucks"}
                            cardTemplate={TruckCard}
                            overrideAddButton={() => this.truckCreationWorkflowRef.setState({visible: true})}
                        />
                        </Col>
                    </Row>
                </Container>
            }
            </>
        )
    }
}


export default injectIntl(Trucks)