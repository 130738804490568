
import React from "react";
import PropTypes from 'prop-types';
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    Col,
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    NavLink,
    Modal,
    Button
  } from "reactstrap";
import { Calendar } from 'react-date-range';

import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator"
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import FormHourInput from "../../pages/components/form/FormHourInput";
import ReactDatetime from "react-datetime";
import RepositoryService from "../../../proxies/RepositoryService";

import { injectIntl , FormattedMessage } from 'react-intl';
import FormRepository from "../../pages/components/form/FormRepository";
import MaskedInput from 'react-text-mask'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import StringHelper from '../../../helpers/StringHelper'
import { it } from "date-fns/esm/locale";

var moment = require('moment');
require('moment/locale/fr');

class DefaultImage extends React.Component {

    constructor(props)
    {
        super(props)

        this.svg = false;
        this.css = false;
        this.img = false;
        this.valid = false;
        this.src = null;

        this.state = {
            failed: false
        }
    }

    onError = () => {
        if (this.props.defaultSrc)
        {
            this.setState({ failed: true });
        }
    }

    getType()
    {
        var source = this.props.src;
        if (!source)
        {
            source = "";
        }

        const items = source.split(':');
        switch(items[0].toUpperCase())
        {
            case "SVG":
                this.svg = true;
                this.valid = true;
                break;
            case "CSS": 
                this.css = true;
                this.valid = true;
                break;
            case "IMG":
                this.img = true;
                this.valid = true;
                break;
        }

        if (this.valid == false)
        {
            this.tryToGetType()   
        }
        else
        {
            this.src = items[1];
        }
    }

    tryToGetType()
    {
        var source = this.props.src;
        if (!source)
        {
            source = "";
        }

        // svg
        if (source.toUpperCase().endsWith(".SVG"))
        {
            this.svg = true;
            this.valid = true;
            this.src = source
        }
        else if (StringHelper.checkRegExp(source, /[^\n]*\.[^\n]*$/))
        {
            this.img = true;
            this.valid = true;
            this.src = source;
        }
        else
        {
            this.css = true;
            this.valid = true;
            this.src = source;
        }


        // Gestion du format inconnu
        if (this.valid == false)
        {
            this.svg = true;
            this.src = require("../../../assets/img/icons/unknown.svg").default
            this.valid = true;
        }
    }

    componentDidMount()
    {
    }

    componentWillMount()
    {
        this.getType()
    }

    renderCss()
    {
        let customClassName = this.props.className ? this.props.className : "";
        return <i id={this.props.id} className={this.src + " " + customClassName} style={this.props.style} onClick={this.props.onClick} />
    }

    renderSvg()
    {
        if (this.state.failed)
        {
            return <DefaultImage src={this.props.defaultSrc} className={this.props.className} style={this.props.style}></DefaultImage>
        }
        else
        {
            return <img src={this.src} className={this.props.className} style={this.props.style} onError={() => this.onError()} />;
        }
    }

    renderImg()
    {
        if (this.state.failed)
        {
            return <DefaultImage src={this.props.defaultSrc} className={this.props.className} style={this.props.style}></DefaultImage>
        }
        else
        {
            return <img src={this.src} className={this.props.className} style={this.props.style} onError={() => this.onError()} />;
        }
    }

    render() {
        if (this.svg) return this.renderSvg();
        if (this.css) return this.renderCss();
        if (this.img) return this.renderImg();
    }
}

DefaultImage.propTypes = {
    src: PropTypes.string
}

export default DefaultImage
