/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

class SimpleHeader extends React.Component {

  render() {
    return (
      <>
        <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  {
                    (this.props.name) ?
                    (
                      <>
                        <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                          {this.props.name}
                        </h6>{" "}
                        <Breadcrumb
                          className="d-none d-md-inline-block ml-lg-4"
                          listClassName="breadcrumb-links breadcrumb-dark"
                        >
                          <BreadcrumbItem>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              {
                                (typeof this.props.icon === "undefined" || this.props.icon === null) ? (
                                  <i className="fas fa-home" />
                                ): (
                                  <i className={this.props.icon} />
                                )
                              }
                            </a>
                          </BreadcrumbItem>
                          <BreadcrumbItem aria-current="page" className="active">
                            {this.props.description}
                          </BreadcrumbItem>
                        </Breadcrumb>
                      </>
                    ) : (<>
                      <div className="d-none d-md-inline-block ml-lg-4"></div>
                    </>)
                  }
                  
                  
                </Col>
                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

SimpleHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
};

export default SimpleHeader;
