import React from "react";
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import { isThursday } from "date-fns/esm";
import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator";
import GifLoader from "../../../components/GifLoader";

var moment = require('moment');
require('moment/locale/fr');

class Step extends FormValidationComponent {

    mounted = false

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            submiting: false,
            pid: null,
            size: 'lg',
            title: null,
            subtitle: null,
            image: null
        }
    }

    busy()
    {
        return this.state.loading || this.state.submiting;
    }

    getWorklowInput()
    {
        return this.props.workflow.state.input
    }

    startLoading()
    {
        this.setState({loading: true})
    }

    stopLoading()
    {
        this.setState({loading: false})
    }

    startSubmiting()
    {
        this.setState({submiting: true})
    }

    stopSubmiting()
    {
        this.setState({submiting: false})
    }

    componentWillMount()
    {
        this.props.workflow.addRef(this.props.id, this)
        
        const size = this.props.size ? this.props.size : this.state.size;
        this.props.workflow.setState({size: size, notification_component_alert: null})
    }


    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    submit(pid)
    {
        this.setState({pid: pid})
    }

    shouldReferToWorkflow()
    {
        return this.props.workflow.props.workflow != null;
    }

    showGenericError(httpErrorResponse, className, methodName)
    {
        alert("Erreur dans " + className + ":" + methodName);
    }

    showError(message, title)
    {
        this.props.workflow.showError(message, title)
    }


    showConfirmation(message, title)
    {
        this.props.workflow.showConfirmation(message, title)
    }

    renderLoading()
    {
        return <>
						<GifLoader width={200} height={200}/>
            <FormLoadingIndicator context={this} loading={true} />
        </>
    }

    renderSubmiting()
    {
        return <>
						<GifLoader width={200} height={200}/>
            <FormLoadingIndicator context={this} loading={true} />
        </>
    }

    renderAsBusy()
    {
        return <>
            {
                (this.state.loading) ?
                (
                    this.renderLoading()
                )
                :
                (
                    (this.state.submiting)?
                    (
                        this.renderSubmiting()
                    ): (<></>)
                )
            }
        </>
    }
}


export default Step

