import React from "react";
import PropTypes from 'prop-types'
import {
    FormGroup,
    UncontrolledTooltip,
    Row,
    Col,
    Label
} from "reactstrap";
import Resources from '../../../../resources'
import StringHelper from '../../../../helpers/StringHelper'
import { injectIntl } from 'react-intl';
import DefaultPopover from "../../../components/common/defaultPopover";

class FormGroupInput extends React.Component {

    constructor(props)
    {
        super(props)
    }

    getLabel()
    {
        return this.props.intlId ? this.props.intl.formatMessage({"id": this.props.intlId, "defaultMessage": this.props.intlId}) : this.props.label;
    }

    renderTooltip()
    {
        if (this.props.tooltip)
        {
            const icon = this.props.tooltip.icon ? this.props.tooltip.icon : Resources.info_logo;
            const color = (this.props.tooltip.color ? "text-" + this.props.tooltip.color : "");
            
            return <>
                &nbsp;
                <DefaultPopover style={{fontSize:"18px", color:"#525f7f", backgroundColor:"transparent", top:"-5px"}} className={ icon + " " + color} content={this.props.tooltip.message}></DefaultPopover>
            </>
        }
    }
    renderInline()
    {
        var contentColumn = this.props.contentColumns
        var label = <></>
        if (this.props.label && this.props.label != "")
        {

            label = <label className={"form-control-label col-md-" + this.props.labelColumns} htmlFor={this.props.htmlFor}>
                {this.getLabel()}
                {this.renderTooltip()}
            </label>
        }
        else
        {
            contentColumn = 12
        }

        return <FormGroup className={"row " + (this.props.className ? this.props.className : "")} style={this.props.style}>
            {label}
            <Col md={contentColumn}>
                {this.props.children}
            </Col>
        </FormGroup>
    }

    
    renderMultiline()
    {
        return <FormGroup style={this.props.style} className={"t2y-form-group-multiline "}>
            <label className={"form-control-label " + this.props.labelClassName} htmlFor={this.props.htmlFor}>
                {this.getLabel()}
            </label>
            {this.renderTooltip()}
            {this.props.children}
        </FormGroup>
    }

    render() {
        return (
            <>
            { this.props.type == "inline" ? this.renderInline() : (this.props.type == "multiline" ? this.renderMultiline() : "") }
            </>
        )
    }

}

FormGroupInput.defaultProps = {
    type: 'inline',
    labelColumns: "4",
    contentColumns: "8"
};

FormGroupInput.propTypes = {
    htmlFor: PropTypes.string,
    label: PropTypes.string,
    intlId: PropTypes.string,
    tooltip: PropTypes.shape({
        icon: PropTypes.string,
        color: PropTypes.string,
        message: PropTypes.string
    }),
    labelColumns: PropTypes.string,
    contentColumns: PropTypes.string,
    type: PropTypes.oneOf(["inline", "multiline"])
}

export default injectIntl(FormGroupInput)