
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Button, Row, Col, Badge, UncontrolledPopover, PopoverBody, UncontrolledTooltip
  } from "reactstrap";
import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultLabel from "./defaultLabel";
import Resources from "../../../resources";
import StringHelper from "../../../helpers/StringHelper";
import message from "./message";
import DefaultImage from "./defaultImage";

class DefaultPopover extends React.Component {

    constructor(props)
    {
        super(props)

        this.open = false;
        this.state = {
            open: true
        }
    }

    componentDidMount() {}

    componentWillMount() {}

    componentWillUnmount() {}

    onMouseEnter()
    {
        this.open = true
    }


    onMouseLeave()
    {
        this.open = false
    }

    render() 
    {
        var targetId = StringHelper.createDomElementId();
        var logo = this.props.logo ? this.props.logo : Resources.info_logo;
        var style = {position:"relative", top:"-10px", ...this.props.style};

        if (this.props.right == true)
        {
            style = {position: "absolute", float:'right', top:'-10px', right:'-5px'}
        }

        if (!this.props.content || StringHelper.notEmptyValue(this.props.content) == false || this.props.content == "")
        {
            return <></>
        }
        return <>
            {
                (this.props.component) ?
                (
                    this.props.component
                ) : 
                (
                <Badge onClick={(elt) => false}
                    id={this.props.targetId ? this.props.targetId : targetId} 
                    style={style}
                    className="badge-md badge-circle badge-floating border-white">                        
                    <DefaultImage src={logo} className={this.props.className} />
                </Badge>
                )
            }
            <UncontrolledTooltip className="t2y-default-popover" style={{...this.props.tooltipStyle, backgroundColor:"white", color: "black", border: "solid 1px black", borderRadius: "5px"}}
            placement={this.props.placement ? this.props.placement : "top"}
            target={this.props.targetId ? this.props.targetId : targetId} >
            
                    {this.props.content}
            </UncontrolledTooltip>
            
        </>

    }
}

export default injectIntl(DefaultPopover)
