import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";

import DefaultInput from "../../common/defaultInput";
import PasswordInput from "../../common/passwordInput";
import Resources from "../../../../resources";
import Title2 from "../../common/title2"
import Title3 from "../../common/title3"


import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import SecurityService from "../../../../proxies/SecurityService";
import Step from "../step";

var moment = require('moment');
require('moment/locale/fr');

class ForgetPasswordConfirmationStep extends Step {

    constructor(props) {
        super(props)
        
        this.validationRules = {}

        this.state = {
        }
    }


    componentWillMount() {
        super.componentWillMount()
    }

    
    render() {
        return (
            <>
            {this.renderParent()}
            <Container>
                <Row>
                    <Col xs="3"></Col> 
                    <Col xs="6" className="justify-content-text-center">
                        <img src={require('../../../../assets/img/illustrations/accountWorkflow_forgetPasswordConfirmationStep.svg').default} style={{width:"100%"}} />
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" className="text-center">
                        <Title2 content={this.props.intl.formatMessage({"id" : "ForgetPassword.confirmation_title"})}>
                        </Title2>
                        <Title3>
                            <FormattedMessage id="ForgetPassword.confirmation_message">
                                { this.decodeEntities }
                            </FormattedMessage>
                        </Title3>
                    </Col>
                    
                </Row>

            </Container>
            </>
        );
    }
}


export default injectIntl(ForgetPasswordConfirmationStep)

