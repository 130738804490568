import Config from '../config.js'
import ProxyService from './ProxyService'
import { request } from 'https';
import DateHelper from '../helpers/DateHelper.js';

class ShipperService extends ProxyService {

    quickSearch(caller, searchRequest, okCallback, errorCallback)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["SHIPPER_QUICK_SEARCH"];
        this.post(caller, apiUrl, searchRequest, okCallback, errorCallback);
    }

    getSearchResult(caller, request, okCallback, errorCallback)
    {
        if (caller != null)
            caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["SHIPPER_SEARCH_RESULTS"];
        this.get(caller, apiUrl + request.searchId + "/" + request.order + "/" + request.from + "/" + request.nb, okCallback, errorCallback);
    }

    getSearchResultDetail(caller, resultId, okCallback, errorCallback)
    {
        if (caller != null)
            caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["SHIPPER_SEARCH_RESULT_DETAIL"];
        this.get(caller, apiUrl + resultId, okCallback, errorCallback);
    }

    applyFilters(caller, searchId, postedData, okCallback, errorCallback)
    {
        if (caller != null)
        caller.setState({loading : true});
    
        let apiUrl = this.apiUrls["SHIPPER_SEARCH_APPLY_FILTERS"];
        this.post(caller, apiUrl + searchId, postedData, okCallback, errorCallback);
    }

    createOrder(caller, postedData, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["SHIPPER_CREATE_ORDER"];
        this.put(caller, apiUrl, postedData, okCallback, errorCallback);
    }

    patchOrder(caller, orderId, property, value, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["SHIPPER_PATCH_ORDER"]
            .replace("{orderId}", orderId)
            .replace("{property}", property);
        this.post(caller, apiUrl, { value: value}, okCallback, errorCallback);
    }

    submitOrder(caller, orderId, paymentMethodExternalId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["SHIPPER_SUBMIT_ORDER"];
        this.post(caller, apiUrl + orderId, { payment_method_external_id: paymentMethodExternalId}, okCallback, errorCallback);
    }

    validateOrder(caller, orderId, postedData, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["SHIPPER_VALIDATE_ORDER"];
        this.post(caller, apiUrl + orderId, postedData, okCallback, errorCallback);
    }

    cancelOrder(caller, orderId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_CANCEL_ORDER"]
            .replace("{orderId}", orderId)
        ;
        this.delete(caller, apiUrl, okCallback, errorCallback);
    }

    createRetainerInvoice(caller, orderId, postedData, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["SHIPPER_CREATE_RETAINER_INVOICE"];
        this.post(caller, apiUrl + orderId, postedData, okCallback, errorCallback);
    }

    createPaymentIntentForCard(caller, amount, okCallback, errorCallback = null)
    {
        let apiUrl = this.apiUrls["SHIPPER_CREATE_PAYMENT_INTENT_CARD"];
        this.post(caller, apiUrl, { amount: amount}, okCallback, errorCallback);
    }

    getAllReusablePaymentMethods(caller, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});
            
        let apiUrl = this.apiUrls["SHIPPER_GET_ALL_REUSABLE_PAYMENT_METHODS"];
        this.get(caller, apiUrl, okCallback, errorCallback);
    }
    
    getCardsAvailable(caller, cardPaymentMethodMinExpirationDate, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["SHIPPER_GET_CARDS_AVAILABLE"];

        if (cardPaymentMethodMinExpirationDate)
        {
            apiUrl = apiUrl + "?cardPaymentMethodMinExpirationDate=" + DateHelper.toString(cardPaymentMethodMinExpirationDate, "YYYY-MM-DD")
        }

        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    getIbansAvailable(caller, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["SHIPPER_GET_IBANS_AVAILABLE"];
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    completeOrder(caller, orderId, okCallback, errorCallback){

        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = "/api/collect/payment/order/" + orderId;
        this.post(caller, apiUrl, {}, okCallback, errorCallback);
    }

    orderRating(caller, orderId, userId, rating, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_ORDER_RATING"]
            .replace("{rating}", rating)
            .replace("{orderId}", orderId)
            .replace("{userId}", userId)
        ;
        this.post(caller, apiUrl, {}, okCallback, errorCallback);
    }

    orderRatingComment(caller, orderId, userId, comment, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_ORDER_RATING_COMMENT"]
            .replace("{orderId}", orderId)
            .replace("{userId}", userId)
        ;
        this.post(caller, apiUrl, {"comment": comment}, okCallback, errorCallback);
    }

    missionRating(caller, missionId, userId, rating, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_RATING"]
            .replace("{rating}", rating)
            .replace("{missionId}", missionId)
            .replace("{userId}", userId)
        ;
        this.post(caller, apiUrl, {}, okCallback, errorCallback);
    }

    missionRatingComment(caller, missionId, userId, comment, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_RATING_COMMENT"]
            .replace("{missionId}", missionId)
            .replace("{userId}", userId)
        ;
        this.post(caller, apiUrl, {"comment": comment}, okCallback, errorCallback);
    }

    getMissionUnloadingRatingInfos(caller, missionId, missionStepId, userId, rating, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_UNLOADING_RATING_INFOS"]
            .replace("{rating}", rating)
            .replace("{missionId}", missionId)
            .replace("{missionStepId}", missionStepId)
            .replace("{userId}", userId)
        ;
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    missionUnloadingRating(caller, missionId, missionStepId, userId, rating, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_UNLOADING_RATING"]
            .replace("{rating}", rating)
            .replace("{missionId}", missionId)
            .replace("{missionStepId}", missionStepId)
            .replace("{userId}", userId)
        ;
        this.post(caller, apiUrl, {}, okCallback, errorCallback);
    }

    missionUnloadingRatingComment(caller, missionId, missionStepId, userId, comment, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_UNLOADING_RATING_COMMENT"]
            .replace("{missionId}", missionId)
            .replace("{missionStepId}", missionStepId)
            .replace("{userId}", userId)
        ;
        this.post(caller, apiUrl, {"comment": comment}, okCallback, errorCallback);
    }


    getAddressContacts(caller, addressId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["SHIPPER_GET_ADDRESS_CONTACTS"].replace("{addressId}", addressId);
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    getTransportOperationById(caller, missionId, transportOperationId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["SHIPPER_MISSION_TRANSPORT_OPERATION_GET_BY_ID"]
            .replace("{missionId}", missionId)
            .replace("{transportOperationId}", transportOperationId)
        ;
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    getTransportOperationsForMission(caller, missionId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["SHIPPER_MISSION_TRANSPORT_OPERATION_GET_ALL"].replace("{missionId}", missionId);
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    downloadTransportOperationFreightDocument(caller, missionId, transportOperationId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_TRANSPORT_OPERATION_FEIGHT_DOCUMENT"]
            .replace("{missionId}", missionId)
            .replace("{transportOperationId}", transportOperationId)

        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    publicDownloadTransportOperationFreightDocument(caller, missionId, transportOperationId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_TRANSPORT_OPERATION_FEIGHT_DOCUMENT"]
            .replace("{missionId}", missionId)
            .replace("{transportOperationId}", transportOperationId)
            .replace('/api/', '/')

        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    downloadSepaMandate(caller, paymentMethodId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_DOWNLOAD_SEPA_MANDATE"]
            .replace("{paymentMethodId}", paymentMethodId)

        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    sendSepaMandateByEmail(caller, paymentMethodId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_SEND_SEPA_MANDATE"]
            .replace("{paymentMethodId}", paymentMethodId)

        this.post(caller, apiUrl, {}, okCallback, errorCallback);
    }

    saveOperationTransportDepartureAddress(caller, datas, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_TRANSPORT_OPERATION_DEPARTURE"]
            .replace("{missionId}", datas.mission_id)
            .replace("{transportOperationId}", datas.id ? datas.id : "")
        ;
        this.post(caller, apiUrl, datas, okCallback, errorCallback);
    }

    saveDepartureAddressDefaultContact(caller, datas, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_TRANSPORT_OPERATION_DEPARTURE_DEFAULT_CONTACT"]
            .replace("{missionId}", datas.mission_id)
            .replace("{transportOperationId}", datas.id ? datas.id : "")
        ;
        this.post(caller, apiUrl, datas, okCallback, errorCallback);
    }

    saveOperationTransportArrivalAddress(caller, datas, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_TRANSPORT_OPERATION_ARRIVAL"]
            .replace("{missionId}", datas.mission_id)
            .replace("{transportOperationId}", datas.id ? datas.id : "")
        ;
        this.post(caller, apiUrl, datas, okCallback, errorCallback);
    }

    saveArrivalAddressDefaultContact(caller, datas, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_TRANSPORT_OPERATION_ARRIVAL_DEFAULT_CONTACT"]
            .replace("{missionId}", datas.mission_id)
            .replace("{transportOperationId}", datas.id ? datas.id : "")
        ;
        this.post(caller, apiUrl, datas, okCallback, errorCallback);
    }

    saveOperationTransportOptions(caller, datas, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_TRANSPORT_OPERATION_OPTIONS"]
            .replace("{missionId}", datas.mission_id)
            .replace("{transportOperationId}", datas.id ? datas.id : "")
        ;
        this.post(caller, apiUrl, datas, okCallback, errorCallback);
    }

    saveOperationTransportGoodItem(caller, datas, okCallback, errorCallback =null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_TRANSPORT_OPERATION_GOOD"]
            .replace("{missionId}", datas.mission_id)
            .replace("{transportOperationId}", datas.id ? datas.id : "")
            .replace("/{goodId}", datas.good_id ? "/" + datas.good_id: "")
        ;
        this.post(caller, apiUrl, datas, okCallback, errorCallback);
    }

    deleteOperationTransportGoodItem(caller, datas, okCallback, errorCallback =null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_TRANSPORT_OPERATION_GOOD"]
            .replace("{missionId}", datas.mission_id)
            .replace("{transportOperationId}", datas.id)
            .replace("{goodId}", datas.good_id)
        ;
        this.delete(caller, apiUrl, okCallback, errorCallback);
    }

    deleteOperationTransport(caller, missionId, transportOperationId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MISSION_TRANSPORT_OPERATION_GET_BY_ID"]
            .replace("{missionId}", missionId)
            .replace("{transportOperationId}", transportOperationId)
        ;
        this.delete(caller, apiUrl, okCallback, errorCallback);
    }

    getCustomers(caller, criteria, person, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_GET_CUSTOMERS_BY_CRITERIA"];
        this.post(caller, apiUrl, {criteria: criteria, person: person}, okCallback, errorCallback);
    }

    getCustomerAddressContacts(caller, customerId, addressId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_GET_CUSTOMER_ADDRESS_CONTACTS"]
            .replace("{customerId}", customerId)
            .replace("{addressId}", addressId)
        ;

        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    getCustomerContacts(caller, customerId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_GET_CUSTOMER_CONTACTS"].replace("{customerId}", customerId);
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    saveTransportOperationAddress(caller, datas, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_ADD_TRANSPORT_OPERATION_ADDRESS"];
        this.post(caller, apiUrl, datas, okCallback, errorCallback);
    }

    saveTransportOperationContact(caller, datas, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_SAVE_TRANSPORT_OPERATION_CONTACT"];
        this.post(caller, apiUrl, datas, okCallback, errorCallback);
    }

    addKnownCustomerContactsToAddress(caller, customerId, addressId, contactIds, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_ADD_CUSTOMER_CONTACTS_TO_ADDRESS"]
            .replace("{customerId}", customerId)
            .replace("{addressId}", addressId)
        ;
        this.post(caller, apiUrl, {
            contact_ids: contactIds
        }, okCallback, errorCallback);
    }

    addUnknownCustomerContactsToAddress(caller, customerId, addressId, contactInfos, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_ADD_CUSTOMER_NEW_CONTACT_TO_ADDRESS"]
            .replace("{customerId}", customerId)
            .replace("{addressId}", addressId)
        ;
        this.put(caller, apiUrl, {
            contact: contactInfos
        }, okCallback, errorCallback);
    }

    deleteCustomerAddressContact(caller, customerId, addressId, contactId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_DELETE_CUSTOMER_ADDRESS_CONTACT"]
            .replace("{customerId}", customerId)
            .replace("{addressId}", addressId)
            .replace("{contactId}", contactId)
        ;
            
        this.delete(caller, apiUrl, okCallback, errorCallback);
    }

    saveCustomerAddress(caller, postedDatas, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        postedDatas.company = postedDatas.customer ? postedDatas.customer.company : true;
        postedDatas.customer_id = postedDatas.customer ? postedDatas.customer.id : null;
        postedDatas.customer_company_name = postedDatas.customer ? postedDatas.customer.label : null;
        postedDatas.customer_first_name = postedDatas.customer ? postedDatas.customer.first_name : null;
        postedDatas.customer_last_name = postedDatas.customer ? postedDatas.customer.last_name : null;
        postedDatas.customer_mobile_phone_number = postedDatas.customer ? postedDatas.customer.mobile_phone_number : null;
        postedDatas.customer_email = postedDatas.customer ? postedDatas.customer.email : null;

        let apiUrl = this.apiUrls["SHIPPER_SAVE_CUSTOMER_ADDRESS"];
        this.post(caller, apiUrl, postedDatas, okCallback, errorCallback);
    }

    searchForCustomerAddress(caller, customerId, criteria, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_SEARCH_FOR_CUSTOMER_ADDRESS"].replace("{customerId}", customerId);
        this.post(caller, apiUrl, {
            criteria: criteria
        }, okCallback, errorCallback);
    }

    getShipperOrderManualPaymentInfos(caller, orderId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_MANUAL_ORDER_PAYMENT_INFOS"].replace("{orderId}", orderId);
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    payInvoiceAfterIncident(caller, params, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_PAY_INVOICE_AFTER_INCIDENT"];
        this.post(caller, apiUrl, params, okCallback, errorCallback);
    }

    saveConfiguration(caller, params, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_SAVE_CONFIGURATION"];
        this.post(caller, apiUrl, params, okCallback, errorCallback);
    }


    callForBids(caller, searchId, values, nbDayToAdd, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["SHIPPER_SEARCH_CALL_FOR_BIDS"].replace("{searchId}", searchId);
        this.post(caller, apiUrl, {values: values, days: nbDayToAdd}, okCallback, errorCallback);
    }

    cancelCallForBids(caller, callForBidsId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["CANCEL_CALL_FOR_BIDS"].replace("{callForBidsId}", callForBidsId);
        this.post(caller, apiUrl, {}, okCallback, errorCallback);
    }

    refuseCallForBidsResult(caller, callForBidsResultId, okCallback, errorCallback = null)
    {
        if (caller != null)
            caller.setState({loading : true});

        let apiUrl = this.apiUrls["REFUSE_CALL_FOR_BIDS_RESULT"].replace("{callForBidsResultId}", callForBidsResultId);
        this.post(caller, apiUrl, {}, okCallback, errorCallback);
    }
    
}



export default ShipperService;