
import React from "react";
import PropTypes from 'prop-types';

// reactstrap components
import {
    Row,
    Col,
  } from "reactstrap";

import { injectIntl , FormattedMessage } from 'react-intl';
import Title2 from "./title2"
import Title4 from "./title4"
import DefaultImage from "./defaultImage"
import StringHelper from "../../../helpers/StringHelper";
import Resources from "../../../resources";

class NotFoundScene extends React.Component 
{
    render()
    {
        return <Row className="align-items-center">
            <Col xs="2"></Col>
            <Col xs="8">
                <Row className="justify-content-center t2y-not-found-container">
                        <DefaultImage 
                            src={require("../../../assets/img/illustrations/not_found_background.svg").default} 
                            style={{width:(this.props.width ? this.props.width : "100%")}}
                        >
                        </DefaultImage>
                        <DefaultImage src={this.props.icon} className="t2y-not-found-foreground"></DefaultImage>
                </Row>
            </Col>
        </Row>
    }
}

NotFoundScene.defaultProps = {
    
}

NotFoundScene.propTypes = {   
    
};

export default injectIntl(NotFoundScene)




