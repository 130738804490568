
import React from "react";
import PropTypes from 'prop-types';

// reactstrap components
import {
    Row,
    Col,
  } from "reactstrap";

import { injectIntl , FormattedMessage } from 'react-intl';
import Title2 from "./title2"
import Title4 from "./title4"
import DefaultImage from "./defaultImage"
import StringHelper from "../../../helpers/StringHelper";
import Resources from "../../../resources";

class EmptyResult extends React.Component 
{
    render()
    {
        var title = this.props.title ? this.props.title: StringHelper.translate(this, "Default.datalist_sorry_title")
        var description = this.props.description ? this.props.description: StringHelper.translate(this, "Default.datalist_sorry_default_description")
        var imageKey = "no_datas_found"
        if (this.props.context)
        {
            imageKey = "no_" + this.props.context.replace(" ", "_") + "_found"
        }
        
        return <Row className="align-items-center mb-3">
            <Col xs="2"></Col>
            <Col xs="8">
                <Row className="justify-content-center">
                    {
                        (this.props.component) ?
                        (
                            this.props.component
                        ) 
                        :
                        (
                            <DefaultImage 
                                src={Resources[imageKey]} 
                                defaultSrc={require("../../../assets/img/illustrations/component_dataList_notFound_default.svg").default} 
                                style={{width:"30%"}}>
                            </DefaultImage>
                        )
                    }
                </Row>
                {
                    (this.props.showTitle) ?
                    (
                        <Row className="justify-content-center">
                            <Title2>{title}</Title2>
                        </Row>
                    ) : (<></>)
                }
                {
                    (this.props.showDescription) ?
                    (
                        <Row className="justify-content-center">
                            <Title4>{description}</Title4>
                        </Row>
                    ) : (<></>)
                }
                {
                    (this.props.separator) ?
                    (
                        <hr/>
                    ) : (<></>)
                }
            </Col>
        </Row>
    }
}

EmptyResult.defaultProps = {
    showTitle: true,
    showDescription: true,
    separator: true
}

EmptyResult.propTypes = {   
    showTitle: PropTypes.bool,
    showDescription: PropTypes.bool,
    separator: PropTypes.bool
};

export default injectIntl(EmptyResult)




