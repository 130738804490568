import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Row,
    Col,
    Container,
    Card,
    CardHeader,
    CardBody,
    Collapse
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import Step from "../step";
import Config from "../../../../config"
import ShipperService from "../../../../proxies/ShipperService";

import InjectedCreditCardComponent from "../../payment/CreditCardComponent"
import PaymentComponent from "../../payment/PaymentComponent"
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import DateLabel from "../../common/dateLabel";
import StringHelper from "../../../../helpers/StringHelper";
const stripePromise = loadStripe(Config.stripePublicKey);


class PaymentStep extends Step {

    constructor(props) {
        super(props)
        
        this.shipperService = new ShipperService();
        this.state = {
            payment_on_invoice: this.getPaymentOnInvoice(),
            order_retainer_amount_to_pay_ttc: 0,
            order_retainer_amount_to_pay_ht: 0
        }
    }

    componentWillMount() {
        this.startLoading();
        super.componentWillMount()

        // Récupération des données
        // Récupération du résultat (camion, notes, prix, disponibilité, ...)
        const resultId = this.props.workflow.state.input;

        // Récupération des données de la recherche / commande
        this.shipperService.getSearchResultDetail(null, resultId, (response) => {
            // Récupération des données de la commande 
            // Montant à payer TTC
            // Montant à payer HT
            this.setState({
                resultId: resultId,
                result: response,
                order_id: response.order_id,
                order_start_date: response.order_start_date,
                order_end_date: response.order_end_date ? response.order_end_date : response.order_start_date,
                retainer_amount_to_pay_ttc: response.retainer_amount_to_pay_ttc,
                retainer_amount_to_pay_ht: response.retainer_amount_to_pay_ht,
            }, () => {
                if (this.getPaymentOnInvoice())
                {
                    this.onPaymentSuccess({
                        payment_methodExternal_id: null,
                        payment_external_id: null,
                        amount_paied_ttc: 0,
                        amount_paied_ht: 0
                    })
                }
            })    

            this.stopLoading();
        });
        
    }

    getPaymentOnInvoice()
    {
        const currentUser = this.shipperService.getCompany()
        return currentUser.payment_on_invoice;
    }

    getOrderId()
    {
        return this.state.order_id
    }

    componentDidMount() {
        super.componentDidMount()
    }

    allowNextStep()
    {
        return false;
    }

    submitPayment(pid, orderId)
    {
        var params = {
            payment_method_external_id: this.paymentInfos.payment_methodExternal_id,
            payment_intent_external_id: this.paymentInfos.payment_external_id,
            amount_to_pay_ttc: this.paymentInfos.amount_paied_ttc,
            amount_to_pay_ht: this.paymentInfos.amount_paied_ht
        }
        this.shipperService.validateOrder(this, orderId, params, (response) => {
            super.submit(pid)

            // Passage à l'étape suivante
            this.props.workflow.goToNextStep(pid, {order_id: orderId, payment: this.paymentInfos})

            this.stopSubmiting()
        }, (httpErrorResponse) => {
            this.stopSubmiting();
            this.stopLoading();
            httpErrorResponse.json().then((apiResponse) => {
                this.showErrorFromResponse(apiResponse, null)
            })
        })

    }

    submit(pid, input)
    {
        var formIsValid = this.htmlFormIsValid();
        if (formIsValid)
        {
            this.startSubmiting()

            // Gestion du mode appel d'offres
            if (this.state.result.type == "CALL_FOR_BIDS_RESULT")
            {
                // Création de la commande
                var datasToPost = {
                    result_id: this.state.resultId,
                    dates: this.state.result.proposed_days[0].dates[0],
                    half_day: this.state.result.half_day
                }
    
                this.shipperService.createOrder(this, datasToPost, (response) => {
                    super.submit(pid)
    
                    // Récupération de l'identifiant de la commande
                    const orderId = response.id;
                    // Payment de la commande
                    this.submitPayment(pid, orderId)
                }, (httpErrorResponse) => {
                    if (httpErrorResponse.status == 401)
                    {
                        // Gestion de l'utilisateur non connecté
                        this.props.workflow.notConnected();
                    }
                    else 
                    {
                        this.stopSubmiting();
                        this.stopLoading();
                        httpErrorResponse.json().then((apiResponse) => {
                            this.showErrorFromResponse(apiResponse)
                        })
                    }
    
                    this.stopSubmiting()
                })
            }
            else{
                // Validation automatique de la commande
                this.submitPayment(pid, this.state.order_id)
            }

            
            /*
            this.shipperService.submitOrder(this, this.state.order_id, this.paymentInfos.payment_methodExternal_id, (response) => {
                super.submit(pid)

                // Passage à l'étape suivante
                this.props.workflow.goToNextStep(pid)

                this.stopSubmiting()
            }, (httpErrorResponse) => {
                this.stopSubmiting();
                this.stopLoading();
                httpErrorResponse.json().then((apiResponse) => {
                    this.showErrorFromResponse(apiResponse, null)
                })
            })*/
        }
    }

    onPaymentSuccess(paymentInfos)
    {
        // Récupération des données de paiement pour passer à l'étape suivante
        this.paymentInfos = paymentInfos;
        //this.props.workflow.submitStep({id: this.props.id}, {step: "ORDER_PAYMENT_CONFIRMATION"})
        this.props.workflow.submitStep({id: this.props.id}, {step: "CONFIRMATION_CHOOSE_FIRST_ARRIVALTIME"})
    }

    onPaymentError(error)
    {
        this.stopSubmiting();
        this.stopLoading();
        if (error != null && error.data != null)
        {
            this.showErrorFromResponse(error.data)
        }
        else
        {
            this.showError(error, "Impossible de traiter votre demande")
        }
    }

    renderCreditCardPaymentComponent()
    {
        return <>
            <PaymentComponent
                orderId={this.state.order_id}
                onlyPaymentMethod={false}
                ttc={this.state.retainer_amount_to_pay_ttc} 
                rescue={false} 
                lastPayment={false}
                onPaymentSuccess={(paymentInfos) => this.onPaymentSuccess(paymentInfos)} 
                onPaymentError={(error) => this.onPaymentError(error)} 
                paymentDescription={<>
                    Un acompte de 50% du prix de votre commande est à régler pour valider votre commande. Le solde sera encaissé après la réalisation de la prestation.
                </>}
                paymentButtonTitle={StringHelper.translate(this, "order.payment_step_pay_button_title")}
                cardPaymentMethodMinExpirationDate={this.state.order_end_date}
            />
            {/*
            <Container>
                <Row> 
                    <Col lg="12" md="12" className="order-lg-1 order-2">
                        <Elements stripe={stripePromise}>
                            <InjectedCreditCardComponent
                                ttcAmount={this.state.retainer_amount_to_pay_ttc} 
                                htAmount={this.state.retainer_amount_to_pay_ht}
                                onlyPaymentMethod={false}
                                onStart={() => {}} 
                                onSuccess={(paymentInfos) => this.onPaymentSuccess(paymentInfos)} 
                                onError={(error) => this.onPaymentError(error)} 
                            />
                        </Elements>
                    </Col>
                </Row>
            </Container>*/}
        </>
    }

    render() {
        if (this.state.payment_on_invoice)
        {
            return <>
                {this.renderParent()}
                {super.renderAsBusy()}
            </>
        }
        else 
        {
            return <>
                {this.renderParent()}
                {
                    (this.busy()) ?
                    (
                        super.renderAsBusy()
                    )
                    :
                    (
                        this.renderCreditCardPaymentComponent()
                    )
                }
            </>
        }
    }
}


export default injectIntl(PaymentStep)

