import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";

const Offer = ({ isConnected }) => {
    const [loaded, setLoaded] = useState(false)
    const history = useHistory();

    useEffect(() => {
        const iframe = document.getElementById("iframeV2")
        if(iframe && iframe.parentNode) {
            iframe.addEventListener("load", onLoad)
        }
    }, [])

    function onLoad() {
        setLoaded(true)
    }

    useEffect(() => {
        if (loaded) {
            let win = window.frames.iframeV2
            win.postMessage({
                token: localStorage.getItem("token")
            }, process.env.REACT_APP_FRONT_V2_DOMAIN)
        }
    }, [loaded])

    useEffect(() => {
        const body = document.getElementsByTagName("body")[0]
        if(window.parent && body) {
            body.style.setProperty('background-color', 'transparent', 'important');
        }
        // Créer une instance de l'observer
        const observer = new MutationObserver((mutationsList, observer) => {
            for(let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const zohoHelper = document.getElementById("zohohc-asap-web-launcherbox")
                    if(zohoHelper) {
                        zohoHelper.style.setProperty('display', 'none')
                        observer.disconnect(); // Arrêter l'observation une fois que l'élément est trouvé et caché
                    }
                }
            }
        });

        // Commencer à observer le document avec la configuration configurée
        observer.observe(document, { childList: true, subtree: true });
    }, []);

    useEffect(() => {
        function receiveMessage(event) {
            // Vérifiez que l'événement provient de la source attendue
            if (event.origin !== process.env.REACT_APP_FRONT_V2_DOMAIN) return
            if(event.data.goToProvider) {
                if(isConnected) {
                    if (window.parent) { // checking if it is an iframe
                        window.parent.location.href = process.env.REACT_APP_FRONT_V1_DOMAIN + `/admin/search-result?category=${event.data.utilityKind ? "1" : "0"}`;
                    } else {
                        history.push(`/admin/search-result?category=${event.data.utilityKind ? "1" : "0"}`)
                    }

                } else {
                    if (window.parent) { // checking if it is an iframe
                        window.parent.location.href = process.env.REACT_APP_FRONT_V1_DOMAIN + `/public/search-result?category=${event.data.utilityKind ? "1" : "0"}`;
                    } else {
                        history.push(`search-result?category=${event.data.utilityKind ? "1" : "0"}`)
                    }
                }
            }
            if(event.data.goToCustom) {
                if (window.parent) { // checking if it is an iframe
                    if(event.data.utilityKind) {
                        window.parent.location.href = process.env.REACT_APP_FRONT_V2_DOMAIN + "/utility-custom";
                    } else {
                        window.parent.location.href = process.env.REACT_APP_FRONT_V2_DOMAIN + "/heavy-custom";
                    }
                } else {
                    if(event.data.utilityKind) {
                        window.location.href = process.env.REACT_APP_FRONT_V2_DOMAIN + "/utility-custom";
                    } else {
                        window.location.href = process.env.REACT_APP_FRONT_V2_DOMAIN + "/heavy-custom";
                    }
                }
            }
        }
        window.addEventListener("message", receiveMessage, false)

        // N'oubliez pas de supprimer l'écouteur d'événements lorsque le composant est démonté
        return () => {
            window.removeEventListener("message", receiveMessage, false)
        }
    }, [])

    useEffect(() => {
        if (loaded) {
            let win = window.frames.iframeV2
            win.postMessage({
                token: localStorage.getItem("token")
            }, process.env.REACT_APP_FRONT_V2_DOMAIN)
        }
    }, [loaded])

    return (
        <div className="iframe-wrapper"
             style={{
                 height: "250px"
            }}
        >
            {!loaded &&
                <div className="loader">
                    Chargement...
                </div>
            }
            <iframe
                id='iframeV2'
                style={{ width: "100%", height: "100%", border: "none" }}
                className="iframe-V2"
                src={`${process.env.REACT_APP_FRONT_V2_DOMAIN}/offer`}
                name="iframeV2"
            />
        </div>
    )
}

export default Offer
