import ProxyService from './ProxyService'

class CloudService extends ProxyService {

    uploadCollaboratorImage(caller, file, okCallback)
    {
        let data = new FormData()
        data.append('file', file)
        data.append('upload_preset', 'driver_upload')

        let url = 'https://api.cloudinary.com/v1_1/dl2d3weuy/image/upload';
        return fetch(url, {
            method: "POST",
            body: data
        })
        .then(httpResponse => {
            httpResponse.json().then(cloudResponse => {
                okCallback(caller, cloudResponse.secure_url);
            })
        })
        .catch(error => {
            return this.handleError(caller, error);
        });
    }
}


export default CloudService;