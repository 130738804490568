/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardText,
  CardTitle,
  CardImg,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import SecurityService from "../../../proxies/SecurityService";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import FormInput from '../components/FormInput.jsx';
import { injectIntl , FormattedMessage } from 'react-intl';

class ForgetPassword extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    
    this.securityService = new SecurityService();

    this.validationRules = {
      email : {
        required : true,
        rules : [
          { 
            rule : this.email.bind(this),
            ruleMessage : this.translateMessage("Default.invalid_email")
          }
        ]
      }
    }
    this.state = {
      loading : false,
      emailSent : false
    }
  }
 
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  onSubmitForm = e => {
    e.preventDefault()
    var formIsValid = this.htmlFormIsValid(e);
    if (formIsValid)
    {
      // Récupération des donées postée
      const formData = new FormData(e.target)
      // Connexion
      this.securityService.forgetPasswordWithEmail(this, this.getHtmlFormControlValue("email"), (response) => {
        this.setState({emailSent : true})
      }, (httpResponse) => {
        this.setState({loading : false})
        const errorTitle = this.translateMessage("DEFAULT_ERROR_MESSAGE");
        const errorMessage = this.translateMessage("ForgetPassword.bad_email");
        this.errorMessage(errorMessage, errorTitle)
      });
    }
  }

  showSuccessMessage()
  {
    return <Col md="7">
      <Card className="bg-secondary border-0 mb-0">
        <CardBody className="px-lg-5 py-lg-5">
            <CardTitle className="mb-3" tag="h3">
                <FormattedMessage id="ForgetPassword.confirmation_title">
                { this.decodeEntities }
                </FormattedMessage>
            </CardTitle>
            <CardText className="mb-4">
                <FormattedMessage id="ForgetPassword.confirmation_message">
                { this.decodeEntities }
                </FormattedMessage>
            </CardText>
        </CardBody>
      </Card>
    </Col>
  }

  showForm()
  {
      return <Col md="7">
      <Card className="bg-secondary border-0 mb-0">
        <CardBody className="px-lg-5 py-lg-5">
          <div className="pt-4 text-center">
            <h5 className="h3 title">
            <FormattedMessage id='ForgetPassword.title2'>
                {this.decodeEntities}
            </FormattedMessage>
            </h5>
          </div>
          
          <Form onSubmit={e => this.onSubmitForm(e)} role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput name="email" manager={this} placeholder={this.translateMessage("ForgetPassword.email")} />
              </InputGroup>
            </FormGroup>
            <div className="text-center">
              <Button className="my-4" color="info" type="submit">
                <FormattedMessage id='ForgetPassword.send_email'/>
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  }

  render() {
    return (
      <>
        {this.renderParent()}
        <AuthHeader
          title={this.intl.formatMessage({"id" : "ForgetPassword.title1"})}
        />
        <Container className="mt--8 pb-5">
        <FormLoadingIndicator
          loading={this.state.loading}
        />
          <Row className="justify-content-center">
            {
                (this.state.emailSent == false) ?
                (
                    this.showForm()
                )
                :
                (
                    this.showSuccessMessage()
                )
            }
            
          </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(ForgetPassword);
