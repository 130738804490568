
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    Col,
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    NavLink,
    Modal,
    Button
  } from "reactstrap";


import { injectIntl , FormattedMessage } from 'react-intl';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file


class Title5 extends React.Component {

    render() {
        var customClassName = "t2y-title5 " + (this.props.className);
        var htmlContent = this.props.content;
        var style = this.props.style ? this.props.style : {}
        
        if (this.props.center)
        {
            customClassName += " t2y-center-title"
        }

        if (this.props.right)
        {
            customClassName += " t2y-right-title"
        }
        
        if (this.props.justify)
        {
            customClassName += " t2y-justify"
        }

        if (this.props.center)
        {
            customClassName += " t2y-center-title"
        }

        if (this.props.children)
        {
            return <h5 style={style} className={customClassName}>{this.props.children}</h5>
        }
        else
        {
            return <h5 style={style} className={customClassName} dangerouslySetInnerHTML={{__html: htmlContent}}></h5>
        }
    }
}

export default injectIntl(Title5)