import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Container
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import DefaultLabel from "../../components/common/defaultLabel";
import ReactSlidingPane from "react-sliding-pane";
import AddressWorkflow from "../../components/workflow/address/addressWorkflow";
import BusinessService from "../../../proxies/BusinessService";
import StringHelper from "../../../helpers/StringHelper";
import { add } from "date-fns";
import NotificationComponent from "../components/NotificationComponent";
import DefaultCheckbox from "../../components/common/defaultCheckbox";
import DefaultPopover from "../../components/common/defaultPopover";
import FormHourInput from "../components/form/FormHourInput"
import FormLoadingIndicator from "../components/FormLoadingIndicator";
import FormValidationComponent from "../components/FormValidationComponent";
import Message from "../../components/common/message";
import CivilityInput from "../../components/common/civilityInput";
import DefaultInput from "../../components/common/defaultInput";
import Resources from "../../../resources";
import DefaultButton from "../../components/common/defaultButton";
import GeocodingAndNavigationService from "../../../proxies/GeocodingAndNavigationService";
import ShipperService from "../../../proxies/ShipperService";
import message from "../../components/common/message";

var moment = require('moment');
require('moment/locale/fr');

class NewMissionStep extends FormValidationComponent {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.shipperService = new ShipperService();
        this.geocodingAndNavigationService = new GeocodingAndNavigationService();

        this.validationRules = {
            contact_last_name: {
              required: true
            },
            contact_mobile_phone_number : {
              required: true,
              rules : [
                {rule : this.mobilePhoneNumber.bind(this)}
              ]
            },
            contact_email : {
              rules : [
                {rule : this.email.bind(this)}
              ]
            }
        }

        this.state = {
            contacts: [],
            contactFormIsVisible: true,
            arrivalTimeIsChecked: false,
            unloadingIsChecked: false,
            loading: false,
            visible: true,
            address: 'not-done',
            contact: 'forbidden',
            allowContact: false,
            freight: 'forbidden',
            allowFreight: false,
            other: 'forbidden',
            allowOther: false,
            current: "address"
        }
    }

    componentWillMount() {
        // Récupération des données de l'étape si elle existe
        if (this.props.step)
        {
            this.businessService.getMissionStep(this, this.props.step.id, (response) => {
                var step = response.mission_step;

                this.updateState(step)
            });
        }
    }

    componentDidMount() {}

    updateState(step)
    {
        var contactIsValid = false;
        var hasContactInfos = step.contact && StringHelper.notEmptyValue(step.contact.mobile_phone_number) && StringHelper.notEmptyValue(step.contact.last_name);

        // Si c'est une adresse du carnet d'adresse, le contact n'est pas obligatoire
        if (step.address.id)
        {
            contactIsValid = true;
        }
        // Sinon on vérifie le nom de famille et le numéro de téléphone
        else if (step.contact && hasContactInfos)
        {
            contactIsValid = true;
        }

        var gpsLevelError = step.address && this.geocodingAndNavigationService.levelIsValid(step.address.gps_level) == false

        this.setState ({
            arrivalTimeIsChecked: step.arrival_time != null,
            contactFormIsVisible: !step.address.id,
            address: gpsLevelError ? 'warning' : 'done',
            contact: contactIsValid ? 'done' : (step == null ? 'not-done' : 'warning'),
            freight: 'not-done',
            other: 'done',
            allowContact: true,
            allowFreight: true,
            allowOther: true,
            step: step,
            unloadingIsChecked: step.type.code == "MISSION_STEP_TYPE_UNLOADING"
        })

        if (step.address && step.address.id)
        {
            this.shipperService.getAddressContacts(null, step.address.id, (response) => {
                this.setState({
                    contactFormIsVisible: false,
                    contacts: response.list
                })
            });
        }
        else
        {
            this.setState ({contacts: [], contactFormIsVisible: true})
        }
    }

    updateMission()
    {
        this.props.parent.loadDatas();
    }


    getDatasToPost(step)
    {
        var dataToPost = {
            ...(step.address),
            country_code: step.address.type ? step.address.type.code : "COUNTRY_FR",
            id: step.id,
            arrival_time: step.arrival_time,
            notification_the_day_before: step.notification_the_day_before,
            departure_notification: step.departure_notification,
            call_of_the_driver: step.call_of_the_driver,
            eta_notification: step.eta_notification,

            mission_id: this.props.mission.id,
            shipper_address_id: step.address ? step.address.id : null,
            after_mission_step_id: this.props.from ? this.props.from.id : null,

            contact_civility_code: step.contact && step.contact.civility ? step.contact.civility.code : null,
            contact_first_name: step.contact ? step.contact.first_name : null,
            contact_last_name: step.contact ? step.contact.last_name : null,
            contact_email:  step.contact ? step.contact.email : null,
            contact_mobile_phone_number: step.contact ? step.contact.mobile_phone_number : null
        }
        dataToPost.type_code = step.type.code;

        return dataToPost;
    }

    saveMissionStep(step, callback = null)
    {
        var dataToPost = this.getDatasToPost(step);
        
        // Si pas de mission alors création de l'étape à partir de l'adresse
        this.businessService.saveMissionStep(this, dataToPost, (response) => {
            if (callback)
            {
                callback(response)
            }

            step.id = response.id;

            this.updateState(step)
            this.updateMission();
        }, (httpErrorResponse) => {
            this.setState({loading: false})
            httpErrorResponse.json().then((apiResponse) => {
                this.showErrorFromResponse(apiResponse, null)
            })
        });
    }

    saveSingleValue(field, value)
    {
        var step = this.state.step;
        step[field] = value;
        
        this.saveMissionStep(step);
    }

    removeContact()
    {
        this.setState({contactFormIsVisible: false})
        var step = this.state.step;
        step.contact = null;

        this.saveMissionStep(step, (response) => {
            this.successNotification("", "Les données du contact ont été supprimées avec succès.", "Fermer")
        })
    }

    /**
     * Méthode permettant d'initialiser l'étape à partir d'une adresse saisie manuellement ou existante
     * @param {*} address 
     * @param {*} callback 
     */
    submitAddress(address, callback)
    {
        // Enregistrement de l'adresse sur l'étape en cours ou création d'une nouvelle étape à partir de l'adresse
        // Mise en session de l'adresse
        var step  = this.state.step ? this.state.step : { id: null, position: null, type: {code: this.props.type}};
        step.address = address;
        
        this.saveMissionStep(step, (response) => {
            var current = "address";
            // Message de confirmation
            if (step.id == null)
            {
                current = "contact";
                // Création de l'étape à partie de l'adresse
                this.successNotification(
                    "Confirmation", 
                    <>
                    <label>Votre étape a bien été créée.</label>
                    <br/>
                    <label>Vous pouvez dès maintenant modifier les données du contact sur place et de la marchandise transportée</label>
                    </>, 
                    "Fermer"
                )
            }
            else
            {
                // Mise à jour d'une étape existante
                // Création de l'étape à partie de l'adresse
                this.successNotification(
                    "Confirmation", 
                    <>
                    <label>La nouvelle adresse a bien été prise en compte</label>
                    </>, 
                    "Fermer"
                )
            }

            this.setState({current: current})
        })


        // Exécution du callback
        callback();
    }

    saveAddressContact()
    {
        var formIsValid = this.htmlFormIsValid();
        if (formIsValid)
        {
            var mobilePhoneNumber = this.cleanPhoneNumber(this.getHtmlFormControlValue("contact_mobile_phone_number"));
            var email = this.getHtmlFormControlValue("contact_email");
            var firstName = this.getHtmlFormControlValue("contact_first_name");
            var lastName = this.getHtmlFormControlValue("contact_last_name");
            var civilityCode = this.getHtmlFormControlValue("contact_civility_code");
            
            var step = this.state.step;
            step.contact = {
                civility: {
                    code: civilityCode
                },
                first_name: firstName,
                last_name: lastName,
                email: email,
                mobile_phone_number: mobilePhoneNumber
            }

            this.saveMissionStep(step, (response) => {
                this.successNotification("", "Les données du contact ont été enregistrées avec succès", "Fermer")
            })
        }
    }

    renderWizard()
    {
        var stepTypeCode = this.getStepTypeCode();
        var messages = this.getMessages(stepTypeCode);
        return <>
            <Row className="stepper-container mt-5">
                <div className="stepper no-before">
                    <div className={"stepper-step stepper-step-4 " + (this.state.address)}>
                        <a onClick={(e) => { this.setState({current: "address"}) }} 
                        class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                        data-placement="top" title="" data-original-title="Basic Information">
                            <i className="fas fa-map-marked-alt" style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content={messages.addressTabTitle}></DefaultLabel>
                    </div>

                    <div className={"stepper-step stepper-step-4 " + (this.state.contact) }>
                        <a onClick={(e) => { if (this.state.allowContact) {this.setState({current: "contact"})} }} 
                        class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                        data-placement="top" title="" data-original-title="Basic Information">
                            <i className="far fa-user" style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content={messages.contactTabTitle}></DefaultLabel>
                    </div>

                    <div className={"stepper-step stepper-step-4 " + (this.state.freight) }>
                        <a onClick={(e) => { if (this.state.allowFreight) {this.setState({current: "freight"})} }} 
                        class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                        data-placement="top" title="" data-original-title="Basic Information">
                            <i class="fas fa-truck-loading" style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content="Marchandise">
                        </DefaultLabel>
                    </div>

                    <div className={"stepper-step stepper-step-4 " + (this.state.other) }>
                        <a onClick={(e) => { if (this.state.allowOther) {this.setState({current: "other"})} }} 
                            class="btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber" data-toggle="tooltip" 
                            data-placement="top" title="" data-original-title="Basic Information">
                            <i className="fas fa-cog" style={{fontSize: "30px"}}></i>
                        </a>
                        <DefaultLabel className="stepper-step-label" content="Options"></DefaultLabel>
                    </div>

                </div>
            </Row>

        </>
    }

    getStepTypeCode()
    {
        return this.props.step && this.props.step.type ? this.props.step.type.code : this.props.type ;
    }
    
    getMessages(stepTypeCode)
    {
        var messages = {
            addressSelectionMessage : null,
            addressTabTitle: null,
            contactTableTitle: null
        }
        if (stepTypeCode == "MISSION_STEP_TYPE_UNLOADING")
        {
            messages.addressSelectionMessage = this.translateMessage("MissionStep.address_selection_unloading_message")
            messages.addressTabTitle = this.translateMessage("MissionStep.address_tab_unloading_title")
            messages.contactTabTitle = this.translateMessage("MissionStep.contact_tab_unloading_title")
        }
        else
        {
            messages.addressSelectionMessage = this.translateMessage("MissionStep.address_selection_loading_message")
            messages.addressTabTitle = this.translateMessage("MissionStep.address_tab_loading_title")
            messages.contactTabTitle = this.translateMessage("MissionStep.contact_tab_loading_title")
        }
        
        return messages
    }

    getAddressSelectionMessage(stepTypeCode)
    {
        var messages = this.getMessages(stepTypeCode);
        return <Message type="infos">
            {messages.addressSelectionMessage}
        </Message>
    }
    
    renderAddress()
    {
        var stepTypeCode = this.getStepTypeCode();
        
        return <AddressWorkflow 
            id="ADDRESS_WORKFLOW"
            context={stepTypeCode}
            parent={this}
            addressSelectionComponent={this.getAddressSelectionMessage(stepTypeCode)}
            addressSelectionGeolocalisation={false}
            addressConfirmationAllowOperationalAddress={false}
            onAddressSubmited={(address, callback) => this.submitAddress(address, callback)}
            childRef={(elt) => this.addressWorkflowRef = elt}
            address={this.props.step ? this.props.step.address : (this.state.step ? this.state.step.address : null)}>
        </AddressWorkflow>
    }

    renderContact()
    {
        return <>
            {
                (this.state.step.address.id == null) ?
                (
                    <Message type="infos">
                        Merci de préciser les coordonnées de la personne que le conducteur pourra contacter à cette adresse.                        
                    </Message>
                ) :
                (
                    (this.state.contacts.length == 0) ?
                    (
                        <Message type="warning">
                            Actuellement aucun contact n'est référencé à cette adresse.
                        </Message>
                    )
                    :
                    (
                        <Message type="infos">
                            <>
                                Voici les contacts rattachés à cette adresse: 
                                <ul>
                                    {
                                        this.state.contacts.map(contact => {
                                            return <><li>{contact.summary} {contact.mobile_phone_number}</li></>
                                        })
                                    }
                                </ul>
                                {
                                    // As t'on le droit de créer un contact temporaire pour une étape si l'adresse fait partie du carnéet d'adresse ?
                                    (this.props.allowAddressBookStepContactCreation == true) ?
                                    (
                                        (!this.state.contactFormIsVisible) ?
                                        (
                                            <>
                                                <br/>
                                                <DefaultButton onClick={(e) => this.setState({contactFormIsVisible: true})} className="t2y-thirdparty-button small">
                                                    Je souhaite définir un nouveau contact
                                                </DefaultButton>
                                            </>
                                        ) : 
                                        (<>
                                            <>
                                                <br/>
                                                <DefaultButton onClick={(e) => this.removeContact()} className="t2y-thirdparty-button small">
                                                    Je ne souhaite pas un nouveau contact pour cette adresse
                                                </DefaultButton>
                                            </>
                                        </>)
                                    )
                                    : (<></>)
                                }
                            </>
                        </Message>
                    )
                )
            }
            {
                (this.state.contactFormIsVisible) ?
                (
                    <Row>
                        <Col>
                            <Row className="mb-3">
                                <Col>
                                    <CivilityInput id="contact_civility_code" 
                                        childRef={(elt) => this.civilityInputRef = elt} 
                                        placeholder={"Civilité"}
                                        value={this.state.step.contact && this.state.step.contact.civility ? this.state.step.contact.civility.code : null}>
                                    </CivilityInput>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <DefaultInput id="contact_first_name" 
                                        childRef={(elt) => this.fnameInputRef = elt} 
                                        icon={Resources.form_fname_icon} placeholder={"Prénom"}
                                        value={this.state.step && this.state.step.contact ? this.state.step.contact.first_name : null}>
                                    </DefaultInput>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <DefaultInput id="contact_last_name" 
                                        childRef={(elt) => this.lnameInputRef = elt} 
                                        icon={Resources.form_lname_icon} placeholder={"Nom de famille"}
                                        value={this.state.step && this.state.step.contact ? this.state.step.contact.last_name : null}>
                                    </DefaultInput>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <DefaultInput id="contact_mobile_phone_number" 
                                        childRef={(elt) => this.mobilePhoneNumberInputRef = elt} 
                                        icon={Resources.form_mobile_phone_number_icon} placeholder={"N° de mobile"}
                                        value={this.state.step && this.state.step.contact ? this.state.step.contact.mobile_phone_number : null}>
                                    </DefaultInput>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <DefaultInput id="contact_email" 
                                        childRef={(elt) => this.loginInputRef = elt} 
                                        icon={Resources.form_email_icon} placeholder={"Email"}
                                        value={this.state.step && this.state.step.contact ? this.state.step.contact.email : null}>
                                    </DefaultInput>
                                </Col>
                            </Row>
                            <Row className="mb-3 justify-content-center">
                                    <DefaultButton className="t2y-secondary-button" onClick={(e) => this.saveAddressContact()} >
                                        Enregistrer les données du contact.
                                    </DefaultButton>
                            </Row>
                        </Col>
                    </Row>
                )
                : (<></>)
            }
        </>
    }

    renderFreight()
    {
        return <>
            En cours de développement
        </>
    }

    onArrivalTimeChecked() 
    {
        var checked = !this.state.arrivalTimeIsChecked;
        this.setState({arrivalTimeIsChecked: checked})

        if (checked == false)
        {
            this.saveSingleValue("arrival_time", null)
        }
    }

    onUnloadingChecked() 
    {
        var checked = !this.state.unloadingIsChecked;
        this.setState({unloadingIsChecked: checked})

        var step = this.state.step;
        step.type.code = checked ? "MISSION_STEP_TYPE_UNLOADING" : "MISSION_STEP_TYPE_LOADING";
        var dataToPost = this.getDatasToPost(step);

        this.businessService.saveMissionStep(this, dataToPost, (response) => {

            this.setState ({
                address: 'done',
                contact: 'not-done',
                freight: 'not-done',
                other: 'done',
                allowContact: true,
                allowFreight: true,
                allowOther: true,
                step: step
            })

            this.props.parent.loadDatas();
        });
    }
    

    renderOther() 
    {
        return <>
            <Row>
                <Col xl="8">
                    <Row>
                        <Col xs="4">
                            <label className={!this.state.unloadingIsChecked ? "t2y-strong" : ""}>
                                Chargement
                            </label>
                        </Col>
                        <Col xs="2" className="justify-content-center">
                            <label className="custom-toggle custom-toggle-success">
                                <input checked={this.state.unloadingIsChecked} type="checkbox"
                                onChange={(e) => this.onUnloadingChecked()} />
                                <span className="custom-toggle-slider primary rounded-circle" />
                            </label>
                        </Col>
                        <Col xs="4">
                            <label className={this.state.unloadingIsChecked ? "t2y-strong" : ""}>
                                Déchargement
                            </label>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col lg="12">
                    <DefaultCheckbox 
                        id="use_arrival_time"
                        checked={this.state.arrivalTimeIsChecked}
                        onChange={(e) => this.onArrivalTimeChecked()}
                        content="Horaire impératif d'arrivée"
                        popover={<DefaultPopover content="
                            Indiquez un horaire uniquement si il est imératif.
                            Le conducteur devra alors arriver à cet horaire pour cette étape">
                        </DefaultPopover>}
                        >
                    </DefaultCheckbox>
                </Col>
                <Col lg="12" className="arrival_time" style={{display: this.state.arrivalTimeIsChecked? "block": "none"}}>
                    <FormHourInput name="arrival_time" value={this.state.step && this.state.step.arrival_time ? this.state.step.arrival_time.substring(0, 5) : null} onChange={(e) => {}} onBlur={(e) => { 
                        this.saveSingleValue("arrival_time", this.getHtmlFormControlValue("arrival_time"))
                    }}/>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col lg="12">
                    <DefaultCheckbox 
                        id="notification_the_day_before"
                        onChange={(elt) => this.saveSingleValue("notification_the_day_before", !this.state.step.notification_the_day_before)}
                        childRef={(elt) => this.notificationTheDayBeforeRef = elt}
                        checked={this.state.step ? this.state.step.notification_the_day_before : false}
                        content="Notification la veille">
                    </DefaultCheckbox>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <DefaultCheckbox 
                        id="departure_notification"
                        onChange={(elt) => this.saveSingleValue("departure_notification", !this.state.step.departure_notification)}
                        childRef={(elt) => this.departureNotificationRef = elt}
                        checked={this.state.step ? this.state.step.departure_notification : false}
                        content="Notification de départ">
                    </DefaultCheckbox>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <DefaultCheckbox 
                        id="call_of_the_driver"
                        onChange={(elt) => this.saveSingleValue("call_of_the_driver", !this.state.step.call_of_the_driver)}
                        childRef={(elt) => this.callOfTheDriverRef = elt}
                        checked={this.state.step ? this.state.step.call_of_the_driver : false}
                        content="Appel préalable du chauffeur">
                    </DefaultCheckbox>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <DefaultCheckbox 
                        id="eta_notification"
                        onChange={(elt) => this.saveSingleValue("eta_notification", !this.state.step.eta_notification)}
                        childRef={(elt) => this.etaNotificationRef = elt}
                        checked={this.state.step ? this.state.step.eta_notification : false}
                        content="Notification ETA -15 minutes">
                    </DefaultCheckbox>
                </Col>
            </Row>
        </>
    }

    renderContent()
    {
        switch(this.state.current)
        {
            case "address":
                return this.renderAddress();
            break;
            case "other":
                return this.renderOther();
            break;
            case "contact":
                return this.renderContact();
            break;
            case "freight":
                return this.renderFreight();
            break;
        }
    }

    render() {
        return <>
            {this.renderParent()}
            <ReactSlidingPane
                className="some-custom-class"
                overlayClassName="some-custom-overlay-class"
                isOpen={this.state.visible}
                title={null}
                width="40%"
                subtitle={null}
                onRequestClose={() => {
                    // triggered on "<" on left top click or on outside click
                    this.setState({ visible: false });

                    if (this.props.onHide) 
                        this.props.onHide(null)
                }}
            >
                <FormLoadingIndicator loading={this.state.loading}>
                <div className="container">
                    
                        {this.renderWizard()}
                        {this.renderContent()}
                </div>
                </FormLoadingIndicator>

            </ReactSlidingPane>

        </>
    }
}


export default injectIntl(NewMissionStep)

