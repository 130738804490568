
import React, {useEffect } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardText,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import SecurityService from "../../../proxies/SecurityService";
import { injectIntl , FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import UserState from "../../components/user/userState";
import RedirectionAlert from "../../components/business/redirectionAlert";
import WebsiteHelper from "../../../helpers/WebsiteHelper";

class AccountValidation extends FormValidationComponent {

  constructor(props)
  {
    super(props);

    this.urlToken = null;
    this.urlId = null;

    this.state = {
      loading : false,
      success : null,
      errorMessage : null,
      isShipper : false,
      isTransporter : false,
      seconds : 5
    }
    this.securityService = new SecurityService();
  }

  getAccountType()
  {
    const accountType = this.state.isShipper ? "shipper" : (this.state.isTransporter ? "transporter" : "unknown");

    return this.translateMessage("RegisterConfirmation." + accountType)
  }
  

  componentDidMount()
  {
    // Si aucune clé n'est présente, alors on retourne sur l'écran de connexion
    const urlParams = new URLSearchParams (this.props.location.search)    
    this.urlToken = urlParams.get("token")
    this.urlId = urlParams.get("id")
    if (this.urlToken == null || this.urlId == null)
    {
      this.props.history.push('/auth/login');
    }
    else
    {
      // Appel de l'url de validation de compte et enregistrement du nouveau token
      this.securityService.validateAccount(this, this.urlToken, (response) => {
        var returnUrl = response.user.return_url != null ? response.user.return_url : null
        
        var url = WebsiteHelper.getHomeUrl(this);

        if (this.securityService.hasCompanyInfos() == false)
        {
          url = "/auth/login" + (returnUrl != null ? "?returnUrl=" + window.btoa(returnUrl) : "")
        }
        
        this.setState({
          url: url,
          success: true
        })
        
        // Si tout est ok, on affiche le message et on redirige sur la home  
        this.successNotification("Confirmation", "Votre compte a été activé avec succès", "Fermer")
      }, (httpResponse) => {
        this.setState({success : false, loading : false })

        httpResponse.json().then( async (apiResponse) => {
          
          /*
          // Récupération des données de l'utilisateur via l'identifiant:
          var userStateActionResponse = this.securityService.checkUserStateAction(this.urlId);
          

          // Sinon impossible de valider le token
          if (typeof apiResponse.invalid_rules !== "undefined" && apiResponse.invalid_rules.length > 0)
          {
            this.setState({
              errorMessage : this.translateMessage("AccountValidation." + apiResponse.invalid_rules[0])
            });
          }*/
        })        
      })
    }
  }

  render() {
    return (
      <>
        {this.renderParent()}
        <Container className="mt--8 pb-5">
          <FormLoadingIndicator loading={this.state.loading} />

          <Row className="justify-content-center">
            <Col md="8">
              {
                (this.state.success == true) ? 
                (
                  /*
                  <Card className="bg-secondary border-0">
                    <CardBody>
                        <CardTitle className="mb-3" tag="h3">
                          <FormattedMessage id="AccountValidation.validation_title" values={{type: this.getAccountType()}}>
                            { this.decodeEntities }
                          </FormattedMessage>
                        </CardTitle>
                        <CardText className="mb-4">
                          <FormattedMessage id="AccountValidation.validation_message" values={{seconds: this.state.seconds}}>
                            { this.decodeEntities }
                          </FormattedMessage>
                        </CardText>
                        <Button
                          className="t2y-secondary-button"
                          color="primary"
                          href={this.state.url}
                        >
                          <FormattedMessage
                            id="AccountValidation.redirect_to_profile"

                            values={{type: this.getAccountType()}}
                          />
                        </Button>
                      </CardBody>  
                  </Card>*/
                  <RedirectionAlert style={{marginTop:"200px"}} url={this.state.url} ></RedirectionAlert>

                ) 
                :
                (
                    (this.state.success != null && this.state.success == false) ? 
                    (
                      <>
                      <UserState id={this.urlId} token={this.urlToken}></UserState>

                      {/*
                      <Card className="bg-secondary border-0">
                        <CardBody>
                            <CardTitle className="mb-3" tag="h3">
                              <FormattedMessage id="AccountValidation.validation_error_title">
                              </FormattedMessage>
                            </CardTitle>
                            <CardText className="mb-4">
                              {this.state.errorMessage}
                            </CardText>
                          </CardBody>  
                      </Card>*/}
                      </>
                    )
                    :
                    (<></>)
                )
              }
              
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(AccountValidation);
