import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";

import DefaultInput from "../../common/defaultInput";
import PasswordInput from "../../common/passwordInput";
import Resources from "../../../../resources";
import Title2 from "../../common/title2"
import Title3 from "../../common/title3"
import Title4 from "../../common/title4";
import DefaultImage from "../../common/defaultImage";
import DefaultLabel from "../../common/defaultLabel";

import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import SecurityService from "../../../../proxies/SecurityService";
import Step from "../step";
import CivilityInput from "../../common/civilityInput";
import Config from '../../../../config'
import Message from "../../common/message";



var moment = require('moment');
require('moment/locale/fr');

class CreateAccountStep extends Step {

    constructor(props) {
        super(props)

        this.businessService = new BusinessService();
        this.securityService = new SecurityService();
        this.validationRules = {
            civility_code: {
                required: true
            },
            first_name: {
                required: true
            },
            last_name: {
                required: true
            },
            mobile_phone_number: {
                required: true,
                rules: [
                    { rule: this.mobilePhoneNumber.bind(this) }
                ]
            },
            email: {
                required: true,
                rules: [
                    { rule: this.email.bind(this) }
                ]
            },
            password: {
                required: true,
                rules: [
                    {
                        rule: (value) => {
                            return this.passwordInputRef.isValid()
                        }
                    }

                ]
            }
        }

        this.state = {
            manual_type: null,
            loading: false
        }
    }

    componentWillMount() {
        super.componentWillMount()
    }

    componentDidMount() {

    }

    getTypeAsCode() {
        if (this.props.type) {
            return this.props.type;
        }
        else {
            return this.manual_type;
        }
    }

    getType() {
        return this.getTypeAsCode() == "shipper" ? "expediteur" : "transporteur";
    }

    manualTypeSelection(type) {
        // On change le type
        this.manual_type = type;
        // On force le réaffichage du composant
        this.setState({ manual_type: type })

        // On met a jour l'affichage des boutons
        this.props.workflow.reload();
    }

    getAccountTypeLabel() {
        return this.getTypeAsCode() == "shipper" ? "Expediteur" : "Transporteur";
    }

    submit(pid) {
        var formIsValid = this.htmlFormIsValid();
        if (formIsValid) {
            super.submit(pid)
            // Récupération des données
            this.setState({ loading: true })
            var dataToPost = {
                "civility_code": this.getHtmlFormControlValue("civility_code"),
                "first_name": this.getHtmlFormControlValue("first_name"),
                "last_name": this.getHtmlFormControlValue("last_name"),
                "mobile_phone_number": this.getHtmlFormControlValue("mobile_phone_number"),
                "username": this.getFormControlValue("email"),
                "password": this.getFormControlValue("password"),
                "type": this.getTypeAsCode(),
                "url": this.props.workflow.props.workflow ? this.props.workflow.props.workflow.getUrl() : (
                    Config.bndFe + "/auth/login"
                    //Config.wpSite + "?source=" + this.getType() + "&action=login"
                )
            }

            // Création du compte
            this.securityService.createAccount(this, dataToPost, this.onAccountCreated.bind(this), this.onError.bind(this));
        }
    }

    onAccountCreated(response) {
        this.setState({ loading: false })
        
        this.securityService.storeData("register_email", this.getFormControlValue("email"));
        this.securityService.storeData("register_account_type", this.getTypeAsCode());

        this.props.workflow.goToNextStep(this.state.pid)
    }

    onError(httpResponse) {
        this.setState({ loading: false })

        httpResponse.json().then((apiResponse) => {
            this.showErrorFromResponse(
                apiResponse,
                this.translateMessage("company.siret_input_step_error_title")
            );
        });
    }

    renderForm() {
        return <><Row>
            <Col lg={this.props.embeded ? "12" : "6"} md="12" className="order-lg-1 order-2">
                <Row className="mb-2">
                    <Col>
                        <Title3 small={true} content={"Création de votre compte " + this.getAccountTypeLabel() + " gratuit"}></Title3>
                        <Title4 content={"En renseignant tous les champs suivants :"}></Title4>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <CivilityInput id="civility_code"
                            manager={this}
                            childRef={(elt) => this.civilityInputRef = elt}
                            placeholder={"votre civilité"}>
                        </CivilityInput>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <DefaultInput id="first_name"
                            autoComplete="OFF"
                            manager={this}
                            childRef={(elt) => this.fnameInputRef = elt}
                            icon={Resources.form_fname_icon} placeholder={"votre prénom"}>
                        </DefaultInput>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <DefaultInput id="last_name"
                            autoComplete="OFF"
                            manager={this}
                            childRef={(elt) => this.lnameInputRef = elt}
                            icon={Resources.form_lname_icon} placeholder={"votre nom de famille"}>
                        </DefaultInput>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <DefaultInput id="mobile_phone_number"
                            autoComplete="OFF"
                            manager={this}
                            childRef={(elt) => this.mobilePhoneNumberInputRef = elt}
                            icon={Resources.form_mobile_phone_number_icon} placeholder={"votre n° de mobile"}>
                        </DefaultInput>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <DefaultInput id="email"
                            autoComplete="OFF"
                            manager={this}
                            type="email"
                            childRef={(elt) => this.loginInputRef = elt}
                            icon={Resources.form_email_icon} placeholder={"votre email"}>
                        </DefaultInput>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <PasswordInput id="password"
                            creation={true}
                            manager={this}
                            childRef={(elt) => this.passwordInputRef = elt}
                            icon={Resources.form_password_icon} placeholder={"votre mot de passe"}>
                        </PasswordInput>
                    </Col>
                </Row>
            </Col>
            {
                (!this.props.embeded) ?
                    (
                        this.renderAccountBenefits()
                    )
                    : (<></>)
            }
        </Row>
        </>
    }

    renderAccountBenefits() {
        if (this.getTypeAsCode() == "shipper") {
            return this.renderShipperBenefits();
        }
        else {
            return this.renderTransporterBenefits();
        }

    }

    renderTransporterBenefits() {

        return <Col lg="6" md="12" className="justify-content-text-center order-lg-2 order-1">
            <Message>
                <Row className="text-align justify-content-center">
                    <Title3>Pourquoi créer votre compte ?</Title3>
                </Row>
                <hr />
                <Row>
                    <Col xl="3">
                        <DefaultImage src="https://www-test.truck2you.com/wp-content/uploads/2020/08/Money-1.svg"></DefaultImage>
                    </Col>
                    <Col xl="9">
                        <DefaultLabel content="Augmentez votre chiffre d’affaires"></DefaultLabel>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xl="3">
                        <DefaultImage src="https://www-test.truck2you.com/wp-content/uploads/2020/08/icone-digital.svg"></DefaultImage>
                    </Col>
                    <Col xl="9">
                        <DefaultLabel content="Améliorez votre efficacité et la satisfaction client"></DefaultLabel></Col>
                </Row>
                <hr />
                <Row>
                    <Col xl="3">
                        <DefaultImage src="https://www-test.truck2you.com/wp-content/uploads/2020/08/icone-montre.svg"></DefaultImage>
                    </Col>
                    <Col xl="9">
                        <DefaultLabel content="Allégez vos process et fluidifiez votre trésorerie"></DefaultLabel></Col>
                </Row>
            </Message>
        </Col>

    }


    renderShipperBenefits() {

        return <Col lg="6" md="12" className="justify-content-text-center order-lg-2 order-1">
            <Message>
                <Row className="text-align justify-content-center">
                    <Title3>Pourquoi créer votre compte ?</Title3>
                </Row>
                <hr />
                <Row>
                    <Col xl="3">
                        <DefaultImage src="https://www.truck2you.com/wp-content/uploads/2020/08/icone-filtre-camion.svg"></DefaultImage>
                    </Col>
                    <Col xl="9">
                        <DefaultLabel content="Faciliter vos recherches pour trouvez le bon prestataire"></DefaultLabel>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xl="3">
                        <DefaultImage src="https://www.truck2you.com/wp-content/uploads/2020/08/icone-location-camion.svg"></DefaultImage>
                    </Col>
                    <Col xl="9">
                        <DefaultLabel content="Bénéficier d'un process unique et efficace, quel que soit le prestataire"></DefaultLabel></Col>
                </Row>
                <hr />
                <Row>
                    <Col xl="3">
                        <DefaultImage src="https://truck2you.com/wp-content/uploads/2020/08/icone-satisfaction-camion.svg"></DefaultImage>
                    </Col>
                    <Col xl="9">
                        <DefaultLabel content="Augmenter la satisfaction de vos clients"></DefaultLabel></Col>
                </Row>
            </Message>
        </Col>

    }

    renderManualTypeSelection() {
        return <>
            <Row className="justify-content-center">
                <Title2 content="Création de votre compte"></Title2>
            </Row>
            <Row>
                <Col md="12" lg="6">
                    <Card className="card-lift--hover shadow border-0 t2y-clickable">
                        <CardBody className="" onClick={() => this.manualTypeSelection("shipper")}>
                            <Container className="text-center justify-content-center" style={{paddingLeft:"0", paddingRight:"0"}}>
                                <img style={{ width: "100%", }} className="img-responsive img-thumbnail " src={Resources.shipper_account_type_image} />
                                <Title2 className="mt-3" center={true} content="Expéditeur"></Title2>
                                <Title3 center={true} content="vous cherchez une solution de transport de vos marchandises"></Title3>
                            </Container>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="12" lg="6">
                    <Card className="card-lift--hover shadow border-0 t2y-clickable">
                        <CardBody className="" onClick={() => this.manualTypeSelection("transporter")}>
                            <Container className="text-center" style={{paddingLeft:"0", paddingRight:"0"}}>
                                <img style={{ width: "100%"}} className="img-responsive img-thumbnail" src={Resources.transporter_account_type_image} />
                                <Title2 className="mt-3" content="Transporteur" center={true}></Title2>
                                <Title3 center={true} content="vous mettez à disposition vos solutions de transport"></Title3>
                            </Container>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>;
    }

    renderIframeManualTypeSelection()
    {
        return <>
            <Row className="justify-content-center">
                <Title2 content="Création de votre compte"></Title2>
            </Row>
            <Row>
                <Col md="12">
                    <Card className="card-lift--hover shadow border-0 t2y-clickable">
                        <CardBody className="" onClick={() => this.manualTypeSelection("shipper")}>
                            <Container className="text-center justify-content-center" style={{paddingLeft:"0", paddingRight:"0"}}>
                                <Row>
                                    <Col xs="3" md="4">
                                    <img style={{ width: "100%", }} className="img-responsive img-thumbnail " src={Resources.shipper_account_type_image} />
                                    </Col>
                                    <Col xs="9" md="8">
                                        <Title2 className="mt-3" center={false} content="Expéditeur"></Title2>
                                        <Title3 center={false} content="vous cherchez une solution de transport de vos marchandises"></Title3>
                                    </Col>
                                </Row>
                            </Container>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <Card className="card-lift--hover shadow border-0 t2y-clickable">
                        <CardBody className="" onClick={() => this.manualTypeSelection("transporter")}>
                            <Container className="text-center justify-content-center" style={{paddingLeft:"0", paddingRight:"0"}}>
                                <Row>
                                    <Col xs="3" md="4">
                                    <img style={{ width: "100%"}} className="img-responsive img-thumbnail" src={Resources.transporter_account_type_image} />
                                    </Col>
                                    <Col xs="9" md="8">
                                        <Title2 className="mt-3" content="Transporteur" center={false}></Title2>
                                        <Title3 center={false} content="vous mettez à disposition vos solutions de transport"></Title3>
                                    </Col>
                                </Row>
                            </Container>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </>
    }


    render() {
        return (
            <>
                {this.renderParent()}
                <FormLoadingIndicator loading={this.state.loading} />
                <Container>
                    {
                        (this.getTypeAsCode()) ?
                            (
                                this.renderForm()
                            )
                            :
                            (
                                //this.renderManualTypeSelection()
                                this.renderIframeManualTypeSelection()
                            )
                    }
                </Container>
            </>
        );
    }
}


export default injectIntl(CreateAccountStep)

