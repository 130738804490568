import React from "react";
import styled from "styled-components";

const S = {};

S.span = styled.span`
  width: 1.8rem;
  height: 1.8rem;
`;

S.svg = styled.svg`
  /* display: inline-block;
  vertical-align: middle; */
  flex-shrink: 0;
`;

function IconStar(props) {
  return (
    <S.svg
      viewBox="0 0 24 24"
      width={props.width}
      height={props.height}
      aria-hidden="true"
    >
      <path
        fill="currentColor"
        d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"
      />
    </S.svg>
  );
}

export default IconStar;
