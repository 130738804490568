import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Modal,
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Resources from "../../../../resources";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import AccountWorkflow from "../account/accountWorkflow";
import WebsiteHelper from "../../../../helpers/WebsiteHelper";
import StringHelper from "../../../../helpers/StringHelper";
import Title2 from "../../common/title2"
import Title4 from "../../common/title4"
import ShipperService from "../../../../proxies/ShipperService";
import CallForBidsProcessStep from "./callForBidsProcessStep";
import CallForBidsConfirmationStep from "./callForBidsConfirmationStep";
import CallForBidsTransporterDatesStep from "./callForBidsTransporterDatesStep";
import CallForBidsPropertyChoiceStep from "./callForBidsPropertyChoiceStep";
import CallForBidsTransporterDatesConfirmationStep from './callForBidsTransporterDatesConfirmationStep';

var moment = require('moment');
require('moment/locale/fr');

class CallForBidsWorkflow extends Workflow {

    constructor(props) {
        super(props)
        
        this.shipperService = new ShipperService();

        this.firstShipperStepCode = "CALL_FOR_BIDS_PROCESS"

        this.state = {
            visible: false,
            current_step: "ACCOUNT",
            steps: {
                "ACCOUNT": {
                    id: "ACCOUNT",
                    position:1,
                    type: "workflow",
                    component: <AccountWorkflow embeded={true} id="ACCOUNT" workflow={this} type="shipper"></AccountWorkflow>,
                    next: "CALL_FOR_BIDS_PROCESS",
                    conditions: [
                        {
                            condition: function(){
                                return this.shipperService.hasCompanyInfos()
                            }.bind(this),
                            step_on_success: this.firstShipperStepCode,
                            step_on_failure: "CURRENT"
                        }
                    ],
                },
                "CALL_FOR_BIDS_PROPERTY_CHOICE": {
                    id: "CALL_FOR_BIDS_PROPERTY_CHOICE",
                    title: <Title2></Title2>,
                    position: 2,
                    type: "step",
                    component: <CallForBidsPropertyChoiceStep id="CALL_FOR_BIDS_PROPERTY_CHOICE" workflow={this}></CallForBidsPropertyChoiceStep>,
                    hideNextsAndLinks: false,
                    nexts : [
                        {
                            label: this.translateMessage("Default.validate"),                       
                            icon: Resources.button_save_icon,
                            visible: true,
                            step: "CALL_FOR_BIDS_PROCESS",
                            condition: (function(component) { return component.choiceMade()})
                        }
                    ]
                },
                "CALL_FOR_BIDS_PROCESS": {
                    id: "CALL_FOR_BIDS_PROCESS",
                    title: <Title2></Title2>,
                    position: 3,
                    type: "step",
                    component: <CallForBidsProcessStep id="CALL_FOR_BIDS_PROCESS" workflow={this}></CallForBidsProcessStep>,
                    hideNextsAndLinks: true,
                    nexts : [
                        {
                            label: this.translateMessage("order.result_detail_step_manual_validation_payment_button_label"),                       
                            icon: Resources.button_cart_icon,
                            visible: true,
                            step: "CALL_FOR_BIDS_CONFIRMATION",
                            //condition: (function(component) { return component.allowPaymentMethodStep()})
                        }
                    ]
                },
                "CALL_FOR_BIDS_CONFIRMATION": {
                    id: "CALL_FOR_BIDS_CONFIRMATION",
                    title: <Title2></Title2>,
                    position: 4,
                    type: "step",
                    component: <CallForBidsConfirmationStep id="CALL_FOR_BIDS_CONFIRMATION" workflow={this}></CallForBidsConfirmationStep>
                },

                "CALL_FOR_BIDS_TRANSPORTER_DATES": {
                    id: "CALL_FOR_BIDS_TRANSPORTER_DATES",
                    position: 5,
                    type: "step",
                    title: <><Title4>Répondre à la demande</Title4></>,
                    component: <CallForBidsTransporterDatesStep size="lg" id="CALL_FOR_BIDS_TRANSPORTER_DATES" workflow={this}></CallForBidsTransporterDatesStep>,
                    nexts : [
                        {   
                            className: "t2y-thirdparty-alt-button",
                            label: "Ne rien faire",
                            visible: false,
                            step: "CANCEL",
                            condition: (function(component) { return component.hasSelectedDates()})
                        }, 
                        {
                            label: "Répondre avec ce véhicule",
                            icon: Resources.button_cart_icon,
                            visible: false,
                            step: "CALL_FOR_BIDS_TRANSPORTER_DATES_CONFIRMATION",
                            condition: (function(component) { return component.hasSelectedDates()})
                        }
                    ]
                },
                "CALL_FOR_BIDS_TRANSPORTER_DATES_CONFIRMATION": {
                    id: "CALL_FOR_BIDS_TRANSPORTER_DATES_CONFIRMATION",
                    position: 6,
                    type: "step",
                    component: <CallForBidsTransporterDatesConfirmationStep parent={this.props.parent} size="lg" id="CALL_FOR_BIDS_TRANSPORTER_DATES_CONFIRMATION" workflow={this}></CallForBidsTransporterDatesConfirmationStep>,
                    nexts: []
                }
            }
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    notConnected()
    {
        super.notConnected();
        this.setState({
            current_step: "ACCOUNT"
        })
    }

    startTransporterProposal(result)
    {
        this.setState({
            current_step: "CALL_FOR_BIDS_TRANSPORTER_DATES",
            input: result,
            visible: true
        })
    }

    start(searchId, propertyValuesAsCode, nbDaysToAdd)
    {
        this.setInput({
            searchId: searchId,
            values: propertyValuesAsCode,
            nbDaysToAdd: nbDaysToAdd
        })
        this.setState({ 
            visible: true
        })
    }

    startPropertyChoice(searchId, propertyValuesAsCode, propertyValuesToSpecify)
    {
        this.firstShipperStepCode = "CALL_FOR_BIDS_PROPERTY_CHOICE"

        this.setInput({
            searchId: searchId,
            values: propertyValuesAsCode,
            propertyValuesToSpecify: propertyValuesToSpecify
        })

        this.setState({ 
            current_step: "CALL_FOR_BIDS_PROPERTY_CHOICE",
            visible: true
        })
    }

    getUrl()
    {
        return window.location.href + "?callForBids=1"
    }

    restore()
    {
        this.goToSpecificStep("CALL_FOR_BIDS_PROCESS")
    }


    beforeClosingModal()
    {
        this.setState({
            visible: false
        })    
    }
    
    render() {
        return (
            <>
                {this.renderParent()}
                {/*this.renderAsSlidingPanel()*/}
                {this.renderAsModal()}
                {/*this.renderCurrentStep() */}
            </>
        );
    }
}


export default injectIntl(CallForBidsWorkflow)

