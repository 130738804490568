import "../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
import classnames from "classnames";
import {
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText  
} from "reactstrap";
import FormInputValidationMessages from './FormInputValidationMessages.jsx';
import FormComponent from "./form/FormComponent"

class FormInput extends FormComponent {

    constructor(props)
    {
        super(props)
        this.state = {
          focused: false,
          formInputValue: null
        }
    }

    componentDidMount() {
      if (typeof this.props.childRef !== "undefined")
      {
        this.props.childRef(this)
      }
    }

    setValue(value)
    {
      document.getElementById(this.getKey()).value = parseFloat(value);
    }

    handleFormInputChanged(e) {
      
      this.setState({
        formInputValue: e.target.value
      });

      
      if (this.props.manager)
      {
        this.props.manager.htmlInputChanged(e, this.getId());
      }
      
      return false;
    }

    renderInput(newProps)
    {
      var value = this.state.formInputValue == null ? this.getDefaultValue() : this.state.formInputValue;

      if (this.props.required)
      {
        if (newProps.className)
        {
          newProps.className = "";
        }

        newProps.className += " t2y-required"
      }
      
      return <Input
          ref={this.props.ref}
          readOnly={this.props.readOnly}
          rows={this.props.rows}
          key={this.props.key}
          id={this.getKey()}
          name={this.getKey()}
          autoComplete="OFF"
          //defaultValue={this.state.value ? this.setState.value : 
          value={value}
          placeholder={this.props.placeholder}
          type={this.props.type}
          onBlur={this.props.onBlur ? this.props.onBlur : this.handleFormInputChanged.bind(this)}
          onChange={this.handleFormInputChanged.bind(this)}
          //onChange={(e) => this.setState({value: e.target.value})}//); /*this.props.manager.htmlInputChanged(e, this.getKey())}*/}}
          {...newProps}
      >
        {this.props.children}
      </Input>
    }

    render() {
      const errorUUID = this.newUUID();

      return (
        <>
            {
              (this.props.append) ? 
              (
                /*
                  <FormGroup id={errorUUID}>
                      <InputGroup className={classnames("input-group-merge", {})}>
                        
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            {this.props.append}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                  </FormGroup>
                */
               <div class="main">
                <div class="form-group">
                  <span class="input-right-content" dangerouslySetInnerHTML={{__html: this.props.append}}></span>
                  {this.renderInput({ className: "input-with-right-content" })}
                </div>  
              </div>

              ) : 
              (
                this.renderInput({})
              )
            }
            
        </>
      );
    }
  }
 
  export default FormInput;