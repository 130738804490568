import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import CreateAccountStep from "./createAccountStep"
import LoginStep from "./loginStep"
import CompanyWorkflow from "../company/companyWorkflow";
import CreateAccountNotificationStep from "./createAccountNotificationStep"
import ForgetPasswordStep from "./forgetPasswordStep";
import ForgetPasswordConfirmationStep from "./forgetPasswordConfirmationStep"
import SecurityService from "../../../../proxies/SecurityService";
import WebsiteHelper from "../../../../helpers/WebsiteHelper";

var moment = require('moment');
require('moment/locale/fr');

class AccountWorkflow extends Workflow {

    constructor(props) {
        super(props)
        this.businessService = new BusinessService();
        this.securityService = new SecurityService();

        this.loginStepRef = null;

        this.state = {
            visible: false,
            current_step: this.props.current_step ? this.props.current_step : "REGISTER_USER",
            steps: {
                "REGISTER_USER": {
                    id: "REGISTER_USER",
                    position:1,
                    component: <CreateAccountStep embeded={this.props.embeded} workflow={this} type={this.props.type} id="REGISTER_USER"></CreateAccountStep>,
                    conditions: [
                        // Si on est déjà connecté  
                        {
                            condition: function(){
                                //console.log(this.securityService.getUser())
                                return this.securityService.isConnectedForWorkflow()
                            }.bind(this),
                            step_on_success: "REGISTER_COMPANY",
                            step_on_failure: "CURRENT"
                        },
                        // Si on est en attente de validation
                        {
                            condition: function(){
                                return this.businessService.getUser() != null && 
                                this.businessService.getUser().workflow_state == "ACCOUNT_CREATED"
                            }.bind(this),
                            step_on_success: "REGISTER_NOTIFICATION",
                            step_on_failure: "CURRENT"
                        }
                    ],
                    links: [
                        {step: "LOGIN", label: "J'ai déjà un compte" }
                    ],
                    nexts: [
                        {   step: "REGISTER_NOTIFICATION", label: "Créer mon compte",
                            visible: false,
                            condition: (function(component) { return component.getTypeAsCode() != null})
                        }
                    ]
                },
                "REGISTER_SHIPPER": {
                    id: "REGISTER_SHIPPER",
                    position:1,
                    component: <CreateAccountStep embeded={this.props.embeded} workflow={this} type="shipper" id="REGISTER_SHIPPER"></CreateAccountStep>,
                    conditions: [
                        // Si on est déjà connecté
                        {
                            condition: function(){
                                //console.log(this.securityService.getUser())
                                return this.securityService.isConnectedForWorkflow()
                            }.bind(this),
                            step_on_success: "REGISTER_COMPANY",
                            step_on_failure: "CURRENT"
                        },
                        // Si on est en attente de validation
                        {
                            condition: function(){
                                return this.businessService.getUser() != null &&
                                this.businessService.getUser().workflow_state == "ACCOUNT_CREATED"
                            }.bind(this),
                            step_on_success: "REGISTER_NOTIFICATION",
                            step_on_failure: "CURRENT"
                        }
                    ],
                    links: [
                        {step: "LOGIN", label: "J'ai déjà un compte" }
                    ],
                    nexts: [
                        {   step: "REGISTER_NOTIFICATION", label: "Créer mon compte",
                            visible: false,
                            condition: (function(component) { return component.getTypeAsCode() != null})
                        }
                    ]
                },

                "REGISTER_TRANSPORTEUR": {
                    id: "REGISTER_TRANSPORTEUR",
                    position:1,
                    component: <CreateAccountStep embeded={this.props.embeded} workflow={this} type="transporter" id="REGISTER_TRANSPORTEUR"></CreateAccountStep>,
                    conditions: [
                        // Si on est déjà connecté
                        {
                            condition: function(){
                                //console.log(this.securityService.getUser())
                                return this.securityService.isConnectedForWorkflow()
                            }.bind(this),
                            step_on_success: "REGISTER_COMPANY",
                            step_on_failure: "CURRENT"
                        },
                        // Si on est en attente de validation
                        {
                            condition: function(){
                                return this.businessService.getUser() != null &&
                                this.businessService.getUser().workflow_state == "ACCOUNT_CREATED"
                            }.bind(this),
                            step_on_success: "REGISTER_NOTIFICATION",
                            step_on_failure: "CURRENT"
                        }
                    ],
                    links: [
                        {step: "LOGIN", label: "J'ai déjà un compte" }
                    ],
                    nexts: [
                        {   step: "REGISTER_NOTIFICATION", label: "Créer mon compte",
                            visible: false,
                            condition: (function(component) { return component.getTypeAsCode() != null})
                        }
                    ]
                },
                "REGISTER_NOTIFICATION": {
                    id: "REGISTER_USER",
                    position:1,
                    component: <CreateAccountNotificationStep embeded={this.props.embeded} workflow={this} type={this.props.type} id="REGISTER_USER"></CreateAccountNotificationStep>,
                    conditions: [],
                    links: [],
                    nexts: []
                },
                "FORGET_PASSWORD": {
                    id: "FORGET_PASSWORD",
                    position:1,
                    component: <ForgetPasswordStep embeded={this.props.embeded} workflow={this} type={this.props.type} id="FORGET_PASSWORD" email={this.props.email}></ForgetPasswordStep>,
                    conditions: [],
                    links: [
                        {
                            step: "LOGIN", label: "Je possède déjà un compte", 
                            visible: false,
                            condition: (function(component) { return component.state.userError == false})  
                        }
                    ],
                    nexts: [
                        { 
                            step: "FORGET_PASSWORD_CONFIRMATION", label: "Changer mon mot de passe",
                            visible: false,
                            condition: (function(component) { return component.state.userError == false})  
                        }
                    ]
                },
                "FORGET_PASSWORD_CONFIRMATION":{
                    id: "FORGET_PASSWORD_CONFIRMATION",
                    position:1,
                    component: <ForgetPasswordConfirmationStep embeded={this.props.embeded} workflow={this} type={this.props.type} id="FORGET_PASSWORD_CONFIRMATION"></ForgetPasswordConfirmationStep>,
                    conditions: [],
                    links: [],
                    nexts: []
                },
                "REGISTER_COMPANY":{
                    id: "REGISTER_COMPANY",
                    position: 2,
                    type: "workflow",
                    component: <CompanyWorkflow embeded={this.props.embeded} workflow={this} type={this.props.type} id="REGISTER_COMPANY"></CompanyWorkflow>,
                    next: null
                },
                "LOGIN": {
                    id: "LOGIN",
                    position:1,
                    component: <LoginStep embeded={this.props.embeded} workflow={this} id="LOGIN" email={this.props.email}></LoginStep>,
                    conditions: [
                        {
                            condition: function(){
                                return this.securityService.isConnectedForWorkflow()
                            }.bind(this),
                            step_on_success: "REGISTER_COMPANY",
                            step_on_failure: "CURRENT"
                        }
                    ],
                    links: [
                        {step: "REGISTER_USER", label: "Je n'ai pas encore de compte" },
                        {step: "FORGET_PASSWORD", label: "J'ai oublié mon mot de passe", onClick: (component) => {
                            component.saveEmail()
                        } }
                    ],
                    nexts: [
                        { step: "REGISTER_COMPANY", label: "Me connecter", condition: function(component) {
                            return !component.state.userError
                        } }
                    ]
                }
            }
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    restore()
    {
        if (this.props.workflow)
        {
            this.props.workflow.restore();
        }
        else
        {
            WebsiteHelper.tryEscapeFromIframe(WebsiteHelper.getHomeUrl())
            //WebsiteHelper.goToHome(this);
        }
        return false;
    }

    start(inputValue)
    {
        this.setState({ 
            input: inputValue,
            visible: true
        })
    }

    render() {
        return (
            <>
                {this.renderCurrentStep()}
            </>
        );
    }
}


export default injectIntl(AccountWorkflow)

