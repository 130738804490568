
import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardTitle,
    FormGroup,
    Form,
    Input,
    ListGroupItem,
    ListGroup,
    Progress,
    Container,
    Row,
    Col,
    Media,
    UncontrolledDropdown, 
    DropdownToggle,
    DropdownMenu,
    DropdownItem
  } from "reactstrap";
import AddressImage from "../Image/AddressImage";
import Ribbon from "../../../components/common/ribbon";
import { FormattedMessage } from "react-intl";


const AddressCard = ({ data }) => (
    <a href={"/admin/address/" + data.id }>
    <Card>
        <CardBody>
            <Ribbon longText={true} align={"center"} style={{
                    backgroundColor: (data.type.code == "ADDRESS_TYPE_INTERNAL" ? null: "var(--gris-fonce);"), 
                    color: (data.type.code == "ADDRESS_TYPE_INTERNAL" ? null: "var(--blanc);"), 
                    }
                }>
                <FormattedMessage id={data.type.code + ".short_label"}></FormattedMessage>
            </Ribbon>
            <AddressImage address={data} maxWidth="140px" />
            <div className="pt-4 text-center">
                <h5 className="h3 title">
                    <span className="d-block mb-1">{data.name}</span>
                </h5>
                <p className=" text-muted">
                    {data.street}<br/>
                    {!data.street2 ? "" : data.street2 }<br />
                    {data.zip_code}&nbsp;{data.city}{data.country_label != null ? ", " + data.country_label : ""}
                </p>
            </div>
        </CardBody>
    </Card>          
    </a>  
);


export default AddressCard