const Resources = {
    card_visa_icon: "fab fa-cc-visa",
    card_mastercard_icon: "fab fa-cc-mastercard",
    card_americanexpress_icon: "fab fa-cc-amex",
    card_default_icon: "fas fa-credit-card",

    form_email_icon: "fas fa-at",
    form_password_icon: "fas fa-lock",
    form_fname_icon: "fas fa-user-tag",
    form_lname_icon: "fas fa-user-shield",
    form_mobile_phone_number_icon: "fas fa-mobile-alt",
    form_siret_icon: "fas fa-list-ol",
    form_search_icon: "fas fa-search",
    form_address_street: 'fas fa-road',
    form_address_street2: '',
    form_address_zip_code: '',
    form_address_city: 'fas fa-city',
    form_euro: 'fas fa-euro-sign',

    signature_logo: "fas fa-file-signature",
    goto_logo: "fas fa-link",

    button_success_icon: "far fa-thumbs-up",
    button_cart_icon: "fas fa-cart-arrow-down",
    button_payment_icon: "fas fa-credit-card",
    button_add_icon: 'fa fa-plus',
    button_edit_icon: 'fas fa-edit',
    button_eye_icon: 'far fa-eye',
    button_delete_icon: "fas fa-trash-alt",
    button_unlink_icon: "fas fa-unlink",
    button_freight_document: "far fa-file-pdf",
    button_driver_icon: 'fas fa-user-alt',
    button_truck_icon: 'fas fa-truck-moving',
    button_google_play_store: 'fab fa-google-play',
    button_save_icon: 'ni ni-check-bold',
    button_search_location: 'fas fa-search-location',
    button_clone_truck_icon: 'far fa-copy',
    button_filter_icon: 'fas fa-sliders-h',
    button_list_icon: 'fas fa-list',

    info_logo: "fas fa-info-circle",
    shipper_logo: "far fa-building",
    search_result_logo: "fa fa-search",

    menu_billing_logo : "fas fa-wallet",
    menu_shipper_config_logo: "fas fa-cog",
    menu_admin: "fas fa-cogs",
    menu_alert: "fas fa-bell",
    menu_alert_iban: "fas fa-money-check",
    map_marker: "fas fa-map-marker-alt",
    transporter_addresses_logo: "fas fa-map-marked-alt",
    shipper_addresses_logo: "fas fa-map-marked-alt",
    addresses_logo : "fas fa-map-marked-alt",
    address_logo : "ni ni-pin-3",
    collaborators_logo : "fas fa-users",
    collaborator_logo: "fas fa-user-tie",
    drivers_logo: "fas fa-users-cog",
    driver_logo: "fas fa-user-alt",
    driver_switch_logl: "fas fa-exchange-alt",
    trucks_logo: "fas fa-truck-moving",
    truck_logo: "fas fa-truck-moving",
    user_logo: "fas fa-user-alt",
    search_logo: "fa fa-search",
    checkOk_logo: "far fa-check-circle",
    checkKo_logo: "far fa-times-circle",
    toast_warning_logo: "fas fa-exclamation-triangle",
    downloaded_file_logo: "fas fa-file-download",
    question_logo: "far fa-question-circle",
    no_contact_logo: "fas fa-user-slash",

    planning_logo : "fas fa-calendar-alt",
    commercial_rules_logo: "fas fa-wallet",
    orders_logo : "fas fa-map",
    mission_logo : "fas fa-calendar-day",
    comment_logo: "fas fa-comment-alt",

    mobile_phone_logo: "fas fa-mobile-alt",
    home_phone_logo: "fas fa-phone",

    reference: "fas fa-barcode",
    loading: 'fas fa-truck-loading',
    unloading: 'fas fa-dolly',
    freight: "fas fa-pallet",
    close: "fas fa-times",
    plus: "fas fa-plus-circle",
    minus: "fas fa-minus-circle",
    best: "fas fa-thumbs-up",


    MISSION_STEP_TYPE_GET_TRUCK: 'fas fa-truck-moving',
    MISSION_EVENT_STEP_START_TO_LOADING_PLACE: 'fas fa-truck-moving',
    MISSION_EVENT_STEP_START_TO_UNLOADING_PLACE: 'fas fa-truck-moving',
    MISSION_EVENT_STEP_START_TO_PARK_TRUCK: 'fas fa-truck-moving',
    
    MISSION_EVENT_TYPE_START: 'fas fa-play',
    MISSION_STEP_TYPE_PARK_TRUCK: 'fas fa-parking',
    MISSION_EVENT_TYPE_END: 'fas fa-parking',
    MISSION_EVENT_BREAK_START: "far fa-pause-circle",
    MISSION_EVENT_BREAK_END: "far fa-pause-circle",
    MISSION_EVENT_TYPE_INCIDENT: "fas fa-exclamation-triangle",

    INCIDENT_IMPACT_OK: "fas fa-exclamation-triangle",
    INCIDENT_IMPACT_DELAY: "fas fa-exclamation-triangle",
    INCIDENT_IMPACT_KO: "fas fa-exclamation-triangle",

    MISSION_STEP_TYPE_LOADING: 'fas fa-truck-loading',
    MISSION_EVENT_STEP_END_LOADING: 'fas fa-dolly',
    MISSION_EVENT_STEP_START: 'fas fa-truck-moving',
    MISSION_EVENT_STEP_ARRIVED : "fas fa-bullseye",

    MISSION_STEP_TYPE_UNLOADING: 'fas fa-dolly',
    MISSION_EVENT_STEP_END_UNLOADING: 'fas fa-dolly',

    TRANSPORT_OPERATION_STATE_LOADED: 'fas fa-truck-loading',
    TRANSPORT_OPERATION_STATE_DELIVERED: 'fas fa-dolly',
    TRANSPORT_OPERATION_STATE_DRAFT: 'fas fa-warehouse',
    TRANSPORT_OPERATION_STATE_ACTIVE: 'fas fa-warehouse',
    
    start_logo: "far fa-play-circle",
    stop_logo: "far fa-calendar-check",
    arrived_logo: "fas fa-flag-checkered",
    time_logo: "fas fa-stopwatch",
    proof_logo: "fas fa-images",


    color_selected_date: "#FFC32D",
    icon_primary_color: "#FFC32D",
    highlight_color: "orange",

    no_orders_found: "",
    no_trucks_found: "",
    no_drivers_found: "",
    no_collaborators_found: null,
    no_transporter_addresses_found: "",
    no_shipper_addresses_found: "",


    shipper_account_type_image: "https://res.cloudinary.com/dl2d3weuy/image/upload/w_600,h_600,c_crop/v1600180564/Wordpress/fond-operateur-truck2you-min_k9r3xz.png",
    transporter_account_type_image: "https://res.cloudinary.com/dl2d3weuy/image/upload/w_600,h_600,c_crop/v1600180096/Wordpress/transporteurs-truck2You_xifvib.png"
}


export default Resources