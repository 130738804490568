
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
import FormLoadingIndicator from './FormLoadingIndicator';

// reactstrap components
import {
    Alert,
    UncontrolledTooltip,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardImg,
    CardTitle,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    FormGroup,
    Form,
    Input,
    Nav,
    Navbar,
    NavItem,
    NavLink,
    Collapse,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    ListGroupItem,
    ListGroup,
    Progress,
    Container,
    Row,
    Col
  } from "reactstrap";
import { injectIntl , FormattedMessage } from 'react-intl';
import ProxyService from '../../../proxies/ProxyService'
import Message from "../../components/common/message";
import DefaultButton from "../../components/common/defaultButton";
import Resources from "../../../resources";
import DefaultLabel from "../../components/common/defaultLabel";
import DefaultImage from "../../components/common/defaultImage";
import Title3 from "../../components/common/title3";
import Title4 from "../../components/common/title4";
import Title2 from "../../components/common/title2";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import FormValidationComponent from "./FormValidationComponent";
import EmptyResult from "../../components/common/emptyResult.jsx";
import StringHelper from "../../../helpers/StringHelper.js";
import styles from "react-loading-overlay/lib/styles";
import NotFoundScene from "../../components/common/notFoundScene.jsx";


class DataList extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        
        this.state = {
            activeRemovedIsChecked : false,
            loading: true,
            showRemoved : false,
            items : [],
            filter : "",
            nbOfFilteredItems : 0
        }
        this.proxyService = new ProxyService();
    }

    activeRemovedChanged()
    {
        const showRemoved = !this.state.activeRemovedIsChecked

        if (showRemoved)
        {
            this.showRemovedItems();
        }
        else
        {
            this.showNotRemovedItems();
        }

        this.setState({
            activeRemovedIsChecked:  showRemoved
        })
    }

    getPrefix()
    {
        return "datalist";
    }
 
    componentDidMount()
    {
        this.getDatas()

        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    showNotRemovedItems()
    {
        this.setState({showRemoved : false});
        this.getDatas(this.props.apiUrl);
    }

    showRemovedItems()
    {
        this.setState({showRemoved : true});
        this.getDatas(this.props.apiRemovedUrl);
    }

    refresh()
    {
        this.getDatas(null);
    }

    getDatas(apiUrl = null)
    {
        if (apiUrl == null)
        {
            apiUrl = this.state.showRemoved ? this.props.apiRemovedUrl : this.props.apiUrl
        }
        this.setState({loading : true});
        this.proxyService.get(this, apiUrl, 
            this.getDatasSuccessCallback.bind(this)
        )
    }

    getDatasSuccessCallback(response)
    {
        this.setState({
            items : response[this.props.apiResponseProperty]
        });
    }

    hasResultsToShow()
    {
        // Si pas de résultat dans la base de données
        if (this.state.items == null || this.state.items.length == 0)
        {
            return false;
        }
        else // On regarde les résultats de filtre
        {
            if (this.state.nbOfFilteredItems == 0 && this.state.filter != "")
            {
                return false;
            }
            else
            {
                return true;
            }
        }
    }

    textFilter(event)
    {
        let filter = event.target.value.toLowerCase();
        let elements = document.getElementsByClassName("filterable")
        
        let nbMatchElements = 0;
        if (elements.length >= 0)
        {
            let element = null;
            for(let i = 0; i < elements.length; i++)
            {
                element = elements[i];
                if (element.innerText.toLowerCase().indexOf(filter) > -1)
                {
                    element.classList.remove( "filterable-hide");
                    nbMatchElements += 1;
                }
                else{
                    element.classList.add( "filterable-hide");
                }
            };
        }
        
        this.setState({nbOfFilteredItems : nbMatchElements, filter : filter})
    }

    openFilter() {
        let domElement = document.getElementsByClassName("data-list-card")[0];
        domElement.classList.add( "g-navbar-search-showing");
        setTimeout(function() {
            domElement.classList.remove( "g-navbar-search-showing");
            domElement.classList.add( "g-navbar-search-show");
        }, 150);
        setTimeout(function() {
            domElement.classList.add( "g-navbar-search-shown");
        }, 300);
      };

    closeFilter() {
        let domElement = document.getElementsByClassName("data-list-card")[0];
        
        domElement.classList.remove("g-navbar-search-shown");
        setTimeout(function() {
            domElement.classList.remove("g-navbar-search-show");
            domElement.classList.add("g-navbar-search-hiding");
        }, 150);
        setTimeout(function() {
            domElement.classList.remove("g-navbar-search-hiding");
            domElement.classList.add("g-navbar-search-hidden");
        }, 300);
        setTimeout(function() {
            domElement.classList.remove("g-navbar-search-hidden");
        }, 500);
    };

    render() {
        const items = this.state.items;
        let listItems = "";
        let nbItems = items.length;
        if(this.state.loading == false && (this.hasResultsToShow() == false))
        {
            listItems = <Col xs="12">
                        <EmptyResult
                            context={this.props.context}
                            component={
                                <>
                                    <NotFoundScene icon={Resources[this.props.context + "_logo"]}></NotFoundScene>
                                </>
                            }
                            separator={true}
                            showTitle={false} 
                            description={StringHelper.translate(
                                this, this.state.filter ? 
                                    (StringHelper.capitalize(this.props.context) + ".datalist_sorry_filter_description" ): 
                                    (StringHelper.capitalize(this.props.context) + ".datalist_sorry_default_description")
                                )
                            }>
                        </EmptyResult>
                        <Row className="justify-content-center">
                            <DefaultButton className="t2y-secondary-button" icon={Resources.button_add_icon}
                                onClick={(e) => 
                                    this.props.overrideAddButton ? this.props.overrideAddButton() : WebsiteHelper.goTo(this, this.props.addButtonUrl)
                                }>
                                {this.props.addButtonTitle}
                            </DefaultButton>
                        </Row>
            </Col>
        }
        else
        {
            
            listItems = items.map((item) =>
                <Col xs="6" sm="4" md="3" lg="3" xl="3" key={item.id} className="filterable">
                    {React.createElement(this.props.cardTemplate, {data: item})}
                </Col>
            );
        }

        return (
            <>
            {this.renderParent()}
            <Card className="data-list-card">
                {
                    (this.props.title) ?
                    (
                        <CardHeader>
                            <Row className="align-items-center ml-5 mt-1">
                                <Title3>
                                    {
                                        (this.props.titleIcon)? 
                                        (
                                            <>
                                            <DefaultImage src={this.props.titleIcon}></DefaultImage>&nbsp;
                                            </>
                                        ) : (<></>)
                                    }
                                    {this.props.title}
                                </Title3>
                            </Row>
                        </CardHeader>
                    ) : (<></>)
                }
                {
                    (this.state.items && this.state.items.length >= 0) ?
                    (
                        <CardHeader>
                            
                                    <Navbar
                                    className={classnames(
                                        "navbar-top navbar-expand border-bottom",
                                        { "navbar-dark bg-info": this.props.theme === "dark" },
                                        { "navbar-light bg-secondary": this.props.theme === "light" }
                                    )}
                                    >
                                    <Container fluid>
                                        
                                        <Collapse navbar isOpen={true}>
                                        <Form onSubmit={ e => e.preventDefault()}
                                            className={classnames(
                                            "navbar-search form-inline mr-sm-3",
                                            { "navbar-search-light": this.props.theme === "dark" },
                                            { "navbar-search-dark": this.props.theme === "light" }
                                            )}
                                        >
                                            <FormGroup className="mb-0">
                                            <InputGroup className="input-group-alternative input-group-merge">
                                                <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fas fa-search" />
                                                </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="Filtrer" type="text" onChange={this.textFilter.bind(this)} />
                                            </InputGroup>
                                            </FormGroup>
                                            <button
                                            aria-label="Close"
                                            className="close"
                                            type="button"
                                            onClick={this.closeFilter.bind(this)}
                                            >
                                            <span aria-hidden={true}>×</span>
                                            </button>
                                            <Row className="align-items-center">
                                            <h3 className="mb-0 ml-5">{
                                                (this.state.filter !== "") ?
                                                (
                                                    <small>{this.state.nbOfFilteredItems}&nbsp;/&nbsp;</small>
                                                ) : 
                                                (
                                                    <span />
                                                )
                                            }{nbItems} éléménts affiché{nbItems > 1 ? "s": ""}</h3>

                        </Row>
                                        </Form>

                                        <Nav className="align-items-center ml-md-auto" navbar>
                                            <NavItem className="d-sm-none">
                                                <NavLink onClick={this.openFilter.bind(this)}>
                                                    <i className="fas fa-search" />
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink id="refreshLink" className="nav-link-icon" href="#" onClick={e => this.refresh()}>
                                                    <i className="fas fa-sync"></i>
                                                </NavLink>
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    placement="bottom"
                                                    target="refreshLink"
                                                >
                                                    <FormattedMessage id="Default.list_refresh_button" />
                                                </UncontrolledTooltip>
                                            </NavItem>
                                            <NavItem>
                                                {
                                                    (this.props.overrideAddButton) ?
                                                    (
                                                        <NavLink id="addLink" className="nav-link-icon" onClick={(e) => this.props.overrideAddButton()} >
                                                            <i className="fas fa-plus"></i>
                                                        </NavLink>
                                                    ) :
                                                    (
                                                        <NavLink id="addLink" className="nav-link-icon" href={this.props.addButtonUrl}>
                                                            <i className="fas fa-plus" />
                                                        </NavLink>
                                                    )
                                                }
                                                
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    placement="bottom"
                                                    target="addLink"
                                                >
                                                    {this.props.addButtonTitle}
                                                </UncontrolledTooltip>
                                            </NavItem>
                                            {
                                                (this.props.allowRemovedButton) ?
                                                (
                                                    <NavItem id="customToggle" className="nav-link-icon">
                                                        <Row>
                                                            <Col xs="6">
                                                                <label className="custom-toggle custom-toggle-danger">
                                                                    <input  checked={ this.state.activeRemovedIsChecked ? "checked" : ""} type="checkbox" onChange={this.activeRemovedChanged.bind(this)} />
                                                                    <span className="custom-toggle-slider rounded-circle" />
                                                                </label>
                                                            </Col>
                                                            <Col xs="6">
                                                                <i className={(this.state.activeRemovedIsChecked ? "text-danger" : "") + " far fa-trash-alt"} />
                                                            </Col>
                                                        </Row>
                                                        
                                                        <UncontrolledTooltip
                                                        delay={0}
                                                        placement="bottom"
                                                        target="customToggle"
                                                    >
                                                        {this.state.activeRemovedIsChecked ? this.props.notRemovedButtonTitle : this.props.removedButtonTitle}
                                                    </UncontrolledTooltip>
                                                    </NavItem>
                                                ): ("")
                                            }
                                            </Nav>
                                        </Collapse>
                                    </Container>
                                    </Navbar>

                            
                        
                        </CardHeader>
                      )
                      : ("")


                  }
                <FormLoadingIndicator loading={this.state.loading}>
                <CardBody id={"card-body-" + this.props.context}>
                

                <Row id={"card-body-row-" + this.props.context}>
                {listItems}
                </Row>
                </CardBody>
                </FormLoadingIndicator>
                </Card>
            </>
        )
    }
}


export default injectIntl(DataList)