/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import queryString from 'query-string';
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components

import FormValidationComponent from "../components/FormValidationComponent.jsx";
import { injectIntl , FormattedMessage } from 'react-intl';
import RedirectToProfile from "./RedirectToProfile.jsx";
import AccountWorkflow from "../../components/workflow/account/accountWorkflow.jsx";

class Register extends FormValidationComponent {
  
  constructor(props) {
    super(props);
    
    this.state = {
      loading : false,
      success: false
    }
  }
 
  getType()
  {
    var type = "";
    switch(this.props.match.params.type)
    {
      case "transporteur":
        type = "transporter";
        break;
      case "expediteur":
        type = "shipper";
        break;
    }

    return type;
  }

  componentDidMount() {
    this.mounted = true;
    document.body.className = 'g-sidenav-show g-sidenav-pinned t2y-public-body';

    
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  end()
  {
    this.setState({success: true})
  }
  
  render() {
    if (this.state.success)
    {
      return <>
          <RedirectToProfile />
      </>
    }
    else
    {
      return (
        <>
          <AccountWorkflow id="ACCOUNT_WORKFLOW" current_step="REGISTER_USER" embeded={true} parent={this} type={this.getType()} />
        </>
      );
    }
  }
}

export default injectIntl(Register);
