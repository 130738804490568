// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://stripe.com/docs/payments/accept-a-payment#web

import React, { useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import axios from "axios";
import $ from "jquery";
// reactstrap components
import {
  Input,
  Alert,
  Button,
  Row
} from "reactstrap";
import ShipperService from "../../../../proxies/ShipperService";
import Resources from '../../../../resources';
import { injectIntl , FormattedMessage } from 'react-intl';

class CheckoutForm extends React.Component {

  constructor(props) {
    super(props);
    
    this.shipperService = new ShipperService();
    this.intl = this.props.intl;

    this.state = {
      paymentMethodIdToUse: null,
      isProcessing: false,
      checkoutError: null,
      reuseCardChecked: true,
      cardsAvailableLoaded: false
    };
  }

  setProcessingTo(value)
  {
    this.setState({isProcessing: value})
  }

  setCheckoutError(error)
  {
    this.setState({checkoutError: error})
  }

  toggleReuseCard(e)
  {
    this.setState({reuseCardChecked : !this.state.reuseCardChecked})
  }

  componentWillMount()
  {
    // Récupération des méthodes de paiement déjà enregistrées
    this.shipperService.getCardsAvailable(this, (response) => {
      this.setState({
        cards: response.payment_methods,
        cardsAvailableLoaded: true,
        paymentMethodIdToUse: response.payment_methods.length > 0 ? null : "new"
      })
    })
  }

  handleSubmit = async (event) => {
    
    // Block native form submission.
    event.preventDefault();
    
    if (this.state.paymentMethodIdToUse == null)
    {
      alert("Merci de selectionner une carte bleue existante ou d'en saisir une nouvelle.");
      return false;
    }

    const {htAmount, ttcAmount, stripe, elements, onStart, onSuccess, onError} = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    onStart()
    
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    const paymentIntentResponse = await axios.post(this.shipperService.getUrlFor("SHIPPER_CREATE_PAYMENT_INTENT_CARD"), {
      amount: ttcAmount
    }, { headers: this.shipperService.buildHeaders()});
    
console.log(paymentIntentResponse);

    const clientSecret = paymentIntentResponse.data.transaction_id;
    const paymentIntentId = paymentIntentResponse.data.id;

    var paymentMethodId = null;
    if (this.state.paymentMethodIdToUse == "new")
    {
      // ============================================================
      //          GESTION D'UNE NOUVELLE METHODE DE PAIMENT
      // ============================================================
      // Enregistrement d'une nouvelle carte bleue
      const paymentMethodResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (paymentMethodResponse.error) {
        onError(paymentMethodResponse.error.message);
        this.setCheckoutError(paymentMethodResponse.error.message);
        this.setProcessingTo(false);
        return;
      }
      paymentMethodId = paymentMethodResponse.paymentMethod.id;
      
      // On rattache le mode de paiement au compte client pour le prélèvement du solde de la commande
      const attachPaymentMethodResponse = await axios.post(this.shipperService.getUrlFor("SHIPPER_ATTACH_PAYMENT_METHOD_TO_SHIPPER"), {
        payment_method_id: paymentMethodId,
        meta_data: paymentMethodResponse.paymentMethod,
        // Si le client accepte de réutiliser ce moyen de paiement pour ses futures commandes
        reuse_type_code: $("#reusePaymentMethod").is(":checked") ? "PAYMENT_METHOD_REUSE_TYPE_ALWAYS" :"PAYMENT_METHOD_REUSE_TYPE_THIS_ORDER"
      }, { headers: this.shipperService.buildHeaders()});
    }
    else
    {
      // Utilisation du mode de paiment déjà connu
      paymentMethodId = this.state.paymentMethodIdToUse;
    }
    
    // ============================================================
    const confirmCardPaymentResponse = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodId
    });

    console.log(confirmCardPaymentResponse)

    if (confirmCardPaymentResponse.error) {
      console.log(confirmCardPaymentResponse.error)
      onError(confirmCardPaymentResponse.error.message);
      this.setCheckoutError(confirmCardPaymentResponse.error.message);
      this.setProcessingTo(false);
      return;
    }
    // Payment effectué avec succès
    onSuccess({
      amountPaiedTtc: ttcAmount,
      amountPaiedHt: htAmount,
      paymentExternalId: paymentIntentId,
      paymentMethodExternalId: paymentMethodId
    });
  };

  render() {
    const {stripe} = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="custom-control custom-checkbox">
        <Alert color="secondary">
          <i className={Resources.info_logo} />
          La carte sera utilisée pour encaisser le solde à la fin de la mission
        </Alert>
        </div>
        <hr/>
        {
          (this.state.cardsAvailableLoaded) ?
          (
            <>
              {
                (this.state.cards && this.state.cards.length > 0) ? 
                (
                  <>
                    {
                      this.state.cards.map((card, index) => {
                        return <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id={"card" + index}
                            name="customRadio"
                            type="radio"
                            onClick={(e) => this.setState({paymentMethodIdToUse: card.payment_external_id})}
                          />
                          <label className="custom-control-label" htmlFor={"card" + index}>
                            {card.type_label} {card.card_brand} XXXX-XXXX-XXXX-{card.card_display_label}
                          </label>
                        </div>
                      })
                    }
                    <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          id={"new_card"}
                          name="customRadio"
                          type="radio"
                          onClick={(e) => this.setState({paymentMethodIdToUse: "new"})}
                        />
                        <label className="custom-control-label" htmlFor={"new_card"}>
                          Saisir une nouvelle carte ?
                        </label>
                    </div>
                  </>
                ) : ("")
              }
              {
                (this.state.paymentMethodIdToUse == "new") ? 
                (
                  <>
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                    />
                    <hr/>
                    <div className="custom-control custom-checkbox">
                      <Input
                        className="custom-control-input"
                        id="reusePaymentMethod"
                        type="checkbox"
                        checked={this.state.reuseCardChecked ? "checked" : ""}
                        onChange={(e) => this.toggleReuseCard(e)}
                      />
                      <label className="custom-control-label" htmlFor="reusePaymentMethod">
                        Enregistrer ce numéro de carte pour mes futures commandes
                      </label>
                    </div>
                  </>
                ) : ("")
              }
              
              
              <Row className="justify-content-center" style={{marginTop:"20px"}}>
                <Button type="submit" disabled={!stripe} color="success">Payer</Button>
              </Row>
            </>
          ):
          (
            ""
          )
        }
        
        
      </form>
    );
  }
}

const InjectedCreditCardCheckoutForm = ({htAmount, ttcAmount, onStart, onSuccess, onError, intl}) => {

    return (
        <ElementsConsumer>
        {({elements, stripe}) => (
            <CheckoutForm intl={intl} htAmount={htAmount} ttcAmount={ttcAmount} elements={elements} stripe={stripe} onStart={onStart} onSuccess={onSuccess} onError={onError} />
        )}
        </ElementsConsumer>
    );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.


export default injectIntl(InjectedCreditCardCheckoutForm);
