import React, { Component } from "react";
import {Route, Redirect } from "react-router-dom";
import SecurityService from '../proxies/SecurityService';
import SmsValidationForm from "../views/components/common/SmsValidationForm";

const SmsValidationRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
            
            new SecurityService().checkAdministratorToken(window.btoa(window.location.href))
            ?  <Component {...props} />
            : <SmsValidationForm {...props} />
    )} />
);

export default SmsValidationRoute
