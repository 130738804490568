
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Container,
    Row,
    ButtonGroup,
    Button,
    Modal,
    Col
  } from "reactstrap";
import { Calendar } from 'react-date-range';

import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator"
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import FormHourInput from "../../pages/components/form/FormHourInput";
import ReactDatetime from "react-datetime";
import RepositoryService from "../../../proxies/RepositoryService";

import { injectIntl , FormattedMessage } from 'react-intl';
import FormRepository from "../../pages/components/form/FormRepository";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DefaultInput from "./defaultInput";
import DefaultImage from "./defaultImage";
import ReactPasswordStrength from 'react-password-strength'
import StringHelper from "../../../helpers/StringHelper";
import Resources from "../../../resources";
import Title4 from "./title4";




class RepositoriesModalInput extends DefaultInput {

    constructor(props)
    {
        super(props)

        this.repositoryService = new RepositoryService()

        this.state = {
            open: false,
            loading: false,
            selectedItem: null,
            datas: [],
            filteredDatas: []
        }
    }

    componentWillMount()
    {
        this.dataBind()
    }

    displayResponse(response)
    {
        var datas = []
        var parents = []

        response.datas.forEach(element => {
            const newData = {id : element.code, text: element.label, icon: element.ui_image_url, parents: element.parents}
            datas.push(newData)

            if (newData.id == this.getValue())
            {
                this.setState({selectedItem: newData})
            }
        });

        this.setState({datas : datas, filteredDatas: datas, loading: false})
    }

    dependsOn(sourceId, selectedValue)
    {
        this.setState({loading: true})
        this.repositoryService.getRepositoriesByParent(this, this.props.familyCode, selectedValue, (response) => {
            this.displayResponse(response)
        })
    }

    dataBind()
    {
        if(StringHelper.notEmptyValue(this.props.dependsOn))
        {
            this.dependsOn(null, this.props.dependsOn)
        }
        else
        {
            this.setState({loading: true})
            this.repositoryService.getRepositoriesWithParents(this, this.props.familyCode, (response) => {
                this.displayResponse(response)
            })
        }
    }


    toggleModal()
    {
        this.setState({open: !this.state.open, filteredDatas: this.state.datas})
    }

    modalSearchInputChanged(e)
    {   
        const datas = this.state.datas;
        var filteredDatas = [];

        if (StringHelper.notEmptyValue(e.currentTarget.value))
        {
            datas.forEach((data, index) => {
                if (data.text.toUpperCase().indexOf(e.currentTarget.value.toUpperCase()) > -1)
                {
                    filteredDatas.push(data)
                }
            })
        }
        else
        {
            filteredDatas = datas;
        }

        this.setState({filteredDatas: filteredDatas})

    }

    selectSingleValue(data)
    {
        this.setValue(data.id);
        this.setState({selectedItem: data})
        this.toggleModal();

        if (this.props.onChange)
        {
            this.props.onChange({target: {value: data.id}, preventDefault: () => {}}, this.props.property)
        }
    }

    setValue(value)
    {
        this.setState({value: value})
        document.getElementById(this.props.name).value = value
    }



    renderInput()
    {
        /*
        return <>
            <DefaultInput value={this.props.value}
                readOnly={true}
                onFocus={(e) => {this.setState({open: true})}}
                onBlur={(e) => {this.setState({open: false})}}
                required={this.props.required} 
                type="text"
                ref={this.props.ref} 
                name={this.props.name} 
                manager={this.props.manager} />
        </>*/

        return <>
            <input type="hidden" id={this.props.name} value={this.getValue()} />
            <Button title={"..."}  onClick={() => this.toggleModal()} color="default" type="button" 
            className="t2y-button-property-input" >
                {
                    (this.state.loading) ?
                    (
                        !this.props.loadingPlaceholder ? "..." : this.props.loadingPlaceholder

                    ) : (
                        (this.state.selectedItem) ?
                        (
                            <>
                                <DefaultImage key={StringHelper.createDomElementId()} style={{height:"40px", marginRight:"10px"}} src={this.state.selectedItem.icon}></DefaultImage>
                                {this.state.selectedItem.text}
                            </>

                        ) : 
                        (
                            this.props.placeholder
                        )
                    )
                }
        </Button>

        </>
    }

    getParent(parents, parent)
    {
        var current = null;
        parents.forEach(p => {
            if (p.code == parent.code)
            {
                current = p;
            }
        })


        return current;
    }

    renderFileteredDatas()
    {
        var parents = []

        this.state.filteredDatas.forEach((data, index) => {
            data.parents.forEach(parent => {
                var existingParent = this.getParent(parents, parent);
                if (existingParent == null)
                {
                    existingParent = parent
                    existingParent.datas = []
                    parents.push(existingParent)
                }

                existingParent.datas.push(data)
            })
        })

        return parents.sort((a, b) => { return a.position - b.position}).map((parent, parentIndex) => {
            return <>
                <Col lg="6" xl="4">
                    <Row>
                        <Col xs="12" key={"p-img-ctn-data-" + parentIndex} >
                            <hr/>
                            <DefaultImage key={"p-img-data-" + parentIndex} style={{width:"10%", marginRight:"5px", float:"left"}} src={parent.ui_image_url}></DefaultImage>
                            <Title4 style={{float:"left", marginTop:"10px"}}>{parent.label}</Title4>
                        </Col>
                    </Row>
                    <Row>
                {
                    parent.datas.map((data, index) => {
                        return <>
                        <Col xs="12" key={"rep-img-ctn-data-" + index}  className={"t2y-clickable" + (data.id == this.getValue() ? " selected " : "")} onClick={(e) => this.selectSingleValue(data)}>
                            <DefaultImage key={"rep-img-data-" + index} style={{width:"10%", marginRight:"20px"}} src={data.icon}></DefaultImage>
                            {data.text}
                        </Col>
                        </>
                    })
                    
                }
                </Row>
                </Col>
            </>
        })
    }

    renderModal()
    {
        return <>
            <Modal
                size="xl"
                fade={false}
                //className={"fullscreen-modal " + this.props.modal_className}
                isOpen={this.state.open}
                toggle={() => this.toggleModal()}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        {this.props.title} {this.state.open}
                    </h6>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal()} >
                    <span aria-hidden={true}>×</span>
                    </button>
                </div>
                
                <div className="modal-body">
                    <Container>
                    <Row className="align-items-center justify-content-xl-between">
                        <Col lg="6">
                                <DefaultInput type="text" onChange={e => this.modalSearchInputChanged(e)} childRef={this.searchInputRef} name={StringHelper.createDomElementId()}
                                    append={<DefaultImage src={Resources.search_logo}></DefaultImage>} />
                        </Col>
                    </Row>
                    {
                        (this.state.loading) ?
                        (
                            <><FormLoadingIndicator loading={true}></FormLoadingIndicator></>
                        ) : (
                            <Row>
                            {
                                (this.state.filteredDatas.length > 0) ?
                                (
                                    this.renderFileteredDatas()
                                ) :
                                (
                                    
                                    <Col xs="12" className="align-items-center">
                                        <hr/>
                                        <>Aucun résultat ne correspond à votre recherche</>
                                    </Col>
                                )
                            }
                            </Row>
                        )
                    }
                    </Container>
                </div>
                <div className="modal-footer" style={this.props.embeded ? null : {paddingTop: 0}}>
                    <Button className="ml-auto" color="secondary"data-dismiss="modal" type="button" onClick={() => this.toggleModal()} >
                        {StringHelper.translate(this, "Default.close")}
                    </Button>
                </div>
            </Modal>
        </>
    }

    render() {
        return <>
            {this.renderModal()}
            {this.rendeInner(this.renderInput())}


        </>
    }
}

export default injectIntl(RepositoriesModalInput)
