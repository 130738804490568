import "../../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
import PropTypes from 'prop-types';
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components
 import {
   Form
 } from "reactstrap";
 import Select2 from "react-select2-wrapper";
import GeocodingAndNavigationService from "../../../../proxies/GeocodingAndNavigationService";
import { injectIntl , FormattedMessage } from 'react-intl';

import {asyncContainer, Typeahead,  Menu, MenuItem, Highlighter} from 'react-bootstrap-typeahead';
import FormInput from "../FormInput";

const AsyncTypeahead = asyncContainer(Typeahead);


class FormAutocompleteAddress extends React.Component     {

    constructor(props, multiple = false, drawOption = null)
    {
        super(props)
        this.geocodingAndNavigationService = new GeocodingAndNavigationService();
        
        this.options = [];

        this.state = {
            isLoading: false,
            options : []
        }
    }
    /*
    addImageToOption(text, image)
    {
        if (typeof image === "undefined" || image == null || image == "")
            return text;

        return "<span><img width='20px' class='' src='" + image + "' />&nbsp;" + text + "</span>"
    }*/

    _handleSearch(text)
    {
        if (text != null && text.length > 3)
        {
            this.setState({loading: true})
            this.geocodingAndNavigationService.suggest(this, text, (suggestions) => {
                this.setState({ options: suggestions, loading: false})
            })
        }
    }
    
    _renderMenuItem(option, props, index) {
        return (
          <div key={option.id}>
            <span>{option.label}</span>
          </div>
        );
    }

    _onChanged(e)
    {
        if (typeof e !== "undefined" && e.length > 0)
        {
            this.geocodingAndNavigationService.getLocationDetail(this.props.manager, e[0].id, (gpsInfos) => {
                document.getElementById("gps_external_id").value = gpsInfos.gps_external_id;
                document.getElementById("gps_lat").value = gpsInfos.gps_lat;
                document.getElementById("gps_long").value = gpsInfos.gps_long;
                document.getElementById("gps_level").value = gpsInfos.gps_level;

                if (this.props.onSelected) {
                    this.props.onSelected(gpsInfos)
                }
            })
        }
    }


    render() {
      return (
        <>
            <FormInput type="hidden" manager={this.props.manager} name="gps_external_id" />
            <FormInput type="hidden" manager={this.props.manager} name="gps_lat" />
            <FormInput type="hidden" manager={this.props.manager} name="gps_long" />
            <FormInput type="hidden" manager={this.props.manager} name="gps_level" />
            
            <AsyncTypeahead
                {...this.state}
                id='address_suggestion' 
                name="address_suggestion"
                ref={ref => this._typeahead = ref}
                input={{id:"suggest_input_id"}}
                labelKey="street"
                placeholder={this.props.intl.formatMessage({"id" : "Default.address_suggestion_placeholder"})}
                emptyLabel={this.props.intl.formatMessage({"id" : "Default.address_suggestion_empty_results"})}
                onSearch={this._handleSearch.bind(this)}
                onChange={this._onChanged.bind(this)}
            />
        </>
      );
    }
  }
 
  export default injectIntl(FormAutocompleteAddress);