
import React from "react";
import classnames from "classnames";
import $ from "jquery";
import PropTypes from "prop-types";

// reactstrap components
import {
    Button, Row, ButtonGroup
  } from "reactstrap";
import { injectIntl , FormattedMessage } from 'react-intl';
import StringHelper from "../../../helpers/StringHelper";
import ProxyService from "../../../proxies/ProxyService";

class DefaultTabs extends React.Component {

    constructor(props)
    {
        super(props)

        this.state = {
            currentTab: this.props.tabs[0],
            currentTabIndex: 0
        }
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    componentWillMount() {}

    componentWillUnmount() {}

    toggleTabs(e, index, mode) {
        const currentTab = this.props.tabs[index];

        if (this.props.manager)
        {
            this.props.manager.setState({currentTab: currentTab})
        }

        if (this.props.afterTabSelected)
        {
            this.props.afterTabSelected(currentTab);
        }

        this.setState({
            currentTab: currentTab,
            currentTabIndex: index
        })
    }

    renderButtons()
    {
        return <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
            {
                this.props.tabs.map((tab, index) => {
                    return <Button  className={classnames({ active: this.state.currentTabIndex === index, disable: tab.disabled }) + " tabBtn"} color="secondary"
                        onClick={(e) => tab.disabled ? {} : this.toggleTabs(e, index, tab.color)}>
                        {tab.label ? tab.label : StringHelper.translate(this, tab.translationId)}
                    </Button>
                })
            }
            
        </ButtonGroup>
    }
    render() 
    {
        return <>
            <Row className="justify-content-center d-md-none mt-5">
                {this.renderButtons()}
            </Row>

            <Row className="justify-content-center text-center d-none d-md-block mt-1">
                {this.renderButtons()}
            </Row>
        
        </>
    }
}


DefaultTabs.propTypes = {
    manager: PropTypes.object.isRequired,
    tabs: PropTypes.arrayOf({
        label: PropTypes.string,
        code: PropTypes.string.isRequired,
        translationId: PropTypes.string,
        disabled: PropTypes.bool
    }),
    lead: PropTypes.string
};

export default injectIntl(DefaultTabs)
