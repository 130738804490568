/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect } from "react";
import PropTypes from "prop-types";
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardText,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import SecurityService from "../../../proxies/SecurityService";
import { injectIntl , FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import DefaultButton from "../../components/common/defaultButton.jsx";
import Title3 from "../../components/common/title3.jsx";
import WebsiteHelper from "../../../helpers/WebsiteHelper.js";
import Resources from '../../../resources'
import Config from "../../../config.js";
import StringHelper from "../../../helpers/StringHelper.js";
import BusinessService from "../../../proxies/BusinessService.js";

class InvoiceSummary extends FormValidationComponent {
    constructor(props)
    {
        super(props);
        this.businessService = new BusinessService();

        this.state = {
            invoiceHtmlContent: null
        }
    }

    componentDidMount()
    {
        
    }

    componentWillMount()
    {
        this.businessService.downloadInvoicingRequestAsHtml(this, this.props.orderId, this.props.transporterId, response => {
            this.setState({invoiceHtmlContent: response.instance})
        });
    }


    downloadInvoicingRequest()
    {
        this.businessService.downloadInvoicingRequestAsPdf(this, this.props.orderId, this.props.transporterId, response => {

            const linkSource = `data:application/pdf;base64,${response.instance.base64_content}`;
            const downloadLink = document.createElement("a");
            const fileName = response.instance.filename;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        });
    }

    renderInvoiceDetail()
    {
        return <>
            <Row>
                <Col md="12">
                    <div dangerouslySetInnerHTML={{__html: this.state.invoiceHtmlContent}}></div>
                </Col>
            </Row>
        </>
    }



  render() {
    return (
      <>
        <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
        <CardTitle className="mb-3" tag="h3">
            <Row>
                <Col md="8">
                    <Title3>{this.props.title}</Title3>
                </Col>
                <Col md="4" className="t2y-clickable text-right" onClick={e => this.downloadInvoicingRequest()}>
                    <i className={Resources.downloaded_file_logo} />
                    <span>&nbsp;<FormattedMessage id={"Orders.line_transporter_popover_facturation"}/> </span>
                </Col>
            </Row>
        </CardTitle>
        <CardText className="mb-4">
        {
            (this.props.invoice) ?
            (
                this.renderInvoiceDetail()
            ) : (<></>)
        }
        </CardText>

      </>
    );
  }
}

InvoiceSummary.propTypes = {
    orderId: PropTypes.string,
    transporterid: PropTypes.string,
    title: PropTypes.string,
    invoice: PropTypes.any
};

export default injectIntl(InvoiceSummary);
