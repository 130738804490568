export default class ArrayHelper
{
    static getKey(item, keys = [])
    {
        return keys.map(k => {
            return item[k] ? item[k] : "";
        }).join("_");
    }

    static count(array, callback)
    {
        if (!array || Array.isArray(array) == false)
        {
            return 0;
        }

        var nb = 0;
        var result = null;
        array.forEach((item, index) => {
            result = true;
            if (callback)
            {
                result = callback(item)
            }

            if (result)
            {
                nb += 1;
            }
        })

        return nb;
    }

    static findFirst(array, callback)
    {
        if (!array || Array.isArray(array) == false)
        {
            return null;
        }

        var firstItem = null;
        array.forEach(item => {
            if (callback(item)){
                firstItem = item;
            }
        })


        return firstItem;
    }

    static find(array, callback)
    {
        if (!array || Array.isArray(array) == false)
        {
            return null;
        }

        var matches = []
        array.forEach(item => {
            if (callback(item)){
                matches.push(item);
            }
        })


        return matches;
    }

    static replaceArrayItem(item, array, keys = [])
    {
        if (!array || Array.isArray(array) == false)
        {
            return []
        }

        const keyToFind = ArrayHelper.getKey(item, keys);

        var arrayToReturn = [];
        var itemExists = false;

        array.forEach(element => {
            const currentKey  = ArrayHelper.getKey(element, keys);

            // On ne conserve que les éléments avec une clé différente
            if (currentKey != keyToFind)
            {
                arrayToReturn.push(element);
            } else // Si on trouve une clé identique, on prend la nouvelle valeur
            {
                arrayToReturn.push(item)
                itemExists = true;
            }
        });

        // Si l'élément n'est pas trouvé, on l'ajoute à la fin du tableay
        if (itemExists == false)
        {
            arrayToReturn.push(item);
        }

        return arrayToReturn;
    }

    static removeArrayItem(item, array, keys = [])
    {
        if (!array || Array.isArray(array) == false)
        {
            return []
        }

        const keyToFind = ArrayHelper.getKey(item, keys);

        var arrayToReturn = [];

        array.forEach(element => {
            const currentKey  = ArrayHelper.getKey(element, keys);

            // On ne conserve que les éléments avec une clé différente
            if (currentKey != keyToFind)
            {
                arrayToReturn.push(element);
            }
            
        });

        return arrayToReturn;
    }

}