
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Button, Row, Col, Badge, UncontrolledPopover, PopoverBody
  } from "reactstrap";
import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultLabel from "./defaultLabel";
import Resources from "../../../resources";
import StringHelper from "../../../helpers/StringHelper";

class DefaultCheckbox extends React.Component {

    constructor(props)
    {
        super(props)

        this.state = {
            checked: false
        }
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
        if (this.props.checked && this.props.checked == true)
        {
            this.setState({checked: true})
        }
    }

    componentWillMount() {}

    componentWillUnmount() {}

    setValue(value)
    {
        this.setState({checked: value})
    }

    getValue()
    {
        return this.state.checked;
    }

    onChange(e)
    {
        this.setState({checked: !this.state.checked})

        if (this.props.onChange)
        {
            this.props.onChange(e);
        }
    }

    render() 
    {
        var yesLabel = this.props.intl.formatMessage({id: "Default.yes"})
        var noLabel = this.props.intl.formatMessage({id: "Default.no"})
        var id = this.props.id ? this.props.id : StringHelper.createDomElementId();
        var readonly = this.props.readonly != null && this.props.readonly === true;

        return <>
            <input type="hidden" id={id} name={id} value={this.state.checked ? 1 : 0} />
            <Row>
                <Col xs="12">
                    {
                        (!this.props.right) ?
                        (
                            <label className="custom-toggle" style={{float:"left"}}>
                                <input id={StringHelper.createDomElementId()} key={this.props.key}  checked={this.state.checked ? "checked" : ""} type="checkbox" onChange={(e) => {if (readonly == false) { this.onChange(e)}}} />
                                <span className="custom-toggle-slider rounded-circle" data-label-off={noLabel} data-label-on={yesLabel} />
                                
                            </label>
                        ) : (<></>)
                    }
                    {
                        (this.props.component) ?
                        (
                            this.props.component
                        ) : (
                            <DefaultLabel content={this.props.content} style={{float:"left", marginLeft:"20px"}}></DefaultLabel>
                        )
                    }
                    {
                        (this.props.popover) ?
                        (
                            this.props.popover
                        ) : (<></>)
                    }
                    {
                        (this.props.right) ?
                        (
                            <label className="custom-toggle" style={{float:"right"}}>
                                <input id={StringHelper.createDomElementId()} key={this.props.key} checked={this.state.checked ? "checked" : ""} type="checkbox" onChange={(e) => {if (readonly == false) { this.onChange(e)}}} />
                                <span className="custom-toggle-slider rounded-circle" data-label-off={noLabel} data-label-on={yesLabel} />
                                
                            </label>
                        ) : (<></>)
                    }
                </Col>
            </Row>
        </>

    }
}

export default injectIntl(DefaultCheckbox)
