
import React from "react";
import classnames from "classnames";
import queryString from 'query-string';
// reactstrap components
import {

    Button,
    Container,
    Form,
    FormGroup,
    UncontrolledPopover,
    UncontrolledTooltip,
    PopoverBody,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    ButtonGroup,
    CardFooter,
  } from "reactstrap";
import Resources from "../../../resources";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
import FormInput from '../components/FormInput'
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import TransporterService from "../../../proxies/TransporterService"
import SecurityService from "../../../proxies/SecurityService"
import FormHeader from "../components/form/FormHeader";
import FormGroupInput from "../components/form/FormGroupInput"
import FormAddressesInput from "../components/form/FormAddressesInput";

import FormDomainInput from "../components/form/FormDomainInput";
import FormCategoriesInput from "../components/form/FormCategoriesInput";
import TruckProperties from "./TruckProperties";
import $ from "jquery";
import { injectIntl , FormattedMessage } from 'react-intl';
import ReactSlidingPane from "react-sliding-pane";
import AddressWorkflow from "../../components/workflow/address/addressWorkflow";
import BusinessService from "../../../proxies/BusinessService";
import DefaultLink from "../../components/common/defaultLink";
import TruckWizard from "./TruckWizard";
import DefaultLabel from "../../components/common/defaultLabel";
import Title3 from "../../components/common/title3";
import DefaultInput from "../../components/common/defaultInput";
import AddressesInput from "../../components/business/addressesInput";
import DriversInput from "../../components/business/driversInput";
import defaultLabel from "../../components/common/defaultLabel";
import StringHelper from "../../../helpers/StringHelper";
import FormLegend from "../../components/form/formLegend";
import DriverWorkflow from "../../components/workflow/driver/driverWorkflow";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import DefaultButton from "../../components/common/defaultButton";
import { duration } from "moment";
import FormFooter from "../../components/form/formFooter";
import Message from "../../components/common/message";

class Truck extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.businessService = new BusinessService();
        this.transporterService = new TransporterService();
        this.securityService = new SecurityService();
        this.childs = [];
        this.createValidationRules();
        this.createDefaultState();
    }

    createDefaultState()
    {
        this.state = {
            addressPanelIsVisible: false,
            driverPanelIsVisible: false,
            propertiesOpenedAtLeastOne: false,
            loading : false,
            instance : null,
            currentTabIndex: 1,
            selectionMode: 'global',
            alreadyCompleted: false,
            formDatas : {
                domain_id: {value: null},
                category_id: {value: null},
                address_id: {value: null}
            }
        }
    }

    toggleTabs(e, index, mode) {
        e.preventDefault();

        var propertiesOpenedAtLeastOne = this.state.propertiesOpenedAtLeastOne
        // Si on clique sur l'onglet propriété
        if (propertiesOpenedAtLeastOne == false && mode == "properties")
        {
            propertiesOpenedAtLeastOne = true;
        }

        this.setState({
            selectionMode: mode,
            currentTabIndex: index,
            propertiesOpenedAtLeastOne: propertiesOpenedAtLeastOne
        });
    }

    updateState(response)
    {
        var instance = this.state.instance;
        instance.state = response.state;
        instance.state_code = response.state.code;
        instance.state_label = response.state.label;
        instance.state_image_url = response.state.ui_image_url;
        this.setState({instance :instance})
    }

    createValidationRules()
    {
        this.validationRules = {
            immatriculation : { required: false, },
            address_id: { required: false},
            reference : { required: false },
            cost_day : { required: false, rules : [{
                rule : this.amount.bind(this),
                ruleMessage: 'Merci de saisir un montant valide'
            }]},
            cost_half_day : { required: false, rules : [{
                rule : this.amount.bind(this),
                ruleMessage: 'Merci de saisir un montant valide'
            }]},
            cost_km : { required: false, rules : [{
                rule : this.amount.bind(this),
                ruleMessage: 'Merci de saisir un montant valide'
            }]},
            cost_hour : { required: false, rules : [{
                rule : this.amount.bind(this),
                ruleMessage: 'Merci de saisir un montant valide'
            }]},
        }
    }

    componentWillMount()
    {
        // Récupération des données du conducteur
        if (!this.props.match.params.id == false)
        {
            this.transporterService.getTruck(this, this.props.match.params.id, this.bindData.bind(this));
        }
    }

    componentDidMount()
    {
        const parsedQueryString = queryString.parse(window.location.search);
        if (parsedQueryString.mode && parsedQueryString.mode == "cloned")
        {
            this.showSuccessToastNotification(
                StringHelper.translate(this, "truck.clone_confirmation_title"),    
                StringHelper.translate(this, "truck.clone_confirmation_message")
            )
        }
    }

    bindData(response)
    {
        var truck = response.instance;

        // Préparation des données des conducteurs rattachés
        let drivers = []
        let preselectedValue = []
        truck.drivers.forEach(driver => {
            drivers.push(driver.id)
            preselectedValue.push({id:driver.id, name: driver.summary})
        })
        
        var formDatas = {
            address_id :  { value : truck.address ? truck.address.id : null},
            drivers : { value : drivers},
            immatriculation :  { value : truck.immatriculation },
            reference : { value : truck.reference},
            cost_day : { value : truck.cost_day},
            cost_half_day : { value : truck.cost_half_day},
            cost_hour : { value : truck.cost_hour},
            cost_km : { value : truck.cost_km},
            description: {value: truck.description}
        }

        this.childs["truckProperties"].setTruck(truck)
        this.driversInputRef.setValues(preselectedValue)

        this.setState({
            instance: truck,
            alreadyCompleted: truck.complete,
            id: truck.id, 
            stateLabel: truck.state_label,
            formDatas: formDatas, 
            loading: false
        })
    }

    /**
     * Méthode permettant de récupérer les données des propriétés à enregistrer
     * En prenant en compte le fait que l'utilisateur a ouvert ou pas l'onglet des propriétés
     */
    getPropertiesToPost()
    {
        // Si on a ouvert les propriétés alors on enregistre les données du formulaire modifié
        if (this.state.propertiesOpenedAtLeastOne)
        {
            return this.childs["truckProperties"].getDataToPost();
        }
        else // Sinon on retournes le propriétés initiales 
        {
            return { property_values: this.state.instance.property_values }
        }
    }

    onSubmitForm(e, active = null)
    {
        e.preventDefault();
        var formValidationSummary = this.htmlFormValidationSummary(e);
        var truckPropertiesFormValidationSummary = this.childs["truckProperties"].getHtmlFormValidationSummary(e);
        
        if (formValidationSummary.formIsValid && truckPropertiesFormValidationSummary.formIsValid)
        {
            // Récupération des propriétés à enregistrer
            var truckPropertiesData = this.getPropertiesToPost()

            // Vérification de la validité des données du camion
            var postedData = {
                id : this.getHtmlFormControlValue("id"),
                address_id : this.getHtmlFormControlValue("address_id")[0],
                drivers : this.driversInputRef.getValues().map(driver => { return driver.id }),
                registration : this.getHtmlFormControlValue("immatriculation"),
                reference : this.getHtmlFormControlValue("reference"),
                cost_day : this.getHtmlFormControlValue("cost_day"),
                cost_half_day : this.getHtmlFormControlValue("cost_half_day"),
                cost_km : this.getHtmlFormControlValue("cost_km"),
                cost_hour: this.getHtmlFormControlValue("cost_hour"),
                description: this.getHtmlFormControlValue("description"),
                // Ajout des valeurs issues des propriétés du camion
                ...truckPropertiesData
            }
            if(typeof active === "boolean") {
                postedData.complete = !active
            }

            this.transporterService.saveTruck(this, postedData, (response) => {
                response.complete = true;

                // Si le camion vient de passé en "Publié" ?
                if (this.state.alreadyCompleted)
                {
                    WebsiteHelper.goTo(this, "/admin/trucks?mode=success")
                    return;
                }
                else
                {
                    this.showSuccessModalNotification(this.translateMessage("truck.complete_creation_title"), <>
                        <div className="t2y-html-translation-container">
                            {StringHelper.translateWithHtml(this, "truck.complete_creation_message", null, null, "div", "text-center")}
                            <Row className="justify-content-center">
                            <DefaultLink small={true} onClick={() => WebsiteHelper.goTo(this, "/admin/planning")}>
                                {StringHelper.translateWithHtml(this, "truck.complete_creation_planning_link", null, null, "div", "text-center")}
                            </DefaultLink>
                            </Row>
                        </div>
                    </>, this.translateMessage("truck.close_go_to_list"), {
                        okButtonCallback: () => {WebsiteHelper.goTo(this, "/admin/trucks")}
                    })
                }

                this.setState({
                    id : response.id,
                    instance : response,
                    alreadyCompleted: true
                })

            }, (httpErrorResponse) => {
                var instance = this.state.instance;
                instance.complete = false;

                this.setState({loading: false, instance: instance}) 
                httpErrorResponse.json().then((apiResponse) => {
                    if (httpErrorResponse.status == 400)
                    {
                        var errorList = this.prepareResponseErrorComponent(apiResponse,
                            <>Ci dessous les données manquantes:</>
                        )

                        var errorComponent = <Col lg="6">
                            <div className="text-muted lead t2y-required-fields">
                                {errorList}
                            </div>
                            <p style={{textAlign: "justify"}}>
                                <em>Vous pouvez compléter cette fiche à tout moment.</em>
                            </p>
                        </Col>

                        var errorMessage = "Ce dernier n'est pas encore disponible à la location car certaines informations ne sont pas renseignées."

                        // Cas ou le véhicule est réservé il ne peut pas passer en brouillon
                        if(apiResponse.invalid_rules[0] === "ORDER_TRUCK") {
                            errorComponent = <Col lg="6">
                                <div className="text-muted lead t2y-required-fields">
                                    Votre véhicule sera caché de l'interface de réservation. Cependant, des commandes sont en attente de traitement de votre part. Cette action n'annule pas les courses ou sollicitations déjà reçues.
                                </div>
                            </Col>
                            errorMessage = "Bien que votre véhicule ne soit plus visible dans les recherches, vous restez engagé sur les missions déjà acceptées."
                        }
                        var message = <>
                            <Row>
                            <Col lg="6">
                                    <div className="text-center" style={{fontSize:"100px", color:"#ff9966"}}>
                                        <i className="fas fa-exclamation-triangle"></i>
                                    </div>
                                    <Title3 center={true}>Les données du véhicule ont été enregistrées en mode &laquo;Brouillon&raquo;.</Title3>
                                    <Row className="justify-content-center text-muted lead text-center">
                                        <br/>
                                        {errorMessage}
                                        <br/>
                                        <br/>
                                    </Row>
                                </Col>
                                {errorComponent}
                            </Row>
                        </>

                        var options = {
                            okButtonCallback: () => {WebsiteHelper.goTo(this, "/admin/trucks")},
                            customFooterButton: <>
                                <DefaultButton className="t2y-secondary-button small" icon={Resources.button_edit_icon} onClick={() => this.closeModal()}>
                                    {this.translateMessage("truck.complete_truck")}
                                </DefaultButton>
                            </>
                        }

                        this.showCustomModalNotification("Information", message, this.translateMessage("truck.close_go_to_list"), true, options)
                    }
                    else
                    {
                        this.showErrorFromResponse(apiResponse, "Une erreur est survenue")
                    }
                })
            });
        }
        else
        {   
            var errors = formValidationSummary.errors.concat(truckPropertiesFormValidationSummary.errors);
            if (errors.length > 0) {
                var visibleErrors = [];
                for(var i = 0; i < errors.length; i++)
                {
                    if ($("#" + errors[i]).is(":hidden") == false)
                    {
                        visibleErrors.push(errors[i]); 
                    }
                }
            
                if (visibleErrors.length > 0)
                {
                    $('html, body').animate({
                        scrollTop: $("#" + visibleErrors[0]).offset().top - 100
                    }, 1000);    
                }
                else
                {
                    // Affichage de la présence d'une erreur dans un des onglet
                    if (formValidationSummary.formIsValid == false)
                    {
                        this.errorMessage("Il y a des erreurs dans l'onglet \"Données générales\"")
                    }
                    else if (truckPropertiesFormValidationSummary.formIsValid == false)
                    {
                        this.errorMessage("Il y a des erreurs dans l'onglet \"Caractéristiques\"")
                    }
                }
                   
            }
        }
    }

    onCloneTruckAction(event)
    {
        this.transporterService.cloneTruck(this, this.state.id, (response) => {
            // Récupération du nouvel identifiant de camion
            const newTruckId = response.id;
            // Redirection vers la page du camion
            WebsiteHelper.goTo(null, "/admin/truck/" + newTruckId + "?mode=cloned")
        })
    }

    onDeleteAction(event)
    {
        this.transporterService.deleteTruck(this, this.state.id, (response) => {
            this.updateState(response)
            WebsiteHelper.goTo(this, "/admin/trucks?remove=1")
            //this.confirmMessage("Les véhicule a été supprimé avec succès")
        });
    }

    onActiveAction(event)
    {
        this.transporterService.activeTruck(this, this.state.id, (response) => {
            this.updateState(response)
            this.confirmMessage("Le véhicule a été ré-activé avec succès")
        });
    }

    referenceRequired()
    {
        if (!this.state.instance)
            return false;


        return !(StringHelper.notEmptyValue(this.state.instance.reference) || StringHelper.notEmptyValue(this.state.instance.immatriculation))
    }

    renderDriverWorkflow()
    {
        if (!this.state.driverPanelIsVisible)
            return <></>

        return <ReactSlidingPane
            className="some-custom-class"
            overlayClassName="some-custom-overlay-class"
            isOpen={this.state.driverPanelIsVisible}
            title={null}
            width="40%"
            subtitle={null}
            onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                this.setState({ driverPanelIsVisible: false });

                if (this.props.onHide) 
                    this.props.onHide(null)
            }}
        >
            <DriverWorkflow 
                id="driverCreationWorkflow"
                onDriverSubmited={(driverInfos) => { 
                    this.setState({loading: true})

                    var formDatas = this.state.formDatas;
                    formDatas.drivers.value.push(driverInfos.id)
                    
                    
                    this.setState({ driverPanelIsVisible: false, loading: false });


                    var selectedValues = this.driversInputRef.getValues();
                    selectedValues.push({id: driverInfos.id, name: driverInfos.summary})
                    this.driversInputRef.clear();
                    this.driversInputRef.dataBind(input => {
                        // Ajout du conducteur aux valeurs déjà selectionnées
                        input.setValues(selectedValues)
                        // Confirmation de la création pour l'utilisateur
                        this.showSuccessModalNotification("Confirmation", "Le conducteur à été créé avec succès et a été ajouté à la fiche du véhicule. Il ne reste plus qu'à valider la fiche pour prendre en compte la modification")
                    })
                }}>
            </DriverWorkflow>

        </ReactSlidingPane>

    }

    renderAddressWorkflow()
    {
        if (!this.state.addressPanelIsVisible)
            return <></>

        return <ReactSlidingPane
            className="some-custom-class"
            overlayClassName="some-custom-overlay-class"
            isOpen={this.state.addressPanelIsVisible}
            title={null}
            width="40%"
            subtitle={null}
            onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                this.setState({ addressPanelIsVisible: false });

                if (this.props.onHide) 
                    this.props.onHide(null)
            }}
        >
            <FormLoadingIndicator loading={this.state.loading}>
            <div className="container">
                <AddressWorkflow 
                    creation={true} 
                    customer={false}
                    autocompletePlaceHolder={this.translateMessage("address.autocomplete_transporter_placeholder")}
                    addressConfirmationAllowOperationalAddress={true}
                    onAddressSubmited={(data) => { 
                        this.setState({loading: true})
                        // Création de la nouvelle adresse
                        this.businessService.createAddress(null, data, (response) => {
                            // Récupération de l'identifiant de la nouvelle adresse
                            const addressId = response.id;

                            // Rechargement de la liste des addresses
                            this.addressInputRef.dataBind((input) => {
                                // Préselection de l'adresse
                                input.setValue(addressId);
                                var formDatas = this.state.formDatas;
                                formDatas.address_id =  { value : addressId}
                                // Confirmation de création d'adresse
                                this.showSuccessModalNotification("Confirmation", "L'adresse à été créé avec succès et a été ajoutée à la fiche du véhicule. Il ne reste plus qu'à valider la fiche pour prendre en compte la modification")

                                // Fermeture de la popup et enregistrement de la nouvelle donnée
                                this.setState({ formDatas: formDatas, addressPanelIsVisible: false, loading: false });
                        
                            });
                        })
                    }
                }></AddressWorkflow>
            </div>
            </FormLoadingIndicator>
        </ReactSlidingPane>
    }


    renderDurationInfos(km, hours, minutes, seconds, pattern = "{km} km / {duration}")
    {
        var duration = "";

        if (hours)
        {
            duration = duration + hours + " heure(s)"
        }
        if (minutes)
        {
            duration = duration + (hours ? " et " : " ") + minutes + " minute(s)"
        }
        if (seconds)
        {
            duration = duration + (hours || minutes ? " et " : " ") + seconds + "seconde(s)"
        }

        return <label className="ty2-default-input-description">
            {pattern.replace("{km}", km).replace("{duration}", duration.trim())}
        </label>

    }

    getTruckSummary(summary)
    {
        var truck = this.state.instance;
        if (truck)
        {
            if (summary && truck.summary)
            {
                return truck.summary;
            }
            else if (truck.reference && truck.immatriculation)
            {
                return truck.reference + " - " + truck.immatriculation;
            }
            else if (truck.reference || truck.immatriculation)
            {
                return truck.reference ? truck.reference : truck.immatriculation;
            }
            else {
                return StringHelper.translate(this, "truck.cloned_default_summary");
            }
        }
        else
        {
            return null;
        }
    }

    render() {
        var truckProperties = <TruckProperties rulesResults={this.state.instance ? this.state.instance.rules_results : []} truck={this.state.instance} childRef={ref => (this.childs["truckProperties"] = ref)} id="truck_properties" instance={this.state.instance} />
        return  (
            <>
            {this.renderParent()}
            <Container id="truck-container" className="mt--4" fluid>
                <Form className="needs-validation" noValidate onSubmit={e => this.onSubmitForm(e)} >
                    <Input type="hidden" id="id" name="id" value={this.state.id} />
                    <Row>
                        <Col className="order-xl-1" xl="12">
                        <FormLoadingIndicator loading={this.state.loading}>
                        <Card>
                            <CardHeader>
                                <FormHeader 
                                    instance={this.state.instance}
                                    breadCrumb={true}
                                    breadCrumbStyle={{marginLeft:70}}
                                    breadCrumbTitle1={"Default.breadcrumb_trucks_title"}
                                    breadCrumbTitle1Click={() => WebsiteHelper.goTo(this, "/admin/trucks")}
                                    breadCrumbTitle2={this.state.instance ? (this.state.instance.summary ? this.state.instance.summary : StringHelper.translate(this, "truck.cloned_default_summary")) : ""}
                                    allowDeleteButton={false}
                                    allowActiveButton={true}
                                    disableSubmitButton={true} 
                                    onActiveAction={this.onActiveAction.bind(this)}
                                    customActionButtons={ [
                                        { 
                                            generate: true,
                                            targetId: "btnCloneTruck",
                                            buttonIcon: Resources.button_clone_truck_icon,
                                            buttonText: "Cloner ce véhicule", 
                                            buttonColor: "t2y-primary-button-color",
                                            modalTitle : "Créer un nouveau véhicule par clonage ?", 
                                            modalMessage : "Confirmez-vous vouloir créer un nouveau véhicule identique à celui-ci (" + this.getTruckSummary(false) + ")?",
                                            modalSubMessage: <label className="">Le nouveau véhicule sera identique sauf la référence interne et l’immatriculation qui devront être saisies. <br/>Les caractéristiques du nouveau véhicule pourront être changées.</label>, 
                                            modalKoButtonText: StringHelper.translate(this, "Default.cancel"),
                                            modalOkButtonText: "Cloner",
                                            onAction: this.onCloneTruckAction.bind(this)
                                        },
                                        { 
                                            generate: true,
                                            modalKoButtonText: StringHelper.translate(this, "Default.cancel"),
                                            modalOkButtonText: StringHelper.translate(this, "Default.delete"),
                                            targetId: "btnDeleteTruck",
                                            buttonIcon: Resources.button_delete_icon,
                                            buttonText: StringHelper.translate(this, "Default.delete"), 
                                            buttonColor: "warning",
                                            modalTitle : "Confirmation de suppression", 
                                            modalMessage : "Confirmez-vous la suppression de ce véhicule ?", 
                                            onAction: this.onDeleteAction.bind(this)
                                        }
                                    ]}
                                >
                                </FormHeader>
                            </CardHeader>
                            <CardBody>
                                {this.state.instance?.complete && <div className="t2y-truck-complete ribbon-top-left">
                                    <span>Publlié</span>
                                </div>}

                                {!this.state.instance?.complete && <div className="t2y-truck-uncomplete ribbon-top-left">
                                    <span>Brouillon</span>
                                </div>}

                                <div style={{ display: "flex", justifyContent: "end" }}>
                                    <button style={{
                                        background:  this.state.instance?.complete ? "#F05656": "#22bb33",
                                        borderRadius: 5,
                                        border: "none",
                                        padding: "5px 10px 5px 10px",
                                        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                                        fontSize: "1.2em",
                                        color: "#FFFFFF"
                                    }}
                                    onClick={(e) => this.onSubmitForm(e, this.state.instance?.complete)}
                                    type="button"
                                    className={"t2y-btn-sm-primary mt-2"}>
                                        {this.state.instance?.complete ? "Désactiver" : "Activer"} le véhicule
                                    </button>
                                </div>
                                <div className="nav-wrapper">
                                <FormLegend required={true} context="truck"></FormLegend>
                                <Row className="justify-content-center">
                                    <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                                        <Button className={classnames({ active: this.state.selectionMode === "global" }) + " tabBtn"} color="secondary" 
                                            onClick={(e) => this.toggleTabs(e, 1, 'global')}>
                                            Données générales
                                        </Button>
                                        <Button className={classnames({ active: this.state.selectionMode === "properties" }) + " tabBtn"} color="secondary" 
                                            onClick={(e) => this.toggleTabs(e, 2, 'properties')}>
                                            Caractéristiques
                                        </Button>
                                    </ButtonGroup>
                                </Row>
                                </div>
                                <TabContent activeTab={"tabs" + this.state.currentTabIndex}>
                                    <TabPane tabId="tabs1">
                                    <Row>
                                        <Col lg="12">
                                                <div className="pl-lg-4">
                                                
                                                    <h6 className="heading-small text-muted mb-4">
                                                        Informations du véhicule
                                                    </h6>
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroupInput label={StringHelper.translate(this, "truck.edit_reference")} 
                                                                tooltip={{message: StringHelper.translate(this, "truck.edit_reference_message")}}
                                                                htmlFor={"reference"}>
                                                                <DefaultInput 
                                                                    required={true}
                                                                    name='reference' manager={this} value={this.state.instance ? this.state.instance.reference : null}>

                                                                </DefaultInput>
                                                            </FormGroupInput>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroupInput label={"Immatriculation"} htmlFor="immatriculation">
                                                                <DefaultInput name='immatriculation' value={this.state.instance ? this.state.instance.immatriculation : null} 
                                                                required={true} />
                                                            </FormGroupInput>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroupInput label={StringHelper.translate(this, "truck.edit_description")} htmlFor="description" labelColumns="2" contentColumns="10"
                                                                tooltip={{message:StringHelper.translate(this, "truck.edit_description_message")}}
                                                            >
                                                                <DefaultInput 
                                                                    value={this.state.instance ? this.state.instance.description : null} 
                                                                    type="textarea" rows="4" name="description"></DefaultInput>
                                                            </FormGroupInput>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroupInput label={StringHelper.translate(this, "truck.edit_address")} htmlFor="address_id">
                                                                <AddressesInput required={true} childRef={(elt) => this.addressInputRef = elt} multiple={false} manager={this} name="address_id" />
                                                                <DefaultLink small={true} onClick={(e) => this.setState({addressPanelIsVisible: true})} style={{paddingTop:"5px", float:"right"}} icon={Resources.button_add_icon}>Créer un nouveau lieu de stationnement</DefaultLink>
                                                            </FormGroupInput>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroupInput label={StringHelper.translate(this, "truck.edit_drivers")} htmlFor="drivers" tooltip={{message: StringHelper.translate(this, "truck.edit_drivers_message")}}>
                                                                <DriversInput multiple={true} value={this.getFormControlValue("drivers")} childRef={(elt) => this.driversInputRef = elt}  manager={this} name="drivers" required={false}></DriversInput>
                                                                <DefaultLink small={true} onClick={(e) => this.setState({driverPanelIsVisible: true})} style={{paddingTop:"5px", float:"right"}} icon={Resources.button_add_icon}>Créer un nouveau conducteur</DefaultLink>
                                                            </FormGroupInput>
                                                        </Col>
                                                    </Row>
                                                    <h6 className="heading-small text-muted mb-4">
                                                        Votre tarif
                                                    </h6>
                                                    <Row>
                                                        <Col lg="6">
                                                            <Card>
                                                                <CardHeader>
                                                                    Forfait
                                                                    <br/>
                                                                    <Message type="infos"  oneLine={true}>
                                                                    <label className="ty2-default-input-description">{StringHelper.translate(this, "truck.toll_message")}</label>
                                                                    </Message>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg="12">
                                                                            <FormGroupInput label={StringHelper.translate(this, "truck.edit_cost_day")} htmlFor="cost_day" >
                                                                                <DefaultInput
                                                                                    required={true}
                                                                                    icon={Resources.form_euro} type="number" name='cost_day' manager={this}
                                                                                    value={this.state.instance ? this.state.instance.cost_day : 0.00} 
                                                                                >
                                                                                </DefaultInput>
                                                                                {!this.state.instance ? <></> : this.renderDurationInfos(this.state.instance.bnd_distance_per_day, this.state.instance.bnd_hours_per_day, this.state.instance.bnd_minutes_per_day, null, StringHelper.translate(this, "truck.edit_cost_day_message"))}
                                                                            </FormGroupInput>
                                                                        </Col>
                                                                        <Col lg="12">
                                                                            <FormGroupInput label={StringHelper.translate(this, "truck.edit_cost_half_day")} htmlFor="cost_half_day" >
                                                                                <DefaultInput
                                                                                    icon={Resources.form_euro} type="number" name='cost_half_day' manager={this}
                                                                                    value={this.state.instance ? this.state.instance.cost_half_day : 0.00} 
                                                                                ></DefaultInput>
                                                                                {!this.state.instance ? <></> : this.renderDurationInfos(this.state.instance.bnd_distance_per_half_day, this.state.instance.bnd_hours_per_half_day, this.state.instance.bnd_minutes_per_half_day, null, StringHelper.translate(this, "truck.edit_cost_half_day_message"))}
                                                                            </FormGroupInput>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="6">
                                                            <Card>
                                                                <CardHeader>
                                                                    Suppléments
                                                                    <br/>
                                                                    <Message type="infos" oneLine={true}>
                                                                        <label className="ty2-default-input-description">{StringHelper.translate(this, "truck.toll_message")}</label>
                                                                    </Message>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg="12">
                                                                            <FormGroupInput label={StringHelper.translate(this, "truck.edit_km_sup")} htmlFor="cost_km">
                                                                                <DefaultInput
                                                                                    required={true}
                                                                                    icon={Resources.form_euro} type="number" name='cost_km' manager={this}
                                                                                    value={this.state.instance ? this.state.instance.cost_km : 0.00} 
                                                                                ></DefaultInput>
                                                                            </FormGroupInput> 
                                                                        </Col>
                                                                        <Col lg="12">
                                                                            <FormGroupInput label={StringHelper.translate(this, "truck.edit_hour_sup")} htmlFor="cost_hour">
                                                                                <DefaultInput
                                                                                    required={true}
                                                                                    icon={Resources.form_euro} type="number" name='cost_hour' manager={this}
                                                                                    value={this.state.instance ? this.state.instance.cost_hour : 0.00} 
                                                                                ></DefaultInput>
                                                                                <label className="ty2-default-input-description">{StringHelper.translate(this, "truck.edit_hour_sup_message")}</label>
                                                                            </FormGroupInput>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                
                                    </TabPane>
                                    <TabPane tabId="tabs2">
                                        <Row>
                                            <Col lg="12">
                                                {truckProperties}
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
        
                            </CardBody>
                            <CardFooter>
                                <FormFooter instance={this.state.instance}></FormFooter>
                            </CardFooter>
                        </Card>
                        </FormLoadingIndicator>
                        </Col>

                        </Row>
                    </Form>
                


                
                </Container>

                {this.renderAddressWorkflow()}
                {this.renderDriverWorkflow()}
          </>
        );
    }
}


export default injectIntl(Truck)