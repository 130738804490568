import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import Config from '../../config.js'

class Copyright extends React.Component {
    render() {
        return (
          <>
            <div className="copyright text-center text-xl-left text-muted">
                © {new Date().getFullYear()}{" "}
                <a
                className="font-weight-bold ml-1"
                href={Config.companyNameWebsite}
                target="_blank"
                >
                {Config.companyName}
                </a>
            </div>
          </>
        );
    }
}


export default Copyright;