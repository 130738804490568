
import React from "react";
import classnames from "classnames";
import $ from "jquery";

import {
    Row,
    Col,
    Badge,
    Alert,
    Modal,
    Button,
    ButtonGroup,
    Spinner,
    Form
} from "reactstrap";
import Title3 from "../common/title3"
import Title2 from "../common/title2"
import Message from "../common/message";
import { injectIntl, FormattedMessage } from 'react-intl';
import RepositoryService from '../../../proxies/RepositoryService'
import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator";
import { format, formatDistance } from 'date-fns'
import { fr } from 'date-fns/locale'
import { Calendar } from 'react-date-range';
import DateHelper from '../../../helpers/DateHelper'
import ImageWithRescue from "../../pages/components/Image/ImageWithRescue";
import Resources from "../../../resources";
import DefaultLink from "../common/defaultLink"
import DefaultButton from "../common/defaultButton"
import DefaultImage from "../common/defaultImage";


var moment = require('moment');
require('moment/locale/fr');


class PortalDateSearchInput extends React.Component {

    constructor(props) {
        super(props);

        this.repositoryService = new RepositoryService();

        this.state = {
            modal: false,
            start_date: null,
            end_date:null,
            
            use_saturday: false,
            use_sunday: false,

            selection_mode: "day", // day, half, period

            public_holidays: [],

            dates: [],

            current_step: this.renderStartDateSelection.bind(this)
        }
    }

    getValues()
    {
        var expectedDates = []
        this.state.dates.forEach(d => {
            expectedDates.push(DateHelper.toMysqlDateFormat(d));
        })

        return {
            dates: expectedDates,
            half_day: this.state.selection_mode === "half",
            use_sunday: this.state.use_sunday,
            use_saturday: this.state.use_saturday
        }
    }

    setValues(request) {
        var expectedDates = []
        request.days.forEach(d => {
            expectedDates.push(DateHelper.phpDateToJavascript(d));
        })

        var selectionMode = request.half_day ? "half" : (
            expectedDates.length > 1 && request.end_date != request.star_date ? "period" : "day"
        );

        if (selectionMode == "period")
        {
            this.checkDates(
                DateHelper.phpDateToJavascript(request.start_date),
                DateHelper.phpDateToJavascript(request.end_date)
            )
        }

        this.setState({
            modal: false,
            start_date: DateHelper.phpDateToJavascript(request.start_date),
            end_date: DateHelper.phpDateToJavascript(request.end_date),
            dates: expectedDates,
            selection_mode: selectionMode,
            use_sunday: request.use_sunday,
            use_saturday: request.use_saturday
        })

    }

    isPublic() {
        return !this.props.public ? true : this.props.public
    }

    componentWillMount() {

        // Récupération des jours fériés
        //this.repositoryService.getPublicHolidays(this, )
    }

    componentDidMount() {
        this.props.childRef(this)
    }

    
    toggleModal(validation) {
        var modal = !this.state.modal
        this.setState({ modal: modal, render_values: false }, () => {
            if (modal == false && validation == true)
            {
                this.props.manager.validateInput(this)
            }
        })
    }

    toggleState(stateKey)
    {
        var v = {}
        v[stateKey] = !this.state[stateKey]
        this.setState(v);
    }

    switchSelectionMode(mode)
    {
        this.setState({
            selection_mode: mode,
            start_date: null,
            end_date: null,
        })
    }

    startDateSelected(date)
    {
        var dates = []
        if (this.state.selection_mode !== "period")
        {
            this.toggleModal(true);
            this.setState({
                start_date: date, 
                dates: [date]
            })
        }
        else {
            this.setState({
                start_date: date,
                current_step: this.renderEndDateSelection.bind(this)
            })
        }

    }

    checkDates(startDate, endDate)
    {
        // Vérification de la présence de weekend
        var weekends = DateHelper.findSpecificDaysBetween2Dates(startDate, endDate, [
            DateHelper.SATURDAY, DateHelper.SUNDAY
        ])

        // Alerte utilisateur pour savoir si il veut prendre en compte les samedis et / ou dimanches   
        if (weekends.length)
        {
            this.setState({
                end_date: endDate,
                current_step: this.renderAvancedSelection.bind(this)
            })
        }
        else
        {
            this.setState({end_date: endDate})
            this.validatePeriod(startDate, endDate)
        }
    }

    endDateSelected(date)
    {
        this.checkDates(this.state.start_date, date)
    }

    changeStartDate()
    {
        this.setState({
            dates: [],
            start_date: null,
            end_date: null
        })
    }

    validatePeriod(startDate, endDate)
    {
        var exclusions = [];
        //TODO gestion des jours fériés
        //this.state.public_holidays;

        if (this.state.use_sunday == false)
        {
            exclusions.push(DateHelper.SUNDAY)
        }

        if (this.state.use_saturday == false)
        {
            exclusions.push(DateHelper.SATURDAY)
        }

        // Calcul du nombre de jours entre date début et date fin
        var dates = DateHelper.findDaysBetween2Dates(
            startDate, 
            endDate,
            exclusions
        )

        // Validation de chaque date entre date de début et date de fin
        this.setState({
            dates: dates
        })
        this.toggleModal(true);
    }

    renderCalendar(date, minDate, onChange)
    {
        return <>
            <Calendar 
                locale={fr} 
                color={Resources.color_selected_date}
                minDate={/*DateHelper.addDaysToDate(1, minDate)*/ minDate}
                date={date}     
                onChange={(d) => onChange(d)}>
            </Calendar>
        </>
    }

    renderStartDateSelection()
    {
        return <>
        <Row className="justify-content-center">
            <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
            <Button className={"t2y-date-search-input-tab-day " + classnames({ active: this.state.selection_mode === "day" }) + " multiChoiceBtn"} color="secondary"onClick={() => this.switchSelectionMode("day")}>
                <input
                autoComplete="off"
                name="options"
                type="radio"
                value={this.state.selection_mode === "day"}
                />
                1 jour
            </Button>
            <Button className={"t2y-date-search-input-tab-half-day " + classnames({ active: this.state.selection_mode === "half" }) + " multiChoiceBtn"} color="secondary"onClick={() => this.switchSelectionMode("half")}>
                <input
                autoComplete="off"
                name="options"
                type="radio"
                value={this.state.selection_mode === "half"}
                />
                1/2 journée
            </Button>
            <Button className={"t2y-date-search-input-tab-period " + classnames({ active: this.state.selection_mode === "period"}) + " multiChoiceBtn"} color="secondary"onClick={() => this.switchSelectionMode("period")}>
                <input
                autoComplete="off"
                name="options"
                type="radio"
                value={this.state.selection_mode === "period"}
                />
                plusieurs jours
            </Button>
            </ButtonGroup>
        </Row>
        <Row className="justify-content-center">
            {this.renderCalendar(this.state.start_date, new Date(), this.startDateSelected.bind(this))}
        </Row>
        <Row className="justify-content-center">    
            <DefaultLink small={true} onClick={() => {this.setState({
                current_step: this.renderStartDateSelection.bind(this),
                use_sunday: false,
                use_saturday: false,
                start_date: null,
                end_date: null
            }); this.toggleModal(false)}}>
                <FormattedMessage id="Default.cancel"></FormattedMessage>
            </DefaultLink>
        </Row>
        </>
    }
    
    renderEndDateSelection()
    {
        return <>
            <Row className="justify-content-center">
                {this.renderCalendar(this.state.end_date, this.state.start_date, this.endDateSelected.bind(this))}
           
                <DefaultLink small={true} onClick={() => this.setState({
                    current_step: this.renderStartDateSelection.bind(this),
                    start_date: null,
                    end_date: null
                })}>
                    <FormattedMessage id="Modifier la date de début">
                    </FormattedMessage>
                </DefaultLink>
            </Row>
        </>
    }

    renderAvancedSelection()
    {
        return <>
            <Message type="infos">
                <strong>Attention!</strong> La période selectionnée contient un ou plusieurs weekend.
            </Message>

            <Title3 content="Souhaitez vous conserver ces jours de livraisons "></Title3>
            <div className="custom-control custom-checkbox">
                <input className="custom-control-input" id="ckbSaturday" type="checkbox" 
                    checked={this.state.use_saturday ? "checked": ""}
                    onClick={(e) => this.toggleState("use_saturday")} />
                <label className="custom-control-label" htmlFor="ckbSaturday">
                conserver le samedi
            </label>
            </div>
            <div className="custom-control custom-checkbox">
                <input className="custom-control-input" id="ckbSunday" type="checkbox"  
                    checked={this.state.use_sunday ? "checked": ""}
                    onClick={(e) => this.toggleState("use_sunday")} />
                <label className="custom-control-label" htmlFor="ckbSunday">
                conserver le dimanche
            </label>
            </div>
            
            <DefaultLink small={true} style={{float: "left", marginTop:"35px"}} onClick={() => this.setState({
                current_step: this.renderEndDateSelection.bind(this),
                use_sunday: false,
                use_saturday: false,
                end_date: null
            })}>
                <FormattedMessage id="Modifier la date de fin"></FormattedMessage>
            </DefaultLink>
        </>
    }

    renderCurrentStep()
    {
        return <>
            {
                this.state.current_step()   
            }
        </>
    }
    
    
    renderModal() {
        var className = "fullscreen-modal " + this.props.modal_className
        var size=null
        
        return <>
            <Modal fade={false} className={className} size={size} isOpen={this.state.modal} toggle={() => this.toggleModal(false)} >
                <div className="modal-body">
                    {this.renderCurrentStep()}

                    {
                        (this.state.selection_mode == "period" && this.state.end_date) ?
                        (
                            <DefaultButton style={{float:"right"}} onClick={() => {this.validatePeriod(this.state.start_date, this.state.end_date)}} icon="fas fa-check" className="t2y-secondary-button small">
                                <FormattedMessage id="Default.validate"></FormattedMessage>
                            </DefaultButton>
                        ) : (<></>)                        
                    }
                </div>
                <div className="modal-footer">
                </div>
            </Modal>

        </>
    }

    renderDateSelectionSummary()
    {
        var summary = "Date de la prestation";
        switch(this.state.selection_mode)
        {
            case "day":
                if (this.state.start_date)
                {
                    summary = "la journée du " + DateHelper.formatWithoutTime(this.state.start_date, this.props.intl)
                }
            break;
            case "half":
                if (this.state.start_date)
                    summary = "demi-journée du " + DateHelper.formatWithoutTime(this.state.start_date, this.props.intl)
            break;
            case "period":
                if (this.state.start_date && this.state.end_date)
                {
                    summary = "Du " + 
                    DateHelper.formatWithoutTime(this.state.start_date, this.props.intl) + 
                    " au " + 
                    DateHelper.formatWithoutTime(this.state.end_date, this.props.intl)
                }
            break;

        }
        return summary;
    }

    render() {
        return <>
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
            {this.renderModal()}

            <Button onClick={() => this.toggleModal(false)} color="default" type="button" className="t2y-search-input t2y-search-input-calendar">
                <span className="btn-inner--icon">
                    <DefaultImage src={"SVG:" + require("../../../assets/img/icons/search-input-calendar.svg").default} />
                </span>
                <span className="btn-inner--text">
                    {
                        this.renderDateSelectionSummary()
                    }
                </span>
            </Button>

        </>
    }
}

export default injectIntl(PortalDateSearchInput)
