import "../../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components

import {Form} from "reactstrap";
import Select2 from "react-select2-wrapper";


import RepositoryService from "../../../../proxies/RepositoryService";
import FormInputValidationMessages from '../FormInputValidationMessages.jsx';
import FormComponent from "./FormComponent";
import StringHelper from "../../../../helpers/StringHelper";

class FormSelect2Component extends FormComponent     {

    constructor(props, multiple = false, drawOption = null)
    {
        super(props)
        this.drawOption = drawOption;
        this.multiple = multiple;
        this.mounted = false;
        this.state = {
            loading: false,
            buffer : null,
            datas : []
        }
    }

    addImageToOption(text, image)
    {
        if (typeof image === "undefined" || image == null || image == "")
            return text;

        return "<span><img width='20px' class='' src='" + image + "' />&nbsp;" + text + "</span>"
    }

    componentDidMount() {
        if (!this.props.noAutoBinding)
        {
            this.dataBind();
        }

        this.mounted = true;
    }

    onChange(e)
    {
        if (this.props.manager)
        {
            this.props.manager.htmlInputChanged(e, this.getKey())
        }
    }

    onBlur(e)
    {
        if (this.props.manager)
        {
            this.props.manager.htmlInputChanged(e, this.getKey())
        }
    }
    
    getValue()
    {
        if (this.value)
        {
            const value = this.value;
            // Suppression de l'ancienne valeur pour ne pas la forcer lors de la prochaine modification
            this.value = null;
            return value;
        }
        else
        {
            return this.props.manager.getFormControlValue(this.getKey());
        }
    }

    setValue(value)
    {
        this.value = value
    }

    render() {
        let validationState = this.getValidationState();
        var className = "form-control searching-in-progress " + (this.props.className? this.props.className : "") + " " + this.className ? this.className : ""
        
        const actor = "me"
        return (
            <>
                {
                    (this.state.loading) ?
                    (
                        <span>{"Chargement en cours ..."}</span>
                    )
                    :
                    (
                        <Select2
                            disabled={this.props.readOnly}
                            key={this.getId()}
                            id={this.getId()}
                            name={this.getName()}
                            className={className}
                            defaultValue={this.getValue()}
                            value={this.multiple ? this.getValue() : null}
                            options={{
                                language: {
                                    noResults: function (params) {
                                      return StringHelper.translate(this, "Default.no_results_found", "Aucun résultat trouvé");
                                    }
                                  },
                                className: "searching-in-progress",
                                placeholder: typeof this.props.placeholder !== "undefined" ? this.props.placeholder : "...", 
                                templateResult : this.drawOption ? this.drawOption : (elt) => { return elt.text},
                                templateSelection : this.drawOption ? this.drawOption : (elt) => { return elt.text},
                                escapeMarkup: (m) => { return m;},
                            }}
                            multiple={this.multiple}
                            data={!this.state.datas ? [] : this.state.datas}
                            onBlur={this.props.onBlur ? this.props.onBlur : this.onBlur.bind(this)}
                            onChange={this.props.onChange ? this.props.onChange : this.onChange.bind(this)}
                        />
                    )
                }
            </>
      );
    }
/*
    componentDidMount() {
        this.dataBind();
    }

    updateDatas(datas)
    {
        this.setState({datas : datas})
    }

    componentDidUpdate(prevProps,  prevState)
    {
       this.localOnChanged(null);
    }

    localOnChanged(event)
    {
        let validationState = this.getValidationState() ;
        if (validationState !== null && validationState !== "valid")
        {
            this.onError();
        }
        else
        {
            this.onSuccess();
        }
    }
    
    onSuccess()
    {
        let doc = document.getElementById(this.props.id).parentElement;
        let select2 = doc.getElementsByClassName("select2-container")[0];
        select2.classList.remove( "is-invalid");
    }

    onError()
    {
        let doc = document.getElementById(this.props.id).parentElement;
        let select2 = doc.getElementsByClassName("select2-container")[0];
        select2.classList.remove( "is-invalid");
        select2.classList.add("is-invalid");
    }

    render() {
        let validationState = this.getValidationState();
      return (
        <>
            <Select2
                id={this.props.id}
                name={this.props.id}
                className={"form-control"}
                defaultValue={this.getDefaultValue()}
                options={{
                    placeholder: typeof this.props.placeholder !== "undefined" ? this.props.placeholder : "..."
                }}
                multiple={this.multiple}
                data={!this.state.datas ? [] : this.state.datas}
                onBlur={this.getOnChange.bind(this)}
            />
            <FormInputValidationMessages 
                validationState={validationState}
                validationValidMessage={this.getValidationValidMessage()}
                validationRequiredMessage={this.getValidationRequiredMessage()}
                validationInvalidMessage={this.getValidationInvalidMessage()}
            />
        </>
      );
    }*/
  }
 
  export default FormSelect2Component;