
import React from "react";

const StateLabel = ({ data }) => (

    <small data-code={typeof data !== "undefined" ? data.state_code : ""} className="h4 font-weight-light text-muted">
        
        {typeof data !== "undefined" ? data.state_label : ""}
    </small>
              
);


export default StateLabel