
import React from "react";
import classnames from "classnames";
import queryString from 'query-string';
// reactstrap components
import {
    Container,
    Input,
    Button,
    Row,
    Col,
    Form,
    ButtonGroup,
    Card,
    Table,
    CardHeader,
    CardBody,
    Collapse,
    CardFooter,
    TabContent,
    TabPane

  } from "reactstrap";
import BusinessService from "../../../proxies/BusinessService";
import GeocodingAndNavigationService from "../../../proxies/GeocodingAndNavigationService";
import FormLoadingIndicator from "../components/FormLoadingIndicator";
import FormValidationComponent from "../components/FormValidationComponent";
import FormInput from "../components/FormInput"
import FormOpenTimeInput from "../components/form/FormOpenTimeInput"
import FormHourInput from '../components/form/FormHourInput'
import FormCivilityInput from "../components/form/FormCivilityInput"
import FormCountryInput from "../components/form/FormCountryInput";
import FormCollaboratorsInput from "../components/form/FormCollaboratorsInput";
import { injectIntl , FormattedMessage } from 'react-intl';
import FormAutocompleteAddress from "../components/form/FormAutocompleteAddress";
import moment from 'moment';
import FormHeader from "../components/form/FormHeader";
import FormAddress from "../components/form/FormAddress";
import FormGroupInput from "../components/form/FormGroupInput";
import DefaultButton from "../../components/common/defaultButton";
import DefaultImage from "../../components/common/defaultImage";
import DefaultInput from "../../components/common/defaultInput";
import CivilityInput from "../../components/common/civilityInput";
import FormLegend from "../../components/form/formLegend";
import CollaboratorsInput from "../../components/business/collaboratorsInput";
import { add } from "date-fns";
import SecurityService from "../../../proxies/SecurityService";
import StringHelper from "../../../helpers/StringHelper";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import Resources from "../../../resources";
import FormFooter from "../../components/form/formFooter";
import EmptyResult from "../../components/common/emptyResult";
import NotFoundScene from "../../components/common/notFoundScene";
import DateHelper from "../../../helpers/DateHelper";
import TruckSummary from "../../components/business/truckSummary";
import Title4 from "../../components/common/title4";
import ArrayHelper from "../../../helpers/ArrayHelper";
import ShipperService from "../../../proxies/ShipperService";
import TruckResult from "../components/search/TruckResult";
import CallForBidsWorkflow from "../../components/workflow/callForBids/callForBidsWorkflow";
import CountDown from "../../components/common/countDown";

class CallsForBids extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.securityService = new SecurityService();
        this.shipperService = new ShipperService();
        this.businessService = new BusinessService();
        
        const selectedId = this.getDefaultSelectedId()
        var openedCollapses = selectedId ? ["collapse" + selectedId] : []

        this.state = {
            openedCollapses: openedCollapses,
            selectedId: selectedId,
            values: this.props.values
        }
    }

    getDefaultSelectedId()
    {
        if (this.props.current)
        {
            return this.props.current
        }
        else
        {
            return this.props.values.length == 1 ? this.props.values[0].id : null
        }
    }

    componentWillMount()
    {
    }

    componentDidMount()
    {
        // Vérification de la présence d'un parametre
        const parsedQueryString = queryString.parse(window.location.search);
        if (parsedQueryString.result_id)
        {
            this.props.values.forEach(bids => {
                if (bids.results.length > 0)
                {
                    bids.results.forEach(result => {
                        if (result.id == parsedQueryString.result_id)
                        {
                            this.callForBidsWorkflowRef.startTransporterProposal(result)
                        }
                    })
                }
            })
        }
    }

    end()
    {
        this.props.parent.loadCallForBids(null, true);
    }

    renderCancelBidsConfirmationModal(callForBids)
    {
        var options = {}
        options.iconStyle = {fontSize:"60px"}
        options.iconColor = "red";
        options.title = <>Annulation<br/>de l'appel d'offres</>
        options.component = <>Êtes-vous sûr d'annuler<br/>cet appel d'offres&nbsp;?</>
        options.cancelButtonTitle = "Ne rien faire";
        options.okButtonTitle = "Annuler l'appel d'offres";
        options.okButtonClassName = "t2y-danger-button";
        options.okButtonCallback = () => {
            this.shipperService.cancelCallForBids(this, callForBids.id, 
                (response) => {
                    this.showModalNotification("success", "", <>Votre appel d'offres a bien été annulé</>);
                    this.setState({values: ArrayHelper.find(this.state.values, (item) => { return item.id != callForBids.id})}, () => {
                    })
                }, 
                (httpErrorResponse) => {
                    httpErrorResponse.json().then((apiErrorResponse) => {
                        this.showErrorFromResponse(apiErrorResponse)
                    })
                    this.setState({loading: false})
                }
            )
        }

        this.showModalConfirmation(options)
    }

    collapsesToggle = (collapseCode, selectedId) => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapseCode)) {
            this.setState({
                selectedId: null,
                openedCollapses: []
            });
        } else {
            this.setState({
                selectedId: selectedId,
                openedCollapses: [collapseCode]
            });
        }
    };

    expandedIsOpen(collapseCode, selectedId)
    {
        if (this.state.selectedId)
        {
            var exists = this.state.selectedId == selectedId
            return exists;
        }
        else
        {
            return this.state.openedCollapses.includes(collapseCode);
        }
    }

    renderShipperCallForBidsState(bids)
    {
        if (parseInt(bids.transporter_expiration_date_as_seconds) > 0)
        {
            return <>Attente de réponse des transporteurs</>
        }
        else
        {
            return <>Au moins un véhicule attend votre réponse</>;
        }
    }

    renderTransporterCallForBidsState(bids)
    {
        return this.renderState(bids, <>Dans l'attente de la réponse de l'expéditeur</>)
    }

    renderState(bids, message)
    {
        if (parseInt(bids.transporter_expiration_date_as_seconds) > 0)
        {
            // Si la date d'expiration n'est pas dépassé
            return <CountDown value={bids.transporter_expiration_date_as_seconds}
                renderAsHms={true}
                pattern={"Expire dans {h}h{m}m{s}s"}
                onDone={() => {
                    this.props.parent.loadCallForBids(null, true);
                }}>
            </CountDown>
        }
        else {
            return <>{message}</>
        }
    }

    renderEmptyContent()
    {
        return <>
            {this.renderParent()}
            <br/>
            <EmptyResult
                component={
                    <NotFoundScene width={"90%"} icon={Resources.orders_logo}></NotFoundScene>
                }
                context="bids"
                separator={false}
                showTitle={false} 
                description={StringHelper.translate(this, "CallForBids.datalist_sorry_default_description")}>
            </EmptyResult>
        </>
    }

    renderProposals(bids)
    {
        const parsedQueryString = queryString.parse(window.location.search);
        
        return <>
        {
            (bids.state.code == "BIDS_STATE_WAITING_TRANSPORTER") ?
            (
                <>
                <Row>
                    <Col xs="12">
                        {
                            (bids.results.length > 0) ?
                            (
                                bids.results.map((result, index) => {
                                    return <TruckResult 
                                        value={result} 
                                        hideRibbon={true}
                                        disableOrder={result.state.code == "BIDS_STATE_REFUSED"}
                                        customRibbon={result.state.code == "BIDS_STATE_REFUSED" ? {color:"refused", text:"Refusé"} : null}
                                        callForBids={true}
                                        autoStartWorkflow={parsedQueryString.result_id && parsedQueryString.result_id == result.id}
                                    />
                                })
                            ) : (
                                <EmptyResult
                                    component={
                                        <NotFoundScene width={"90%"} icon={Resources.orders_logo}></NotFoundScene>
                                    }
                                    context="bids"
                                    separator={false}
                                    showTitle={false} 
                                    description={<>
                                        {StringHelper.translate(this, "CallForBids.results_datalist_sorry_default_description_before_countdown") + " "}
                                        <CountDown value={bids.transporter_expiration_date_as_seconds}
                                            renderAsHms={true}
                                            pattern={"{h}h{m}m{s}s"}
                                            onDone={() => {
                                                this.props.parent.loadCallForBids(null, true);
                                            }}>
                                        </CountDown>
                                        {" " + StringHelper.translate(this, "CallForBids.results_datalist_sorry_default_description_after_countdown")}
                                        </>
                                    }>
                                </EmptyResult>
                            )
                        }
                    </Col>
                </Row>
                {
                    (bids.cancel_allowed) ?
                    (
                        <Row>
                            <Col md="12 text-center">
                                <DefaultButton className="t2y-removed-button-alt small "  onClick={(e) => this.renderCancelBidsConfirmationModal(bids) }>
                                    {StringHelper.translate(this, "CallForBids.shipper_cancel_button_title")}
                                </DefaultButton>
                            </Col>
                        </Row>
                    ) : (null)
                }
                </>
            ) : (null)
        }
        </>
    }

    renderTrucks(bids)
    {
        return <>
            <Row>
                <Col md="12">
                    <Table classnames="table" responsive>
                        <thead>
                            <tr>
                                <th>{StringHelper.translate(this, "CallForBids.results.header_truck")}</th>
                                <th>{StringHelper.translate(this, "CallForBids.results.header_amounts")}</th>
                                <th>{StringHelper.translate(this, "CallForBids.results.header_added_amounts")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
            {
                bids.results.map((result, index) => {
                    return <tr>
                        <td width="15%"><TruckSummary completeSummaryOnClick={true} properties={false} reference={true} label={false} image={true} truck={result.truck}></TruckSummary></td>
                        <td>
                            {StringHelper.translate(this, "CallForBids.results.header_amounts_package")} {StringHelper.formatAmount(bids.half_day ? result.truck.cost_half_day : result.truck.cost_day)}
                            <br/>
                            <strong>{StringHelper.translate(this, "CallForBids.results.header_amounts_days", null, {days: bids.half_day ? "1/2" : bids.days.length})} {StringHelper.formatAmount(bids.half_day ? result.truck.cost_half_day : result.truck.cost_day * bids.days.length)}</strong>
                        </td>
                        <td>
                            {StringHelper.formatAmount(result.truck.cost_km)} / km
                            <br/>
                            {StringHelper.formatAmount(result.truck.cost_hour)} / h
                        </td>
                        <td>
                            {
                                (result.state.code == "BIDS_STATE_REFUSED") ?
                                (
                                    <>
                                        
                                        <strong className="t2y-warning-color">
                                            <DefaultImage src={Resources.toast_warning_logo} className="t2y-warning-color" />&nbsp;
                                            Refusé
                                        </strong>
                                        <br/>
                                        {DateHelper.datesAsStringPeriod(result.dates)}
                                    </>
                                ) : 
                                (
                                    (result.state.code == "BIDS_STATE_WAITING_SHIPPER") ?
                                    (
                                        <>
                                        <strong>Dans l'attente de la réponse de l'expéditeur</strong><br/>
                                        {DateHelper.datesAsStringPeriod(result.dates)}
                                        </>
                                    ) : 
                                    (
                                        (bids.allowed) ?
                                        (
                                            <DefaultButton className="t2y-secondary-button small" onClick={(e) => {this.callForBidsWorkflowRef.startTransporterProposal(result)}} type="button">
                                                {StringHelper.translate(this, "CallForBids.results.propose_this_truck")}
                                            </DefaultButton>
                                        ) : (null)
                                    )
                                    
                                )
                            }
                        </td>
                    </tr>
                })
            }
                    </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    }

    renderTabContentBids(bids, index)
    {
        const headerStyle = {textAlign:"center", paddingTop:"10px", cursor:"pointer"}
        const collapseCode = "collapse" + bids.id;
        const format = this.props.intl.formatMessage({id: "Default.day_month_year_format"})

        return <Card key={"collapse"+ index} className={"card-plain " + collapseCode } style={{marginBottom:0,paddingBottom:0, border:"solid 1px black"}}>
            <CardHeader role="tab" style={{marginTop:0,marginBottom:0, paddingTop:0, paddingBottom:0}}  aria-expanded={this.expandedIsOpen(collapseCode, bids.id)}>
                <Row>
                    <Col xs="4" style={headerStyle}>
                        <h5 className="mb-0 t2y-clickable">
                            <span onClick={() => this.collapsesToggle(collapseCode, bids.id)}>
                                {
                                    (bids.criterias && bids.criterias.length > 0) ?
                                    (
                                        bids.criterias.map((criteria, index) => {
                                            return (index > 0 ? " - " : "") + criteria.label
                                        })
                                    ) : (null)
                                }
                            </span>
                        </h5>
                    </Col>
                    <Col xs="4" style={headerStyle} onClick={() => this.collapsesToggle(collapseCode, bids.id)}>
                        {DateHelper.datesAsStringPeriod(bids.days)}
                        {/*this.renderOrderDates(moment(order.start_date.date).format(format), moment(order.end_date.date).format(format), order.nb_days_used)*/}
                    </Col>
                    <Col xs="4" style={headerStyle} onClick={() => this.collapsesToggle(collapseCode, bids.id)}>
                        {this.securityService.isTransporter() ? this.renderTransporterCallForBidsState(bids) : this.renderShipperCallForBidsState(bids)}
                    </Col>
                </Row>
            </CardHeader>
            <Collapse role="tabpanel" isOpen={this.expandedIsOpen(collapseCode, bids.id)}>
            <CardBody>
                {this.securityService.isTransporter() ? this.renderTrucks(bids) : this.renderProposals(bids)}
            </CardBody>
            </Collapse>
        </Card>
    }

    renderTabContentHeader()
    {
        const headerStyle = {textAlign:"center", paddingTop:"10px"}
        return <Card key={"collapse-orders"} className={"card-plain " } style={{marginBottom:0,paddingBottom:0, border:"solid 1px black"}}>
            <CardHeader role="tab" style={{marginBottom:0,paddingBottom:0}}>
            <Row>
                <Col xs="4" style={headerStyle}>
                    {StringHelper.translate(this, "CallForBids.header_expected_truck")}
                </Col>
                <Col xs="4" style={headerStyle}>
                    {StringHelper.translate(this, "CallForBids.header_expected_dates")}
                </Col>
                <Col xs="4" style={headerStyle}>
                    {StringHelper.translate(this, "CallForBids.header_state")}
                </Col>
            </Row>
            </CardHeader>
        </Card>
    }

    renderContent()
    {
        return <>
            {this.renderTabContentHeader()}
            {
                this.state.values.map((bids, index) => {
                    return this.renderTabContentBids(bids, index)
                })
            }

        </>
    }

    

    render() {
        if (this.state.values.length == 0)
        {
            return this.renderEmptyContent()
        }
        else{
            return (
                <>
                    {this.renderParent()}
                    <CallForBidsWorkflow childRef={(elt) => this.callForBidsWorkflowRef = elt} id="CALL_FOR_BIDS_WORKFLOW" parent={this} />
                    <Container>
                    {this.renderContent()}
                    </Container>
                </>
            )
        }
    }
}


export default injectIntl(CallsForBids)