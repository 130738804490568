/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import queryString from 'query-string';
import $ from "jquery";
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";

// reactstrap components
import {
    Modal,
    Alert,
    Table,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardText,
  CardBody,
  CardImg,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import BusinessService from "../../../proxies/BusinessService.js";
import ShipperService from "../../../proxies/ShipperService.js";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import NotificationComponent from "../components/NotificationComponent.jsx";
import FormGroupInput from '../components/form/FormGroupInput.jsx';
import FormInput from '../components/FormInput.jsx';
import ReactDatetime from "react-datetime";

import Config from "../../../config.js"
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import InjectedCreditCardCheckoutForm from "../components/payment/CreditCardOldForm";
import InjectedIbanCheckoutForm from "../components/payment/IbanForm";

import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultImage from '../../components/common/defaultImage'
import PaymentComponent from "../../components/payment/PaymentComponent.jsx";
import Resources from "../../../resources.js";
import StringHelper from "../../../helpers/StringHelper.js";
import DefaultInput from "../../components/common/defaultInput.jsx";
import DefaultButton from "../../components/common/defaultButton.jsx";
import DefaultLabel from "../../components/common/defaultLabel.jsx";
import Title2 from "../../components/common/title2.jsx";
import Title3 from "../../components/common/title3.jsx";
import Message from '../../components/common/message'

var moment = require('moment');
require('moment/locale/fr');

const stripePromise = loadStripe(Config.stripePublicKey);

class ManualInvoicePayment extends NotificationComponent {
  
    constructor(props) {
        super(props);

        this.businessService = new BusinessService();
        this.shipperService = new ShipperService();

        this.state = {
            order_id: null,
            success: false,
            error: false,
            show_payment_modal: false,
            payment_modal_title: "Paiement de l'acompte",
            loading : false,
            buttons: []
        }
    }
 
    componentDidMount() {
        // Récupération des données de la facture avec les données pour l'expéditeur
        const parsedQueryString = queryString.parse(window.location.search);
        this.refresh(parsedQueryString.orderId)
        
        /*this.businessService.getShipperInvoiceBillingInfos(this, parsedQueryString.orderId, parsedQueryString.shipperId, (response) => {
            this.setState({
                invoice: response.invoice,
                order_id: parsedQueryString.orderId,
                shipper_id: parsedQueryString.shipperId,
            })
        });*/
    }

    refresh(orderId)
    {
        this.shipperService.getShipperOrderManualPaymentInfos(this, orderId, (response) => {
            const order = response.instance;
            var buttons = []
            order.invoices.forEach((invoice, index) => {
                buttons[index] = {loading: false}
            })

            this.setState({
                order: order,
                invoices: order.invoices,
                buttons: buttons
            })
        });

    }

    startPayment()
    {
        this.setState({
            showPaiementConfirmationModal: false,
            //showTruckDetailModal: false,
            loading: true,
            show_payment_modal: true,
            payment_modal_title: "Paiement de l'acompte"
        });
    }

    savePayment(paymentInfos)
    {
        this.setState({error: false, success: true})

        /*console.log({
            invoice_id: this.state.invoice.id,
            amount_ttc: paymentInfos.amountPaiedTtc,
            payment_external_id: paymentInfos.paymentExternalId,
            payment_method_external_id: paymentInfos.paymentMethodExternalId
        })
        */
        var params = {
            invoice_id: this.state.order.invoice.id,
            payment_method_external_id: paymentInfos.payment_methodExternal_id,
            payment_intent_external_id: paymentInfos.payment_external_id,
            amount_to_pay_ttc: paymentInfos.amount_paied_ttc,
            amount_to_pay_ht: paymentInfos.amount_paied_ht
        }
        
        this.shipperService.payInvoiceAfterIncident(this, params, (response) => {
            if (response.payment_succeeds)
            {
                this.showSuccessModalNotification("Confirmation", "Le paiement a été effectué avec succès.");
                this.setState({confirmation: true})
                this.refresh(this.state.order.id)
            }  
            else // Une erreur est survenue
            {
                this.showErrorModalNotification("", "Impossible d'effectuer le paiement.")
            }
        })        

        /*
        this.shipperService.createRetainerInvoice(this, orderId, {
                    id: orderId,
                    retainer_amount_ttc: paymentInfos.amountPaiedTtc,
                    retainer_amount_ht: paymentInfos.amountPaiedHt,
                    payment_external_id: paymentInfos.paymentExternalId,
                    payment_method_external_id: paymentInfos.paymentMethodExternalId
                }, (response) => {
                    this.setState({orderCreationMessage: "Redirection vers la commande"});
                    setTimeout(() => {
                        // Confirmation de la commande
                        const url = "/admin/orders?orderId=" + orderId + "&date=" + moment(this.state.truckDetail.start_date.date).format("YYYY-MM-DD");
                        window.location = url;
                    }, 2000) 
                });
        */

    }

    paymentError(error)
    {
        this.setState({error: true, success: false})
        this.errorNotification("Une erreur est survenue", error, "Fermer");
    }

    downloadInvoice(buttonIndex, invoiceId)
    {
        var buttons = this.state.buttons;
        buttons[buttonIndex].loading = true;
        this.setState({buttons: buttons})

        this.businessService.downloadInvoice(this, invoiceId, response => {

            const linkSource = `data:application/pdf;base64,${response.instance.base64_content}`;
            const downloadLink = document.createElement("a");
            const fileName = response.instance.filename;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

            var buttons = this.state.buttons;
            buttons[buttonIndex].loading = false;
            this.setState({buttons: buttons})
        });
    }

    renderInvoiceDetail()
    {
        return <>
        <Row>
            <Col md="12">
                <Card className="bg-gradient-default">

                    <CardBody>
                    <Row>
                        <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0 text-white">
                            Montant à payer
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-white">
                            {this.state.invoice.amount_to_pay}
                        </span>
                        </div>
                        <Col className="col-auto">
                        <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                            <i className="fas fa-file-invoice-dollar"></i>
                        </div>
                        </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                        <Button color="link" outline onClick={e => {}}>
                            <span><i className="far fa-file-pdf" />&nbsp;Voir la facture</span>
                        </Button>
                    </p>
                    </CardBody>
                </Card>
                
            </Col>
        </Row>
        </>
    }

    renderOrderBillingInfos()
    {
        return <>
            <Table className="align-items-center" responsive>
                <thead className="thead-light">
                    <tr>
                    <th scope="col">Facture</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Payé</th>
                    <th scope="col">Action</th>
                    <th scope="col" />
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.invoices.map((invoice, index) => {
                            return <tr>
                                <td><DefaultLabel>{StringHelper.translate(this, "Default." + invoice.type.code)} ({invoice.number})</DefaultLabel></td>
                                <td><DefaultLabel>{invoice.total}€</DefaultLabel></td>
                                <td><DefaultLabel>{invoice.amount_paied}€ </DefaultLabel>
                                    {invoice.amount_paied == 0 ? <DefaultImage src={Resources.toast_warning_logo} style={{color: "red"}} size="20" /> : <></>}
                                </td>
                                <td>
                                    <DefaultButton loading={this.state.buttons[index].loading} className="t2y-secondary-button small" outline onClick={e => this.downloadInvoice(index, invoice.id)}>
                                        <span><i className="far fa-file-pdf" />&nbsp;Voir la facture</span>
                                    </DefaultButton>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        </>
    }

    old_renderPayForms()
    {
        return <>
            <Row  className="justify-content-center">
                <Col xl="6">
                    <Card>
                        <CardHeader className="border-0">
                            <h3 className="mb-0">Mode de paiement carte</h3>
                        </CardHeader>
                        <CardBody>
                            <Row className="justify-content-center">
                                <Col xs="12" xl="6">
                                    <Elements stripe={stripePromise}>
                                        <InjectedCreditCardCheckoutForm 
                                            ttcAmount={this.state.invoice.amount_to_pay} 
                                            onStart={() => this.startPayment()} 
                                            onSuccess={(paymentInfos) => this.savePayment(paymentInfos)} 
                                            onError={(error) => this.paymentError(error)} 
                                        />
                                    </Elements>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl="6">
                    <Card>
                        <CardHeader className="border-0">
                            <h3 className="mb-0">Mode de paiement IBAN</h3>
                        </CardHeader>
                        <CardBody>
                            <Row className="justify-content-center">
                                <Col xs="12" xl="6">
                                    <Elements stripe={stripePromise}>
                                        <InjectedIbanCheckoutForm 
                                            ttcAmount={this.state.invoice.amount_to_pay} 
                                            onStart={() => this.startPayment()} 
                                            onSuccess={(paymentInfos) => this.savePayment(paymentInfos)} 
                                            onError={(error) => this.paymentError(error)} 
                                        />
                                    </Elements>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    }

    renderPaymentForm()
    {
        return <>
            <PaymentComponent 
                ttc={this.state.order.amount_to_pay} 
                orderId={this.state.order.id}
                rescue={true} 
                lastPayment={this.state.invoices.length > 1 || this.state.invoices[0].type.code == "INVOICE_TYPE_SHIPPER_INVOICE"}
                onPaymentSuccess={(paymentInfos) => this.savePayment(paymentInfos)} 
                onPaymentError={(error) => this.paymentError(error)} 
            />
        </>
    }

    render() {
        
        return (
        <>
            {this.renderParent()}          
            <Container className="mt-4" fluid>
                <Row className="justify-content-center">
                <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
                    <Col md="8">
                        <Card className="bg-secondary border-0">
                            <CardBody>
                                {
                                    (this.state.confirmation) ?
                                    (
                                        <>
                                            {this.renderOrderBillingInfos()}
                                        </>
                                    ) : 
                                    (
                                        (this.state.order) ?
                                        (
                                            ( parseFloat(this.state.order.amount_to_pay) == 0) ?
                                            (
                                                <>
                                                <Message type="warning">
                                                <strong>Attention!</strong> Le paiement de cette commande est à jour.
                                                </Message>
                                                    
                                                {this.renderOrderBillingInfos()}
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <Row>
                                                        <Col xs="12">
                                                            <Title3>Incident de paiement sur la commande n° {this.state.order ? this.state.order.number : ""} </Title3>
                                                        </Col>
                                                        <Col xs="12">
                                                            <DefaultLabel>Ci-dessous le récapitulatif des factures générée pour cette commande</DefaultLabel>
                                                        </Col>
                                                    </Row>
                                                    {this.renderOrderBillingInfos()}
                                                    {this.renderPaymentForm()}
                                                </>
                                            )
                                        )
                                        :
                                        (
                                            <Title3 center={true}>Récupération des données de la commande en cours ...</Title3>
                                        )
                                    )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
        );
    }
}

export default injectIntl(ManualInvoicePayment);
