
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Row,
    Col,
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    NavLink,
    Modal,
    Button
  } from "reactstrap";
import { Calendar } from 'react-date-range';

import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator"
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import FormGroupInput from "../../pages/components/form/FormGroupInput";
import FormHourInput from "../../pages/components/form/FormHourInput";
import ReactDatetime from "react-datetime";
import RepositoryService from "../../../proxies/RepositoryService";

import { injectIntl , FormattedMessage } from 'react-intl';
import FormRepository from "../../pages/components/form/FormRepository";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

var moment = require('moment');
require('moment/locale/fr');

class Paragraph extends React.Component {

    render() {
        var htmlContent = this.props.content;
        var customClassName = "t2y-paragraph " + (this.props.className);

        if (this.props.small)
        {
            customClassName += " t2y-paragraph-small"
        }

        if (this.props.center)
        {
            customClassName += " text-center"
        }

        if (this.props.children)
        {
            return <div className={customClassName} >{this.props.children}</div>
        }
        else
        {
            return <div><p className={customClassName} dangerouslySetInnerHTML={{__html: htmlContent}}></p></div>
        }

        
    }
}

export default injectIntl(Paragraph)
