
import Config from "../config.js"
import SecurityService from "../proxies/SecurityService.js";
import $ from "jquery"

export default class WebsiteHelper
{
    static cleanUrl(url)
    {
        return url.replace(/#/g, '');
    }

    static onlyVisibleOnLarge()
    {
        return " d-none d-xl-inline-block "
    }

    static onlyVisibleOnMiddle()
    {
        return " d-block d-xl-none "
    }

    
    static onlyVisibleOnSmall()
    {
        return " d-block d-sm-none "
    }

    static onlyHiddenOnSmall()
    {
        return " d-none d-sm-block "
    }

    static onlyHiddenOnMiddleAndSmall()
    {
        return " d-none d-lg-block "
    }

    static getCurrentWidth()
    {
        return window.innerWidth
    }

    static isPortraitMobileWidth()
    {
        return WebsiteHelper.getCurrentWidth() <= 500;
    }

    static isSmallWidth()
    {
        return WebsiteHelper.getCurrentWidth() <= 768;
    }

    static isMiddleWidth()
    {
        return WebsiteHelper.getCurrentWidth() <= 1200;
    }

    static isLargeWidth()
    {
        return WebsiteHelper.getCurrentWidth() > 1200;
    }

    static getSlidingPanelWidth()
    {
        if (WebsiteHelper.isSmallWidth())
        {
            return "90%";
        }
        else if (WebsiteHelper.isMiddleWidth())
        {
            return "75%";
        }
        else {
            return "60%"
        }
    }

    static getSearchUrl() {
        return "/admin/search-result"
    }

    static getHomeUrl(context)
    {
        // Récupération du context
        const securityService = new SecurityService();
        return "/admin/orders";
        
        /*if (user.is_company_account_manager)
        {
            return "/admin/orders";
        }
        else
        {
            return "/admin/profile";
        }*/
    }

    static goToOrder(context, orderId, newTab = false)
    {
        const relativeUrl = "/admin/orders?orderId=" + orderId
        if (newTab)
        {
            const url = Config.bndFe + relativeUrl
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else
        {
            this.goTo(context, relativeUrl)
        }
    }

    static goToHome(context, newTab = false)
    {
        WebsiteHelper.goTo(context, this.getHomeUrl(context), newTab)
    }

    static goTo(context, url, newTab = false)
    {
        var isFullUrl = false;
        var fullUrlPattern = /^((http|https|ftp):\/\/)/;
        if(fullUrlPattern.test(url)) {
            isFullUrl = true;
        }

        if (newTab)
        {
            const newWindow = window.open((isFullUrl ? url : (Config.bndFe + url)), '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else
        {
            // Si c'est une url relative et que le context permet d'utiliser la navigation avec l'historique
            if (isFullUrl == false && context != null && context.props != null && context.props.history != null)
            {
                context.props.history.push(url)
            }
            else
            {
                window.location.href = isFullUrl ? url : (Config.bndFe + url);
            }
        }
    }

    static tryEscapeFromIframe(url)
    {
        if (window.parent) { // checking if it is an iframe
            window.parent.location.href = url;
        } else {
            window.location.href = url;
        }
    }
    
    static getResourceUrl(resourceName)
    {
        var s = require(eval(`../${resourceName}`))
        return s.default
        
    }

    static tryRequire = (path) => {
        path = "../" + path;
        try {
            return require(`${path}`);
        } catch (err) {
            return null;
        }
    };

    static getScroll()
    {
        if (window.pageYOffset != undefined) {
            return {left: window.pageXOffset, top: window.pageYOffset};
        } else {
            var sx, sy, d = document,
                r = d.documentElement,
                b = d.body;
            sx = r.scrollLeft || b.scrollLeft || 0;
            sy = r.scrollTop || b.scrollTop || 0;
            return {left: sx, top: sy};
        }
    }

    static getElementPosition(element) {
        var clientRect = element.getBoundingClientRect();
        var scroll = WebsiteHelper.getScroll();

        var height = clientRect.bottom - clientRect.top
        return {
            left: clientRect.left - scroll.left,
            right: clientRect.right - scroll.left,
            top: clientRect.top - scroll.top,
            bottom: clientRect.bottom - scroll.top,
            defaultTop: clientRect.top,
            defaultBottom: clientRect.bottom,
            defaultLeft: clientRect.left,
            defaultRight: clientRect.right,
            width: clientRect.right - clientRect.left,
            height: clientRect.bottom - clientRect.top
        }
    }
}