import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
    Container,
    Row,
    Col,
    Button,
    Table,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardBody,
    CardTitle,
    ButtonGroup,
    Label
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import ShipperService from "../../../proxies/ShipperService";
import DefaultImage from "../common/defaultImage";
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import Resources from "../../../resources";
import StringHelper from "../../../helpers/StringHelper";
import ArrayHelper from "../../../helpers/ArrayHelper";
import EmptyResult from "../common/emptyResult";
import DeleteButton from "../common/deleteButton";
import LoadingContainer from "../common/loadingContainer";
import DefaultButton from "../common/defaultButton";
import RepositoryDataComponent from "../../pages/components/RepositoryDataComponent";
import Gallery from "react-grid-gallery";
import BusinessService from "../../../proxies/BusinessService";

import Title3 from "../common/title3"
import Title2 from "../common/title2"
import InjectedSepaSourceComponent from "./SepaSourceComponent"
import InjectedCreditCardComponent from "./CreditCardComponent"
import InjectedSepaComponent from "./SepaComponent"
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Config from "../../../config";
import DefaultCheckbox from "../common/defaultCheckbox";
import MutedText from "../common/mutedText";
import Codes from "../../../codes";
const stripePromise = loadStripe(Config.stripePublicKey);

var moment = require('moment');
require('moment/locale/fr');

class AddPaymentMethodComponent extends FormValidationComponent {

    constructor(props) {
        super(props)
        
        this.state = {
            loading: false,
            mode: Codes.CARD
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }
    
    renderSepaMode()
    {
        return <>
            <Container>
                <Row> 
                    <Col lg="12" md="12" className="order-lg-1 order-2">
                        <Elements stripe={stripePromise}>
                            <InjectedSepaComponent
                                forceNewAndReusable={true}
                                onlyPaymentMethod={true}
                                onStart={() => {}} 
                                onSuccess={(paymentInfos) => this.props.onPaymentSuccess(paymentInfos)} 
                                onError={(error) => this.props.onPaymentError(error)} 
                                paymentButtonTitle={this.props.paymentButtonTitle ? this.props.paymentButtonTitle : "Payer"}
                            />
                        </Elements>
                    </Col>
                </Row>
            </Container>

        </>
    }

    renderCardMode()
    {
        return <>
            <Container>
                <Row> 
                    <Col lg="12" md="12" className="order-lg-1 order-2">
                        <Elements stripe={stripePromise}>
                            <InjectedCreditCardComponent
                                forceNewAndReusable={true}
                                onlyPaymentMethod={true}
                                onStart={() => {}} 
                                onSuccess={(paymentInfos) => this.props.onPaymentSuccess(paymentInfos)} 
                                onError={(error) => this.props.onPaymentError(error)} 
                                paymentButtonTitle={this.props.paymentButtonTitle ? this.props.paymentButtonTitle : "Ajouter ce moyen de paiement"}
                                cardPaymentMethodMinExpirationDate={this.props.cardPaymentMethodMinExpirationDate}
                            />
                        </Elements>
                    </Col>
                </Row>
            </Container>
        </>
    }

    renderSepaSourceMode()
    {
        return <>
            <Container>
                <Row> 
                    <Col lg="12" md="12" className="order-lg-1 order-2">
                        <Elements stripe={stripePromise}>
                            <InjectedSepaSourceComponent
                                forceNewAndReusable={true}
                                onlyPaymentMethod={true}
                                onStart={() => {}} 
                                onSuccess={(paymentInfos) => this.props.onPaymentSuccess(paymentInfos)} 
                                onError={(error) => this.props.onPaymentError(error)} 
                                paymentButtonTitle={this.props.paymentButtonTitle ? this.props.paymentButtonTitle : "Ajouter ce moyen de paiement"}
                            />
                        </Elements>
                    </Col>
                </Row>
            </Container>
        </>
    }

    render()
    {
        return <>
            <hr className="t2y-hr-light" />
            <Row className="justify-content-center">
                <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                    <Button className={classnames({ active: this.state.mode === Codes.CARD }) + " tabBtn"} color="primary" 
                        onClick={(e) => {this.setState({mode: Codes.CARD})}}>
                        Carte bancaire
                    </Button>
                    <Button className={classnames({ active: this.state.mode === Codes.IBAN }) + " tabBtn"} color="secondary"
                        onClick={(e) => {this.setState({mode: Codes.IBAN})}}>
                        Prélèvement SEPA
                    </Button>
                </ButtonGroup>
            </Row>
            <hr/>
            {
                (this.state.mode == Codes.IBAN) ?
                (
                    this.renderSepaSourceMode()
                ) :
                (
                    this.renderCardMode()
                )
            }

        </>
    }

}

AddPaymentMethodComponent.propTypes = {
    onPaymentSuccess: PropTypes.func,
    onPaymentError: PropTypes.func,
    paymentDescription: PropTypes.string,
    paymentButtonTitle: PropTypes.string,
    cardPaymentMethodMinExpirationDate: PropTypes.any
};

export default injectIntl(AddPaymentMethodComponent)

