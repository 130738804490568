import React, { useEffect, useState } from "react"

const Report = ({ missionId }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const iframe = document.getElementById("iframeV2")
    if (iframe) {
      iframe.addEventListener("load", onLoad)
    }
    return () => {
      if (iframe) {
        iframe.removeEventListener("load", onLoad)
      }
    }
  }, [])

  function onLoad() {
    setLoaded(true)
  }

  useEffect(() => {
    if (loaded) {
      const iframeWindow = window.frames.iframeV2;
      iframeWindow.postMessage({
          token: localStorage.getItem("token"),
      }, process.env.REACT_APP_FRONT_V2_DOMAIN)
    }
  }, [loaded]);

  return (
    <div className="iframe-wrapper"
			style={{ height: "95vh"}}
		>
      {!loaded &&
        <div className="loader">
          Chargement...
        </div>
      }
      <iframe
        id='iframeV2'
        style={{ width: "100%", height: "100%", border: "none" }}
        className="iframe-V2"
        src={`${process.env.REACT_APP_FRONT_V2_DOMAIN}/mission/${missionId}/report?jwt=${encodeURIComponent(localStorage.getItem("token"))}`}
        name="iframeV2"
      />
    </div>

  )
}

export default Report