import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ToastNotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
// reactstrap components
import {
    Row,
    Col,
    Button,
    Modal
  } from "reactstrap";
import Title2 from "../../components/common/title2";
import DefaultButton from "../../components/common/defaultButton";
import Resources from "../../../resources";
import DateHelper from "../../../helpers/DateHelper";
import WebsiteHelper from '../../../helpers/WebsiteHelper';

class NotificationComponent extends React.Component {

    constructor(props)
    {
        super(props)
        this.intl  = this.props.intl;
    }

    translateMessage(id, values = {})
    {
        if (!id || id == "")
            return "!Traduction inconnue";
        
        var message = this.intl.formatMessage({"id" : id, "defaultMessage" : "!" + id}, values);
        if (message)
        {
            message = message.replace("&lt;", "<").replace("&gt;", ">");
        }
        return message
    }

    componentWillMount()
    {
    }
    
    componentDidMount() 
    { 
    }

    refreshParent()
    {
        this.setState({"update" : DateHelper.now()})
    }

    hideNotificationComponentAlert = () => {
        this.setState({
            notification_component_alert: null
        });
    };

    successNotification(title, component, okButtonTitle){
        okButtonTitle = !okButtonTitle ? this.intl.formatMessage({id: "Default.close"}) : okButtonTitle;

        if (!this.state.notification_component_alert) {
            this.setState({
                notification_component_alert: (<ReactBSAlert
                    success
                    confirmBtnText={okButtonTitle}
                    confirmBtnBsStyle="success success-btn-success"
                    title={title}
                    onConfirm={this.hideNotificationComponentAlert.bind(this)}
                    onCancel={this.hideNotificationComponentAlert.bind(this)}
                    focusCancelBtn
                >
                    {component}
                </ReactBSAlert>)
            })
        }
    }

    errorNotification(title, component, okButtonTitle){
        this.setState({
            notification_component_alert: (<ReactBSAlert
                danger
                customClass={"error-notification"}
                confirmBtnText={okButtonTitle}
                confirmBtnBsStyle="danger"
                title={title}
                onConfirm={this.hideNotificationComponentAlert.bind(this)}
                onCancel={this.hideNotificationComponentAlert.bind(this)}
                focusCancelBtn
            >
                {component}
            </ReactBSAlert>)
        })
    }

    warningNotification(title, warnings, okButtonTitle)
    {
        this.setState({
            notification_component_alert: (<ReactBSAlert
                warning
                confirmBtnText={okButtonTitle}
                confirmBtnBsStyle="warning"
                title={title}
                onConfirm={this.hideNotificationComponentAlert.bind(this)}
                onCancel={this.hideNotificationComponentAlert.bind(this)}
                focusCancelBtn
            >
                <>
                    {
                        (Array.isArray(warnings)) ?
                        (
                            <>
                            {this.translateMessage("WARNING_MESSAGE")}
                            {
                                warnings.map(w => {
                                return <div>{this.translateMessage(w)}</div>
                                })
                            }
                            </>
                        ) : 
                        (
                            warnings
                        )
                    }
                </>
                
            </ReactBSAlert>)
        })
    }

    confirmNotification(title, component, okButtonTitle, okCallback, cancelButtonTitle, cancelCallback = null){
        this.setState({
            notification_component_alert: (<ReactBSAlert
                warning
                showCancel
                confirmBtnBsStyle="success confirm-btn-success"
                confirmBtnText={okButtonTitle}
                cancelBtnBsStyle="secondary confirm-btn-cancel"
                cancelBtnText={cancelButtonTitle}
                title={title}
                onConfirm={() => {
                    this.hideNotificationComponentAlert()
                    okCallback()
                }}
                onCancel={() => {
                    this.hideNotificationComponentAlert()
                    if (cancelCallback != null){
                        cancelCallback()
                    } 
                }}
                focusCancelBtn
            >
                {component}
            </ReactBSAlert>)
        })
    }

    /*
    ====================================================================================
    ===================================================================================== 

                                    POPUP DE CONFIRMATION

    =====================================================================================
    ===================================================================================== 
    */
    /**
     * 
     * @param {*} options title, component, okButtonTitle, cancelButtonTitle,  okButtonCallback, cancelButtonCallback
     */
    showModalConfirmation(options)
    {
        if (!options || options == null)
        {
            options = {}
        }

        const modalOrder = options.order ? options.order : "";

        const large = options.large ? true : false;
        const bodyClassName = options.bodyClassName ? options.bodyClassName : "text-center text-muted lead"
        const showIcon = options.hideIcon ? false : true
        const showTitle = options.hideTitle ? false : true

        const okButtonIcon = options.okButtonIcon ? options.okButtonIcon : null
        const title = !options.title ? "" : options.title
        const component = !options.component ? "" : options.component
        const headerComponent = !options.headerComponent ? "" : options.headerComponent
        const okButtonClassName = !options.okButtonClassName ? "t2y-secondary-button" : options.okButtonClassName;
        const okButtonStyle = !options.okButtonStyle ? {}: options.okButtonStyle;
        const cancelButtonClassName = !options.cancelButtonClassName ?  "t2y-thirdparty-alt-button" : options.cancelButtonClassName;
        const cancelButtonStyle = !options.cancelButtonStyle ? {} : options.cancelButtonStyle;
        const onEscapeClose = !options.onEscapeClose ? () => this.closeModal(modalOrder) : () => options.onEscapeClose()
        
        const okButtonTitle = !options.okButtonTitle ? this.translateMessage("Default.accept")  : options.okButtonTitle
        const cancelButtonTitle = !options.cancelButtonTitle ? this.translateMessage("Default.cancel") : options.cancelButtonTitle
        const okButtonCallback = !options.okButtonCallback ? () => this.closeModal(modalOrder) : () => {
            this.closeModal(modalOrder);
            options.okButtonCallback()
        }
        const cancelButtonCallback = !options.cancelButtonCallback ? () => this.closeModal(modalOrder) : () => {
            this.closeModal(modalOrder);
            options.cancelButtonCallback()
        }

        var iconStyle = !options.iconStyle ? {fontSize:"100px",} : options.iconStyle;
        var iconColor = {}
        if (options.iconColor)
        {
            iconColor = {color: options.iconColor}
        }
        
        var iconComponent = !options.iconComponent ? <>
            <div className="text-center text-success"  style={iconStyle}>
                <i className="fas fa-exclamation" style={iconColor}></i>
            </div>
        </> : options.iconComponent

        var modalContentComponent = <>
            <div className="modal-header">
                {headerComponent}
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onEscapeClose}>
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                {
                    (showIcon) ?
                    (
                        iconComponent
                    ) : (<></>)
                } 
                {
                    (showTitle) ?
                    (
                        <Title2 center={true}>
                            {title}
                        </Title2>
                    ) : (<></>)
                }
                
                <div className={"py-3 " + bodyClassName}>
                    {component}
                </div>
            </div>
            <div className="modal-footer">
                <DefaultButton className={cancelButtonClassName} style={cancelButtonStyle}  onClick={() => cancelButtonCallback()}>
                    {cancelButtonTitle}
                </DefaultButton>
                <DefaultButton icon={okButtonIcon} className={okButtonClassName} style={okButtonStyle} onClick={() => okButtonCallback()}>
                    {okButtonTitle}
                </DefaultButton>
            </div>
        </>

        var confirmationModal = <>
            <Modal key={options.key} className={"modal-dialog-centered " + (large ? "modal-lg" : "")} isOpen={true} toggle={onEscapeClose}>
                {
                    (options.leftComponent) ?
                    (
                        <Row>
                            <Col lg="4" className={WebsiteHelper.onlyHiddenOnMiddleAndSmall()}>{options.leftComponent}</Col>
                            <Col lg="8" xs="12">{modalContentComponent}</Col>
                        </Row>
                    ) : 
                    (modalContentComponent)
                }
                
            </Modal>
        </>

        var newState = {}
        newState["notification_component_alert" + modalOrder] = confirmationModal
        this.setState(newState)
        /*this.setState({
            notification_component_alert: confirmationModal
        });*/
    }

    /*
    ====================================================================================
    ===================================================================================== 

                                    TOAST NOTIFICATION

    =====================================================================================
    ===================================================================================== 
    */
    /**
     * Méthode générique de création de notification de type "toast"
     * @param {*} type 
     * @param {*} title 
     * @param {*} component 
     */
    showToastNotification(type, title, component, options = {})
    {
        this.refs.toastNotificationAlert.notificationAlert({
            place: "tc",
            message: (
            <div className="alert-text">
                <span className="alert-title" data-notify="title">
                {" "}
                {title}
                </span>
                <span data-notify="message">
                {component}
                </span>
            </div>
            ),
            type: type,
            icon: type == "warning" ? Resources.toast_warning_logo :  "far fa-thumbs-up",
            autoDismiss: 7
        });
    }

    /**
     * Méthode pour créer directement une notification "Toast" de type "Success" 
     * @param {*} title 
     * @param {*} component 
     */
    showSuccessToastNotification(title, component = null)
    {
        this.showToastNotification("success", title, component)
    }

    /**
     * Méthode pour créer directement une notification "Toast" de type "warning"
     * @param {} tile 
     * @param {*} component 
     */
    showWarningToastNotification(title, component = null)
    {
        this.showToastNotification("warning", title, component)
    }

    /* 
    ====================================================================================
    ===================================================================================== 

                                    POPUP DE NOTIFICATION

    =====================================================================================
    ===================================================================================== 
    
    */
   prepareWarningsComponent(apiResponse, label = false)
   {
    var message = "";
    if (apiResponse.warnings != null && apiResponse.warnings.length > 0)
    {
        if (apiResponse.warnings.length > 1 && label)
        {
            message += "<p>" + this.translateMessage("WARNING_MESSAGE") + "</p>";
        }

        message += "<ul style='list-style:none;padding:0 !important;'>";
        apiResponse.warnings.forEach(element => {
            message += "<li>" + (this.translateMessage(element)) + "</li>";
        });
        message += "</ul>";
    }
    return <label dangerouslySetInnerHTML={{ __html: message }}></label>;
   }
    /**
     * Méthode générique permettant de constituer le message des règles non valides en fonction de la réponse du serveur
     * @param {*} apiResponse 
     * @param {*} label //Flag indiquant si oui ou non, on utilse le message par défaut
     */
    prepareInvalidRulesComponent(apiResponse, label = false)
    {
        var message = "";
        if (apiResponse.invalid_rules != null && apiResponse.invalid_rules.length > 0)
        {
            if (apiResponse.invalid_rules.length > 1 && label)
            {
                message += "<p>" + this.translateMessage("INVALID_RULES_MESSAGE") + "</p>";
            }
            message += "<ul style='list-style:none;padding:0 !important;'>";
            apiResponse.invalid_rules.forEach(element => {
                message += "<li>" + (this.translateMessage(element)) + "</li>";
            });
            message += "</ul>";
        }
        return <label dangerouslySetInnerHTML={{ __html: message }}></label>;
    }

    /**
     * Méthode générique permettant de constituer le message des champs obligatoires en fonction de la réponse du serveur
     * @param {*} apiResponse 
     * @param {*} label //Flag indiquant si oui ou non, on utilse le message par défaut
     */
    prepareRequiredFieldsComponent(apiResponse, label = false)
    {
        var message = "";
        if (apiResponse.required_fields != null && apiResponse.required_fields.length > 0)
        {
            if (apiResponse.required_fields.length > 0 && label)
            {
                message += "<p class='text-muted lead'>" + this.translateMessage("REQUIRED_FIELD_MESSAGE") + "</p>";
            }
            message += "<ul style='list-style:none;padding:0 !important;'>";
            apiResponse.required_fields.forEach(element => {
                message += "<li>" + (this.translateMessage(element)) + "</li>";
            });
            message += "</ul>";
        }
        return <label dangerouslySetInnerHTML={{ __html: message }}></label>;
    }

    /**
     * Méthode générique permettant de constituer le message d'erreur complet en fonction de la réponse du serveur
     * @param {*} apiResponse // Réponse du serveur de type Dto..Response
     * @param {*} message  // Flag indiquant le message d'introduction à utliser (si null alors on utilise le message par défaut)
     */
    prepareResponseErrorComponent(apiResponse, message = null)
    {
        var hasInvalidRules = apiResponse.invalid_rules != null && apiResponse.invalid_rules.length > 0;
        var hasRequireFields = apiResponse.required_fields != null && apiResponse.required_fields.length > 0;
        var hasWarnings = apiResponse.warnings != null && apiResponse.warnings.length > 0;

        if (hasInvalidRules && hasRequireFields == false)
        {
            return <>
                {message}
                {this.prepareInvalidRulesComponent(apiResponse, message != null ? false : true)}
            </>
        }
        else if (hasInvalidRules == false && hasRequireFields)
        {
            return <>
                {message}
                {this.prepareRequiredFieldsComponent(apiResponse, message != null ? false : true)}
            </>
        }
        else if (hasInvalidRules && hasRequireFields)
        {
            return <>
                {message}
                {this.prepareRequiredFieldsComponent(apiResponse, message != null ? false : true)}
                <br/>
                {this.prepareInvalidRulesComponent(apiResponse, message != null ? false : true)}
            </>
        }
        else if (hasWarnings)
        {
            return <>
                {message}
                {this.prepareWarningsComponent(apiResponse, message != null ? false : true)}
            </>
        }
        else
        {
            return <>{this.translateMessage("MODAL_ERROR_TITLE")}</>
        }

    }

    /**
     * Méthode permettant de fermer la popup de notification si elle est ouverte
     */
    closeModal(modalOrder = "")
    {
        var newState = {}
        newState["notification_component_alert" + modalOrder] = null;
        this.setState(newState)
        //this.setState({notification_component_alert: null})
    }

    /**
     * Méthode générique permettant de créer la popup de notification 
     * @param {*} type  // Détermine l'icon qui est utilisé
     * @param {*} title 
     * @param {*} component  // Composant utiliser dans le corps de la popup
     * @param {*} okButtonTitle  // Titre du bouton pour fermer la popup
     * @param {*} large // Flag indiquant si la popup s'ouvre en petit ou en grand
     */
    createModal(type, title, component, okButtonTitle = null, large = false, options = null)
    {
        if (options == null)
        {
            options = {}
        }

        if (okButtonTitle == null)
        {
            okButtonTitle = this.translateMessage("Default.close");
        }

        var okButtonCallback = options.okButtonCallback ? () => { this.closeModal();options.okButtonCallback()} : () => {this.closeModal()}
        
        var iconComponent = null;
        switch(type)
        {
            case "infos":
                iconComponent = <div className="text-center text-info"  style={{fontSize:"100px",}}>
                    <i className="fas fa-info-circle"></i>
                </div>
            break;
            case "success":
                iconComponent = <div className="text-center text-success"  style={{fontSize:"100px",}}>
                    <i className="far fa-check-circle"></i>
                </div>
                break;
            
            case "warning":
                iconComponent = <div className="text-center text-warning" style={{fontSize:"100px"}}>
                    <i className="fas fa-exclamation-triangle"></i>
                </div>
                break;

            case "error":
                iconComponent = <div className="text-center text-danger" style={{fontSize:"100px"}}>
                    <i className="far fa-times-circle"></i>
                </div>
                break;
        }

        return <Modal className={"modal-dialog-centered " + (large ? "modal-lg" : "")} isOpen={true} toggle={() => this.closeModal()}>
            <div className="modal-header">
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.closeModal()}>
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                {
                    (type == "custom") ?
                    (
                        component
                    )
                    :
                    (
                        <>
                            {iconComponent}
                            <Title2 center={true}>
                                {title}
                            </Title2>
                            <div className="py-3 text-center text-muted lead">
                            {component}
                            </div>
                        </>
                    )
                }
                
            </div>
            <div className="modal-footer">
                <Button className="btn-white" color="default" type="button" onClick={() => okButtonCallback()}>
                {okButtonTitle}
                </Button>
                {
                    (options.customFooterButton) ?
                    (
                        options.customFooterButton
                    ) : (<></>)
                }
            </div>
        </Modal>
    }

    /**
     * Méthode générique permettant de créer une modal à partir d'un type, un titre et un contenu
     * @param {*} type 
     * @param {*} title 
     * @param {*} component 
     * @param {*} okButtonTitle 
     * @param {*} large 
     */
    showModalNotification(type, title, component, okButtonTitle, large = false, options = null)
    {
        if (options == null)
        {
            options = {}
        }

        this.setState({
            notification_component_alert: this.createModal(type, title, component, okButtonTitle, large, options)
        });
    }

    /**
     * Méthode pour créer directement une notification popup de type "Error"
     * @param {*} title 
     * @param {*} component 
     * @param {*} okButtonTitle 
     */
    showErrorModalNotification(title, component, okButtonTitle = null)
    {
        this.showModalNotification("error", title, component, okButtonTitle)
    }

    /**
     * Méthode pour créer directement une notification popup de type "Success"
     * @param {*} title 
     * @param {*} component 
     * @param {*} okButtonTitle 
     */
    showSuccessModalNotification(title, component, okButtonTitle = null, options = null)
    {
        if (options == null)
        {
            options = {}
        }

        this.showModalNotification("success", title, component, okButtonTitle, false, options)
    }

    /**
     * Méthode pour créer directement une notification popup de type "Warning"
     * @param {*} title 
     * @param {*} component 
     * @param {*} okButtonTitle 
     */
    showWarningModalNotification(title, component, okButtonTitle = null)
    {
        this.showModalNotification("warning", title, component, okButtonTitle)
    }

    /**
     * Méthode pour créer directement une notification popup de type "Custom"
     * @param {*} title 
     * @param {*} component 
     * @param {*} okButtonTitle 
     * @param {*} large 
     */
    showCustomModalNotification(title, component, okButtonTitle = null, large = false, options = null)
    {
        if (options == null)
        {
            options = {}   
        }

        this.showModalNotification("custom", title, component, okButtonTitle, large, options)
    }

    /**
     * Méthode générique permettant de créer une notification à partir d'une reponse d'alerte du serveur
     * @param {*} response 
     * @param {*} title 
     * @param {*} message 
     */
    showWarningFromResponse(response, title, message = null)
    {
        var component = this.prepareResponseErrorComponent(response, message)

        this.showWarningModalNotification(title, component, "Fermer")
    }

    /**
     * Méthode générique permettant de créer une notification à partir d'une reponse d'erreur du serveur
     * @param {*} response 
     * @param {*} title 
     * @param {*} message 
     */
    showErrorFromResponse(response, title, message = null)
    {
        var component = this.prepareResponseErrorComponent(response, message)

        this.showErrorModalNotification(title, component, "Fermer");
    }

    /**
     * Méthode permettant d'afficher la popup si elle existe
     * Cette méthode est à appeler au début du render des classe enfants
     */
    renderParent() {
      return (
        <>
            <div className="rna-wrapper">
                <ToastNotificationAlert ref="toastNotificationAlert" />
            </div>
            {this.state.notification_component_alert}
            {
                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(o => {
                    return this.state["notification_component_alert" + o]
                })
            }
        </>
      );
    }
  }
 
  export default NotificationComponent;