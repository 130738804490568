const WorkflowMessages = {
    "account.login_step.title1": "Vous possédez déjà un compte?",
    "account.login_step.title2": "Renseignez vos identifiants pour pouvoir continuer.",
    "account.login_step.email": "votre email",
    "account.login_step.password": "votre mot de passe",
    "account.password_information" :  `Merci de saisir un mot de passe contenant au moins 6 caractères dont :
    - un mélange de majuscules et de minuscules
    - un chiffre ou caractère spécial`,

    
    
    "company.siret_input_step_title1": 'Finissons la configuration de votre compte, {user_summary}',
    "company.siret_input_step_title2": 'Renseignez vote SIRET : nous trouverons votre entreprise et nous vérifierons son éligibilité',
    "company.siret_input_step_description": 'company siret input step description',
    "company.siret_input_step_placeholder": 'company siret input step placeholder',
    "company.siret_input_step_error_title": "Enregistrement impossible",
    "company.operational_address": 'Adresse opérationelle ?',
    "company.transporter_billing_operational_address_yes": "Votre siège est maintenant considéré comme un lieu de stationnement",
    "company.transporter_billing_operational_address_no": "Votre siège n'est plus considéré comme un lieu de stationnement",
    "company.shipper_billing_operational_address_yes": "Votre siège est maintenant considéré comme un lieu de chargement",
    "company.shipper_billing_operational_address_no": "Votre siège n'est plus considéré comme un lieu de chargement",
    "company.operational_address.infos": "Permet de préciser si des chargements ou des déchargements auront lieu à cette adresse",
    "company.confirmation_step.company_not_found_title": "Société non trouvée",
    "company.confirmation_step.company_not_found_message": "Merci de vérifier que le numéro SIRET que vous avez renseigné est valide",
    "company.confirmation_step.bad_siret_format_title" : "Le numéro SIRET saisi n'est pas valide.",
    "company.confirmation_step.bad_siret_format_message" : `
    
    Ce numéro, attribué par l'INSEE, est un identifiant composé de 14 chiffres.

    `,
    "company.confirmation_step.no_licence_title": "Impossible de valider votre inscription",
    "company.confirmation_step.no_licence_subtitle": " ",
    "company.confirmation_step.no_licence_message": `Notre plate-forme est réservée aux détenteurs de licences communautaires (liées aux poids lourd).
    

    Veuillez appeler notre équipe commerciale Transporteurs au 04 84 80 08 68.
    `,

    "company.confirmation_step_transporter.success_title" : "Félicitation",
    "company.confirmation_step_shipper.success_title" : "Félicitation",
    "company.confirmation_step_transporter.success_subtitle": "Vous venez de créer votre compte avec succès!",
    "company.confirmation_step_shipper.success_subtitle": "Vous venez de créer votre compte EXPÉDITEUR avec succès!",
    "company.confirmation_step_transporter.success_message": `
    Vous pouvez dès à présent accéder à votre espace en cliquant sur le bouton ci dessous.

    `,
    "company.confirmation_step_shipper.success_message": `
    Vous pouvez dès à présent accéder à votre espace EXPÉDITEUR en cliquant sur le bouton ci dessous.

    `,
    "company.confirmation_step_transporter.success_next": "Accéder à mon espace",
    "company.confirmation_step_shipper.success_next": "Accéder à mon espace EXPÉDITEUR",


    "company.confirmation_step.transporter_billing_operational_address": "Votre siège est-il également un lieu de stationnement de vos véhicules?",
    "company.confirmation_step.shipper_billing_operational_address": "Votre siège social est il également un entrepôt (point de départ d’expéditions de marchandises)?",
    "company.confirmation_ste_.shipper_return_to_search_results": "Poursuivre ma recherche",

    "address.autocomplete_shipper_placeholder": "Saisir l'adresse de votre chargement",
    "address.autocomplete_shipper_customer_placeholder": "Saisir la désignation de votre client",
    "address.autocomplete_transporter_placeholder": "Saisir l'adresse de votre lieu de stationnement",
    "address.confirmation_operational_address": "Ajouter au carnet d'adresse",
    "address.confirmation_operational_address_infos": "Si vous cochez cette case, cette adresse sera conservé dans vos adresses opérationnelles",
    "address.confirmation_form_address_name": "Désignation de cette adresse",
    "address.confirmation_customer_infos": "Données de votre client",
    "address.confirmation_form_address_name_infos": "Ce libellé permet de désigner cette adresse pour la reconnaître plus facilement",
    "address.confirmation_form_address_company_name": "Raison sociale ou désignation",
    "address.new_internalAddresses": "Ajouter un nouvel entrepôt?",
    "address.new_subcontractorAddresses": "Ajouter un nouveau sous-traitant?",
    "address.shipper_no_results_subcontractorAddresses": "Vous n'avez renseigné aucun sous-traitant.",
    "address.shipper_no_results_internalAddresses": "Vous n'avez renseigné aucun entrepôt.",
    "address.transporter_no_results_subcontractorAddresses": "Il n'existe aucun sous-traitant pour cet expéditeur.",
    "address.transporter_no_results_internalAddresses": "Il n'existe aucun entrepôt pour cet expéditeur.",
    

    "order.result_detail_step_proposition_title": 'Notre proposition',
    "order.result_detail_step_proposition_message": 'Vous trouverez ci dessous une proposition chiffrée de location de véhicule.',

    "order.result_detail_step_truck_title": "Détail du camon",
    "order.result_detail_step_truck_message": " ",
    "order.result_detail_step_information_title": 'Information complémentaire',
    "order.result_detail_step_information_message": 'Merci de prendre connaissance des éléments de facturation ci dessous avant de valider votre commande',
    "order.result_detail_step_full_day_title": 'Le forfait journée comprend : ',
    "order.result_detail_step_half_day_title": 'Le forfait demi-journée comprend:',
    "order.result_detail_step_km_per_day_label": 'kilomètres',
    "order.result_detail_step_hours_per_day_label": 'heures de travail (*)',
    "order.result_detail_step_dates_selection_title": 'Période de location',
    "order.result_detail_step_automatic_validation_false": `Ce transporteur n'est pas en réservation instantanée : si vous réservez ce véhicule, nous vérifierons sa disponibilité avant de valider votre commande.`,
    "order.result_detail_step_dates_selection_message": 'Merci de selectionner une période sachant que votre demande initiale ne peut être honorée',
    "order.result_detail_step_one_date_selection_message": "Nous proposons une période alternative car votre demande initiale ne peut pas être honorée",
    "order.result_detail_step_dates_range_message": 'Veuillez trouver ci joint la répartition des jours qui vous sont proposés. Les jours en gris ne font pas parti de la prestation.',
    "order.result_detail_step_dates_range_button": 'Je sélectionne cette plage de dates',
    "order.result_detail_step_manual_validation_payment_button_label": "Commander ce véhicule",
    "order.result_detail_step_manual_validation_payment_go_back_button_label": "Je retourne au paiement de l'acompte",
    "order.result_detail_step_auto_validation_payment_button_label": "Commander ce véhicule",
    "order.result_detail_step_auto_validation_payment_go_back_button_label": "Je retourne au paiement de l'acompte",
    "order.payment_method_step_title": "Paiement de l'acompte",
    "order.payment_step_title": "Paiement de l'acompte",
    "order.bypass_payment_method_step_title": "Création de votre commande",
    "order.bypass_payment_step_title": "Création de votre commande",

    "order.payment_step_pay_button_title": "Valider ma commande",
    "order.payment_method_step_pay_button_title": "Valider ma commande",

    "truck.complete_creation_title": "Félicitation",
    "truck.complete_creation_message": "Votre véhicule est maintenant publié sur notre plateforme.<hr/>Vous pouvez dès à présent mettre à jour ses disponibilités en cliquant sur le lien ci dessous<br/>",
    "truck.complete_creation_planning_link": "Accéder au planning de mon véhicule",
    "truck.close_go_to_list": "Fermer et retourner à la liste",
    "truck.complete_truck": "Compléter les données du véhicule",
    "truck.save_confirmation_title": "Confirmation",
    "truck.save_confirmation_message": "Les données du véhicules ont été enregistrées avec succès",
    "truck.new_truck_title": "Nouveau véhicule",
    "truck.new_truck_subtitle": "",
    "truck.cloned_default_summary": "Véhicule cloné",
    "truck.clone_confirmation_title": "Confirmation",
    "truck.clone_confirmation_message": "Le véhicule a été cloné avec succès.",
    "truck.toll_message" : "Le coût des péages est à inclure dans votre tarif",
    "truck.edit_reference": "Référence interne",
    "truck.edit_reference_message": "Il s’agit de la façon dont vous identifiez ce véhicule en interne (un nom ou un numéro de parc). Cette information est privée et ne sera pas affichée aux expéditeurs.",
    "truck.edit_description": "Commentaire",
    "truck.edit_description_message": "Vous pouvez ajouter commentaire à ce véhicule. Cette information est privée et ne sera pas affichée aux expéditeurs.",
    "truck.edit_address": "Lieu de stationnement",
    "truck.edit_drivers": "Conducteur(s)",
    "truck.edit_drivers_message": "Il peut y avoir plusieurs conducteurs affectés à ce véhicule",
    "truck.edit_km_sup": "Tarif du km supplémentaire",
    "truck.edit_km_sup_message": "",
    "truck.edit_hour_sup": "Tarif de l'heure supplémentaire",
    "truck.edit_hour_sup_message": "Saisir un prix par heure, la facturation sera faite par 1/4 d'heure",
    "truck.edit_cost_day": "Tarif du forfait journée",
    "truck.edit_cost_day_message": "{km} km et/ou {duration} de travail effectif",
    "truck.edit_cost_day_light_message": "{km} km et/ou {duration}",
    "truck.edit_cost_half_day": "Tarif du forfait 1/2 journée",
    "truck.edit_cost_half_day_message": "{km} km et/ou {duration} de travail effectif",
    "truck.edit_cost_half_day_light_message": "{km} km et/ou {duration}",

    "Search.call_for_bids_accept": "Lancer la recherche",
    "Search.call_for_bids_refuse": "Ne rien faire",
    "Search.call_for_bids_title": "On cherche pour vous !",
    "Search.call_for_bids" : `Nous n'avons pas de disponibilité immédiate, mais nous pouvons lancer une recherche auprès de nos transporteurs.
    
    Ce service est <strong>gratuit</strong> et vous permettra d'avoir une ou plusieurs réponses <strong>sous quelques heures !</strong> 

    Souhaitez-vous que nous recherchions ce véhicule pour vous ?`,
    "Search.call_for_bids_next_day_accept": "Lancer la recherche à partir du {proposed_first_day_as_string}",
    "Search.call_for_bids_next_day_refuse": "Ne rien faire",
    "Search.call_for_bids_next_day" : `Nous n'avons pas de disponibilité immédiate, mais nous pouvons lancer une recherche auprès de nos transporteurs.
    
    Ce service est <strong>gratuit</strong> et vous permettra d'avoir une ou plusieurs réponses <strong>sous quelques heures !</strong> 

    Toutefois, le délai nécessaire pour contacter nos transporteurs partenaires est trop court pour lancer cette recherche pour {expected_first_day_as_string}.`,

    "Search.call_for_bids_later" : `Nous n'avons pas de disponibilité, mais nous pouvons lancer une recherche auprès de nos transporteurs.
    
    Ce service est <strong>gratuit</strong> et vous permettra de recevoir une ou plusieurs réponses <strong>sous quelques heures !</strong> (durant les jours ouvrés) 

    Cependant si le délai nécessaire est trop court pour contacter nos transporteurs, nous reviendrons vers vous afin de vous proposer des alternatives.`,

    "call_for_bids.process_step.title": "Création de l'appel d'offre",
    "call_for_bids.process_step.message": "Nous sommes en train d'identifier parmis nos transporteurs ceux suceptibles de vous faire la meilleur offre",
    "call_for_bids.confirmation_step.title": "Félicitations",
    "call_for_bids.confirmation_step.message": "Votre appel d'offre a été créé avec succés.",
    "call_for_bids.proposal_confirmation_step.title": "Félicitations",
    "call_for_bids.proposal_confirmation_step.message": "Votre véhicule a bien été proposé à l'expéditeur",
    "call_for_bids.one_dates_selection_message": "Confirmez-vous vouloir répondre à la demande d'un #{expectedTruckDescription} pour #{expectedPeriodAsString} avec ce véhicule :",
    "call_for_bids.multiple_dates_selection_message": "Votre planning ne permet pas de proposer une véhicule avec chauffeur aux dates souhaitées par notre expéditeur. Merci de selectioner une des proposition suivantes."
 }

export default WorkflowMessages;
