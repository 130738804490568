
import React from "react";
import ImageWithRescue from "./ImageWithRescue";


class AddressImage extends React.Component {
    constructor(props) {
      super(props);

    }
    render() {
        return (<>
        <ImageWithRescue
          maxWidth={this.props.maxWidth}
          alt={this.props.address.type_label}
          imageUrl={this.props.address.type_image_url}
          rounded={false}
          defaultImage={require("../../../../assets/img/icons/common/empty_address_image.svg").default }
        />
      </>)
    }
};

export default AddressImage