
import React from "react";
import classnames from "classnames";
import $ from "jquery";

// reactstrap components
import {
    Button, Row, Col, Badge, UncontrolledPopover, PopoverBody, Container
  } from "reactstrap";
import { injectIntl , FormattedMessage } from 'react-intl';
import DefaultLabel from "./defaultLabel";
import Resources from "../../../resources";
import StringHelper from "../../../helpers/StringHelper";
import Spinner from 'react-spinners/PulseLoader'

class LoadingContainer extends React.Component {

    constructor(props)
    {
        super(props)
    }

    componentDidMount() {}

    componentWillMount() {}

    componentWillUnmount() {}

    setValue(value)
    {
        this.setState({checked: value})
    }

    getValue()
    {
        return this.state.checked;
    }

    onChange(e)
    {
        this.setState({checked: !this.state.checked})

        if (this.props.onChange)
        {
            this.props.onChange(e);
        }
    }

    render() 
    {
        var loadingStyle = {

        }

        return <>
        <div style={{position: "relative", minHeight:"100px"}}>
            {
                (this.props.loading) ?
                (
                    <div  className="t2y-loading-subcontainer">
                        <Spinner style={{margin:"0 auto"}} />
                    </div>
                ) : (<></>)
            }
            
            {
                (this.props.children) ?
                (
                    this.props.children
                ) : (
                    "Vide"
                )
            }
        </div>
        </>

    }
}

export default injectIntl(LoadingContainer)
