
import React from "react";
import PropTypes from 'prop-types'
import classnames from "classnames";

// reactstrap components
import {
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	CardTitle
  } from "reactstrap";
import FormInput from '../components/FormInput'
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import TransporterService from "../../../proxies/TransporterService"
import FormGroupInput from "../components/form/FormGroupInput"
import FormIntegerList from "../components/form/FormIntegerList"
import FormRepositoryTreeview from "../components/form/FormRepositoryTreeview"
import FormRepository from "../components/form/FormRepository";
import FormBoolean from "../components/form/FormBoolean";
import { injectIntl, FormattedMessage } from 'react-intl';
import $ from "jquery";
import DefaultInput from "../../components/common/defaultInput";
import RepositoryInput from "../../components/business/repositoryInput";
import IntegerListInput from "../../components/common/integerListInput";
import BooleanListInput from "../../components/common/booleanListInput";
import StringHelper from "../../../helpers/StringHelper";
import RepositoriesModalInput from "../../components/common/repositoriesModalInput";
import ArrayHelper from "../../../helpers/ArrayHelper";


class TruckProperties extends FormValidationComponent {

	constructor(props)
	{
		super(props)
		this.transporterService = new TransporterService();
		this.childs = [];
		this.values = [];
		this.validationRules = {}

		this.overrideFamilyDisplay = []

		this.state = {
			done: false,
			loading : false,
			shared : [],
			property_families: [],
			formDatas : {
			}
		}
	}
	
	componentWillMount()
	{
		// Récupération des données des proipriétés
		if (!this.props.families)
		{
			this.transporterService.getAllTruckPropertiesAndFamilies(this, (response) => {
				var newState = {};
				newState.property_families = response.property_families
				newState.property_rules = response.property_rules
				
				if (this.props.truck)
				{
					this.setTruck(this.props.truck)
				}

				this.setState(newState)
			});
		}
		else{
			var newState = {};
			newState.property_families = this.props.families
			newState.property_rules = this.props.rules
			this.setState(newState)

			if (this.props.truck)
			{
				this.setTruck(this.props.truck)
			}
		}
	}

	

	componentDidMount() {
		//const { childRef } = this.props;
		//childRef(this);
		if (this.props.childRef)
		{        
			this.props.childRef(this)
		}
		if (this.props.truck && this.props.truck.property_values)
		{
			this.props.truck.property_values.forEach(propertyValue => {

				// On force l'affichage de la famille de la donnée si on a une valeur
				this.applyRuleAction(null, propertyValue.property_family_id, "PROPERTY_RULE_ACTION_TYPE_SHOW")
			})
		}

		// Application automatique des règles issues du serveur
		if (this.props.rulesResults)
		{
			this.applyServerRules(this.props.rulesResults)
		}

	}

	// Application automatique des règles issues du serveur
	applyServerRules(rulesResults)
	{
		var hiddenFamillies = [];

		rulesResults.forEach(ruleResult => {

			
			switch(ruleResult.action)
			{
				case "PROPERTY_RULE_ACTION_TYPE_HIDE":
					var ruleActionResult = this.applyRuleAction(null, ruleResult.target_id, "PROPERTY_RULE_ACTION_TYPE_HIDE")
					if (ruleActionResult.action == "PROPERTY_RULE_ACTION_TYPE_HIDE")
					{
						if (this.props.families && this.props.families.findIndex((x) => x.id == ruleResult.target_id) != -1)
						{
							hiddenFamillies.push(ruleResult.target_id);
						}
					}
				break;

				case "PROPERTY_RULE_ACTION_TYPE_SHOW":
					var ruleActionResult = this.applyRuleAction(null, ruleResult.target_id, "PROPERTY_RULE_ACTION_TYPE_SHOW")
					if (ruleActionResult.action == "PROPERTY_RULE_ACTION_TYPE_SHOW")
					{
						if (this.props.families && this.props.families.findIndex((x) => x.id == ruleResult.target_id) != -1)
						{
							const index = hiddenFamillies.indexOf(ruleResult.target_id);
							if (index > -1) {
								hiddenFamillies.splice(index, 1);
							}
						}
					}
				break;
			}
		})
		
		if (this.props.families)
		{
			if (hiddenFamillies.length == this.props.families.length)
			{
				this.setState({
					done: true
				})
			}
		}
	}

	// Affectation du camion et des ses propriétés
	setTruck(truck)
	{
		if (truck)
		{
			var formDatas = {}
			truck.property_values.forEach(propertyValue => {

				formDatas[propertyValue.property_id] = { value : propertyValue.value}
				this.values[propertyValue.property_id] = propertyValue.value;
			})

			// Application automatique des règles issues du serveur
			if (truck.rules_results)
			{
				this.applyServerRules(truck.rules_results);
			}

			this.setState({formDatas: formDatas})
		}
	}

	getHtmlFormValidationSummary(e)
	{
		return this.htmlFormValidationSummary(e)
	}

	getDataToPost()
	{
		var postedData = []
		this.state.property_families.forEach(family => {
			// Si la famille est visible
			if (this.htmlFormControlHidden(family.id) == false)
			{
				family.properties.forEach(property => {
					// Si la propriété est visible
					if (this.htmlFormControlHidden(property.id) == false)
					{
						var value = this.getHtmlFormControlValue(property.id)
						if (value != null && value != "")
						{
							postedData.push({ property_id: property.id, value: (Array.isArray(value) ? value[0] : value)})
						} else {
							if (this.props.getEmptyValues)
							{
								postedData.push({ property_id: property.id, value: null})
							}
						}
					}
				})
			}
		})

		return { property_values: postedData };
	}

	checkCondition(condition)
	{
		// Récupération de la valeur
		var value = this.getHtmlFormControlValue(condition.field);
		
		switch(condition.operator)
		{
			case "equal":
			case "=":
				return value == condition.value;
				break;
			case "not_equal":
			case "<>":
			case "!=":
				return value != condition.value;
				break;
			case "in":
				return condition.values.includes(Array.isArray(value) ? value[0] : value);
				break;
			case "not_in":
				return condition.values.includes(Array.isArray(value) ? value[0] : value) == false;
				break;
			case "less":
			case "<":
				return value < condition.value;
				break;
			case "less_or_equal":
			case "<=":
				return value < condition.value;
				break;
			case "greater":
			case ">":
				return value > condition.value;
				break;
			case "greater_or_equal":
			case ">=":
				return value < condition.value;
				break;
			case "is_empty":
				return this.emptyValue(value)
				break;
			case "is_not_empty":
				return this.notEmptyValue(value)
				break;
		}
		
		return false;
	}

	checkConditionNode(node)
	{
		if (typeof node === "undefined" || node == null || typeof node.rules === "undefined" || node.rules == null || node.rules.length == 0)
		{
			return true;
		}
		
		const operator = node.combinator;
		var result = (operator.toString().toUpperCase() == "OR" ? false : true);

		node.rules.forEach(rule => {
			var ruleResult = null;
			if (!rule.combinator)
			{
				// Traitement de la comparaison
				ruleResult = this.checkCondition(rule);
			}
			else
			{
				// Traitement d'un noeud
				ruleResult = this.checkConditionNode(rule);
			}
			
			if (operator == "OR")
			{
				result = result || ruleResult;
			}
			else
			{
				result = result && ruleResult;
			}
		})
		
		
		return result;
	}

	getRuleById(ruleId)
	{
		var rule = null;
		this.state.property_rules.forEach(r  => {
			if (r.id == ruleId)
			{
				rule = r;
			}
		})

		return rule;
	}
	manageRuleForResult(ruleId)
	{
		var rule = this.getRuleById(ruleId)
		var result = false;
		// exécution de la règle
		if (rule.stored_procedure != null)
		{
			result = true;
		}
		else
		{
			result = this.checkConditionNode(rule.rule_object)
		}
		return result;
	}

	manageRule(ruleId, manual = false)
	{
		var rule = this.getRuleById(ruleId)
		var result = this.manageRuleForResult(ruleId)
		// Gestion du résultat
		if (result) // success
		{
			this.applyRuleAction(rule, rule.target_id, rule.success_action_code, manual)
		}
		else  // failure
		{
			this.applyRuleAction(rule, rule.target_id, rule.failure_action_code, manual)
		}
	}

	applyRuleAction(rule, targetId, actionCode, manual = false)
	{
		var valueToReturn = {
			action: 'none'
		}
		var element = $("#" + targetId)
		
		// On détermine l'élément sur lequel effectué l'action 
		if (element.hasClass("is-family"))
		{
			element = element.parent();
		}

		if (manual)
		{
			var family = ArrayHelper.findFirst(this.state.property_families, (item) => { return item.id == targetId})
			if (family)
			{
				switch(actionCode)
				{
					case "PROPERTY_RULE_ACTION_TYPE_SHOW":
						family.visible = true
						break;
					case "PROPERTY_RULE_ACTION_TYPE_HIDE":
						family.visible = false
						break;
				}

				this.overrideFamilyDisplay = ArrayHelper.replaceArrayItem(family, this.overrideFamilyDisplay, ["id"])
			}
		}
		
		switch(actionCode)
		{
			case "PROPERTY_RULE_ACTION_TYPE_SHOW":
				if (element.hasClass("not-visible-and-exclude-from-validation"))
				{
					element.removeClass("not-visible-and-exclude-from-validation")
					valueToReturn.action = actionCode
				}
				break;
			case "PROPERTY_RULE_ACTION_TYPE_HIDE":
				if (element.hasClass("not-visible-and-exclude-from-validation") == false)
				{
					element.addClass("not-visible-and-exclude-from-validation")
					valueToReturn.action = actionCode
				}
				break;
			case "PROPERTY_RULE_ACTION_TYPE_CUSTOM_SP_CODE":
				// Création des paramèetres
				
				var params = {}
				rule.rule_object.rules.forEach(r => {
					params[r.field] = this.getHtmlFormControlValue(r.field);
				})

				if(this.props.truck && this.props.truck.id)
				{
					params["truck_id"] = this.props.truck.id;
				}
				
				this.transporterService.executeSpCustomRule(null, rule.id, params).then(response => {
					const value = response.value;
					const currentValue = this.getHtmlFormControlValue(rule.target_id);
					
					
					// Si aucune valeur alors on la modifie sinon on laisse celle déjà présente
					//if (!currentValue || currentValue == "" || currentValue == null || currentValue == 0)
					//{
					if (this.childs[rule.target_id] && this.childs[rule.target_id].current)
					{
						this.childs[rule.target_id].current.setValue(value);
					}
					//}
					
				})
				break;
			case "PROPERTY_RULE_ACTION_TYPE_SPECIFIC_VALUE":
				
				break;
		}

		return valueToReturn
	}

	// Récupération de la valeur dont dépent un liste de valeur
	getDependsOnValue(property)
	{
		var sourceSelectedValue = null;
		if (this.state.property_rules)
		{
			this.state.property_rules.forEach((rule) => {
				if (rule.target_id == property.id && rule.depends_on == true && !sourceSelectedValue)
				{
					if (this.props.truck.property_values)
					{
						this.props.truck.property_values.forEach(propertyValue => {
							if (propertyValue.property_id == rule.source_id)
							{
								sourceSelectedValue = propertyValue.value;
							}
						});
					}
				}
			})
		}

		return sourceSelectedValue;
	}

	onProperyChange(event, property)
	{
		event.preventDefault();
		if (event.target.value)
		{
			// Si la valeur n'a pas changé alors on sort
			// Bug de certaines valeur qui se mettent à jour tout le temps
			if (this.values[property.id] && this.values[property.id] == event.target.value)
			{
				return false;
			}


			this.values[property.id] = event.target.value;

			// Recherche d'une règle sur mesure pour ce chanmps
			property.rules.forEach(ruleId => {
				this.manageRule(ruleId, true)
			})

			// Gestion des règles sur la modificaiton d'un champs
			var dependsOnState = {};
			var stateChanged = false;
			this.state.property_rules.forEach((rule) => {
				if (rule.source_id == property.id && rule.depends_on == true)
				{
					if (this.childs[rule.target_id] && this.childs[rule.target_id].current)
					{
						this.childs[rule.target_id].current.dependsOn(property.id,  event.target.value);
					}
				}
			})

		}
		
		this.htmlInputChanged(event, property.id)
	}

	// Affichage de la zone de saisie en fonction du type de propriété
	renderInput(property, propertyIndex)
	{
		var propertyComponent = null;
		var key = property.id
		var currentValue = this.values  && this.values[property.id] ? this.values[property.id] : null

		switch(property.data_type_code)
		{
			case "PROPERTY_TYPE_TEXT":
					propertyComponent =  <DefaultInput computed={property.computed} computedDescription={property.computed_description} value={currentValue} required={property.required} type="textarea" cols="3" ref={this.childs[property.id]} name={property.id} manager={this} />
				break;
			case "PROPERTY_TYPE_INT_FROM_LIST":
					propertyComponent =  <IntegerListInput  value={currentValue} required={property.required} name={property.id} min={property.min} max={property.max} step={property.step}  manager={this} />
				break;
			case "PROPERTY_TYPE_INT":
					propertyComponent = <DefaultInput computed={property.computed} computedDescription={property.computed_description} value={currentValue} required={property.required} type="number" ref={this.childs[property.id]} onBlur={(e) => { this.onProperyChange(e, property)}}  name={property.id} manager={this} append={property.data_unit_code ? this.props.intl.formatMessage({id: property.data_unit_code + ".short_label"}) : null} />
				break;
			case "PROPERTY_TYPE_DECIMAL":
					propertyComponent = <DefaultInput computed={property.computed} computedDescription={property.computed_description} value={currentValue} required={property.required} type="number" ref={this.childs[property.id]} onBlur={(e) => { this.onProperyChange(e, property)}}  name={property.id} manager={this} append={property.data_unit_code ? this.props.intl.formatMessage({id: property.data_unit_code + ".short_label"}) : null} />
				break;
			case "PROPERTY_TYPE_BOOL":
					propertyComponent = <BooleanListInput value={currentValue} name={property.id} manager={this} />
				break;
			case "PROPERTY_TYPE_REPOSITORY":
					if (property.code != "BODY")
					{
						propertyComponent = <RepositoryInput loadingPlaceholder={StringHelper.translate(this, "Default.loading_placeholder")} dependsOn={this.getDependsOnValue(property)} key={key} required={property.required} ref={this.childs[property.id]} onChange={(e) => { this.onProperyChange(e, property)}} buffer={this.state["buffer_" + property.id]} name={property.id} familyCode={property.data_repository_family_code} manager={this} multiple={false} />
					}
					else
					{
						propertyComponent = <RepositoriesModalInput placeholder="Selectionnez une carrosserie" title="Merci de selectionner une carrosserie" value={currentValue} required={property.required} key={key} ref={this.childs[property.id]} onChange={(e) => { this.onProperyChange(e, property)}} buffer={this.state["buffer_" + property.id]} name={property.id} familyCode={property.data_repository_family_code} manager={this} multiple={false} property={property}  ></RepositoriesModalInput>
					}
				break;
			case "PROPERTY_TYPE_REPOSITORIES":
					propertyComponent = <RepositoryInput key={key} name={property.id} familyCode={property.data_repository_family_code} manager={this} multiple={true} />
				break;
			case "PROPERTY_TYPE_TREEVIEW":
					propertyComponent = <FormRepositoryTreeview name={property.id} manager={this} />
				break;
			default:
					propertyComponent = <DefaultInput computed={property.computed} computedDescription={property.computed_description}  value={currentValue} required={property.required} name={property.id} manager={this} />
				break;
		}
		//{property: property, component: propertyComponent};
		return propertyComponent
	}

	// Affichage du bloc contenant la zone de saisie d'une propriété
	renderProperty(property, propertyIndex)
	{
		this.childs[property.id] = React.createRef();

		return <FormGroupInput 
			type={!this.props.groupInputType ? "inline" : this.props.groupInputType}
			label={property.label} tooltip={property.description ? {message:property.description} : null} htmlFor={property.code}>
			{this.renderInput(property, propertyIndex)}
		</FormGroupInput>
	}

	// Affichage de toutes le propriétés
	renderProperties(properties, familyIndex)
	{
		return properties.map((property, index) => {
			if (property.code == "DOMAIN")
			{
				return <></>
			}
			else
			{
				return <Col lg={12 / (!this.props.itemsPerLines ? 2 : this.props.itemsPerLines)} key={"PropertyRow" + familyIndex + "_" + index}>
					{this.renderProperty(property, index)}
				</Col>
			}
		});

	}
	// Affichage de chaque famille de propriétés
	renderFamilies(propertyFamilies)
	{
		if (propertyFamilies && propertyFamilies.length > 0)
		{
			var familyComponents = propertyFamilies.map((propertyFamily, index) => {
				var visible = propertyFamily.visible

				var overrideFamily = ArrayHelper.findFirst(this.overrideFamilyDisplay, (item) => { return item.id == propertyFamily.id})
				if (overrideFamily)
				{
					//visible = overrideFamily.visible
				}

				return  <Col lg="12" key={"col-family" + index} className={visible == false? "not-visible-and-exclude-from-validation": ""}>
							<Card key={"family" + index} className="is-family" id={propertyFamily.id} >
								<CardHeader>
									<CardTitle className="h2 mb-0">{propertyFamily.label}</CardTitle>
									<small className="text-muted">{propertyFamily.description}</small>
								</CardHeader>
								<CardBody>
									<Row>
										{this.renderProperties(propertyFamily.properties, index)}
									</Row>
								</CardBody>
							</Card>
					</Col>
			})

			if (this.props.truck && this.props.truck.property_values)
			{
				this.props.truck.property_values.forEach(propertyValue => {
					// On force l'affichage de la famille de la donnée si on a une valeur
					this.applyRuleAction(null, propertyValue.property_family_id, "PROPERTY_RULE_ACTION_TYPE_SHOW")
				})
			}

			// Application automatique des règles issues du serveur
			if (this.props.rulesResults)
			{
				this.applyServerRules(this.props.rulesResults)
			}
			
			return familyComponents
		}
	}


	render() {
		if (this.state.done)
		{
			return <>
				Le paramétrage est terminé.
			</>
		}

		if (this.props.truck == null)
		{
			return <></>
		}

		return  (
			<>
			<FormLoadingIndicator visible={this.state.loading}>
			<Row>
				{this.renderFamilies(this.state.property_families)}
			</Row>
			</FormLoadingIndicator>
			</>
		);
	}
}

TruckProperties.propTypes = {
	instance: PropTypes.any
}

export default injectIntl(TruckProperties)