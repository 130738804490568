
import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";
import queryString from 'query-string';

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    InputGroupText,
    InputGroupAddon,
    InputGroup,
    Button,
    ButtonGroup,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal
} from "reactstrap";
import SimpleHeader from '../../../../components/Headers/SimpleHeader'
import SearchService from '../../../../proxies/SearchService'
import { injectIntl , FormattedMessage } from 'react-intl';
import FormValidationComponent from "../FormValidationComponent";
import ReactSlidingPane from "react-sliding-pane";
import FormGroupInput from "../form/FormGroupInput"
import FormInput from '../FormInput'
import Title2 from '../../../components/common/title2'
import Title3 from '../../../components/common/title3'
import Title4 from '../../../components/common/title4'
import DefaultButton from '../../../components/common/defaultButton'
import Message from "../../../components/common/message"
import Rating from "../../../components/rating/rating"
import FormLoadingIndicator from "../FormLoadingIndicator";
import PortalSearchForm from "../../../components/search/portalSearchForm";
import loginStep from "../../../components/workflow/account/loginStep";
import DefaultImage from "../../../components/common/defaultImage";
import DateHelper from "../../../../helpers/DateHelper";
import DateLabel from "../../../components/common/dateLabel";
import ImageWithRescue from "../Image/ImageWithRescue";
import Config from "../../../../config";
import Resources from "../../../../resources";
import SecurityService from "../../../../proxies/SecurityService";
import StringHelper from "../../../../helpers/StringHelper";
import DefaultInput from "../../../components/common/defaultInput";
import DefaultLink from "../../../components/common/defaultLink";
import WebsiteHelper from "../../../../helpers/WebsiteHelper";
import CallForBidsWorkflow from "../../../components/workflow/callForBids/callForBidsWorkflow";
import OrderWorkflow from "../../../components/workflow/order/orderWorkflow";



var moment = require('moment');
require('moment/locale/fr');

class TruckResult extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        
        this.state = {
            value: this.props.value,
            customRibbon: this.props.customRibbon,
            disableOrder: this.props.disableOrder
        }
    }

    componentWillMount()
    {
    }

    componentDidMount()
    {
        if (this.props.autoStartWorkflow)
        {
            this.startOrderWorkflow(this.state.value.id)
        }
    }

    
    startOrderWorkflow(resultId)
    {
        this.orderWorkflowRef.start(resultId)
    }

    
    getDesktopVisibleComponentClass()
    {
        return "d-none d-lg-block";
    }

    getTabletVisibleComponentClass()
    {
        return "d-none d-md-block";
    }

    getMobileVisibleComponentClass()
    {
        return "d-block d-lg-none"
    }

    refuse()
    {
        var value = this.state.value;
        value.state.code = "BIDS_STATE_REFUSED"
        
        this.setState({
            value: value, 
            customRibbon: {
                text: "Refusé",
                color: "refused"
            }, 
            disableOrder: true
        })
    }

    renderRating(rating, color)
    {
        return <Rating iconWidth="0.8em" iconHeight="0.8em" note={parseFloat(rating) / 2} type={"default"} color={color} />
    }

    renderEnvironmentalNote(rating, color)
    {
        //return <Rating iconWidth="0.8em" iconHeight="0.8em" note={parseFloat(rating) / 2}  type={"leaf"} color={color} />
        return <Rating iconWidth="0.8em" iconHeight="0.8em" note={rating}  type={"leaf"} color={color} />
    }

    
    renderExtendedLabel(label, extended, classToAdd)
    {
        return <label className={"t2y-search-result-label " + (extended == "1" ? "" : classToAdd)}>
            {label}
        </label>
    }

    renderRibbon(text, color)
    {
        return <>
            <div className="d-none d-md-block t2y-ribbon t2y-ribbon-bottom-right"><span className={color + " pl-2"}>{text}</span></div>
            <div className="d-block d-md-none t2y-ribbon t2y-ribbon-bottom"><span className={color}>{text}</span></div>
        </>
    }

    renderTruckResult()
    {   
        if (!this.props.value)
        {
            return <></>
        }

        const result = this.state.value;

        var realCost = result.amount_to_pay_ht;
        var automaticValidationColor = "green";
        var automaticValidationText = "Réservation instantanée";

        if(result.transporter_automatic_validation == false)
        {
            automaticValidationColor = "orange";
            automaticValidationText = "Disponibilité à confirmer";
        }

        return <>
        <div className="t2y-search-result-container" style={{position:"relative"}} key={StringHelper.createDomElementId()}>

            {
                (this.props.hideRibbon) ?
                (
                    null
                ) : (
                    this.renderRibbon(automaticValidationText, automaticValidationColor)
                )
            }
            {
                (this.state.customRibbon) ?
                (
                    this.renderRibbon(this.state.customRibbon.text, this.state.customRibbon.color)
                ) : (null)
            }

            <Row>
                <Col xs="12" className="d-block d-md-none">
                {this.renderExtendedLabel(
                    <><Title4 content={result.truck_label}></Title4></>,
                    result.strict_truck,
                    "t2y-search-result-extended-truck"
                )}
                </Col>
            </Row>
            <Row>
                <Col xs="3" sm="3" className="d-none d-md-block">
                    <img alt="" className="img-center  shadow shadow-lg--hover " 
                    src={Config.bndApi + "/truck/image/" + result.truck_id} 
                    style={{width: "100%", height: "auto"}} />
                </Col>
                <Col xs="12" sm="12" md="9">
                    <Row>
                        <Col xs="6">
                            <Row>
                                <Col xs="12" className="d-none d-md-block">
                                {this.renderExtendedLabel(
                                    <><Title4 content={result.truck_label}></Title4></>,
                                    result.strict_truck,
                                    "t2y-search-result-extended-truck"
                                )}
                                </Col>
                            </Row>
                            {/*
                                (result.transporter_checked) ?
                                (
                                <Row><Col xs="12">
                                    <Row>
                                            <Col xs="1"><DefaultImage className="t2y-search-result-certified" src={require("../../../../assets/img/icons/certified_transporter.svg").default}></DefaultImage></Col> 
                                            <Col xs="10">
                                                <label className={"t2y-search-result-label"}>
                                                <FormattedMessage id="Search.detail_verify_transporteur" />
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                ) : (<></>)
                            */}
                            <Row>
                                <Col xs="12">
                                    <div style={{float:"left"}} className="mr-2">
                                        <label className="t2y-search-result-label">Note qualité :</label>
                                    </div>
                                    <div style={{float:"left"}}>
                                        {this.renderRating(result.rating, "#FFD433")}
                                    </div>
                                </Col>
                            </Row>
                            <Row><Col xs="12">
                                    <Row>
                                        <Col xs="1"><DefaultImage className="t2y-search-result-check" src={require("../../../../assets/img/icons/check.svg").default}></DefaultImage></Col> 
                                        <Col xs="10">
                                            {this.renderExtendedLabel(
                                                <>à {Math.round(result.distance)} Km</>,
                                                result.strict_distance,
                                                "t2y-search-result-extended-distance"
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> 
                            <Row>
                                <Col xs="12">
                                    <Row>
                                        <Col xs="1"><DefaultImage className="t2y-search-result-check" src={require("../../../../assets/img/icons/check.svg").default}></DefaultImage></Col> 
                                        <Col xs="10">
                                            {this.renderExtendedLabel(
                                                (
                                                    !result.strict_amount ? 
                                                        <>Toute la journée du <DateLabel date={result.dates[0]} label={false}></DateLabel></> : 
                                                        <>Disponibilité le <DateLabel date={result.dates[0]} label={false}></DateLabel></>
                                                ),
                                                result.strict_date,
                                                "t2y-search-result-extended-date"
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row><Col xs="12">
                                <Row>
                                    <Col xs="1">
                                    <DefaultImage className="t2y-search-result-check" src={require("../../../../assets/img/icons/check.svg").default}></DefaultImage>
                                    </Col>
                                    <Col xs="10">
                                    <div style={{float:"left"}} className="mr-2">
                                        <label className="t2y-search-result-label">Note éco : </label>
                                    </div>
                                    <div style={{float:"left"}}>
                                    {this.renderEnvironmentalNote(result.environmental_note, "#80FF33")}
                                    </div>
                                    </Col>
                                </Row>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs="6">
                            <Row>
                                <Col xs="12" className="d-block d-md-none">
                                    <img alt="" className="img-center  shadow shadow-lg--hover " 
                                    src={Config.bndApi + "/truck/image/" + result.truck_id} 
                                    style={{width: "100%", height: "auto"}} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12"  className={"mb-3 pr-0 pl-0 " + (!result.strict_amount ? "t2y-search-result-extended-amount" : "")} >
                                    <Title2  content={StringHelper.formatAmountWithPattern(realCost, "€", "{amount} {devise} HT")}></Title2>
                                    <em className="t2y-search-result-other-prices" style={{position:"absolute", marginTop:-14}}>forfait total</em>
                                </Col>
                                <Col sm="12">
                                    <span className="t2y-search-result-other-prices">heure supp : {result.cost_hour_as_string}</span>
                                </Col>
                                <Col sm="12">
                                    <span className="t2y-search-result-other-prices">km supp : {result.cost_km_as_string}</span> 
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <DefaultButton className="t2y-secondary-button small" onClick={(e) => {this.startOrderWorkflow(result.id)}}>
                                        <FormattedMessage id="Search.results_see_detail"></FormattedMessage>
                                    </DefaultButton>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>

                    </Row>

                </Col>
            </Row>
            
        </div>
        </>
    }


    render() {
        return  (
            <>
                <OrderWorkflow childRef={(elt) => this.orderWorkflowRef = elt} id="ORDER_WORKFLOW" 
                    disableOrder={this.state.disableOrder} 
                    callForBids={this.props.callForBids}
                    parent={this}
                >
                </OrderWorkflow>
                {this.renderParent()}
                
                {this.renderTruckResult()}
            </>      
        );
    }
}


export default injectIntl(TruckResult)


