import "../../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
import {
    Input,
} from "reactstrap";


class FormComponent extends React.Component {

    constructor(props)
    {
        super(props)
    }

    componentDidMount() {}

    newUUID()
    {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }

    generateUniqueId()
    {
        return  Math.random().toString(16).slice(-4);
    }

    getOnChange(e)
    {
        let key = this.getKey();
        if (!this.props.onChange && this.props.manager)
        {
            this.props.manager.validateForm(e, key)
        }
        else
        {
            this.props.onChange(e, key)
        }

        if (typeof this.localOnChanged !== "undefined")
        {
            this.localOnChanged(e);
        }
    }

    getKey()
    {
        return !this.props.id ? this.props.name : this.props.id;
    }

    getId()
    {
        return !this.props.id ? this.props.name : this.props.id;
    }

    getName(){
        return !this.props.name ? this.props.id : this.props.name;
    }
    getValidationState()
    {
        return (!this.props.validationState && this.props.manager) ? this.props.manager.getFormControlValidationState(this.getKey()) : this.props.validationState
    }

    getDefaultValue()
    {   
        return (!this.props.defaultValue && this.props.manager) ? this.props.manager.getFormControlValue(this.getKey()) : this.props.defaultValue
    }

    getValidationInvalidMessage()
    {
        return (!this.props.validationInvalidMessage && this.props.manager) ? this.props.manager.getValidationInvalidMessage(this.getKey()) : this.props.validationInvalidMessage
    }

    getValidationRequiredMessage()
    {
        return (!this.props.validationRequiredMessage && this.props.manager) ? this.props.manager.getValidationRequiredMessage(this.getKey()) : this.props.validationRequiredMessage
    }

    getValidationValidMessage()
    {
        return this.props.validationValidMessage
    }
  }
 
  export default FormComponent;