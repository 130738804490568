import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Container
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import Title2 from '../common/title2';
import Title3 from '../common/title3';
import Message from '../common/message';

import Step from "./step";
import DefaultLabel from "../common/defaultLabel";


class ComponentAndImageStep extends Step {

    constructor(props) {
        super(props)
        
        this.state = {
            loading: true,
        }
    }

    componentWillMount() {
        super.componentWillMount()

        // Récupération des données du paiement de l'acompte
    }

    componentDidMount() {
        super.componentDidMount()
    }

    renderTitle()
    {
        return <Title2 content={this.state.title}></Title2>
    }

    renderSubTitle()
    {
        return <Title3 content={this.state.subtitle}></Title3>
    }

    renderMessage()
    {

    }

    renderImage()
    {
        return <img src={this.state.image} style={{width:"100%"}} />
    }

    renderComponent() { <></>}
    
    renderFooter()
    {
        return <></>
    }

    render() {
        var componentSize = this.state.componentSize ? this.state.componentSize : 6
        var imageSize = this.state.imageSize ? this.state.imageSize : 6
        return <>
        {this.renderParent()}
        <Container>
            <Row> 
                <Col lg={componentSize} md="12" className="order-lg-1 order-2">
                    <Row className="mb-3">
                        <Col>
                            {this.renderTitle()}
                            {this.renderSubTitle()}
                        </Col>
                    </Row>
                    {
                        (this.state.message) ? 
                        (
                            <Row className="">
                                <Col xs="12">
                                    <DefaultLabel className="text-justify" content={this.state.message}></DefaultLabel>
                                </Col>
                            </Row>
                        ) : (<></>)
                    }
                    <Row className="mb-3">
                        {this.renderComponent()}
                    </Row>
                </Col>
                <Col lg={imageSize} md="12" className="justify-content-text-center order-lg-2 order-1">
                    {this.renderImage()}
                </Col>
            </Row>
            {this.renderFooter()}
            
        </Container>
        </>
    }
}


export default ComponentAndImageStep