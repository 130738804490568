import "../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
import { renderToString } from 'react-dom/server'
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components
 import {
     Row,
     Col
 } from "reactstrap";
import RepositoryService from "../../../proxies/RepositoryService"
import { injectIntl , FormattedMessage } from 'react-intl';

import { Multiselect } from 'multiselect-react-dropdown';
import DefaultInput from "../common/defaultInput";
import DefaultImage from "../common/defaultImage";
import StringHelper from "../../../helpers/StringHelper";
import TagsInput from "react-tagsinput";
import ShipperService from "../../../proxies/ShipperService";
import LoadingIndicator from 'react-spinners/MoonLoader'
import DefaultCheckbox from "../common/defaultCheckbox";
import Resources from "../../../resources";
import FormValidationComponent from "../../pages/components/FormValidationComponent";
import PersonCompanyInput from "../common/personCompanyInput";

class ShipperCustomerInput extends FormValidationComponent     {

    constructor(props)
    {
        super(props);
        
        this.shipperService = new ShipperService();

        this.state = {
            options: [],
            company: this.props.company == null ? true : this.props.company,
            loading: false
        }
    }

    componentWillMount()
    {
        super.componentWillMount();
    }

    componentDidMount()
    {
        super.componentDidMount();

        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    _handleSearch(text)
    {
        if (text != null && text.length >= 3)
        {
            this.setState({loading: true, text: text, onlyAutocomplete: true})
            this.shipperService.getCustomers(this, text, false, (suggestions) => {
                
                this.setState({ options: suggestions.list, loading: false, value: null})
                document.getElementById("customer_id").value = "";
            })
        }
        else
        {
            this.setState({options: [], text: null, onlyAutocomplete: false, value: null})
            document.getElementById("customer_id").value = "";
        }

        if (this.props.onSearch)
        {
            this.props.onSearch(text)
        }
    }

    _onChanged(customer)
    {
        document.getElementById("customer_search_input").value = customer.label;
        document.getElementById("customer_id").value = customer.id;

        this.setState({
            onlyAutocomplete: false,
            value: customer,
            options: [], 
            text: null
        })
        if (this.props.onChange)
        {
            this.props.onChange(customer)
        }
    }



    hasValue()
    {
        var value = this.getValue();

        if (typeof value !== "undefined" && value  && value != null && value.label && value.label != null && value.label != "")
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    isCompany()
    {
        return this.state.company;
    }

    getValue()
    {
        var id = this.getHtmlFormControlValue("customer_id");//document.getElementById("customer_id").value;

        return {
            id: id == "" ? null : id,
            label: this.getHtmlFormControlValue("customer_search_input"), //document.getElementById("customer_search_input").value
            first_name: this.getHtmlFormControlValue("first_name"),
            last_name: this.getHtmlFormControlValue("last_name"),
            mobile_phone_number: this.getHtmlFormControlValue("mobile_phone_number"),
            home_phone_number: this.getHtmlFormControlValue("home_phone_number"),
            email: this.getHtmlFormControlValue("email")
        }
    }
    
    hasCustomerId()
    {
        var value = this.getValue();

        if (typeof value !== "undefined" && value  && value != null && value.id && value.id != null && value.id != "")
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    getInternalValue()
    {
        return this.state.value ? this.state.value : (this.props.value ? this.props.value : {id: null, label: null})
    }

    getCustomerId()
    {
        if (this.hasCustomerId())
        {
            return this.getValue().id;
        }
        else
        {
            return null;
        }
    }   

    renderPerson()
    {
        return <>
            <Row className="mb-3">
                <Col>
                    <DefaultInput id="first_name"
                        manager={this}
                        value={this.getInternalValue().first_name}
                        childRef={(elt) => this.fnameInputRef = elt}
                        icon={Resources.form_fname_icon} placeholder={"Prénom"}>
                    </DefaultInput>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <DefaultInput id="last_name"
                        manager={this}
                        value={this.getInternalValue().last_name}
                        childRef={(elt) => this.lnameInputRef = elt}
                        icon={Resources.form_lname_icon} placeholder={"Nom de famille"}>
                    </DefaultInput>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <DefaultInput id="mobile_phone_number"
                        manager={this}
                        value={this.getInternalValue().mobile_phone_number}
                        childRef={(elt) => this.mobilePhoneNumberInputRef = elt}
                        icon={Resources.form_mobile_phone_number_icon} placeholder={"N° de mobile"}>
                    </DefaultInput>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <DefaultInput id="email"
                        manager={this}
                        type="email"
                        value={this.getInternalValue().email}
                        childRef={(elt) => this.loginInputRef = elt}
                        icon={Resources.form_email_icon} placeholder={"E-mail"}>
                    </DefaultInput>
                </Col>
            </Row>
        </>

    }


    renderCompany()
    {
        var backgroundColor = "white"
        var textColor = "#1e2635" 
        var iconColor = "#FFC42B"

        return <div className={"btn btn-default "} style={{zIndex:"600", width: "100%", backgroundColor: backgroundColor, borderColor: textColor, color:textColor, padding:0}}>
            <span className="btn-inner--icon" style={{ color:this.props.iconColor, fontSize: "20px", float: "left", margin: "0.625rem 1.25rem" }}>
                <DefaultImage style={{ color: iconColor}} src="far fa-address-card"></DefaultImage>
            </span>
            <input
                
                id="customer_search_input"
                value={this.getInternalValue().label}
                focus={this.state.focus}
                onChange={(e)=> this._handleSearch(e.currentTarget.value)}
                autoComplete="OFF" 
                style={{ border:"none", height:"50px", marginTop:"0px", float:"left", width:"80%", color:this.props.textColor}}
                placeholder={this.props.autocompletePlaceHolder ? this.props.autocompletePlaceHolder : StringHelper.translate(this, "address.autocomplete_shipper_customer_placeholder")}
            />
            {
                (this.state.loading) ?
                (
                    <span class="btn-inner--icon t2y-default-input-icon-container">
                        <LoadingIndicator size={15} />
                    </span>
                ) : (<></>)
            }
            <div style={{width:"100%", overflow:"scroll"}}  className={"dropdown-menu " + ((this.state.options.length > 0) ? "show": "")}>
                { this.state.options.map((option, index) => 
                        <div 
                            key={"selectItem" + index} 
                            className="dropdown-item" onClick={ ()=> this._onChanged(option)}
                            dangerouslySetInnerHTML={{__html: option.label}}
                        > 
                        </div> 
                    )
                }
            </div>
        </div>
    }

    render()
    {
        

        var className = ""
        if (this.props.required)
        {
            className += " t2y-required" + (this.hasValue() ? "-with-value" : "")
        }

        return <>
            <input type="hidden" id="customer_id" value={this.getInternalValue().id}></input>

            <Row className="mt-3">
                <Col xl="12">
                    <PersonCompanyInput 
                        name="personCompany" 
                        className="mb-2"
                        value={this.state.company ? "COMPANY": "PERSON"} childRef={elt => this.personCompanyInputRef = elt}
                        onChange={(value) => {
                            const company =  (value == "COMPANY")
                            this.setState({company:company})
                            if (this.props.onCustomerTypeChange)
                            {
                                this.props.onCustomerTypeChange(company)
                            }

                        }}
                    ></PersonCompanyInput>
                </Col>
                
                {
                    (this.state.company) ?
                    (
                        <Col xl="12" className={className}>
                        {this.renderCompany()}
                        </Col>
                    ) :
                    (
                        <Col xl="12" className={""}>
                        {this.renderPerson()}
                        </Col>
                    )
                }
            </Row>
        </>
    }
  }
 
  export default injectIntl(ShipperCustomerInput);