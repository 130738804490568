
import React from "react";
import classnames from "classnames";
import $ from "jquery";
import { GeolocatedProps, geolocated } from "react-geolocated";
import {
    Row,
    Col,
    Badge,
    Alert,
    Modal,
    Button,
    ButtonGroup,
    Spinner,
    Input
} from "reactstrap";
import Title3 from "../common/title3"
import Title2 from "../common/title2"
import { injectIntl, FormattedMessage } from 'react-intl';
import RepositoryService from '../../../proxies/RepositoryService'
import SecurityService from '../../../proxies/SecurityService'
import FormLoadingIndicator from "../../pages/components/FormLoadingIndicator";
import onClickOutside from "react-onclickoutside";
import GeocodingAndNavigationService from "../../../proxies/GeocodingAndNavigationService"
import {asyncContainer, Typeahead,  Menu, MenuItem, Highlighter} from 'react-bootstrap-typeahead';
import DefaultButton from '../common/defaultButton'


import { format, formatDistance, add } from 'date-fns'
import { fr } from 'date-fns/locale'
import { Calendar } from 'react-date-range';
import DateHelper from '../../../helpers/DateHelper'
import ImageWithRescue from "../../pages/components/Image/ImageWithRescue";
import BusinessService from "../../../proxies/BusinessService";
import Resources from "../../../resources";
import DefaultImage from "../common/defaultImage";
import Message from "../common/message";
import AddressWorkflow from "../workflow/address/addressWorkflow"
import WebsiteHelper from "../../../helpers/WebsiteHelper";

var moment = require('moment');
require('moment/locale/fr');


class PortalAddressSearchInput extends React.Component {

    constructor(props) {
        super(props);
        this.securityService = new SecurityService();
        this.businessService = new BusinessService();
        this.geocodingAndNavigationService = new GeocodingAndNavigationService();
        this.current_address_id = null;
        this.state = {
            selection_mode: this.props.public == false && this.securityService.isConnected() ? "known_address" : "autocomplete",
            only_autocomplete: false,
            known_addresses: null,
            addresses_error: false,
            modal: false,
            options: [],
            latitude: null,
            longitude: null,
            value: null,
            positionAddress: null,
            summary: null
        }
    }

    componentWillMount()
    {
        // Si l'utilisateur est connecté, alors in récupère les adresses métier
        if (this.props.public == false && this.securityService.isConnected())
        {
            /*this.businessService.getAddresses(null, (response) => {
                this.setState({known_addresses: response.addresses})
            }, (httpErrorResponse) => {
                this.setState({known_addresses: [], addresses_error: true})
            })*/
            this.setState({known_addresses: []})
        }
        else
        {
            this.setState({known_addresses: []})
        }
        
    }

    componentDidMount()
    {
        this.props.childRef(this)
    }

    getInputId(suffix)
    {
        return "search_" + suffix
    }

    getValues()
    {
        return this.state.value;
    }
    
    setDomElementValueById(id, value)
    {
        var domElement = document.getElementById(this.getInputId(id));
        if (domElement)
        {
            domElement.value = value;
        }
    }


    setValues(address) {

        this.setDomElementValueById("gps_external_id", address.gps_external_id);
        this.setDomElementValueById("gps_lat", address.gps_lat);
        this.setDomElementValueById("gps_long", address.gps_long);
        this.setDomElementValueById("gps_level", address.gps_level);
        this.setDomElementValueById("address_label", address.summary);
        this.setDomElementValueById("street", address.street);
        this.setDomElementValueById("zip_code", address.zip_code);
        this.setDomElementValueById("city", address.city);
        this.setDomElementValueById("country", address.country_code);
        
        //document.getElementById("search_input").value = address.summary;

        this.setState({
            value: {
                address_id: address.id,
                gps_external_id: address.gps_external_id,  
                gps_lat: address.gps_lat,
                gps_long: address.gps_long,
                gps_level: address.gps_level,
                summary: address.summary,
                line4: address.street,
                line2: null,
                zip_code: address.zip_code,
                city: address.city,
                country_code: address.country_code
            },
        })

        this.current_address_id = address.id
    }

    switchSelectionMode(mode)
    {
        var value = null;

        // Si on est en mode position actuelle, alors on récupère la position
        if (mode == "point")
        {

        }

        this.setState({
            selection_mode: mode,
            value: value
        })
    }

    /*
    toggleModal() {
        this.setState({ modal: !this.state.modal})
    }
    */
    isPublic() {
        return !this.props.public ? true : this.props.public
    }
    
    toggleModal(validation) 
    {
        var modal = !this.state.modal;

        this.setState({ modal: modal, focus: modal }, () => {
            if (modal == false && validation == true)
            {
                this.props.manager.validateInput(this)
            }
        })

    }

    toggleState(stateKey)
    {
        var v = {}
        v[stateKey] = !this.state[stateKey]
        this.setState(v);
    }

    _handleSearch(text)
    {
        if (text != null && text.length > 3)
        {
            this.setState({loading: true, text: text, only_autocomplete: true})
            this.geocodingAndNavigationService.autosuggest(this, text, (suggestions) => {
                this.setState({ options: suggestions, loading: false})
            })
        }
        else
        {
            this.setState({options: [], text: null, only_autocomplete: false})
        }   
    }

    _exit()
    {
        this.setState({options: [], text: null})
    }

    getPoint()
    {
        var latitude = this.props.coords.latitude;
        var longitude = this.props.coords.longitude;

        var value = {
            address_id: null,
            gps_external_id: null,  
            gps_lat: latitude,
            gps_long: longitude,
            gps_level: "houseNumber",
            summary: "ma position actuelle ",
            line4: null,
            line2: null,
            zip_code: null,
            city: null,
            country_code: "COUNTRY_FR"
        }
        /*
        this.setState({
            value: {
                address_id: null,
                gps_external_id: null,  
                gps_lat: latitude,
                gps_long: longitude,
                gps_level: "houseNumber",
                summary: "ma position actuelle ",
                line4: null,
                line2: null,
                zip_code: null,
                city: null,
                country_code: "COUNTRY_FR"
            },
        })*/

        this.geocodingAndNavigationService.reverse(null, latitude, longitude, (address) => {

            if (address)
            {
                value.summary = address && address.city ? "ma position actuelle (" + address.city.toUpperCase() + ")" : this.state.summary
                value.gps_external_id = address.gps_external_id;
                value.gps_lat = address.gps_lat;
                value.gps_long = address.gps_long;
                value.gps_level = address.gps_level;
                value.street = address.street;
                value.line4 = address.street;
                value.zip_code = address.zip_code;
                value.city = address.city
                value.country_code = address.country_code;
            }
            
            this.setState({
                value: value,
                positionAddress: address
            }, () => {
                this.toggleModal(true)
            })
        })

        
        /*this.toggleModal(true);*/
    }

    getKnownAddress(address)
    {
        this.setState({
            value: {
                address_id: address.id,
                gps_external_id: address.gps_external_id,  
                gps_lat: address.gps_lat,
                gps_long: address.gps_long,
                gps_level: address.gps_level,
                summary: address.name,
                line4: address.street,
                line2: address.street2,
                zip_code: address.zip_code,
                city: address.city,
                country_code: "COUNTRY_FR"
            },
        })

        this.toggleModal(false);
    }

    _onChanged(gpsInfos)
    {
        if (gpsInfos != null)
        {
            this.geocodingAndNavigationService.getAutoSuggestDetail(gpsInfos.gps_external_id, (response) => {
                document.getElementById(this.getInputId("gps_external_id")).value = response.gps_external_id;
                document.getElementById(this.getInputId("gps_lat")).value = response.gps_lat;
                document.getElementById(this.getInputId("gps_long")).value = response.gps_long;
                document.getElementById(this.getInputId("gps_level")).value = response.gps_level;
                document.getElementById(this.getInputId("address_label")).value = response.label;
                document.getElementById(this.getInputId("street")).value = response.street;
                document.getElementById(this.getInputId("zip_code")).value = response.zipCode;
                document.getElementById(this.getInputId("city")).value = response.city;
                document.getElementById(this.getInputId("country")).value = response.country;
                
                document.getElementById("search_input").value = response.label;

                this.setState({
                    only_autocomplete: false,
                    value: {
                        address_id: null,
                        gps_external_id: response.gps_external_id,  
                        gps_lat: response.gps_lat,
                        gps_long: response.gps_long,
                        gps_level: response.gps_level,
                        summary: response.label,
                        line4: response.street,
                        line2: null,
                        zip_code: response.zipCode,
                        city: response.city,
                        country_code: response.country
                    },
                })

                //console.log(response)
                this._exit();

                if (this.props.onSelected) {
                    this.props.onSelected(response)
                }
            })
        }
        else
        {
            this.setState({value: null})
        }
    }

    renderHeaders()
    {
        var messageComponent = <><Message type="infos" className="mt-2">Merci de préciser l'adresse de votre premier chargement</Message></>

        if (this.props.public)
        {
            return messageComponent
        }
        else
        {
            return <Row className="justify-content-center mb-2">
                {messageComponent}
                <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                    <Button className={classnames({ active: this.state.selection_mode === "autocomplete" }) + " multiChoiceBtn"} color="secondary"
                            onClick={() => this.switchSelectionMode("autocomplete")}>
                        Adresse libre
                    </Button>
                    <Button className={classnames({ active: this.state.selection_mode === "known_address" }) + " multiChoiceBtn"} color="secondary"
                        onClick={() => this.switchSelectionMode("known_address")}>
                        Mon carnet d'adresses
                    </Button>
                </ButtonGroup>
            </Row>
        }
    }
        
    renderAutocomplete()
    {
        return <>
            
            <Row className="mt-3">
                <Col xl="12">
                <div className="btn btn-default t2y-address-search-input-container" style={{width: "100%", backgroundColor:this.props.backgroundColor, borderColor:this.props.textColor, color:this.props.textColor, padding:0}}>
                    <span className="btn-inner--icon" style={{ color:this.props.iconColor, fontSize: "20px", float: "left", margin: "0.625rem 1.25rem" }}>
                        <i className="fas fa-map-marker-alt"></i>
                    </span>
                    <input
                        
                        id="search_input"
                        focus={this.state.focus}
                        onChange={(e)=> this._handleSearch(e.currentTarget.value)}
                        autoComplete="OFF" 
                        style={{ border:"none", height:"50px", marginTop:"0px", float:"left", width:"80%", color:this.props.textColor}}
                        placeholder="Adresse du 1er chargement"
                    />
                    <div style={{width:"100%", overflow:"scroll"}}  className={"dropdown-menu " + ((this.state.options.length > 0) ? "show": "")}>
                        { this.state.options.map((option, index) => 
                                <div 
                                    key={"selectItem" + index} 
                                    className="dropdown-item" onClick={ ()=> this._onChanged(option)}
                                    dangerouslySetInnerHTML={{__html: option.html ? option.html : option.label}}
                                > 
                                </div> 
                            )
                        }
                    </div>
                </div>
                </Col>
            </Row>
            {
                (this.props.isGeolocationAvailable && this.props.isGeolocationEnabled && this.state.only_autocomplete == false) ?
                (
                    <>
                    <Row style={{lineHeight:"20px"}}>
                        <Col xs="4">
                            <hr/>
                        </Col>
                        <Col xs="4" style={{marginTop:"20px", textAlign:"center"}}>
                            OU
                        </Col>
                        <Col xs="4">
                            <hr/>
                        </Col>
                    </Row>
                    <Row className="    ">
                        <Col xl="12" className="text-center">
                            <DefaultButton icon="fas fa-crosshairs" onClick={(e) => this.getPoint() } className="t2y-secondary-button" >
                                <FormattedMessage id="utiliser ma position actuelle"></FormattedMessage>
                            </DefaultButton>
                        </Col>
                    </Row>
                    </>
                ) : (<></>)
            }
        
        </>
    }

    onSearchAddressSubmited(address, callback)
    {
        if (callback)
        {
            callback()
        }
        this.getKnownAddress(address)
    }

    renderKnownAddress()
    {
        return <>
            <AddressWorkflow 
                key="SEARCH_ADDRESS_WORKFLOW"
                id="SEARCH_ADDRESS_WORKFLOW"
                context={"MISSION_STEP_TYPE_LOADING"}
                parent={this}
                skipAddressConfirmation={true}
                addressSelectionComponent={<></>}
                addressSelectionGeolocalisation={false}
                addressConfirmationAllowOperationalAddress={false}
                onAddressSubmited={(address, callback) => this.onSearchAddressSubmited(address, callback)}
                childRef={(elt) => this.departureAddressWorkflowRef = elt}
                address={null}
                readonly={false}
                customer={true}
                defaultContact={null}
                /*onDefaultContactChanged={(address, contact) => {
                    var contactId = contact ? contact.id : null

                    var postedData = {
                        id: this.state.transport_operation.id,
                        mission_id: this.props.mission.id,
                        departure_contact_id: address.customer ? null : contactId,
                        departure_customer_address_contact_id: address.customer ? contactId : null
                    }

                    this.shipperService.saveDepartureAddressDefaultContact(this, postedData, (response) => {})
                }}*/
            >
            </AddressWorkflow>

        </>

        /*
        if (this.state.known_addresses == null)
        {
            return <>Chargement en cours ...</>
        }
        else if (this.state.known_addresses.length == 0)
        {
            return <><hr/><Message type="infos">Vous n'avez enregistré aucune adresse dans votre carnet d'adresse.</Message></>
        }
        else
        {
            return <>
                {
                    this.state.known_addresses.map(address => {
                        
                        var isSelected = (this.state.value != null && this.state.value.address_id == address.id) || this.current_address_id == address.id;

                        return <>
                            <Row className="mt-3">
                                <Col xs="12">
                            <Button onClick={() => this.getKnownAddress(address)} color="default" type="button" 
                                style={{ width: "100%", color:"red", 
                                    backgroundColor: !isSelected ? this.props.backgroundColor : Resources.color_selected_date, 
                                    borderColor:this.props.textColor, 
                                    color:this.props.textColor}}
                            >
                                <span className="btn-inner--icon" style={{ 
                                    color: !isSelected ? this.props.iconColor : "black", fontSize: "20px", float: "left", marginRight: "20px" }}>
                                    <i className="fas fa-map-marker-alt"></i>
                                </span>
                                <span className="btn-inner--text" style={{ textAlign:"left", float: "left", color:this.props.textColor, paddingTop:"5px" }}>
                                    {address.name}
                                    <br/>
                                    <div>{address.street}</div>
                                    <div>{address.zip_code + " " + address.city}</div>
                                </span>
                            </Button>
                            </Col>  
                            </Row>
                        </>
                    })
                }
            </>
        }
        */
    }

    renderCurrent()
    {
        switch(this.state.selection_mode)
        {
            case "autocomplete":
                return this.renderAutocomplete()
            break;
            case "point":
                return <></>
            break;
            case "known_address":
                return this.renderKnownAddress();
            break;
        }

        return <></>
    }

    renderAddressSummary()
    {
        var summary = "Adresse du chargement...";
        
        if (this.state.value != null)
        {
            if (this.state.value.city != null && this.state.value.city != "")
            {
                summary = (!this.state.value.zip_code  ? "" : (this.state.value.zip_code + " ")) + this.state.value.city;
            }
            else
            {
                summary = this.state.value.summary;
            }
        }
        return summary;
    }
    
    renderModal() {
        var className = "fullscreen-modal " + this.props.modal_className
        var size=null
 
        return <>
            <Modal size={size} fade={false} className={className} isOpen={this.state.modal} toggle={() => this.toggleModal(false)} >
                <div className="modal-body">
                    {
                        (WebsiteHelper.isPortraitMobileWidth()) ?
                        (
                            <></>
                        ) :
                        (
                            <Row className="justify-content-center">
                                <Col xl="12">
                                    {this.renderHeaders()}
                                </Col>
                            </Row>
                        )
                    }
                    <Row className="justify-content-center">
                        <Col xl="12">
                            {this.renderCurrent()}
                        </Col>
                    </Row>
                    
                </div>
                <div className="modal-footer">
                    <Row>
                    {
                        (this.state.value != null) ?
                        (
                            
                                <Col xl="4" className="text-right">
                                    <DefaultButton icon="fas fa-check" className="t2y-secondary-button small"  onClick={(e) => this.toggleModal(true)}>
                                        <FormattedMessage id="Valider"></FormattedMessage>
                                    </DefaultButton>

                                </Col>
                        ) : (<></>)
                    }
                    </Row>
                </div>
            </Modal>

        </>
    }

    render() {
        return <>
            <Input type="hidden" id={this.getInputId("gps_external_id")} />
            <Input type="hidden" id={this.getInputId("gps_lat")} />
            <Input type="hidden" id={this.getInputId("gps_long")} />
            <Input type="hidden" id={this.getInputId("gps_level")} />
            <Input type="hidden" id={this.getInputId("address_label")} />
            <Input type="hidden" id={this.getInputId("street")} />
            <Input type="hidden" id={this.getInputId("zip_code")} />
            <Input type="hidden" id={this.getInputId("city")} />
            <Input type="hidden" id={this.getInputId("country")} />

            {this.renderModal()}
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
            

            <Button onClick={() => this.toggleModal(false)} color="default" type="button" className="t2y-search-input t2y-search-input-address" >
                <span className="btn-inner--icon" >
                    <DefaultImage src={"SVG:" + require("../../../assets/img/icons/search-input-address.svg").default} />
                </span>
                <span className="btn-inner--text">
                    {
                        this.renderAddressSummary()
                    }
                </span>
            </Button>

            


        </>
    }
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(injectIntl(PortalAddressSearchInput));
