const Repository = {
    "State.ORDER_STATE_WAITING_TRANSPORTER_VALIDATION" : "Attente de validation",
    "State.ORDER_STATE_COMPLETED" : "Réalisée",
    "State.ORDER_STATE_STARTED": "En cours",
    "State.ORDER_STATE_VALIDATED": "Planifiée",
    "State.ORDER_STATE_DRAFT" : "Brouillon",
    "State.STATE_CANCELED" : "Annulée"
    
    
}

export default Repository