/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect } from "react";
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardText,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import SecurityService from "../../../proxies/SecurityService";
import { injectIntl , FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import DefaultButton from "../../components/common/defaultButton.jsx";
import WebsiteHelper from "../../../helpers/WebsiteHelper.js";
import Resources from '../../../resources'
import Config from "../../../config.js";
import StringHelper from "../../../helpers/StringHelper.js";

class DriverAccountValidation extends FormValidationComponent {
  constructor(props)
  {

    super(props);
    this.state = {
      loading : false,
      success : null,
      errorMessage : null,
      seconds : 5,
      isMobile: isMobile,
      isAndroid: isAndroid,
      isIos: isIOS,
      android: {}
    }
    this.securityService = new SecurityService();
  }

  componentDidMount()
  {
    // Si aucune clé n'est présente, alors on retourne sur l'écran de connexion
    const urlParams = new URLSearchParams (this.props.location.search)    
    const token = urlParams.get("token")
    if (token == null)
    {
      this.props.history.push('/auth/login');
    }
    else
    {
      // Appel de l'url de validation de compte et enregistrement du nouveau token
      this.securityService.validateDriverAccount(this, token, (response) => {
        // Si tout est ok, on affiche le message et on redirige sur la home
        this.setState({success : true, android: response.application })
      }, (httpResponse) => {
        httpResponse.json().then((apiResponse) => {

          this.setState({success : false, loading : false, android: apiResponse.application })

          // Sinon impossible de valider le token
          if (typeof apiResponse.invalid_rules !== "undefined" && apiResponse.invalid_rules.length > 0)
          {
            this.setState({
              errorMessage : this.translateMessage("DriverAccountValidation." + apiResponse.invalid_rules[0])
            });
          }
        })        
      })
    }
  }

  renderSimpleConfirmationMessage()
  {
    return <>
      <Card className="bg-secondary border-0">
        <CardBody>
          {
            (this.state.success == true) ? 
            (
              <>
                <CardTitle className="mb-3" tag="h3">
                  {StringHelper.translateWithHtml(this, "Driver.account_validation_title")}
                  {StringHelper.translateWithHtml(this, "Driver.account_simple_validation_message")}
                  <FormattedMessage id="Driver.account_validation_title">
                    { this.decodeEntities }
                  </FormattedMessage>
                </CardTitle>
                <CardText className="mb-4">
                  <FormattedMessage id="Driver.account_simple_validation_message">
                    { this.decodeEntities }
                  </FormattedMessage>
                </CardText>
              </>
            ) : (<></>)
          }
        </CardBody>
      </Card>
    </>
  }

  renderMobileAppDownloadComponent()
  {
    return <>
      <Card className="bg-secondary border-0">
        <CardBody className="t2y-html-translation-container">
          {
            (this.state.success == true) ? 
            (
              <CardTitle className="mb-3" tag="h3">
                <FormattedMessage id="Driver.account_validation_title">
                  { this.decodeEntities }
                </FormattedMessage>
              </CardTitle>
            ) : (<></>)
          }
          {
            (this.state.isIos) ?
            (
              <>
                <CardText className="mb-4">
                  {StringHelper.translateWithHtml(this, "Driver.account_validation_ios_error_message")}
                </CardText>
              </>
            ) : 
            (
              (this.state.isAndroid) ?
              (
                <>
                  <CardText className="mb-4 t2y-justify">
                    {StringHelper.translateWithHtml(this, "Driver.account_validation_android_message")}
                  </CardText>
                  <DefaultButton 
                      onClick={() => WebsiteHelper.goTo(this, this.state.android.store_url) }
                      icon={Resources.button_google_play_store} className="t2y-secondary-button">
                      <FormattedMessage id="Driver.account_validation_android_download_title"></FormattedMessage>
                  </DefaultButton>  
                </>
              )
              :
              (
                <CardText className="mb-4">
                  {StringHelper.translateWithHtml(this, "Driver.account_validation_other_device_error_message")}
                </CardText>
              )
            )
          }
        </CardBody>  
      </Card>

    </>
  }

  render() {
    return (
      <>
        {this.renderParent()}
        
        <Container className="mt-5 pb-5">
          <FormLoadingIndicator loading={this.state.loading} />

          <Row className="justify-content-center">
            <Col md="8">
              {
                (this.state.success != null) ? 
                (
                  (Config.allowMobileApplicationDownload) ?
                  (
                    this.renderMobileAppDownloadComponent()
                  ) : (
                    this.renderSimpleConfirmationMessage()
                  )
                )
                :
                (
                  <Card className="bg-secondary border-0">
                    <CardBody>
                        <CardText className="mb-4 text-center">
                          <FormattedMessage id="AccountValidation.loading_message" />
                        </CardText>
                      </CardBody>  
                  </Card>      
                )
              }
              
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(DriverAccountValidation);
