
import React from "react";
import UserImage from "../Image/UserImage";
import StateLabel from "../StateLabel";


// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardImg,
    CardTitle,
    FormGroup,
    Form,
    Input,
    ListGroupItem,
    ListGroup,
    Progress,
    Container,
    Row,
    Col,
    CardFooter
  } from "reactstrap";
import Codes from "../../../../codes"
import Rating from "../../../components/rating/rating";
import DefaultLabel from "../../../components/common/defaultLabel";
import Ribbon from "../../../components/common/ribbon"

const DriverCard = ({ data }) => (
    <a href={"/admin/driver/" + data.id }>
    <Card>
        <CardBody>
            {
                (data.state && data.state.code == Codes.WAITING_FOR_DRIVER_VALIDATION) ?
                (
                    <Ribbon type={"warning"} position={"top-right"}>en attente</Ribbon>
                ) : (<></>)
            }
            
            <UserImage user={data} maxWidth="140px !important" />
            <div className="pt-4 text-center">
                <h5 className="h3 title">
                    <span className="d-block mb-1">{data.summary}</span>
                    {data.state_code}
                </h5>
                <h3>
                    <i className="fas fa-truck-moving"></i>&nbsp;
                    {data.nb_trucks} camion(s)
                </h3>
                <StateLabel data={data} />
            </div>
            {
                (data.rating_count && data.rating_count > 0)?
                (
                    <>
                        <Row className="justify-content-center">
                        <Rating iconWidth="0.8em" note={parseFloat(data.rating) / 2.0}></Rating>
                        </Row>
                        <Row className="justify-content-center">
                            <h6>{data.rating_count} évaluation(s)</h6>
                        </Row>
                    </>
                ) : (<>
                <Row className="justify-content-center">&nbsp;</Row>
                <Row className="justify-content-center">&nbsp;</Row></>)
            }
        </CardBody>
        
    </Card>
    </a>      
);


export default DriverCard