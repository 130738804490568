
import React from "react";
import ImageWithRescue from "./ImageWithRescue";
import SecurityService from "../../../../proxies/SecurityService"


class UserImage extends React.Component {
    constructor(props) {
      super(props);
      this.securityService = new SecurityService();

    }
    render() {
        /*var defaultImageUrl = require("~/assets/img/icons/common/empty_user_image.svg");*/
        var defaultImageUrl = this.securityService.getDefaultUserImageUrl(this.props.user)

        return (<>
        <ImageWithRescue
          shadow={false}
          thumbnail={false}
          maxWidth={this.props.maxWidth}
          alt={this.props.user.summary}
          imageUrl={this.props.user.image_url}
          defaultImage={defaultImageUrl }
        />
      </>)
    }
};

export default UserImage