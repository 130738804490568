import React from "react";
import { Form, Row, Col} from "reactstrap";
import { injectIntl , FormattedMessage } from 'react-intl';
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator";
import TransporterService from "../../../proxies/TransporterService";
import Message from "../../components/common/message"
import FormLegend from "../../components/form/formLegend"
import FormGroupInput from "../components/form/FormGroupInput"
import DefaultInput from "../../components/common/defaultInput"
import StringHelper from "../../../helpers/StringHelper";
import DefaultButton from "../../components/common/defaultButton";

class AddNewIban extends FormValidationComponent {

    constructor(props)
    {
        super(props);

        this.transporterService = new TransporterService();

        this.validationRules = {
            iban_owner_label: {
                required: true
            },
            iban: {
                required : true, 
                rules : [{
                    rule : this.iban.bind(this),
                    ruleMessage: this.translateMessage("Company.new_iban_invalid_format_message")
                }]
            }
        }

        this.state = {
            uploadFileSizeAlert: false
        }
    }

    fileChangedHandler = (event) => {
        let file = event.target.files[0];
        let type = file.type;
        let fileSize = file.size;
            

        var uploadFileSizeAlert = false;
        var uploadFileExtensionAlert = false;

        if (((fileSize / 1024) / 1024) > 5)
        {
            uploadFileSizeAlert = true;
        }

        let typeParts = type.split('/');

        if (["pdf", "png", "jpg", "jpeg", "tiff"].includes(typeParts[typeParts.length - 1].toLowerCase()) == false)
        {
            uploadFileExtensionAlert = true;
        }

        this.setState({
            uploadFileSizeAlert: uploadFileSizeAlert,
            uploadFileExtensionAlert: uploadFileExtensionAlert,
            image_file: uploadFileSizeAlert == false && uploadFileExtensionAlert == false ? file : null
        })
    }
    
    onSubmitForm = e => {
        e.preventDefault()
    
        if (this.state.uploadFileSizeAlert)
        {
            alert("Merci de télécharger un fichier de 5Mo maximum");
            return false;
        }

        if (this.state.uploadFileExtensionAlert)
        {
            alert("Merci de télécharger un fichier pdf ou une image (jpg, jpeg ou png)");
            return false;
        }
    
        if (!this.state.image_file)
        {
            alert("Merci de télécharger un fichier pdf ou une image (jpg, jpeg ou png)");
            return false;
        }
        

        var formIsValid = this.htmlFormIsValid(e);
        if (formIsValid)
        {
            // Récupération des donées postée
            var formData = new FormData(e.target)
            const fileInput = document.querySelector("#file");
            
            formData.append("file", this.state.image_file);
            formData.append("iban_owner_value", this.ibanOwnerInputRef.getValue());
            formData.append("iban_public_value", this.ibanInputRef.getValue());
            
            this.transporterService.addNewIban(this, formData, (response) => {
                var savedValue = response.value;
                // Récupération des données saisies et mise à jour des données société en mémoire
                var company = this.transporterService.getCompany();
                company.iban_owner_label = savedValue.iban_owner_label
                company.iban_public_value = savedValue.iban_public_value
                company.iban_state = savedValue.iban_state
                company.iban_creator = savedValue.iban_creator
                company.iban_creation_date = savedValue.iban_creation_date
                company.has_iban = savedValue.has_iban
                this.transporterService.storeCompany(company);

                // Retour à la page parent
                this.props.manager.ibanAdded(company)

            }, (httpErrorResponse) => {
                this.showErrorFromResponse(httpErrorResponse.response.data);
                this.setState({loading: false}) 
            })
        }
    }

    render()
    {
        return <>
            {this.renderParent()}
            <Form onSubmit={e => this.onSubmitForm(e)} role="form">
                <FormLoadingIndicator loading={this.state.loading} ></FormLoadingIndicator>
                <Row>
                    <Col lg="12">
                        <div className="pl-lg-4">
                            <Row className="justify-content-center">
                                <Message type="infos">
                                    {StringHelper.translate(this, "Company.new_iban_message")}
                                </Message>
                            </Row>
                            <FormLegend required={true} />
                            <hr/>
                            <Row>
                                <Col lg="12">
                                    <FormGroupInput intlId={"Company.new_iban_owner_label"} htmlFor="iban_owner_label" type="multiline">
                                        <DefaultInput required={true} childRef={(elt) => this.ibanOwnerInputRef = elt}  name='iban_owner_label' manager={this} />
                                    </FormGroupInput>
                                </Col>
                                <Col lg="12">
                                    <FormGroupInput intlId={"Company.new_iban"} htmlFor="iban" type="multiline">
                                        <DefaultInput required={true} childRef={(elt) => this.ibanInputRef = elt}  name='iban' manager={this} />
                                    </FormGroupInput>
                                </Col>
                                <Col lg="12">
                                    <FormGroupInput labelClassName="t2y-full-width" intlId={"Company.new_iban_attachment"} htmlFor="attachment" type="multiline">
                                        <input type="file" id="file" name="file" onChange={(e) => this.fileChangedHandler(e)}/>
                                    </FormGroupInput>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="text-center">
                            <DefaultButton className="t2y-secondary-button small" type="submit">
                                <FormattedMessage id='Company.new_iban_upload' />
                            </DefaultButton>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    }

  }
 
  export default injectIntl(AddNewIban);