import React from "react";
import IconPageNotFound from "../../assets/img/illustrations/pageNotFound.svg";
import WebsiteHelper from "../../helpers/WebsiteHelper";

const PageNotFound = ({ mainText = "La page demandée n'existe pas"}) => {
	return <div className="bg-white text-center w-100">
		<div className="offset-2 col-8 mt-2" style={{ color: "#0f1428" }}>
			<img src={IconPageNotFound} className="img-fluid" style={{ width: "25em" }} />
			<div className="mt-5 p-4 d-flex justify-content-center align-items-center flex-column">
				<h1 className="display-2">Oups!</h1>
				<div className="h2 text-center d-block">
					{mainText}
					<br />
					Veuillez saisir une autre URL ou cliquer sur un des éléments de la barre du menu
				</div>
			</div>
			<div className="d-flex justify-content-center align-items-center mt-2">
				<button className="t2y-btn-primary" onClick={(e) => WebsiteHelper.goToHome(this)}>
					Retourner à l'accueil
				</button>
			</div>
		</div>
	</div>
}

export default PageNotFound;
