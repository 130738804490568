import React, { useEffect, useState } from "react";

const IframeV2 = () => {

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const iframe = document.getElementById("iframeV2");
    if(iframe && iframe.parentNode) {
      iframe.addEventListener("load", onLoad);
    }
  }, [])

  function onLoad() {
    setLoaded(true)
  }

  useEffect(() => {
    if (loaded) {
      let win = (window.frames as any).iframeV2;
      win.postMessage({ token: localStorage.getItem("token") }, process.env.REACT_APP_FRONT_V2_DOMAIN);
    }
  }, [loaded])

  return (
    <div className="iframe-wrapper"
			style={{ height: "95vh"}}
		>
      {!loaded &&
        <div className="loader">
          Chargement...
        </div>
      }

      <iframe id='iframeV2' style={{ width: "100%", height: "100%", border:"none" }} className="iframe-V2" src={`${process.env.REACT_APP_FRONT_V2_DOMAIN}/admin`} name="iframeV2"/>
    </div>

  )
};

export default IframeV2;