import Config from '../config.js'
import ProxyService from './ProxyService'
import axios from "axios";

class TransporterService extends ProxyService {

    getOrderDecision(caller, orderId, truckId, okCallback, errorCallback= null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_ORDER_DECISION_ID"];
        this.get(caller, apiUrl + orderId + "/" + truckId,  okCallback, errorCallback);
    }

    acceptOrder(caller, orderId, userId, orderDecisionId, infos, okCallback, errorCallback)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_ACCEPT_ORDER"];
        this.post(caller, apiUrl + orderId + "/" + userId + "/" + orderDecisionId, (infos == null ? {}: infos), okCallback, errorCallback);
    }

    refuseOrder(caller, orderId, userId, orderDecisionId, okCallback, errorCallback)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_REFUSE_ORDER"];
        this.post(caller, apiUrl + orderId + "/" + userId + "/" + orderDecisionId, {}, okCallback, errorCallback);
    }

    getDriver(caller, id, okCallback)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_GET_DRIVER"];
        this.get(caller, apiUrl + id, okCallback);
    }

    deleteDriver(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_DELETE_DRIVER"];
        this.delete(caller, apiUrl + id, okCallback);
    }

    createDriver(caller, datas, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_CREATE_DRIVER"];
        this.post(caller, apiUrl, datas, okCallback, errorCallback);
    }

    saveDriver(caller, datas, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_UPDATE_DRIVER"];
        this.put(caller, apiUrl, datas, okCallback, errorCallback);
    }

    activeDriver(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_ACTIVE_DRIVER"];
        this.post(caller, apiUrl + id, {}, okCallback, errorCallback);
    }

    resendSms(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_RESEND_SMS_FOR_DRIVER"];
        this.post(caller, apiUrl, {id : id}, okCallback, errorCallback);
    }

    getTruck(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_GET_TRUCK"];
        this.get(caller, apiUrl + id, okCallback);
    }

    createTruck(caller, datas, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_CREATE_TRUCK"];
        this.post(caller, apiUrl, datas, okCallback, errorCallback);
    }

    saveTruck(caller, datas, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_UPDATE_TRUCK"];
        this.put(caller, apiUrl, datas, okCallback, errorCallback);
    }

    deleteTruck(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_DELETE_TRUCK"];
        this.delete(caller, apiUrl + id, okCallback);
    }

    activeTruck(caller, id, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_ACTIVE_TRUCK"];
        this.post(caller, apiUrl + id, {}, okCallback, errorCallback);
    }

    getCommercialRules(caller, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        const apiUrl = this.apiUrls["TRANSPORTER_COMMERCIAL_RULES"];
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    getPlanning(caller, date, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        const apiUrl = this.apiUrls["TRANSPORTER_PLANNING"];
        this.get(caller, apiUrl + (date.month() + 1)+ "/" + date.year(), okCallback, errorCallback);
    }

    savePlanningRules(caller, postedDatas, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_SAVE_PLANING_RULES"];
        this.post(caller, apiUrl, postedDatas, okCallback, errorCallback);
    }

    createEvent(caller, postedDatas, okCallback, errorCallback = null)
    {
        if (caller != null)
        {   
            caller.setState({loading : true});
        }
        let apiUrl = this.apiUrls["TRANSPORTER_CREATE_EVENT"];
        this.put(caller, apiUrl, postedDatas, okCallback, errorCallback);
    }

    getAllTruckPropertiesAndFamilies(caller, okCallback, errorCallback)
    {
        if (caller) 
        {
            caller.setState({loading : true});
        }
        const apiUrl = this.apiUrls["TRANSPORTER_PROPERTIES_AND_FAMILIES"];
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    executeSpCustomRule(caller, ruleId, postedDatas)
    {
        if (caller != null)
        {
            caller.setState({loading : true});
        }
        
        let apiUrl = this.apiUrls["TRANSPORTER_EXECUTE_SP_TRUCK_RULE"];
        return this.syncPost(caller, apiUrl + ruleId, postedDatas);
    }

    saveTruckReference(caller, postedDatas, okCallback, errorCallback)
    {
        caller.setState({loading : true});
        let apiUrl = "/api/transporter/truck/reference"
        this.post(caller, apiUrl, postedDatas, okCallback, errorCallback);
    }

    saveTruckAmounts(caller, postedDatas, okCallback, errorCallback)
    {
        caller.setState({loading : true});
        let apiUrl = "/api/transporter/truck/amounts"
        this.post(caller, apiUrl, postedDatas, okCallback, errorCallback);
    }

    saveTruckAddressAndDrivers(caller, postedDatas, okCallback, errorCallback)
    {
        caller.setState({loading : true});
        let apiUrl = "/api/transporter/truck/address"
        this.post(caller, apiUrl, postedDatas, okCallback, errorCallback);
    }

    saveTruckProperties(caller, postedDatas, okCallback, errorCallback)
    {
        caller.setState({loading : true});
        let apiUrl = "/api/transporter/truck/properties"
        this.post(caller, apiUrl, postedDatas, okCallback, errorCallback);
    }

    cloneTruck(caller, truckId, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["TRANSPORTER_CLONE_TRUCK"] + truckId;
        this.post(caller, apiUrl, {}, okCallback, errorCallback);
    }

    changeMissionDriver(caller, missionId, driverId, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["TRANSPORTER_CHANGE_MISSION_DRIVER"].replace("{missionId}", missionId);
        this.post(caller, apiUrl, {driver_id: driverId}, okCallback, errorCallback);
    }

    removeMissionDriver(caller, missionId, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        
        let apiUrl = this.apiUrls["TRANSPORTER_REMOVE_MISSION_DRIVER"].replace("{missionId}", missionId);
        this.delete(caller, apiUrl, okCallback, errorCallback);
    }

    
    addNewIban(caller, formData, okCallback, errorCallback = null)
    {
        caller.setState({loading : true});
        let apiUrl = this.apiUrls["TRANSPORTER_ADD_NEW_PAYMENT_METHOD"];
        const token = this.getToken();
        
        let headers = {
            "Content-Type": "multipart/form-data; boundary=" + Math.random().toString().substr(2),
            "Authorization" : (token === null ? "" : "Bearer " + token)
        };

        axios.post(this.buildUrl(apiUrl), formData, { headers: headers})
        .then(res => { // then print response status
            caller.setState({loading : false});
            okCallback(res.data)
        })
        .catch(function (error) {
            caller.setState({loading : false});

            if (errorCallback)
            {
                errorCallback(error)
            }
            // handle error
        })
    }

    getTrucksForOrderPropsal(caller, orderId, okCallback, errorCallback)
    {
        if (caller) 
        {
            caller.setState({loading : true});
        }
        const apiUrl = this.apiUrls["TRANSPORTER_GET_TRUCKS_FOR_ORDER_PROPOSAL"].replace("{orderId}", orderId);
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    getCallForBidsResultAvailabilities(caller, callForBidsResultId, okCallback, errorCallback = null)
    {
        if (caller != null)
        caller.setState({loading : true});

        let apiUrl = this.apiUrls["TRANSPORTER_CALL_FOR_BIDS_RESULT_AVAILABILITIES"].replace("{callForBidsResultId}", callForBidsResultId);
        this.get(caller, apiUrl, okCallback, errorCallback);
    }

    makeAProposal(caller, callForBidsResultId, datasToPost, okCallback, errorCallback = null)
    {
        if (caller != null)
        caller.setState({loading : true});

        let apiUrl = this.apiUrls["TRANSPORTER_CALL_FOR_BIDS_RESULT_PROPOSAL"].replace("{callForBidsResultId}", callForBidsResultId);
        this.post(caller, apiUrl, datasToPost, okCallback, errorCallback);
    }
}



export default TransporterService;