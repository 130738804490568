import "../../../../assets/vendor/select2/dist/css/select2.min.css";

import React from "react";
import PropTypes from 'prop-types';
 // react plugin used to create DropdownMenu for selecting items
 // reactstrap components
import {
    Input,
Form
} from "reactstrap";
import Select2 from "react-select2-wrapper";
import onClickOutside from "react-onclickoutside";
import GeocodingAndNavigationService from "../../../../proxies/GeocodingAndNavigationService";
import { injectIntl , FormattedMessage } from 'react-intl';

import {asyncContainer, Typeahead,  Menu, MenuItem, Highlighter} from 'react-bootstrap-typeahead';
import FormInput from "../FormInput";
import DefaultInput from "../../../components/common/defaultInput";
import Resources from "../../../../resources";

const AsyncTypeahead = asyncContainer(Typeahead);


class FormAddressAutocomplete extends React.Component     {

    constructor(props, multiple = false, drawOption = null)
    {
        super(props)
        this.geocodingAndNavigationService = new GeocodingAndNavigationService();

        this.options = [];

        this.state = {
            isLoading: false,
            options : [],
            text: null
        }
    }

    handleClickOutside = evt => {
        this._exit()
    };

    /*
    addImageToOption(text, image)
    {
        if (typeof image === "undefined" || image == null || image == "")
            return text;

        return "<span><img width='20px' class='' src='" + image + "' />&nbsp;" + text + "</span>"
    }*/

    _handleSearch(text)
    {
        if (text != null && text.length > 3)
        {
            this.setState({loading: true, text: text})
            this.geocodingAndNavigationService.autosuggest(this, text, (suggestions) => {
                this.setState({ options: suggestions, loading: false})
            })
        }
    }
    
    _renderMenuItem(option, props, index) {
        return (
          <div key={option.id}>
            <span>{option.label}</span>
          </div>
        );
    }

    _exit()
    {
        this.setState({options: [], text: null})
    }

    _onChanged(gpsInfos)
    {
        if (gpsInfos != null)
        {
            this.geocodingAndNavigationService.getAutoSuggestDetail(gpsInfos.gps_external_id, (response) => {
                document.getElementById("gps_external_id").value = response.gps_external_id;
                document.getElementById("gps_lat").value = response.gps_lat;
                document.getElementById("gps_long").value = response.gps_long;
                document.getElementById("gps_level").value = response.gps_level;
                //document.getElementById("address_label").value = response.label;
                document.getElementById("street").value = response.street;
                document.getElementById("zip_code").value = response.zipCode;
                document.getElementById("city").value = response.city;
                document.getElementById("country_code").value = response.country;
                
                document.getElementById("search_input").value = response.label;

                if (this.props.manager)
                {
                    this.geocodingAndNavigationService.setCallerAddressIsValid(this.props.manager, gpsInfos.gps_level)
                }

                this.setState({
                    only_autocomplete: false,
                    value: {
                        address_id: null,
                        gps_external_id: response.gps_external_id,  
                        gps_lat: response.gps_lat,
                        gps_long: response.gps_long,
                        gps_level: response.gps_level,
                        summary: response.label,
                        line4: response.street,
                        line2: null,
                        zip_code: response.zipCode,
                        city: response.city,
                        country_code: response.country
                    },
                })

                //console.log(response)
                this._exit();

                if (this.props.onSelected) {
                    this.props.onSelected(response)
                }
            })
        }

        /*
        if (gpsInfos != null)
        {
            document.getElementById("gps_external_id").value = gpsInfos.gps_external_id;
            document.getElementById("gps_lat").value = gpsInfos.gps_lat;
            document.getElementById("gps_long").value = gpsInfos.gps_long;
            document.getElementById("gps_level").value = gpsInfos.gps_level;

            if (this.props.manager)
            {
                this.geocodingAndNavigationService.setCallerAddressIsValid(this.props.manager, gpsInfos.gps_level)
            }

            this._exit();

            if (this.props.onSelected) {
                this.props.onSelected(gpsInfos)
            }
        }
        */
        /*
        if (typeof e !== "undefined" && e.length > 0)
        {
            this.geocodingAndNavigationService.getLocationDetail(this.props.manager, option.id, (gpsInfos) => {
                document.getElementById("gps_external_id").value = gpsInfos.gps_external_id;
                document.getElementById("gps_lat").value = gpsInfos.gps_lat;
                document.getElementById("gps_long").value = gpsInfos.gps_long;
                document.getElementById("gps_level").value = gpsInfos.gps_level;

                if (this.props.onSelected) {
                    this.props.onSelected(gpsInfos)
                }
            })
        }*/
    }


    render() {
      return (
        <>
        <div>
            
            <DefaultInput readOnly={this.props.readOnly} autoComplete="off" icon={Resources.form_search_icon} name="search_input" onChange={(e)=> this._handleSearch(e.currentTarget.value)} ></DefaultInput>


            <div  className={"dropdown-menu " + ((this.state.options.length > 0) ? "show": "")}>
                { this.state.options.map((option, index) => 
                        <div 
                            key={"selectItem" + index} 
                            className="dropdown-item" onClick={ ()=> this._onChanged(option)}
                            dangerouslySetInnerHTML={{__html: option.html ? option.html : option.label}}
                        > 
                        </div> 
                    )
                }
            </div>
        </div>
            {/*
            <FormInput type="hidden" manager={this.props.manager} name="gps_external_id" />
            <FormInput type="hidden" manager={this.props.manager} name="gps_lat" />
            <FormInput type="hidden" manager={this.props.manager} name="gps_long" />
            <FormInput type="hidden" manager={this.props.manager} name="gps_level" />
            
            <AsyncTypeahead
                {...this.state}
                id='address_suggestion' 
                name="address_suggestion"
                ref={ref => this._typeahead = ref}
                input={{id:"suggest_input_id"}}
                labelKey="street"
                placeholder={this.props.intl.formatMessage({"id" : "Default.address_suggestion_placeholder"})}
                emptyLabel={this.props.intl.formatMessage({"id" : "Default.address_suggestion_empty_results"})}
                onSearch={this._handleSearch.bind(this)}
                onChange={this._onChanged.bind(this)}
            />*/}
        </>
      );
    }
  }
 
  export default onClickOutside(FormAddressAutocomplete)