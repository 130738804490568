// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://stripe.com/docs/payments/accept-a-payment#web

import React, { useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer, IbanElement} from '@stripe/react-stripe-js';
import axios from "axios";
import $ from "jquery";
// reactstrap components
import {
  Input,
  Alert,
  Button,
  Row,
  Col
} from "reactstrap";
import ShipperService from "../../../../proxies/ShipperService";
import Resources from '../../../../resources';
import FormGroupInput from '../form/FormGroupInput';
import FormInput from '../FormInput';
import FormValidationComponent from '../FormValidationComponent';
import { injectIntl , FormattedMessage } from 'react-intl';

class CheckoutForm extends FormValidationComponent {

  constructor(props) {
    super(props);
    this.shipperService = new ShipperService();
    this.intl = this.props.intl;
    this.state = {
      paymentMethodIdToUse: null,
      isProcessing: false,
      checkoutError: null,
      reusePaymentMethodChecked: true,
      ibanAvailableLoaded: false
    };

    this.validationRules = {
        email: { required: true, rules : [{
            rule : this.email.bind(this)
        }]},
        name: {required: true },
        function: { required: true}
    }
  }

  setProcessingTo(value)
  {
    this.setState({isProcessing: value})
  }

  setCheckoutError(error)
  {
    this.setState({checkoutError: error})
  }

  toggleReusePaymentMethod(e)
  {
    this.setState({reusePaymentMethodChecked : !this.state.reusePaymentMethodChecked})
  }

  componentWillMount()
  {
    // Récupération des méthodes de paiement déjà enregistrées
    this.shipperService.getIbansAvailable(this, (response) => {
      this.setState({
        iban: (response.payment_methods.length > 0 ? response.payment_methods[0] : null),
        ibanAvailableLoaded: true,
        paymentMethodIdToUse: response.payment_methods.length > 0 ? response.payment_methods[0].payment_external_id : "new"
      })
    })
  }

  handleSubmit = async (event) => {
    
    // Block native form submission.
    event.preventDefault();

    var formIsValid = this.htmlFormIsValid(event);
    if (!formIsValid)
    {
        return false;
    }
    
    if (this.state.paymentMethodIdToUse == null)
    {
      alert("Merci de selectionner un compte bancaire ou d'en saisir un nouveau.");
      return false;
    }

    const {htAmount, ttcAmount, stripe, elements, onStart, onSuccess, onError} = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    onStart()
    
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const ibanElement = elements.getElement(IbanElement);

    const paymentIntentResponse = await axios.post(this.shipperService.getUrlFor("SHIPPER_CREATE_PAYMENT_INTENT_IBAN"), {
      amount: ttcAmount
    }, { headers: this.shipperService.buildHeaders()});
    
    const clientSecret = paymentIntentResponse.data.transaction_id;
    const paymentIntentId = paymentIntentResponse.data.id;

    var paymentMethodId = null;
    if (this.state.paymentMethodIdToUse == "new")
    {
      // ============================================================
      //          GESTION D'UNE NOUVELLE METHODE DE PAIMENT
      // ============================================================
      // Enregistrement d'une nouvelle carte bleue
      const paymentMethodResponse = await stripe.createPaymentMethod({
        type: 'sepa_debit',
        sepa_debit: ibanElement,
        billing_details : {
            name: this.getHtmlFormControlValue("name"),
            email: this.getHtmlFormControlValue("email")
        },
        metadata: {
            function: this.getHtmlFormControlValue("function")
        }
      });

      if (paymentMethodResponse.error) {
        onError(paymentMethodResponse.error.message);
        this.setCheckoutError(paymentMethodResponse.error.message);
        this.setProcessingTo(false);
        return;
      }
      paymentMethodId = paymentMethodResponse.paymentMethod.id;
      
      // On rattache le mode de paiement au compte client pour le prélèvement du solde de la commande
      const attachPaymentMethodResponse = await axios.post(this.shipperService.getUrlFor("SHIPPER_ATTACH_PAYMENT_METHOD_TO_SHIPPER"), {
        payment_method_id: paymentMethodId,
        meta_data: paymentMethodResponse.paymentMethod,
        // Si le client accepte de réutiliser ce moyen de paiement pour ses futures commandes
        reuse: true
      }, { headers: this.shipperService.buildHeaders()});
    }
    else
    {
      // Utilisation du mode de paiment déjà connu
      paymentMethodId = this.state.paymentMethodIdToUse;
    }
    
    // ============================================================
    const confirmPaymentResponse = await stripe.confirmSepaDebitPayment(clientSecret, {
      payment_method: paymentMethodId
    });

    if (confirmPaymentResponse.error) {
      onError(confirmPaymentResponse.error.message);
      this.setCheckoutError(confirmPaymentResponse.error.message);
      this.setProcessingTo(false);
      return;
    }
    // Payment effectué avec succès
    onSuccess({
      amountPaiedTtc: ttcAmount,
      amountPaiedHt: htAmount,
      paymentExternalId: paymentIntentId,
      paymentMethodExternalId: paymentMethodId
    });
  };

  render() {
    const {stripe} = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="custom-control custom-checkbox">
          <Alert color="secondary">
            <i className={Resources.info_logo} />
            La compte bancaire sera utilisé pour encaisser le solde à la fin de la mission
          </Alert>
        </div>
        <hr/>
        {
            (!this.state.iban) ?
            (
                <>
                    <Row className="justify-content-center" style={{marginTop:"20px"}}>
                        <Col xs="12">
                            <FormGroupInput label="Nom et prénom" htmlFor="name" type="multiline" >
                                <FormInput type="text" name="name" manager={this} />
                            </FormGroupInput>
                        </Col>
                        <Col xs="12">
                            <FormGroupInput label="Email" htmlFor="email" type="multiline" >
                                <FormInput type="text" name="email" manager={this} />
                            </FormGroupInput>
                        </Col>
                        <Col xs="12">
                            <FormGroupInput label="Fonction" htmlFor="function" type="multiline" >
                                <FormInput type="text" name="function" manager={this} />
                            </FormGroupInput>
                        </Col>
                        <Col xs="12">
                            <FormGroupInput label="Iban" htmlFor="iban" type="multiline" >
                            <IbanElement 
                                options={{
                                    supportedCountries: ["SEPA"],
                                    style: {
                                    base: {
                                        fontSize: '16px',
                                        border: "1px solid #dee2e6",
                                        color: '#424770',
                                        '::placeholder': {
                                        color: '#aab7c4',
                                        },
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                    },
                                }}
                            />
                            </FormGroupInput>
                        </Col>
                    </Row>
                </>
            ) 
            :
            (
                <label className="custom-control-label">
                    {this.state.iban.iban_displayed_label}
                </label>
            )
        }
        
        
            
        <Row className="justify-content-center" style={{marginTop:"20px"}}>
            <Button type="submit" disabled={!stripe} color="success">Payer</Button>
        </Row>
         
        
        
      </form>
    );
  }
}

const InjectedIbanCheckoutForm = ({htAmount, ttcAmount, onStart, onSuccess, onError, intl}) => {

    return (
        <ElementsConsumer>
        {({elements, stripe}) => (
            <CheckoutForm intl={intl} htAmount={htAmount} ttcAmount={ttcAmount} elements={elements} stripe={stripe} onStart={onStart} onSuccess={onSuccess} onError={onError} />
        )}
        </ElementsConsumer>
    );
};

export default injectIntl(InjectedIbanCheckoutForm);
