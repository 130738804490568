import React, { useState } from "react"
import { Button } from "reactstrap"
import Search from "./Search"
import HeavyCustom from "./HeavyCustom"
import UtilityCustom from "./UtilityCustom"

const OfferOnline = ({ startOrder, setStartOrder, defaultDisplay = 0 }) => {
  const [display, setDisplay] = useState(defaultDisplay)
  const [category, setCategory] = useState(0)
  if(display === 0) {
    return <div style={{ backgroundColor: "rgb(256,256,256,0.5)", paddingTop: 50, paddingBottom: 50 }} >
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <h1>Réservez votre transport</h1>
      </div>
      <div style={{ display: "flex", justifyContent: "center", justifySelf: "center", width: "70%" }}>
        <div style={{ display: "flex", justifyContent: "center", width: "40%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img src="https://res.cloudinary.com/dl2d3weuy/image/upload/v1720530222/Pictogrammes%20appli/V%C3%A9hicules/V2/utilitaire.png"/>
              <h1 style={{ alignSelf: "center", paddingTop: 5 }}>Utilitaire</h1>
            </div>
            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                <Button style={{ backgroundColor: "#ffc32d", borderRadius: 30 }} onClick={() => {
                  setCategory(1)
                  setDisplay(1)
                }}>
                <span>Mise à disposition</span>
                <svg style={{ height: 20, width: 20 }} transform="" className="icon icon-small icon-black ml-2" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z"></path></svg>
              </Button>
              <Button style={{ backgroundColor: "#ffc32d", borderRadius: 30 }} onClick={() => setDisplay(3)}>
                <span>À la course</span>
                <svg style={{ height: 20, width: 20 }} transform="" className="icon icon-small icon-black ml-2" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z"></path></svg>
              </Button>
            </div>
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "center", width: "40%"}}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{display: "flex", flexDirection: "column"}}>
              <img src="https://res.cloudinary.com/dl2d3weuy/image/upload/v1720530219/Pictogrammes%20appli/V%C3%A9hicules/V2/poids-lourd.png"/>
              <h1 style={{alignSelf: "center", paddingTop: 5}}>Poids lourd</h1>
            </div>
            <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
              <Button style={{ backgroundColor: "#ffc32d", borderRadius: 30 }} onClick={() => {
                setCategory(0)
                setDisplay(1)
              }}>
                <span>Mise à disposition</span>
                <svg style={{ height: 20, width: 20 }} transform="" className="icon icon-small icon-black ml-2" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z"></path></svg>
              </Button>
              <Button style={{backgroundColor: "#ffc32d", borderRadius: 30}} onClick={() => setDisplay(2)}>
                <span>À la course</span>
                <svg style={{ height: 20, width: 20 }} transform="" className="icon icon-small icon-black ml-2" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z"></path></svg>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  } else if(display === 1) {
    return <Search startOrder={startOrder} setStartOrder={setStartOrder} setDisplay={setDisplay} selectedCategory={category}/>
  } else if(display === 2) {
    return <HeavyCustom setDisplay={() => setDisplay(0)}/>
  } else if(display === 3) {
    return <UtilityCustom setDisplay={() => setDisplay(0)}/>
  }
}
export default OfferOnline