/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import queryString from 'query-string';
import $ from "jquery";
// nodejs library that concatenates classes
import classnames from "classnames";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
    Modal,
    Alert,
    Table,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardText,
  CardBody,
  CardImg,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
import BusinessService from "../../../proxies/BusinessService.js";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormValidationComponent from "../components/FormValidationComponent.jsx";
import FormGroupInput from '../components/form/FormGroupInput.jsx';
import FormInput from '../components/FormInput.jsx';
import ReactDatetime from "react-datetime";

import Config from "../../../config.js"
import { injectIntl , FormattedMessage } from 'react-intl';
import AdministratorService from "../../../proxies/AdministratorService.js";
import DefaultLabel from "../../components/common/defaultLabel.jsx";
import StringHelper from "../../../helpers/StringHelper.js";
import DateLabel from '../../components/common/dateLabel';
import DefaultImage from "../../components/common/defaultImage.jsx";
import Resources from "../../../resources.js";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
import FormHeader from "../components/form/FormHeader.jsx";
import Title3 from "../../components/common/title3.jsx";
import DefaultButton from "../../components/common/defaultButton.jsx";

class TransporterIPaymentMethodValidation extends FormValidationComponent {
  
    constructor(props) 
    {
        super(props);
        
        this.administratorService = new AdministratorService();

        this.validationRules = {
            invoice_number: {
                required: false
            },
            invoice_date : {
                required :false,
            }
        }
        this.state = {
            transporterId: null,
            company: null,
            done: false,
            doneMessage: null
        }
    }
 
    componentDidMount() {
        
        // Récupération des données pour l'authentification pas sms
        this.getTransporterIbanToValidate();
        
    }

    getTransporterIbanToValidate()
    {
        const parsedQueryString = queryString.parse(window.location.search);
        // Récupération des données de l'iban à valider
        this.administratorService.getTransporterIbanToValidate(this, parsedQueryString.transporterId, (response) => {
            this.setState({
                transporterId: parsedQueryString.transporterId,
                company: response.value
            })


        }, (httpErrorResponse) => {
            httpErrorResponse.json().then((apiResponse) => {
                this.showErrorFromResponse(apiResponse, null)
            })
            this.setState({loading: false}) 
        })
    }


    confirmOk()
    {
        this.showModalConfirmation({
            iconComponent: <>
                <div className="text-center text-success"  style={{fontSize:"100px",}}>
                    <DefaultImage src={Resources.button_success_icon}></DefaultImage>
                </div>
            </>,
            title: "", 
            component: <>Confirmez vous l'acceptation de cet IBAN ?</>, 
            okButtonTitle: StringHelper.translate(this, "Default.yes"), cancelButtonTitle: StringHelper.translate(this, "Default.no"),
            okButtonCallback: () => {
                this.administratorService.acceptPaymentMethod(this, this.state.transporterId, (response) => {
                        // Confirmation de l'acceptation
                        this.showSuccessModalNotification("", "Les données bancaires du transporteur ont bien été acceptées.")
                        // On n'affiche plus rien
                        this.setState({done: true})
                    }, 
                    (httpErrorResponse) => {
                        httpErrorResponse.json().then((apiResponse) => {
                            this.showErrorFromResponse(apiResponse, null)
                        })
                        this.setState({loading: false}) 
                    }
                )
            },
            cancelButtonCallback: () => {}
        })
    }

    confirmKo()
    {
        this.showModalConfirmation({
            iconComponent: <>
                <div className="text-center text-warning"  style={{fontSize:"100px",}}>
                    <DefaultImage src={Resources.toast_warning_logo}></DefaultImage>
                </div>
            </>,
            title: "", 
            component: <>Confirmez vous le refus de cet IBAN ?</>, 
            okButtonTitle: StringHelper.translate(this, "Default.yes"), cancelButtonTitle: StringHelper.translate(this, "Default.no"),
            okButtonCallback: () => {
                this.administratorService.refusePaymentMethod(
                    this, 
                    this.state.transporterId,
                    (response) => {
                        //console.log("OK")
                        this.showSuccessModalNotification("", "Les données bancaires du transporteur ont bien été refusées.")
                        // On n'affiche plus rien
                        this.setState({done: true})
                    }, 
                    (httpErrorResponse) => {
                        httpErrorResponse.json().then((apiResponse) => {
                            this.showErrorFromResponse(apiResponse, null)
                        })
                        this.setState({loading: false}) 
                    }
                )
            },
            cancelButtonCallback: () => {}
        })
    }

    downloadFile()
    {
        this.administratorService.getTransporterIbanAttachment(this, this.state.transporterId, response => {

            const dtoAttachment = response.value;

            const linkSource = "data:" + dtoAttachment.content_type + `;base64,${dtoAttachment.base64_content}`;
            const downloadLink = document.createElement("a");
            const fileName = dtoAttachment.filename;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }, (httpErrorResponse) => {
            httpErrorResponse.json().then((apiResponse) => {
                this.showErrorFromResponse(apiResponse, null)
            })
            this.setState({loading: false}) 
        })
    }

  componentWillUnmount(){
  }

  renderIbanToValidate(){
    const ibanPublicInfos = this.state.company;

    return <> 
        <div className="pl-lg-4" key={StringHelper.createDomElementId()}>
            <Row>
                <Col lg="12">
                    <Title3> {ibanPublicInfos.company_name} / {ibanPublicInfos.siret}</Title3>
                </Col>
                <Col lg="12">
                    <Row>
                        <Col xs="12" md="8">
                            <h6 className="heading-small text-muted mb-4">
                                Données bancaires à valider
                            </h6>
                            <Row>
                                <Col lg="12">
                                    <FormGroupInput intlId={"Company.iban_owner_label"} htmlFor="company_iban_owner" type="multiline">
                                        <DefaultLabel  style={{width:"100%"}}>{ibanPublicInfos.iban_owner_label}</DefaultLabel>
                                    </FormGroupInput>
                                </Col>
                                <Col lg="12">
                                    <FormGroupInput intlId={"Company.iban_public_value"} htmlFor="company_iban" type="multiline">
                                        <DefaultLabel  style={{width:"100%"}}>{StringHelper.asBlockOf(ibanPublicInfos.iban_public_value)}</DefaultLabel>
                                    </FormGroupInput>
                                </Col>
                                <Col lg="12">
                                    <FormGroupInput intlId={"Company.iban_creation"} htmlFor="company_creation" type="multiline">
                                        <DefaultLabel  style={{width:"100%"}}>Créé le <DateLabel label=" " date={ibanPublicInfos.iban_creation_date}></DateLabel> par {ibanPublicInfos.iban_creator.summary}</DefaultLabel>
                                    </FormGroupInput>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="4">
                            <h6 className="heading-small text-muted mb-4">
                                Fichier a télécharger
                            </h6>
                            <Row className="t2y-center-title">
                                <DefaultImage onClick={elt => this.downloadFile()} className="t2y-clickable" src={Resources.downloaded_file_logo} style={{fontSize:"80px", margin:"0 auto"}}></DefaultImage>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col lg="12">
                    <Row>
                        <Col lg="6" style={{textAlign:"center"}}>
                            <DefaultButton className="t2y-third-button small t2y-warning-button" onClick={(elt) => this.confirmKo()} icon={Resources.checkKo_logo}>Refuser cet iban</DefaultButton>
                        </Col>
                        <Col lg="6" style={{textAlign:"center"}}>
                            <DefaultButton className="t2y-secondary-button" onClick={(elt) => this.confirmOk()} icon={Resources.checkOk_logo}>Valider cet iban</DefaultButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr/>
        </div>
    </>
  }

  render() {
    if (this.state.done)
    {
        return <>
            {this.renderParent()}
        </>
    }

    return (
      <>
        
        {this.renderParent()}
        <FormLoadingIndicator loading={this.state.loading}/>
        <Container className="">
          <Row className="justify-content-center">
            <Col xs="12" lg="8">
                < Card className="bg-secondary border-0 mb-0 p-1">
                    <CardBody>
                        {
                            (this.state.company) ?
                            (
                                this.renderIbanToValidate()
                            ) : (<></>)
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row>
        </Container>
      </>
    );
  }
}

export default injectIntl(TransporterIPaymentMethodValidation);
