import React, { Component } from "react";
import {Route, Redirect } from "react-router-dom";
import SecurityService from '../proxies/SecurityService';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
            new SecurityService().isConnected()
            ? <Component {...props} layout={Component}/>
            : <Redirect to={"/auth/login?expectedUrl=" +  window.btoa(window.location.href)} />
    )} />
);

export default PrivateRoute