import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Container
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import ComponentAndImageStep from "../componentAndImageStep.jsx";
import DefaultLabel from "../../common/defaultLabel.jsx";
import DefaultButton from "../../common/defaultButton.jsx";
import DateHelper from "../../../../helpers/DateHelper.js";
import DefaultLink from "../../common/defaultLink.jsx";
import WebsiteHelper from "../../../../helpers/WebsiteHelper.js";
import StringHelper from "../../../../helpers/StringHelper.js";
import Codes from "../../../../codes.js";
import FormGroupInput from "../../../pages/components/form/FormGroupInput.jsx";
import FormHourInput from "../../../pages/components/form/FormHourInput.jsx";
import FormLoadingIndicator from "../../../pages/components/FormLoadingIndicator.jsx";
import ShipperService from "../../../../proxies/ShipperService.js";


class CallForBidsProcessStep extends ComponentAndImageStep {

    constructor(props) {
        super(props)

        this.shipperService = new ShipperService();
        
        this.validationRules = {
            arrival_time: {
                required: true
            }
        }

        this.state = {
            componentSize: 7,
            imageSize: 5,
            loading: false,
            image: require('../../../../assets/img/illustrations/orderWorkflow_orderArrivalTimeStep.svg').default,
            title: StringHelper.translate(this, "call_for_bids.process_step.title"),
            subtitle: null,
            message: StringHelper.translate(this, "call_for_bids.process_step.message"),
            component: null,
            formDatas: {
            }
        }
    }

    componentWillMount() {
        super.componentWillMount()
        this.props.workflow.renderNormalView()
    }

    componentDidMount() {
        super.componentDidMount()

        const searchId = this.props.workflow.state.input.searchId
        const values = this.props.workflow.state.input.values
        const nbDayToAdd = this.props.workflow.state.input.nbDaysToAdd
        this.shipperService.callForBids(this, searchId, values, nbDayToAdd, (response) => {
            this.props.workflow.goToSpecificStep("CALL_FOR_BIDS_CONFIRMATION", response.id)
        }, (httpErrorResponse) => {
            this.stopSubmiting();
            this.stopLoading();
            httpErrorResponse.json().then((apiResponse) => {
                this.setState({
                    message: null,
                    image: require('../../../../assets/img/illustrations/cancel_confirmation.svg').default,
                    component: this.prepareResponseErrorComponent(apiResponse)
                })
            })
        })
    }
/*
    submit(pid)
    {
        // Enregistrement de l'horaire souhaité sur la commande
        const formIsValid = this.formIsValid()
        if (formIsValid)
        {
            // Enregistrement de l'horaire sur la commande
            const orderId = this.props.workflow.state.input.order_id
            const arrivalTime = this.getHtmlFormControlValue("arrival_time")
            this.shipperService.patchOrder(this, orderId, "arrival_time", arrivalTime, response => {
                // Puis passage a l'étape suivante
                super.submit(pid)

                this.props.workflow.goToNextStep(pid);
            })
        }
    }
*/
    /*
    close()
    {
        this.props.workflow.goToNextStep();
    }*/

    renderComponent() {
        return <>
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
            {this.state.component}
        </>
    }
}


export default injectIntl(CallForBidsProcessStep)