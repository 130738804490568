import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";
import queryString from 'query-string';

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import RegisterCompanyStep from "./registerCompanyStep"
import SiretInputStep from "./siretInputStep"
import ContactWorkflow from "../contact/contactWorkflow"
import Resources from "../../../../resources";
import CompanyConfirmationStep from "./companyConfirmationStep";
import StringHelper from "../../../../helpers/StringHelper";


var moment = require('moment');
require('moment/locale/fr');

class CompanyWorkflow extends Workflow {

    constructor(props) {
        super(props)
        this.businessService = new BusinessService();

        this.loginStepRef = null;
        

        this.state = {
            visible: false,
            current_step: "SIRET_INPUT",
            steps: {
                "SIRET_INPUT": {
                    id: "SIRET_INPUT",
                    position: 1,
                    component: <SiretInputStep embeded={this.props.embeded} workflow={this} type={this.props.type} id="SIRET_INPUT"></SiretInputStep>,
                    links: [
                        /*{ step: "CONTACT", label: "Besoin d'aide?"}*/
                    ],
                    conditions: [
                        {
                            condition: function(){
                                return this.businessService.hasCompanyInfos()
                            }.bind(this),
                            step_on_success: "EXIT",
                            step_on_failure: "CURRENT"
                        }
                    ],
                    nexts: [
                        {   
                            step: "NEXT", 
                            label: "C'est bien ma société", 
                            icon: Resources.button_success_icon,
                            condition: (function(component) { return component.allowNextStep()})
                        }
                    ]
                },
                "COMPANY_WITH_LICENSE_CONFIRMATION": {
                    id: "COMPANY_WITH_LICENSE_CONFIRMATION",
                    position: 2,
                    component: <CompanyConfirmationStep id="COMPANY_WITH_LICENSE_CONFIRMATION" workflow={this} ></CompanyConfirmationStep>
                },
                "COMPANY_WITHOUT_LICENSE_CONFIRMATION": {
                    id: "COMPANY_WITHOUT_LICENSE_CONFIRMATION",
                    position: 3,
                    component: <CompanyConfirmationStep id="COMPANY_WITHOUT_LICENSE_CONFIRMATION" workflow={this} ></CompanyConfirmationStep>
                },
                "CONTACT": {
                    type: "workflow",
                    component: <ContactWorkflow embeded={this.props.embeded} workflow={this} id="CONTACT"></ContactWorkflow>
                }
            }
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        if (this.props.childRef)
        {
            this.props.childRef(this)
        }
    }

    getReturnUrl()
    {
        const parsedQueryString = queryString.parse(window.location.search);
        
        if (parsedQueryString && parsedQueryString.returnUrl)
        {
            return parsedQueryString.returnUrl;
        }
        else
        {
            return null;
        }
    }

    start(inputValue)
    {
        this.setState({ 
            input: inputValue,
            visible: true
        })
    }

    render() {
        return (
            <>
                {this.renderCurrentStep()}
            </>
        );
    }
}


export default injectIntl(CompanyWorkflow)

